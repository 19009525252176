import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { FaMinus, FaPlus } from "react-icons/fa";
import BtnLoader from "../../AppForm/BtnLoader";
import * as yup from "yup";
import { HTTP } from "../../../Utils/API";
import { options } from "../../../Utils/ColumnOptions";
import { asyncGetNewSupplier } from "../../../redux/features/supplier/AddNewSupplier";
import { asyncGetVisaType } from "../../../redux/features/visa/VisaTypeSlice";
import { asyncGetCurrencyData } from "../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { dropMenuProp } from "../../../Utils/MenuProps";
import {
  asyncDeleteRate,
  asyncEditRate,
  asyncGetVisaRate,
  asyncPostVisaRate,
  asyncUpdateRate,
  reqEditNull,
} from "../../../redux/features/visa/RateSlice";
import { asyncGetVisaCountry } from "../../../redux/features/visa/CountrySlice";

const passanger = [
  { id: 1, name: "ADULT", value: "ADT" },
  { id: 3, name: "CHILD", value: "CNN" },
  { id: 2, name: "INFANT", value: "INF" },
];
const Rate = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState("");

  const { visaRate, loading, editRate } = useSelector(
    (state) => state.visaRates
  );
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { newSupplier } = useSelector((state) => state?.supplier);
  const { visaType } = useSelector((state) => state?.visaType);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { visaCountry } = useSelector((state) => state.visaCountry);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const pkr = currencyData?.find((curr) =>
    curr?.curr_code === "PKR" ? true : false
  );
  const supplierlist = newSupplier?.filter(
    (supplier) => supplier?.cat_id === 1
  );

  // check permission for add visa rate for the login user
  const saveVisaRatePermissions = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/visa-rate-post"
      )
    : true;

  // check permission for edit visa rate for the login user
  const editVisaRatePermissions = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/visa-rate-update"
      )
    : true;

  useEffect(() => {
    // dispatch(asyncGetAccountData())
    dispatch(asyncGetVisaCountry());
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetVisaType());
    dispatch(asyncGetCurrencyData());

    dispatch(asyncGetVisaRate());
  }, [editRate, dispatch]);

  const visaTypeStatus = async (status, id) => {
    if (!status) {
      var statusrow = 0;
    } else if (status) {
      var statusrow = 1;
    } else if (status === 0) {
      var statusrow = 0;
    } else if (status === 1) {
      var statusrow = 1;
    }
    const formdata = {
      id: id,
      status: statusrow,
    };
    // console.log(id);
    HTTP.post(`/api/v1/b2b/visa_service_status`, formdata)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const validationSchema = yup.object().shape({
    visaRates: yup.array().of(
      yup.object().shape({
        supplier_id: yup.number().required("Supplier is Required"),
        Passenger_type: yup.string().required("Required"),
        visatype: yup.number().required("Visa Type is Required"),
        currency: yup.number().required("Required"),
        roe: yup.string().required("Required"),

        pay_cur: yup.string().test("pay-cur", "Required", function (value) {
          const currency = this.parent?.currencylist;
          if (currency.curr_code === "PKR") {
            return true;
          }
          return this.parent.pay_cur;
        }),
        rec_cur: yup.string().test("rec-cur", "Required", function (value) {
          const currency = this.parent?.currencylist;
          if (currency.curr_code === "PKR") {
            return true;
          }
          return this.parent.rec_cur;
        }),
        pay_pkr: yup.string().required("Required"),
        rec_pkr: yup.string().required("Required"),
      })
    ),
  });

  const combinedArray = visaRate.reduce((acc, curr) => {
    const found = acc.find(
      (item) =>
        item[0]?.visa_type_id === curr.visa_type_id &&
        item[0]?.supp_acc_id === curr?.supp_acc_id
    );
    if (found) {
      found.push(curr);
    } else {
      acc.push([curr]);
    }
    return acc;
  }, []);

  const columns = [
    // {
    //   label: "id",
    //   name: "id",
    //   options: {
    //     display: false,
    //   },
    // },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div class="checkbox-rect">
              <input
                value={value}
                checked={value}
                type="checkbox"
                id={"with_sar_" + id}
                onChange={(event) => {
                  updateValue(!value);
                  visaTypeStatus(!value, id);
                }}
              />
              <label for={"with_sar_" + id} className="h6"></label>
            </div>
          );
        },
      },
    },
    // {
    //   name: "visa_type_id",
    //   options: {
    //     display: false,
    //   },
    // },
    {
      name: "Country",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newArray = tableMeta?.rowData?.filter((it) => it?.id);

          const visatype_obj = visaType?.find(
            (it) => it.id === newArray?.at(0)?.visa_type_id
          );

          const country = visaCountry?.find(
            (it) => it.id === visatype_obj?.country_id
          );

          return <div>{country?.name}</div>;
        },
      },
    },
    {
      label: "Supplier",
      options: {
        customBodyRender: (value, tableMeta) => {
          const newArray = tableMeta?.rowData?.filter((it) => it?.id);
          return <div>{newArray?.at(0)?.supplier_list.supp_name}</div>;
        },
      },
    },
    // {
    //   label: "pax_type",
    //   name: "pax_type",
    //   options: {
    //     display: false,
    //   },
    // },
    {
      label: "visa/Passenger_type",
      options: {
        customBodyRender: (value, tableMeta) => {
          const newArray = tableMeta?.rowData?.filter((it) => it?.id);
          return (
            <div>
              {newArray?.map((it) => (
                <p style={{ lineHeight: "2rem" }}>
                  {it?.visa_types?.visa_type} / {it?.pax_type}
                </p>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "visa_type",
      label: "visa price",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const newArray = tableMeta?.rowData?.filter((it) => it?.id);
          return (
            <div>
              {newArray?.map((it, idx) => (
                <p key={idx} style={{ lineHeight: "2rem" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {`Pay ${it?.currency?.curr_code}: ${it?.curr_pay} / Rec ${it?.currency?.curr_code}: ${it?.curr_rec}`}
                  </span>
                  {` / Pay PKR: ${it?.pkr_pay} / Rec PKR: ${it?.pkr_rec}`}
                </p>
              ))}
            </div>
          );
        },
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta?.rowData
            ?.map((it) => it?.id)
            ?.filter((value) => typeof value === "number");
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                disabled={error || !editVisaRatePermissions}
                style={{ opacity: !editVisaRatePermissions ? 0.5 : 1 }}
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncEditRate(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button ms-2"
                onClick={() => {
                  dispatch(asyncDeleteRate(id));
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const mappedData = editRate?.map((item) => ({
    ...item,
    supplier_id: item.supp_acc_id || "",
    Passenger_type: item.pax_type || "",
    visatype: item.visa_type_id || "",
    currencylist: item.currency || pkr,
    currency: item.cur_id || "",
    roe: item.roe || "",
    pay_cur: item.curr_pay || "",
    rec_cur: item.curr_rec || "",
    pay_pkr: item.pkr_pay || "",
    rec_pkr: item.pkr_rec || "",
  }));

  const initialValues = {
    visaRates: editRate
      ? mappedData
      : [
          {
            supplier_id: "",
            Passenger_type: "",
            visatype: "",
            currencylist: pkr,
            currency: pkr?.currency_id || "",
            roe: pkr?.roe || "",
            pay_cur: "",
            rec_cur: "",
            pay_pkr: "",
            rec_pkr: "",
          },
        ],
  };

  const handleSubmit = (values, { resetForm }) => {
    const visabody = values.visaRates?.map((rate) => {
      return {
        ...rate,
        visa_type_id: rate?.visatype,
        pax_type: rate?.Passenger_type,
        supp_acc_id: rate?.supplier_id,
        cur_id: rate?.currency,
        pkr_pay: Number(rate?.pay_pkr),
        pkr_rec: Number(rate?.rec_pkr),
        roe: Number(rate?.roe),
        curr_pay: Number(rate?.pay_cur),
        curr_rec: Number(rate?.rec_cur),
      };
    });

    if (editRate) {
      dispatch(asyncUpdateRate(visabody));
      dispatch(reqEditNull());
    } else {
      dispatch(asyncPostVisaRate(visabody));
      resetForm();
    }
  };

  const visaTypeWithCountry = visaType.map((vistype) => {
    const country = visaCountry?.find(
      (country) => country?.id === vistype?.country_id
    );

    return {
      ...vistype,
      country_code: country ? country?.code : "",
    };
  });

  return (
    <div
      style={{ width: "90%" }}
      className="col-sm-8 mx-auto rounded-2 shadow mt-5 pb-5 flash-news"
    >
      <p className="h4 otawix-heading">Visa Rate</p>
      <Formik
        validationSchema={validationSchema}
        validateOnChange={true}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="col-12 mt-5 d-flex flex-column justify-content-center">
              <FieldArray
                name="visaRates"
                render={(arrayHelpers) => (
                  <div>
                    {values?.visaRates?.map((visaRat, index) => (
                      <>
                        <div key={index} className="p-3">
                          <div className="row w-100 mt-2">
                            <div className="col-12 col-sm-2 position-relative">
                              <FormControl
                                variant="standard"
                                style={{ width: "100%" }}
                              >
                                <InputLabel id="demo-simple-select-standard-label">
                                  Supplier
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id={`visaRates[${index}].supplier_id`}
                                  name={`visaRates[${index}].supplier_id`}
                                  value={values.visaRates[index].supplier_id}
                                  label="Supplier"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const existingCombo = visaRate?.filter(
                                      (rate) =>
                                        rate?.supp_acc_id === value &&
                                        rate?.pax_type ===
                                          values?.visaRates[index]
                                            ?.Passenger_type &&
                                        rate.visa_type_id ===
                                          values.visaRates[index].visatype
                                    );
                                    if (existingCombo.length) {
                                      setError(
                                        "This supplier visa type already exists."
                                      );
                                    } else {
                                      setError("");
                                    }
                                    setFieldValue(
                                      `visaRates[${index}].supplier_id`,
                                      value
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].supplier_id`,
                                      value
                                    );
                                  }}
                                  MenuProps={dropMenuProp}
                                >
                                  {supplierlist?.map((vis_supp, i) => (
                                    <MenuItem key={i} value={vis_supp?.list_id}>
                                      {vis_supp?.supp_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name={`visaRates[${index}].supplier_id`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-1">
                              <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Pass Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id={`visaRates[${index}].Passenger_type`}
                                  name={`visaRates[${index}].Passenger_type`}
                                  value={values.visaRates[index].Passenger_type}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const existingCombo = visaRate?.filter(
                                      (rate) =>
                                        rate?.supp_acc_id ===
                                          values?.visaRates[index]
                                            ?.supplier_id &&
                                        rate?.pax_type === value &&
                                        rate.visa_type_id ===
                                          values.visaRates[index].visatype
                                    );
                                    if (existingCombo.length) {
                                      setError(
                                        "This supplier visa type already exists."
                                      );
                                    } else {
                                      setError("");
                                    }
                                    setFieldValue(
                                      `visaRates[${index}].Passenger_type`,
                                      value
                                    );
                                  }}
                                  MenuProps={dropMenuProp}
                                >
                                  {passanger?.map((psg) => (
                                    <MenuItem value={psg.value} key={psg.id}>
                                      {psg.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name={`visaRates[${index}].Passenger_type`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-2 position-relative">
                              <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Visa Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id={`visaRates[${index}].visatype`}
                                  name={`visaRates[${index}].visatype`}
                                  label="Visa Type (- Sign Is Not Allowed To Type)"
                                  value={values.visaRates[index].visatype}
                                  onChange={(e) => {
                                    const existingCombo = visaRate?.filter(
                                      (rate) =>
                                        rate?.supp_acc_id ===
                                          values?.visaRates[index]
                                            ?.supplier_id &&
                                        rate?.pax_type ===
                                          values?.visaRates[index]
                                            ?.Passenger_type &&
                                        rate.visa_type_id === e.target.value
                                    );
                                    if (existingCombo.length) {
                                      setError(
                                        "This supplier visa type already exists."
                                      );
                                    } else {
                                      setError("");
                                    }
                                    setFieldValue(
                                      `visaRates[${index}].visatype`,
                                      e.target.value
                                    );
                                  }}
                                  MenuProps={dropMenuProp}
                                >
                                  {visaTypeWithCountry?.map((vistype, ind) => (
                                    <MenuItem value={vistype.id} key={ind}>
                                      {`${vistype?.country_code} : ${vistype?.visa_type}`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name={`visaRates[${index}].visatype`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                              {error && (
                                <div
                                  className="text-danger mt-1"
                                  style={{ position: "absolute", top: 50 }}
                                >
                                  {error}
                                </div>
                              )}
                            </div>

                            <div className="col-12 col-sm-1">
                              <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Currency
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id={`visaRates[${index}].currency`}
                                  name={`visaRates[${index}].currency`}
                                  value={values.visaRates[index].currency}
                                  label="currency"
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    const currencylist = currencyData?.find(
                                      (it) => it?.currency_id === value
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].currencylist`,
                                      currencylist
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].currency`,
                                      value
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].roe`,
                                      currencylist?.roe
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].pay_pkr`,
                                      ""
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].rec_pkr`,
                                      ""
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].pay_cur`,
                                      ""
                                    );
                                    setFieldValue(
                                      `visaRates[${index}].rec_cur`,
                                      ""
                                    );
                                  }}
                                  MenuProps={dropMenuProp}
                                >
                                  {currencyData?.map((cur, idx) => (
                                    <MenuItem
                                      key={idx}
                                      value={cur?.currency_id}
                                    >
                                      {cur?.curr_code}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <ErrorMessage
                                name={`visaRates[${index}].currency`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-1">
                              <TextField
                                variant="standard"
                                fullWidth
                                value={values.visaRates[index].roe}
                                name={`visaRates[${index}].roe`}
                                label="ROE"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue(
                                    `visaRates[${index}].roe`,
                                    value
                                  );

                                  setFieldValue(
                                    `visaRates[${index}].pay_pkr`,
                                    (
                                      value * values.visaRates[index].pay_cur
                                    ).toFixed(2)
                                  );
                                  setFieldValue(
                                    `visaRates[${index}].rec_pkr`,
                                    (
                                      value * values.visaRates[index].rec_cur
                                    ).toFixed(2)
                                  );
                                }}
                              />
                              <ErrorMessage
                                name={`visaRates[${index}].roe`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-1">
                              <TextField
                                name={`visaRates[${index}].pay_cur`}
                                type={"number"}
                                label={`Pay ${values?.visaRates[index]?.currencylist?.curr_code}`}
                                fullWidth
                                variant="standard"
                                value={values.visaRates[index].pay_cur}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue(
                                    `visaRates[${index}].pay_cur`,
                                    value
                                  );
                                  setFieldValue(
                                    `visaRates[${index}].pay_pkr`,
                                    (
                                      value * values.visaRates[index].roe
                                    ).toFixed(2)
                                  );
                                }}
                                disabled={
                                  values?.visaRates[index]?.currencylist
                                    ?.curr_code === "PKR"
                                }
                              />
                              <ErrorMessage
                                name={`visaRates[${index}].pay_cur`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-1">
                              <TextField
                                name={`visaRates[${index}].rec_cur`}
                                type={"number"}
                                label={`Rec ${values?.visaRates[index]?.currencylist?.curr_code}`}
                                fullWidth
                                variant="standard"
                                value={values.visaRates[index].rec_cur}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue(
                                    `visaRates[${index}].rec_cur`,
                                    value
                                  );
                                  setFieldValue(
                                    `visaRates[${index}].rec_pkr`,
                                    (
                                      value * values.visaRates[index].roe
                                    ).toFixed(2)
                                  );
                                }}
                                disabled={
                                  values?.visaRates[index]?.currencylist
                                    ?.curr_code === "PKR"
                                }
                              />
                              <ErrorMessage
                                name={`visaRates[${index}].rec_cur`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-1">
                              <TextField
                                name={`visaRates[${index}].pay_pkr`}
                                type={"number"}
                                label={`Pay PKR`}
                                fullWidth
                                variant="standard"
                                value={values.visaRates[index].pay_pkr}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue(
                                    `visaRates[${index}].pay_pkr`,
                                    value
                                  );
                                }}
                                inputProps={{
                                  readOnly:
                                    values?.visaRates[index]?.currencylist
                                      ?.curr_code !== "PKR",
                                }}
                              />
                              <ErrorMessage
                                name={`visaRates[${index}].pay_pkr`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-1">
                              <TextField
                                name={`visaRates[${index}].rec_pkr`}
                                type={"number"}
                                label={`Rec PKR`}
                                fullWidth
                                variant="standard"
                                value={values.visaRates[index].rec_pkr}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue(
                                    `visaRates[${index}].rec_pkr`,
                                    value
                                  );
                                }}
                                inputProps={{
                                  readOnly:
                                    values?.visaRates[index]?.currencylist
                                      ?.curr_code !== "PKR",
                                }}
                              />
                              <ErrorMessage
                                name={`visaRates[${index}].rec_pkr`}
                                component={"div"}
                                className="text-danger mt-2"
                              />
                            </div>

                            <div className="col-12 col-sm-1">
                              {index >= 1 && (
                                <button
                                  className="btn bg-danger text-white"
                                  bg={"rgb(0, 154, 214)"}
                                  type="button"
                                  onClick={() => {
                                    if (editRate?.length) {
                                      dispatch(
                                        asyncDeleteRate(
                                          values?.visaRates[index]?.id
                                        )
                                      );
                                      arrayHelpers.remove(index);
                                    } else {
                                      arrayHelpers.remove(index);
                                    }
                                  }}
                                >
                                  - Pax
                                </button>
                              )}
                              {index === 0 && (
                                <button
                                  className="text-white btn"
                                  style={{ backgroundColor: "#00324E" }}
                                  type="button"
                                  disabled={values.visaRates.length === 3}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      supplier_id:
                                        values?.visaRates[index]?.supplier_id,
                                      Passenger_type: "",
                                      visatype:
                                        values?.visaRates[index]?.visatype,
                                      currencylist:
                                        values?.visaRates[index]?.currencylist,
                                      currency:
                                        values?.visaRates[index]?.currency ||
                                        "",
                                      roe: values?.visaRates[index]?.roe || "",

                                      pay_cur: "",
                                      rec_cur: "",
                                      pay_pkr: "",
                                      rec_pkr: "",
                                    })
                                  }
                                >
                                  + Pax
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              />
              <div className="d-flex align-items-center w-25 align-self-center mt-5">
                <button
                  className="btn setup-btn mx-auto"
                  type="submit"
                  disabled={error || !saveVisaRatePermissions}
                >
                  {loading ? <BtnLoader /> : editRate ? "update" : "save"}
                </button>
                {editRate && (
                  <button
                    className="btn bg-danger text-white mx-auto ms-5"
                    onClick={(e) => dispatch(reqEditNull())}
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingTop: 11,
                      paddingBottom: 11,
                    }}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Visa Type Details"}
          data={combinedArray}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Rate;
