import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FieldArray } from "formik";
import React, { useRef, useState } from "react";
// import { menuProps, pax_title, pax_type } from "../helper";
// import TaxPkr from "./pax/TaxPkr";
// import TaxCurr from "./pax/TaxCurr";
// import SupplierCurr from "./pax/SupplierCurr";
// import SupplierPkr from "./pax/SupplierPkr";
// import AgentPkr from "./pax/AgentPkr";
// import AgentCurr from "./pax/AgentCurr";
// import PerPaxTotal from "./pax/PerPaxTotal";
// import Currency from "./pax/Currency";
import { debounce } from "lodash";
// import { getAsyncSearchPNR } from "../../../../../redux/features/booking/bookingDetailsSlice";
import { useDispatch } from "react-redux";
// import { getAsyncSearchPNR } from "../../../../../redux/features/booking/bookingDetailsSlice";
import CurrencyCredit from "./CurrencyCredit";
import PerPaxTotalCredit from "./PerPaxTotalCredit";
import TaxCurr from "../Flight/pax/TaxCurr";
import TaxPkr from "../Flight/pax/TaxPkr";
import { getAsyncSearchPNR } from "../../../../../redux/features/booking/bookingDetailsSlice";
import { menuProps, pax_title, pax_type } from "../helper";
import TaxPkrCredit from "./TaxPkrCredit";
import SupplierCredit from "./SupplierCredit";
import AgentCurrCredit from "./AgentCurrCredit";
import SupplierPkrCredit from "./SupplierPkrCredit";
import AgentPkrCredit from "./AgentPkrCredit";
import TaxCurrCredit from "./TaxCurrCredit";

const PaxFareCredit = ({ values, setFieldValue, auto_manual }) => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [pnrError, setPnrError] = useState({});
  ////////////////// scroll to the new added pax
  const scrollToElement = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end", // Align the bottom of the element with the bottom of the scroll container
        inline: "nearest", // Scroll as little as possible to make the element visible
      });
    }
  };
  const debouncedHandleChange = debounce((e, index) => {
    const param = e.target.value;
    dispatch(getAsyncSearchPNR(param))
      .then((response) => {
        setPnrError({
          ...pnrError,
          ["open_" + index]: response?.payload ? true : false,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, 3000);

  return (
    <FieldArray
      name="fareValues"
      render={(arrayHelpers) => (
        <div>
          {values?.fareValues?.map((friend, index) => (
            <>
              <div key={index}>
                <div className="section_box">
                  {/* pax section */}
                  <div className="pax mt-2 mt-md-0">
                   
                    <div className="row my-2">
                      <div className="col-12">
                        <div className="row align-items-end">
                          <div className="col-4 col-md-1">
                            <FormControl
                              variant="standard"
                              className="w-100"
                              required
                            >
                              <InputLabel id={`type-label-${index}`}>
                                Type
                              </InputLabel>
                              <Select
                                id={`fareValues[${index}].pax_type`}
                                value={friend.pax_type}
                                onChange={(e) => {
                                  setFieldValue(
                                    `fareValues[${index}].pax_type`,
                                    e.target.value
                                  );
                                }}
                                label="Type"
                                MenuProps={menuProps}
                                required
                                disabled={auto_manual === 0}
                              >
                                {pax_type?.map((it) => (
                                  <MenuItem value={it} key={it}>
                                    {it}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-4 col-md-1">
                            <FormControl
                              variant="standard"
                              className="w-100"
                              required
                            >
                              <InputLabel id={`title-${index}`}>
                                Title
                              </InputLabel>
                              <Select
                                id={`fareValues[${index}].title`}
                                value={friend.title}
                                onChange={(e) => {
                                  setFieldValue(
                                    `fareValues[${index}].title`,
                                    e.target.value
                                  );
                                }}
                                label="Title"
                                MenuProps={menuProps}
                                required
                                disabled={true}
                              >
                                {pax_title?.map((it) => (
                                  <MenuItem value={it} key={it}>
                                    {it}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                          <div className="col-4 col-md-1">
                            <TextField
                              value={friend.first_name}
                              id={`fareValues.[${index}].first_name`}
                              onChange={(e) => {
                                setFieldValue(
                                  `fareValues.[${index}].first_name`,
                                  e.target.value.toUpperCase()
                                );
                              }}
                              label="F-Name"
                              variant="standard"
                              autoComplete="off"
                              required
                              disabled={true}
                            />
                          </div>
                          <div className="col-4 col-md-1">
                            <TextField
                              value={friend.last_name}
                              id={`fareValues.[${index}].last_name`}
                              onChange={(e) => {
                                setFieldValue(
                                  `fareValues.[${index}].last_name`,
                                  e.target.value.toUpperCase()
                                );
                              }}
                              label="L-Name"
                              variant="standard"
                              autoComplete="off"
                              required
                              disabled={true}
                            />
                          </div>
                          <div className="col-4 col-md-1">
                            <TextField
                              value={friend.ticket_no}
                              id={`fareValues.[${index}].ticket_no`}
                              onChange={(e) => {
                                setFieldValue(
                                  `fareValues.[${index}].ticket_no`,
                                  e.target.value
                                );

                                debouncedHandleChange(e, index);
                              }}
                              label="Ticket No"
                              variant="standard"
                              autoComplete="off"
                              required
                              disabled={true}
                            />

                            {pnrError["open_" + index] ? (
                              <p className="position-absolute text-danger">
                                Ticket number already exist.
                              </p>
                            ) : null}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* fare section */}
                  <div className="black_border rounded p-2 pe-4 py-5 my-4 mt-5">
                    <div className="row">
                      {/* fare /supplier /agen */}
                      <div className="col-3">
                        <div className="row align-items-start">
                          <div
                            className="h5 col-2"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            Fare:
                          </div>
                          <CurrencyCredit
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                            auto_manual={auto_manual}
                          />
                        </div>
                        {/* per passenger total */}
                        <PerPaxTotalCredit
                          friend={friend}
                          values={values}
                          index={index}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      {/* taxes */}
                      <div
                        className="col-3 d-flex flex-column"
                        style={{ gap: "50px" }}
                      >
                        {/* TAX IN CURRENCY (ANY SELECTED) */}
                        {friend?.currency?.curr_code !== "PKR" && (
                          <TaxCurrCredit
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                          />
                        )}

                        {/* TAX IN PKR */}
                        <TaxPkrCredit
                          setFieldValue={setFieldValue}
                          values={values}
                          friend={friend}
                          index={index}
                          auto_manual={auto_manual}
                        />
                        {/* inputs field */}
                        <div
                          className=" position-relative col-4  mt-4 mt-md-0"
                          style={{ width: "50%" }}
                        >
                          <FormControl
                            variant="standard"
                            className="w-100"
                          
                          >
                            <InputLabel
                              htmlFor={`segmentValues.${index}?.destination`}
                            >
                              Service Fee
                            </InputLabel>
                            <Input
                              autoComplete="off"
                              type={"number"}
                              id={`fareValues.${index}?.selfCharge`}
                              value={values?.fareValues[index]?.selfCharge}
                              name={`fareValues.${index}.selfCharge`}
                              // onFocus={(event) => {
                              //   event.target.select();
                              // }}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue(
                                  `fareValues.${index}.selfCharge`,
                                  value
                                );
                              }}
                            />
                          </FormControl>
                        </div>
                        <div
                          className=" position-relative col-4  mt-4 mt-md-0"
                          style={{ width: "50%" }}
                        >
                          <FormControl
                            variant="standard"
                            className="w-100"
                         
                          >
                            <InputLabel
                              htmlFor={`segmentValues.${index}?.destination`}
                            >
                              Airine Charge
                            </InputLabel>
                            <Input
                              autoComplete="off"
                              type={"number"}
                              id={`fareValues.${index}?.AirLineCharge`}
                              value={values?.fareValues[index]?.AirLineCharge}
                              name={`fareValues.${index}.AirLineCharge`}
                              // onFocus={(event) => {
                              //   event.target.select();
                              // }}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue(
                                  `fareValues.${index}.AirLineCharge`,
                                  value
                                );
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-6">
                        {/* supplier in current(any curr selected) */}
                        {friend?.currency?.curr_code !== "PKR" && (
                          <div
                            className="row align-items-center"
                            style={{ marginBottom: 14 }}
                          >
                            {/* SUPPLIER IN CURRENCY (ANY SELECTED) */}
                            <SupplierCredit
                              setFieldValue={setFieldValue}
                              values={values}
                              friend={friend}
                              index={index}
                              auto_manual={auto_manual}
                            />
                            {/* AGENT IN CURRENCY (ANY SELECTED) */}
                            <AgentCurrCredit
                              setFieldValue={setFieldValue}
                              values={values}
                              friend={friend}
                              index={index}
                            />
                          </div>
                        )}
                        <div className={`row align-items-center`}>
                          {/* SUPPLIER IN PKR */}
                          <SupplierPkrCredit
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                            auto_manual={auto_manual}
                          />
                          {/* AGENT IN PKR */}
                          <AgentPkrCredit
                            setFieldValue={setFieldValue}
                            values={values}
                            friend={friend}
                            index={index}
                          />
                        </div>
                      </div>
                    </div>
                    <div ref={elementRef} />
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      )}
    />
  );
};

export default PaxFareCredit;
