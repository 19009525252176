import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import CustomDropdown from "../CustomDrp";

const VisaTab = () => {
  const [countries, setCountries] = useState([
    "Dubai",
    "Saudia",
    "Turkey",
    "Thai Visa",
  ]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };
  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };

  return (
    <div className="my-3">
      <div className="row align-items-end">
        <div className="col-sm-3">
          <CustomDropdown arry={countries} placehlder='Select Country' initvalue={selectedCountry} setfunction={setSelectedCountry} />
        </div>
        {selectedCountry && (
          <div className="col-sm-3 mt-3 mt-md-0">
            <TextField variant="standard" label='Refrence No' className="w-100" />
          </div>
        )}
        {selectedCountry && (
          <div className="col-sm-3 mt-3 mt-md-0">
            <TextField variant="standard" label='Please Select a Company' className="w-100" />
          </div>
        )}
      </div>
      {selectedCountry && (
        <>
          <h5 className="mt-5">Visa Type</h5>
          <div className="row mt-3 mt-md-5 ">
            <div className="col-md-3">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="">30 days single entry</span>
              </label>
            </div>
            <div className="col-6 col-md-2 mt-3 mt-md-0">

              <label class="form-control  ">
                <input type="radio" name="radio" />
                <span className="">Short Stay</span>
              </label>
            </div>
            <div className="col-6 col-md-2 mt-3 mt-md-0">

              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="">Short Stay</span>
              </label>
            </div>
          </div>
          <hr />
          <div>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
              <h5>PAX Details</h5>
              <div className="mt-3 mt-md-0">
                <Button variant="contained"
                  className="me-2"
                  onClick={addRow}
                >Add Passanger
                </Button>
                <Button variant='contained' color="error"
                  onClick={(e) => removeRow()}
                >
                  Remove Passanger
                </Button>
              </div>
            </div>

          </div>
          <div className="row mt-3 mt-md-4">
            <div className="col-6 col-sm-2">
              <TextField variant="standard" label='Passenger Type' className="w-100" />
            </div>
            <div className="col-6 col-sm-2">
              <TextField variant="standard" label='Complete Name' className="w-100" />
            </div>
            <div className="col-6 col-sm-2">
              <TextField variant="standard" label='Date Of Birth' className="w-100" />
            </div>
            <div className="col-6 col-sm-2">
              <TextField variant="standard" label='Nationality' className="w-100" />
            </div>
            <div className="col-6 col-sm-2">
              <TextField variant="standard" label='Passport' className="w-100" />
            </div>
            <div className="col-6 col-sm-2">
              <TextField variant="standard" label='Price' className="w-100" />
            </div>
          </div>
          <div className="row justify-content-between mt-5">
            <div className="col-sm-5 border rounded-2 py-2">
              <p>
                Attachment 1 File Size Not Greater Then 2 MB And Format Must Be
                PDF JPG and PNG *
              </p>
              <input type="file" />
            </div>
            <div className="col-sm-3 border rounded-2 py-2 my-3 my-md-0">
              <p>Attachment 2</p>
              <input type="file" />
            </div>
            <div className="col-sm-3 border rounded-2 py-2 px-2">
              <p>Attachment 3</p>
              <input type="file" />
            </div>
          </div>

          {multiRows.map((val) => (
            <>
              <div className="col-sm-12 fl-right mt-5">
                <hr />
              </div>
              <div className="row mt-1">
                <div className="col-sm-2">
                  <span>Passanger Type*</span>
                  <input
                    type="text"
                    className="inputs_visa_tab mt-3"
                    placeholder=""
                  />
                </div>
                <div className="col-sm-2">
                  <span>Complete Name*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Date Of Birth*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Nationality*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Passport*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
                <div className="col-sm-2">
                  <span>Price*</span>{" "}
                  <input type="text" className=" inputs_visa_tab mt-3" />
                </div>
              </div>
              <div className="row justify-content-between mt-5">
                <div className="col-sm-5 border rounded-2 py-2">
                  <p>
                    Attachment 1 File Size Not Greater Then 2 MB And Format Must
                    Be PDF JPG and PNG *
                  </p>
                  <input type="file" />
                </div>
                <div className="col-sm-3 border rounded-2 py-2 ">
                  <p>Attachment 2</p>
                  <input type="file" />
                </div>
                <div className="col-sm-3 border rounded-2 py-2 px-2">
                  <p>Attachment 3</p>
                  <input type="file" />
                </div>
              </div>
            </>
          ))}
          <h5 className="mt-5">Requirement</h5>
          <hr />
          <ol>
            <li>PSPT size icture white background</li>
          </ol>

          <h5 className="mt-5">Requirement</h5>
          <hr />
          <ul>
            <li>
              Visa fee once deposited shall NOT BE refunded in case of visa
              application is rejected/denied by the authorities.
            </li>
          </ul>

          <Button variant="contained" className="mt-3">
            submit
          </Button>
        </>
      )}
    </div>
  );
};

export default VisaTab;
