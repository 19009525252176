import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
// import icons
import AgencyDataW from "../../b2b icons/agency/agency data W.png";
import AgencyDataB from "../../b2b icons/agency/agency data.png";
import UserW from "../../b2b icons/agency/users W.png";
import User from "../../b2b icons/agency/users.png";
import RolePermissionW from "../../b2b icons/agency/roles permissions W.png";
import RolePermission from "../../b2b icons/agency/roles permissions.png";
import HomeViewW from "../../b2b icons/agency/home page W.png";
import HomeView from "../../b2b icons/agency/home page.png";
import checkMenuItemExistence from "../../Utils/checkMenuItemExistence";

const AgencyDropDown = ({ callback }) => {
  const navigate = useNavigate();

  //use state
  const [sub_menuagents, setSub_MenuAgents] = useState(false);
  const [isHovered, setIsHovered] = useState("dark");
  //use selector redux
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const { userTypePermissions } = useSelector(
    (state) => state?.roleAndPermissions
  );

  // local storage get and set  // set and get local storage
  const Active_link = localStorage.getItem("Active_link")?.split(")")[0];
  const setActive_link = (value) =>
    localStorage.setItem("Active_link", `${value})agency`);

  // Menu array for agency dropdown
  const menuArrays = [
    {
      title: "Agency Data",
      pageLink: "/agency-data",
      subMenu: [],
      page_level_id: 8,
      iconW: AgencyDataW,
      iconB: AgencyDataB,
    },
    {
      title: "Users",
      pageLink: "/agency-users",
      subMenu: [],
      page_level_id: 17,
      iconW: UserW,
      iconB: User,
    },

    {
      title: "Roles and  Permissions",
      pageLink: "/agency-user-level",
      subMenu: [],
      page_level_id: 146,
      iconW: RolePermissionW,
      iconB: RolePermission,
    },
    {
      title: "Home View Settings",
      pageLink: "/home-view-settings",
      page_level_id: [22, 38, 30, 34, 26],
      iconW: HomeViewW,
      iconB: HomeView,
      subMenu: [
        {
          title: "Banner",
          pageLink: "/agency-banner",
          page_level_id: 22,
        },
        {
          title: "About Us",
          pageLink: "/agency-aboutus",
          page_level_id: 38,
        },
        {
          title: "Our Services",
          pageLink: "/agency-ourservies",
          page_level_id: 30,
        },
        {
          title: "Our Partner",
          pageLink: "/agency-ourpartner",
          page_level_id: 34,
        },
        {
          title: "Footer Gallery",
          pageLink: "/agency-ourgallery",
          page_level_id: 26,
        },
      ],
    },
  ];

  useEffect(() => {
    const filteredItems = checkMenuItemExistence(
      userTypePermissions,
      menuArrays,
      user
    );
    callback(filteredItems);
  }, [userTypePermissions, user]);

  return (
    <div className="dropdown-menu-agency" id="dropdown-menu-agency">
      {menuArrays.map((item, index) => {
        // if the login user has permission to the specified page
        let isSubArrayExist = item?.page_level_id?.length > 0;
        let rolePermission = userTypePermissions?.find((role) => {
          return isSubArrayExist
            ? item?.page_level_id?.some(
                (id) =>
                  role.user_type_id === user?.role_id &&
                  id === role.page_level_id
              )
            : role.user_type_id === user?.role_id &&
                role.page_level_id === item?.page_level_id;
        });

        return rolePermission || user?.user_id === 1 ? (
          <div
            key={index}
            onMouseEnter={() => setIsHovered(`W${index}`)}
            onMouseLeave={() => setIsHovered("")}
          >
            <div
              className={`finance-dropdown-items ${
                Active_link === item.pageLink ||
                item.subMenu.find((it) => it.pageLink === Active_link)
                  ? "menu_link_container-active"
                  : ""
              }`}
              onClick={() => {
                item.subMenu.length > 0 && setSub_MenuAgents(!sub_menuagents);
                item.subMenu.length < 1 && setActive_link(item.pageLink);
                item.subMenu.length < 1 && navigate(`${item.pageLink}`);
                const element = document.getElementById("dropdown-menu-agency");
                item.subMenu.length === 0 &&
                  element.classList.remove("dropdown-menu-agency-show");
              }}
            >
              <div
                className="d-flex align-items-end"
                onMouseEnter={() => setIsHovered(`W${index}`)}
                onMouseLeave={() => setIsHovered("")}
              >
                <img
                  src={
                    isHovered === `W${index}` ||
                    Active_link === item.pageLink ||
                    item.subMenu.find((it) => it.pageLink === Active_link)
                      ? item.iconW
                      : item.iconB
                  }
                  alt=""
                  width={25}
                  height={25}
                  className="me-3"
                />
                <div className="drp-links">{item.title}</div>
              </div>
              {item.subMenu.length > 0 && (
                <div>
                  {sub_menuagents ? (
                    <IoIosArrowUp size={25} />
                  ) : (
                    <IoIosArrowDown size={25} />
                  )}
                </div>
              )}
            </div>
            {item.subMenu.length > 0 && sub_menuagents && (
              <div
                className="py-2"
                style={{ paddingLeft: "2rem" }}
                onMouseEnter={() => setIsHovered(``)}
              >
                {item.subMenu?.map((it) => {
                  // if the login user has permission to the specified page
                  let rolePerSubPage = userTypePermissions?.find(
                    (role) =>
                      role.user_type_id === user?.role_id &&
                      role.page_level_id === it.page_level_id
                  );

                  return rolePerSubPage !== undefined || user?.user_id === 1 ? (
                    <Link
                      to={`${it.pageLink}`}
                      onClick={() => {
                        const element = document.getElementById(
                          "dropdown-menu-agency"
                        );
                        element.classList.remove("dropdown-menu-agency-show");
                        setActive_link(`${it.pageLink}`);
                      }}
                      className={`finance-dropdown-items ${
                        Active_link === it.pageLink &&
                        "menu_link_container-active"
                      }`}
                      key={it.title + index}
                    >
                      <div className="d-flex align-items-center">
                        <EastIcon color="white" size="20" className="me-2" />
                        <span>{it.title}</span>
                      </div>
                    </Link>
                  ) : null;
                })}
              </div>
            )}
          </div>
        ) : null;
      })}
    </div>
  );
};
export default AgencyDropDown;
