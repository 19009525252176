import React, { useState, useEffect } from "react";
import {
  MdCheck,
  MdOutlineRestaurant,
  MdRemoveRedEye,
  MdSwapHoriz,
} from "react-icons/md";
import { LightTooltip } from "../../OneWaySearchResult/LightTooltip";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../../Utils/currencyExchange";
import { BsBagCheck, BsHandbag, BsPerson } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import moment from "moment";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setselectedFlight } from "../../../../../redux/features/home/FlightSearchSlice";
import { NODEURL } from "../../../../../redux/features/ApiConfig";
import BtnLoader from "../../../../AppForm/BtnLoader";
const FlyJinnahFares = ({
  rpH,
  segments,
  timeDuration,
  getPaxTaxes,
  getPaxFare,
  singlePaxFare,
  ptcBreakdowns,
  faresArray,
  flyJinnahFares,
  fareBreakdowns,
  flyjinnahItem,
  selectedFare,
  lowestTotalFare,
  searchValues,
  GetFlyJinnahFaresDetails,
  selOutbound,
  selInBound,
  cookie,
  transaction_identifier,
}) => {
  const [faress, setFares] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const [outBoundBundleId, setOutBoundBundleId] = useState([]);
  const [fareData, setFareData] = useState({});
  const { selectedFlight } = useSelector((state) => state.FlightSearch);
  const outB = selectedFlight?.outboundFare;
  const inB = selectedFlight?.inboundFare;
  const [boundToggle, setBoundToggle] = useState("outBound");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const extractBaggageInfo = (description, fare) => {
    const carryOnBaggageMatch = description?.match(
      /(\d+\s*Kg\s*Carry(-| )?on Baggage|\d+\s*Kg\s*Carry(-| )?on|\d+\s*Kg\s*Hand Baggage)/i
    );
    const checkedBaggageMatch = description?.match(
      /(\d+\s*Kg\s*Checked Baggage|Checked Baggage:\s*\d+\s*Kg)/i
    );

    let checkedBaggage = "No Baggage";
    if (checkedBaggageMatch) {
      // If checked baggage match found, split the string and extract the numeric value and 'Kg'
      const baggageParts = checkedBaggageMatch?.[0]
        .replace(/Checked\s*Baggage\s*:\s*|\s*Checked\s*Baggage/, "")
        .split(/\s+/);
      checkedBaggage = baggageParts?.join(" ");
      // Join the parts back together, including the numeric value and 'Kg'
    }

    return {
      carryOnBaggage: carryOnBaggageMatch ? carryOnBaggageMatch?.[0] : "N/A",
      checkedBaggage: checkedBaggage,
      checkedBaggageMatch: checkedBaggageMatch
        ? checkedBaggageMatch?.[0]
        : "No Baggage",
    };
  };
  const outBoundformateDate =
    flyjinnahItem?.itenaries?.[0]?.flightSegments?.[0]?.departureDateTimeLocal;
  const outformattedDate = moment(outBoundformateDate)
    .format("ddd DD-MMM-YY")
    .toUpperCase();
  const inBoundformateDate =
    flyjinnahItem?.itenaries?.[1]?.flightSegments?.[0]?.departureDateTimeLocal;
  const informattedDate = moment(inBoundformateDate)
    .format("ddd DD-MMM-YY")
    .toUpperCase();

  const firstSegment = flyjinnahItem?.itenaries?.at(0).flightSegments;
  const lastSegment = flyjinnahItem?.itenaries?.at(1).flightSegments;
  const fare_req_data = flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.$;
  let TransactionIdentifier =
    flyJinnahFares?.["ns1:OTA_AirPriceRS"]?.["$"]?.["TransactionIdentifier"];
  // Function to filter fares based on segment code
  const filterFares = (segmentCode) => {
    return faresArray?.filter(
      (fare) => fare?.["applicableOnd"] === segmentCode
    );
  };
  const setFaresBasedOnBoundToggle = () => {
    if (boundToggle === "outBound") {
      const outboundSegmentCode = firstSegment?.[0]?.segmentCode;
      setFares(filterFares(outboundSegmentCode));
    } else if (boundToggle === "inBound") {
      const inboundSegmentCode =
        lastSegment?.[lastSegment?.length - 1].segmentCode;
      //   selectedFare ? setFares(fa) :
      setFares(filterFares(inboundSegmentCode));
    }
  };

  useEffect(() => {
    setFaresBasedOnBoundToggle();
    if (selectedFlight) {
      setFares([selectedFlight?.[`${boundToggle?.toLocaleLowerCase()}Fare`]]);
    }
  }, [boundToggle, firstSegment, lastSegment]);
  const baggage = {
    Value: "23 KG",
    Extra: "46 KG",
    Ultimate: "46 KG",
    Basic: "No Baggage",
  };

  return (
    <div className="p-3">
      <div className="d-flex align-items-center justify-content-between mt-3 mb-2">
        <div
          className={`${
            boundToggle === "outBound"
              ? "button_acitve active-box"
              : "button_style"
          } rounded-md p-2 w-50 cursorpointer me-2`}
          onClick={() => {
            setBoundToggle("outBound");
            if (!selectedFlight) {
              GetFlyJinnahFaresDetails("", TransactionIdentifier);
            }
          }}
        >
          <div className="d-flex gap-4">
            <div className="font-medium">
              {`${
                flyjinnahItem?.itenaries?.[0]?.flightSegments?.[0]?.origin
                  ?.airportCode
              } -
              ${
                flyjinnahItem?.itenaries?.[0]?.flightSegments[
                  flyjinnahItem?.itenaries?.[0]?.flightSegments?.length - 1
                ]?.destination?.airportCode
              }`}
            </div>
            <div className="font-medium">{outformattedDate}</div>
          </div>
        </div>

        <div
          className={`${
            boundToggle === "inBound"
              ? "button_acitve active-box text-white"
              : "button_style"
          } rounded-md p-2 w-50 cursorpointer ms-2`}
          onClick={() => {
            setBoundToggle("inBound");
            if (!selectedFlight) {
              GetFlyJinnahFaresDetails("", TransactionIdentifier);
            }
          }}
        >
          <div className="d-flex gap-4">
            <div className="font-medium">
              {`${
                flyjinnahItem?.itenaries?.[1]?.flightSegments?.[0]?.origin
                  ?.airportCode
              } -
              ${
                flyjinnahItem?.itenaries?.[1]?.flightSegments?.[
                  flyjinnahItem?.itenaries?.[1]?.flightSegments?.length - 1
                ]?.destination?.airportCode
              }`}
            </div>
            <div className="font-medium">{informattedDate}</div>
          </div>
        </div>
      </div>
      <div className="search_engin_result_box py-3 bg-white h-auto">
        <div
          className={`d-flex ${
            selectedFare ? "justify-content-start" : "justify-content-between"
          } align-items-start px-5`}
        >
          {/* headings */}
          <div className={`${selectedFare ? "me-5 pe-5" : ""}`}>
            <div className="mb-4 pb-3"></div>
            <div className="flight-detail-headings">
              <BsHandbag size={25} className="color_primary" />
              <span>Hand Carry Bags</span>
            </div>
            <div className="flight-detail-headings">
              <BsBagCheck size={25} className="color_primary" />
              <span>Checked Bags</span>
            </div>
            <div className="flight-detail-headings">
              <MdOutlineRestaurant size={25} className="color_primary" />
              <span>Meals</span>
            </div>
            <div className="flight-detail-headings">
              <BsPerson size={25} className="color_primary" />
              <span>Seat Selection</span>
            </div>
            <div className="flight-detail-headings">
              <MdSwapHoriz size={25} className="color_primary" />
              <span>Refund & Exchanges</span>
            </div>
          </div>

          {Array.isArray(faress) &&
            (selectedFare
              ? faress
              : [
                  {
                    "ns1:bunldedServiceId": "",
                    "ns1:bundledServiceName": "Basic",
                    "ns1:perPaxBundledFee": "0",
                    "ns1:bookingClasses": "",
                    "ns1:description": "",
                  },
                  ...faress,
                ]
            )?.map((fare, index) => {
              const { carryOnBaggage, checkedBaggage, checkedBaggageMatch } =
                extractBaggageInfo(fare?.["ns1:description"]);

              const paxFare = Array.isArray(ptcBreakdowns)
                ? ptcBreakdowns
                : [ptcBreakdowns];
              return (
                <div key={index}>
                  <div className="fs-4 theme-text-color mb-3 fw-bold">
                    {fare?.["ns1:bundledServiceName"]}
                  </div>
                  <div className="flight-detail-headings">
                    <MdCheck size={25} color="green" />
                    <span>Upto 10 kg</span>
                  </div>
                  <div className="flight-detail-headings">
                    <>
                      <MdCheck size={25} color="green" />
                      <span>
                        {/* {checkedBaggage
                            ? checkedBaggage
                            : checkedBaggageMatch} */}
                        {baggage[fare?.["ns1:bundledServiceName"]]}
                      </span>
                    </>
                  </div>
                  <div className="flight-detail-headings">
                    <MdCheck size={25} color="green" />
                    <span>Allowed</span>
                  </div>
                  <div className="flight-detail-headings">
                    <CgMenuGridR size={25} color="#38025c" />
                    <span>System Allocated</span>
                  </div>
                  {/* refund and exchange dialog  */}
                  <div className="flight-detail-headings">
                    <LightTooltip
                      title={
                        <div className="p-3">
                          <h4>Refund & Exchange</h4>
                          <h6>
                            Penalty will be charged per passenger segment.
                          </h6>
                          <div className="text-danger font-italic">
                            for further clarification contact with Airline Or
                            Call our support team.
                          </div>
                          <div className="text-danger font-italic mt-2">
                            After 25 days of departure ticket is Non-Refundable.
                          </div>

                          <h6 className="mt-3">
                            Call our support team to verify the Exchange &
                            Refund Charges
                          </h6>
                        </div>
                      }
                      placement="top-start"
                    >
                      <div style={{ cursor: "pointer" }}>
                        <MdRemoveRedEye size={25} color="#262829" />
                        <span>View</span>
                      </div>
                    </LightTooltip>
                  </div>
                  {/* ///////////// fare button and tooltip */}
                  {!selectedFare ? (
                    <div className="pt-3 px-2">
                      <LightTooltip
                        title={
                          <div className="p-3">
                            <div className="d-flex justify-content-between align-items-end">
                              <div className="d-flex me-4">
                                <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                <h5>* {searchValues?.adultsCount}</h5>
                              </div>
                              <h6 style={{ minWidth: "7rem" }}>
                                {CurrencyCode()}{" "}
                                {singlePaxFare(
                                  paxFare[0],
                                  searchValues?.adultsCount,
                                  ~~fare?.["ns1:perPaxBundledFee"]
                                )}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-end my-2">
                              <div className="d-flex">
                                <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                <h5>* {searchValues?.childCount}</h5>
                              </div>
                              <h6 style={{ minWidth: "7rem" }}>
                                {CurrencyCode()}{" "}
                                {singlePaxFare(
                                  paxFare[1],
                                  searchValues?.childCount,
                                  ~~fare?.["ns1:perPaxBundledFee"]
                                )}
                              </h6>
                            </div>
                            <div className="d-flex justify-content-between align-items-end">
                              <div className="d-flex">
                                <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                <h5>* {searchValues?.infantNo}</h5>
                              </div>
                              <h6 style={{ minWidth: "7rem" }}>
                                {CurrencyCode()}{" "}
                                {singlePaxFare(
                                  searchValues?.infantNo !== 0
                                    ? paxFare[paxFare?.length - 1]
                                    : {},
                                  searchValues?.infantNo,
                                  0
                                )}
                              </h6>
                            </div>
                          </div>
                        }
                        placement="top"
                      >
                        {loading ? (
                          <div className="button_style px-4">
                            <BtnLoader />
                          </div>
                        ) : (
                          <button
                            type="button"
                            className="button_style px-4"
                            onClick={async () => {
                              //   const getPaxData = (paxType) => {
                              //     let breakdown = Array.isArray(fareBreakdowns)
                              //       ? fareBreakdowns
                              //       : [fareBreakdowns];
                              //     return breakdown?.find(
                              //       (pax) =>
                              //         pax?.["ns1:PassengerTypeQuantity"]?.$
                              //           ?.Code === paxType
                              //     );
                              //   };
                              const getAirportInfo = async (airportCode) => {
                                const { data } = await NODEURL.get(
                                  `/api/v1/b2b/airport-list/${airportCode}`
                                );
                                const { code, city, airport_name } = data?.data;
                                return `${code} - ${airport_name} - ${city}`;
                              };

                              if (boundToggle === "inBound") {
                                setIsLoading(true);
                                const data = await GetFlyJinnahFaresDetails(
                                  outBoundBundleId,
                                  TransactionIdentifier,
                                  fare["ns1:bunldedServiceId"]
                                );
                                const breakDown =
                                  data?.["ns1:OTA_AirPriceRS"]?.[
                                    "ns1:PricedItineraries"
                                  ]?.["ns1:PricedItinerary"]?.[
                                    "ns1:AirItineraryPricingInfo"
                                  ]?.["ns1:PTC_FareBreakdowns"]?.[
                                    "ns1:PTC_FareBreakdown"
                                  ];
                                const getPaxData = (paxType) => {
                                  let breakdown = Array.isArray(breakDown)
                                    ? breakDown
                                    : [breakDown];
                                  return breakdown?.find(
                                    (pax) =>
                                      pax?.["ns1:PassengerTypeQuantity"]?.$
                                        ?.Code === paxType
                                  );
                                };
                                //   updateFareData({inboundFare:fare})

                                const ADLT_FARE = getPaxData("ADT");
                                const CHLD_FARE = getPaxData("CHD");
                                const INFT_FARE = getPaxData("INF");
                                const farePrice =
                                  ~~fare?.["ns1:perPaxBundledFee"] *
                                  (searchValues?.adultsCount +
                                    searchValues?.childCount);
                                const lowestFare = lowestTotalFare(farePrice);

                                const segmentss = await Promise.all(
                                  flyjinnahItem?.itenaries.flatMap(
                                    (item, index) =>
                                      item.flightSegments.map(
                                        async (segment, ind) => {
                                          const locations = await Promise.all([
                                            getAirportInfo(
                                              segment.origin.airportCode
                                            ),
                                            getAirportInfo(
                                              segment.destination.airportCode
                                            ),
                                          ]);
                                          const segIndex =
                                            index === 0
                                              ? ind
                                              : flyjinnahItem?.itenaries[0]
                                                  ?.flightSegments?.length +
                                                ind;
                                          return {
                                            segments:
                                              segments?.[
                                                "ns1:OriginDestinationOption"
                                              ]?.[segIndex],
                                            trip_type:
                                              index === 0
                                                ? "outbound"
                                                : "inbound",
                                            selectedFare: item.cabinPrices[0], // Adjust based on your logic
                                            departure_date: moment(
                                              segment.departureDateTimeLocal.split(
                                                "T"
                                              )[0]
                                            ).format("DD-MMM-YY"),
                                            rph: segments?.[
                                              "ns1:OriginDestinationOption"
                                            ][segIndex]?.["ns1:FlightSegment"]
                                              ?.$?.RPH,
                                            baggage:
                                              baggage[
                                                fare?.["ns1:bundledServiceName"]
                                              ],
                                            departure_time:
                                              segment.departureDateTimeLocal
                                                .split("T")[1]
                                                ?.slice(0, 5),
                                            arrival_date: moment(
                                              segment.arrivalDateTimeLocal.split(
                                                "T"
                                              )[0]
                                            ).format("DD-MMM-YY"),
                                            arrival_time:
                                              segment.arrivalDateTimeLocal
                                                .split("T")[1]
                                                ?.slice(0, 5),
                                            origin_terminal:
                                              segment.origin.terminal || "",
                                            desti_terminal:
                                              segment.destination.terminal ||
                                              "",
                                            flight_number:
                                              segment.flightNumber?.slice(2),
                                            number_in_party: "", // Populate based on your data
                                            res_book_desig_code:
                                              item.cabinPrices[0]
                                                ?.fareOndWiseBookingClassCodes[
                                                segment.segmentCode
                                              ] || "",
                                            status: "NN",
                                            des_location: locations[1],
                                            dest_code:
                                              segment.destination.airportCode,
                                            marketing_airline:
                                              segment.flightNumber?.slice(0, 2),
                                            orig_location: locations[0],
                                            orig_code:
                                              segment.origin.airportCode,
                                            marriage_grp: "",
                                            cabin_class:fare?.["ns1:bundledServiceName"],
                                            segment_type: getSectorsName(
                                              segment.origin.airportCode,
                                              segment.destination.airportCode
                                            ),
                                          };
                                        }
                                      )
                                  )
                                );

                                dispatch(
                                  setselectedFlight({
                                    flight: flyjinnahItem,
                                    outboundFare: fareData,
                                    inboundFare: fare,
                                    price: TwoDecimalPoint(lowestFare),
                                    ttl_agent_fare: TwoDecimalPoint(
                                      CurrencyExchangeToPKR(
                                        Number(
                                          lowestFare?.split(" ")?.at(1) || 0
                                        )
                                      )
                                    ),
                                    ttlagent_curr_fare: Number(
                                      lowestFare?.split(" ")?.at(1) || 0
                                    ),
                                    ttl_supplier_fare: 0,
                                    ttlsup_curr_fare: TwoDecimalPoint(
                                      CurrencyExchange(
                                        fare?.["ns1:PassengerFare"]?.[
                                          "ns1:TotalFare"
                                        ]?.$?.Amount + farePrice
                                      )
                                    ),
                                    adultTaxes: TwoDecimalPoint(
                                      getPaxFare(ADLT_FARE)?.taxes
                                    ),
                                    childTaxes: TwoDecimalPoint(
                                      getPaxFare(CHLD_FARE)?.taxes
                                    ),
                                    infantTaxes: TwoDecimalPoint(
                                      getPaxFare(INFT_FARE)?.taxes
                                    ),

                                    adultBaseFare:
                                      getPaxFare(ADLT_FARE)?.basicFare,
                                    childBaseFare:
                                      getPaxFare(CHLD_FARE)?.basicFare,
                                    infantBaseFare:
                                      getPaxFare(INFT_FARE)?.basicFare,

                                    // adultBaseFare: CurrencyExchangeToPKR(singlePaxFare(paxFare[0], searchValues?.adultsCount, ~~fare?.["ns1:perPaxBundledFee"])),
                                    // childBaseFare: CurrencyExchangeToPKR(singlePaxFare(paxFare[1] || {}, searchValues?.childCount, ~~fare?.["ns1:perPaxBundledFee"])),
                                    // infantBaseFare: CurrencyExchangeToPKR(singlePaxFare(searchValues?.infantNo !== 0 ? paxFare[paxFare?.length - 1] : {}, searchValues?.infantNo, 0)),

                                    ADLT_FARE,
                                    CHLD_FARE,
                                    INFT_FARE,

                                    ADLT_TAXES: getPaxTaxes(ADLT_FARE),
                                    CHLD_TAXES: getPaxTaxes(CHLD_FARE),
                                    INFT_TAXES: getPaxTaxes(INFT_FARE),

                                    adlt_ttl_d_curr: CurrencyExchangeToPKR(
                                      singlePaxFare(
                                        paxFare[0],
                                        searchValues?.adultsCount,
                                        ~~fare?.["ns1:perPaxBundledFee"]
                                      )
                                    ),
                                    chld_ttl_d_curr: CurrencyExchangeToPKR(
                                      singlePaxFare(
                                        paxFare[1] || {},
                                        searchValues?.childCount,
                                        ~~fare?.["ns1:perPaxBundledFee"]
                                      )
                                    ),
                                    inft_ttl_d_curr: CurrencyExchangeToPKR(
                                      singlePaxFare(
                                        searchValues?.infantNo !== 0
                                          ? paxFare[paxFare?.length - 1]
                                          : {},
                                        searchValues?.infantNo,
                                        0
                                      )
                                    ),

                                    flightNumber: lastSegment[0].flightNumber
                                      ?.replace(/9P/g, "")
                                      ?.replace(/G9/g, ""),
                                    flightDuration: timeDuration(
                                      lastSegment[0]?.departureDateTimeLocal,
                                      lastSegment[0]?.arrivalDateTimeLocal
                                    ),
                                    arrTime: lastSegment[0].arrivalDateTimeLocal
                                      ?.split("T")[1]
                                      ?.slice(0, 5),
                                    arrDate: moment(
                                      new Date(
                                        lastSegment[0].arrivalDateTimeLocal.split(
                                          "T"
                                        )[0]
                                      )
                                    ).format("DD-MMM-YY"),
                                    depDate: moment(
                                      new Date(
                                        lastSegment[0].departureDateTimeLocal.split(
                                          "T"
                                        )[0]
                                      )
                                    ).format("DD-MMM-YY"),
                                    rph: rpH,
                                    depTime:
                                      lastSegment[0].departureDateTimeLocal
                                        ?.split("T")[1]
                                        ?.slice(0, 5),
                                    agent_name:
                                      flyjinnahItem?.itenaries?.[0]?.agent_name,

                                    transaction_identifier: data?.["ns1:OTA_AirPriceRS"]?.$?.TransactionIdentifier,
                                    cookie: cookie,
                                    destination:
                                      lastSegment[0].origin?.airportCode,
                                    origin:
                                      lastSegment[0].destination?.airportCode,
                                    // token_info: token,
                                    baggageName:
                                      fare?.["ns1:bundledServiceName"],
                                    logo: "FlyJinnahR",
                                    code: "9P",
                                    bundleIdOutbound: outBoundBundleId,
                                    bundleIdInbound:
                                      fare["ns1:bunldedServiceId"],
                                    booking: {
                                      gds: "FLYJINNAH", // FlyJinnah GDS code
                                      pnr: "",
                                      agent_name:
                                        flyjinnahItem?.itenaries?.[0]
                                          ?.agent_name,
                                      seg_r: "Return",
                                      segments: segmentss,
                                      rph: rpH,
                                      tp_ur_code: "",
                                      platting_carrier: "9P",
                                      tp_ssr_verssion: 0,
                                      tp_trace_id: "",
                                      fare_req_data: fare_req_data,
                                      TransactionIdentifier: data?.["ns1:OTA_AirPriceRS"]?.$?.TransactionIdentifier,
                                    },
                                    // })
                                  })
                                );
                                navigate("/check-out");
                              } else {
                                setIsLoading(true);
                                setOutBoundBundleId(
                                  fare["ns1:bunldedServiceId"]
                                );
                                await GetFlyJinnahFaresDetails(
                                  fare["ns1:bunldedServiceId"],
                                  TransactionIdentifier
                                );
                                setIsLoading(false);
                                setBoundToggle("inBound");
                                setFareData(fare);
                              }

                              //   dispatch(setExpandFlightDetails(null));
                            }}
                          >
                            {flyJinnahFares
                              ? lowestTotalFare(
                                  ~~fare?.["ns1:perPaxBundledFee"] *
                                    (searchValues?.adultsCount +
                                      searchValues?.childCount)
                                )
                              : CurrencyCode() +
                                " " +
                                flyjinnahItem?.cabinPrices[0]?.price +
                                ~~fare?.["ns1:perPaxBundledFee"]}
                          </button>
                        )}
                      </LightTooltip>
                    </div>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
      <div>
        {/* {pathname === "/check-out" && (
          <div>
            <Ancillary />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default FlyJinnahFares;
