import React from "react";
import { useNavigate } from "react-router-dom";

const Booking = ({ bg_color, title, img, counter, preview }) => {
  const navigate = useNavigate();

  return (
    <div className="px-md-2">
      <div
        className={`custom-card ${bg_color}`}
        style={{ background: `${bg_color}` }}
      >
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h4 className="card-title text-white card_title">{title}</h4>
            <h5 className="text-white h4 mt-3">{counter?.totalCount}</h5>
          </div>
          <div className="text-right">
            {/* <span className="d-flex gap-4 align-items-center">
              <b className="text-white mt-2">{counter?.flightGroup?.title}</b>
              <span className="text-white text-center mt-2">
                {counter?.flightGroup?.flight_group}
              </span>
            </span> */}
            <span className="d-flex gap-4 align-items-center">
              <b className="text-white text-center mt-2">{counter?.flights?.title}</b>
              <span className="text-white text-center mt-2">{counter?.flights?.flight}</span>
            </span>
          </div>
        </div>
        {preview && (
          <div
            className="text-white text-center mt-3"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/booking")}

          >
            Preview All
          </div>
        )}
      </div>
    </div>
  );
};

export default Booking;
