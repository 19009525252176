import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, HTTPForm } from "../ApiConfig";

const initialState = {
  // login get data
  loginGetStateData: [],
  // login data
  loginEditStateData: [],

  loading: false,
  error: null,
};

// get login data
export const asyncGetLoginData = createAsyncThunk(
  "asyncEditLoginData/get",
  async (_, { rejectWithValue }) => {
    // try {
    //   const response = await HTTP.get("/api/v1/b2b/user");
    //   return await response?.data?.data;
    // } catch (error) {
    //   console.log(error);
    //   rejectWithValue(error);
    // }
  }
);

// edit login data
export const asyncEditLoginData = createAsyncThunk(
  "asyncEditLoginData/edit",
  async (_, { rejectWithValue }) => {
    // try {
    //   const response = await HTTP.get("/api/v1/b2b/home_promotion/user_profile_edit");
    //   return await response?.data?.data;
    // } catch (error) {
    //   console.log(error);
    //   rejectWithValue(error);
    // }
  }
);

// posting login data
export const asyncUpdateLoginData = createAsyncThunk(
  "asyncPostLoginData/post",
  async ({ id, obj }, { rejectWithValue }) => {
    // try {
    //   const response = await HTTP.put(
    //     `/api/v1/b2b/user/user-update/${id}`,
    //     obj
    //   );
    //   // return await response?.data
    // } catch (error) {
    //   console.log(error);
    //   rejectWithValue(error);
    // }
  }
);

export const userLoginSlice = createSlice({
  name: "userloginSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get
    builder.addCase(asyncGetLoginData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetLoginData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.loginGetStateData = payload;
    });
    builder.addCase(asyncGetLoginData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //edit
    builder.addCase(asyncEditLoginData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditLoginData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.loginEditStateData = payload;
    });
    builder.addCase(asyncEditLoginData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting
    builder.addCase(asyncUpdateLoginData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateLoginData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.loginStateData = payload
    });
    builder.addCase(asyncUpdateLoginData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export default userLoginSlice.reducer;
