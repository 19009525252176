import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  landingPageData: [],
  loading: false,
  error: null,
  aboutUsData: [],
};

export const asyncGetLandingPageData = createAsyncThunk(
  "asyncGetLandingPageData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/homePage");
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//About us

export const asyncGetLandingAboutPage = createAsyncThunk(
  "asyncGetLandingAboutPage/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/aboutus-view");
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const landingPageSlice = createSlice({
  name: "landingPageSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncGetLandingPageData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetLandingPageData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.landingPageData = payload;
    });
    builder.addCase(asyncGetLandingPageData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    builder.addCase(asyncGetLandingAboutPage.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetLandingAboutPage.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.aboutUsData = payload;
      }
    );
    builder.addCase(asyncGetLandingAboutPage.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export default landingPageSlice.reducer;
