import React from 'react'
import OurServices from '../components/Agency/homeviewsittings/OurServices'

const OurServicesPage = () => {
  return (
    <div>
      <OurServices/>
    </div>
  )
}

export default OurServicesPage