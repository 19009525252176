import React, { useEffect, useState } from "react";
import "../Styles/manualInvoices.css";
import CustomDropdown from "../components/CustomDrp";
import ReactDatePicker from "react-datepicker";
import { Button, TextField } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Input, Select, MenuItem } from "@mui/material";
import { InputAdornment } from "@mui/material";
import { IconButton } from "@mui/material";
import { MdClose } from "react-icons/md";
import { ClickAwayListener } from "@mui/material";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";
import { useDispatch, useSelector } from "react-redux";
import { asyncSearchAgencyData } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

function VisaReport() {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [saleType, setSaleType] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const [agent, setAgent] = useState("");
  const { searchAgency } = useSelector((state) => state.newAgency);

  const [drp, setdrp] = useState(false);
  const [supplierName, setSupplierName] = useState([]);
  const [supValue, setSupValue] = useState("");

  const { newSupplier } = useSelector((state) => state.supplier);
  const suppArray = newSupplier?.map((sup) => sup?.supp_name);

  useEffect(() => {
    dispatch(asyncGetNewSupplier());
  }, []);

  const arry = [
    { id: 0, type: "All" },
    { id: 1, type: "Flight" },
    { id: 2, type: "Visa" },
    { id: 3, type: "Hotel" },
    { id: 4, type: "Insurance" },
    { id: 5, type: "Transfer" },
    { id: 6, type: "Activity" },
    { id: 7, type: "Umrah" },
  ];

  return (
    <div className="">
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">
            Visa Report (Date Wise / Visa Country / Agent | Office | Customer /
            Supplier / Status / Report Format )
          </div>
        </div>
        <div className="pt-5 px-2 px-md-5">
          <div className="row align-items-center">
            <div className=" col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={fromDate}
                onChange={(date) => {
                  setFromDate(date);
                }}
                maxDate={new Date()}
                className="date_picker "
              />
            </div>
            <div className=" col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={toDate}
                onChange={(date) => {
                  setToDate(date);
                }}
                maxDate={new Date()}
                className="date_picker "
              />
            </div>
          </div>
          <div className="row align-items-center mt-5">
            <div className="col-6">
              <FormControl variant="standard" className="w-100" sx={{}}>
                <InputLabel id="demo-simple-select-standard-label">
                  Sales Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name={saleType}
                  value={saleType}
                  onChange={(e) => setSaleType(e.target.value)}
                  label="Sales Type"
                >
                  {arry.map((list) => (
                    <MenuItem
                      value={list?.type}
                      sx={{ bgcolor: "#fff" }}
                      key={list?.id}
                    >
                      {list?.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-6">
              <CustomDropdown
                arry={["All", "Issued", "Pending"]}
                placehlder="Select Status"
                initvalue={status}
                name={status}
                setfunction={setStatus}
              />
            </div>
          </div>

          <div className="row align-items-center mt-5">
            <div className="col-6">
              {/* <TextField id="standard-basic" label="Select Supplier" variant="standard" className="w-100" /> */}
              <FormControl
                variant="standard"
                className="w-100"
                onClick={() => {
                  setdrp(!drp);
                }}
              >
                <InputLabel htmlFor="supplier">Select Supplier</InputLabel>
                <Input
                  autoComplete="off"
                  id="supplier"
                  className="w-100"
                  value={supValue}
                  name={supValue}
                  onChange={(e) => {
                    let value = e.target.value;
                    setSupValue(value);

                    let filterSupplier = value
                      ? suppArray?.filter((item) =>
                          item?.toLowerCase().includes(value.toLowerCase())
                        )
                      : suppArray;
                    setSupplierName([...filterSupplier]);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {supValue !== "" && (
                        <IconButton
                          onClick={() => {
                            setSupValue("");
                          }}
                        >
                          <MdClose />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>
              {drp && (
                <ClickAwayListener onClickAway={() => setdrp(!drp)}>
                  <div className="supplier_dropdown">
                    {supplierName?.length > 0 ? (
                      supplierName?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="supplier_style"
                            onClick={() => {
                              let value = `${item}`;
                              setSupValue(value);
                              setdrp(!drp);
                            }}
                          >
                            <div className="fs-6 supplier_captilize">
                              {item.toLowerCase()}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="supplier_style">No Record Found.</div>
                    )}
                  </div>
                </ClickAwayListener>
              )}
            </div>
            <div className="col-6" style={{ zIndex: 10 }}>
              {/* <TextField id="standard-basic" label="Select Agent" variant="standard" className="w-100" /> */}
              <FormControl
                variant="standard"
                className="w-100"
                onClick={() => setagentDRopDwn(!agentDRopDwn)}
              >
                <InputLabel htmlFor="supplier">Select Agent</InputLabel>
                <Input
                  variant="standard"
                  label="Select Agent"
                  autoComplete="off"
                  className="w-100"
                  name="agent"
                  value={agent}
                  onChange={(e) => {
                    let value = e.target.value;
                    setAgent(value);
                    dispatch(asyncSearchAgencyData(value));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {agent !== "" && (
                        <IconButton
                          onClick={() => {
                            setAgent("");
                          }}
                        >
                          <MdClose />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>

              {agentDRopDwn ? (
                <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
                  <div className="agent_dropdown">
                    {searchAgency?.length > 0 ? (
                      searchAgency?.map((item, index) => {
                        return (
                          <div
                            className="p-3"
                            onClick={(e) => {
                              setAgent(item.agent_name);
                              setagentDRopDwn(false);
                            }}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            {item.agent_name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="p-3">No record available.</div>
                    )}
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          </div>
        </div>
        <div className="mx-2 mx-md-5 pt-5">
          {/* radio buttons */}
          <div className="row align-items-start ">
            <div className="col-6 col-md-3">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">Booking Status</span>
              </label>
            </div>
            <div className="col-6 col-md-3">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">Document Status</span>
              </label>
            </div>

            <div className="col-6 col-md-2">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">PDF</span>
              </label>
            </div>
            <div className="col-6 col-md-3">
              <label class="form-control">
                <input type="radio" name="radio" />
                <span className="h6">EXCEL</span>
              </label>
            </div>
          </div>
        </div>

        {/* generat report button */}
        <div className="container py-4">
          <div className="d-flex justify-content-center align-items-center my-3 ">
            <button className="button_style">Generate Report</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VisaReport;
