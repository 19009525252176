import moment from 'moment';
import React from 'react'
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from 'react-icons/md';

const AirsialSegmentsDetails = ({ segements, formatDuration, boundToggle }) => {
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;
    const getCity = (loc) => {
        let city = loc?.split("-")[2]?.toUpperCase();
        return city;
    };
    return (
        <div>
            <div className="d-flex align-items-center py-4">
                <div className="col-2">
                    <div className="">
                        <div className="text-center">
                            <img
                                src={"/NEW_LOGO/PF.png"}
                                alt="airsial"
                                width={90}
                                height={90}
                            />
                            <div className="pt-3">
                                <span>{segements?.FLIGHT_NO}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightTakeoff size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(segements?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    <h6 className="my-3">{segements?.DEPARTURE_TIME}</h6>
                                </h6>
                                <h6>
                                    {segements?.ORGN} -{" "}
                                    {getCity(boundToggle === "outBound" ? locDeparture : locArrival)}
                                </h6>
                            </div>
                        </div>

                        <div>
                            <h6 className="text-center">
                                {formatDuration(segements?.DURATION)}
                            </h6>
                            <div className="border_rounded mb-2" />
                            <h6 className="mx-3">
                                Non-Stop
                            </h6>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightLand size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(segements?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    <h6 className="my-3">{segements?.ARRIVAL_TIME}</h6>
                                </h6>
                                <h6>
                                    {segements?.DEST} -{" "}
                                    {getCity(boundToggle === "outBound" ? locArrival : locDeparture)}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirsialSegmentsDetails
