import React from "react";
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from "react-icons/md";
import moment from "moment";

const MultiAmadeusIteneries = ({ segments, index, timeDuration }) => {
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const multiDepartureSectors = searchValues?.multi_des_from;
  const multiArrivalSectors = searchValues?.multi_des_to;

  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  const getDepartureLocation = (index) => {
    if (index === 0) {
      return locDeparture;
    } else {
      return multiDepartureSectors[index - 1];
    }
  };

  const getArrivalLocation = (index) => {
    if (index === 0) {
      return locArrival;
    } else {
      return multiArrivalSectors[index - 1];
    }
  };

  return (
    <div className="row align-items-center ">
      <div className="col-3 my-4">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={`/NEW_LOGO/${segments[0]?.carrierCode}.png`}
            alt="logo"
            width={90}
            height={90}
          />
          <div className="pt-3" style={{ whiteSpace: "nowrap" }}>
            {segments?.map((it, idx, arr) => (
              <span className="pe-1" key={idx}>{`${it?.carrierCode} ${
                it?.number
              } ${idx < arr.length - 1 ? " -" : ""}`}</span>
            ))}
          </div>
        </div>
      </div>
      <div className="col-9">
        <div className="d-flex justify-content-evenly align-items-center">
          <div className="d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(segments[0]?.departure?.at?.split("T")[0]).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>
              <h6 className="my-3">
                {moment(
                  segments[0]?.departure?.at?.split("T")[1],
                  "HH:mm:ss"
                ).format("HH:mm")}
              </h6>
              <h6>
                {segments[0]?.departure?.iataCode} -{" "}
                {getCity(getDepartureLocation(index))}
              </h6>
            </div>
          </div>

          <div>
            <h6 className="text-center">
              {timeDuration(
                segments[0]?.departure?.at,
                segments[segments?.length - 1]?.arrival?.at
              )}
            </h6>
            <div className="border_rounded mb-2" />
            <h6 className="mx-3">
              {segments?.length === 1
                ? "Non-Stop"
                : segments?.length === 2
                ? "One-Stop"
                : segments?.length === 3
                ? "Two-Stop"
                : segments?.length === 4
                ? "Three Stop"
                : "Four Stop"}
            </h6>

            {segments?.length > 1 && (
              <h6 className="text-center">
                {segments
                  ?.slice(1)
                  ?.map((seg) => seg?.departure?.iataCode)
                  ?.join(", ")}
              </h6>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(
                  segments[segments?.length - 1]?.arrival?.at?.split("T")[0]
                ).format("ddd DD-MMM-YY")}
              </h6>
              <h6 className="my-3">
                {moment(
                  segments[segments?.length - 1]?.arrival?.at?.split("T")[1],
                  "HH:mm:ss"
                ).format("HH:mm")}
              </h6>
              <h6>
                {segments[segments?.length - 1]?.arrival?.iataCode} -{" "}
                {getCity(getArrivalLocation(index))}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiAmadeusIteneries;
