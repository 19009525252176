import React from 'react'
import Header from '../components/Common/Header'
import UmrahTable from '../components/UmrahRequests/UmrahTable'
import Footer from '../components/Footer'

const Umrah = () => {
  return (
    <div className=''>
      <UmrahTable />
    </div>
  )
}

export default Umrah