export const options = {
  filter: false,
  Selection: false,
  print: false,
  download: false,
  sort: false,
  viewColumns: false,
  displayRowCheckbox: false,
  selectableRows: "none",
  enableNestedDataAccess: ".",
  // responsive: "scrollMaxHeight",
  rowsPerPage: 100,
};
