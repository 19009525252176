import React, { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../../DeleteModal";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl} from "../../../Utils/configUrl";
import {
  asyncGetCircularData,
  asyncPostCircularData,
  asyncEditCircularData,
  asyncUpdateCircularData,
  asyncdeleteCircularData,
  toggleModel,
} from "../../../redux/features/promotion/CircularSlice";

import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";

const SUPPORTED_FORMATS = ["image/png"];





const Circular = () => {
  const [updateRecord, setUpdateRecord] = useState({});
  const [modalData, setModalData] = useState([]);
  const [show, setShow] = useState(false);
  const [imagevalidation, setImageValidation] = useState(false);
  const [keyVal, setkeyVal] = useState();

  const dispatch = useDispatch();

  const { circularData, loading, editCircular } = useSelector(
    (state) => state.circular
  );

//Yup 
const validationSchema = yup.object().shape({
  icon: editCircular
    ? yup
        .mixed()
        .nullable()
        .test(
          "format",
          "Only png logo are accepted",
          (value) =>
            !value || (value && SUPPORTED_FORMATS.includes(value.type))
        )
    : yup
        .mixed()
        .nullable()
        .required("Image is required")
        .test(
          "format",
          "Only png logo are accepted",
          (value) =>
            !value || (value && SUPPORTED_FORMATS.includes(value.type))
        ),
  // attachment: editCircular
  //   ? yup
  //       .mixed()
  //       .nullable()
  //       .test(
  //         "format",
  //         "Only pdf are accepted",
  //         (value) =>
  //           !value || (value && SUPPORTED_FORMATS.includes(value.type))
  //       )
  //   : yup
  //       .mixed()
  //       .nullable()
  //       .required("Pdf is required")
  //       .test(
  //         "format",
  //         "Only Pdf are accepted",
  //         (value) =>
  //           !value || (value && SUPPORTED_FORMATS.includes(value.type))
  //       ),
        description: yup.string().required("Description Required"),
        // attachment: yup.string().required("Slogan Required"),
})


  useEffect(() => {
    // setkeyVal(Date.now());
    dispatch(asyncGetCircularData());
  }, []);
// editCircular?.id
  const onSubmit = (values, { resetForm }) => {
    
    
    const formData = new FormData();
    
    formData.append("icon", values.icon);
    formData.append("attachment",  values.attachment);
    formData.append("description", values.description);
    // dispatch(asyncPostCircularData(formData));



    // if (values.cancelable == true) {
    //   return;
    // }
    // if (!logo || !pdf || !values.description) {
    //   setError(true);
    //   setImageValidation(false);
    //   return;
    // }

    // if (values.icon.name.split(".")[1] !== "png") {
    //   setImageValidation(true);
    //   setError(false);
    // } else {
    //   setImageValidation(false);
    //   setError(false);

    //   if (editCircular?.id) {
    //     dispatch(asyncUpdateCircularData({ id: editCircular?.id, formData }));
    //     setkeyVal(Date.now());
    //     resetForm();
    //   } else {
    //     dispatch(asyncPostCircularData(formData));
    //     // for empty the field values 
    //     setkeyVal(Date.now());
    //     resetForm();
    //   }
    // }

    if (editCircular?.id) {
          dispatch(asyncUpdateCircularData({ id: editCircular?.id, formData }));
          setkeyVal(Date.now());
          // setError(true);
          // resetForm();
        } else {
          dispatch(asyncPostCircularData(formData, resetForm));
          // for empty the field values 
          setkeyVal(Date.now());
          // setError(true);
          resetForm();
        }


  };

  const { values, setFieldValue,errors ,handleBlur, handleChange, handleSubmit, resetForm } =
    useFormik({
      enableReinitialize: true,
      validationSchema:validationSchema,
      initialValues: {
        icon:editCircular?.icon || "",
        attachment:editCircular?.attachment || "",
        description: editCircular?.description || "",
      },
      onSubmit
    });

  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Airline Logo",
      name: "icon",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img src={`${apiUrl}/${value}`} className="table-row-image" alt="img"/>
          );
        },
      },
    },
    {
      label: "PDF File",
      name: "attachment",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a
              href={`${apiUrl}/${value}`}
              target="_blank"
              className="table-anchor"
              
            >
              {value}
            </a>
          );
        },
      },
    },
    {
      name: "description",
      label: "Remarks",
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                type="button"
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  window.scroll({ top: 0, behavior: "smooth" });
                  dispatch(asyncEditCircularData(tableMeta.rowData[0]))
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                type="button"
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShow(true);
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];


  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Circular</p>
      <form
        onSubmit={handleSubmit}
        className="col-12 px-4 d-flex flex-column justify-content-center"
      >
        {updateRecord.airline_logo && <div className="col-12 row px-5">
          <div className="col-12 col-sm-6 d-flex flex-column mt-5"><label>Previouse AirLine Logo</label><img src={updateRecord.airline_logo} className="update-airline-logo mt-3"/></div>
          <div className="col-12 col-sm-6 d-flex flex-column mt-5">
          <label>Previouse PDF File</label>
          <a href={updateRecord.pdf_file.link} target="_blank" className="update-airline-logo mt-3">{updateRecord.pdf_file.name}</a>
          </div>
      </div>}
        <div className="col-12 row px-5">
          <div className="col-12 col-sm-6 d-flex flex-column mt-5">
            <label htmlFor="" className="mb-2">
              Attach Logo <span className="text-danger">(size 70*70)</span>
            </label>
            <TextField
              type={"file"}
              variant="standard"
              className="col-12"
              key={keyVal || ""}
              name="icon"
              onChange={(e) =>
                setFieldValue("icon", e.currentTarget.files[0])
              }
            />
            {errors?.attachment && <p className="text-danger">Icon required</p>}
            {imagevalidation && (
              <p className="text-danger">Only icon required</p>
            )}
          </div>
          <div className="col-12 col-sm-6 d-flex flex-column mt-5">
            <label htmlFor="" className="mb-2">
              Attach File <span className="text-danger">(PDF only)</span>
            </label>
            <TextField
              type={"file"}
              variant="standard"
              key={keyVal || ""}
              className="col-12"
              name='attachment'
              onChange={(e) =>
                setFieldValue("attachment", e.currentTarget.files[0])
              }
            />
            {errors?.icon && <p className="text-danger">pdf required</p>}
            {/* {imagevalidation && <p className="text-danger">Only pdf file required</p>} */}
          </div>
        </div>
        <div className="col-12 px-5">
          <div className="col-12 d-flex flex-column mt-5 px-2">
            {/* {updateRecord.remarks ?  */}
            <TextField
              type="text"
              id="standard-basic"
              label="Remarks (75 characters only)"
              variant="standard"
              className="col-12 mt-5"
              autoComplete="off"
              name="description"
              inputProps={{ maxLength: 75 }}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              // error={touched.remark && errors.remark ? 1 : 0}
              // helperText={touched.remark && errors.remark ? errors.remark : ""}
            />
            {errors?.description && <p className="text-danger">remarks required</p>}
            {/* <p style={{ color: "red" }}>
                                        {errors?.partnerValue &&
                                          errors?.partnerValue[index]?.image}
                                      </p> */}
            {/* :  */}
            {/* <TextField
            error={touched.remarks && errors.remarks ? 1 : 0}
              id="standard-basic"
              label="Remarks (75 characters only)"
              variant="standard"
              className="col-12 my-5"
              name="remarks"
              inputProps={{ maxLength: 75 }}
              // onChange={(e)=> setRemarks(e.target.value)}
              onChange={handleChange}
              value={values.remarks}
              onBlur={handleBlur}
              helperText={touched.remarks && errors.remarks ? errors.remarks : ""}

            /> */}
            {/* } */}
          </div>
        </div>

        <div className="col-md-12 text-center mt-5">
          <button
            className="btn setup-btn"
            onClick={onSubmit}
            type="submit"
            disabled={loading ? true : false}
          >
            {loading ? <BtnLoader /> : editCircular?.id ? "Update" : "Save"}
          </button>
        </div>

        {/* {updateRecord.remarks
         ?
        <button className="btn setup-btn">Update</button>
        :
        <button 
        onClick={onSubmit}
        type="submit"
        className="btn setup-btn"
        >
          save
        </button>} */}
      </form>
      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={show}
          rowId={modalData && modalData[0]}
          setShow={setShow}
          onClick={(e) => {
            dispatch(asyncdeleteCircularData(modalData[0]));
            setShow(false);
          }}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Circular Details"}
          data={circularData}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Circular;
