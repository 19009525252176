import moment from "moment";
import { useState, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const FlightGroupExcel = ({
  TotalSeats,
  totalSeat,
  pnr,
  flightGroup,
  grouptType,
}) => {
  const limitedArray = Array.from(
    { length: Math.min(totalSeat) },
    (_, index) => ({
      seats: index + 1,
      ...TotalSeats[index],
    })
  );

  return (
    <div className="col-11 mx-auto rounded-2 shadow-sm bg-white p-4">
      <h3 className="mt-3 text-success">
        <center>Export React Table Data into EXCEL Sheet</center>
      </h3>
      <div className="row mt-4 col-12 mx-auto rounded-2 shadow-sm bg-white">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button btn btn-success mb-3"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText="Export Data to Excel Sheet"
        />

        <table className="table" id="table-to-xls">
          <thead className="thead-dark justify-center">
            <tr className="text-center">
              <td colspan="11" className="fs-3">
                Group Type / {grouptType}
              </td>
            </tr>
            <tr className="text-center">
              <td colspan="11"></td>
            </tr>
            {flightGroup?.map((flt, index) => (
              <tr className="text-center">
                <td colspan="11">
                  {moment(flt?.flt_dep_date, "DD-MM-YYYY").format(
                    "ddd DD-MMM-YY"
                  ) +
                    " " +
                    flt?.dep_time +
                    " " +
                    flt?.origin?.split("-")?.at(0) +
                    " " +
                    flt?.destination?.split("-")?.at(0)}
                </td>
              </tr>
            ))}

            <tr className="text-center">
              <td colspan="11">PNR: {pnr}</td>
            </tr>
            <tr className="text-center">
              <td colspan="11">
                Supplier:{" "}
                {limitedArray?.at(0)?.acc_supplier?.supplier_lists?.at(0)?.supp_name}
              </td>
            </tr>

            <tr className="text-center">
              <td colspan="11"></td>
            </tr>
            {/* <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>ok</th>
                <th>ok</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>dsdsd</td>
                <td>dsdsd</td>
                <td></td>
                <td></td>
                <td></td>
              </tr> */}
            <tr classNamer="text-center">
              <th>Seats</th>
              <th>Booking Id</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Passport No</th>
              <th>DOB</th>
              <th>Ticket No</th>
              <th>Supplier Pay</th>
              <th>Agent Pay</th>
              <th>Agent Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {limitedArray?.map((pax) => (
              <tr key={pax.seats}>
                <td>{pax?.seats}</td>
                <td>{pax.main_id}</td>
                <td>{pax.fName}</td>
                <td>{pax.lname}</td>
                <td>{pax.pspt_cnic}</td>
                <td>{pax?.dob?.split("T")?.at(0)}</td>
                <td>{pax?.tkt_number}</td>
                <td>{pax?.ttl_sup_pay_d_cur}</td>
                <td>{pax?.ttl_agt_pay_d_cur}</td>
                <td>{pax?.acc_agents?.agents_comp?.at(0)?.agent_name}</td>
                <td>{pax?.doc_status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default FlightGroupExcel;
