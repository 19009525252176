import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  user: null,
  CreditVoucherBooking: [],
  isLoading: false,
  error: null,
  voucherCredit:[],
  singleCreditNote:null
};

export const asyncPaxRefundData = createAsyncThunk(
  "asyncPaxRefundData/post",
  async (body, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/pax-table-refund", body);
      return await response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);


export const asyncUpadteCreditNote = createAsyncThunk(
  "asyncUpadteCreditNote/post",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/manual-credit/${id}`,
        body
      );
      return await response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getAsyncBookingDetailsForVocherDetailsCredit = createAsyncThunk(
  "getAsyncBookingDetailsForVocherDetailsCredit/get",
  async ({ id, tkt_voucher_no, voucher_id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/manual-credit/${id}?tkt_voucher_no=${tkt_voucher_no}&&voucher_id=${voucher_id}`
      );
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);


export const getAsyncSingleCreditNote = createAsyncThunk(
  "getAsyncSingleCreditNote/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/credit-note/${id}`);
      return await response.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const PaxRefundsSlice = createSlice({
  name: "PaxRefundsSlice",
  initialState,
  reducers: {
 setManualkInvoicCreditNoteNull:(state)=>{
      state.CreditVoucherBooking=null;
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(asyncPaxRefundData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncPaxRefundData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.voucherCredit = payload;
    });
    builder.addCase(asyncPaxRefundData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(
      getAsyncBookingDetailsForVocherDetailsCredit.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      getAsyncBookingDetailsForVocherDetailsCredit.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.CreditVoucherBooking = payload;
      }
    );
    builder.addCase(
      getAsyncBookingDetailsForVocherDetailsCredit.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      }
    );


 builder.addCase(getAsyncSingleCreditNote.pending, (state, { payload }) => {
   state.isLoading = true;
   state.error = null;
 });
 builder.addCase(getAsyncSingleCreditNote.fulfilled, (state, { payload }) => {
   state.isLoading = false;
   state.error = null;
   state.singleCreditNote = payload;
 });
 builder.addCase(getAsyncSingleCreditNote.rejected, (state, action) => {
   state.isLoading = false;
   state.error = action.payload;
 });

  },
});

export const { setManualkInvoicCreditNoteNull } = PaxRefundsSlice.actions;

export default PaxRefundsSlice.reducer;



