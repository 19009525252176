import React, { useState } from "react";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { useNavigate } from "react-router-dom";

function MobileMenu({ setshowMobileMenu }) {
  const [showDropDown, setshowDropDown] = useState("");
  const [showSubDropDown, setshowSubDropDown] = useState("");
  const [showTerDropDown, setshowTerDropDown] = useState("");
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="navigation_mobile_menu">
        <div className="d-flex justify-content-end align-items-end h-4">
          <CloseRoundedIcon onClick={(e) => setshowMobileMenu(false)} />
        </div>
        <div className="w-75">
          <img src="/zairaa.png" alt="logo" className="w-100" />
        </div>

        <div className="pt-4 mobile_navigation_links">
          <span
            onClick={(e) => {
              navigate("/");
            }}
          >
            Home
          </span>
          <span
            onClick={(e) => {
              navigate("/booking");
            }}
          >
            Booking
          </span>
          <span
            onClick={(e) => {
              navigate("/collection");
            }}
          >
            Collection
          </span>
          <span
            onClick={(e) => {
              navigate("/requests");
            }}
          >
            Requests
          </span>

          <span
            onClick={(e) => {
              navigate("/flight-group-list");
            }}
          >
            Groups
          </span>

          <span
            onClick={(e) => {
              let agency = document.getElementById("mobile_sub_menu_agency");
              let setup = document.getElementById("mobile_sub_menu_setup");
              let finance = document.getElementById("mobile_sub_menu_finance");
              agency.classList.remove("display_block");
              finance.classList.remove("display_block");

              if (setup.classList.contains("display_block")) {
                setup.classList.remove("display_block");
                setup.classList.add("display_none");
                setshowDropDown("");
              } else {
                setup.classList.add("display_block");
                setshowDropDown("setup");
              }
            }}
            className={`${showDropDown === "setup" ? "active_link" : ""}`}
          >
            <div>Setup</div>
            {showDropDown === "setup" ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </span>
          <div
            className="mobile_sub_menu_container display_none"
            id="mobile_sub_menu_setup"
          >
            <span
              onClick={(e) => {
                if (showSubDropDown === "payment") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("payment");
                }
              }}
              className={`${
                showSubDropDown === "payment" ? "active_link" : ""
              }`}
            >
              <div>Payments</div>
              {showSubDropDown === "payment" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "payment" && (
              <div className="mobile_sub_menu_container_link">
                <span
                  onClick={(e) => {
                    navigate("/make-payment");
                  }}
                >
                  Make Payment
                </span>
                <span
                  onClick={(e) => {
                    navigate("/corporate-payment");
                  }}
                >
                  Corporate Payment
                </span>
                <span
                  onClick={(e) => {
                    navigate("/paid-deposits");
                  }}
                >
                  Reciept
                </span>
                <span
                  onClick={(e) => {
                    navigate("/collection");
                  }}
                >
                  Collection
                </span>
                <span
                  onClick={(e) => {
                    setshowTerDropDown("paymentvoucher");
                  }}
                  className={`${
                    showTerDropDown === "paymentvoucher" ? "active_link" : ""
                  }`}
                >
                  <div>Payment Voucher</div>
                  {showTerDropDown === "paymentvoucher" ? (
                    <KeyboardArrowUpRoundedIcon />
                  ) : (
                    <KeyboardArrowDownRoundedIcon />
                  )}
                </span>
                {showTerDropDown === "paymentvoucher" && (
                  <div className="mobile_sub_menu_container_link">
                    <span onClick={(e) => navigate("/admin-vouchers")}>
                      Unpaid Voucher
                    </span>
                    <span onClick={(e) => navigate("/admin-paid-vouchers")}>
                      Paid Voucher
                    </span>
                  </div>
                )}
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "promotion") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("promotion");
                }
              }}
              className={`${
                showSubDropDown === "promotion" ? "active_link" : ""
              }`}
            >
              {" "}
              <div>Promotions</div>
              {showSubDropDown === "promotion" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "promotion" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/flash-news")}>
                  Add Flaish News
                </span>
                <span onClick={(e) => navigate("/flight-groups")}>
                  Flight Groups
                </span>
                <span onClick={(e) => navigate("/circular")}>Circular</span>
                <span onClick={(e) => navigate("/promotions")}>Promotions</span>
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "visa") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("visa");
                }
              }}
              className={`${showSubDropDown === "visa" ? "active_link" : ""}`}
            >
              <div>Visa</div>
              {showSubDropDown === "visa" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "visa" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/visa-countries")}>
                  Countries
                </span>
                <span onClick={(e) => navigate("/visa-type")}>Visa Type</span>
                <span onClick={(e) => navigate("/requirements")}>
                  Requirements
                </span>
                <span onClick={(e) => navigate("/visa-terms")}>
                  Terms & Conditions
                </span>
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "umrah") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("umrah");
                }
              }}
              className={`${showSubDropDown === "umrah" ? "active_link" : ""}`}
            >
              <div>Umrah</div>
              {showSubDropDown === "umrah" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "umrah" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/umrah-hotels")}>Hotel</span>
                <span onClick={(e) => navigate("/umrah-transfer")}>
                  Transfer
                </span>
                <span onClick={(e) => navigate("/umrah-visa-supplier")}>
                  Visa Supplier
                </span>
                <span onClick={(e) => navigate("/umrah-transfer-supplier")}>
                  Transfer Supplier
                </span>
                <span onClick={(e) => navigate("/room-type")}>
                  Hotel Room Type
                </span>
                <span onClick={(e) => navigate("/hotel-rates")}>
                  Umrah Hotel Rates
                </span>
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "newregistration") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("newregistration");
                }
              }}
              className={`${
                showSubDropDown === "newregistration" ? "active_link" : ""
              }`}
            >
              <div>New Registration</div>
              {showSubDropDown === "newregistration" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "newregistration" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/new-agency-registration")}>
                  New Agency Registration
                </span>
                <span onClick={(e) => navigate("/new-corporate-registration")}>
                  New Corporate Registration
                </span>
              </div>
            )}

            <span
              onClick={(e) => {
                navigate("/supplier");
              }}
            >
              Add New Supplier
            </span>

            <span
              onClick={(e) => {
                navigate("/agent-group");
              }}
            >
              Agents Group
            </span>
            <span
              onClick={(e) => {
                navigate("/agents");
              }}
            >
              Agent
            </span>
            <span
              onClick={(e) => {
                navigate("/bank-details");
              }}
            >
              Bank Details
            </span>
            <span
              onClick={(e) => {
                navigate("/currency");
              }}
            >
              Currency
            </span>

            <span
              onClick={(e) => {
                navigate("/pricing");
              }}
            >
              API Pricing
            </span>
            <span
              onClick={(e) => {
                navigate("/airlines");
              }}
            >
              API Airline
            </span>
            <span
              onClick={(e) => {
                navigate("/apis-credencial");
              }}
            >
              API Password
            </span>
            <span
              onClick={(e) => {
                navigate("/supplier-rules");
              }}
            >
              Suppliers Rules
            </span>
            <span
              onClick={(e) => {
                navigate("/resolved-requests");
              }}
            >
              Resolved Request
            </span>
          </div>

          <span
            onClick={(e) => {
              let agency = document.getElementById("mobile_sub_menu_agency");
              let setup = document.getElementById("mobile_sub_menu_setup");
              let finance = document.getElementById("mobile_sub_menu_finance");
              agency.classList.remove("display_block");
              setup.classList.remove("display_block");

              if (finance.classList.contains("display_block")) {
                finance.classList.remove("display_block");
                finance.classList.add("display_none");
                setshowDropDown("");
              } else {
                finance.classList.add("display_block");
                setshowDropDown("finance");
              }
            }}
            className={`${showDropDown === "finance" ? "active_link" : ""}`}
          >
            <div>Finance</div>
            {showDropDown === "finance" ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </span>

          <div
            className="mobile_sub_menu_container display_none"
            id="mobile_sub_menu_finance"
          >
            <span
              onClick={(e) => {
                if (showSubDropDown === "agents") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("agents");
                }
              }}
              className={`${showSubDropDown === "agents" ? "active_link" : ""}`}
            >
              <div>Agents</div>
              {showSubDropDown === "agents" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "agents" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/agent-list")}>Agent List</span>
                <span onClick={(e) => navigate("/agent-account-statement")}>
                  Agent Statement
                </span>
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "supplier") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("supplier");
                }
              }}
              className={`${
                showSubDropDown === "supplier" ? "active_link" : ""
              }`}
            >
              <div>Suppliers</div>
              {showSubDropDown === "supplier" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "supplier" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/supplier-account-statement")}>
                  Account Statement
                </span>
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "sale") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("sale");
                }
              }}
              className={`${showSubDropDown === "sale" ? "active_link" : ""}`}
            >
              <div>Sale</div>
              {showSubDropDown === "sale" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "sale" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/daily-sales-report")}>
                  Daily Sale Report
                </span>
                <span onClick={(e) => navigate("/saleS-report")}>
                  Sale Report
                </span>
                <span onClick={(e) => navigate("/refund-report")}>
                  Refund Report
                </span>
                <span onClick={(e) => navigate("/transaction-report")}>
                  Transactional Report
                </span>
                <span onClick={(e) => navigate("/visa-report")}>
                  Visa Report
                </span>
                <span onClick={(e) => navigate("/group-flight-manifest")}>
                  Group FLight Menifest
                </span>
                <span onClick={(e) => navigate("/airline-report")}>
                  Airline PLB
                </span>
                <span onClick={(e) => navigate("/")}>Agent error Finding</span>
                <span onClick={(e) => navigate("/")}>
                  Supplier error Finding
                </span>
                <span onClick={(e) => navigate("/umrah-manifest-view")}>
                  Umrah Menifest View
                </span>
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "voucher") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("voucher");
                }
              }}
              className={`${
                showSubDropDown === "voucher" ? "active_link" : ""
              }`}
            >
              <div>Vouchers</div>
              {showSubDropDown === "voucher" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "voucher" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/all-vouchers")}>
                  All Voucher
                </span>
                <span onClick={(e) => navigate("/vouchers-entry")}>
                  Voucher Entry
                </span>
                <span onClick={(e) => navigate("/manual-invoice")}>
                  Manual Invoice
                </span>
                <span onClick={(e) => navigate("/manual-credit")}>
                  Manual Credit
                </span>
                <span onClick={(e) => navigate("/invoice-logs")}>
                  Invoice Logs
                </span>
              </div>
            )}

            <span
              onClick={(e) => {
                if (showSubDropDown === "account") {
                  setshowSubDropDown("");
                } else {
                  setshowSubDropDown("account");
                }
              }}
              className={`${
                showSubDropDown === "account" ? "active_link" : ""
              }`}
            >
              <div>Accounts</div>
              {showSubDropDown === "account" ? (
                <KeyboardArrowUpRoundedIcon />
              ) : (
                <KeyboardArrowDownRoundedIcon />
              )}
            </span>
            {showSubDropDown === "account" && (
              <div className="mobile_sub_menu_container_link">
                <span onClick={(e) => navigate("/chart-of-account")}>
                  Chart of Account
                </span>
                <span onClick={(e) => navigate("/gournal-ledger")}>
                  Gournal Ladger
                </span>
                <span onClick={(e) => navigate("/trial-balance")}>
                  Trial Balance
                </span>
                <span onClick={(e) => navigate("/balance-sheet")}>
                  Balance Sheet
                </span>
                <span onClick={(e) => navigate("/profit-and-lose")}>
                  Profit and Lose
                </span>
                <span onClick={(e) => navigate("/asset-statement")}>
                  Assets Statement
                </span>
                <span onClick={(e) => navigate("/expance-statement")}>
                  Expance Statement
                </span>
                <span
                  onClick={(e) => {
                    setshowTerDropDown("bankbook");
                  }}
                  className={`${
                    showTerDropDown === "bankbook" && "active_link"
                  }`}
                >
                  <div>Bank Book</div>
                  {showTerDropDown === "bankbook" ? (
                    <KeyboardArrowUpRoundedIcon />
                  ) : (
                    <KeyboardArrowDownRoundedIcon />
                  )}
                </span>
                {showTerDropDown === "bankbook" && (
                  <div className="mobile_sub_menu_container_link">
                    <span onClick={(e) => navigate("/bank-cash")}>
                      Bank/Cash
                    </span>
                    <span onClick={(e) => navigate("/add-cheque-book")}>
                      Add Cheque Book
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>

          <span
            onClick={(e) => {
              let agency = document.getElementById("mobile_sub_menu_agency");
              let setup = document.getElementById("mobile_sub_menu_setup");
              let finance = document.getElementById("mobile_sub_menu_finance");
              finance.classList.remove("display_block");
              setup.classList.remove("display_block");

              if (agency.classList.contains("display_block")) {
                agency.classList.remove("display_block");
                agency.classList.add("display_none");
                setshowDropDown("");
              } else {
                agency.classList.add("display_block");
                setshowDropDown("agency");
              }
            }}
            className={`${showDropDown === "agency" ? "active_link" : ""}`}
          >
            <div>Agency</div>
            {showDropDown === "agency" ? (
              <KeyboardArrowUpRoundedIcon />
            ) : (
              <KeyboardArrowDownRoundedIcon />
            )}
          </span>

          <div
            className="mobile_sub_menu_container display_none"
            id="mobile_sub_menu_agency"
          >
            <span
              onClick={(e) => {
                navigate("/agency-data");
              }}
            >
              Agency Data
            </span>
            <span
              onClick={(e) => {
                navigate("/agency-documents");
              }}
            >
              Documents
            </span>
            <span
              onClick={(e) => {
                navigate("/agency-users");
              }}
            >
              User
            </span>
            <span
              onClick={(e) => {
                navigate("/agency-user-level");
              }}
            >
              User Level
            </span>
          </div>

          <span
            onClick={(e) => {
              navigate("/chat");
            }}
          >
            Chats
          </span>
        </div>
      </div>
    </div>
  );
}

export default MobileMenu;
