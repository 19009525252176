import { TextField } from "@mui/material";
import React from "react";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../components/CustomDrp";

const UmrahDetails = () => {
  return (
    <div className="">
      <DetailForm />
    </div>
  );
};

const DetailForm = () => {
  return (
    <div className="m-5 bg-white p-4">
      <div className="row align-items-end my-2">
        <h4>Passenger Information:</h4>
        <div className="col-1">
          <CustomDropdown
            arry={["Adult", "Child", "Infant"]}
            placehlder="Type"
          />
        </div>
        <div className="col-1">
          <CustomDropdown
            arry={["MR", "MS", "MRS", "MISS", "MSTR", "INFANT"]}
            placehlder="Title"
          />
        </div>
        <div className="col-2">
          <TextField variant="standard" label="First Name" className="w-100" />
        </div>
        <div className="col-2">
          <TextField variant="standard" label="Last Name" className="w-100" />
        </div>
        <div className="col-2">
          <ReactDatePicker
            placeholderText="Date of Birt"
            // selected={startDate}
            // onChange={(date) => {
            //     setStartDate(date);
            // }}
            monthsShown={2}
            selected={new Date()}
            // onCalendarClose={handleCalendarClose}
            minDate={new Date()}
            // onCalendarOpen={handleCalendarOpen}
            className="date_picker"
          />
        </div>
        <div className="col-1">
          <TextField variant="standard" label="Passport No" className="w-100" />
        </div>
        <div className="col-1">
          <TextField variant="standard" label="Visa No" className="w-100" />
        </div>
        <div className="col-2">
          <TextField
            variant="standard"
            label="Visa Supplier"
            className="w-100"
          />
        </div>
      </div>

      <hr className="my-4" />

      {/* Flight Info */}
      <div className=" my-2">
        <div className="d-flex justify-content-between align-items-end">
          <h4>Flight Information:</h4>
          <div>
            <TextField variant="standard" label="PNR" className="w-100" />
          </div>
        </div>

        <div className="row my-3">
          <h6>Arrival ( Saudi Arebia )</h6>
          <div className="col-2">
            <TextField variant="standard" label="Flight" className="w-100" />
          </div>
          <div className="col-2">
            <TextField
              variant="standard"
              label="Flight Date"
              className="w-100"
            />
          </div>
          <div className="col-2">
            <TextField variant="standard" label="Origin" className="w-100" />
          </div>
          <div className="col-2">
            <TextField
              variant="standard"
              label="Destination"
              className="w-100"
            />
          </div>
          <div className="col-2">
            <TextField
              variant="standard"
              label="Departure Time"
              className="w-100"
            />
          </div>
          <div className="col-2">
            <TextField
              variant="standard"
              label="Arrival Time"
              className="w-100"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField variant="standard" className="w-100" value={"PK321"} />
          </div>
          <div className="col-2 mt-4">
            <TextField
              variant="standard"
              className="w-100"
              value={"31/10/2022"}
            />
          </div>
          <div className="col-2 mt-4">
            <TextField
              variant="standard"
              className="w-100"
              value={"JED - Jeddah, Saudi Arabia "}
            />
          </div>
          <div className="col-2 mt-4">
            <TextField
              variant="standard"
              className="w-100"
              value={"LHE - Lahore, Pakistan "}
            />
          </div>
          <div className="col-2 mt-4">
            <TextField variant="standard" className="w-100" value={"20:40"} />
          </div>
          <div className="col-2 mt-4">
            <TextField variant="standard" className="w-100" value={"20:40"} />
          </div>
        </div>
      </div>

      <hr className="my-3" />

      <div className="row py-3">
        <div className="col-6 col-md-2">
          <div class="checkbox-rect">
            <input type="checkbox" id="Umrah_hotel" name="check" />
            <label for="Umrah_hotel" className="h6">
              Umrah Hotel
            </label>
          </div>
        </div>
        <div className="col-6 col-md-2">
          <div class="checkbox-rect">
            <input type="checkbox" id="umrah_transfer" name="check" />
            <label for="umrah_transfer" className="h6">
              Umrah Transfer
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UmrahDetails;
