import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import RefreshImg from "../../../assets/images/refresh (1).png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setselectedFlight } from "../../../redux/features/home/FlightSearchSlice";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

function SessionExpire() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      setOpenBackdrop(true);
      document.body.classList.add("no-scroll");
      return <div className="timer">00:00</div>;
    }
    const minutes = Math.floor(remainingTime / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (remainingTime % 60).toString().padStart(2, "0");
    return (
      <div className="timer">
        <div className="value">{`${minutes}:${seconds}`}</div>
      </div>
    );
  };

  return (
    <div className="shadow-sm borderradius p-4 py-3 mb-3 bg-white">
      <h4 className="d-flex gap-3 align-items-center color_primary">
        Session expires in:
        <span className="">
          <CountdownCircleTimer
            isPlaying
            duration={600}
            colors={["#457a7c", "#355a6b", "#A30000", "#A30000"]}
            colorsTime={[10, 6, 3, 0]}
            size={80}
            strokeWidth={6}
          >
            {renderTime}
          </CountdownCircleTimer>
        </span>
      </h4>
      <Backdrop
        open={openBackdrop}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="bg-white rounded-2 shadow-md p-4 px-5">
            <h4>You take a long time to make a reservation.</h4>
            <div className="d-flex align-items-center pb-3">
              <div className="me-3">
                Refresh the last request or call our support team.
              </div>
              <img src={RefreshImg} alt="" width={50} height={50} />
            </div>
            <div className="d-flex justify-content-end align-items-end pt-3">
              <button
                className="button_style px-4"
                type="button"
                onClick={() => {
                  document.body.classList.remove("no-scroll");
                  setOpenBackdrop(false);
                  navigate("/search-results");
                  dispatch(setselectedFlight(null));
                }}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}

export default SessionExpire;
