import React from "react";
import { useSelector } from "react-redux";
import Sial from "../../../../assets/images/airlineLogo/PF.png";
import Serene from "../../../../assets/images/airlineLogo/ER.png";
import HITIT from "../../../../assets/images/airlineLogo/PK.png";
import AirBlue from "../../../../assets/images/airlineLogo/PA.png";
import { CurrencyCode } from "../../../../Utils/currencyExchange";
import FlyJinnah from "../../../../assets/images/airlineLogo/9P.png";
function SelectedFlight() {
  const { selectedFlight } = useSelector((state) => state.FlightSearch);

  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  const logImage = {
    Sial,
    Serene,
    HITIT,
    AirBlue,
    FlyJinnah,
  };
  return (
    <>
      <div className="search_engin_result_box bg-white">
        <div className="shadow result_box_body">
          <div className="row align-items-center justify-content-center result_box_body">
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  src={
                    selectedFlight?.booking?.gds === "AMADEUS"
                      ? `/NEW_LOGO/${selectedFlight?.fare?.itineraries[0]?.segments[0]?.carrierCode}.png`
                      : selectedFlight?.booking?.gds === "SABRE"
                      ? selectedFlight?.logo
                      : logImage[selectedFlight?.logo]
                  }
                  alt={selectedFlight?.logo}
                  width={100}
                />
                <div className="mt-2">{selectedFlight?.flightNumber}</div>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div>
                  <h6>{selectedFlight?.depDate}</h6>
                  <h6 className="my-3">{selectedFlight?.depTime}</h6>
                  <h6>
                    {searchValues?.locationDep?.split("-")[0]?.toUpperCase()} -{" "}
                    {searchValues?.locationDep?.split("-")[2]?.toUpperCase()}
                  </h6>
                </div>
                <div>
                  <h6 className="text-center">
                    {selectedFlight?.flightDuration}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">{selectedFlight?.stops}</h6>
                </div>
                <div>
                  <h6>{selectedFlight?.arrDate}</h6>
                  <h6 className="my-3">{selectedFlight?.arrTime}</h6>
                  <h6>
                    {searchValues?.locationArrival
                      ?.split("-")[0]
                      ?.toUpperCase()}{" "}
                    -{" "}
                    {searchValues?.locationArrival
                      ?.split("-")[2]
                      ?.toUpperCase()}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow d-flex flex-column align-items-center justify-content-center result_box_body">
                <h4>
                  {CurrencyCode()} {selectedFlight?.price}
                </h4>
                <h5 className="fs-4 theme-text-color fw-bold">
                  {selectedFlight?.baggageName}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelectedFlight;
