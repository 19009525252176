import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { IoIosAirplane } from "react-icons/io";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { asyncupdateFlightGroupBookeRequestFlag } from "../../redux/features/booking/fligthBookingSlice";
import { asyncCreateFlightRequestsData } from "../../redux/features/requests/flightrequest";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";

const RequestModal = ({
  show,
  setToggleModel,
  agencyData,
  booking,
  selectedRequest,
}) => {
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const agentCurrency = currencyData?.find(
    ({ currency_id }) => currency_id === ~~booking?.curr
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  const requestData = {
    agencyData: {
      ...agencyData,
      credit_limit: `PKR ${agencyData.credit_limit} ${
        agentCurrency?.curr !== 1
          ? `| ${agentCurrency?.curr_code} ${TwoDecimalPoint(
              Number(agencyData.credit_limit) / Number(agentCurrency?.roe)
            )}`
          : ``
      }`,
      credit_used: `PKR ${agencyData.credit_used} ${
        agentCurrency?.curr !== 1
          ? `| ${agentCurrency?.curr_code} ${TwoDecimalPoint(
              Number(agencyData.credit_used) / Number(agentCurrency?.roe)
            )}`
          : ``
      }`,
      available_limit: `PKR ${
        Number(agencyData.credit_limit) - Number(agencyData.credit_used)
      } ${
        agentCurrency?.curr !== 1
          ? `| ${agentCurrency?.curr_code} ${TwoDecimalPoint(
              (Number(agencyData.credit_limit) -
                Number(agencyData.credit_used)) /
                Number(agentCurrency?.roe)
            )}`
          : ``
      }`,
    },
    Date: moment().format("ddd DD-MMM-YY HH:mm"),
    auto_manual: null,
    paxes:
      booking?.FlightBookingModels?.at(0)?.pax_tables?.map((pax) => ({
        ...pax,
      })) || [],
    ...selectedRequest,
  };
  function formatDuration(time1, time2) {
    // Use a custom regular expression to extract hours and minutes
    var timeStart = new Date("2024-02-15T" + time1);
    var timeEnd = new Date("2024-02-15T" + time2);

    let duration = timeEnd - timeStart;

    const seconds = Math.floor(duration / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    // Format the duration as 'hh:mm'
    const formattedDuration = `${hours?.toString()?.padStart(2, "0")}:${(
      minutes % 60
    )
      ?.toString()
      ?.padStart(2, "0")}`;

    return formattedDuration;
  }
  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    let body = {
      agent_id: agencyData?.agent_id,
      book_id:
        booking?.FlightBookingModels && booking?.FlightBookingModels[0]?.id,
      req_resolvedBy_id: 0,
      req_dateTime: moment().format("ddd DD-MMM-YY HH:mm"),
      req_resolve_dateTime: moment().format("ddd DD-MMM-YY HH:mm"),
      agent_note: values?.Agent_Description,
      admin_note: "",
      // req_flag: 7,
      take_by_user_id: 0,
      request_type: "Issued",
      status: "new",
    };

    setTimeout(() => {
      dispatch(
        asyncupdateFlightGroupBookeRequestFlag({
          id: booking?.FlightBookingModels[0]?.id,
          body: {
            request_flag: "Issued",
          },
        })
      );
      dispatch(asyncCreateFlightRequestsData(body));
    }, 3000);
  };

  return (
    <Formik
      initialValues={requestData}
      // validationSchema={SignupSchema}
      //   onSubmit={handleSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Modal
          show={show}
          onHide={(e) => setToggleModel(false)}
          centered
          dialogClassName="modal-xl modal-full"
        >
          <Form>
            <Modal.Header>
              <Modal.Title>Issue Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="px-md-3">
                <div className="col-12 d-flex">
                  <div className="col-sm-3 px-3 mt-3">
                    <TextField
                      label="Company Name"
                      variant="standard"
                      className="w-100"
                      InputLabelProps={{ shrink: true }}
                      value={values.agencyData?.agent_name}
                      disabled
                    />
                  </div>
                  <div className="col-sm-3 px-3 mt-3">
                    <TextField
                      label="Approve Limit"
                      variant="standard"
                      className="w-100"
                      value={values.agencyData.credit_limit}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </div>
                  <div className="col-sm-3 px-3 mt-3">
                    <TextField
                      label="Used Limit"
                      variant="standard"
                      className="w-100"
                      value={values.agencyData.credit_used}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </div>
                  <div className="col-sm-3 px-3 mt-3">
                    <TextField
                      label="Available Limit"
                      variant="standard"
                      className="w-100"
                      value={values.agencyData.available_limit}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-12 d-flex">
                  <div className="col-sm-4 px-3 mt-5">
                    <TextField
                      label="Request Time"
                      variant="standard"
                      className="w-100"
                      InputLabelProps={{ shrink: true }}
                      value={values.req_dateTime}
                      disabled
                    />
                  </div>
                  <div className="col-sm-4 px-3 mt-5">
                    <TextField
                      label="Request Type"
                      variant="standard"
                      className="w-100"
                      InputLabelProps={{ shrink: true }}
                      value={values.request_type}
                      disabled
                    />
                  </div>
                  <div className="col-sm-4 px-3 mt-5">
                    <Autocomplete
                      id="size-small-standard"
                      size="small"
                      options={newSupplier}
                      getOptionLabel={(option) => option.supp_name}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setFieldValue("supplier", newValue.supp_name);
                          setFieldValue("auto_manual", newValue.gds ? 0 : 1);
                        } else {
                          setFieldValue("supplier", "");
                          setFieldValue("agent_name", "");
                          setFieldValue("auto_manual", null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          variant="standard"
                          label="Select Supplier"
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="col-12 d-flex">
                  <div className="col-sm-6 px-3 mt-5">
                    <TextField
                      label="Agent Description"
                      variant="standard"
                      className="w-100"
                      InputLabelProps={{ shrink: true }}
                      value={values.agent_note}
                      disabled
                    />
                  </div>
                  <div className="col-sm-6 px-3 mt-5">
                    <TextField
                      label="Supplier Description"
                      variant="standard"
                      className="w-100"
                      InputLabelProps={{ shrink: true }}
                      value={values.admin_note}
                    />
                  </div>
                </div>
                {/* <hr className="my-5" /> */}
                <div className="row mt-5">
                  <div className="col-12 col-md-6">
                    {/* ///////////////////////////////////////////////////////////////////////////Main Boking/////////////////////////// */}
                    <div className="booked_requestModel p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Booking </h5>
                      </div>
                      <hr />

                      <table class="table table-bordered border-dark align-items-center ">
                        <thead
                          className="bg_secondary text-white "
                          style={{ fontSize: "20px" }}
                        >
                          <tr>
                            <th scope="col">Booking ID</th>
                            <th scope="col">Booking Status</th>
                            <th scope="col">Document Status</th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-dark "
                          style={{ fontSize: "20px", color: "#000000" }}
                        >
                          {
                            <tr>
                              <td>{booking?.id}</td>
                              <td>{booking?.book_status}</td>
                              <td>{booking?.doc_status}</td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>

                    {/* //////////////////////////////////////////////////////////////////////Flight Segemnet////////////////////////////////////// */}
                    <div>
                      {booking?.FlightBookingModels &&
                        booking?.FlightBookingModels[0]?.FlightSegmentModels?.map(
                          (fltSegment, index) => (
                            <div className="booked_items">
                              <div className="d-flex align-items-center">
                                <div>
                                  <IoIosAirplane size={26} />
                                </div>
                                <p
                                  className="h5"
                                  style={{
                                    lineHeight: 1,
                                    marginLeft: 6,
                                    paddingTop: 5,
                                  }}
                                >
                                  Flight
                                </p>
                              </div>

                              <div className="d-flex align-items-center justify-content-center ">
                                <div className="col-2">
                                  <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img
                                      src={`/NEW_LOGO/${fltSegment?.flt}.png`}
                                      alt="SereneLogo"
                                      width={80}
                                    />
                                    <div style={{ paddingLeft: 4 }}>
                                      {fltSegment?.flt +
                                        " " +
                                        fltSegment?.flt_no}{" "}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-8">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h6>
                                        {moment(
                                          fltSegment?.dep_date,
                                          "DD-MM-YYYY"
                                        ).format("ddd DD-MMM-YY")}
                                      </h6>
                                      <h6 className="my-3">
                                        {fltSegment?.dep_time}{" "}
                                      </h6>
                                      <h6>
                                        {fltSegment?.origin.split("-")[0] +
                                          "-" +
                                          fltSegment?.origin.split("-")[1]}
                                      </h6>
                                    </div>

                                    <div>
                                      <h6 className="text-center">
                                        {formatDuration(
                                          fltSegment?.dep_time,
                                          fltSegment?.arr_time
                                        )}
                                      </h6>
                                      <div className="border_rounded mb-2" />
                                      <h6 className="mx-3">Non-Stop</h6>
                                    </div>

                                    <div>
                                      <h6>
                                        {moment(
                                          fltSegment?.arr_date,
                                          "DD-MM-YYYY"
                                        ).format("ddd DD-MMM-YY")}
                                      </h6>
                                      <h6 className="my-3">
                                        {fltSegment?.arr_time}{" "}
                                      </h6>
                                      <h6>
                                        {fltSegment?.destination.split("-")[0] +
                                          "-" +
                                          fltSegment?.destination.split("-")[1]}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                  {/* /////////////////////////////////////////////////passeng Data //////////////////////////////////////////////*/}

                  <div className="col-12 col-md-6">
                    <div className="booked_requestModel p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Passenger</h5>
                        <h5></h5>
                      </div>
                      <hr />

                      <table class="table table-bordered border-dark align-items-center ">
                        <thead
                          className="bg_secondary text-white "
                          style={{ fontSize: "20px" }}
                        >
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Type</th>
                            <th scope="col">Name</th>
                            <th scope="col">Ticket No</th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-dark "
                          style={{ fontSize: "20px", color: "#000000" }}
                        >
                          {values?.paxes?.map((pax, index) => (
                            <tr>
                              <td class="checkbox-rect">
                                <input
                                  type="checkbox"
                                  id={"passenger" + index}
                                  checked
                                  name="passenger"
                                />
                                <label
                                  htmlFor={"passenger" + index}
                                  for="passenger"
                                  className="h6"
                                ></label>
                              </td>
                              <td>{pax?.pax_type}</td>
                              <td>{pax?.fName + " " + pax?.lname}</td>

                              <td>
                                {(values.auto_manual === null ||
                                values.auto_manual === 0) ? (
                                  pax?.tkt_number
                                ) : (
                                  <TextField
                                    placeholder="Enter Ticket No"
                                    type="number"
                                    variant="standard"
                                    className="h-auto mt-0"
                                    InputLabelProps={{ shrink: true }}
                                    value={pax?.tkt_number}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {values.auto_manual !== null && (
                      <div className="d-flex mt-5">
                        <div className="form-check form-check-inline d-flex gap-1">
                          <input
                            type="radio"
                            value="0"
                            onChange={() => {
                              setFieldValue("auto_manual", 0);
                            }}
                            checked={values.auto_manual === 0}
                            className="form-check-input-styled back_up"
                            name="auto_manual"
                          />{" "}
                          <label className="form-check-label"> Auto</label>
                        </div>
                        <div className="form-check form-check-inline d-flex gap-1">
                          <input
                            type="radio"
                            value="1"
                            onChange={() => {
                              setFieldValue("auto_manual", 1);
                            }}
                            checked={values.auto_manual === 1}
                            className="form-check-input-styled back_up"
                            name="auto_manual"
                          />{" "}
                          <label className="form-check-label">Manual</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="d-flex flex-column gap-2 justify-content-end align-items-end ">
                  <div className="d-flex gap-4 ">
                    {" "}
                    <button
                      className="border px-4  mt-4 selectbtn_style"
                      style={{ fontSize: "20px", color: "white", padding: 8 }}
                      onClick={() => setShow(setActive_Tab("General"))}
                    >
                      Close
                    </button>
                    <button
                      className="border px-4  mt-4 selectbtn_style"
                      type="submit"
                      style={{ fontSize: "20px", color: "white", padding: 8 }}
                    >
                      {message ? <BtnLoader /> : "Save Request"}
                    </button>
                  </div>
                  {message && (
                    <div>
                      <p className="fs-5 text-success">
                        Your Request Save Successfully
                      </p>
                    </div>
                  )}
                </div> */}
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default RequestModal;
