import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactDatePicker from "react-datepicker";

import "../../../Styles/setup.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetChequeData,
  asyncGenerateSupplierPayment,
  asyncGenerateAgentPayment,
} from "../../../redux/features/Payments/MakePaymentsSlice";

import { toast } from "react-toastify";

const MakePaymentModal = ({
  show,
  setShow,
  SupplierD,
  data,
  AgentSuplierradio,
  supplierId,
}) => {
  const { chequeData } = useSelector((state) => state.makePayments);
  const dispatch = useDispatch();


  const [file, setFile] = useState("");
  const [radio, setRadio] = useState("Cheque");
  const [bankData, setbankData] = useState("");

  useEffect(() => {}, [bankData?.id, dispatch]);
  const Sname = JSON.parse(localStorage.getItem("user"));

  // console.log(`${bankData.name} - ${bankData.account_no} - cheq_no - ${chequeData?.cheque_no}`);
  // console.log(supplierId)
  // console.log(chequeData)

  const basicSchema = yup.object().shape({
    staffId: yup.number().required("Required"),
    staffName: yup.string().required("Required"),
    amount: yup.number().required("Required"),
    bankName: yup.string().required("Required"),
    // file: yup.string().required("Required"),
    admin_Desc: yup.string().required("Required"),
    cheque_no: yup.number().required("Required"),
    date: yup.date().required("Required"),
  });

  const IBFTSchema = yup.object().shape({
    bankName: yup.string().required("Required"),
    admin_Desc: yup.string().required("Required"),
  });

  const cashSchema = yup.object().shape({
    admin_Desc: yup.string().required("Required"),
  });

  const handleClose = () => setShow(false);
  const onSubmit = (values, actions) => {
    const formData = new FormData();
    if (values.cancelable == true) {
      return;
    }
    if (AgentSuplierradio === "supplier") {
      formData.append("admin_desc", values?.admin_Desc);
      formData.append("admin_invoice_amount", values?.amount);
      formData.append("attta", file);
      formData.append("bank_id", bankData?.id);
      formData.append("ch_id", chequeData?.ch_id);
      formData.append("cheque_date", values?.date);
      formData.append("cheque_no", values?.cheque_no);
      formData.append("company_id", "");
      formData.append("fop", radio);
      formData.append("staff_id", values?.staffId);
      formData.append("staff_name", values?.staffName);
      formData.append("supplier_id", supplierId);
      formData.append("total_amount", values?.amount);
      dispatch(asyncGenerateSupplierPayment({ formData, toast }));
    } else {
      formData.append("admin_desc", values?.admin_Desc);
      formData.append("admin_invoice_amount", values?.amount);
      formData.append("attta", file);
      formData.append("bank_id", bankData?.id);
      formData.append("ch_id", chequeData?.ch_id);
      formData.append("cheque_date", values?.date);
      formData.append("cheque_no", values?.cheque_no);
      formData.append("company_id", SupplierD?.agent[0]?.id);
      formData.append("fop", radio);
      formData.append("staff_id", values?.staffId);
      formData.append("staff_name", values?.staffName);
      formData.append("supplier_id", supplierId);
      formData.append("total_amount", values?.amount);
      dispatch(asyncGenerateAgentPayment({ formData, toast }));
    }

    // console.log(formData);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        staffId: Sname?.data?.id || "",
        staffName: Sname?.data?.name || "",
        amount: SupplierD?.amount || "",
        bankName: "",
        // file: "",
        admin_Desc:
          (chequeData &&
            `${bankData?.name} - ${bankData?.account_no} - cheq_no - ${chequeData?.cheque_no}`) ||
          "",
        cheque_no: (chequeData && chequeData?.cheque_no) || "",
        date: "",
      },
      validationSchema: radio == "Cheque" ? basicSchema : radio=="IBFT" ? IBFTSchema : cashSchema ,
      onSubmit,
    });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"modal-lg"}
        backdrop="static"
        keyboard={false}
        centered
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Payment Voucher</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "65vh" }}>
            <div className="d-flex flex-column">
              <div className="row justify-content-between">
                <div className="col-sm-4 px-3">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Staff ID *"
                    variant="standard"
                    className="col-12"
                    name="staffId"
                    error={touched.staffId && errors.staffId ? 1 : 0}
                    value={Sname?.data?.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.staffId && errors.staffId ? errors.staffId : ""
                    }
                  />
                </div>
                <div className="col-sm-4 px-3">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Staff Name *"
                    variant="standard"
                    className="col-12"
                    name="staffName"
                    error={touched.staffName && errors.staffName ? 1 : 0}
                    value={Sname?.data?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.staffName && errors.staffName
                        ? errors.staffName
                        : ""
                    }
                  />
                </div>
                <div className="col-sm-4 px-3">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Amount *"
                    variant="standard"
                    className="col-12"
                    name="amount"
                    error={touched.amount && errors.amount ? 1 : 0}
                    value={SupplierD?.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.amount && errors.amount ? errors.amount : ""
                    }
                  />
                </div>
              </div>
              <div className="col-sm-12 d-flex mt-5">
                <span className="form-control me-5">
                  <input
                    type="radio"
                    id="Cheque"
                    value="Cheque"
                    checked={radio === "Cheque"}
                    onChange={(e) => setRadio(e.target.value)}
                  />
                  <label htmlFor="Cheque" style={{ marginLeft: "5px" }}>
                    Cheque
                  </label>
                </span>
                <span className="form-control me-5">
                  <input
                    type="radio"
                    id="IBFT"
                    value="IBFT"
                    checked={radio === "IBFT"}
                    onChange={(e) => setRadio(e.target.value)}
                  />
                  <label htmlFor="IBFT" style={{ marginLeft: "5px" }}>
                    IBFT
                  </label>
                </span>
                <span className="form-control me-5">
                  <input
                    type="radio"
                    id="Cash"
                    value="Cash"
                    checked={radio === "Cash"}
                    onChange={(e) => setRadio(e.target.value)}
                  />
                  <label htmlFor="Cash" style={{ marginLeft: "5px" }}>
                    Cash
                  </label>
                </span>
              </div>
              {radio !== "Cash" && (
                <div className="col-12 d-flex bank-info">
                  <div className="col-sm-5 mt-5 mx-3">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Bank Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Bank Name"
                        name="bankName"
                        error={touched.bankName && errors.bankName ? 1 : 0}
                        value={values.bankName || "Select-Bank"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.bankName && errors.bankName
                            ? errors.bankName
                            : ""
                        }
                      >
                        <MenuItem value="Select-Bank">Select Bank</MenuItem>
                        {data?.bank?.map((bank) => {
                          return (
                            <MenuItem
                              value={bank.name}
                              key={bank.id}
                              onClick={() => {
                                setbankData(bank);
                                // dispatch(emptyBankData())
                                // setMakePayment({ ...MakePayment, cheque_no: "" });
                                dispatch(asyncGetChequeData(bank?.id));
                              }}
                            >
                              {` ${bank.name} - ${bank.account_no} - ${bank.beneficiary_name}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {/* <FormControl variant="standard" className="w-100 mt-3">
                      <InputLabel id="demo-simple-select-standard-label">
                        Bank Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Bank"
                        name="bank"
                        error={touched.bank && errors.bank ? 1 : 0}
                        value={values.bank}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.bank && errors.bank
                            ? errors.bank
                            : ""
                        }
                      >
                        {data?.bank?.map((bank) => {
                          return (
                            <MenuItem
                              value={bank.name}
                              key={bank.id}
                              onClick={() => {
                                // setBankData(bank)
                                // // dispatch(emptyBankData())
                                dispatch(asyncGetChequeData(bank?.id));

                                // setMakePayment({ ...MakePayment, cheque_no: "" });
                              }}
                            >
                             {` ${bank.name} - ${bank.account_no} - ${bank.beneficiary_name}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}
                  </div>
                  <div className="col-sm-5 mt-5 mx-3 align-items-end">
                    <TextField
                      // label="Attach Deposit Slip"
                      variant="standard"
                      className="col-12 mt-3"
                      type={"file"}
                      onChange={(e) => setFile(e.target.files[0])}
                      // name="file"
                      // error={touched.file && errors.file ? 1 : 0}
                      // value={values.file}
                      // onChange={handleChange}
                      // onBlur={handleBlur}
                      // helperText={touched.file && errors.file ? errors.file : ""}
                    />
                  </div>
                  {radio === "Cheque" && (
                    <>
                      <div className="col-sm-5 mt-5 mx-3">
                        <TextField
                          InputProps={{
                            readOnly: true,
                          }}
                          label="Cheque No. *"
                          variant="standard"
                          className="col-12"
                          name="cheque_no"
                          error={touched.cheque_no && errors.cheque_no ? 1 : 0}
                          value={values.cheque_no}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.cheque_no && errors.cheque_no
                              ? errors.cheque_no
                              : ""
                          }
                        />
                      </div>
                      <div className="col-sm-5 mt-5 align-items-end mx-3">
                        <TextField
                          type="date"
                          variant="standard"
                          className="date_picker mt-4"
                          placeholderText="Cheque Date"
                          monthsShown={2}
                          name="date"
                          error={touched.date && errors.date ? 1 : 0}
                          value={values.date}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={
                            touched.date && errors.date ? errors.date : ""
                          }
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className="mt-5">
                <span>Description No. *</span>
                <textarea
                  className="col-sm-12 mt-3"
                  rows={6}
                  name="admin_Desc"
                  error={touched.admin_Desc && errors.admin_Desc ? 1 : 0}
                  // value={chequeData?.admin_Desc}
                  value={values.admin_Desc}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.admin_Desc && errors.admin_Desc
                      ? errors.admin_Desc
                      : ""
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              cancel
            </Button>
            <Button onClick={onSubmit} type="submit" variant="secondary">
              save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default MakePaymentModal;
