import React, { useEffect, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  InputLabel,
} from "@mui/material";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { Form, Formik, ErrorMessage } from "formik";
import { useSelector, useDispatch } from "react-redux";
import BtnLoader from "../AppForm/BtnLoader";
import * as yup from "yup";
import {
  asyncGetAdminAgency,
  asyncGetAgentData,
  asyncUpdateMainAgency,
  myMessage,
} from "../../redux/features/setupRestPages/agentsSlice";
import { asyncGetAllUserData } from "../../redux/features/agency/usersSlice";
import { URL2 } from "../../redux/features/ApiConfig";

function AgencyData() {
  const [loginUserData, setLoginUserData] = useState({});
  const dispatch = useDispatch();

  const dropMenuProp = {
    PaperProps: {
      sx: {
        width: "inherit",
      },
    },
  };

  // const { user } = useSelector((state) => state.user);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { agentsData, loading, message } = useSelector(
    (state) => state.agentsCategories
  );

  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { allUsersData } = useSelector((state) => state.usersSlice);

  useEffect(() => {
    const loginUser = agentsData?.find((it) => it?.agent_id === user?.agent_id);
    setLoginUserData(loginUser);
  }, [user, agentsData]);

  const keyManager = allUsersData?.filter(
    (it) => it.user_id === loginUserData?.manager
  );

  // if the login user has permissions to edit office data
  const officeDataPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/agency-data-office-data"
  );
  // if the login user has permissions to edit view settings
  const viewSettingPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/agency-data-view-settings"
  );

  useEffect(() => {
    dispatch(asyncGetAgentData());
    dispatch(asyncGetAllUserData());
    setTimeout(() => {
      dispatch(myMessage(null));
    }, 3000);
  }, [dispatch, message]);

  const agentTypes = ["Travel Agent", "Corporate", "Travel Consultant"];

  const initialValues = {
    
    agent_id: loginUserData?.agent_id || "",
    add_by: user?.user_id || "",
    add_edit_by: user?.user_id || "",
    add_edit_datetime: loginUserData?.add_edit_datetime || "",
    agent_type: loginUserData?.agent_type || "",
    auth_by: user?.user_id || "",

    manager: loginUserData?.manager,
    agent_name: loginUserData?.agent_name || "",
    reg_no: loginUserData?.reg_no || "",
    tax_no: loginUserData?.tax_no || "",
    acc_id: loginUserData?.acc_id || "",
    logo: "",
    // its a boolean
    logo_view: loginUserData?.logo_view || "",
    bg_image: "",
    fav_icon: "",
    // its a boolean
    tkt_auth: loginUserData?.tkt_auth || "",
    hours_72: loginUserData?.hours_72 || "",
    footer: loginUserData?.footer || "",
    first_color: loginUserData?.first_color || "",
    second_color: loginUserData?.second_color || "",
    // country + city + address will be inside address
    address: loginUserData?.address?.split("]")[0] || "",
    phone: loginUserData?.phone || "",
    mobile_no: loginUserData?.mobile_no || "",
    agent_email: loginUserData?.email || "",
    smtp_password: loginUserData?.smtp_password || "",
    url: loginUserData?.url || "",

    credit_limit: loginUserData?.credit_limit || "",
    credit_used: loginUserData?.credit_used || "",
    limit_alert: loginUserData?.limit_alert || "",
    status: loginUserData?.status || "",

    f_name: user?.f_name || "",
    l_name: user?.l_name || "",
    user_email: user?.email || "",
    country: loginUserData?.address?.split("]")[2] || "Pakistan",
    city: loginUserData?.address?.split("]")[1] || "",
    pdf_footer: loginUserData?.pdf_footer || "",
  };

  const SUPPORTED_FORMATS = ["image/png"];

  const validationSchema = yup.object().shape({
    agent_name: yup.string().required("agent Name Required"),

    logo: yup
      .mixed()
      .nullable()
      .test(
        "format",
        "Only png logo are accepted",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),
    // bg_image: yup
    //   .mixed()
    //   .nullable()
    //   .required("Background Image is required")
    //   .test(
    //     "format",
    //     "Only png Image are accepted",
    //     (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    //   ),

    fav_icon: yup
      .mixed()
      .nullable()
      .test(
        "format",
        "Only png Fav-Icon are accepted",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      ),

    footer:
      user?.role_id === 1
        ? yup.string().required("Footer Text Required")
        : yup.string(),
    first_color:
      user?.role_id === 1
        ? yup.string().required("First Color Required")
        : yup.string(),
    second_color:
      user?.role_id === 1
        ? yup.string().required("Second Color Required")
        : yup.string(),
    agent_type: yup.string().required("agent_type Required"),
    f_name: yup.string().required("First Name Required"),
    l_name: yup.string().required("Last Name Required"),
    user_email: yup
      .string()
      .email("Please Enter valid Email")
      .required("User Email Required"),
    phone: yup.string().required("Phone Required"),
    city: yup.string().required("City Required"),
    address: yup.string().required("Address Required"),
    agent_email: yup
      .string()
      .email("Please Enter valid Email")
      .required("Agent Email Required"),
    smtp_password: yup.string(),
    mobile_no: yup.string().required("Mobile Number Required"),
  });

  const onSubmit = (values) => {
    let id = values?.agent_id;

    const formData = new FormData();

    // if logo bg_image or fav_icons are null and not selected then just dont send any thing and the old images will remain
    values?.logo && formData.append("logo", values?.logo);
    values?.bg_image && formData.append("bg_image", values?.bg_image);
    values?.fav_icon && formData.append("fav_icon", values?.fav_icon);

    formData.append("agent_id", values?.agent_id);
    formData.append("add_by", values.add_by);
    formData.append("add_edit_by", values.add_edit_by);
    formData.append("add_edit_datetime", values.add_edit_datetime);
    formData.append("agent_type", values?.agent_type);
    formData.append("auth_by", values?.auth_by);
    formData.append("manager", values?.manager);
    formData.append("agent_name", values.agent_name);
    formData.append("reg_no", values?.reg_no);
    formData.append("tax_no", values?.tax_no);
    formData.append("acc_id", values?.acc_id);
    formData.append("user_id", user?.user_id);
    values.logo_view && formData.append("logo_view", values?.logo_view);

    values.tkt_auth && formData.append("tkt_auth", values?.tkt_auth);
    values.hours_72 && formData.append("hours_72", values?.hours_72);
    values.footer && formData.append("footer", values?.footer);
    values.first_color && formData.append("first_color", values?.first_color);
    values.second_color &&
      formData.append("second_color", values?.second_color);
    formData.append(
      "address",
      `${values?.address}]${values?.city}]${values?.country}`
    );
    formData.append("phone", values?.phone);
    formData.append("mobile_no", values?.mobile_no);
    formData.append("email", values?.agent_email);
    formData.append("url", values?.url);
    formData.append("credit_limit", values?.credit_limit);
    formData.append("credit_used", values?.credit_used);
    formData.append("limit_alert", values?.limit_alert);
    formData.append("status", values?.status);
    formData.append("f_name", values?.f_name);
    formData.append("l_name", values?.l_name);
    formData.append("user_email", values?.user_email);
    formData.append("smtp_password", values?.smtp_password);
    values.pdf_footer && formData.append("pdf_footer", values?.pdf_footer);

    dispatch(asyncUpdateMainAgency({ id, formData }));
    dispatch(asyncGetAdminAgency())
  };

  return (
    <div className="pb-5">
      <div className="mx-5 rounded-2 shadow-sm mt-5 pb-4 bg-white mb-3">
        <p className="h4 otawix-heading box_title">Agency Data</p>
        <Formik
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleChange }) => {
            return (
              <Form>
                <div className="black_border rounded-2 m-4 my-5 p-4 pb-5">
                  <p className="box_title">Office Data:</p>
                  <div className="row pt-4 align-items-end">
                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="agent_name"
                        value={values?.agent_name}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("agent_name", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label="Agent Name"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="agent_name"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name={"agent_id"}
                        value={`${
                          values.agent_type === "Travel Agent"
                            ? "TA-"
                            : values.agent_type === "Corporate"
                            ? "CA-"
                            : "CO-"
                        }${values?.agent_id}`}
                        onChange={handleChange}
                        label={[`Agent ID`]}
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="agent_id"
                      />
                    </div>
                    {user?.agent_id !== 1 && (
                      <div className="col-sm-2 h-5rem mt-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            Account Manager
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            name={"manager"}
                            value={values?.manager}
                            onChange={handleChange}
                            inputProps={{
                              readOnly:
                                user?.role_id === 1 ||
                                officeDataPermission !== undefined
                                  ? false
                                  : true,
                            }}
                            MenuProps={dropMenuProp}
                          >
                            {keyManager?.map((list, ind) => (
                              <MenuItem
                                value={list.user_id}
                                sx={{ m: 1, bgcolor: "#fff" }}
                                key={list.user_id + ind}
                              >
                                {list.f_name.toUpperCase()}{" "}
                                {list.l_name.toUpperCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage
                          component="div"
                          className="text-danger mt-1"
                          name="manager"
                        />
                      </div>
                    )}
                    <div className="col-sm-2 h-5rem mt-2">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Agent Type
                        </InputLabel>
                        <Select
                          label="agent_type"
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name={"agent_type"}
                          value={values?.agent_type}
                          onChange={handleChange}
                          inputProps={{
                            readOnly:
                              user?.role_id === 1 ||
                              officeDataPermission !== undefined
                                ? false
                                : true,
                          }}
                          MenuProps={dropMenuProp}
                        >
                          {agentTypes?.map((list, ind) => (
                            <MenuItem
                              value={list}
                              sx={{ m: 1, bgcolor: "#fff" }}
                              key={ind + list}
                            >
                              {list}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="f_name"
                        value={values?.f_name}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("f_name", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label="First Name"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="f_name"
                      />
                    </div>
                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="l_name"
                        value={values?.l_name}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("l_name", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label="Last Name"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="l_name"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="user_email"
                        value={values?.user_email}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("user_email", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label={[`Email `, <small>(logged in user)</small>]}
                        type={"email"}
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="user_email"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="agent_email"
                        value={values?.agent_email}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("agent_email", value.toUpperCase());
                        }}
                        label="Agent Email"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="agent_email"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="smtp_password"
                        value={values?.smtp_password}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("smtp_password", value.toUpperCase());
                        }}
                        label="SMTP Password"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="smtp_password"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="phone"
                        value={values?.phone}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("phone", value);
                        }}
                        autoComplete="off"
                        label="Phone"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="phone"
                      />
                    </div>
                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="mobile_no"
                        value={values?.mobile_no}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("mobile_no", value);
                        }}
                        autoComplete="off"
                        label="Mobile Number"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="mobile_no"
                      />
                    </div>

                    <div className="col-12 col-sm-2 h-5rem mt-2">
                      <TextField
                        name="reg_no"
                        value={values?.reg_no}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("reg_no", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label="Registration number"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="reg_no"
                      />
                    </div>

                    <div className="col-12 col-sm-2 h-5rem mt-2">
                      <TextField
                        name="tax_no"
                        value={values?.tax_no}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("tax_no", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label="Tax Number"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="tax_no"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="url"
                        value={values?.url}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("url", value);
                        }}
                        autoComplete="off"
                        label="Company URL"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="url"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="address"
                        value={values.address}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("address", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label="Address"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="address"
                      />
                    </div>
                    <div className="col-sm-2 h-5rem mt-2">
                      <TextField
                        name="city"
                        value={values?.city}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("city", value.toUpperCase());
                        }}
                        autoComplete="off"
                        label="City"
                        variant="standard"
                        className="w-100"
                        inputProps={{
                          readOnly:
                            user?.role_id === 1 ||
                            officeDataPermission !== undefined
                              ? false
                              : true,
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="city"
                      />
                    </div>

                    <div className="col-sm-2 h-5rem mt-2" key={"UNIQUE_ID"}>
                      <CountryDropdown
                        id="UNIQUE_ID"
                        className="country_dropdown absolute w-100"
                        preferredCountries={["pk"]}
                        value={values.country}
                        handleChange={handleChange}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="country"
                      />
                    </div>
                    {user?.user_id !== 1 && loginUserData?.attachment ? (
                      <div className="col-sm-2 h-5rem mt-2">
                        <a
                          href={`${URL2}/${loginUserData?.attachment}`}
                          target="_blank"
                          rel="noreferrer"
                          className="btn setup-btn"
                          type="submit"
                        >
                          Previous Statement
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
                {/************** View Setting  ******************* */}
                <>
                  {loginUserData?.logo_view ? (
                    <div className="black_border m-4 rounded-2 p-4">
                      <p className="box_title">
                        View Setting:{" "}
                        {user?.agent_id !== 1 && (
                          <span className="helper_text_agency_data">
                            You Can Edit Only Office Logo
                          </span>
                        )}
                      </p>
                      {user?.agent_id === 1 ? (
                        <>
                          <div className="row pt-5">
                            <div className="col-sm-2">
                              <p style={{ lineHeight: 0 }}>
                                Agent logo{" "}
                                <sub className="text-danger">
                                  (225 * 75 only png)
                                </sub>
                              </p>
                              <TextField
                                type="file"
                                className="w-100"
                                variant="standard"
                                onChange={(event) => {
                                  setFieldValue("logo", event.target.files[0]);
                                }}
                                disabled={
                                  viewSettingPermission !== undefined ||
                                  user.user_id === 1
                                    ? false
                                    : true
                                }
                              />
                              <ErrorMessage
                                component="div"
                                className="text-danger mt-1"
                                name="logo"
                              />
                            </div>

                            <div className="col-sm-2">
                              <p style={{ lineHeight: 0 }}>
                                Background Image{" "}
                                <sub className="text-danger">
                                  (1550 * 769 only png)
                                </sub>
                              </p>
                              <TextField
                                onChange={(event) => {
                                  setFieldValue(
                                    "bg_image",
                                    event.target.files[0]
                                  );
                                }}
                                type={"file"}
                                variant="standard"
                                className="w-100"
                                disabled={
                                  viewSettingPermission !== undefined ||
                                  user.user_id === 1
                                    ? false
                                    : true
                                }
                              />
                              <ErrorMessage
                                component="div"
                                className="text-danger mt-1"
                                name="bg_image"
                              />
                            </div>

                            <div className="col-sm-2">
                              <p style={{ lineHeight: 0 }}>
                                Fav Icon{" "}
                                <sub className="text-danger">
                                  (90 * 90 only png)
                                </sub>
                              </p>
                              <TextField
                                onChange={(event) => {
                                  setFieldValue(
                                    "fav_icon",
                                    event.target.files[0]
                                  );
                                }}
                                variant="standard"
                                className="w-100"
                                type="file"
                                disabled={
                                  viewSettingPermission !== undefined ||
                                  user.user_id === 1
                                    ? false
                                    : true
                                }
                              />
                              <ErrorMessage
                                component="div"
                                className="text-danger mt-1"
                                name="fav_icon"
                              />
                            </div>
                          </div>
                          <div className="row py-4 pt-5">
                            <div className="col-sm-2">
                              <TextField
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue("footer", value.toUpperCase());
                                }}
                                name="footer"
                                value={values.footer}
                                label="Footer Text"
                                autoComplete="off"
                                className="w-100"
                                variant="standard"
                                disabled={
                                  viewSettingPermission !== undefined ||
                                  user.user_id === 1
                                    ? false
                                    : true
                                }
                              />
                              <ErrorMessage
                                component="div"
                                className="text-danger mt-1"
                                name="footer"
                              />
                            </div>
                            <div className="col-sm-2">
                              <TextField
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue(
                                    "first_color",
                                    value.toUpperCase()
                                  );
                                }}
                                name="first_color"
                                value={values?.first_color}
                                autoComplete="off"
                                variant="standard"
                                label="First Color"
                                className="w-100"
                                disabled={
                                  viewSettingPermission !== undefined ||
                                  user.user_id === 1
                                    ? false
                                    : true
                                }
                              />
                              <ErrorMessage
                                component="div"
                                className="text-danger mt-1"
                                name="first_color"
                              />
                            </div>
                            <div className="col-sm-2">
                              <TextField
                                onChange={(e) => {
                                  const value = e.target.value || "";
                                  setFieldValue(
                                    "second_color",
                                    value.toUpperCase()
                                  );
                                }}
                                name="second_color"
                                value={values?.second_color}
                                autoComplete="off"
                                variant="standard"
                                label="Second Color"
                                className="w-100"
                                disabled={
                                  viewSettingPermission !== undefined ||
                                  user.user_id === 1
                                    ? false
                                    : true
                                }
                              />
                              <ErrorMessage
                                component="div"
                                className="text-danger mt-1"
                                name="second_color"
                              />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <TextField
                              autoComplete="off"
                              variant="standard"
                              label="Footer PDF"
                              className="w-100"
                              name="pdf_footer"
                              value={values?.pdf_footer}
                              onChange={(e) => {
                                let value = e.target.value || "";
                                setFieldValue(
                                  "pdf_footer",
                                  value.toUpperCase()
                                );
                              }}
                              disabled={
                                viewSettingPermission !== undefined ||
                                user.user_id === 1
                                  ? false
                                  : true
                              }
                            />
                            <ErrorMessage
                              component="div"
                              className="text-danger mt-1"
                              name="pdf_footer"
                            />
                          </div>
                        </>
                      ) : loginUserData?.logo_view ? (
                        <div className="col-sm-2 pt-5">
                          <p style={{ lineHeight: 0 }}>
                            Agent logo{" "}
                            <sub className="text-danger">
                              (225 * 75 only png)
                            </sub>
                          </p>
                          <TextField
                            type="file"
                            className="w-100"
                            variant="standard"
                            onChange={(event) => {
                              setFieldValue("logo", event.target.files[0]);
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            className="text-danger mt-1"
                            name="logo"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </>

                <div className="text-center my-5">
                  <div className="my-3">
                    {message === "success" ? (
                      <span className="text-success">
                        Data has been Updated!
                      </span>
                    ) : message === "error" ? (
                      <span className="text-danger">
                        An error is happened while updating your data, Please
                        try again!
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <button className="btn setup-btn" type="submit">
                    {loading ? <BtnLoader /> : "Update"}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AgencyData;
