import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  HTTPForm } from "../ApiConfig";

const initialState= {
    circularData: [],
    editCircular: null,
    loading: false,
    showModel: false,
    error: null,
}


//Get 
export const asyncGetCircularData = createAsyncThunk(
    'asyncGetCircularData/get',
    async(_, {rejectWithValue})=>{
        try {
            const response = await HTTPForm.get('/api/v1/b2b/circular')
            // console.log(response.data.data)
            return await response.data.data;
        } catch (error) {
            console.log(error)
            rejectWithValue(error)
        }
    }
) 


//Post
export const asyncPostCircularData = createAsyncThunk(
    "asyncPostCircularData/post",
    async(formData, {rejectWithValue, dispatch})=>{
        try {
            // console.log('POST DATA ->',formData);
            const response = await HTTPForm.post('/api/v1/b2b/circular', formData);
            dispatch(asyncGetCircularData())
            return await response.data?.data
        } catch (error) {
            console.log(error)
            rejectWithValue(error)
        }
    }
)

//delete circullardata 
export const asyncdeleteCircularData = createAsyncThunk(
    "asyncdeleteCircularData/delete",
    async(rowId, {rejectWithValue, dispatch})=>{
        try {
            const response = await HTTPForm.delete(`/api/v1/b2b/circular/${rowId}`);
            dispatch(asyncGetCircularData())
            return await response.data?.data
            // return response.data.data;
        } catch (error) {
            console.log(error)
            rejectWithValue(error)
        }
    }
)
// edit circularData 
export const asyncEditCircularData = createAsyncThunk(
    "asyncEditCircularData/edit",
    async(rowId, {rejectWithValue})=>{
        // console.log(rowId);
        try {
            const response = await HTTPForm.get(`/api/v1/b2b/circular/${rowId}`);
            // console.log(response.data.data.edit)
            return await response?.data?.data
        } catch (error) {
            console.log(error)
            rejectWithValue(error)
        }

    }
)

//update 
export const asyncUpdateCircularData = createAsyncThunk(
    "asyncUpdateCircularData/update",
    async({id, formData}, {rejectWithValue, dispatch})=>{
        // console.log();
        try {
            const response = await HTTPForm.patch(`/api/v1/b2b/circular/${id}`, formData);
            dispatch(asyncGetCircularData())
            dispatch(resetValue())
            // return await response?.data?.data
        } catch (error) {
            console.log(error)
           return rejectWithValue(error.response.data)
        }
    }
)

 export const Circular = createSlice({
    name: "circularSlice",
    initialState,
    reducers:{
        toggleModel: (state)=>{
            state.showModel = !state.showModel;
        },
        resetValue:(state)=>{
            state.editCircular=null;
        }
    },
    extraReducers:(builder)=>{

        //get circular data
        builder.addCase(asyncGetCircularData.pending, (state, {payload})=>{
            state.loading = true;
            state.error = null;
        })
        builder.addCase(asyncGetCircularData.fulfilled, (state, {payload})=>{
            state.loading = false;
            state.error = null;
            state.circularData = payload;
        })
        builder.addCase(asyncGetCircularData.rejected, (state, {payload})=>{
            state.loading = false;
            state.error = payload;
        })

        //post circular data
        builder.addCase(asyncPostCircularData.pending, (state, {payload})=>{
            state.loading = true;
            state.error = null;
        })
        builder.addCase(asyncPostCircularData.fulfilled, (state, {payload})=>{
            state.loading = false;
            state.error = null;
            state.circularData = payload;
        })
        builder.addCase(asyncPostCircularData.rejected, (state, {payload})=>{
            state.loading = false;
            state.error = null;
        })

        //delet circular data
        builder.addCase(asyncdeleteCircularData.pending, (state, {payload})=>{
            state.loading = true;
            state.error = null;
        })
        builder.addCase(asyncdeleteCircularData.fulfilled, (state, {payload})=>{
            state.loading = false;
            state.error = null;
            const currentCurcularData = state.circularData.filter((data)=> data.rowId !== payload)
            state.circularData = currentCurcularData;
        })
        builder.addCase(asyncdeleteCircularData.rejected, (state, {payload})=>{
            state.loading = false;
            state.error = null;
        })

        // edit circularData
        builder.addCase(asyncEditCircularData.pending, (state, {payload})=>{
            state.loading = true;
            state.error = null;
        })
        builder.addCase(asyncEditCircularData.fulfilled, (state, {payload})=>{
            state.loading = false;
            state.error = null;
            state.editCircular = payload;
        })
        builder.addCase(asyncEditCircularData.rejected, (state, {payload})=>{
            state.loading = false;
            state.error = null;
        })

        //update circular data
        builder.addCase(asyncUpdateCircularData.pending, (state, {payload})=>{
            state.loading = true;
            state.error = null;
        })
        builder.addCase(asyncUpdateCircularData.fulfilled, (state, {payload})=>{
            state.loading = false;
            state.error = null;
            // state.editCircular = payload;
        })
        builder.addCase(asyncUpdateCircularData.rejected, (state, {payload})=>{
            state.loading = false;
            state.error = null;
        })
    }
})
export const {toggleModel,resetValue}= Circular.actions;

export default Circular.reducer;