import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {  HTTPForm, NODEURL } from "../ApiConfig";

const initialState= {
    promotionData: [],
    editPromotion:null,
    showModel: false,
    isLoading: false,
    error: null
}

export const asyncGetPromotionData = createAsyncThunk(
    'asyncGetPromotionData/get',
    async(_, {rejectWithValue})=>{
        try {
            const response = await NODEURL.get('/api/v1/b2b/promotion');
            return await response.data.data;
        } catch (error) {
            rejectWithValue(error)
        }
    })

export const asyncPostPromotionData = createAsyncThunk(
    'asyncPostPromotionData/post',
    async(formData, {rejectWithValue, dispatch})=>{
        try {
            const response = await HTTPForm.post('/api/v1/b2b/promotion',formData)
            dispatch(asyncGetPromotionData())
            // return await response.data;
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const asyncDeletPromotionData = createAsyncThunk(
    'asyncDeletePromotionData/delete',
    async(rowId, {rejectWithValue, dispatch})=>{
        try {
            const response = await NODEURL.delete(`/api/v1/b2b/promotion/${rowId}`)
            dispatch(asyncGetPromotionData())
            return await response.data.data;
        } catch (error) {
            rejectWithValue(error.response.data)
        }
    }
)

export const asyncEditPromotionData = createAsyncThunk(
    'asyncEditPromotionData/edit',
    async(rowId, {rejectWithValue})=>{
        try {
            const response = await NODEURL.get(`/api/v1/b2b/promotion/${rowId}`)
            return await response.data.data;
        } catch (error) {
            rejectWithValue(error)
        }
    }
)

export const asyncUpdatePromotionData = createAsyncThunk(
    'asyncUpdatePromotionData/update',
    async({id,formData}, {rejectWithValue, dispatch})=>{
        try {
            const response = await HTTPForm.patch(`/api/v1/b2b/promotion/${id}`, formData)
            dispatch(asyncGetPromotionData())
            dispatch(toggleEditPromotion())
            return await response.data;
        } catch (error) {
            rejectWithValue(error.response.data)
        }
    }
)

export const Promotion = createSlice({
    name: "promotionSlice",
    initialState,
    reducers:{
        toggleModel: (state)=>{
            state.showModel = !state.showModel;
        },
        toggleEditPromotion: (state)=>{
            state.editPromotion = null
        }
    },
    extraReducers: (builder)=>{
        // geting Promotion data 
        builder.addCase(asyncGetPromotionData.pending, (state, {payload})=>{
            state.isLoading = true;
            state.error = null;
        })
        builder.addCase(asyncGetPromotionData.fulfilled, (state, {payload})=>{
            state.isLoading = false;
            state.error = null;
            state.promotionData = payload;
        })
        builder.addCase(asyncGetPromotionData.rejected, (state, {payload})=>{
            state.isLoading = false;
            state.error= payload;
        })

        // posting promotion data 
        builder.addCase(asyncPostPromotionData.pending, (state, {payload})=>{
            state.isLoading= true;
            state.error = null;
        })
        builder.addCase(asyncPostPromotionData.fulfilled, (state, {payload})=>{
            state.isLoading = false;
            state.error= null;
            state.promotionData = payload;
        })
        builder.addCase(asyncPostPromotionData.rejected, (state, {payload})=>{
            state.isLoading = false;
            state.error = payload;
        })

        // deleting promotion data
        builder.addCase(asyncDeletPromotionData.pending, (state, {payload})=>{
            state.isLoading =true
            state.error = null
        })
        builder.addCase(asyncDeletPromotionData.fulfilled, (state, {payload})=>{
            state.isLoading = false;
            state.error = null;
            const currentPromotionData = state.promotionData.filter((data)=> data.rowId !== payload)
            state.promotionData = currentPromotionData;
        })
        builder.addCase(asyncDeletPromotionData.rejected, (state, {payload})=>{
            state.isLoading = false
            state.error = null;
        })

        // edit promotion data
        builder.addCase(asyncEditPromotionData.pending, (state, {payload})=>{
            state.isLoading =true
            state.error = null
        })
        builder.addCase(asyncEditPromotionData.fulfilled, (state, {payload})=>{
            state.isLoading = false;
            state.error = null;
            state.editPromotion = payload;
        })
        builder.addCase(asyncEditPromotionData.rejected, (state, {payload})=>{
            state.isLoading = false
            state.error = null;
        })

        // update promotion data
        builder.addCase(asyncUpdatePromotionData.pending, (state, {payload})=>{
            state.isLoading =true
            state.error = null
        })
        builder.addCase(asyncUpdatePromotionData.fulfilled, (state, {payload})=>{
            state.isLoading = false;
            state.error = null;
            // state.promotionData = payload;
        })
        builder.addCase(asyncUpdatePromotionData.rejected, (state, {payload})=>{
            state.isLoading = false
            state.error = null;
        })
    }
})

export const { toggleModel, toggleEditPromotion } = Promotion.actions;
export default Promotion.reducer;