import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDropdown from "../../../CustomDrp";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAllBankandCashData } from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import { dropMenuProp } from "../../Vouchers/VoucherEntry/helper";

function BankCash() {
    const dispatch = useDispatch()
    const { allBankAndCash } = useSelector((state) => state.chartAccount);
  const [bankData, setBankData] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    voucherTypeId:[1,2,3],
    acc_id: [],
  });
  
  useEffect(()=>{
dispatch(asyncGetAllBankandCashData());
  },[])
  const handleGenerateReport = () => {
    console.log("bankData", bankData);
  };
  return (
    <div className="Full_height">
      <div className="manual_invoices  mx-auto">
        <div className="manual_invoices_header">
          <div>Bank / Cash Transactions</div>
        </div>
        <div className="row py-3 pb-5 px-2 px-md-4 align-items-end">
          <div className="col-6 col-md-4 mt-4 mt-md-0">
            <ReactDatePicker
              placeholderText="From"
              selected={bankData?.fromDate}
              onChange={(date) => {
                setBankData((prev) => ({
                  ...prev,
                  fromDate: date,
                }));
              }}
              maxDate={new Date()}
              className="date_picker"
            />
          </div>
          <div className="col-6 col-md-4 mt-5 mt-md-0 ">
            <ReactDatePicker
              placeholderText="To"
              selected={bankData?.toDate}
              onChange={(date) => {
                setBankData((prev) => ({
                  ...prev,
                  toDate: date,
                }));
              }}
              minDate={bankData?.fromDate}
              maxDate={new Date()}
              className="date_picker"
            />
          </div>
          <div className="col-6 col-md-4 mt-5 mt-md-0 ">
            <FormControl variant="standard" className="w-100" required>
              <InputLabel id="Select_Creditor_Account">
                Cash and Bank
              </InputLabel>
              <Select
                labelId="Select_Creditor_Account"
                id="Select_Creditor_Account"
                name="creditor_account"
                // value={values?.creditor_account}
                onChange={(e) => {
                  let value = e.target.value;
                  console.log("value", value);
                //   allBankAndCash?.filter((flt)=>)?.map((item)=>item?.split("-")?.at(0))
                  setBankData((prev) => ({
                    ...prev,
                    acc_id: value?.split("-")?.at(0),
                  }));
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                      maxWidth: "100%",
                      overflowY: "auto",
                    },
                  },
                }}
              >
                {[{acc_id:"0",acc_title:"SELECT ALL"},...allBankAndCash]?.map((item, index) => (
                  <MenuItem
                    value={`${item.acc_id}-${item.acc_title}`}
                    sx={{ bgcolor: "white", m: 1 }}
                    key={index}
                  >
                    {item.acc_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* button */}
          <div className="container py-4">
            {/* {message ? ( */}
            <div className="text-center py-4">
              <button
                className="button_style px-4"
                onClick={handleGenerateReport}
                // disabled={isButtonDisabled()}
              >
                Generate Report
              </button>
            </div>
            {/* ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )} */}
          </div>
        </div>
      </div>

      <table className="table table-bordered">
        <thead>
          <tr>
            <th colSpan={4}>BANK NAME-AC NO</th>
            <th>BALANCE</th>
            <th>2000</th>
          </tr>
          <tr>
            <th>DATE</th>
            <th>VN</th>
            <th>DESC / REMARKS</th>
            <th>PAID TO</th>
            <th>PKR</th>
            <th>BALANCE</th>
          </tr>
        </thead>
        <tbody>
          {/* {data?.map((report, index) => (
            <>
              {report?.voucher_type_id !== 1 ? (
                <tr key={index}>
                  <>
                    <td>
                      {report?.saved_date?.split("/")?.at(0)?.toUpperCase()}
                    </td>
                    <td>
                      {obj[
                        report?.voucher_no?.split("-")?.at(0)
                      ]?.toUpperCase()}
                    </td>
                    <td
                      className="text-primary"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        let check =
                          userData?.agent_id === 1
                            ? editVoucherPermission
                              ? true
                              : false
                            : false;

                        if (check) {
                          const url = `/${"vouchers-entry"}/${
                            report?.voucher_id
                          }`;
                          window.open(url, "_blank", "noopener,noreferrer");
                        }
                      }}
                    >
                      {report?.voucher_no}
                    </td>
                    <td>
                      {report?.acc_voucher_trans?.at(1)?.acc_account?.acc_title}
                    </td>
                    <td>
                      {report?.acc_voucher_trans?.at(0)?.acc_account?.acc_title}
                    </td>
                    <td>{report?.acc_voucher_trans?.at(1)?.d_cur_debit}</td>
                    <td>
                      {TwoDecimalPoint(
                        report?.acc_voucher_trans?.at(0)?.d_cur_credit
                      )}
                    </td>
                  </>
                </tr>
              ) : (
                report?.acc_voucher_trans?.map((jv, ind) => (
                  <tr key={index}>
                    <>
                      <td>
                        {report?.saved_date?.split("/")?.at(0)?.toUpperCase()}
                      </td>
                      <td>
                        {obj[
                          report?.voucher_no?.split("-")?.at(0)
                        ]?.toUpperCase()}
                      </td>
                      <td
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          let check =
                            userData?.agent_id === 1
                              ? editVoucherPermission
                                ? true
                                : false
                              : false;

                          if (check) {
                            const url = `/${"vouchers-entry"}/${
                              report?.voucher_id
                            }`;
                            window.open(url, "_blank", "noopener,noreferrer");
                          }
                        }}
                      >
                        {report?.voucher_no}
                      </td>
                      <td>
                        {Number(jv?.d_cur_debit) === 0
                          ? ""
                          : jv?.acc_account?.acc_title}
                      </td>
                      <td>
                        {Number(jv?.d_cur_credit) === 0
                          ? ""
                          : jv?.acc_account?.acc_title}
                      </td>
                      <td>
                        {Number(jv?.d_cur_debit) === 0 ? "" : jv?.d_cur_debit}
                      </td>
                      <td>
                        {Number(jv?.d_cur_credit) === 0 ? "" : jv?.d_cur_credit}
                      </td>
                    </>
                  </tr>
                ))
              )}
            </>
          ))} */}
        </tbody>
      </table>
    </div>
  );
}

export default BankCash;
