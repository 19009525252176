import React from 'react'
import FooterGallery from '../components/Agency/homeviewsittings/FooterGallery'

const FooterGalleryPage = () => {
  return (
    <div>
      <FooterGallery/>
    </div>
  )
}

export default FooterGalleryPage