import React, { useState } from "react";
import PreviewInsurance from "./PreviewInsurance";
import Modal from "@mui/material/Modal";
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Box,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { asyncSearchAgencyData } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Beneficiary = ({
  setFieldValue,
  values,
  setisActiveTaxPayer,
  isActiveTaxPayer,
  FieldArray,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const { searchAgency } = useSelector((state) => state.newAgency);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginTop: 40, marginBottom: 48}}>
      <div className="row align-items-end">
        <div className="col-6 col-md-3">
          <TextField
            variant="standard"
            label="Beneficiary"
            className="w-100"
            value={values.beneficiary}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              setFieldValue("beneficiary", value);
            }}
          />
        </div>
        <div className="col-6 col-md-3">
          <TextField
            variant="standard"
            label="Relationship"
            className="w-100"
            value={values.relationship}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              setFieldValue("relationship", value);
            }}
          />
        </div>
        <div className="col-6 col-md-3">
          <FormControl
            variant="standard"
            className="w-100"
            onClick={() => setagentDRopDwn(!agentDRopDwn)}
          >
            <InputLabel htmlFor="supplier">Select Agent</InputLabel>
            <Input
              variant="standard"
              label="Select Agent"
              autoComplete="off"
              className="w-100"
              name="agent"
              value={values?.select_agent}
              onChange={(e) => {
                let value = e.target.value;
                setFieldValue("select_agent", value);
                dispatch(asyncSearchAgencyData(value));
              }}
              endAdornment={
                <InputAdornment position="end">
                  {values?.select_agent !== "" && (
                    <IconButton
                      onClick={() => {
                        setFieldValue("select_agent", "");
                      }}
                    >
                      <MdClose />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </FormControl>

          {agentDRopDwn ? (
            <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
              <div className="agent_dropdown">
                {searchAgency?.length > 0 ? (
                  searchAgency?.map((item, index) => {
                    return (
                      <div
                        className="p-3"
                        onClick={(e) => {
                          setFieldValue("select_agent", item.agent_name);
                          setagentDRopDwn(false);
                        }}
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        {item?.agent_name}
                      </div>
                    );
                  })
                ) : (
                  <div className="p-3">No record available.</div>
                )}
              </div>
            </ClickAwayListener>
          ) : null}
        </div>
      </div>
      <div className="text-center py-5">
        {/* <button type="submit" className="setup-btn me-4 mt-3" variant="contained">
          Submit
        </button> */}

        <button
          type="button"
          className="setup-btn mt-3"
          onClick={handleOpen}
          variant="contained"
        >
          Preview
        </button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 1400, border: 0, padding: 0 }}>
          <PreviewInsurance
            setFieldValue={setFieldValue}
            values={values}
            setisActiveTaxPayer={setisActiveTaxPayer}
            isActiveTaxPayer={isActiveTaxPayer}
            FieldArray={FieldArray}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Beneficiary;
