// i have added
// (1) Pricing page
// (2) Add Air Lines page
// (3) Supplier Rule page
// (4) Resolved Request page
// pages in this slice from Setup section
// ******************************************* AGENT CATAAGORIES SECTION *************************************************************************

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTPForm, NODEURL } from "../ApiConfig";

const initialState = {
  //addairlines State
  addAirLinesData: [],
  editAirlinesData: null,

  // supplier Rules
  supplierRulesData: [],
  editSupplierRule: null,
  airlinePricing: [],
  editPricingData: null,
  loading: false,
  error: null,
};
// get addAirlines data
export const asyncGetAddAirlinesData = createAsyncThunk(
  "asyncGetAddAirlinesData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/airline");
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);
// post data
export const asyncPostAddAirlinesData = createAsyncThunk(
  "asyncPostAddAirlinesData/post",
  async (addairlineobj, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/airline", addairlineobj);
      dispatch(asyncGetAddAirlinesData());

      return await response?.data?.data;
    } catch (error) {
      // console.log(error)
      rejectWithValue(error);
    }
  }
);

// edit addAirlines data
export const asyncEditAddAirlinesData = createAsyncThunk(
  "asyncEditAddAirlinesData/Edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/airline/" + id);
      return await response?.data?.data;
    } catch (error) {
      // console.log(error)
      rejectWithValue(error);
    }
  }
);
// update addAirlines data
export const asyncUpdateAddAirlinesData = createAsyncThunk(
  "asyncUpdateAddAirlinesData/Update",
  async (updateData, { rejectWithValue, dispatch }) => {
    // console.log("update data", updateData);
    let id = updateData?.arr_id;
    try {
      const response = await NODEURL.patch(
        "/api/v1/b2b/airline/" + id,
        updateData
      );
      dispatch(asyncGetAddAirlinesData());
      // console.log(response?.data?.data)
      // return await response?.data?.data
    } catch (error) {
      // console.log(error)
      rejectWithValue(error);
    }
  }
);

// ******************************************* SUPPLIER RULES SECTION *************************************************************************

// get supplierRules data
export const asyncGetSupplierRule = createAsyncThunk(
  "asyncGetSupplierRule/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/supplier-rule");
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// get supplierRules data

export const asyncPostSupplier = createAsyncThunk(
  "asyncPostSupplier/post",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      await HTTPForm.post("/api/v1/b2b/supplier-rule", formData);
      dispatch(asyncGetSupplierRule());
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// delete supplierRules data
export const asyncDeleteSupplierRule = createAsyncThunk(
  "asyncDeleteSupplierRule/post",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await NODEURL.delete(`/api/v1/b2b/supplier-rule/${id}`);
      dispatch(asyncGetSupplierRule());
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// edit supplierRules data
export const asyncEditSupplierData = createAsyncThunk(
  "asyncEditSupplierData/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/supplier-rule/${id}`);
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// update status data

export const asyncUpdateStatus = createAsyncThunk(
  "asyncUpdateStatus/patch",
  async (updateStatus, { rejectWithValue, dispatch }) => {
    try {
      await NODEURL.patch(`/api/v1/b2b/supplier-rule/status/${updateStatus}`);
      dispatch(asyncGetSupplierRule());
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// update supplierRules data
export const asyncUpdateSupplierRule = createAsyncThunk(
  "asyncUpdateSupplierRule/post",
  async ({ id, formData }, { rejectWithValue, dispatch }) => {
    try {
      await HTTPForm.patch(`/api/v1/b2b/supplier-rule/${id}`, formData);
      dispatch(asyncGetSupplierRule());
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// ******************************************* AIRLINE PRICING SECTION *************************************************************************
// get supplierRules data
export const asyncGetAirlinePricing = createAsyncThunk(
  "asyncGetAirlinePricing/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/airline-pricing");
      return await response.data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
export const asyncPostAirlinePricing = createAsyncThunk(
  "asyncPostAirlinePricing/post",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/airline-pricing", {
        bg_id: data?.bg_id,
        service_type: data?.service_type,
      });
      dispatch(asyncGetAirlinePricing());
      return await response.data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
export const asyncDeleteAirlinePricing = createAsyncThunk(
  "asyncDeleteAirlinePricing/delete",
  async (pricing_id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(
        `/api/v1/b2b/airline-pricing/${pricing_id}`
      );
      dispatch(asyncGetAirlinePricing());
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
export const asyncEditAirlinePricing = createAsyncThunk(
  "asyncEditAirlinePricing/get",
  async (pricing_id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/airline-pricing/${pricing_id}`
      );
      return await response.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);
export const asyncUpdateAirlinePricing = createAsyncThunk(
  "asyncEditAirlinePricing/patch",
  async (editPricing, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/airline-pricing/${editPricing?.pricing_id}`,
        editPricing
      );
      dispatch(asyncGetAirlinePricing());
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const Price_Airlines_SuppRoleSlice = createSlice({
  name: "Price_Airlines_SuppRoleSlice",
  initialState,
  reducers: {
    editAirlinesData_null(state) {
      state.editAirlinesData = null;
    },
    editSupplierRuleNull(state) {
      state.editSupplierRule = null;
    },
    editAirlinePricing_Null(state) {
      state.editPricingData = null;
    },
  },
  extraReducers: (builder) => {
    // get data...
    builder.addCase(asyncGetAddAirlinesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAddAirlinesData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.addAirLinesData = payload;
    });
    builder.addCase(asyncGetAddAirlinesData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //post data........
    builder.addCase(asyncPostAddAirlinesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostAddAirlinesData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.addAirLinesData = payload;
      }
    );
    builder.addCase(asyncPostAddAirlinesData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //edit data........
    builder.addCase(asyncEditAddAirlinesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncEditAddAirlinesData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.editAirlinesData = payload;
      }
    );
    builder.addCase(asyncEditAddAirlinesData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //Update data........
    builder.addCase(
      asyncUpdateAddAirlinesData.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateAddAirlinesData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.addAirLinesData = payload;
      }
    );
    builder.addCase(
      asyncUpdateAddAirlinesData.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // ******************************************* SUPPLIER RULES SECTION *************************************************************************
    //post supplier rules data
    builder.addCase(asyncPostSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostSupplier.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // asyncUpdateStatus

    builder.addCase(asyncUpdateStatus.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateStatus.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateStatus.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //get supplier rules data
    builder.addCase(asyncGetSupplierRule.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSupplierRule.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.supplierRulesData = payload;
    });
    builder.addCase(asyncGetSupplierRule.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //delete supplier rules data
    builder.addCase(asyncDeleteSupplierRule.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteSupplierRule.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteSupplierRule.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      // const deletedId = payload; // Modify this based on your payload structure
      //   state.supplierRulesData = state.supplierRulesData.filter(item => item.id !== deletedId);
    });
    //Edit supplier rules data
    builder.addCase(asyncEditSupplierData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditSupplierData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editSupplierRule = payload;
    });
    builder.addCase(asyncEditSupplierData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //update supplier rules data
    builder.addCase(asyncUpdateSupplierRule.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateSupplierRule.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateSupplierRule.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // ******************************************* AIRLINE PRICING SECTION *************************************************************************
    //get airline pricing data
    builder.addCase(asyncGetAirlinePricing.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAirlinePricing.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.airlinePricing = payload;
    });
    builder.addCase(asyncGetAirlinePricing.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    //post airline pricing data
    builder.addCase(asyncPostAirlinePricing.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostAirlinePricing.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.airlinePricing = [...state.airlinePricing, payload];
    });
    builder.addCase(asyncPostAirlinePricing.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    //delete airline pricing data
    builder.addCase(asyncDeleteAirlinePricing.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncDeleteAirlinePricing.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncDeleteAirlinePricing.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    //Edit airline pricing data
    builder.addCase(asyncEditAirlinePricing.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditAirlinePricing.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editPricingData = payload;
    });
    builder.addCase(asyncEditAirlinePricing.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    //Update airline pricing data
    builder.addCase(asyncUpdateAirlinePricing.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncUpdateAirlinePricing.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateAirlinePricing.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});
export const {
  editAirlinesData_null,
  editSupplierRuleNull,
  editAirlinePricing_Null,
} = Price_Airlines_SuppRoleSlice.actions;
export default Price_Airlines_SuppRoleSlice.reducer;
