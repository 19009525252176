import React, { useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { AiOutlinePlus } from 'react-icons/ai';

const MealComp = ({ image,meal, mealCode, mealPack, price,indexx, mealDescription, setFieldValue, paxIndex, isSelected, handleMealSelection }) => {
  const imageUrl = image.replace(/"/g, '');

  // useEffect(() => {
  //   if ((price === '0.00' || price==="0") && !isSelected) {
  //     // handleAddMeal( mealDescription, mealCode, price);
  //     const baggageKey = indexx === 0 ? 'outbound' : 'inbound';
  //     setFieldValue(`pax[${paxIndex}].${baggageKey}.meal`, { mealDescription, mealCode, price });
  //   }
  // }, [paxIndex]);

  const handleAddMeal = ( ) => {
    
    const baggageKey = indexx===0 ? 'outbound' : 'inbound';
    handleMealSelection(!isSelected);
    setFieldValue(`pax[${paxIndex}].${baggageKey}.meal`, isSelected ? null : {mealDescription,mealCode, price} );
  };
 

  return (
    <Box sx={{ display: 'flex', width: '90%', alignItems: "center", marginBottom: '10px', paddingBottom: '10px', marginTop: '20px' }} alignItems='center'>
      <Box sx={{ flex: 1 }}>
        <img src={imageUrl} alt="" height={112} />
      </Box>
      <Box sx={{ width: '55%', pl: 6, }}>
        <Typography variant="h6" fontWeight="bold">
          {mealCode}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: '0.8rem', mr: 2 }}>{mealDescription}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Typography align="right">PKR <span style={{ fontWeight: 'bold', fontSize: '1.125rem' }}>{price}</span></Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: "#355a6b", color: 'white', borderRadius: '4px', px: 2, py: 1, mt: 2, cursor: 'pointer', width: 160 }}
          >
          <AiOutlinePlus size={18} />
          <Typography onClick={handleAddMeal} variant="body1" sx={{ pl: 1, whiteSpace: 'nowrap' }}>Add This Meal</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MealComp;
