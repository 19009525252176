import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";
const initialState = {
  flashData: [],
  editflashId: null,
  showModel: false,
  loading: false,
  error: null,
};

// get flash
export const asyncGetAddFlshNewsData = createAsyncThunk(
  "asyncGetAddFlshNewsData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/flash-news/");
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post flash
export const asyncPostAddFlshNewsData = createAsyncThunk(
  "asyncPostAddFlashNewsData/post",
  async (flash_news, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/flash-news`, {
        news: flash_news,
      });
      dispatch(asyncGetAddFlshNewsData());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Delete flash
export const asyncDeleteflshnNewsData = createAsyncThunk(
  "asyncDeleteflashNewsData/Delete",
  async (rowData, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(
        `/api/v1/b2b/flash-news/${rowData}`
      );
      dispatch(asyncGetAddFlshNewsData());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//Edit flash
export const asyncEditflashNewsData = createAsyncThunk(
  "asyncEditflashNewsData/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/flash-news/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//update flash
export const asyncUpdateFlashNewsData = createAsyncThunk(
  "asyncUpdateFlashNewsData/Update",
  async ({ id, flash_news }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(`/api/v1/b2b/flash-news/${id}`, {
        news: flash_news,
      });
      dispatch(asyncGetAddFlshNewsData());
      return await response.data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const AddFlahNews = createSlice({
  name: "AddFlashSlice",
  initialState,
  reducers: {
    toggleEditId: (state) => {
      state.editflashId = null;
    },
  },

  extraReducers: (builder) => {
    // getting flashnewsdata
    builder.addCase(asyncGetAddFlshNewsData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAddFlshNewsData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.flashData = payload;
    });
    builder.addCase(asyncGetAddFlshNewsData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting flashnewsdata
    builder.addCase(asyncPostAddFlshNewsData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostAddFlshNewsData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(asyncPostAddFlshNewsData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //deleting flashnewsData
    builder.addCase(asyncDeleteflshnNewsData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncDeleteflshnNewsData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(asyncDeleteflshnNewsData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //edit flashnewsData
    builder.addCase(asyncEditflashNewsData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditflashNewsData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editflashId = payload;
    });
    builder.addCase(asyncEditflashNewsData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // update flashnewData
    builder.addCase(asyncUpdateFlashNewsData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncUpdateFlashNewsData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(asyncUpdateFlashNewsData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { toggleModel, toggleEditId } = AddFlahNews.actions;

export default AddFlahNews.reducer;
