import { Skeleton } from "@mui/material";
import React from "react";

function FlightSkeleton() {
  return (
    <div className="search_engin_result_box bg-white">
      <div className="shadow">
        <div className="row align-items-center">
          <div className="col-3">
            <div className="d-flex flex-column justify-content-center align-items-center py-4">
              <Skeleton variant="circular" width={95} height={95} />
              <Skeleton width={"80px"} height={20} />
            </div>
          </div>
          <div className="col-2">
            <div className="py-4 px-3">
              <Skeleton width={"70%"} height={30} />
              <div className="my-1" />
              <Skeleton width={"40%"} height={20} />
              <div className="my-1" />
              <Skeleton width={"60px"} height={20} />
            </div>
          </div>
          <div className="col-2">
            <div className="py-4 px-3">
              <Skeleton width={"50%"} height={30} />
              <div className="my-1" />
              <Skeleton width={"60%"} height={20} />
              <div className="my-1" />
              <Skeleton width={"50%"} height={30} />
            </div>
          </div>
          <div className="col-2">
            <div className="py-4 px-3">
              <Skeleton width={"70%"} height={30} />
              <div className="my-1" />
              <Skeleton width={"40%"} height={20} />
              <div className="my-1" />
              <Skeleton width={"60px"} height={20} />
            </div>
          </div>
          <div className="col-3">
            <div className="py-4 px-3">
              <Skeleton width={"80%"} height={30} />
              <Skeleton width={"100%"} height={90} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlightSkeleton;
