import * as yup from "yup";

export const voucherType = [
  { id: 1, title: "Journal Voucher" },
  { id: 2, title: "Payment Voucher" },
  { id: 3, title: "Reciept Voucher" },
  { id: 4, title: "Sale Invoice" },
  { id: 5, title: "Credit Note" },
];

// Drop down item
export const dropMenuProp = {
  PaperProps: {
    sx: {
      width: "inherit",
    },
  },
};

///////// validation schema
export const validationSchema = yup.object().shape({
  Description: yup.string().required("Description Required "),
  // agent_name: yup.string().required("agent Name Required"),
  // reciep_pay_type: yup.string().required("Please select atleast one"),
  // pay_type: yup.string().required("Pay Type Required"),
});
