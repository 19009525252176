// i have added
// (1) banner page
// (2) about us page
// (3) servics page
// pages in this slice from agency section

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTPForm, NODEURL } from "../../ApiConfig";

const initialState = {
  // banner data
  bannerStateData: [],
  editbanner: null,

  // about
  aboutUsData: [],
  editAboutus: null,

  //services
  servicesdata: [],
  editServices: null,
  loading: false,
  error: null,

  //ourpartner
  partnerStateData: [],
  editpartner: null,

  //footergallery
  footerStateData: [],
  editfooter: null,
};

// ******************************************* BANNER SECTION *************************************************************************
//get
export const asyncGetBannerData = createAsyncThunk(
  "asyncGetBannerData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/home-banner");
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// patch
export const asyncUpdateBannerData = createAsyncThunk(
  "asyncUpdateBannerData/patch",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.patch(
        "/api/v1/b2b/home-banner/" + id,
        formData
      );
      dispatch(asyncGetBannerData());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//post
export const asyncPostBannerData = createAsyncThunk(
  "asyncPostBannerData/post",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(`/api/v1/b2b/home-banner`, formData);
      dispatch(asyncGetBannerData());
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Edit
export const asyncEditBannerData = createAsyncThunk(
  "asyncEditBannerData/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/home-banner/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Delete
export const asyncDeleteBannerData = createAsyncThunk(
  "asyncDeleteBannerData/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/home-banner/${id}`);
      dispatch(asyncGetBannerData());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// ******************************************* ABOUT-US SECTION *************************************************************************

export const asyncGetAboutUsData = createAsyncThunk(
  "asyncGetAboutUsData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/aboutus-view");
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncUpdateAboutUs = createAsyncThunk(
  "asyncUpdateAboutUs/patch",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.patch(
        "/api/v1/b2b/aboutus-view/" + id,
        formData
      );
      dispatch(asyncGetAboutUsData());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const asyncPostAboutus = createAsyncThunk(
  "asyncPostAboutus/post",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(
        `/api/v1/b2b/aboutus-view`,
        formData
      );
      dispatch(asyncGetAboutUsData());
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Edit
export const asyncEditAboutUs = createAsyncThunk(
  "asyncEditAboutUs/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/aboutus-view/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Delete
export const asyncDeleteAboutUs = createAsyncThunk(
  "asyncEditAboutUs/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/aboutus-view/${id}`);
      dispatch(asyncGetAboutUsData());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// *******************************************Our-Services SECTION thunk **********************************************************************
//get
export const asyncGetOurServicesData = createAsyncThunk(
  "asyncGetOurServicesData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/our-services");
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// // patch
export const asyncUpdateOurServices = createAsyncThunk(
  "asyncUpdateOurServices/patch",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.patch(
        "/api/v1/b2b/our-services/" + id,
        formData
      );
      dispatch(asyncGetOurServicesData());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// //post
export const asyncPostOurServices = createAsyncThunk(
  "asyncPostAboutus/post",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(
        `/api/v1/b2b/our-services`,
        formData
      );
      dispatch(asyncGetOurServicesData());
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//Edit
export const asyncEditOurServices = createAsyncThunk(
  "asyncEditOurServices/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/our-services/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Delete
export const asyncDeleteOurServices = createAsyncThunk(
  "asyncDeleteOurServices/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/our-services/${id}`);
      dispatch(asyncGetOurServicesData());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// ******************************************* OurPartner section *************************************************************************
//get
export const asyncGetOurPartner = createAsyncThunk(
  "asyncGetOurPartner/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/our-partner");
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// patch
export const asyncUpdateOurPartner = createAsyncThunk(
  "asyncUpdateOurPartner/patch",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.patch(
        "/api/v1/b2b/our-partner/" + id,
        formData
      );
      dispatch(asyncGetOurPartner());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//post
export const asyncPostOurPartner = createAsyncThunk(
  "asyncPostOurPartner/post",
  async (formData, { rejectWithValue, dispatch }) => {
    console.log(formData);
    try {
      const response = await HTTPForm.post(`/api/v1/b2b/our-partner`, formData);
      dispatch(asyncGetOurPartner());
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Edit
export const asyncEditOurPartner = createAsyncThunk(
  "asyncEditOurPartner/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/our-partner/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Delete
export const asyncDeleteOurPartner = createAsyncThunk(
  "asyncDeleteOurPartner/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/our-partner/${id}`);
      dispatch(asyncGetOurPartner());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// ******************************************* Our Footer Gallery Image section *************************************************************************
//get
export const asyncGetOurFooterGallery = createAsyncThunk(
  "asyncGetOurFooterGallery/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/home-footer");
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// patch
export const asyncUpdateOurFooterGallery = createAsyncThunk(
  "asyncUpdateOurFooterGallery/patch",
  async ({ formData, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.patch(
        "/api/v1/b2b/home-footer/" + id,
        formData
      );
      dispatch(asyncGetOurFooterGallery());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//post
export const asyncPostOurFooterGallery = createAsyncThunk(
  "asyncPostOurFooterGallery/post",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(`/api/v1/b2b/home-footer`, formData);
      dispatch(asyncGetOurFooterGallery());
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Edit
export const asyncEditOurFooterGallery = createAsyncThunk(
  "asyncEditOurFooterGallery/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/home-footer/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Delete
export const asyncDeleteOurFooterGallery = createAsyncThunk(
  "asyncDeleteOurFooterGallery/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/home-footer/${id}`);
      dispatch(asyncGetOurFooterGallery());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const Banner_About_Services_Slice = createSlice({
  name: "Banner_About_Services_Slice",
  initialState,
  reducers: {
    editAbout_null(state) {
      state.editAboutus = null;
    },
    editServices_null(state) {
      state.editServices = null;
    },
    editbanner_null(state) {
      state.editbanner = null;
    },
    editpartner_null(state) {
      state.editpartner = null;
    },
    editfootergallery_null(state) {
      state.editfooter = null;
    },
  },

  extraReducers: (builder) => {
    // ******************************************* BANNER SECTION *************************************************************************

    //get
    builder.addCase(asyncGetBannerData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetBannerData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.bannerStateData = payload;
    });
    builder.addCase(asyncGetBannerData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // update banner data
    builder.addCase(asyncUpdateBannerData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateBannerData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateBannerData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post Banner data

    builder.addCase(asyncPostBannerData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostBannerData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostBannerData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //  Edit banner data

    builder.addCase(asyncEditBannerData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditBannerData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editbanner = payload;
    });
    builder.addCase(asyncEditBannerData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Deleted banner data
    builder.addCase(asyncDeleteBannerData.pending, (state, { payload }) => {
      state.error = null;
    });
    builder.addCase(asyncDeleteBannerData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteBannerData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // ******************************************* ABOUT-US SECTION *************************************************************************
    // get about data
    builder.addCase(asyncGetAboutUsData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAboutUsData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.aboutUsData = payload;
    });
    builder.addCase(asyncGetAboutUsData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // update about data
    builder.addCase(asyncUpdateAboutUs.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateAboutUs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateAboutUs.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post about data

    builder.addCase(asyncPostAboutus.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostAboutus.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostAboutus.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //  Edit about data

    builder.addCase(asyncEditAboutUs.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditAboutUs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editAboutus = payload;
    });
    builder.addCase(asyncEditAboutUs.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Deleted About data
    builder.addCase(asyncDeleteAboutUs.pending, (state, { payload }) => {
      state.error = null;
    });
    builder.addCase(asyncDeleteAboutUs.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteAboutUs.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // ******************************************* Our-Services SECTION **********************************************************************
    // get our services data
    builder.addCase(asyncGetOurServicesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetOurServicesData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.servicesdata = payload;
    });
    builder.addCase(asyncGetOurServicesData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // // update Our services data
    builder.addCase(asyncUpdateOurServices.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateOurServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateOurServices.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // // Edit our services data

    builder.addCase(asyncEditOurServices.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditOurServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editServices = payload;
    });
    builder.addCase(asyncEditOurServices.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Deleted Services data
    builder.addCase(asyncDeleteOurServices.pending, (state, { payload }) => {
      state.error = null;
    });
    builder.addCase(asyncDeleteOurServices.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteOurServices.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // ******************************************* Our-Partner SECTION **********************************************************************
    // get our partner data
    builder.addCase(asyncGetOurPartner.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetOurPartner.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.partnerStateData = payload;
    });
    builder.addCase(asyncGetOurPartner.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // // update Our partner data
    builder.addCase(asyncUpdateOurPartner.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateOurPartner.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateOurPartner.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post Banner data

    builder.addCase(asyncPostOurPartner.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostOurPartner.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostOurPartner.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // // Edit our partner data

    builder.addCase(asyncEditOurPartner.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditOurPartner.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editpartner = payload;
    });
    builder.addCase(asyncEditOurPartner.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Deleted our partner data
    builder.addCase(asyncDeleteOurPartner.pending, (state, { payload }) => {
      state.error = null;
    });
    builder.addCase(asyncDeleteOurPartner.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteOurPartner.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // ******************************************* Footer Gallery SECTION **********************************************************************
    // get Footer Gallery data
    builder.addCase(asyncGetOurFooterGallery.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetOurFooterGallery.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.footerStateData = payload;
      }
    );
    builder.addCase(asyncGetOurFooterGallery.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // // update Footer Gallery data
    builder.addCase(
      asyncUpdateOurFooterGallery.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateOurFooterGallery.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateOurFooterGallery.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // post Banner data

    builder.addCase(asyncPostOurFooterGallery.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostOurFooterGallery.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncPostOurFooterGallery.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // // Edit footer gallery data

    builder.addCase(asyncEditOurFooterGallery.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncEditOurFooterGallery.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.editfooter = payload;
      }
    );
    builder.addCase(
      asyncEditOurFooterGallery.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Deleted footer gallery data
    builder.addCase(
      asyncDeleteOurFooterGallery.pending,
      (state, { payload }) => {
        state.error = null;
      }
    );
    builder.addCase(
      asyncDeleteOurFooterGallery.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncDeleteOurFooterGallery.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const {
  editAbout_null,
  editServices_null,
  editbanner_null,
  editpartner_null,
  editfootergallery_null,
} = Banner_About_Services_Slice.actions;

export default Banner_About_Services_Slice.reducer;
