import React from "react";
import Header from "./components/Common/Header";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import ProtectedRoute from "./components/Common/ProtectedRoute";

const Layout = ({ children }) => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <ProtectedRoute>
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex flex-column justify-content-between gap-4"
      >
        <div className="">
          {pathname !== "/" && <Header />}
          {children}
        </div>
        {pathname !== "/" && <Footer />}
      </div>
    </ProtectedRoute>
  );
};

export default Layout;
