import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  SabreCredentialsData: [],
  editSabreCredentials: null,
  loading: false,
  error: null,
};

// post ER API CREDENTIAL
export const asyncPostSabreApiCredentials = createAsyncThunk(
  "asyncPostSabreApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/sabre-credential`,
        values
      );
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get ER API CREDENTIAL
export const asyncGetSabreApiCredentials = createAsyncThunk(
  "asyncGetSabreApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/sabre-credential`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get single ER API CREDENTIAL
export const asyncGetSingleSabreApiCred = createAsyncThunk(
  "asyncGetSingleSabreApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/sabre-credential/${id}`);

      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// Update ER API CREDENTIAL
export const asyncUpdateSabreCredential = createAsyncThunk(
  "asyncUpdateSabreCredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/sabre-credential/${id}`,
        values
      );
      dispatch(asyncGetSabreApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Update ER API CREDENTIAL
export const asyncDeleteSabreCredential = createAsyncThunk(
  "asyncDeleteSabreCredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(
        `/api/v1/b2b/sabre-credential/${id}`
      );
      dispatch(asyncGetSabreApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const SabreApiCredentialSlice = createSlice({
  name: "SabreApiCredentialSlice",
  initialState,
  reducers: {
    editSabreCredentialNull: (state) => {
      state.editSabreCredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post ER Api Credentials
    builder.addCase(
      asyncPostSabreApiCredentials.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncPostSabreApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.SabreCredentialsData = [...state.SabreCredentialsData, payload];
      }
    );
    builder.addCase(
      asyncPostSabreApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get ER Api Credentials
    builder.addCase(
      asyncGetSabreApiCredentials.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetSabreApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.SabreCredentialsData = payload;
      }
    );
    builder.addCase(
      asyncGetSabreApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // Get single ER Api Credentials
    builder.addCase(
      asyncGetSingleSabreApiCred.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetSingleSabreApiCred.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.editSabreCredentials = payload;
      }
    );
    builder.addCase(
      asyncGetSingleSabreApiCred.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // Update ER Api Credentials
    builder.addCase(
      asyncUpdateSabreCredential.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateSabreCredential.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateSabreCredential.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // DElete ER Api Credentials
    builder.addCase(
      asyncDeleteSabreCredential.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncDeleteSabreCredential.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncDeleteSabreCredential.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});
export const { editSabreCredentialNull } = SabreApiCredentialSlice.actions;
export default SabreApiCredentialSlice.reducer;
