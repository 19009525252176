import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetDefaultAirportDestination,
  asyncGetDefaultOriginAirport,
  asyncGetDestinationAirportsName,
  asyncGetOriginAirportsName,
} from "../../../redux/features/home/homeSlice";
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { MdClose, MdFlightLand, MdFlightTakeoff } from "react-icons/md";

const FlightInfo = ({ values, setFieldValue, FieldArray }) => {
  const dispatch = useDispatch();
  let inputRef = useRef(null);
  const [drp, setdrp] = useState("");

  const { originAirportList, destinatioAirportList } = useSelector(
    (state) => state.home
  );

  useEffect(() => {
    dispatch(asyncGetDefaultOriginAirport());
    dispatch(asyncGetDefaultAirportDestination());
  }, [dispatch]);
  return (
    <FieldArray
      name="flightInfo"
      render={(arrayHelpers) => (
        <ClickAwayListener onClickAway={() => setdrp("")}>
          <div>
            {values?.flightInfo && values?.flightInfo?.length > 0
              ? values?.flightInfo?.map((fltInfo, index) => (
                  <div key={index}>
                    <div className="row" style={{ marginTop: 38 }}>
                      <div
                        style={{
                          marginTop: 23,
                          width: 80,
                          whiteSpace: "nowrap",
                        }}
                      >
                        <h6>{index + 1}. Flight</h6>
                      </div>

                      <div className="row col-md-4">
                        <div className="col-6 col-md-6 relative">
                          <FormControl
                            variant="standard"
                            className="w-100"
                            onClick={() => {
                              setdrp(`from${index}`);
                            }}
                            required
                          >
                            <InputLabel htmlFor={`flightInfo.${index}.from`}>
                              From Where
                            </InputLabel>
                            <Input
                              autoComplete="off"
                              id={`flightInfo.${index}.from`}
                              value={values.flightInfo[index].from}
                              name={`flightInfo.${index}.from`}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue(
                                  `flightInfo.${index}.from`,
                                  value
                                );
                                value === ""
                                  ? dispatch(asyncGetDefaultOriginAirport())
                                  : dispatch(asyncGetOriginAirportsName(value));
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  {values.flightInfo[index].from !== "" && (
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue(
                                          `flightInfo.${index}.from`,
                                          ""
                                        );
                                        dispatch(
                                          asyncGetDefaultOriginAirport()
                                        );
                                      }}
                                    >
                                      <MdClose />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {drp === `from${index}` ? (
                            <div className="airport_search_drp">
                              {originAirportList?.length > 0 ? (
                                originAirportList?.map((item) => {
                                  return (
                                    <div
                                      key={item.code}
                                      className="airport_search_drp_item"
                                      onClick={() => {
                                        let value = `${item?.code}-${item?.airport_name}-${item?.city}`;
                                        setFieldValue(
                                          `flightInfo.${index}.from`,
                                          value
                                        );
                                        inputRef.current.focus();
                                        setdrp(`to${index}`);
                                      }}
                                    >
                                      <MdFlightTakeoff className="me-3 fs-3 color_primary" />
                                      <div className="fs-5">
                                        {item?.code} - {item?.airport_name} -{" "}
                                        {item?.city}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="airport_search_drp_item">
                                  No Record Found.
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-6 col-md-6">
                          <FormControl
                            variant="standard"
                            className="w-100"
                            onClick={() => {
                              setdrp(`to${index}`);
                            }}
                            required
                          >
                            <InputLabel htmlFor={`flightInfo.${index}?.to`}>
                              To Where
                            </InputLabel>
                            <Input
                              autoComplete="off"
                              id={`flightInfo.${index}?.to`}
                              value={values?.flightInfo[index]?.to}
                              name={`flightInfo.${index}.to`}
                              inputRef={inputRef}
                              onChange={(e) => {
                                let value = e.target.value;
                                setFieldValue(`flightInfo.${index}.to`, value);
                                value === ""
                                  ? dispatch(
                                      asyncGetDefaultAirportDestination()
                                    )
                                  : dispatch(
                                      asyncGetDestinationAirportsName(value)
                                    );
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  {values.flightInfo[index].to !== "" && (
                                    <IconButton
                                      onClick={() => {
                                        setFieldValue(
                                          `flightInfo.${index}.to`,
                                          ""
                                        );
                                        dispatch(
                                          asyncGetDefaultAirportDestination()
                                        );
                                      }}
                                    >
                                      <MdClose />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                          {drp === `to${index}` ? (
                            <div className="airport_search_drp">
                              {destinatioAirportList?.length > 0 ? (
                                destinatioAirportList?.map((item) => {
                                  return (
                                    <div
                                      key={item.code}
                                      className="airport_search_drp_item"
                                      onClick={() => {
                                        let value = `${item.code}-${item.airport_name}-${item.city}`;
                                        setFieldValue(
                                          `flightInfo.${index}.to`,
                                          value
                                        );
                                        setdrp("");
                                      }}
                                    >
                                      <MdFlightTakeoff className="me-3 fs-3 color_primary" />
                                      <div className="fs-5">
                                        {item.code} - {item.airport_name} -{" "}
                                        {item.city}
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="airport_search_drp_item">
                                  No Record Found.
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-6 col-md-1" style={{ marginTop: 22 }}>
                        <ReactDatePicker
                          minDate={new Date()}
                          className="date_picker w-100"
                          placeholderText="Departure Date"
                          selected={
                            values.flightInfo[index].dep_date
                              ? new Date(values.flightInfo[index].dep_date)
                              : null
                          }
                          onChange={(date) => {
                            setFieldValue(`flightInfo.${index}.dep_date`, date); // Assuming date is in the required format
                            setFieldValue(
                              `flightInfo.${index}.arrival_date`,
                              date
                            );
                          }}
                        />
                      </div>
                      <div className="col-6 col-md-1" style={{ marginTop: 22 }}>
                        <DatePicker
                          selected={
                            values.flightInfo[index].dep_time
                              ? new Date(
                                  `1970-01-01T${values.flightInfo[index].dep_time}`
                                )
                              : null
                          }
                          autoComplete="off"
                          name={`flightInfo.${index}.dep_time`}
                          value={
                            values.flightInfo[index].dep_time
                              ? new Date(
                                  `1970-01-01T${values.flightInfo[index].dep_time}`
                                )
                              : null
                          }
                          className="date_picker"
                          calendarClassName="custom-timepicker"
                          onChange={(e) => {
                            const dateString = e;
                            const dateObject = new Date(dateString);

                            const hours = dateObject.getHours();
                            const minutes = dateObject.getMinutes();

                            const formattedHours = hours
                              .toString()
                              .padStart(2, "0");
                            const formattedMinutes = minutes
                              .toString()
                              .padStart(2, "0");

                            const timeString = `${formattedHours}:${formattedMinutes}`;

                            setFieldValue(
                              `flightInfo.${index}.dep_time`,
                              timeString
                            );
                            setFieldValue(
                              `flightInfo.${index}.arrival_time`,
                              timeString
                            );
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          placeholderText="Dep Time"
                          timeCaption="Time"
                          dateFormat="HH:mm"
                          timeFormat="HH:mm"
                        />
                      </div>
                      <div className="col-6 col-md-1" style={{ marginTop: 22 }}>
                        <ReactDatePicker
                          minDate={new Date()}
                          className="date_picker"
                          placeholderText="Arrival Date"
                          selected={
                            values.flightInfo[index].arrival_date
                              ? new Date(values.flightInfo[index].arrival_date)
                              : null
                          }
                          onChange={(date) => {
                            setFieldValue(
                              `flightInfo.${index}.arrival_date`,
                              date
                            ); // Assuming date is in the required format
                          }}
                        />
                      </div>
                      <div className="col-6 col-md-1" style={{ marginTop: 22 }}>
                        <DatePicker
                          selected={
                            values.flightInfo[index].arrival_time
                              ? new Date(
                                  `1970-01-01T${values.flightInfo[index].arrival_time}`
                                )
                              : null
                          }
                          autoComplete="off"
                          name={`flightInfo.${index}.arrival_time`}
                          value={
                            values.flightInfo[index].arrival_time
                              ? new Date(
                                  `1970-01-01T${values.flightInfo[index].arrival_time}`
                                )
                              : null
                          }
                          className="date_picker"
                          calendarClassName="custom-timepicker"
                          onChange={(e) => {
                            const dateString = e;
                            const dateObject = new Date(dateString);

                            const hours = dateObject.getHours();
                            const minutes = dateObject.getMinutes();

                            const formattedHours = hours
                              .toString()
                              .padStart(2, "0");
                            const formattedMinutes = minutes
                              .toString()
                              .padStart(2, "0");

                            const timeString = `${formattedHours}:${formattedMinutes}`;

                            setFieldValue(
                              `flightInfo.${index}.arrival_time`,
                              timeString
                            );
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          placeholderText="Arrival Time"
                          timeCaption="Time"
                          dateFormat="HH:mm" // Use "HH:mm" for 24-hour format
                          timeFormat="HH:mm" // Use "HH:mm" for 24-hour format
                        />
                      </div>
                      <div className="col-6 col-md-1 ">
                        <TextField
                          variant="standard"
                          label="Airline Code"
                          className="w-100"
                          name={`flightInfo.${index}.airline_code`}
                          value={values.flightInfo[index].airline_code}
                          inputProps={{
                            maxLength: 2,
                            pattern: "[A-Z]",
                          }}
                          onChange={(e) => {
                            const value = e.target.value
                              .toUpperCase()
                              .replace(/[^A-Z]/g, "");
                            setFieldValue(
                              `flightInfo.${index}.airline_code`,
                              value
                            );
                          }}
                        />
                      </div>
                      <div className="col-6 col-md-1">
                        <TextField
                          variant="standard"
                          label="Airline No"
                          className="w-100"
                          name={`flightInfo.${index}.airline_no`}
                          value={values.flightInfo[index].airline_no}
                          type="number"
                          onChange={(e) => {
                            const value = e.target.value.toUpperCase();
                            setFieldValue(
                              `flightInfo.${index}.airline_no`,
                              value
                            );
                          }}
                        />
                      </div>
                      <div className="col-6 col-md-1">
                        {index === 0 ? (
                          <button
                            type="button"
                            className="addflt-btn"
                            disabled={values?.flightInfo?.length === 6}
                            onClick={() => {
                              arrayHelpers.push({
                                from: "",
                                to: "",
                                dep_date: "",
                                dep_time: "",
                                arrival_date: "",
                                arrival_time: "",
                                airline_code: "",
                                airline_no: "",
                              });
                              setdrp("");
                            }}
                          >
                            + Flight
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="removeflt-btn bg-danger"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            - Flight
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </ClickAwayListener>
      )}
    />
  );
};

export default FlightInfo;
