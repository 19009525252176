import React, { useEffect, useState } from "react";
import moment from "moment";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import {
  MdFlight,
  MdOutlineFlightLand,
  MdOutlineFlightTakeoff,
} from "react-icons/md";
import { NODEURL } from "../../../../../redux/features/ApiConfig";

const ConnectedFlight = ({
  timeDuration,
  amadeusItem,
  inx,
  flightSegment,
  index,
  segment,
}) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");

  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        let airportCode = amadeusItem?.departure?.iataCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        setairport_name(data);
      } catch (error) { }
    };
    const fetchDataArr = async () => {
      try {
        let airportCode = amadeusItem?.arrival?.iataCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        setArrairport_name(data);
      } catch (error) { }
    };
    fetchDataDep();
    fetchDataArr();
  }, []);

  return (
    <div key={inx}>
      <div className="row align-items-center">
        <div className="col-2">
          {segment === "multi" ? (
            <>
              {inx === 0 && (
                <h6 className="fw-bolder py-2 ps-2">{`Flight - ${index + 1}`}</h6>
              )}
            </>
          ) : null}
          <img
            src={`/NEW_LOGO/${amadeusItem?.carrierCode}.png`}
            alt="logo"
            width={70}
            height={70}
          />
          <h6 className="mt-3 ms-2">
            {`${amadeusItem?.carrierCode} ${amadeusItem?.number}`}
          </h6>

          {amadeusItem?.carrierCode ? (
            <div className="mt-2">Operated by : {amadeusItem?.operating?.carrierCode}</div>
          ) : null}
        </div>
        <div className="col-10 d-flex justify-content-between align-items-center">
          <div className="col-5 d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div className="">
              <h6>
                {moment(amadeusItem?.departure?.at?.split("T")?.at(0)).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>

              <h6 className="my-3">
                {moment(
                  amadeusItem?.departure?.at?.split("T")?.at(1),
                  "HH:mm:ss"
                ).format("HH:mm")}
              </h6>
              <h6>
                {amadeusItem?.departure?.iataCode} - {airport_name}
              </h6>
              {amadeusItem?.departure?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{amadeusItem?.departure?.terminal})
                </h6>
              )}
            </div>
          </div>

          <div className="col-2 d-flex flex-column align-items-start">
            <div className="d-flex justify-content-center">
              <MdFlight className="color_primary fs-5 rotate-right" />
              <h6 className="text-center">Trip Time</h6>
            </div>
            <div className="border_rounded mb-2" />
            <h6 className="text-center ms-4">
              {timeDuration(
                amadeusItem?.departure?.at,
                amadeusItem?.arrival?.at
              )}
            </h6>
          </div>
          <div className="col-5 d-flex align-items-center ps-4">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(amadeusItem?.arrival?.at?.split("T")?.at(0)).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>
              <h6 className="my-3">
                {moment(
                  amadeusItem?.arrival?.at?.split("T")?.at(1),
                  "HH:mm:ss"
                ).format("HH:mm")}
              </h6>
              <h6>
                {amadeusItem?.arrival?.iataCode} - {arr_airport_name}
              </h6>
              {amadeusItem?.arrival?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{amadeusItem?.arrival?.terminal})
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
      {inx < flightSegment.length - 1 && (
        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <div className="border_rounded w-25" />
          <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
            <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
            <h6 className="pt-2">
              Layover time:{" "}
              <span className="fs-5">
                {" "}
                {timeDuration(
                  amadeusItem?.arrival?.at,
                  flightSegment[inx + 1]?.departure?.at
                )}
              </span>
            </h6>
          </div>
          <div className="border_rounded w-25" />
        </div>
      )}
    </div>
  );
};

export default ConnectedFlight;
