import React, { useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import MealComp from "./MealComp";
import './ancillaryStyle.css'

const Meals = ({ meal, paxes, setFieldValue,selectedMeals,setSelectedMeals,indexx }) => {
  const [selectedPassengerIndex, setSelectedPassengerIndex] = useState(null);

  const [error, setError] = useState("");
  const handlePassengerClick = (paxIndex) => {
    setSelectedPassengerIndex(paxIndex);
    setError("");
  };

  const handleMealSelection = (mealName, isSelected) => {
    if (selectedPassengerIndex === null) {
      setError("Please select a passenger first."); // Set error if no passenger is selected
      return;
    }
    setSelectedMeals(prevState => ({
      ...prevState,
      [mealName]: isSelected
    }));
  };

  // const handleInputClick = (paxIndex) => {
  //   setSelectedPassengerIndex(paxIndex);
  //   setError("");
  // };

  const meals = meal?.["ns1:MealDetailsResponse"]["ns1:Meal"];
  
  return (
    <Box border={1} mx={6} mt={3} p={2} borderColor={"gray"} borderRadius={2} display='flex' flexDirection='row' gap={4} >
      <Box  border={1} p={1} mt={3} pr={4} borderLeft='gray' borderBottom='none' borderTop='none'>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            style={{ width: '20rem' }}
          >
            {paxes.map((pax, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                bgcolor={selectedPassengerIndex === index ? "#00324E" : "#19465F"}
                borderRadius={1}
                color="white"
                py={1}
                px={2}
                mt={2}
                justifyContent="space-between"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "auto",
                  textOverflow: "ellipsis",
                  cursor: "pointer"
                  
                }}
                onClick={() => handlePassengerClick(index)}
              >
              
                <Typography
                  variant="body1"
                  pl={4}
                  style={{
                    flex: '0 0 70%',
                    maxWidth: '70%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {`${pax.title}: ${pax.f_name} ${pax.l_name}`.slice(0, 16)}

                </Typography>
               
                <Box class="checkbox-wrapper-12">
                  <Box class="cbx">
                    <input
                      type="checkbox"
                      id={`cbx-${index}`} 
                      checked={selectedMeals[index]} 
                      // onChange={() => handleMealSelection(index, !selectedMeals[index])}
                      onClick={() => {
                        handlePassengerClick(index);
                      handleMealSelection(index, !selectedMeals[index]);
                    }}
                      />
                    <label for={`cbx-${index}`}></label>
                    <svg fill="none" viewBox="0 0 15 14" height="14" width="15">
                      <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>

          {/* error display goes here */}
          {error && (
            <Box ml={4} mt={2} bgcolor="red.100" fontSize={18} color="red">
              {error}
            </Box>
          )}
        </Box>

      <Box mt={4}>
      
        <Box sx={{ height: 380, overflow: "scroll", overflowX: "hidden" }}>
        {meals?.map((item, index) => (
          <React.Fragment key={index}>
          
            <MealComp
              paxIndex={selectedPassengerIndex}
              setFieldValue={setFieldValue}
              image={item["ns1:mealImageLink"]}
              mealCode={item["ns1:mealCode"]}
              mealDescription={item["ns1:mealDescription"]}
              price={parseFloat(item["ns1:mealCharge"])}
              indexx={indexx}
              meal={meal}
              isSelected={selectedMeals[selectedPassengerIndex] === index}
              handleMealSelection={(isSelected) => handleMealSelection(selectedPassengerIndex, isSelected)}
            />
            {index !== meals.length - 1 && <Divider sx={{ width: "100%" }} />}
          </React.Fragment>
        ))}
      </Box>
      
    </Box>
    </Box>
  );
};

export default Meals;
