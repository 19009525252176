import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../Utils/configUrl";
import { asyncGetPromotionData } from "../../redux/features/promotion/PromotionSlice";

function ImageSlider() {
  const dispatch = useDispatch();

  const { promotionData } = useSelector((state) => state.promotion);

  useEffect(() => {
    dispatch(asyncGetPromotionData());
  }, [dispatch]);

  return (
    <>
      <div className="mx-3 shadow-sm h-100 border ">
        <Carousel indicators={false} interval={2000}>
          {promotionData?.map((slider, i) => (
            <Carousel.Item key={slider.id}>
              <img
                className="d-block w-100 slider_image"
                src={`${apiUrl}/${slider?.image}`}
                alt={`Slide ${i + 1}`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default ImageSlider;
