// const user = JSON.parse(localStorage.getItem("loginUser"));

const checkMenuItemExistence = (userTypePermissions, menuArrays, user) => {
  return menuArrays?.filter((menuItem) => {
    // Check if user has permission for the page_level_id
    let isSubArrayExist = menuItem?.page_level_id?.length > 0;
    const hasPermission = userTypePermissions?.some((permission) =>
      isSubArrayExist
        ? menuItem.page_level_id?.some(
            (id) => id === permission.page_level_id
          ) && permission?.user_type_id === user?.role_id
        : permission?.user_type_id === user?.role_id &&
          permission.page_level_id === menuItem.page_level_id
    );

    // Show the menu item if it has permission or has nested items
    return hasPermission;
  });
};

export default checkMenuItemExistence;
