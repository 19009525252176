import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetCurrencyData,
  asyncPostCurrencyData,
  asyncEditCurrencyData,
  asyncUpdateCurrencyData,
  editCurrency_null,
} from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import BtnLoader from "../AppForm/BtnLoader";
import * as yup from "yup";
import { options } from "../../Utils/ColumnOptions";

const Currency = () => {
  const dispatch = useDispatch();
  const { currencyData, editCurrency, loading } = useSelector(
    (state) => state?.bank_curr_roleSlice
  );

  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  //if the login user has permissions to add new currencies
  const postPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/post-currency"
      )
    : true;
  // if the login user has permissions to edit the currency
  const editPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/update-currency"
      )
    : true;

  const initialValues = {
    name: editCurrency?.curr_name || "",
    code: editCurrency?.curr_code || "",
    roe: editCurrency?.roe || "",
  };
  const validationSchema = yup.object().shape({
    name: yup.string().required("Currency Name Required"),
    code: yup.string().required("Currency Code Required"),
    roe: yup.number().positive().required("Rate of Exchange Required"),
  });

  useEffect(() => {
    dispatch(asyncGetCurrencyData());
  }, [dispatch, editCurrency]);

  const columns = [
    {
      name: "currency_id",
      label: "ID",
    },
    {
      name: "curr_name",
      label: "Currency Name",
    },
    {
      name: "curr_code",
      label: "Currency Code",
    },
    {
      name: "roe",
      label: "Rate Of Exchange",
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                disabled={!editPermission && user.user_id !== 1}
                style={{
                  opacity: !editPermission && user.user_id !== 1 ? 0.5 : 1,
                }}
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncEditCurrencyData(tableMeta.rowData[0]));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];
  return (
    <div>
      <div className="mx-5 rounded-2 shadow-sm my-4 bg-white mb-5">
        <p className="h4 otawix-heading box_title">Add / Edit Currency</p>
        <Formik
          validateOnChange={true}
          validateOnBlur={false}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            const obj = {
              curr_name: values?.name,
              curr_code: values?.code,
              roe: parseFloat(values?.roe),
            };
            if (editCurrency) {
              dispatch(
                asyncUpdateCurrencyData({
                  id: editCurrency?.currency_id,
                  obj,
                })
              );
              dispatch(editCurrency_null());
            } else {
              dispatch(asyncPostCurrencyData(obj));
              resetForm({});
            }
          }}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className="row p-5  pb-3">
                  <div className="col-sm-2">
                    <TextField
                      name="name"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "name",
                          value.replace(/[^a-z]/gi, "").toUpperCase()
                        );
                      }}
                      label="Currency Name"
                      autoComplete="off"
                      className="w-100"
                      variant="standard"
                      inputProps={{ maxLength: 50 }}
                      value={values?.name}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="name"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      name="code"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue(
                          "code",
                          value.replace(/[^a-z]/gi, "").toUpperCase()
                        );
                      }}
                      autoComplete="off"
                      className="w-100"
                      label="Currency Code"
                      variant="standard"
                      inputProps={{ maxLength: 3 }}
                      value={values.code}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="code"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("roe", value);
                      }}
                      name="roe"
                      autoComplete="off"
                      className="w-100"
                      label="Rate Of Exchange"
                      type="number"
                      variant="standard"
                      value={values.roe}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="roe"
                    />
                  </div>
                </div>
                <div className="text-center my-5 pb-5">
                  {!editCurrency ? (
                    <button
                      className="setup-btn me-2"
                      type="submit"
                      disabled={!postPermission && user.user_id !== 1}
                      style={{
                        opacity:
                          !postPermission && user.user_id !== 1 ? 0.5 : 1,
                      }}
                    >
                      {loading ? <BtnLoader /> : "Save"}
                    </button>
                  ) : (
                    <>
                      <button className="setup-btn me-2" type="submit">
                        {loading ? <BtnLoader /> : "Update"}
                      </button>
                      <button
                        className="setup-btn "
                        onClick={(e) => {
                          dispatch(dispatch(editCurrency_null()));
                        }}
                        style={{ background: "#dc3545", color: "#fff" }}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className=" mx-5 rounded-2 shadow-sm my-4 bg-white">
        <p className="h4 otawix-heading box_title">Currency List</p>
        <div className="p-5">
          <MUIDataTable
            className="muidatatable"
            title={"Currency Details"}
            data={currencyData}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default Currency;
