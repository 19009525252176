import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP } from "../ApiConfig";

const initialState = {
  hotelRoomData: [],
  EditRoomType:null,
  loading: false,
  error: null,
};

// getting hotelRoomData data
export const asyncGetHotelRoomData = createAsyncThunk(
  "asyncGetHotelRoomData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/room_type`);
      // console.log(await response.data.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting Room Type data
export const asyncPostRoomType = createAsyncThunk(
  "asyncPostRoomType/post",
  async (vals, { rejectWithValue, dispatch }) => {
    console.log(vals);
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/room_type`,
        vals
      );
      dispatch(asyncGetHotelRoomData());
      // toast.success("data added");
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit RoomType data
export const asyncEditRoomtype = createAsyncThunk(
  "asyncEditRoomtype/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/room_type/${id}`
      );
      // console.log(await response.data);
      dispatch(asyncGetHotelRoomData());
      // toast.success("data added");
      return await response.data.data.edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// update Room Type data
export const asyncUpdateRoomtype = createAsyncThunk(
  "asyncUpdateRoomtype/post",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.put(
        `/api/v1/b2b/room_type/room_type_update`,values
      );
      // console.log(await response.data.data);
      dispatch(asyncGetHotelRoomData());
      // toast.success("data added");
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit hotel data
export const asyncDeleteHotelRoom = createAsyncThunk(
    "asyncDeleteHotelRoom/delete",
    async (id, { rejectWithValue, dispatch }) => {
      try {
        const response = await HTTP.get(
          `/api/v1/b2b/room_type/room_type_delete/${id}`
        );
        dispatch(asyncGetHotelRoomData());
      } catch (error) {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  );

export const HotelRoomSlice = createSlice({
  name: "HotelRoomSlice",
  initialState,

  reducers:{
    EditRoomTypeNull:(state)=>{
      state.EditRoomType=null
    }
  },
  extraReducers: (builder) => {
    // getting hotelRoomData
    builder.addCase(asyncGetHotelRoomData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetHotelRoomData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.hotelRoomData = payload;
    });
    builder.addCase(asyncGetHotelRoomData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting RoomType data
    builder.addCase(asyncPostRoomType.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostRoomType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostRoomType.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

        // edit hotel data
        builder.addCase(asyncEditRoomtype.pending, (state, { payload }) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(asyncEditRoomtype.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.error = null;
          state.EditRoomType = payload;
        });
        builder.addCase(asyncEditRoomtype.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });

                // update hotel data
                builder.addCase(asyncUpdateRoomtype.pending, (state, { payload }) => {
                  state.loading = true;
                  state.error = null;
                });
                builder.addCase(asyncUpdateRoomtype.fulfilled, (state, { payload }) => {
                  state.loading = false;
                  state.error = null;
                });
                builder.addCase(asyncUpdateRoomtype.rejected, (state, { payload }) => {
                  state.loading = false;
                  state.error = payload;
                });

                // delete hotelRoom data
                builder.addCase(asyncDeleteHotelRoom.pending, (state, { payload }) => {
                    state.loading = true;
                    state.error = null;
                  });
                  builder.addCase(asyncDeleteHotelRoom.fulfilled, (state, { payload }) => {
                    state.loading = false;
                    state.error = null;
                  });
                  builder.addCase(asyncDeleteHotelRoom.rejected, (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                  });
  },
});

export const {EditRoomTypeNull} =HotelRoomSlice.actions
export default HotelRoomSlice.reducer;
