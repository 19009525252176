import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { dropMenuProp } from "../../../../../Utils/MenuProps";
import { pax_title } from "../helper";

const PaxDetail = ({ setFieldValue, values }) => {
  return (
    <div className="section_box">
      <div className="">
        <div className="h6">Lead Passenger Name</div>
        <div className="row justify-content-start align-items-end my-4">
          <div className="col-2">
            <FormControl variant="standard" className="w-100" required>
              <InputLabel id={`title`}>Title</InputLabel>
              <Select
                id={`title`}
                value={values.title}
                onChange={(e) => {
                  let value = e.target.value;
                  setFieldValue("title", value);
                }}
                MenuProps={dropMenuProp}
                required
              >
                {pax_title?.map((it, ind) => (
                  <MenuItem value={it} key={ind}>
                    {it}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-2">
            <TextField
              value={values.f_name}
              onChange={(e) => {
                setFieldValue("f_name", e.target.value);
              }}
              label="First Name"
              variant="standard"
              className="w-100"
            />
          </div>
          <div className="col-2">
            <TextField
              value={values.l_name}
              onChange={(e) => {
                setFieldValue("l_name", e.target.value);
              }}
              label="Last Name"
              variant="standard"
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaxDetail;
