import React from 'react'
import AgentGroupsComp from '../components/Setup/AgentGroups'

const AgentGroups = () => {
  return (
    <div className='Full_height'>
      <AgentGroupsComp />
    </div>
  )
}

export default AgentGroups