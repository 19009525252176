import React from 'react'
import AgencyComp from "../components/Agency/AgencyData";

const FinanceAgency = () => {
  return (
    <div className='Full_height'>
      <AgencyComp />
    </div>
  )
}

export default FinanceAgency