import React from 'react'
import HotelRatesComp from '../components/Setup/Umrah/HotelRates'

const UmrahHotelRates = () => {
  return (
    <div className=''>
      <HotelRatesComp />
    </div>
  )
}

export default UmrahHotelRates