import React, { useEffect, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";

import { options } from "../../../Utils/ColumnOptions";
import {
  asyncEditVisatype,
  asyncGetVisaType,
  asyncPostVisaType,
  asyncUpdatevisaType,
  editNull,
} from "../../../redux/features/visa/VisaTypeSlice";
import { asyncGetVisaCountry } from "../../../redux/features/visa/CountrySlice";

const VisaType = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { visaType, loading, editvisaType } = useSelector(
    (state) => state?.visaType
  );
  const { visaCountry } = useSelector((state) => state.visaCountry);

  // If the user has permission to add visa type
  const addVisaTypePermissions = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/visa-type-post"
      )
    : true;

  // If the user has permission to edit visa type
  const editVisaTypePermissions = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/visa-type-update"
      )
    : true;

  useEffect(() => {
    dispatch(asyncGetVisaCountry());
    dispatch(asyncGetVisaType());
  }, [editvisaType]);

  const initialValues = {
    name: editvisaType?.country_id || "",
    type: editvisaType?.visa_type || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Country Name is required"),
    type: Yup.string().required("Visa Type is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    if (editvisaType) {
      const updateobj = {
        id: editvisaType?.id,
        country_id: values?.name,
        visa_type: values?.type,
      };
      dispatch(asyncUpdatevisaType(updateobj));
      dispatch(editNull());
      resetForm();
    } else {
      const postBody = {
        country_id: values?.name,
        visa_type: values?.type,
      };
      dispatch(asyncPostVisaType(postBody));
      resetForm();
    }
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "country_id",
      label: "Country Name",
      options: {
        customBodyRender: (value) => {
          const name = visaCountry?.find((it) => it.id === value);
          return <div>{name?.name}</div>;
        },
      },
    },
    {
      name: "visa_type",
      label: "Visa Type",
      // options: {
      //   customBodyRender: (value, tableMeta, updateValue) => {
      //     console.log(value);
      //     return (
      //       <ol>
      //         {value.map((req) => (
      //           <li className="d-flex justify-content-between">
      //             {req.id + " _  " + req.requirement}
      //             <span>
      //               <button
      //                 className="bg-white btn text-danger"
      //                 onClick={() => dispatch(asyncDeleteRequirement(req.id))}
      //               >
      //                 <FaTrash />
      //                 {/* <i className="fa fa-trash" style={{fontSize:"12px"}}></i> */}
      //               </button>
      //             </span>
      //           </li>
      //         ))}
      //       </ol>
      //     );
      //   },
      // },
    },
    {
      name: "Action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              disabled={!editVisaTypePermissions}
              style={{ opacity: !editVisaTypePermissions ? 0.5 : 1 }}
              onClick={() => {
                window.scroll({ top: 0, behavior: "smooth" });
                dispatch(asyncEditVisatype(id));
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Visa Type</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className="col-12 my-4 py-3 d-flex justify-content-around">
                  <div className="w-100 mx-3">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="country-name-select">
                        Country Name
                      </InputLabel>
                      <Select
                        id="country-name-select"
                        label="Country Name"
                        className="w-100"
                        value={values.name}
                        onChange={(e) => {
                          const value = e.target.value;

                          const existingVisa = visaType?.find(
                            (visa) =>
                              visa?.country_id === value &&
                              visa?.visa_type === values.type
                          );
                          if (existingVisa) {
                            setError(
                              "This visa type already exists for this country!"
                            );
                          } else {
                            setError("");
                          }
                          setFieldValue("name", value);
                        }}
                      >
                        {visaCountry?.map((country, index) => (
                          <MenuItem key={index} value={country?.id}>
                            {country?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ErrorMessage
                      component="div"
                      name="name"
                      className="text-danger mt-2"
                    />
                  </div>
                  <div className="w-100 mx-3 position-relative">
                    <TextField
                      id="standard-basic"
                      label="Visa Type"
                      variant="standard"
                      className="w-100"
                      name="type"
                      value={values.type}
                      onChange={(e) => {
                        const value = e.target.value;

                        const existingVisa = visaType?.find(
                          (visa) =>
                            visa?.country_id === values.name &&
                            visa?.visa_type === value
                        );

                        if (existingVisa) {
                          setError(
                            "This visa type already exists for this country!"
                          );
                        } else {
                          setError("");
                        }

                        setFieldValue("type", value.toUpperCase());
                      }}
                    />
                    {error && (
                      <div
                        className="text-danger mt-1"
                        style={{ position: "absolute", top: 50 }}
                      >
                        {error}
                      </div>
                    )}
                    <ErrorMessage
                      component="div"
                      name="type"
                      className="text-danger mt-2"
                    />
                  </div>
                </div>
                <div className="col-md-12 text-center mt-5">
                  {!editvisaType ? (
                    <button
                      className="btn setup-btn text-align: center"
                      type="submit"
                      disabled={
                        loading || !addVisaTypePermissions
                          ? true
                          : false || error
                      }
                    >
                      {loading ? <BtnLoader /> : "save"}
                    </button>
                  ) : (
                    <button
                      className="btn setup-btn text-align: center"
                      type="submit"
                      disabled={
                        loading || !editVisaTypePermissions
                          ? true
                          : false || error
                      }
                    >
                      {loading ? <BtnLoader /> : "Update"}
                    </button>
                  )}

                  {editvisaType && (
                    <button
                      className="btn bg-danger text-white mx-auto ms-5"
                      onClick={(e) => dispatch(editNull())}
                      style={{
                        paddingLeft: 40,
                        paddingRight: 40,
                        paddingTop: 11,
                        paddingBottom: 11,
                      }}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="col-12 mt-5 px-3">
        <MUIDataTable
          className="muidatatable"
          title={"Visa Type Details"}
          data={visaType}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default VisaType;
