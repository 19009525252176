import React, { useEffect } from "react";

import {
  asyncGetBankDetailData,
  asyncGetCurrencyData,
} from "../../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAccountData } from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import Voucher from "./Voucher";
import { asyncGetAgentGroup } from "../../../../redux/features/setupRestPages/agentsSlice";
import { asyncGetNewAgencyReg } from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import VoucherCreated from "./VoucherCreated";
import { getSingleVoucherLog } from "../../../../redux/features/booking/bookingDetailsSlice";
import { useParams } from "react-router-dom";

function VoucherEntry() {
  const dispatch = useDispatch();
  const params = useParams()
  const { getSingleVoucher } = useSelector((state) => state?.voucherEntries);
  const voucherId = getSingleVoucher?.data?.data?.voucher_id || ~~params?.id;
 const { singleVoucherLog } = useSelector((state) => state?.booking);
  //////// use effect
  useEffect(
    () => {
      dispatch(asyncGetCurrencyData());
      dispatch(asyncGetAccountData());
      dispatch(asyncGetBankDetailData());
      dispatch(asyncGetAgentGroup());
      dispatch(asyncGetNewAgencyReg());
        dispatch(getSingleVoucherLog({ voucher_id:voucherId }));
    },
    [dispatch],
    voucherId
  );
  //   const location = useLocation();

  //   const voucherId = location.state?.voucherId;
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("use effect");
  //   let id = voucherId;
  //   dispatch(AsyncGetSingleVoucherEntries({ id }));
  // }, [dispatch, voucherId]);

  return (
    <div className="finance-container">
      <div className="col-11 mx-auto bg-white my-5 shadow-sm rounded-2">
        <div className="h4 otawix-heading box_title">Manual Voucher Entry</div>
        <Voucher />
      </div>

      {voucherId && (
        <div className="col-11 mx-auto bg-white my-5 shadow-sm rounded-2">
          <VoucherCreated singleVoucherLog={singleVoucherLog} />
        </div>
      )}
    </div>
  );
}

export default VoucherEntry;
