import { NODEURL } from "../redux/features/ApiConfig";
import { asyncGetPromotionData } from "../redux/features/promotion/PromotionSlice";
import { notificationMsg } from "../redux/features/utilis/NotficationSlice";

export const ChangeStatus = async (url, status, id, statusField, dispatch) => {
  if (!status) {
    var statusrow = 0;
  } else if (status) {
    var statusrow = 1;
  } else if (status === 0) {
    var statusrow = 0;
  } else if (status === 1) {
    var statusrow = 1;
  }
  const formdata = {
    id: id,
    status: statusrow,
  };

  NODEURL.patch(`${url}/${id}`, {
    [statusField]: formdata.status,
  })
    .then((res) => {
      dispatch(asyncGetPromotionData())
      dispatch(notificationMsg(true));
      setTimeout(() => {
        dispatch(notificationMsg(false));
      }, 2000);
    })
    .catch((err) => {
      // console.log(err);
    });
};
