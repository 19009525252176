import React from 'react'
import MUIDataTable from 'mui-datatables';
import { useNavigate } from 'react-router-dom';

const UmrahTable = () => {
    const navigate = useNavigate();
    const columns = [
        {
            name: "date",
            label: "Date/Time",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "booking_type",
            label: "Booking Type",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "booking_id",
            label: "Booking ID",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "office_name",
            label: "Office Name",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "req_status",
            label: "Request Status",
            options: {
              filter: false,
              sort: true,
            }
        },
        {
            name: "quotation_id",
            label: "Quotation ID",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <button className="table-btn btn-primary" onClick={() => console.log(value, tableMeta)}>{value}</button>;
              },
            },
        }
    ];

    const data = [
      {
        date: "17-Sep-2022 11:47 AM",
        office_name: "ZAIRAA Main Office",
        booking_type: "23",
        booking_id: "276",
        status: "New",
        req_status: "Issue",
        quotation_id: "Pending"
      }
    ];

    const options = {
      filter:false,
      Selection:false,
      print:false,
      download:false,
      viewColumns:false,
      displayRowCheckbox:false,
      selectableRows: 'none',
      onRowClick: (rowData) => {
        navigate(`/umrah-requests/${rowData[2]}`)
      },
    };
  return (
    <div className='py-4 px-2'>
    <MUIDataTable
    className='muidatatable'
          title={"Booking Filter Record"}
          data={data}
          columns={columns}
          options={options}
        />
    </div>)
}

export default UmrahTable