import { TextField } from "@mui/material";
import { FieldArray } from "formik";
import React from "react";

function TaxPkrCredit({ setFieldValue, values, friend, index, auto_manual }) {
  return (
    <div className="shadow-sm rounded px-2 pe-1 tax_pf black_border">
      <div className="h5">Tax In PKR</div>
      <FieldArray
        name={`fareValues.${index}.text_pkr`}
        render={(helpers) => (
          <div>
            {friend?.text_pkr?.map((obj, ndx) => (
              <>
                <div key={ndx}>
                  <div className="row align-items-end mt-1">
                    <div className="h5 col-3">Tax {ndx + 1}:</div>
                    <div className="col-3">
                      <TextField
                        id={`fareValues.${index}.text_pkr.${ndx}.tax_pkr_yq`}
                        value={obj.tax_pkr_yq}
                        onChange={(e) => {
                          const inputValue = e.target.value.toUpperCase();
                          if (/^[A-Z0-9]{0,2}$/.test(inputValue))
                            setFieldValue(
                              `fareValues.${index}.text_pkr.${ndx}.tax_pkr_yq`,
                              inputValue
                            );
                        }}
                        label="Txt Code"
                        placeholder="e.g YQ"
                        variant="standard"
                        className="w-100"
                        InputProps={{
                          readOnly:
                            values.fareValues[index].curr_label ===
                            "PKR : RUPEES"
                              ? false
                              : true,
                        }}
                        disabled={auto_manual === 0}
                      />
                    </div>
                    <div className="col-3">
                      <TextField
                        id={`fareValues.${index}.text_pkr.${ndx}.tax_pkr_amount`}
                        value={obj.tax_pkr_amount}
                        onChange={(e) => {
                          setFieldValue(
                            `fareValues.${index}.text_pkr.${ndx}.tax_pkr_amount`,
                            e.target.value
                          );
                        }}
                        label="Txt Amount"
                        variant="standard"
                        className="w-100"
                        InputProps={{
                          readOnly:
                            values.fareValues[index].curr_label ===
                            "PKR : RUPEES"
                              ? false
                              : true,
                        }}
                     
                      />
                    </div>
                    <div className="col-3">
                      { (
                        <button
                          onClick={() => {
                            helpers.remove(ndx);
                            friend.text_cur.splice(ndx, 1);
                          }}
                          className="button_style w-100 fs-6 bg-danger "
                          type="button"
                         
                        >
                          - Tax
                        </button>
                      )}
                     
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      />
    </div>
  );
}

export default TaxPkrCredit;
