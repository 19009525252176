import React, { useEffect } from "react";
import "../Styles/Footer.css";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAgentData } from "../redux/features/setupRestPages/agentsSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const { agentsData } = useSelector((state) => state.agentsCategories);

  useEffect(() => {
    dispatch(asyncGetAgentData());
  }, [dispatch]);

  return (
    <footer className="py-3 footer px-5">
      <div className=""> &#169; {agentsData && agentsData[0]?.footer}</div>
      <div className="">SMAWIX V5.0</div>
    </footer>
  );
};

export default Footer;
