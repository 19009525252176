import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../../ApiConfig";

const initialState = {};

// export const asyncGetLandingPageData = createAsyncThunk(
//     "asyncGetLandingPageData/get",
//     async (_, { rejectWithValue }) => {
//         try {
//             const response = await HTTP.get("/api/v1/homePage")
//             return await response?.data?.data;
//         } catch (error) {
//             console.log(error)
//             rejectWithValue(error)
//         }
//     }
// )

export const asyncCreateFlightManualinvoice = createAsyncThunk(
  "asyncCreateFlightManualinvoice/post",
  async (flight_obj, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        "/api/v1/b2b/manual/invoice/flight-store",
        flight_obj
      );
      // return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

const FlightSlice = createSlice({
  name: "FlightSlice",
  initialState,
  reducers: {
    setTotalSummaryFlt: (state, payload) => {
      state.totalSummaryFlight = payload.payload;
    },
  },
});
export const { setTotalSummaryFlt } = FlightSlice.actions;

export default FlightSlice.reducer;
