import React, { useEffect } from "react";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetOurFooterGallery } from "../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import { apiUrl } from "../../Utils/configUrl";

function Footer() {
  const dispatch = useDispatch();
  const { footerStateData } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);

  useEffect(() => {
    dispatch(asyncGetOurFooterGallery());
  }, [dispatch]);
  return (
    <div className="container-fluid bg_primary py-3">
      <div className="row justify-content-md-center justify-content-start">
        <div className="col-md-4 d-flex justify-content-center">
          {" "}
          {/* Adjust col-md-6 to your desired width */}
          <div id="contact-us" className="d-flex flex-column">
            <div className="text-white h1 mb-4">CONTACT US</div>

            <div className="my-2 d-flex align-items-center">
              <IoMdMail color="#ffffff" className="me-2" size={20} />
              <span className="text-white">{adminAgencyData?.email}</span>
            </div>

            <div className="my-2 d-flex align-items-center">
              <FaPhoneSquareAlt color="#ffffff" className="me-2" size={20} />
              <span className="text-white">{adminAgencyData?.mobile_no}</span>
            </div>

            <div className="my-2 d-flex align-items-center">
              <MdLocationOn color="#ffffff" className="me-2" size={22} />
              <span className="text-white">
                {adminAgencyData?.address?.replace(/]/g, ", ").toUpperCase()}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4 d-none d-md-block">
          <div className="d-flex gap-4 flex-wrap justify-content-center  ">
            {footerStateData?.map((data) => {
              return (
                <div key={data.id} className=" rounded-2 shadow-sm ">
                  <div>
                    <img
                      src={`${apiUrl}/${data?.image}`}
                      alt="footer"
                      width={120}
                      height={90}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>{" "}
      </div>
    </div>
  );
}

export default Footer;
