import React, { useState } from "react";
import MultiAmadeusIteneries from "./MultiAmadeusIteneries";
import { useDispatch, useSelector } from "react-redux";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import AmadeusLogo from "../../../../../assets/images/airlineLogo/amadeusRounded.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import moment from "moment";
import {
  codeShareAmadeus,
  ExclusiveSetors,
} from "../../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import { pricingairline } from "../../../../../Utils/airlinePricing";
import {
  CurrencyExchange,
  CurrencyCode,
} from "../../../../../Utils/currencyExchange";
import ConnectedFlight from "../../OneWaySearchResult/amadeus/ConnectedFlight";
import AmadeusMultiNoBrandFare from "./AmadeusMultiNoBrandFare";
import {
  setExpandFlightDetails,
  setpricingErrorNull,
} from "../../../../../redux/features/home/FlightSearchSlice";

const MultiAmadeusCard = ({
  amadeusItem,
  selectedFlight,
  index,
  toggleDetail,
  isExpanded,
  setShowOtherOption,
  showOtherOption,
}) => {
  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const dispatch = useDispatch();

  // calculate duration
  const timeDuration = (time1, time2) => {
    if (!time1 || !time2) {
      return null;
    }
    const depMoment = moment(time1);
    const arrMoment = moment(time2);
    const duration = moment.duration(arrMoment.diff(depMoment));
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha ===
        amadeusItem?.validatingAirlineCodes?.at(0)
  );

  //airline
  const airline = addAirLinesData?.find(
    (it) =>
      it.code_alpha === amadeusItem?.itineraries[0]?.segments[0]?.carrierCode
  );

  const fligthSegment = amadeusItem?.itineraries[0]?.segments;

  // calculate the total fare price
  const totalFarePrice = (fare) => {
    let adultFare = adultFareTotal(fare);
    let total = adultFare;
    return TwoDecimalPoint(total);
  };

  const adultFareTotal = (fare) => {
    return ~~pricingairline(
      airline,
      pricing,
      Number(CurrencyExchange(fare?.price?.base)),
      Number(CurrencyExchange(fare?.price?.grandTotal - fare?.price?.base)),
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        fare?.travelerPricings[0]?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment)
    )?.total_fare;
  };

  // discount
  const DiscountPrice = (fare) => {
    let paxFare = fare.travelerPricings;
    paxFare = Array.isArray(paxFare) ? paxFare : [paxFare];

    const getBaseFareAndTaxes = (faree) => ({
      baseFare: Number(faree?.price?.base),
      taxes: Number(faree?.price?.total) - Number(faree?.price?.base),
      sector: faree?.fareDetailsBySegment?.[0]?.class,
    });
    const getNoFare = () => ({ baseFare: 0, taxes: 0 });

    // console.log("fare",fare);
    let adultFare = getBaseFareAndTaxes(paxFare?.at(0));
    let childFare =
      searchValues?.childCount > 0
        ? getBaseFareAndTaxes(paxFare?.at(1))
        : getNoFare();
    let infantFare =
      searchValues?.infantNo > 0
        ? getBaseFareAndTaxes(paxFare?.at(paxFare?.length - 1))
        : getNoFare();

    return (
      TwoDecimalPoint(
        pricingairline(
          airline,
          pricing,
          Number(CurrencyExchange(adultFare?.baseFare)),
          Number(CurrencyExchange(adultFare?.taxes)),
          getSectorsName(locDeparture, locArrival),
          ExclusiveSetors(searchValues, pricing, adultFare?.sector)
        )?.sp * searchValues?.adultsCount
      ) +
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(childFare?.baseFare)),
        Number(CurrencyExchange(childFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing, childFare?.sector)
      )?.sp *
        searchValues?.childCount +
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(infantFare?.baseFare)),
        Number(CurrencyExchange(infantFare?.taxes)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing, infantFare?.sector)
      )?.sp *
        searchValues?.infantNo
    );
  };

  // calculate the totalDiscount
  const totalDiscount = (fare) => {
    let total = DiscountPrice(fare);
    // let total = adultFare;
    if (total > 0) {
      return `Off ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
    }
    return "";
  };

  const singPaxFareTotal = (fare, pax, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );
    if (!travelerPricing) return 0;

    const baseFare = Number(CurrencyExchange(travelerPricing?.price?.base));
    const tax = Number(
      CurrencyExchange(
        travelerPricing?.price?.total - travelerPricing?.price?.base
      )
    );
    const totalFare = ~~pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment)
    )?.total_fare;

    return totalFare * pax;
  };

  const singPaxTotalFare = (fare, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );
    if (!travelerPricing) return 0;

    const baseFare = Number(CurrencyExchange(travelerPricing?.price?.base));
    const tax = Number(
      CurrencyExchange(
        travelerPricing?.price?.total - travelerPricing?.price?.base
      )
    );
    const totalFare = ~~pricingairline(
      airline,
      pricing,
      baseFare,
      tax,
      getSectorsName(locDeparture, locArrival),
      ExclusiveSetors(
        searchValues,
        pricing,
        travelerPricing?.fareDetailsBySegment[0]?.class
      ),
      codeShareAmadeus(fligthSegment)
    )?.total_fare;

    return totalFare;
  };
  return (
    <div className="search_engin_result_box flight_card_shadow bg-white">
      <div
        className="shadow cursorpointer d-flex justify-content-center align-items-center"
        onClick={() => {
          dispatch(setpricingErrorNull(null));
          toggleDetail(index);
        }}
      >
        <div className="col-9">
          {amadeusItem?.itineraries?.map((itinery, index, array) => {
            return (
              <div
                key={index}
                style={{
                  borderBottom:
                    index === array?.length - 1
                      ? "none"
                      : "1px solid lightgray",
                }}
              >
                <MultiAmadeusIteneries
                  totalDiscount={totalDiscount}
                  array={array}
                  timeDuration={timeDuration}
                  segments={itinery?.segments}
                  amadeusItem={amadeusItem}
                  toggleDetail={toggleDetail}
                  index={index}
                  showOtherOption={showOtherOption}
                  setShowOtherOption={setShowOtherOption}
                  isExpanded={isExpanded}
                  totalFarePrice={totalFarePrice}
                  selectedFlight={selectedFlight}
                />
              </div>
            );
          })}
        </div>
        <div className="col-3 left-shadow text-center align-self-stretch pt-5">
          <div className=" pt-5">
            <h6 className="text-danger">{totalDiscount(amadeusItem)}</h6>
            <div className="d-flex align-items-center justify-content-center pt-2">
              <h4>{`${CurrencyCode()} ${totalFarePrice(amadeusItem)}`}</h4>
              <img src={AmadeusLogo} alt="" width={40} height={40} className="ms-3 mb-1" />
            </div>
          </div>

          <button
            onClick={(e) => {
              toggleDetail(index);
            }}
            className="button_style w-75 mb-2 cursorpointer mt-3"
            type="button"
          >
            <div className="">
              <MenuOpenIcon className="me-1" />
              Flight Detail
            </div>
          </button>
          {!selectedFlight && (
            <>
              {amadeusItem?.otherOptions?.length > 0 && (
                <button
                  className="bg-white color_primary border_primary p-2 px-4 fs-5 rounded-2 cursorpointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(setExpandFlightDetails(null));
                    setShowOtherOption(!showOtherOption);
                  }}
                >
                  <div>
                    <UnfoldMoreIcon className="me-1" />
                    {showOtherOption
                      ? "Hide Options"
                      : `${amadeusItem?.otherOptions?.length} More Options`}
                  </div>
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="p-3">
          <div className="d-flex mb-4 mt-2 flight_details_tabs">
            {["Fare Options", "Flight Details"].map((item, index) => {
              const active = selectedTab === item;
              return (
                <button
                  key={index}
                  onClick={() => setSelectedTab(item)}
                  className={active ? "active" : ""}
                >
                  {item}
                </button>
              );
            })}
          </div>

          <div>
            {selectedTab === "Flight Details" && (
              <div className="row rounded-2 search_engin_result_box bg-white p-4">
                {amadeusItem?.itineraries?.map((itinery, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        index === 0 ? "" : "pt-4 "
                      } border-bottom pb-4`}
                    >
                      {itinery?.segments?.map((segment, idx, array) => {
                        return (
                          <div key={index}>
                            <ConnectedFlight
                              timeDuration={timeDuration}
                              amadeusItem={segment}
                              inx={idx}
                              index={index}
                              flightSegment={array}
                              segment="multi"
                            />
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {selectedTab === "Fare Options" && (
            <div className="row rounded-2 search_engin_result_box bg-white p-4">
              <AmadeusMultiNoBrandFare
                amadeusItem={amadeusItem}
                selectedFlight={selectedFlight}
                totalFarePrice={totalFarePrice}
                pricing={pricing}
                airline={airline}
                singPaxFareTotal={singPaxFareTotal}
                singPaxTotalFare={singPaxTotalFare}
                timeDuration={timeDuration}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiAmadeusCard;
