import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { HTTP, HTTPForm } from "../ApiConfig";

const initialState = {
  VoucherType: "Journal Voucher",
  loading: false,
  error: null,
};

// export const asyncGetLandingPageData = createAsyncThunk(
//     "asyncGetLandingPageData/get",
//     async (_, { rejectWithValue }) => {
//         try {
//             const response = await HTTP.get("/api/v1/homePage")
//             return await response?.data?.data;
//         } catch (error) {
//             console.log(error)
//             rejectWithValue(error)
//         }
//     }
// )

const VoucherEntry = createSlice({
  name: "VoucherEntry",
  initialState,
  reducers: {
    handleVoucherType: (state, payload) => {
      state.VoucherType = payload.payload;
    },
  },
});
export const { handleVoucherType } = VoucherEntry.actions;

export default VoucherEntry.reducer;
