import React, { useEffect, useState } from "react";
import FligthSummary from "../Summary/FligthSummary";
import Segment from "./Segment";
import PaxFare from "./PaxFare";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import {
  asyncmanualInvoice,
  updateAsynicAutoVoucher,
  updateAsynicManuailVoucher,
} from "../../../../../redux/features/booking/bookingDetailsSlice";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import BtnLoader from "../../../../AppForm/BtnLoader";
import {
  getFlightType,
  getSectorsName,
} from "../../../../../Utils/FlightSector";
import moment from "moment";

function Flight({ initValues, permission, location, pnrEror, VoucherBooking }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { VoucherBooking } = useSelector((state) => state?.booking);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { user } = useSelector((state) => state.user);
  const [message, setMessage] = useState(false);
  // booking?.currency?.curr_code
  const pkr = currencyData?.find((it) => it?.curr_code === "PKR");

  const [initialValues, setInitValues] = useState({
    ...initValues,
    ttl_agent_fare_pkr: 0,
    ttl_supplier_fare_pkr: 0,
    flight_id: null,
    description: "",
  });

  // useEffect(() => {
  //   const fareValues = VoucherBooking?.id
  //     ? VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables.map(
  //         (pax, paxIndex) => ({
  //           pax_id: pax?.pax_id,
  //           book_id: pax?.book_id,
  //           ttl_agt_pay_d_cur: pax?.ttl_agt_pay_d_cur,
  //           ttl_sup_pay_d_cur: pax?.ttl_sup_pay_d_cur,
  //           pax_type: obj[pax?.pax_type],

  //           title: pax?.pax_title,
  //           first_name: pax?.fName,
  //           last_name: pax?.lname,
  //           ticket_no: pax?.tkt_number,
  //           currency: pkr,
  //           // curr_label:
  //           //   booking?.currency?.curr_code +
  //           //   " : " +
  //           //   booking?.currency?.curr_name,
  //           curr_label: "PKR : RUPEES",
  //           // roe: booking?.currency?.roe || "",
  //           roe: 1,
  //           b_fare: TwoDecimalPoint(pax?.baseFare_d_cur),
  //           eq_b_fare: TwoDecimalPoint(pax?.baseFare_d_cur),

  //           text_cur: pax?.flight_taxes?.length
  //             ? pax?.flight_taxes?.map((tax, taxIndex) => ({
  //                 tax_yq: tax?.tax_code,
  //                 tax_amount: TwoDecimalPoint(tax?.tax_amount / tax?.roe),
  //               }))
  //             : [
  //                 {
  //                   tax_pkr_yq: "",
  //                   tax_pkr_amount: "",
  //                 },
  //               ],

  //           text_pkr: pax?.flight_taxes?.length
  //             ? pax?.flight_taxes?.map((tax, taxIndex) => ({
  //                 tax_pkr_yq: tax?.tax_code,
  //                 tax_pkr_amount: tax?.tax_amount,
  //               }))
  //             : [
  //                 {
  //                   tax_pkr_yq: "",
  //                   tax_pkr_amount: "",
  //                 },
  //               ],

  //           tax_pkr: {
  //             s_psf: pax?.sup_psf_d_cur,
  //             s_psf_per: pax?.sup_psf_per_d_s_cur,
  //             s_gst: pax?.sup_gst_d_cur,
  //             s_gst_per: pax?.sup_gst_per_d_s_cur,
  //             // sp discount data
  //             s_sp: pax?.sup_sp_d_cur,
  //             s_sp_per: pax?.sup_sp_per_d_s_cur,
  //             s_wht: pax?.sup_wht_d_cur,
  //             s_wht_per: pax?.sup_wht_per_d_s_cur,
  //             // agens Data
  //             c_psf: pax?.agt_psf_d_cur,
  //             c_psf_per: pax?.agt_psf_per_d_s_cur,
  //             c_gst: pax?.agt_gst_d_cur,
  //             c_gst_per: pax?.agt_gst_per_d_s_cur,
  //             c_sp: pax?.agt_sp_d_cur, /////////////////////////////////////////
  //             c_sp_per: pax?.agt_sp_per_d_s_cur,
  //             c_wht: pax?.agt_wht_d_cur,
  //             c_wht_per: pax?.agt_wht_per_d_s_cur,
  //           },

  //           // pax?.sup_psf_s_cur
  //           tax_cur: {
  //             s_psf: pax?.income_type === "income" ? pax?.unappropriate : "",
  //             s_psf_per: pax?.sup_psf_per_d_s_cur,
  //             s_gst: pax?.sup_gst_s_cur,
  //             s_gst_per: pax?.sup_gst_per_d_s_cur,
  //             s_sp: pax?.sup_sp_s_cur,
  //             s_sp_per: pax?.sup_sp_per_d_s_cur,
  //             s_wht: pax?.sup_wht_s_cur,
  //             s_wht_per: pax?.sup_wht_per_d_s_cur,
  //             c_psf: pax?.agt_psf_s_cur,
  //             c_psf_per: pax?.agt_psf_per_d_s_cur,
  //             c_gst: pax?.agt_gst_s_cur,
  //             c_gst_per: pax?.agt_gst_per_d_s_cur,
  //             c_sp: pax?.income_type === "Disc-Given" ? pax?.unappropriate : "",
  //             c_sp_per: pax?.agt_sp_per_d_s_cur,
  //             c_wht: pax?.agt_wht_s_cur,
  //             c_wht_per: pax?.agt_wht_per_d_s_cur,
  //           },
  //         })
  //       )
  //     :
  //   setInitValues({
  //     ...initValues,
  //     fareValues,
  //   });
  // }, [pkr?.curr_code, VoucherBooking?.id]);
  useEffect(() => {
    if (VoucherBooking?.id) {
      setInitValues({
        ...initValues,
        flight_id: VoucherBooking?.FlightBookingModels?.at(0)?.id,
        ttl_agent_fare_pkr: VoucherBooking?.ttl_agent_fare,
        ttl_supplier_fare_pkr: Number(VoucherBooking?.ttl_supplier_fare),
        segmentValues: VoucherBooking?.FlightBookingModels?.at(
          0
        )?.FlightSegmentModels?.map((flightSegment, flightSegmentId) => ({
          segment_id: flightSegment?.flight_id,
          t_class: flightSegment?.cabin_class,
          air_code: flightSegment?.flt,
          air_no: flightSegment?.flt_no,
          origin: flightSegment?.origin,
          destination: flightSegment?.destination,
          flt_date: new Date(flightSegment?.dep_date) || new Date(),
          arr_date: new Date(flightSegment?.arr_date) || new Date(),
          arr_time: flightSegment?.arr_time,
          dep_time: flightSegment?.dep_time,

          seat_b:
            VoucherBooking?.FlightBookingModels?.at(0)?.flight_pax_baggages[
              flightSegmentId
            ]?.baggage,
        })),
        fareValues: VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables.map(
          (pax, paxIndex) => ({
            pax_id: pax?.pax_id,
            book_id: pax?.book_id,
            ttl_agt_pay_d_cur: pax?.ttl_agt_pay_d_cur,
            ttl_sup_pay_d_cur: pax?.ttl_sup_pay_d_cur,
            pax_type: obj[pax?.pax_type],

            title: pax?.pax_title,
            first_name: pax?.fName,
            last_name: pax?.lname,
            ticket_no: pax?.tkt_number,
            currency: pkr,
            curr_label: "PKR : RUPEES",
            roe: 1,

            // curr_label:
            //   VoucherBooking?.currency?.curr_code +
            //   " : " +
            //   VoucherBooking?.currency?.curr_name,

            // roe: VoucherBooking?.currency?.roe || "",

            b_fare: TwoDecimalPoint(pax?.baseFare_d_cur),
            eq_b_fare: TwoDecimalPoint(pax?.baseFare_d_cur),

            text_cur: pax?.flight_taxes?.length
              ? pax?.flight_taxes?.map((tax, taxIndex) => ({
                  flight_text_id: tax?.id,
                  tax_yq: tax?.tax_code,
                  tax_amount: TwoDecimalPoint(tax?.tax_amount / tax?.roe),
                }))
              : [
                  {
                    tax_pkr_yq: "",
                    tax_pkr_amount: "",
                  },
                ],

            text_pkr: pax?.flight_taxes?.length
              ? pax?.flight_taxes?.map((tax, taxIndex) => ({
                  flight_text_id: tax?.id,
                  tax_pkr_yq: tax?.tax_code,
                  tax_pkr_amount: tax?.tax_amount,
                }))
              : [
                  {
                    tax_pkr_yq: "",
                    tax_pkr_amount: "",
                  },
                ],

            tax_pkr: {
              s_psf: pax?.sup_psf_d_cur,
              s_psf_per: pax?.sup_psf_per_d_s_cur,
              s_gst: pax?.sup_gst_d_cur,
              s_gst_per: pax?.sup_gst_per_d_s_cur,
              // sp discount data
              s_sp: pax?.sup_sp_d_cur,
              s_sp_per: pax?.sup_sp_per_d_s_cur,
              s_wht: pax?.sup_wht_d_cur,
              s_wht_per: pax?.sup_wht_per_d_s_cur,
              // agens Data
              c_psf: pax?.agt_psf_d_cur,
              c_psf_per: pax?.agt_psf_per_d_s_cur,
              c_gst: pax?.agt_gst_d_cur,
              c_gst_per: pax?.agt_gst_per_d_s_cur,
              c_sp: pax?.agt_sp_d_cur, /////////////////////////////////////////
              c_sp_per: pax?.agt_sp_per_d_s_cur,
              c_wht: pax?.agt_wht_d_cur,
              c_wht_per: pax?.agt_wht_per_d_s_cur,
            },

            // pax?.sup_psf_s_cur
            tax_cur: {
              s_psf: pax?.income_type === "income" ? pax?.unappropriate : "",
              s_psf_per: pax?.sup_psf_per_d_s_cur,
              s_gst: pax?.sup_gst_s_cur,
              s_gst_per: pax?.sup_gst_per_d_s_cur,
              s_sp: pax?.sup_sp_s_cur,
              s_sp_per: pax?.sup_sp_per_d_s_cur,
              s_wht: pax?.sup_wht_s_cur,
              s_wht_per: pax?.sup_wht_per_d_s_cur,
              c_psf: pax?.agt_psf_s_cur,
              c_psf_per: pax?.agt_psf_per_d_s_cur,
              c_gst: pax?.agt_gst_s_cur,
              c_gst_per: pax?.agt_gst_per_d_s_cur,
              c_sp: pax?.income_type === "Disc-Given" ? pax?.unappropriate : "",
              c_sp_per: pax?.agt_sp_per_d_s_cur,
              c_wht: pax?.agt_wht_s_cur,
              c_wht_per: pax?.agt_wht_per_d_s_cur,
            },
          })
        ),
      });
    } else {
      setInitValues({
        ...initValues,
        
        segmentValues: [
          {
            t_class: "",
            air_code: "",
            air_no: "",
            origin: "",
            destination: "",
            flt_date: new Date(),
            arr_date: new Date(),
            dep_time: "10:10",
            arr_time: "10:10",
            seat_b: "",
          },
        ],
        fareValues: [
          {
            pax_type: "ADULT",
            title: "",
            first_name: "",
            last_name: "",
            ticket_no: "",
            currency: pkr,
            curr_label: "PKR : RUPEES",
            roe: pkr?.roe || "",
            b_fare: "",
            eq_b_fare: "",
            ttl_agent_per_pass: "",
            ttl_supp_per_pass: "",
            ttl_supp_per_pass_s_curr: "",
            ttl_agg_per_pass_assg_curr: "",
            ttl_agt_pay_d_cur: "",
            ttl_sup_pay_d_cur: "",
            text_cur: [
              {
                tax_yq: "",
                tax_amount: "",
              },
            ],

            text_pkr: [
              {
                tax_pkr_yq: "",
                tax_pkr_amount: "",
              },
            ],

            tax_pkr: {
              s_psf: "",
              s_psf_per: "",
              s_gst: "",
              s_gst_per: "",
              s_sp: "",
              s_sp_per: "",
              s_wht: "",
              s_wht_per: "",
              c_psf: "",
              c_psf_per: "",
              c_gst: "",
              c_gst_per: "",
              c_sp: "",
              c_sp_per: "",
              c_wht: "",
              c_wht_per: "",
            },

            tax_cur: {
              s_psf: "",
              s_psf_per: "",
              s_gst: "",
              s_gst_per: "",
              s_sp: "",
              s_sp_per: "",
              s_wht: "",
              s_wht_per: "",
              c_psf: "",
              c_psf_per: "",
              c_gst: "",
              c_gst_per: "",
              c_sp: "",
              c_sp_per: "",
              c_wht: "",
              c_wht_per: "",
            },
          },
        ],
      });
    }
  }, [
    VoucherBooking?.id,
    pkr?.curr_code,
    initValues.agent?.acc_id,
    initValues?.supplier,
    initValues?.d_No_pnr,
    initValues?.remarks,
    initValues?.inovice_date,
  ]);
  const validationSchema = yup.object().shape({
    description: yup.string().when([], {
      is: (value) => location?.state?.postVoucher === true,
      then: yup.string().required("Description is required "),
    }),
  });
  const obj = {
    ADT: "ADULT",
    CNN: "CHILD",
    INF: "INFANT",
  };
  const objSrt = {
    ADULT: "ADT",
    CHILD: "CNN",
    INFANT: "INF",
  };

  const handleSubmit = (values, { resetForm }) => {
    const ispkr =
      values?.fareValues[0]?.currency?.curr_code === "PKR" ? true : false;

    const body = {
      id: VoucherBooking?.id,
      auto_manual: VoucherBooking?.auto_manual,
      voucher_id: VoucherBooking?.voucherData?.voucher_id,
      flight_id: values?.flight_id,
      currency:
        VoucherBooking?.currency?.currency_id ||
        Number(values?.agent?.agent_group?.currency?.currency_id),
      roe: values?.agent?.agent_group?.currency?.roe,
      type: values?.service_type?.toLowerCase(),
      service_id: values?.service_id,
      loginUserId: user?.user_id,
      agent_acc_id: values?.agent?.acc_id,
      supplier_acc_id: values?.supplier,
      document_number: values?.d_No_pnr,
      invoice_date:
        moment(values?.inovice_date).format("YYYY-MM-DD") +
        " " +
        moment(new Date().getTime()).format("HH:mm"),
      remarks: values?.remarks,
      s_cur_debit: values?.s_cur_debit,
      // currency: values?.fareValues[0]?.currency?.curr_code,
      rateOfExchange: Number(values?.fareValues[0]?.roe),
      description: values?.description,

      PassengerInformation: values?.fareValues.map((pax) => ({
        pax_id: pax?.pax_id,
        pax_type: objSrt[pax?.pax_type],
        ttl_agt_pay_d_cur: pax?.ttl_agt_pay_d_cur,
        ttl_sup_pay_d_cur: pax?.ttl_sup_pay_d_cur,
        ttl_agent_per_pass: pax?.ttl_agent_per_pass,
        ttl_supp_per_pass: pax?.ttl_supp_per_pass,
        ttl_agg_per_pass_assg_curr: pax?.ttl_agg_per_pass_assg_curr,
        title: pax?.title,
        first_name: pax?.first_name,
        last_name: pax?.last_name,
        // dob: pax?.dob,
        // passport_cnic:pax?.passport_no,
        ticket_no: pax?.ticket_no,
        baseFare_curr: Number(pax?.b_fare),
        baseFare_pkr: Number(pax?.eq_b_fare),

        totaltaxes_pkr: Number(
          pax?.text_pkr.reduce(
            (total, tax) => total + Number(tax.tax_pkr_amount),
            0
          )
        ),
        totaltaxes_curr: 0,
        // Number(
        //   pax?.text_cur.reduce(
        //     (total, taxcur) => total + Number(taxcur.tax_amount),
        //     0
        //   )
        // ) ,

        // supplier curr field
        s_psf_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_psf_per),
        s_psf_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_psf),
        s_gst_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_gst_per),
        s_gst_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_gst),
        s_sp_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_sp_per),
        s_sp_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_sp),
        s_wht_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_wht_per),
        s_wht_curr: ispkr ? 0 : Number(pax?.tax_cur?.s_wht),
        total_supplier_pay_curr: TwoDecimalPoint(Number(pax?.tax_cur?.s_sp)),

        //supplier pkr field
        s_psf_per_pkr: ~~pax?.tax_pkr?.s_psf_per,
        s_psf_pkr: Number(pax?.tax_pkr?.s_psf),
        s_gst_per_pkr: Number(pax?.tax_pkr?.s_gst_per),
        s_gst_pkr: Number(pax?.tax_pkr?.s_gst),
        s_sp_per_pkr: Number(pax?.tax_pkr?.s_sp_per),
        s_sp_pkr: Number(pax?.tax_pkr?.s_sp),
        s_wht_per_pkr: Number(pax?.tax_pkr?.s_wht_per),
        s_wht_pkr: Number(pax?.tax_pkr?.s_wht),
        total_supplier_pay_pkr: "",

        // agent curr field
        c_psf_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_psf_per),
        c_psf_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_psf),
        c_gst_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_gst_per),
        c_gst_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_gst),
        c_sp_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_sp_per),
        c_sp_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_sp),
        c_wht_per_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_wht_per),
        c_wht_curr: ispkr ? 0 : Number(pax?.tax_cur?.c_wht),
        total_customer_pay_curr: "",

        //agent pkr field
        c_psf_per_pkr: Number(pax?.tax_pkr?.c_psf_per),
        c_psf_pkr: Number(pax?.tax_pkr?.c_psf),

        s_psf_pkr_pst_diff: TwoDecimalPoint(
          Number(pax?.tax_pkr?.s_psf) -
            VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
              ?.sup_psf_d_cur
        ),
        s_psf_pkr_disc_diff: TwoDecimalPoint(
          Number(values?.fareValues?.at(0)?.tax_pkr?.s_sp) -
            VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
              ?.sup_sp_d_cur
        ),

        c_psf_pkr_pst_diff: TwoDecimalPoint(
          Number(pax?.tax_pkr?.c_psf) -
            VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
              ?.agt_psf_d_cur
        ),
        c_psf_pkr_disc_diff: TwoDecimalPoint(
          Number(values?.fareValues?.at(0)?.tax_pkr?.c_sp) -
            VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
              ?.agt_sp_d_cur
        ),

        c_psf_pkr_gst_or_wht:
          Number(pax?.tax_pkr?.c_gst) > 0
            ? TwoDecimalPoint(
                Number(pax?.tax_pkr?.c_gst) -
                  VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
                    ?.agt_gst_d_cur
              )
            : TwoDecimalPoint(
                Number(values?.fareValues?.at(0)?.tax_pkr?.c_wht) -
                  VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
                    ?.agt_wht_d_cur
              ),

        s_psf_pkr_gst_or_wht:
          Number(pax?.tax_pkr?.c_gst) > 0
            ? TwoDecimalPoint(
                Number(pax?.tax_pkr?.s_gst) -
                  VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
                    ?.sup_gst_d_cur
              )
            : TwoDecimalPoint(
                Number(values?.fareValues?.at(0)?.tax_pkr?.s_wht) -
                  VoucherBooking?.FlightBookingModels?.at(0)?.pax_tables?.at(0)
                    ?.sup_wht_d_cur
              ),

        c_gst_per_pkr: Number(pax?.tax_pkr?.c_gst_per), ////////////////////////////
        c_gst_pkr: Number(pax?.tax_pkr?.c_gst),
        c_sp_per_pkr: Number(pax?.tax_pkr?.c_sp_per),
        c_sp_pkr: Number(pax?.tax_pkr?.c_sp),
        c_wht_per_pkr: Number(pax?.tax_pkr?.c_wht_per),
        c_wht_pkr: Number(pax?.tax_pkr?.c_wht),
        total_customer_pay_pkr: "",

        taxesBreakUp_curr: pax?.text_cur.map((tax) => ({
          flight_text_id: tax?.flight_text_id,
          curr_code: tax?.tax_yq,
          curr_amount: 0,
          //  Number(tax?.tax_amount)
        })),

        taxesBreakUp_pkr: pax?.text_pkr?.map((tax_pkr) => ({
          flight_text_id: tax_pkr?.flight_text_id,
          pkr_code: tax_pkr?.tax_pkr_yq,
          pkr_amount: Number(tax_pkr?.tax_pkr_amount),
        })),
      })),

      FlightSegments: values?.segmentValues?.map((segment) => ({
        segment_id: segment?.segment_id,
        segment_type: getSectorsName(segment?.origin, segment?.destination),
        cabin_class: segment?.t_class,
        airline_code: segment?.air_code,
        flight_number: Number(segment?.air_no),
        orig_location: segment?.origin,
        des_location: segment?.destination,
        departure_date: segment?.flt_date,
        arr_date: segment?.arr_date,
        dep_time: segment?.dep_time,
        arr_time: segment?.arr_time,
        seat_bag: segment?.seat_b,
      })),
      flight_type: getFlightType(
        values?.segmentValues?.at(0)?.origin,
        values?.segmentValues
      ),

      // voucher_no: location?.state?.voucher_no,
      ttl_agent_fare: values?.ttl_agent_fare,
      ttl_suppl_fare: values?.ttl_suppl_fare,
      ttl_agent_fare_pkr: TwoDecimalPoint(values.ttl_agent_fare_pkr),
      ttl_agent_fare_curr: TwoDecimalPoint(
        values.ttl_agent_fare_pkr / values?.fareValues?.at(0)?.roe
      ),
      ttl_supplier_fare_pkr: values.ttl_supplier_fare_pkr,
      ttl_sup_fare_curr:
        values.ttl_supplier_fare_pkr / values?.fareValues?.at(0)?.roe,
    };
    let id = Number(location?.state?.voucherId);
    //  if (location?.state?.postVoucher) {
    //    navigate("/post-voucher");
    //  } else {
    //   //  navigate("/save-voucher");
    //       navigate("/post-voucher");
    //  }
    // dispatch(updateAsynicManuailVoucher({ id, body }));
    // dispatch(asyncCreateFlightManualinvoice(flight_obj))
    if (!pnrEror) {
      setMessage(true);
    }
    setTimeout(() => {
      const data = !pnrEror
        ? id
          ? VoucherBooking?.auto_manual === 0
            ? dispatch(updateAsynicAutoVoucher({ id, body }))
                .then((response) => {
                  // Handle the response here if needed
                  setMessage(false);
                  //  resetForm();
                  if (location?.state?.postVoucher) {
                    navigate("/post-voucher");
                  } else {
                    navigate("/save-voucher");
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  console.error("Error from AsyncCreatVoucherEntries:", error);
                })
            : dispatch(updateAsynicManuailVoucher({ id, body }))
                .then((response) => {
                  // Handle the response here if needed
                  setMessage(false);
                  //  resetForm();
                  if (location?.state?.postVoucher) {
                    navigate("/post-voucher");
                  } else {
                    navigate("/save-voucher");
                  }
                })
                .catch((error) => {
                  // Handle the error here
                  console.error("Error from AsyncCreatVoucherEntries:", error);
                })
          : dispatch(asyncmanualInvoice(body))
              //  set post disptach for post api it just assaign
              .then((response) => {
                // Handle the response here if needed
                setMessage(false);
                //  resetForm();
                navigate("/save-voucher");
              })
              .catch((error) => {
                // Handle the error here
                console.error("Error from AsyncCreatVoucherEntries:", error);
              })
        : null;
    }, 3000);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, resetForm, setFieldValue, errors }) => {
        return (
          <Form>
            <div>
              <Segment
                values={values}
                setFieldValue={setFieldValue}
                auto_manual={VoucherBooking?.auto_manual}
              />
              <PaxFare
                values={values}
                setFieldValue={setFieldValue}
                auto_manual={VoucherBooking?.auto_manual}
              />
              <FligthSummary data={values} setFieldValue={setFieldValue} />
              {/* input description for post */}
              {location?.state?.postVoucher ? (
                <div
                  className="d-flex gap-4  flex-column "
                  style={{ width: "70%", marginLeft: 300 }}
                >
                  <div className="d-flex gap-4">
                    {" "}
                    <div className="fs-5 mt-4 ">Action:</div>
                    <div className="col-10">
                      <TextField
                        label="Description"
                        variant="standard"
                        name="description"
                        className="w-100"
                        value={values.description}
                        autoComplete="off"
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("description", value.toUpperCase());
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    {errors.description ? (
                      <div className="text-danger">{errors.description}</div>
                    ) : null}{" "}
                  </div>
                </div>
              ) : null}

              <div className=" d-flex justify-content-center align-item-center">
                <div className="text-center py-4 mx-2">
                  <button
                    type="button"
                    className="border px-4   selectbtn_style"
                    onClick={() => resetForm()}
                    disabled={!permission}
                  >
                    Clear Form
                  </button>
                </div>
                {VoucherBooking?.id ? (
                  <div className="">
                    {
                      message ? (
                        <div
                          className="border px-4  mt-4 selectbtn_style"
                          type="submit"
                        >
                          {" "}
                          <BtnLoader />{" "}
                        </div>
                      ) : (
                        <button
                          className="border px-4  mt-4 selectbtn_style"
                          type="submit" // Change type to "button" to prevent form submission
                        >
                          {location?.state?.postVoucher ? "Edit" : "Post"}
                        </button>
                      )

                      // location?.state?.postVoucher ? (
                      //   "Edit"
                      // ) : (
                      //   "Post"
                      // )
                    }
                  </div>
                ) : (
                  <div className="">
                    {message ? (
                      <div className="border px-4  mt-4 selectbtn_style">
                        <BtnLoader />
                      </div>
                    ) : (
                      <button
                        className="border px-4  mt-4 selectbtn_style"
                        type="submit" // Change type to "button" to prevent form submission
                      >
                        Submit
                      </button>
                    )}

                    {/* { ? <BtnLoader /> : ""} */}
                  </div>
                  // <div className="text-center py-4">
                  //   <button
                  //     className="button_style px-5"
                  //     type="submit"
                  //     // disabled={
                  //     //   (totalDebitor === totalCreditor &&
                  //     //   totalDebitor !== 0 &&
                  //     //   totalCreditor !== 0
                  //     //     ? false
                  //     //     : true) || !voucherEntryPermission
                  //     // }
                  //   >
                  //     {message ? <BtnLoader /> : "Submit"}
                  //   </button>
                  // </div>
                )}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Flight;
