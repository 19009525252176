import React, { useEffect } from "react";
import OneWaySearchResult from "../components/Reservation/FlightsTab/OneWaySearchResult";
import ReturnSearchResult from "../components/Reservation/FlightsTab/ReturnSearchResult";
import MultiCitySearchResult from "../components/Reservation/FlightsTab/MultiCitySearchResult";
import {
  setReturnFlight,
  setselectedFlight,
} from "../redux/features/home/FlightSearchSlice";
import { useDispatch } from "react-redux";

function SearchResultPage() {
  const dispatch = useDispatch();
  const availibity = JSON.parse(localStorage.getItem("flightSearchData"));

  useEffect(() => {
    dispatch(setselectedFlight(null));
    dispatch(setReturnFlight(null));
  }, [dispatch]);
  return (
    <div>
      {availibity?.trip_type === "ONE WAY" ? <OneWaySearchResult /> : null}
      {availibity?.trip_type === "ROUND TRIP" ? <ReturnSearchResult /> : null}
      {availibity?.trip_type === "MULTI DESTINATION" ? (
        <MultiCitySearchResult />
      ) : null}
    </div>
  );
}

export default SearchResultPage;
