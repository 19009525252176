import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";
import DeleteModal from "../../DeleteModal";
import { HTTP } from "../../../Utils/API";
import {
  asyncDeleteHotelRoom,
  asyncEditRoomtype,
  asyncGetHotelRoomData,
  asyncPostRoomType,
  asyncUpdateRoomtype,
  EditRoomTypeNull,
} from "../../../redux/features/umrah/HotleRoomSlice";
import { useDispatch, useSelector } from "react-redux";
import { options } from "../../../Utils/ColumnOptions";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";

import ErrorMessage from "../../AppForm/ErrorMessage";
import BtnLoader from "../../AppForm/BtnLoader";

const RoomsType = () => {
  const dispatch = useDispatch();
  const { hotelRoomData, EditRoomType, loading } = useSelector(
    (state) => state.hotelRoom
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    dispatch(asyncGetHotelRoomData());
  }, []);
  // console.log(hotelRoomData?.RoomTypeModel);
  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Room Type Name",
      options: {
        filter: false,

        sort: true,
      },
    },
    {
      name: "room_type_hotel.umrah_hotel_name",
      label: "Hotel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  dispatch(asyncEditRoomtype(id));
                  window.scroll({ top: 0 });
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(id);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const cityNames = [
    { id: 1, name: "Mecca" },
    { id: 2, name: "Medina" },
  ];

  const validationSchem = yup.object().shape({
    umrah_city_id: yup.number().required("umrah_city_id Required"),
    umrah_hotel_id: yup.number().required("umrah_hotel_id Required"),
    room_type_name: yup.array().of(
      yup
        .string()
        // .min(10, "Room Type must be 10 characters")
        .required("Room Type is Required")
    ),
  });

  const initialValues = {
    // its not changing because the city id is comming null and the hotel is also depend upon city_id 
    umrah_city_id:
      (EditRoomType && EditRoomType?.city_id) || cityNames.length
        ? cityNames[0].id
        : 0,
    umrah_hotel_id:
      EditRoomType?.hotel_id || hotelRoomData?.UmrahHotelModel?.length
        ? hotelRoomData?.UmrahHotelModel[0]?.u_hotel_id
        : 0,
    room_type_name: (EditRoomType && [EditRoomType?.name]) || [""],
  };

  console.log(EditRoomType);

  return (
    <div className="col-12 col-sm-6 mx-auto rounded-3 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Room Type</p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchem}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, action) => {
          console.log(values);
          const vals = {
            umrah_hotel_id: values.umrah_hotel_id,
            room_type_name: values.room_type_name,
          };
          const editVals = {
            room_type_id: EditRoomType?.id,
            umrah_hotel_id: values.umrah_hotel_id,
            room_type_name: values.room_type_name.join(""),
          };
          console.log();
          if (EditRoomType) {
            dispatch(asyncUpdateRoomtype(editVals));
            dispatch(EditRoomTypeNull());
          } else {
            dispatch(asyncPostRoomType(vals));
            action.resetForm({ values: "" });
          }
        }}
      >
        {({ values, setFieldValue, errors, handleChange }) => {
          console.log(errors);
          // console.log(values);

          return (
            <Form className="col-12 px-4 mt-5">
              <div className="col-12 d-flex">
                <div className="col-5 me-5">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select City
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={values.umrah_city_id}
                      onChange={handleChange}
                      name="umrah_city_id"
                    >
                      {cityNames.map((city) => (
                        <MenuItem value={city.id} key={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage errors={errors && errors.umrah_city_id} />
                  </FormControl>
                </div>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Hotel
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="umrah_hotel_id"
                    onChange={handleChange}
                    value={values.umrah_hotel_id}
                  >
                    {hotelRoomData &&
                      hotelRoomData?.UmrahHotelModel?.filter(
                        (it) => it.umrah_city_id === values.umrah_city_id
                      ).map((hotel) => {
                        return (
                          <MenuItem
                            value={hotel.u_hotel_id}
                            key={hotel.u_hotel_id}
                          >
                            {hotel.umrah_hotel_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <ErrorMessage errors={errors && errors.umrah_hotel_id} />
              </div>

              <div className="col-12 mt-3 d-flex flex-wrap">
                <FieldArray
                  name="room_type_name"
                  render={(arrayHelpers) => (
                    <div className="w-100 ">
                      {values.room_type_name &&
                      values.room_type_name.length > 0 ? (
                        values.room_type_name.map((val, index) => (
                          <div key={index} className="gap-3 my-2 d-flex ">
                            <div className="d-flex flex-column w-100">
                              <TextField
                                fullWidth
                                name={`room_type_name.${index}`}
                                variant="standard"
                                label="Room Type"
                                onChange={(e) =>
                                  setFieldValue(
                                    `room_type_name.${index}`,
                                    e.target.value
                                  )
                                }
                                value={values.room_type_name[index]}
                              />
                              <p></p>
                              <ErrorMessage
                                errors={
                                  errors &&
                                  errors?.room_type_name &&
                                  errors?.room_type_name[index]
                                }
                              />
                            </div>
                            {/* { */}
                            {!EditRoomType && (
                              <div className="d-flex gap-3 align-items-center">
                                {index >= 1 && (
                                  <button
                                    className="btn bg-danger text-white h-4"
                                    style={{ width: 120, height: 38 }}
                                    onClick={() => arrayHelpers.remove(index)} // insert an empty string at a position
                                  >
                                    - Room
                                  </button>
                                )}
                                {index === 0 && (
                                  <button
                                    className="btn bg-primary text-white h-4"
                                    style={{ width: 120, height: 38 }}
                                    type="button"
                                    onClick={() => arrayHelpers.push("")} // insert an empty string at a position
                                  >
                                    + Room
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <button
                          className="btn bg-primary text-white h-4"
                          style={{ width: 120, height: 38 }}
                          type="button"
                          onClick={() => arrayHelpers.push("")} // insert an empty string at a position
                        >
                          + Room
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="text-center">
                <button className="btn setup-btn m-2" type="submit">
                  {loading ? <BtnLoader /> : EditRoomType ? "update" : "save"}
                </button>
                {EditRoomType && (
                  <button
                    className="btn setup-btn bg-danger"
                    onClick={(e) => dispatch(EditRoomTypeNull())}
                    type="submit"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData}
          onClick={(e) => {
            dispatch(asyncDeleteHotelRoom(modalData));
            setShowDeleteModal(false);
          }}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Room Type Details"}
          data={hotelRoomData?.RoomTypeModel}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default RoomsType;
