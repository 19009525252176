import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP } from "../ApiConfig";

const initialState = {
  hotelData: [],
  editHotelData:null,
  loading: false,
  error: null,
};

// getting hotelData data
export const asyncGetHotelData = createAsyncThunk(
  "asyncGetHotelData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/umrah_hotel`);
      // console.log(await response.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting hotel data
export const asyncPostHotelData = createAsyncThunk(
  "asyncPostHotelData/post",
  async (vals, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/umrah_hotel/umrah_hotel_create`,
        vals
      );
      dispatch(asyncGetHotelData());
      // toast.success("data added");
      // return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit hotel data
export const asyncEditHotelData = createAsyncThunk(
  "asyncEditHotelData/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/umrah_hotel/umrah_hotel_edit/${id}`
      );
      // console.log(await response.data.data);
      dispatch(asyncGetHotelData());
      // toast.success("data added");
      return await response.data.data.edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// update hotel data
export const asyncUpdateData = createAsyncThunk(
  "asyncUpdateData/post",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/umrah_hotel/umrah_hotel_update`,values
      );
      // console.log(await response.data.data);
      dispatch(asyncGetHotelData());
      // toast.success("data added");
      return await response.data.data.edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const HotelSlice = createSlice({
  name: "HotelSlice",
  initialState,

  reducers:{
    hotelEditNull:(state)=>{
      state.editHotelData=null
    }
  },
  extraReducers: (builder) => {
    // getting hotelData
    builder.addCase(asyncGetHotelData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetHotelData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.hotelData = payload;
    });
    builder.addCase(asyncGetHotelData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting hotel data
    builder.addCase(asyncPostHotelData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostHotelData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.hotelData = payload;
    });
    builder.addCase(asyncPostHotelData.rejected, (state, { payload }) => {
      state.loading = false;
      // state.error = payload;
    });

        // edit hotel data
        builder.addCase(asyncEditHotelData.pending, (state, { payload }) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(asyncEditHotelData.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.error = null;
          state.editHotelData = payload;
        });
        builder.addCase(asyncEditHotelData.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });
  },
});

export const {hotelEditNull} =HotelSlice.actions
export default HotelSlice.reducer;
