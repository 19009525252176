import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";
import { notificationMsg } from "../utilis/NotficationSlice";

const initialState = {
  origanList: [],
  destinationList: [],
  flightGroup: [],
  SoldFlightGroup: [],
  FlightGrpType: [],
  postFlightGroup: [],
  editFLightData: null,
  singleFlightData: null,
  upDateflightGroup: null,
  flightGroupPostRes: [],
  // isLoading: false,
  pnrError: null,
  seatsError: null,
};

// for originList
export const asyncGetOriganListData = createAsyncThunk(
  "asyncGetOriganListData/get",
  async (value, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/airportlist/filter/?query_param=${value}`
      );
      return [...response.data.data];
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// for distinationList
export const asyncGetDestinationListData = createAsyncThunk(
  "asyncGetDestinationListData/get",
  async (value, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/airportlist/filter/?query_param=${value}`
      );
      return [...response?.data?.data];
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

//get flightgroup data
export const asyncGetFlightGroupData = createAsyncThunk(
  "asyncGetFlightGroupData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/flight-group");
      return [...response?.data?.data];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get all sold flight group

export const asyncGetSoldFlightGroupData = createAsyncThunk(
  "asyncGetSoldFlightGroupData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/flight-sold-group");
      return [...response?.data?.data];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//post flightgroup data
export const asyncPostFlightGroupData = createAsyncThunk(
  "asyncPostFlightGroupData/post",
  async (obj, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/flight-group", obj);
      dispatch(asyncGetFlightGroupData());
      return await response?.data;
    } catch (error) {
      // return await error.response.data.message
      // console.log("errrrrr",error);
      // return dispatch(notificationMsg(error.response.data.message))
      return rejectWithValue(error);
    }
  }
);

//Edit FlightGroup data
export const asyncEditFlightGroupData = createAsyncThunk(
  "asyncEditFlightGroupData/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/flight_groups/group_promotion_edit/${id}`
      );
      return await response?.data?.data?.edit;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// updating FLightGroup data
export const asyncUpdateFlightGroupData = createAsyncThunk(
  "asyncUpdateFlightGroupData/post",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.put(
        `/api/v1/b2b/flight_groups/group_promotion_edit/${id}`
      );
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

//deleting flightgroup data
export const asyncDeleteFlightGroupData = createAsyncThunk(
  "asyncDeleteFlightGroupData/delete",
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/flight_groups/group_promotion_destory/${id}`
      );
      dispatch(asyncGetFlightGroupData());
      return await response?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// getSingleFlightGroup

export const asyncgetSingleFlightGroup = createAsyncThunk(
  "asyncEditFlightGroupData/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/flight-group/${id}`);
      // console.log("response",response);

      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// update flight group data majid
export const asyncUpdateFlightGroup = createAsyncThunk(
  "asyncUpdateFlightGroup/post",
  async ({ id, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/flight-group/${id}`,
        body
      );
      // console.log("rresponsees",response);
      // dispatch(asyncGetFlightGroupData());
      return await response?.data;
      // console.log("response?.data",response?.data.status);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// delete flight group data asyncdeleteFlightGroup

export const asyncdeleteFlightGroup = createAsyncThunk(
  "asyncUpdateFlightGroup/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/flight-group/${id}`);
      dispatch(asyncGetFlightGroupData()); // return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

////update status
export const updateFlightGroupStatus = createAsyncThunk(
  "updateFlightGroupStatus/patch",
  async ({ id, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/flight-group-status/${id}`,
        data
      );

      dispatch(asyncGetFlightGroupData()); // return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// ******************************************** FLIGHT GROUP TYPE SECTION ***********************************************

//get flight group type
export const asyncGetFlightGrpType = createAsyncThunk(
  "asyncGetFlightGrpType/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/flight-group-type");
      return await response.data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

//post flight group type
export const asyncPostFlightGrpType = createAsyncThunk(
  "asyncPostFlightGrpType/post",
  async (type_name, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/flight-group-type", {
        type_name,
      });
      dispatch(notificationMsg("success"));
      return response.data.data;
    } catch (error) {
      return dispatch(notificationMsg(error.response.data.message));
      //    return rejectWithValue(error)
    }
  }
);

////update flight group booke seats
export const asyncupdateFlightGroupBookedSeats = createAsyncThunk(
  "asyncupdateFlightGroupBookedSeats/patch",
  async ({ id, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/flight-group-bookedSeats/${id}`,
        body
      );
      // dispatch(getAsyncBookingDetails());
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// update flight group passenger
export const asyncUpdateFlightGroupPassenger = createAsyncThunk(
  "asyncUpdateFlightGroupPassenger/patch",
  async ({ id, body }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/update-flight-grout-passenger/${id}`,
        body
      );
      // console.log("rresponsees",response);
      // dispatch(asyncGetFlightGroupData());
      return await response?.data;
      // console.log("response?.data",response?.data.status);
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const FlightGroup = createSlice({
  name: "flightGroup",
  initialState,
  reducers: {
    makeOriginNull: (state) => {
      state.origanList = [];
    },
    UpdatedDataNull: (state) => {
      state.upDateflightGroup = null;
    },
    resetValue: (state) => {
      state.singleFlightData = null;
    },
  },
  extraReducers: (builder) => {
    // getting origanlist data
    builder.addCase(asyncGetOriganListData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.origanList = [];
    });
    builder.addCase(asyncGetOriganListData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.origanList = [...payload];
    });
    builder.addCase(asyncGetOriganListData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // getting destinationList data
    builder.addCase(
      asyncGetDestinationListData.pending,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.destinationList = [];
      }
    );
    builder.addCase(
      asyncGetDestinationListData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.destinationList = payload;
      }
    );
    builder.addCase(
      asyncGetDestinationListData.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );

    //getting FlightGroup data
    builder.addCase(asyncGetFlightGroupData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
      state.flightGroup = [];
    });
    builder.addCase(asyncGetFlightGroupData.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.flightGroup = payload;
    });
    builder.addCase(asyncGetFlightGroupData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    // sold flight group

    builder.addCase(
      asyncGetSoldFlightGroupData.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = null;
        state.SoldFlightGroup = [];
      }
    );
    builder.addCase(
      asyncGetSoldFlightGroupData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.SoldFlightGroup = payload;
      }
    );
    builder.addCase(
      asyncGetSoldFlightGroupData.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );
    //posting FLightGroup data
    builder.addCase(asyncPostFlightGroupData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.pnrError = null;
    });
    builder.addCase(
      asyncPostFlightGroupData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.pnrError = null;
        state.flightGroupPostRes = payload;
      }
    );
    builder.addCase(asyncPostFlightGroupData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.pnrError = payload;
    });

    //deleting FLightGroup data
    builder.addCase(
      asyncDeleteFlightGroupData.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncDeleteFlightGroupData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        const flightGroupData = state.flightGroup.filter(
          (data) => data.id !== payload
        );
        state.flightGroup = flightGroupData;
      }
    );
    builder.addCase(
      asyncDeleteFlightGroupData.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
      }
    );

    //edit FLightGroup data
    builder.addCase(asyncEditFlightGroupData.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      asyncEditFlightGroupData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.editFLightData = payload;
      }
    );
    builder.addCase(asyncEditFlightGroupData.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    // get single flight data majid
    builder.addCase(asyncgetSingleFlightGroup.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      asyncgetSingleFlightGroup.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.singleFlightData = payload;
      }
    );
    builder.addCase(
      asyncgetSingleFlightGroup.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );

    // update FLightGroup data
    builder.addCase(asyncUpdateFlightGroup.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateFlightGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.upDateflightGroup = payload?.book_id;
    });
    builder.addCase(asyncUpdateFlightGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.seatsError = payload;
    });

    // update FLightGroup data wajid
    builder.addCase(
      asyncUpdateFlightGroupData.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncUpdateFlightGroupData.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.flightGroup = payload;
      }
    );
    builder.addCase(
      asyncUpdateFlightGroupData.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );

    // ******************************************** FLIGHT GROUP TYPE SECTION ***********************************************

    // Get FlightGrpType
    builder.addCase(asyncGetFlightGrpType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncGetFlightGrpType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.FlightGrpType = payload;
    });
    builder.addCase(asyncGetFlightGrpType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });

    //posting FLight Group type
    builder.addCase(asyncPostFlightGrpType.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(asyncPostFlightGrpType.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.FlightGrpType = state.flightGroup.push(payload);
    });
    builder.addCase(asyncPostFlightGrpType.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    });
  },
});

export const { makeOriginNull, resetValue, UpdatedDataNull } =
  FlightGroup.actions;

export default FlightGroup.reducer;
