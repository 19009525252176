import React, { useState, useEffect } from "react";
import DeleteModal from "../../DeleteModal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import ReactDatePicker from "react-datepicker";
import { HTTP } from "../../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteHotelRate,
  asyncEditHotelRates,
  asyncGetHotelRate,
  asyncGetHotelRateRoom,
  asyncPostHotelRate,
  asyncUpdateHotelRate,
  editHotelRateNull,
} from "../../../redux/features/umrah/HotelRateSlice";
import { options } from "../../../Utils/ColumnOptions";
import * as yup from "yup";

import AppErrorMessage from "../../AppForm/ErrorMessage";
import BtnLoader from "../../AppForm/BtnLoader";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { asyncGetHotelRoomData } from "../../../redux/features/umrah/HotleRoomSlice";

const HotelRates = () => {
  const { hotelRateData, editHotelRate, loading, hotelRateRoom, reLoadPage } =
    useSelector((state) => state.hotelRates);
  // this is for room name
  const { hotelRoomData } = useSelector((state) => state.hotelRoom);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [cityVal, setCityVal] = useState(1);
  const [roomId, setRoomId] = useState(1);

  useEffect(() => {
    dispatch(asyncGetHotelRate());
    dispatch(asyncGetHotelRoomData());

    if (editHotelRate?.hotel_id) {
      dispatch(asyncGetHotelRateRoom(editHotelRate?.hotel_id));
    }
  }, [dispatch, editHotelRate?.hotel_id]);

  const roomNature = [
    { id: 1, name: "Room" },
    { id: 2, name: "Sharing" },
  ];
  const cityNames = [
    { id: 1, name: "Mecca" },
    { id: 2, name: "Medina" },
  ];

  const roomPrice = [
    { id: 1, name: "Double" },
    { id: 2, name: "Tripple" },
    { id: 2, name: "Quad" },
    { id: 2, name: "Quint" },
    { id: 2, name: "6 Person" },
    { id: 2, name: "7 Person" },
    { id: 2, name: "8 Person" },
    { id: 2, name: "9 Person" },
  ];

  const validationSchem = yup.object().shape({
    umrah_hotel_city: yup.number().required("umrah hotel city Required"),
    hotel_id: yup.number().required("hotel id Required"),
    hotelRateValue: yup.array().of(
      yup.object().shape({
        Room_Nature: yup.string().required("Room Nature is required"),
        ROE: yup.number().positive().required("ROE is required"),
        from_date: yup.string().required("Date is required"),
        to_date: yup.string().required("Date is required"),

        // if room nature is sharing then these will be required else not
        pay_SAR_Sharing:
          roomId === 2 &&
          yup.number().positive().required("pay sar is required"),
        rec_SAR_Sharing:
          roomId === 2 &&
          yup.number().positive().required("Rec sar is required"),

        roomValue:
          roomId === 1 &&
          yup.array().of(
            yup.object().shape({
              Room: yup.string().required("Room is required"),
              pay_SAR: yup.number().positive().required("pay sar is required"),
              rec_SAR: yup.number().positive().required("Rec sar is required"),
            })
          ),
      })
    ),
  });

  const initialValues = {
    umrah_hotel_city: editHotelRate?.city_id || cityNames[0].id,
    hotel_id: editHotelRate ? editHotelRate?.hotel_id : 2,
    hotelRateValue: editHotelRate
      ? [
          {
            Room_Nature: editHotelRate?.Room_Nature,
            room_id: editHotelRate?.room_id,
            supplier_id: editHotelRate?.supplier_id,
            from_date: editHotelRate?.from_date,
            to_date: editHotelRate?.to_date,
            ROE: editHotelRate?.roe,
            roomValue: [
              {
                Room: editHotelRate?.room_qty, //two arrays
                pay_SAR: editHotelRate?.pay_SAR, //two arrays
                rec_SAR: editHotelRate?.rec_SAR, //two arrays
              },
            ],
            pay_SAR_Sharing: editHotelRate?.pay_SAR,
            rec_SAR_Sharing: editHotelRate?.rec_SAR,
          },
        ]
      : [
          {
            Room_Nature: roomNature[0].name,
            room_id: 3,
            supplier_id: hotelRateData?.suppliers?.length
              ? hotelRateData?.suppliers[0]?.id
              : 0,
            from_date: "",
            to_date: "",
            ROE: "",
            roomValue: [
              {
                Room: roomPrice[0].name, //two arrays
                pay_SAR: "", //two arrays
                rec_SAR: "", //two arrays
              },
            ],
            pay_SAR_Sharing: "",
            rec_SAR_Sharing: "",
          },
        ],
  };

  const hotelRateStatus = async (status, id) => {
    if (!status) {
      var statusrow = 0;
    } else if (status) {
      var statusrow = 1;
    } else if (status === 0) {
      var statusrow = 0;
    } else if (status === 1) {
      var statusrow = 1;
    }
    const formdata = {
      ratesid: id,
      status: statusrow,
    };
    HTTP.post(`/api/v1/b2b/hotel_rates/hotelRateStatus`, formdata)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div class="checkbox-rect">
              <input
                value={value}
                checked={value}
                type="checkbox"
                id={"with_sar_" + id}
                onChange={(event) => {
                  updateValue(!value);
                  hotelRateStatus(!value, id);
                }}
              />
              <label for={"with_sar_" + id} className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "room_type_hotel.umrah_hotel_name",
      label: "Hotel",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Room_Nature",
      label: "Room Nature",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "room_qty",
      label: "Room type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "room_id",
      label: "Room Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div class="checkbox-rect">
              <p>
                {hotelRoomData?.RoomTypeModel?.filter(
                  (it) => it.id === value
                ).map((it) => it.name)}
              </p>
            </div>
          );
        },
      },
    },
    {
      name: "supplier_id",
      label: "Supplier Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div class="checkbox-rect">
              <p>
                {hotelRateData?.suppliers
                  ?.filter((it) => it.id === value)
                  .map((it) => it.name)}
              </p>
            </div>
          );
        },
      },
    },
    {
      name: "from_date",
      label: "From Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "to_date",
      label: "To Date",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "roe",
      label: "Roe",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "pay_SAR",
      label: "Pay Sar",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "rec_SAR",
      label: "Rec Sar",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <div>
              <button
                className="btn-danger rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(id);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
              <button
                className="btn-success rounded edit-delete-icon-button"
                onClick={() => {
                  dispatch(asyncEditHotelRates(id));
                  window.scroll({ top: 0 });
                }}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];


  return (
    <div className="col-12 col-sm-11 mx-auto rounded-3 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Hotel Rates</p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchem}
        enableReinitialize={true}
        initialValues={{ ...initialValues }}
        onSubmit={(values, action) => {
          let umrah_hotel_city = values.umrah_hotel_city;
          let hotel_id = values.hotel_id;
          let Room_Nature = values.hotelRateValue.map((it) => it.Room_Nature);
          let room_id = values.hotelRateValue.map((it) => it.room_id);

          let supplier_id = values.hotelRateValue.map((it) => it.supplier_id);
          let from_date = values.hotelRateValue.map((it) => it.from_date);
          let to_date = values.hotelRateValue.map((it) => it.to_date);
          let ROE = values.hotelRateValue.map((it) => it.ROE);
          // if room is not sharing
          let Room = values.hotelRateValue.map((it) =>
            it.roomValue.map((it) => it.Room)
          );
          let pay_SAR = values.hotelRateValue.map((it) =>
            it.roomValue.map((it) => it.pay_SAR)
          );
          let rec_SAR = values.hotelRateValue.map((it) =>
            it.roomValue.map((it) => it.rec_SAR)
          );
          // if room is sharing

          let pay_SAR_Sharing = values.hotelRateValue.map(
            (it) => it.pay_SAR_Sharing
          );
          let rec_SAR_Sharing = values.hotelRateValue.map(
            (it) => it.rec_SAR_Sharing
          );

          const vals = {
            id: editHotelRate?.id,
            umrah_hotel_city,
            hotel_id,
            Room_Nature,
            room_id,
            supplier_id,
            from_date,
            to_date,
            ROE,
            Room,
            pay_SAR,
            rec_SAR,
            pay_SAR_Sharing,
            rec_SAR_Sharing,
          };
          if (editHotelRate) {
            dispatch(asyncUpdateHotelRate(vals));
            dispatch(editHotelRateNull());
          } else {
            dispatch(asyncPostHotelRate(vals));
            action.resetForm({ values: "" });
          }
        }}
      >
        {({ values, errors, setFieldValue, handleChange }) => {
          return (
            <Form className="col-12 px-5 mt-5">
              <div className="col-10 umrah-hotel-rates w-100">
                <div className="col-12 d-flex gap-5">
                  <div className="col-4">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Select City
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={values.umrah_hotel_city}
                        onChange={handleChange}
                        name="umrah_hotel_city"
                      >
                        {cityNames.map((city) => (
                          <MenuItem
                            value={city.id}
                            key={city.id}
                            onClick={() => setCityVal(city.id)}
                          >
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <AppErrorMessage
                        errors={errors && errors.umrah_hotel_city}
                      />
                    </FormControl>
                  </div>
                  <div className="col-4">
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Hotel
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="hotel_id"
                        onChange={handleChange}
                        value={values.hotel_id}
                      >
                        <MenuItem value={2} key={2}>
                          ---Select Hotel---
                        </MenuItem>
                        {editHotelRate
                          ? hotelRateData?.UmrahHotelModel?.filter(
                              (it) =>
                                it.umrah_city_id === editHotelRate?.city_id
                            ).map((hotel) => {
                              return (
                                <MenuItem
                                  onClick={(e) => {
                                    dispatch(
                                      asyncGetHotelRateRoom(hotel.u_hotel_id)
                                    );
                                  }}
                                  value={hotel.u_hotel_id}
                                  key={hotel.u_hotel_id}
                                >
                                  {hotel.umrah_hotel_name}
                                </MenuItem>
                              );
                            })
                          : hotelRateData?.UmrahHotelModel?.filter(
                              (it) => it.umrah_city_id === cityVal
                            ).map((hotel) => {
                              return (
                                <MenuItem
                                  onClick={(e) => {
                                    dispatch(
                                      asyncGetHotelRateRoom(hotel.u_hotel_id)
                                    );
                                  }}
                                  value={hotel.u_hotel_id}
                                  key={hotel.u_hotel_id}
                                >
                                  {hotel.umrah_hotel_name}
                                </MenuItem>
                              );
                            })}
                      </Select>
                    </FormControl>
                    <AppErrorMessage errors={errors && errors.hotel_id} />
                  </div>
                </div>

                <FieldArray
                  name="hotelRateValue"
                  value={values.hotelRateValue}
                  render={(hotelArrayHelpers) => (
                    <div className="d-flex flex-column w-100">
                      {values.hotelRateValue.map((friend, index) => (
                        <div key={index} className=" my-5 w-100 d-flex">
                          {/* main section  */}
                          <div className="d-flex flex-column w-100">
                            <div className="d-flex w-100  flex-wrap justify-content-between">
                              <FormControl variant="standard">
                                <InputLabel id="demo-simple-select-label">
                                  Room Nature
                                </InputLabel>
                                <Select
                                  name={`hotelRateValue.${index}.Room_Nature`}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={handleChange}
                                  value={friend?.Room_Nature}
                                  style={{ width: 270 }}
                                >
                                  {roomNature?.map((room) => (
                                    <MenuItem
                                      value={room.name}
                                      key={room.id}
                                      onClick={(e) => setRoomId(room.id)}
                                    >
                                      {room.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              {values.hotelRateValue[index].Room_Nature ===
                                "Room" && (
                                <FormControl variant="standard">
                                  <InputLabel id="demo-simple-select-label">
                                    Room Name
                                  </InputLabel>
                                  <Select
                                    name={`hotelRateValue.${index}.room_id`}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={handleChange}
                                    value={friend?.room_id}
                                    style={{ width: 270 }}
                                  >
                                    <MenuItem value={3} key={3}>
                                      ---Select Room---
                                    </MenuItem>
                                    {
                                      hotelRateRoom?.map((room) => (
                                        <MenuItem value={room.id} key={room.id}>
                                          {room.name}
                                        </MenuItem>
                                      ))
                                    }
                                  </Select>
                                </FormControl>
                              )}

                              <FormControl variant="standard">
                                <InputLabel id="demo-simple-select-label">
                                  Supplier
                                </InputLabel>
                                <Select
                                  name={`hotelRateValue.${index}.supplier_id`}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  onChange={handleChange}
                                  value={friend?.supplier_id}
                                  style={{ width: 270 }}
                                >
                                  {hotelRateData?.suppliers?.map((sup) => (
                                    <MenuItem value={sup.id} key={sup.id}>
                                      {sup.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <div className="d-flex flex-column">
                                <TextField
                                  variant="standard"
                                  type={"date"}
                                  name={`hotelRateValue.${index}.from_date`}
                                  label={"From Date"}
                                  value={friend?.from_date}
                                  sx={{ width: 120 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name={`hotelRateValue.[${index}].from_date`}
                                >
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div className="d-flex flex-column">
                                <TextField
                                  variant="standard"
                                  type={"date"}
                                  name={`hotelRateValue.${index}.to_date`}
                                  label={"To Date"}
                                  value={friend?.to_date}
                                  sx={{ width: 120 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name={`hotelRateValue.[${index}].to_date`}
                                >
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div className="d-flex flex-column">
                                <TextField
                                  name={`hotelRateValue.${index}.ROE`}
                                  type={"text"}
                                  label="ROE"
                                  value={friend?.ROE}
                                  variant="standard"
                                  style={{ width: "100px" }}
                                  onChange={handleChange}
                                />

                                <ErrorMessage
                                  name={`hotelRateValue.[${index}].ROE`}
                                >
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                            {/* add room  */}

                            <div>
                              {values?.hotelRateValue[index]?.Room_Nature ===
                              "Room" ? (
                                <FieldArray
                                  name={`hotelRateValue.${index}.roomValue`}
                                  render={(helper) => (
                                    <div className="d-flex w-100 flex-wrap">
                                      {friend?.roomValue?.map(
                                        (obj, ndx) => (
                                          (
                                            <div
                                              key={ndx}
                                              className=" my-5 w-100"
                                            >
                                              <div className="d-flex w-100 flex-wrap gap-4">
                                                <FormControl variant="standard">
                                                  <InputLabel id="demo-simple-select-label">
                                                    Room Price
                                                  </InputLabel>
                                                  <Select
                                                    name={`hotelRateValue.${index}.roomValue.${ndx}.Room`}
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    onChange={handleChange}
                                                    value={obj?.Room}
                                                    style={{ width: 270 }}
                                                  >
                                                    {roomPrice?.map((room) => (
                                                      <MenuItem
                                                        value={room.name}
                                                        key={room.id}
                                                      >
                                                        {room.name}
                                                      </MenuItem>
                                                    ))}
                                                  </Select>
                                                </FormControl>
                                                <div className="d-flex flex-column">
                                                  <TextField
                                                    name={`hotelRateValue.${index}.roomValue.${ndx}.pay_SAR`}
                                                    type={"text"}
                                                    label="Payable SAR"
                                                    value={obj?.pay_SAR}
                                                    onChange={handleChange}
                                                    variant="standard"
                                                    style={{ width: "100px" }}
                                                  />
                                                  <ErrorMessage
                                                    name={`hotelRateValue.[${index}].roomValue.[${ndx}].pay_SAR`}
                                                  >
                                                    {(msg) => (
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        {msg}
                                                      </div>
                                                    )}
                                                  </ErrorMessage>
                                                </div>
                                                <div className="d-flex flex-column">
                                                  <TextField
                                                    name={`hotelRateValue.${index}.roomValue.${ndx}.rec_SAR`}
                                                    type={"text"}
                                                    label="Receivable SAR"
                                                    value={obj?.rec_SAR}
                                                    onChange={handleChange}
                                                    variant="standard"
                                                    style={{ width: "100px" }}
                                                  />
                                                  <ErrorMessage
                                                    name={`hotelRateValue.[${index}].roomValue.[${ndx}].rec_SAR`}
                                                  >
                                                    {(msg) => (
                                                      <div
                                                        style={{ color: "red" }}
                                                      >
                                                        {msg}
                                                      </div>
                                                    )}
                                                  </ErrorMessage>
                                                </div>

                                                {!editHotelRate && (
                                                  <div>
                                                    {ndx >= 1 && (
                                                      <button
                                                        className="btn bg-danger m-3 text-white"
                                                        type="button"
                                                        onClick={() =>
                                                          helper.remove(ndx)
                                                        }
                                                      >
                                                        - Room Type
                                                      </button>
                                                    )}
                                                    {ndx === 0 && (
                                                      <button
                                                        className="btn bg-primary text-white"
                                                        type="button"
                                                        onClick={() =>
                                                          helper.push(ndx)
                                                        }
                                                      >
                                                        + Room Type
                                                      </button>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )
                                        )
                                      )}
                                    </div>
                                  )}
                                />
                              ) : (
                                <div className="d-flex gap-3 mt-5">
                                  <div className="d-flex flex-column">
                                    <TextField
                                      name={`hotelRateValue.${index}.pay_SAR_Sharing`}
                                      type={"text"}
                                      label="Receivable SAR"
                                      value={
                                        values.hotelRateValue[index]
                                          .pay_SAR_Sharing
                                      }
                                      onChange={handleChange}
                                      variant="standard"
                                      style={{ width: "130px" }}
                                    />
                                    <ErrorMessage
                                      name={`hotelRateValue.[${index}].pay_SAR_Sharing`}
                                    >
                                      {(msg) => (
                                        <div style={{ color: "red" }}>
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>

                                  <div className="d-flex flex-column">
                                    <TextField
                                      name={`hotelRateValue.${index}.rec_SAR_Sharing`}
                                      type={"text"}
                                      label="Receivable SAR"
                                      value={
                                        values.hotelRateValue[index]
                                          .rec_SAR_Sharing
                                      }
                                      onChange={handleChange}
                                      variant="standard"
                                      style={{ width: "130px" }}
                                    />
                                    <ErrorMessage
                                      name={`hotelRateValue.[${index}].rec_SAR_Sharing`}
                                    >
                                      {(msg) => (
                                        <div style={{ color: "red" }}>
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* buttons  */}
                          </div>
                          {!editHotelRate && (
                            <div style={{ width: 200 }}>
                              {index >= 1 && (
                                <button
                                  className="btn bg-danger m-3 text-white"
                                  type="button"
                                  onClick={() =>
                                    hotelArrayHelpers.remove(index)
                                  }
                                >
                                  - Room
                                </button>
                              )}
                              {index === 0 && (
                                <button
                                  className="btn bg-primary text-white"
                                  type="button"
                                  onClick={() =>
                                    hotelArrayHelpers.push({
                                      Room_Nature: roomNature[0].name,
                                      room_id: hotelRateRoom.length
                                        ? hotelRateRoom[0].id
                                        : 0,
                                      supplier_id: hotelRateData?.suppliers
                                        ?.length
                                        ? hotelRateData?.suppliers[0]?.id
                                        : 0,
                                      from_date: "",
                                      to_date: "",
                                      ROE: "",
                                      roomValue: [
                                        {
                                          Room: "", //two arrays
                                          pay_SAR: "", //two arrays
                                          rec_SAR: "", //two arrays
                                        },
                                      ],
                                      pay_SAR_Sharing: "",
                                      rec_SAR_Sharing: "",
                                    })
                                  }
                                >
                                  + Room
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                />
                <div className="text-center">
                  <button className="btn setup-btn m-2" type="submit">
                    {loading ? (
                      <BtnLoader />
                    ) : editHotelRate ? (
                      "update"
                    ) : (
                      "save"
                    )}
                  </button>
                  {editHotelRate && (
                    <button
                      className="btn setup-btn bg-danger"
                      onClick={(e) => dispatch(editHotelRateNull())}
                      type="submit"
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData && modalData[0]}
          onClick={(e) => {
            dispatch(asyncDeleteHotelRate(modalData));
            setShowDeleteModal(false);
          }}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Hotel Rates Details"}
          data={hotelRateData?.umrahHotelRatesModel}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default HotelRates;
