import React from 'react'
import Deposit from '../components/Setup/Payments/Reciept'

const Reciept = () => {
  return (
    <div className='Full_height'>
      <Deposit />
    </div>
  )
}

export default Reciept