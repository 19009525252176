import React, { useEffect, useState } from "react";
import ManualInvoic from "../components/Finance/Vouchers/ManualInvoice";
import { asyncGetNewAgencyReg } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetAgentGroup } from "../redux/features/setupRestPages/agentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getAsyncBookingDetailsForVocherDetails,
  getSingleVoucherLog,
} from "../redux/features/booking/bookingDetailsSlice";
import VoucherCreated from "../components/Finance/Vouchers/VoucherEntry/VoucherCreated";
import ManualVoucherLogsCreate from "../components/Finance/Vouchers/VoucherEntry/ManualVoucherLogsCreate";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";
import ManualCreditIndex from "../components/Finance/Vouchers/ManualInvoice/ManuallCreditNew/ManualCreditIndex";
import { getAsyncBookingDetailsForVocherDetailsCredit } from "../redux/features/PaxRefund/PaxRefundsSlice";

function ManuallcreditNew() {
  const { VoucherBooking, singleVoucherLog } = useSelector(
    (state) => state?.booking
  );
  const { CreditVoucherBooking } = useSelector((state) => state?.PaxRefunds);

  const { searchAgency, newAgencyRegData } = useSelector(
    (state) => state.newAgency
  );
  const [ManualInvoice, setManualInvoice] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const tkt_voucher_no =
    location?.state?.tkt_voucher_no ||params?.tkt;
  const voucher_id = location?.state?.voucher_id || params?.vid;
  const CreditNote = location?.state?.voucher_no?.split("-")?.at();
  useEffect(() => {
    let id = Number(location?.state?.voucherId) || Number(params?.id);

    // if (id) {
    if (CreditNote === "CN") {
        console.log("fggf", id, tkt_voucher_no, voucher_id);
      dispatch(
        getAsyncBookingDetailsForVocherDetailsCredit({
          id,
          tkt_voucher_no,
          voucher_id,
        })
      );
    } else {
      dispatch(
        getAsyncBookingDetailsForVocherDetails({
          id,
          tkt_voucher_no,
          voucher_id,
        })
      );
    }

    dispatch(
      getSingleVoucherLog({
        voucher_id,
      })
    );
    dispatch(asyncGetNewAgencyReg());
    // }
    setTimeout(() => {
      setManualInvoice(true);
    }, 2000);
  }, [location?.state?.voucherId, params?.id]);
  const VoucherBookingData =
    CreditNote === "CN" ? CreditVoucherBooking : VoucherBooking;

  return (
    <div className="Full_height">
      {/* VoucherBooking?.id && newAgencyRegData?.length && */}
      {ManualInvoice && (
        <ManualCreditIndex
          VoucherBooking={VoucherBookingData}
          CreditNote={CreditNote}
        />
      )}

      {voucher_id && VoucherBookingData?.id && ManualInvoice ? (
        <div className="col-11 mx-auto bg-white  shadow-sm rounded-2">
          <ManualVoucherLogsCreate
            voucher={[VoucherBookingData?.voucherData, singleVoucherLog]}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ManuallcreditNew;
