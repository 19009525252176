import MUIDataTable from "mui-datatables";
import React, { useState, useEffect } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import BtnLoader from "../../../AppForm/BtnLoader";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "../../../DeleteModal";
import {
  asyncEditAccountData,
  asyncGetAccountData,
  asyncPostAccountData,
  asyncUpdateAccountData,
  editAccount_null,
} from "../../../../redux/features/Finance/Account/ChartOfAccountSlice";
import * as yup from "yup";
import { NODEURL } from "../../../../redux/features/ApiConfig";

function ChartOfAccount() {
  const dispatch = useDispatch();

  /***** use selector redux *****/
  const { loading, editAccountData, accountData } = useSelector(
    (state) => state.chartAccount
  );

  /********** use state react ************* */
  const [ShowModel, setShowModel] = useState(false);

  /*****  Menu props dropdown ********* */
  const dropMenuProp = {
    PaperProps: {
      sx: {
        width: "inherit",
      },
    },
  };

  /*************  Update account status **************** */
  const accountStatus = async (status, id) => {
    if (!status) {
      var statusrow = 0;
    } else if (status) {
      var statusrow = 1;
    } else if (status === 0) {
      var statusrow = 0;
    } else if (status === 1) {
      var statusrow = 1;
    }
    const formdata = {
      id: id,
      status: statusrow,
    };
    NODEURL.patch(`/api/v1/b2b/account/chart-acount-status/${id}`, {
      acc_status: formdata.status,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  /********** account level data ************/
  const account_level = [
    {
      id: 1,
      name: "Level 2",
      value: 2,
    },
    {
      id: 2,
      name: "Level 3",
      value: 3,
    },
    {
      id: 3,
      name: "Level 4",
      value: 4,
    },
    {
      id: 4,
      name: "Level 5",
      value: 5,
    },
  ];

  /**********Account nature data *********8 */
  const account_nature = [
    {
      id: 1,
      name: "SUBSIDIARY",
    },
    {
      id: 2,
      name: "CONTROL",
    },
  ];

  /************ mui table column ***************/
  const columns = [
    {
      name: "acc_id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "acc_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            tableMeta?.rowData[2] > 1 && (
              <>
                <div className="checkbox-rect">
                  <input
                    value={value}
                    checked={value}
                    type="checkbox"
                    id={"with_sar_" + id}
                    onChange={(event) => {
                      updateValue(!value);
                      accountStatus(!value, id);
                    }}
                  />
                  <label htmlFor={"with_sar_" + id} className="h6"></label>
                </div>
              </>
            )
          );
        },
      },
    },
    {
      label: "Account Level",
      name: "acc_level",
    },
    {
      label: "Account Level Code",
      name: "acc_level_code",
    },
    {
      label: "Account Title",
      name: "acc_title",
    },
    {
      label: "Account Nature",
      name: "acc_nature",
    },
    {
      label: "Option",
      name: "option",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let acc_id = tableMeta?.rowData[0];
          return (
            tableMeta?.rowData[2] > 1 && (
              <>
                <button
                  className="btn-success rounded edit-delete-icon-button me-2"
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                    dispatch(asyncEditAccountData(acc_id));
                  }}
                >
                  <i className="fa fa-thin fa-pencil"></i>
                </button>
              </>
            )
          );
        },
      },
    },
  ];

  /********** options muidata-table  ***********/
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    title: false,
    rowsPerPage: 100,
  };

  /************ initial Values Formik **********/
  const initialValues = {
    acc_status: 1,
    acc_level: editAccountData?.acc_level || "",
    acc_title: editAccountData?.acc_title || "",
    acc_code: editAccountData?.acc_code || "",
    acc_nature: editAccountData?.acc_nature || "",
    acc_parent: editAccountData?.acc_parent || "",
  };

  /******** Validation Schema ***************** */
  const validationSchema = yup.object().shape({
    acc_level: yup.string().required("Account Level Required"),
    acc_title: yup.string().required("Account Title Required"),
    acc_nature: yup.string().required("Account Nature Required"),
    acc_parent: yup.string().required("Parent Account Required"),
  });
  /************ useEffect ********************* */
  useEffect(() => {
    dispatch(asyncGetAccountData());
  }, [dispatch]);

  return (
    <div>
      <div className="pb-4">
        {/********** add edit account  **********/}
        <div className="col-md-11 mx-auto rounded-2 shadow-sm mt-5 pb-5 flash-news">
          <p className="h4 otawix-heading">Add/Edit Account</p>
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let data = {
                acc_status: 1,
                acc_level: values?.acc_level,
                acc_title: values?.acc_title,
                acc_nature: values?.acc_nature,
                acc_parent: values?.acc_parent,
              };

              let editData = {
                acc_status: editAccountData?.acc_status,
                acc_id: editAccountData?.acc_id,
                acc_level: values?.acc_level,
                acc_title: values?.acc_title,
                acc_nature: values?.acc_nature,
                acc_parent: values?.acc_parent,
              };
              if (editAccountData) {
                dispatch(asyncUpdateAccountData(editData));
              } else {
                dispatch(asyncPostAccountData(data));
              }
              dispatch(editAccount_null());
              resetForm();
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div className="row  pt-5 px-4">
                    {/********* * account level **************/}
                    <div className="col-sm-2" style={{ height: "5rem" }}>
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Account Level
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name="acc_level"
                          value={values?.acc_level}
                          onChange={handleChange}
                          MenuProps={dropMenuProp}
                          inputProps={{
                            readOnly: editAccountData ? true : false,
                          }}
                        >
                          {account_level?.map((acc) => (
                            <MenuItem key={acc?.id} value={acc?.value}>
                              {acc?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="acc_level"
                      />
                    </div>
                    {/* ********* account title **********/}
                    <div className="col-sm-2">
                      <TextField
                        label="Account Title"
                        variant="standard"
                        autoComplete="off"
                        className="w-100"
                        name="acc_title"
                        value={values?.acc_title}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("acc_title", value.toUpperCase());
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="acc_title"
                      />
                    </div>
                    {/* ****** account nature ************* */}
                    <div className="col-sm-2">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Account Nature
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name="acc_nature"
                          value={values?.acc_nature}
                          onChange={handleChange}
                          MenuProps={dropMenuProp}
                          inputProps={{
                            readOnly: editAccountData ? true : false,
                          }}
                        >
                          {account_nature
                            ?.filter((er) => {
                              return values?.acc_level === 5 ? er.id !== 2 : er;
                            })
                            .map((acc) => (
                              <MenuItem key={acc?.id} value={acc?.name}>
                                {acc?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="acc_nature"
                      />
                    </div>
                    {/* ****** Parent account level ************* */}
                    <div className="col-sm-2">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Parent Account Level
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          name="acc_parent"
                          value={values?.acc_parent}
                          onChange={handleChange}
                          MenuProps={dropMenuProp}
                          inputProps={{
                            readOnly: editAccountData ? true : false,
                          }}
                        >
                          {accountData
                            ?.filter((ac) => {
                              let val = values?.acc_level;
                              let newV = val && val - 1;
                              return (
                                ac.acc_level === newV &&
                                ac.acc_nature === "CONTROL"
                              );
                            })
                            ?.map((acc) => {
                              return (
                                <MenuItem key={acc?.acc_id} value={acc?.acc_id}>
                                  {acc?.acc_title}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="acc_parent"
                      />
                    </div>
                    <div className="text-center mt-5">
                      {editAccountData && (
                        <button
                          className="setup-btn btn setup-btn me-2"
                          onClick={(e) => {
                            dispatch(dispatch(editAccount_null()));
                          }}
                          style={{ background: "#dc3545", color: "#fff" }}
                        >
                          Cancel
                        </button>
                      )}
                      <button className="btn setup-btn" type="submit">
                        {loading ? (
                          <BtnLoader />
                        ) : editAccountData ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        {/* account list table */}
        <div className="col-md-11 mx-auto rounded-2 shadow-sm mt-5 pb-5 bg-white">
          <DeleteModal
            show={ShowModel}
            setShow={setShowModel}
            onClick={(e) => {
              setShowModel(false);
            }}
          />
          <p className="h4 otawix-heading">Chat of Accounts</p>
          <div className="col-12 pt-5 px-4">
            <MUIDataTable
              className="muidatatable add_airline_table"
              title={""}
              data={accountData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
        {/* ************* */}
      </div>
    </div>
  );
}

export default ChartOfAccount;
