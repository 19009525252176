// import Corporate from '../components/Setup/Payments/CorporatePayment'


import React from 'react'
import AgentPayment from '../components/Setup/Payments/AgentPayment'

const AgentPaymentPage = () => {
  return (
    <div className='Full_height'>
    <AgentPayment/>
  </div>
  )
}

export default AgentPaymentPage