import React from "react";
import AboutUs from "../components/LandingPage/AboutUs";
import Header from "../components/LandingPage/Header";
import Slider from "../components/LandingPage/Swipper";
import PopularPackages from "../components/LandingPage/Packages";
import "../Styles/landingPage.css";
import LogosConteiner from "../components/LandingPage/LogosConteiner";
// import WhyChooseUs from '../components/LandingPage/WhyChooseUs';
import Footer from "../components/LandingPage/Footer";
// import { asyncGetRoleData } from '../redux/features/setupRestPages/Bank_curr_RoleSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import { asyncGetUserToken } from '../redux/features/userLogin/UserSlice';

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Slider />
      <AboutUs />
      <PopularPackages />
      <LogosConteiner />
      {/* <WhyChooseUs /> */}
      <Footer />
    </div>
  );
};

export default LandingPage;
