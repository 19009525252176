import React from 'react'
import Header from '../components/Common/Header'
import Footer from '../components/Footer'
import SupplierListComp from '../components/Finance/Suppliers/SupplierListComp'

const SupplierList = () => {
  return (
    <div>
      <SupplierListComp/>
    </div>
  )
}

export default SupplierList
