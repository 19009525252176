import { TextField } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetFlightGrpType,
  asyncPostFlightGrpType,
} from "../../../redux/features/promotion/FlightGroupsSlice";
import { ChangeStatus } from "../../../Utils/ChangeStatus";
import * as yup from "yup";
import { notificationMsg } from "../../../redux/features/utilis/NotficationSlice";

const FightGroupType = ({ addPermission }) => {
  const dispatch = useDispatch();

  //useSelector
  const { FlightGrpType } = useSelector((state) => state.flightGroup);
  const { message } = useSelector((state) => state.notification);
  const initialValues = {
    type_name: "",
  };

  useEffect(() => {
    dispatch(asyncGetFlightGrpType());
    setTimeout(() => {
      dispatch(notificationMsg(null));
    }, 3000);
  }, [dispatch, message]);

  return (
    <div>
      <p className="h4 otawix-heading box_title">
        Flight Group Type / Default View
      </p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={yup.object({
          type_name: yup.string().required(),
        })}
        onSubmit={(values, { resetForm }) => {
          const type_name = values.type_name;
          dispatch(asyncPostFlightGrpType(type_name));
          resetForm({ values: "" });
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="d-flex flex-wrap p-4 py-5 gap-2">
              <div className="col-2">
                <TextField
                  id="standard-basic"
                  label="Type Name"
                  variant="standard"
                  autoComplete="off"
                  name="type_name"
                  className="w-100"
                  value={values.type_name}
                  onChange={(e) =>
                    setFieldValue("type_name", e.target.value.toUpperCase())
                  }
                />
                <ErrorMessage
                  component="div"
                  className="text-danger mt-1"
                  name="type_name"
                />
                {message === "success" ? (
                  ""
                ) : message !== "success" ? (
                  <p className="text-danger">{message}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-2">
                <button
                  className="button_style px-4"
                  type="submit"
                  disabled={!addPermission}
                >
                  Submit
                </button>
              </div>

              <div className="d-flex flex-wrap gap-3">
                {FlightGrpType?.map((item, index) => {
                  return (
                    <>
                      <div className="px-3" key={index}>
                        <label className="form-control">
                          <input
                            type="radio"
                            id={item?.type_name}
                            name={item?.type_name}
                            value={item?.status}
                            checked={item?.status}
                            onChange={(e) => {
                              ChangeStatus(
                                "/api/v1/b2b/flight-group-type-status",
                                !item?.status,
                                item?.id,
                                "status"
                              );
                              setTimeout(() => {
                                dispatch(asyncGetFlightGrpType());
                              }, 500);
                            }}
                          />
                          <span className="h6">{item?.type_name}</span>
                        </label>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FightGroupType;
