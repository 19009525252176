import React from 'react'
import PasswordComp from '../components/Setup/Password'

const ApiPassword = () => {
  return (
    <div className='Full_height'>
      <PasswordComp />
    </div>
  )
}

export default ApiPassword