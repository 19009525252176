import { useEffect, useState } from "react";
import { timeDuration } from "../../../../../Utils/FixedTwoDecimal";
import { NODEURL } from "../../../../../redux/features/ApiConfig";
import moment from "moment";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import { MdFlight, MdOutlineFlightLand, MdOutlineFlightTakeoff } from "react-icons/md";

export const ConnectedFlightFlyjinnah = ({data,item,inde,flyJinnahFares,locDeparture,flightDuration,locArrival}) => {
    const [airport_name, setairport_name] = useState("");
    const [arr_airport_name, setArrairport_name] = useState("");
    
    //api call for fatching airport name
    useEffect(() => {
      const fetchDataDep = async () => {
        try {
          let airportCode = item?.origin?.airportCode;
          const response = await NODEURL.get(
            `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
          );
          const data = response.data.data?.at(0)?.airport_name;
          // Handle the data here, for example, set it in the state
          setairport_name(data);
        } catch (error) {
          // Handle errors here
        }
      };
  
      const fetchDataArr = async () => {
        try {
          let airportCode = item?.destination?.airportCode;
          const response = await NODEURL.get(
            `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
          );
          const data = response.data.data?.at(0)?.airport_name;
          // Handle the data here, for example, set it in the state
          setArrairport_name(data);
        } catch (error) {
          // Handle errors here
        }
      };
  
      fetchDataDep(); 
      fetchDataArr(); 
    }, []);
  
    return (
      <div key={inde}>
       <div className="row align-items-center">
              <div className="col-2">
                <div className="px-2">
                  <div className="text-center">
                    <img
                      src={`/NEW_LOGO/${item?.flightNumber.slice(0,2)}.png`}
                      alt="9P"
                      style={{
                        width: "90px",
                        height: "90px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <p className="text-center">
                    
                  <h6 className="mt-3 ms-2">
                  {`${item?.flightNumber}`}
                </h6>
              <div className="mt-2">Operated by : {item?.flightNumber.slice(0,2)}</div>
                  </p>
                </div>
              </div>
  
              <div className="col-7">
                <div className="d-flex justify-content-evenly align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <MdOutlineFlightTakeoff
                        size={30}
                        className="color_primary"
                      />
                    </div>
                    <div>
                      <h6>
                        {moment(item?.departureDateTimeLocal?.split("T")?.at(0)).format(
                          "ddd DD-MMM-YY"
                        )}
                      </h6>
                      <h6 className="my-3">
                        {" "}
                        {moment(
                          item?.departureDateTimeLocal
                        ).format("HH:MM")}
                      </h6>
                      <h6>
                      {item?.origin?.airportCode} - {airport_name}
                      </h6>
                      <h6>
                      {item?.origin?.terminal && (
                      <h6 className="color_primary">
                        (Terminal-{item?.origin?.terminal})
                      </h6>
                    )}
                      </h6>
                    </div>
                  </div>
                  <div>
                    
                  <div className="d-flex justify-content-center">
                <MdFlight className="color_primary fs-5 rotate-right" />
                <h6 className="text-center">Trip Time</h6>
              </div>
              <div className="border_rounded mb-2" />
              <h6 className="text-center ms-4">
                {timeDuration(
                  item?.departureDateTimeLocal,
                  item?.arrivalDateTimeLocal
                )}
              </h6>
             </div>
                  
                    <div className="col-5 d-flex align-items-center ps-4">
                    <div className="me-3">
                      <MdOutlineFlightLand size={30} className="color_primary" />
                    </div>
                    <div>
                      <h6>
                        {moment(item?.arrivalDateTimeLocal?.split("T")?.at(0)).format(
                          "ddd DD-MMM-YY"
                        )}
                      </h6>
                      <h6 className="my-3">
                      {moment(
                          item?.arrivalDateTimeLocal
                        ).format("HH:MM")}
                      </h6>
                      <h6>
                        {item?.destination?.airportCode} - {arr_airport_name}
                      </h6>
                      {item?.destination?.terminal && (
                      <h6 className="color_primary">
                        (Terminal-{item?.destination?.terminal})
                      </h6>
                    )}
                 </div>
                   </div>
                 
                </div>{" "}
              </div>
              {inde < data?.flightSegments?.length - 1 && (
          <div className="col-12 d-flex justify-content-center align-items-center my-4">
            <div className="border_rounded w-25" />
            <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
              <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
              <h6 className="pt-2">
                Layover time:{" "}
                <span className="fs-5">
                  {" "}
                  
                  {timeDuration(
                  item?.arrivalDateTimeLocal,
                  data?.flightSegments[data?.flightSegments?.length - 1]?.departureDateTimeLocal,
                )}
                </span>
              </h6>
            </div>
            <div className="border_rounded w-25" />
          </div>
        )}
            </div>
      </div>
    );
  };