import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const user =
    localStorage.getItem("loginUser") &&
    JSON.parse(localStorage.getItem("loginUser"));

  return (
    <>
      {!user && location.pathname !== "/" ? (
        <Navigate to="/" replace />
      ) : user && location.pathname === "/" ? (
        <Navigate to="/home" replace />
      ) : null}
      {/* {user && location.pathname !== "/" ? null : <Navigate to="/" replace />} */}
      {children}
    </>
  );
};

export default ProtectedRoute;
