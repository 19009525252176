import React, { useEffect, useState } from "react";
import ManualInvoic from "../components/Finance/Vouchers/ManualInvoice";
import { asyncGetNewAgencyReg } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetAgentGroup } from "../redux/features/setupRestPages/agentsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getAsyncBookingDetailsForVocherDetails, getSingleVoucherLog } from "../redux/features/booking/bookingDetailsSlice";
import VoucherCreated from "../components/Finance/Vouchers/VoucherEntry/VoucherCreated";
import ManualVoucherLogsCreate from "../components/Finance/Vouchers/VoucherEntry/ManualVoucherLogsCreate";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";

function ManualInvoice() {
  const { VoucherBooking, singleVoucherLog } = useSelector(
    (state) => state?.booking
  );
  const { searchAgency, newAgencyRegData } = useSelector(
    (state) => state.newAgency
  );
  const [ManualInvoice, setManualInvoice] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams()
  const tkt_voucher_no = location?.state?.tkt_voucher_no ||params?.tkt;
  const voucher_id = location?.state?.voucher_id || params?.vid;
 
  useEffect(() => {
    let id = Number(location?.state?.voucherId) || Number(params?.id);
    // if (id) {
    dispatch(
      getAsyncBookingDetailsForVocherDetails({
        id,
        tkt_voucher_no,
        voucher_id,
      })
    );
    dispatch(
      getSingleVoucherLog({
        voucher_id,
      })
    );
    dispatch(asyncGetNewAgencyReg());
    // }
    setTimeout(() => {
      setManualInvoice(true);
    }, 2000);
  }, [location?.state?.voucherId,params?.id]);

  return (
    <div className="Full_height">
      {/* VoucherBooking?.id && newAgencyRegData?.length && */}
      {ManualInvoice && <ManualInvoic VoucherBooking={VoucherBooking} />}

      {voucher_id && VoucherBooking?.id && ManualInvoice ? (
        <div className="col-11 mx-auto bg-white  shadow-sm rounded-2">
          <ManualVoucherLogsCreate
            voucher={[VoucherBooking?.voucherData, singleVoucherLog]}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ManualInvoice;
