import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import { Check, Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncRegNewAgency,
  messageNull,
} from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import BtnLoader from "../AppForm/BtnLoader";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { asyncGetAllUserData } from "../../redux/features/agency/usersSlice";

function RegisterForm({ setShowReisterModal }) {
  const { loading, registerationSuccess } = useSelector(
    (state) => state?.newAgency
  );

  const { allUsersData } = useSelector((state) => state.usersSlice);

  /*****************use state *************** */
  const [InputGroup, setInputGroup] = useState("office");
  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setError] = useState(false);
  const [emailError, setEmailError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetAllUserData());
  }, []);

  const [requirements, setRequirements] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    minLength: false,
    specialChar: false,
  });

  /****************************** Initial Values  */
  const initialValues = {
    agent_name: "",
    reg_number: "",
    tax_number: "",
    country: "Pakistan",
    city: "",
    address: "",
    url: "",
    agent_email: "",
    phone: "",
    mobile_no: "",
    f_name: "",
    l_name: "",
    user_email: "",
    password: "",
    repeat_password: "",
  };

  const validatePassword = (values) => {
    const { password } = values;
    setRequirements({
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      minLength: password.length >= 8,
    });
  };

  /************** VALIDATION ************* */
  const validationSchema = yup.object().shape({
    agent_name: yup
      .string()
      .required("Agent Name Required")
      .min(2, "Minimum 2 characters"),

    city: yup.string().required("City Required"),
    address: yup.string().required("Address Required"),
    agent_email: yup
      .string()
      .required("Agent Email Required")
      .email("Enter valid email e.g USER12@GMAIL.COM"),
    phone: yup.string().required("Phone No Required"),
    mobile_no: yup.string().required("Mobile Number Required"),
    f_name: yup.string().required("First Name Required"),
    l_name: yup.string().required("Last Name Required"),
    url: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url! e.g. https://www.google.com"
      ),

    user_email: yup
      .string()
      .required("User Email Required")
      .email("Enter valid email e.g USER12@GMAIL.COM"),
    password: yup
      .string()
      .min(8, "Password must be atleast 8 characters long")
      .required("Password Required"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
    //   "Must Contain One Uppercase, One Lowercase, One Number and One Special Case Character"
    // ),
    repeat_password: yup
      .string()
      .required("Repeat Password Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <div className="back_drop_shadow_container">
      <Modal show={true} size="lg" onHide={(e) => setShowReisterModal(false)}>
        <div className="py-2 pt-0 pb-5" style={{ minHeight: "85vh" }}>
          {/**   heading */}
          <div className="otawix-heading text-center">
            <h4 className="box_title">New Agent Registration</h4>
          </div>

          <Formik
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validate={validatePassword}
            onSubmit={(values, { resetForm }) => {
              let url = values.url;
              if (url && !url.startsWith("https://")) {
                url = "https://" + url;
              }

              if (
                requirements.lowercase &&
                requirements.uppercase &&
                requirements.number &&
                requirements.minLength &&
                requirements.specialChar
              ) {
                let data = {
                  acc_id: 6,
                  agent_name: values.agent_name,
                  reg_no: values.reg_number,
                  tax_no: values.tax_number,
                  address: `${values.address} ] ${values.city} ] ${values.country}`,
                  phone: values.phone,
                  mobile_no: values.mobile_no,
                  user_email: values.user_email,
                  email: values.agent_email,
                  url: url,
                  f_name: values.f_name,
                  l_name: values.l_name,
                  password: values.password,
                };
                dispatch(asyncRegNewAgency(data));
                setInputGroup("office");
                resetForm("");

                setTimeout(() => {
                  setShowReisterModal(false);
                  dispatch(messageNull());
                }, 3000);
              }
            }}
          >
            {({ values, handleChange, errors, setFieldValue }) => {
              return (
                <Form>
                  {/********************** tabs  **************** */}
                  <div className="d-none d-md-flex justify-content-center py-3 pt-5 align-items-center progress_bar-register">
                    <div className="d-flex justify-content-between align-items-center w-50 progress_bar-register-cont">
                      <div
                        className="d-flex flex-column align-items-center pb-3"
                        style={{ height: "8rem" }}
                      >
                        <div
                          className={`progress_bar-register rounded-circle ${InputGroup === "office"
                              ? "bg-primary"
                              : "bg-success"
                            } text-white px-3 py-2 h4`}
                        >
                          1
                        </div>

                        <h4
                          className={` ${InputGroup === "office"
                              ? "text-primary"
                              : "text-success"
                            } h4`}
                        >
                          Company Info
                        </h4>
                      </div>
                      <div
                        className="d-flex flex-column align-items-center pb-3"
                        style={{ height: "8rem" }}
                      >
                        <div
                          className={`progress_bar-register rounded-circle ${InputGroup === "person"
                              ? "bg-primary"
                              : "bg-secondary"
                            } text-white px-3 py-2 h4`}
                        >
                          2
                        </div>

                        <h4
                          className={` ${InputGroup === "person"
                              ? "text-primary"
                              : "text-secondary"
                            } h4`}
                        >
                          Personal Info
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="inputs_group">
                    {InputGroup === "office" ? (
                      <div className="row px-4">
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Travel Agency Name"
                            variant="standard"
                            className="w-100 red-asterisk"
                            required
                            name="agent_name"
                            autoComplete="off"
                            value={values.agent_name}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("agent_name", value.toUpperCase());
                            }}
                          />
                          {formErrors && errors?.agent_name ? (
                            <p className="text-danger mt-1">
                              {errors?.agent_name}
                            </p>
                          ) : null}
                        </div>

                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Registration Number"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            name="reg_number"
                            value={values.reg_number}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("reg_number", value.toUpperCase());
                            }}
                          />
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Tax Number"
                            variant="standard"
                            className="w-100"
                            name="tax_number"
                            autoComplete="off"
                            value={values.tax_number}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("tax_number", value.toUpperCase());
                            }}
                          />
                        </div>
                        <div
                          className="col-6 py-2 py-md-3 px-4 h-5rem"
                          key={"UNIQUE_ID_country"}
                        >
                          <CountryDropdown
                            id="UNIQUE_ID_country"
                            className="country_dropdown absolute w-100"
                            // preferredCountries={["pk"]}
                            value={values?.country}
                            handleChange={handleChange}
                          />
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="City"
                            variant="standard"
                            className="w-100 red-asterisk"
                            required
                            autoComplete="off"
                            name="city"
                            value={values.city}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("city", value.toUpperCase());
                            }}
                          />
                          {formErrors && errors?.city && (
                            <p className="text-danger mt-1">{errors?.city}</p>
                          )}
                        </div>

                        <div className="col-6 py-2 py-md-3 px-4 h-5rem ">
                          <TextField
                            label="Address"
                            variant="standard"
                            className="w-100 red-asterisk"
                            required
                            autoComplete="off"
                            name="address"
                            value={values.address}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("address", value.toUpperCase());
                            }}
                          />
                          {formErrors && errors?.address && (
                            <p className="text-danger mt-1">
                              {errors?.address}
                            </p>
                          )}
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Company url"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            name="url"
                            value={values.url}
                            onChange={handleChange}
                          />
                          {formErrors && errors?.url && (
                            <p className="text-danger mt-1">{errors?.url}</p>
                          )}
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Agent Email (Company)"
                            variant="standard"
                            className="w-100 red-asterisk"
                            required
                            autoComplete="off"
                            name="agent_email"
                            value={values.agent_email}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("agent_email", value.toUpperCase());
                            }}
                          />
                          {formErrors && errors?.agent_email && (
                            <p className="text-danger mt-1">
                              {errors?.agent_email}
                            </p>
                          )}
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Phone Number "
                            variant="standard"
                            className="w-100 red-asterisk"
                            required
                            autoComplete="off"
                            type="number"
                            name="phone"
                            value={values.phone}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("phone", value.toUpperCase());
                            }}
                          />
                          {formErrors && errors?.phone && (
                            <p className="text-danger mt-1">{errors?.phone}</p>
                          )}
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Mobile Number"
                            variant="standard"
                            className="w-100 red-asterisk"
                            required
                            autoComplete="off"
                            type="number"
                            name="mobile_no"
                            value={values.mobile_no}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("mobile_no", value.toUpperCase());
                            }}
                          />
                          {formErrors && errors?.mobile_no && (
                            <p className="text-danger mt-1">
                              {errors?.mobile_no}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="row px-4">
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="First Name"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            name="f_name"
                            value={values.f_name}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("f_name", value.toUpperCase());
                            }}
                          />
                          <ErrorMessage
                            component={"div"}
                            className="text-danger mt-1"
                            name="f_name"
                          />
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Last Name "
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            name="l_name"
                            value={values.l_name}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("l_name", value.toUpperCase());
                            }}
                          />
                          <ErrorMessage
                            component={"div"}
                            className="text-danger mt-1"
                            name="l_name"
                          />
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Email (Your Login User ID)"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            name="user_email"
                            value={values.user_email}
                            onChange={(e) => {
                              let value = e.target.value;
                              const findEmail = allUsersData?.find(
                                (user) => user?.email === value.toUpperCase()
                              );
                              if (findEmail) {
                                setEmailError("This email already exist");
                              } else {
                                setEmailError("");
                              }
                              setFieldValue("user_email", value.toUpperCase());
                            }}
                          />
                          <ErrorMessage
                            component={"div"}
                            className="text-danger mt-1"
                            name="user_email"
                          />

                          {emailError && (
                            <div className="text-danger mt-2">{emailError}</div>
                          )}
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem"></div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <FormControl
                            sx={{ width: "100%" }}
                            variant="standard"
                          >
                            <InputLabel htmlFor="standard-adornment-password">
                              Password
                            </InputLabel>
                            <Input
                              id="standard-adornment-password"
                              autoComplete="off"
                              type={showPassword ? "text" : "password"}
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              inputProps={{
                                readOnly: values.agent_type === "Corporate",
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                            <Typography
                              variant="body2"
                              sx={{ mt: 1, fontWeight: 500 }}
                            >
                              PASSWORD MUST CONTAIN:
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: requirements.lowercase ? "green" : "red",
                              }}
                            >
                              {requirements.lowercase ? <Check /> : <Close />}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                At least one lowercase letter
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: requirements.uppercase ? "green" : "red",
                              }}
                            >
                              {requirements.uppercase ? <Check /> : <Close />}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                At least one uppercase letter
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: requirements.specialChar
                                  ? "green"
                                  : "red",
                              }}
                            >
                              {requirements.specialChar ? <Check /> : <Close />}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                At least one special character
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: requirements.number ? "green" : "red",
                              }}
                            >
                              {requirements.number ? <Check /> : <Close />}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                At least one number
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: requirements.minLength ? "green" : "red",
                              }}
                            >
                              {requirements.minLength ? <Check /> : <Close />}
                              <Typography variant="body2" sx={{ ml: 1 }}>
                                minimum 8 characters
                              </Typography>
                            </Box>
                          </FormControl>

                          <ErrorMessage
                            component={"p"}
                            className="text-danger mt-1"
                            name="password"
                          />
                        </div>
                        <div className="col-6 py-2 py-md-3 px-4 h-5rem">
                          <TextField
                            label="Confirm Password"
                            variant="standard"
                            className="w-100"
                            autoComplete="off"
                            type="password"
                            name="repeat_password"
                            value={values.repeat_password}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="repeat_password"
                            component="p"
                            className="mt-1 text-danger"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {/** Buttons group*/}

                  <div className="text-center">
                    {registerationSuccess && (
                      <h6 className="mt-2 confirm_msg text-success">
                        You will get a confirmation of your account activation
                        as soon.
                      </h6>
                    )}
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      {InputGroup === "office" ? (
                        <button
                          className="button_style px-5 mt-2"
                          onClick={() => {
                            if (
                              errors?.agent_name ||
                              errors.address ||
                              errors.phone ||
                              errors.city ||
                              errors.agent_email ||
                              errors.mobile_no ||
                              errors.url
                            ) {
                              setError(true);
                              setInputGroup("office");
                            } else {
                              setInputGroup("person");
                            }
                          }}
                        >
                          Personal Info
                        </button>
                      ) : (
                        <>
                          <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
                            <div className="mt-5">
                              <button
                                className="button_style px-4 me-2 me-md-4"
                                onClick={() => setInputGroup("office")}
                              >
                                Company Info
                              </button>
                            </div>
                            <div className="mt-5">
                              <button
                                className="button_style px-4"
                                type="submit"
                              >
                                {loading ? <BtnLoader /> : "Create Account"}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}

export default RegisterForm;
