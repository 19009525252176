import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAddFlshNewsData } from "../../redux/features/promotion/AddFlashNewsSlice";

function TextSlider() {
  const dispatch = useDispatch();
  const { flashData } = useSelector((state) => state.addFlashNews);
  useEffect(() => {
    dispatch(asyncGetAddFlshNewsData());
  }, [dispatch]);
  return (
    <Carousel
      interval={2000}
      indicators={false}
      nextIcon={false}
      prevIcon={false}
      className="custom--class-text-slider  px-auto"
    >
      {flashData?.map((d, id) => (
        <Carousel.Item className="pt-2" key={id}>
          <p className="text-justify">{d?.news}</p>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
export default TextSlider;
