import React from 'react'
import Header from '../components/Common/Header'
import CollectionComp from "../components/Collection/index"
import Footer from '../components/Footer'

const Collection = () => {
  return (
    <div className='Full_height'>
      <CollectionComp />
    </div>
  )
}

export default Collection