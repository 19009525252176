import React from "react";
import Checkoutpage from "../components/Reservation/checkout/Checkout";

const Checkout = () => {
  return (
    <div>
      <Checkoutpage />
    </div>
  );
};

export default Checkout;
