import { debounce } from "lodash";

// Define a function that wraps debounce and returns a Promise
function debouncedWithPromise(func, wait) {
  return (...args) => {
    return new Promise((resolve) => {
      const debouncedFunc = debounce((...innerArgs) => {
        resolve(func(...innerArgs));

      }, wait);
      debouncedFunc(...args);
    });
  };
}

// Wrapped function that performs uniqueness check and returns the result
const checkUniqueness = (data, index, e) => {
  return new Promise((resolve) => {
    let ticketNumber = e.target.value;

    const filteredTicketNumbers = data
      .filter((pax, i) => i !== index)
      .map((pa) => pa.tkt_no);
    const unique = filteredTicketNumbers.includes(ticketNumber);
    // Resolve the Promise with the uniqueness result
    resolve(unique);
  });
};

// Create a debounced function with Promise
const debouncedHandleChanges = debouncedWithPromise(checkUniqueness, 3000);

// Export the debounced function
export function UniqueInputValues(data, index, e) {
  // Call the debounced function and handle the result
  
 return debouncedHandleChanges(data, index, e)
}
