import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  AmadeusCredentialsData: [],
  editAmadeusCredentials: null,
  loading: false,
  error: null,
};

// post ER API CREDENTIAL
export const asyncPostAmadeusApiCredentials = createAsyncThunk(
  "asyncPostAmadeusApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/amadeus-credential`, values);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get ER API CREDENTIAL
export const asyncGetAmadeusApiCredentials = createAsyncThunk(
  "asyncGetAmadeusApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/amadeus-credential`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get single ER API CREDENTIAL
export const asyncGetSingleAmadeusApiCred = createAsyncThunk(
  "asyncGetSingleAmadeusApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/amadeus-credential/${id}`);

      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// Update ER API CREDENTIAL
export const asyncUpdateAmadeusCredential = createAsyncThunk(
  "asyncUpdateAmadeusCredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/Amadeus-credential/${id}`,
        values
      );
      dispatch(asyncGetAmadeusApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Update ER API CREDENTIAL
export const asyncDeleteAmadeusCredential = createAsyncThunk(
  "asyncDeleteAmadeusCredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/amadeus-credential/${id}`);
      dispatch(asyncGetAmadeusApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const AmdeusApiCredentialSlice = createSlice({
  name: "AmadeusApiCredentialSlice",
  initialState,
  reducers: {
    editAmadeusCredentialNull: (state) => {
      state.editAmadeusCredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post ER Api Credentials
    builder.addCase(asyncPostAmadeusApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostAmadeusApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.AmadeusCredentialsData = [...state.AmadeusCredentialsData, payload];
      }
    );
    builder.addCase(
      asyncPostAmadeusApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get ER Api Credentials
    builder.addCase(asyncGetAmadeusApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetAmadeusApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.AmadeusCredentialsData = payload;
      }
    );
    builder.addCase(asyncGetAmadeusApiCredentials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Get single ER Api Credentials
    builder.addCase(asyncGetSingleAmadeusApiCred.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSingleAmadeusApiCred.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editAmadeusCredentials = payload;
    });
    builder.addCase(asyncGetSingleAmadeusApiCred.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update ER Api Credentials
    builder.addCase(asyncUpdateAmadeusCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateAmadeusCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateAmadeusCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // DElete ER Api Credentials
    builder.addCase(asyncDeleteAmadeusCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteAmadeusCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteAmadeusCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { editAmadeusCredentialNull } = AmdeusApiCredentialSlice.actions;
export default AmdeusApiCredentialSlice.reducer;
