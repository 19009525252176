import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect } from "react";
import { options } from "../../../Utils/ColumnOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteBannerData,
  asyncEditBannerData,
  asyncGetBannerData,
  asyncPostBannerData,
  asyncUpdateBannerData,
  editbanner_null,
} from "../../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";
import { apiUrl } from "../../../Utils/configUrl";

const Banner = () => {
  const dispatch = useDispatch();

  const { bannerStateData, editbanner, loading } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // if the login user has permissions to add banner
  const postPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/post-banner"
  );
  // if the user has permission to edit banner
  const editPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/update-banner"
  );
  // if the user has permission to edit banner
  const deletePermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/delete-banner"
  );

  /************useEffect ************* */
  useEffect(() => {
    dispatch(asyncGetBannerData());
  }, [dispatch]);

  /*********table column *********/
  const column = [
    {
      label: "Id",
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <img
                style={{ height: 80, width: 80 }}
                src={`${apiUrl}/${value}`}
                alt={value}
              />
            </div>
          );
        },
      },
    },
    {
      label: "Heading",
      name: "heading",
    },
    {
      label: "Description",
      name: "description",
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {editPermission !== undefined || user?.user_id ? (
                <button
                  className="edit-delete-icon-button btn-success "
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                    dispatch(asyncEditBannerData(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-thin fa-pencil" aria-hidden="true"></i>
                </button>
              ) : null}
              {deletePermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-danger ms-3"
                  onClick={() => {
                    dispatch(asyncDeleteBannerData(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  const SUPPORTED_FORMATS = ["image/png"];

  /******************Initials values ********** */
  const initialValues = {
    bannerValue: [
      {
        image: "",
        heading: editbanner?.heading || "",
        description: editbanner?.description || "",
      },
    ],
  };
  /**********Validation schema ************ */
  const validationSchema = yup.object().shape({
    bannerValue: yup.array().of(
      yup.object().shape({
        image: editbanner
          ? yup
              .mixed()
              .nullable()
              .test(
                "format",
                "Only png logo are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              )
          : yup
              .mixed()
              .nullable()
              .required("Image is required")
              .test(
                "format",
                "Only png logo are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              ),
        heading: yup.string().required("Heading is Required"),
        description: yup.string().required("Description is Required"),
      })
    ),
  });
  // {/***************Add edit banner ************* */}
  return (
    <div className="pb-5">
      {/***************Add edit banner ************* */}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          values?.bannerValue.map((it) => formData.append("image", it.image));
          values?.bannerValue.map((it) =>
            formData.append("heading", it.heading)
          );
          values?.bannerValue.map((it) =>
            formData.append("description", it.description)
          );

          if (!editbanner) {
            for (const [key, value] of Object.entries(values)) {
              value.map((it) => dispatch(asyncPostBannerData(it)));
            }
            resetForm();
          } else {
            dispatch(asyncUpdateBannerData({ formData, id: editbanner?.id }));
            dispatch(editbanner_null());
          }
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="col-11 mx-auto rounded-2 mt-5 bg-white">
                <p className="h4 otawix-heading box_title">Add / Edit Banner</p>
                <div className="p-5">
                  <FieldArray
                    name={`bannerValue`}
                    render={(arrayHelpers) => (
                      <div className="w-100 ">
                        {values.bannerValue.map((val, index) => (
                          <div key={index}>
                            <div className="row align-items-start mt-4">
                              <div className="col-3">
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  label="Image"
                                  type="file"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `bannerValue.${index}.image`,
                                      e.currentTarget.files[0]
                                    )
                                  }
                                />

                                <p style={{ color: "red" }}>
                                  {errors?.bannerValue &&
                                    errors?.bannerValue[index]?.image}
                                </p>
                              </div>
                              <div className="col-3">
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  label="Heading"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `bannerValue.${index}.heading`,
                                      e.target.value.toUpperCase()
                                    )
                                  }
                                  value={val.heading}
                                />

                                <p style={{ color: "red" }}>
                                  {errors?.bannerValue &&
                                    errors?.bannerValue[index]?.heading}
                                </p>
                              </div>
                              <div className="col-3">
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  label="Description"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `bannerValue.${index}.description`,
                                      e.target.value.toUpperCase()
                                    )
                                  }
                                  value={val.description}
                                />

                                <p style={{ color: "red" }}>
                                  {errors?.bannerValue &&
                                    errors?.bannerValue[index]?.description}
                                </p>
                              </div>

                              <div className="col-3">
                                {index >= 1 && (
                                  <button
                                    className="button_style bg-danger"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    - Banner
                                  </button>
                                )}
                                {index === 0 && (
                                  <button
                                    className="button_style"
                                    type="button"
                                    onClick={() => arrayHelpers.push("")}
                                  >
                                    + Banner
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />

                  <div className="text-center mt-5">
                    {editbanner ? (
                      <>
                        {" "}
                        <button
                          className="button_style px-5 bg-danger me-2"
                          onClick={(e) => {
                            dispatch(editbanner_null());
                          }}
                        >
                          Cancel
                        </button>
                        <button className="button_style px-5" type="submit">
                          {loading ? <BtnLoader /> : "Update"}
                        </button>
                      </>
                    ) : null}

                    {(postPermission !== undefined || user?.user_id === 1) &&
                    !editbanner ? (
                      <button className="button_style px-5" type="submit">
                        {loading ? <BtnLoader /> : "Save"}
                      </button>
                    ) : null}
                  </div>

                  <div />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {/***************Banner LIst */}
      <div className="shadow-sm rounded-2 col-11 mx-auto mt-5 bg-white">
        <p className="h4 otawix-heading box_title">Banner List</p>
        <div className="p-5">
          <MUIDataTable
            title={""}
            columns={column}
            data={bannerStateData}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
