import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncEditRoute,
  asyncEditVehicle,
  asyncGetUmrahTransfer,
  asyncPostUmrahRoutes,
  asyncPostVehicle,
  asyncUpdateRoute,
  asynUpdateVehicle,
  editRouteDataNull,
  editVehicleDataNull,
} from "../../../redux/features/umrah/TransferSlice";
import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";
import { Form, Formik } from "formik";
import * as yup from "yup";

const Transfer = () => {
  const dispatch = useDispatch();
  const {
    carTypes,
    umrahRoutes,
    vehicleLoader,
    routeLoader,
    editVehicleData,
    editRouteData,
  } = useSelector((state) => state.transfer);

  useEffect(() => {
    dispatch(asyncGetUmrahTransfer());
  }, [editRouteData]);

  const transferColumns = [
    {
      name: "r_id",
      label: "ID",
    },
    {
      name: "route",
      label: "Routes",
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log(tableMeta.rowData[0]);
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => {
                dispatch(asyncEditRoute(tableMeta.rowData[0]));
                window.scroll({ top: 0 });
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const vehicleColumns = [
    {
      name: "ct_id",
      label: "ID",
    },
    {
      name: "name",
      label: "Vehicle",
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => {
                dispatch(asyncEditVehicle(tableMeta.rowData[0]));
                window.scroll({ top: 0 });
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  return (
    <div className="col-12 col-sm-9 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Add Umrah Transfer</p>
      <div className="col-12 px-4">
        <div className="col-12 mt-4 py-3 d-flex">
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object().shape({
              route: yup.string().required("route required"),
            })}
            enableReinitialize={true}
            initialValues={{ route: editRouteData?.route || "" }}
            onSubmit={(values, action) => {
              // console.log(values);
              let val = {
                id: editRouteData?.r_id,
                route: values.route,
              };
              if (editRouteData) {
                dispatch(asyncUpdateRoute(val));
                dispatch(editRouteDataNull());
              } else {
                dispatch(asyncPostUmrahRoutes(values.route));
                action.resetForm({ values: "" });
              }
            }}
          >
            {({ values, handleChange, errors }) => {
              // console.log(errors);
              return (
                <Form className="col-sm-6 d-flex flex-column px-5">
                  <TextField
                    id="standard-basic"
                    label="Route"
                    variant="standard"
                    autoComplete="off"
                    name="route"
                    onChange={handleChange}
                    value={values.route}
                  />
                  <p style={{ color: "red" }}>{errors && errors?.route}</p>
                  <div className="d-flex align-self-center gap-3">
                    <button className="btn setup-btn mt-3" type="submit">
                      {routeLoader ? (
                        <BtnLoader />
                      ) : editRouteData ? (
                        "update"
                      ) : (
                        "save"
                      )}
                    </button>
                    {editRouteData && (
                      <button
                        className="btn setup-btn bg-danger mt-3"
                        type="submit"
                        onClick={() => dispatch(editRouteData())}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>

          {/* for vehicle section  */}
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={yup.object().shape({
              vehicle: yup.string().required("vehicle required"),
            })}
            enableReinitialize={true}
            initialValues={{ vehicle: editVehicleData?.name || "" }}
            onSubmit={(values, action) => {
              let val = {
                id: editVehicleData?.ct_id,
                vehicle: values.vehicle,
              };

              if (editVehicleData) {
                dispatch(asynUpdateVehicle(val));
                dispatch(editVehicleDataNull());
              } else {
                dispatch(asyncPostVehicle(values.vehicle));
                action.resetForm({ values: "" });
              }
            }}
          >
            {({ values, handleChange, errors }) => {
              return (
                <Form className="col-sm-6 d-flex flex-column px-5">
                  <TextField
                    id="standard-basic"
                    label="Vehicle Type"
                    variant="standard"
                    autoComplete="off"
                    name="vehicle"
                    onChange={handleChange}
                    value={values.vehicle}
                  />
                  <p style={{ color: "red" }}>{errors && errors?.vehicle}</p>
                  <div className="d-flex align-self-center gap-3">
                    <button className="btn setup-btn mt-3" type="submit">
                      {vehicleLoader ? (
                        <BtnLoader />
                      ) : editVehicleData ? (
                        "update"
                      ) : (
                        "save"
                      )}
                    </button>
                    {editVehicleData && (
                      <button
                        className="btn setup-btn bg-danger mt-3"
                        type="submit"
                        onClick={() => editVehicleDataNull()}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="col-12 mt-4 px-4 d-flex justify-content-between">
        <div className="col-sm-6 px-2">
          <MUIDataTable
            className="muidatatable"
            title={"Umrah Route"}
            data={umrahRoutes}
            columns={transferColumns}
            options={options}
          />
        </div>
        <div className="col-sm-6 px-2">
          <MUIDataTable
            className="muidatatable"
            title={"Vehicle Type"}
            data={carTypes}
            columns={vehicleColumns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default Transfer;
