import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTPForm, NODEURL } from "../../ApiConfig";

const initialState = {
  PACredentialsData: [],
  editPACredentials: null,
  loading: false,
  error: null,
};

// post ER API CREDENTIAL
export const asyncPostPAApiCredentials = createAsyncThunk(
  "asyncPostPAApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await HTTPForm.post(`/api/v1/b2b/pa-credential`, values);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get ER API CREDENTIAL
export const asyncGetPAApiCredentials = createAsyncThunk(
  "asyncGetPAApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/pa-credential`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get single ER API CREDENTIAL
export const asyncGetSinglePAApiCred = createAsyncThunk(
  "asyncGetSinglePAApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/pa-credential/${id}`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// Update ER API CREDENTIAL
export const asyncUpdatePACredential = createAsyncThunk(
  "asyncUpdatePACredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.patch(
        `/api/v1/b2b/pa-credential/${id}`,
        values
      );
      dispatch(asyncGetPAApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Update ER API CREDENTIAL
export const asyncDeletePACredential = createAsyncThunk(
  "asyncDeletePACredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/pa-credential/${id}`);
      dispatch(asyncGetPAApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const PaApiCredentialSlice = createSlice({
  name: "PaApiCredentialSlice",
  initialState,
  reducers: {
    editPACredentialNull: (state) => {
      state.editPACredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post ER Api Credentials
    builder.addCase(asyncPostPAApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostPAApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.PACredentialsData = [...state.PACredentialsData, payload];
      }
    );
    builder.addCase(
      asyncPostPAApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get ER Api Credentials
    builder.addCase(asyncGetPAApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetPAApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.PACredentialsData = payload;
      }
    );
    builder.addCase(asyncGetPAApiCredentials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Get single ER Api Credentials
    builder.addCase(asyncGetSinglePAApiCred.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSinglePAApiCred.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editPACredentials = payload;
    });
    builder.addCase(asyncGetSinglePAApiCred.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update ER Api Credentials
    builder.addCase(asyncUpdatePACredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdatePACredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdatePACredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // DElete ER Api Credentials
    builder.addCase(asyncDeletePACredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeletePACredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeletePACredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { editPACredentialNull } = PaApiCredentialSlice.actions;
export default PaApiCredentialSlice.reducer;
