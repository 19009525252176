import React from "react";

import DiscreteSlider from "./slider";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dropMenuProp } from "../../../../Utils/MenuProps";
import {
  setStops_Filters,
  set_Airline_selected_filter,
  set_GDS_selected_filter,
  set_Refundable_filter,
  set_sort_by_filter,
} from "../../../../redux/features/home/FlightSearchSlice";

function FIlter() {
  const {
    stop_filters,
    gds_filter_drp,
    gds_filter,
    airline_Filter_drp,
    airline_filter,
    sort_by_filter,
    refundable_filter,
  } = useSelector((state) => state.FlightSearch);
  const dispatch = useDispatch();
  // stops drp array
  const stopsArray = ["ALL", "NON STOP", "ONE STOP", "TWO STOP"];
  // sort by
  const sortBy = [
    { id: 1, value: "Price - Low to High" },
    { id: 2, value: "Price - High to Low" },
  ];

  const refundable = ["ALL", "NON-REFUNDABLE", "REFUNDABLE"];

  return (
    <div className="bg-white shadow-sm rounded-2 p-3 py-4">
      <div className="d-flex flex-wrap justify-content-between">
        {/* price accordian */}
        {/* <div className="col-1">
          <h6>Price</h6>
          <DiscreteSlider />
        </div> */}

        {/* STOPS accordian */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="Stops">STOPS</InputLabel>
            <Select
              id="stops"
              value={stop_filters}
              label="STOPS"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(setStops_Filters(value));
              }}
              MenuProps={dropMenuProp}
            >
              {stopsArray.map((it) => (
                <MenuItem value={it} key={it}>
                  {it}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* GDS accordian */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="GDS">GDS</InputLabel>
            <Select
              id="GDS"
              value={gds_filter}
              label="GDS"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_GDS_selected_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {gds_filter_drp?.map((it) => (
                <MenuItem value={it} key={it}>
                  {it}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* airline accordian */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="Airline">AIRLINE</InputLabel>
            <Select
              id="Airline"
              value={airline_filter}
              label="AIRLINE"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_Airline_selected_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {airline_Filter_drp?.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Refundable non-Refundable */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="refundable_filter">REFUNDABLE</InputLabel>
            <Select
              id="refundable_filter"
              value={refundable_filter}
              label="REFUNDABLE"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_Refundable_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {refundable?.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/*  SORT BY  */}
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel id="Sortby">SORT BY</InputLabel>
            <Select
              id="Sortby"
              value={sort_by_filter}
              label="SORT BY"
              onChange={(e) => {
                let value = e.target.value;
                dispatch(set_sort_by_filter(value));
              }}
              MenuProps={dropMenuProp}
            >
              {sortBy.map((it) => (
                <MenuItem value={it.id} key={it.id}>
                  {it.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default FIlter;
