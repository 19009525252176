import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { options } from "../../../Utils/ColumnOptions";
import { ChangeStatus } from "../../../Utils/ChangeStatus";
import {
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";
import { asyncUpdateUserData } from "../../../redux/features/agency/usersSlice";
import { Check, Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { asyncGetRoleData } from "../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { useParams } from "react-router-dom";

const AgentUser = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [editUserData, seteditUserData] = useState(null);

  const { editAgentsData } = useSelector((state) => state.agentsCategories);
  const { roleData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { loading } = useSelector((state) => state?.usersSlice);

  const [requirements, setRequirements] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    minLength: false,
    specialChar: false,
  });

  /*********user table data  */
  const userData = editAgentsData?.agents_users;
  const { id: agentId } = useParams();
  const accessLevel = roleData?.find(
    (it) => editUserData && it.role_id === editUserData[5]
  );
  useEffect(() => {
    dispatch(asyncGetRoleData());
  }, [dispatch]);

  /***************table columns */
  const columns = [
    {
      name: "user_id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div class="checkbox-rect">
              <input
                value={value}
                checked={value}
                type="checkbox"
                id={"with_sar_" + id}
                onChange={(event) => {
                  updateValue(!value);
                  ChangeStatus(
                    "/api/v1/b2b/agent-user-status",
                    !value,
                    id,
                    "status"
                  );
                }}
              />
              <label for={"with_sar_" + id} className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "f_name",
      options: {
        display: false,
      },
    },
    {
      name: "l_name",
      options: {
        display: false,
      },
    },
    {
      name: "agent_name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          let f_name = tableMeta.rowData[2];
          let l_name = tableMeta.rowData[3];
          return (
            <div>
              {f_name} {l_name}
            </div>
          );
        },
      },
    },
    {
      name: "role_id",
      label: "Access level",
      options: {
        customBodyRender: (value, tableMeta) => {
          let level = roleData?.find((it) => it.role_id === value);
          return <div>{level?.role_name}</div>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
    },
    // {
    //   name: "last_signin",
    //   label: "Last Sign In",
    // },
    // {
    //   name: "sign-in",
    //   label: "Sign In",
    // },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                seteditUserData(tableMeta.rowData);
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];
  /**********initial values formik */
  const initialValues = {
    f_name: editUserData ? editUserData[2] : "",
    l_name: editUserData ? editUserData[3] : "",
    email: editUserData ? editUserData[6] : "",
    user_access_level: accessLevel?.role_name || "",
    password: "",
    repeat_password: "",
  };
  /**********validation schema */
  const validationSchema = yup.object().shape({
    f_name: yup.string().required("First Name Required"),
    l_name: yup.string().required("Last Name Required"),
    email: yup.string().required("User Email Required"),
    user_access_level: yup.string().required("Access Level Required"),
    password: yup
      .string()
      .required("Password Required"),
    repeat_password: yup
      .string()
      .required("Repeat Password Required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const validatePassword = (values) => {
    const { password } = values;
    setRequirements({
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      minLength: password.length >= 8,
    });
  };

  return (
    <div className="pb-4">
      <div className="col-11 mx-auto">
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
          validationSchema={validationSchema}
          validate={validatePassword}
          initialValues={initialValues}
          onSubmit={(values, action) => {
            let id = editUserData[0];
            if (
              requirements.lowercase &&
              requirements.uppercase &&
              requirements.number &&
              requirements.minLength &&
              requirements.specialChar
            ) {
              let obj = {
                password: values.password,
                role_id: editUserData[5],
                f_name: values.f_name,
                l_name: values.l_name,
                email: values.email,
              };
              dispatch(asyncUpdateUserData({ id, obj, agentId }));
              seteditUserData(null);
              action.resetForm({ values: "" });
            }
          }}
        >
          {({ values, handleChange, setFieldValue }) => {
            return (
              <Form>
                <div className="row align-items-start mt-4 ">
                  <div className="col-sm-2  h-5rem">
                    <TextField
                      label="First Name"
                      variant="standard"
                      className="w-100"
                      autoComplete="off"
                      name={`f_name`}
                      value={values.f_name}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("f_name", value.toUpperCase());
                      }}
                    />
                    <ErrorMessage
                      component={"p"}
                      className="text-danger mt-1"
                      name="f_name"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      label="Last Name"
                      variant="standard"
                      className="w-100"
                      autoComplete="off"
                      name={`l_name`}
                      value={values.l_name}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("l_name", value.toUpperCase());
                      }}
                    />
                    <ErrorMessage
                      component={"p"}
                      className="text-danger mt-1"
                      name="l_name"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      label="User Email"
                      variant="standard"
                      className="w-100"
                      autoComplete="off"
                      name={`email`}
                      value={values.email}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("email", value.toUpperCase());
                      }}
                    />
                    <ErrorMessage
                      component={"p"}
                      className="text-danger mt-1"
                      name="email"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      label="User Access Level"
                      variant="standard"
                      className="w-100"
                      autoComplete="off"
                      name={`user_access_level`}
                      value={values.user_access_level}
                      onChange={handleChange}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <ErrorMessage
                      component={"p"}
                      className="text-danger mt-1"
                      name="user_access_level"
                    />
                  </div>
                  <div className="col-sm-2">
                    <FormControl sx={{ width: "100%" }} variant="standard">
                      <InputLabel htmlFor="standard-adornment-password">
                        Password
                      </InputLabel>
                      <Input
                        id="standard-adornment-password"
                        autoComplete="off"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e) => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                      <Typography
                        variant="body2"
                        sx={{ mt: 1, fontWeight: 500 }}
                      >
                        PASSWORD MUST CONTAIN:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: requirements.lowercase ? "green" : "red",
                        }}
                      >
                        {requirements.lowercase ? <Check /> : <Close />}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          At least one lowercase letter
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: requirements.uppercase ? "green" : "red",
                        }}
                      >
                        {requirements.uppercase ? <Check /> : <Close />}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          At least one uppercase letter
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: requirements.specialChar
                            ? "green"
                            : "red",
                        }}
                      >
                        {requirements.specialChar ? <Check /> : <Close />}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          At least one special character
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: requirements.number ? "green" : "red",
                        }}
                      >
                        {requirements.number ? <Check /> : <Close />}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          At least one number
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: requirements.minLength ? "green" : "red",
                        }}
                      >
                        {requirements.minLength ? <Check /> : <Close />}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                          minimum 8 characters
                        </Typography>
                      </Box>
                    </FormControl>
                    <ErrorMessage
                      component={"p"}
                      className="text-danger mt-1"
                      name="password"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      label="Confirm Password"
                      variant="standard"
                      className="w-100"
                      autoComplete="off"
                      type="password"
                      name={`repeat_password`}
                      value={values.repeat_password}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      component={"p"}
                      className="text-danger mt-1"
                      name="repeat_password"
                    />
                  </div>
                  <div className="text-center">
                    {editUserData && (
                      <button
                        className="btn setup-btn mt-5 me-2"
                        style={{ background: "#dc3545", color: "#fff" }}
                        onClick={(e) => seteditUserData(null)}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      className="btn setup-btn mt-5 mx-auto"
                      type="submit"
                    // disabled={!editUserData}
                    >
                      {loading ? <BtnLoader /> : "Update"}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <MUIDataTable
        className="muidatatable col-sm-11 mx-auto my-4"
        title={"Agents Details"}
        data={userData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default AgentUser;
