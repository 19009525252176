import React from 'react'
import OurPartner from '../components/Agency/homeviewsittings/OurPartner'

const OurPartnerPage = () => {
  return (
    <div>
      <OurPartner/>
    </div>
  )
}

export default OurPartnerPage