import { TextField } from "@mui/material";
import { FieldArray } from "formik";
import React from "react";
import { TwoDecimalPoint } from "../../../../../../Utils/FixedTwoDecimal";

function TaxCurr({ setFieldValue, values, friend, index }) {
  return (
    <div className="shadow-sm rounded px-2 pe-1 tax_pf black_border mb-3">
      <div className="h5">Tax In {friend?.currency?.curr_code}</div>
      <FieldArray
        name={`fareValues.${index}.text_cur`}
        render={(helpers) => (
          <div>
            {friend?.text_cur?.map((objcur, ndx) => (
              <>
                <div key={ndx}>
                  <div className="row align-items-end mt-1">
                    <div className="h5 col-3">Tax {ndx + 1}:</div>
                    <div className="col-3">
                      <TextField
                        required
                        id={`fareValues.${index}.text_cur.${ndx}.tax_yq`}
                        value={objcur.tax_yq}
                        onChange={(e) => {
                          const inputValue = e.target.value.toUpperCase();
                          if (/^[A-Z0-9]{0,2}$/.test(inputValue)) {
                            setFieldValue(
                              `fareValues.${index}.text_cur.${ndx}.tax_yq`,
                              inputValue
                            );
                            setFieldValue(
                              `fareValues.${index}.text_pkr.${ndx}.tax_pkr_yq`,
                              inputValue
                            );
                          }
                        }}
                        label="Txt Code"
                        placeholder="e.g YQ"
                        variant="standard"
                        className="w-100"
                      />
                    </div>
                    <div className="col-3">
                      <TextField
                        id={`fareValues.${index}.text_cur.${ndx}.tax_amount`}
                        value={objcur.tax_amount}
                        onChange={(e) => {
                          setFieldValue(
                            `fareValues.${index}.text_cur.${ndx}.tax_amount`,
                            e.target.value
                          );
                          setFieldValue(
                            `fareValues.${index}.text_pkr.${ndx}.tax_pkr_amount`,
                            TwoDecimalPoint(e.target.value * friend.roe)
                          );
                        }}
                        label="Txt Amount"
                        variant="standard"
                        className="w-100"
                      />
                    </div>
                    <div className="col-3">
                      {ndx >= 1 && (
                        <button
                          onClick={() => {
                            helpers.remove(ndx);
                            friend.text_pkr.splice(ndx, 1);
                          }}
                          className="button_style w-100 fs-6 bg-danger "
                          type="button"
                        >
                          - Tax
                        </button>
                      )}
                      {friend.text_cur.length <= 5 && ndx === 0 && (
                        <button
                          onClick={() => {
                            helpers.push({
                              tax_yq: "",
                              tax_amount: "",
                            });
                            friend.text_pkr.push({
                              tax_pkr_yq: "",
                              tax_pkr_amount: "",
                            });
                          }}
                          type="button"
                          className="button_style w-100 fs-6"
                        >
                          + Tax
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        )}
      />
    </div>
  );
}

export default TaxCurr;
