import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  originAirportList: [],
  destinatioAirportList: [],
  loading: false,
  error: null,
};

// getting first 5 airports names of origin
export const asyncGetDefaultOriginAirport = createAsyncThunk(
  "asyncGetDefaultOriginAirport/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/airport-list`);
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// getting first 5 airports names
export const asyncGetDefaultAirportDestination = createAsyncThunk(
  "asyncGetDefaultAirportDestination/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/airport-list`);
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// getting filter airport
export const asyncGetOriginAirportsName = createAsyncThunk(
  "asyncGetOriginAirportsName/get",
  async (value, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/airportlist/filter/?query_param=${value}`
      );
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// getting filter airport
export const asyncGetDestinationAirportsName = createAsyncThunk(
  "asyncGetDestinationAirportsName/get",
  async (value, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/airportlist/filter/?query_param=${value}`
      );
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const homeSlice = createSlice({
  name: "homeSlice",
  initialState,

  extraReducers: (builder) => {
    // getting default airport list for origin
    builder.addCase(
      asyncGetDefaultOriginAirport.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetDefaultOriginAirport.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.originAirportList = payload;
      }
    );
    builder.addCase(
      asyncGetDefaultOriginAirport.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // getting default airport name for destination
    builder.addCase(
      asyncGetDefaultAirportDestination.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetDefaultAirportDestination.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.destinatioAirportList = payload;
      }
    );
    builder.addCase(
      asyncGetDefaultAirportDestination.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    //// getting filter airport data origin
    builder.addCase(
      asyncGetOriginAirportsName.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetOriginAirportsName.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.originAirportList = payload;
      }
    );
    builder.addCase(
      asyncGetOriginAirportsName.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    //// getting filter airport data for destination
    builder.addCase(
      asyncGetDestinationAirportsName.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetDestinationAirportsName.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.destinatioAirportList = payload;
      }
    );
    builder.addCase(
      asyncGetDestinationAirportsName.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export default homeSlice.reducer;
