import MUIDataTable from "mui-datatables";
import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAdminPaidVoucher } from "../../../redux/features/Payments/PaymentsAllSlices";
import { HTTP } from "../../../Utils/API";

const PaidVoucher = () => {
  const {  adminPaidVoucher}=useSelector(state=>state.allPayments)
  const dispatch=useDispatch()


  useEffect(() => {
    dispatch(asyncGetAdminPaidVoucher())

  }, [])

console.log(adminPaidVoucher);
  const columns = [
    {
      name: "cheque_date",
      label: "Date/Time",
    
    },
    {
      name: "company.company_name",
      label: "company",
      options: {
        display:false,
      },
    },
    // i hided the company name here just to get the value of it from the row data down
    {
      name: "supplier.name",
      label: "Supplier | Customer",
  options:{
    customBodyRender: (value, tableMeta, updateValue) => {
      return (
        <div>
          <p>{tableMeta.rowData[1] } {tableMeta.rowData[2]}</p>
        </div>
      );
    },
  },
    },
    {
      name: "total_amount", 
      label: "Amount",
    },
    {
      label: "Status By Bank",
      name: "b_status.status_name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="table-btn btn-success"
              // onClick={() => console.log(value, tableMeta)}
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      // here is only ibft image 
      label: "Deposit Image",
      name: "ibft_image",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
        value &&    <a
              role="button"
              href={value}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <button className="table-btn btn-success">View</button>
            </a>
          );
        },
      },
    },
  ];


  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    enableNestedDataAccess:"."
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };

  return (
    <div className="pb-5 px-5 mt-5">
      <MUIDataTable
        className="muidatatable"
        title={"Paid Vouchers"}
        data={adminPaidVoucher}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default PaidVoucher;
