import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetRoleData } from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import Grid from "@mui/material/Grid";
import { asyncGetAllUserData } from "../../redux/features/agency/usersSlice";
import User from "../../assets/images/user1.png";
import { HiPlus, HiOutlineMinus } from "react-icons/hi";
import {
  asyncAssignRolePermission,
  asyncAssignUserLevelPermission,
  asyncGetAllPageLevel,
} from "../../redux/features/agency/rolesPermissionSlice";
import Setup from "../../assets/images/setup.png";
import Finance from "../../assets/images/chart.png";
import Agency from "../../assets/images/agency.png";
import { ContentPasteSearchOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";

const UserLevel = () => {
  const dispatch = useDispatch();

  /***************USE selector redux *********** */
  const { roleData } = useSelector((state) => state?.bank_curr_roleSlice);

  useEffect(() => {
    // Fetch role data and all users data from Redux store on component mount
    dispatch(asyncGetRoleData());
    dispatch(asyncGetAllPageLevel());
    dispatch(asyncGetAllUserData());
  }, [dispatch]);

  const mainAgency = roleData?.filter(
    (ad) => ad.role_id !== 1 && ad.role_id !== 6 && ad.role_id !== 7
  );
  const subAgency = roleData?.filter(
    (ad) => ad.role_id === 6 || ad.role_id === 7
  );

  return (
    <div className="pb-5">
      <div className="mx-5 rounded-2 shadow-sm mt-5 pb-2 bg-white mb-3">
        <p className="otawix-heading box_title">Roles and Permissions</p>
        <div className="p-4 px-5">
          <div className="role_permition">
            <div className="row align-items-center py-4 px-2">
              <div className="col-4"></div>
              <div className="col-4 box_title color_primary">Roles</div>
              <div className="col-4 box_title color_primary">Permissions</div>
            </div>
            {/************* //// BODY  ////***********/}
            <div style={{ transition: "all 2000ms ease-in-out" }}>
              <div className="shadow-sm">
                <Component data={mainAgency} />
              </div>
              <div className="shadow-sm mt-5">
                <Component data={subAgency} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Component = ({ data }) => {
  const dispatch = useDispatch();
  const [toastMessage, setToastMessage] = useState({
    success: "",
    msg: "",
  });

  const { PageLevelData, userPermission, userTypePermissions } = useSelector(
    (state) => state?.roleAndPermissions
  );
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const { allUsersData } = useSelector((state) => state?.usersSlice);
  /**************use state ************ */
  const [expandedRole, setExpandedRole] = useState(null);
  const [expandedPermission, setExpandedPermission] = useState(null);
  const [expandedUser, setExpandedUser] = useState(null);

  // Check if the login user has permission to add supplier
  const editRolesAndPermissions = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/edit-roles-and-permissions"
      )
    : true;

  // handle assign button roles
  const handleAssignButtonClick = (roleName) => {
    // Toggle the expanded role. If the clicked role is already expanded, collapse it.
    setExpandedRole((prevExpandedRole) =>
      prevExpandedRole === roleName ? null : roleName
    );
  };

  // handle assign button permissions
  const handlePermissionButtonClick = (roleName) => {
    // Toggle the expanded permission. If the clicked permission is already expanded, collapse it.
    setExpandedPermission((prevExpandedPermission) =>
      prevExpandedPermission === roleName ? null : roleName
    );
  };

  // handle on click on user in permissions
  const handleUserClick = (userId) => {
    // Toggle the expanded user. If the clicked user is already expanded, collapse it.
    setExpandedUser((prevExpandedUser) =>
      prevExpandedUser === userId ? null : userId
    );
  };

  // Handle assigned page levels
  const assignPageLevel = async (it, role, itemTitle) => {
    it.page_level_id.length > 0
      ? it.page_level_id.forEach(async (id) => {
          const res = await dispatch(
            asyncAssignRolePermission({
              user_type_id: role.role_id,
              page_level_id: id,
              toast,
            })
          );
          setToastMessage({
            success: res?.payload ? true : false,
            msg: res?.payload ? "Permission assigned!" : "Permission denied!",
            title: itemTitle,
          });
          setTimeout(() => {
            setToastMessage({
              success: "",
              msg: "",
              title: null,
            });
          }, 5000);
        })
      : alert("You cant assign this permission");
  };

  // Handle assiged Permissions
  const assignPermissions = (it, ai, itemTitle) => {
    it?.page_level_id?.forEach(async (it) => {
      const res = await dispatch(
        asyncAssignUserLevelPermission({
          user_id: ai.user_id,
          page_level_id: it,
        })
      );
      setToastMessage({
        success: res?.payload ? true : false,
        msg: res?.payload ? "Permission assigned!" : "Permission denied!",
        title: itemTitle,
      });
      setTimeout(() => {
        setToastMessage({
          success: "",
          msg: "",
          title: null,
        });
      }, 5000);
    });
  };

  // helper array for page level permissions
  const rolesArary = [
    {
      id: 1,
      heading: "Setup",
      image: Setup,
      mainArr: [
        //promotion
        {
          id: 2,
          title: "Promotions",
          subarr: [
            {
              id: 1,
              name: "Add Flash News",
              page_level_id: [
                PageLevelData[46]?.id,
                PageLevelData[47]?.id,
                PageLevelData[48]?.id,
                PageLevelData[49]?.id,
              ],
              url: [
                PageLevelData[46]?.permission_url,
                PageLevelData[47]?.permission_url,
                PageLevelData[48]?.permission_url,
                PageLevelData[49]?.permission_url,
              ],
            },
            {
              id: 2,
              name: "Flight Groups",
              page_level_id: [PageLevelData[87]?.id, PageLevelData[88]?.id],
              url: [
                PageLevelData[87]?.permission_url,
                PageLevelData[88]?.permission_url,
              ],
            },
            {
              id: 3,
              name: "Circular",
              page_level_id: [
                PageLevelData[50]?.id,
                PageLevelData[51]?.id,
                PageLevelData[52]?.id,
                PageLevelData[53]?.id,
              ],
              url: [
                PageLevelData[50]?.permission_url,
                PageLevelData[51]?.permission_url,
                PageLevelData[52]?.permission_url,
                PageLevelData[53]?.permission_url,
              ],
            },
            {
              id: 4,
              name: "Promotions",
              page_level_id: [
                PageLevelData[54]?.id,
                PageLevelData[55]?.id,
                PageLevelData[56]?.id,
                PageLevelData[57]?.id,
                PageLevelData[58]?.id,
              ],
              url: [
                PageLevelData[54]?.permission_url,
                PageLevelData[55]?.permission_url,
                PageLevelData[56]?.permission_url,
                PageLevelData[57]?.permission_url,
                PageLevelData[58]?.permission_url,
              ],
            },
          ],
        },
        //visa
        {
          id: 3,
          title: "Visa",
          subarr: [
            {
              id: 1,
              name: "Countries",
              page_level_id: [
                PageLevelData[76]?.id,
                PageLevelData[77]?.id,
                PageLevelData[78]?.id,
              ],
              url: [
                PageLevelData[76]?.permission_url,
                PageLevelData[77]?.permission_url,
                PageLevelData[78]?.permission_url,
              ],
            },
            {
              id: 2,
              name: "Visa Type",
              page_level_id: [
                PageLevelData[79]?.id,
                PageLevelData[80]?.id,
                PageLevelData[81]?.id,
              ],
              url: [
                PageLevelData[79]?.permission_url,
                PageLevelData[80]?.permission_url,
                PageLevelData[81]?.permission_url,
              ],
            },
            {
              id: 2,
              name: "Visa Rate",
              page_level_id: [
                PageLevelData[82]?.id,
                PageLevelData[83]?.id,
                PageLevelData[84]?.id,
                PageLevelData[85]?.id,
                PageLevelData[86]?.id,
              ],
              url: [
                PageLevelData[82]?.permission_url,
                PageLevelData[83]?.permission_url,
                PageLevelData[84]?.permission_url,
                PageLevelData[85]?.permission_url,
                PageLevelData[86]?.permission_url,
              ],
            },
          ],
        },
        //umrah
        // {
        //   id: 4,
        //   title: "Umrah",
        //   subarr: [
        //     {
        //       id: 1,
        //       name: "Hotel",
        //       page_level_id: [],
        //       url: [],
        //     },
        //     {
        //       id: 2,
        //       name: "Transfer",
        //       page_level_id: [],
        //       url: [],
        //     },
        //     {
        //       id: 3,
        //       name: "Visa Supplier",
        //       page_level_id: [],
        //       url: [],
        //     },
        //     {
        //       id: 4,
        //       name: "Transfer Supplier",
        //       page_level_id: [],
        //       url: [],
        //     },
        //     {
        //       id: 5,
        //       name: "Hotel Room Type",
        //       page_level_id: [],
        //       url: [],
        //     },
        //     {
        //       id: 6,
        //       name: "Umrah Hotel Rates",
        //       page_level_id: [],
        //       url: [],
        //     },
        //   ],
        // },
        //new registration
        
        {
          id: 5,
          title: "New Regisration",
          subarr: [
            {
              id: 1,
              name: "New Regisration",
              page_level_id: [PageLevelData[0]?.id],
              url: [PageLevelData[0]?.permission_url],
            },
          ],
        },
        //add new supplier
        {
          id: 6,
          title: "Add New Supplier",
          subarr: [
            {
              id: 1,
              name: "Add New Supplier",
              page_level_id: [PageLevelData[59]?.id],
              url: [PageLevelData[59]?.permission_url],
            },
          ],
        },
        //agents group
        {
          id: 7,
          title: "Agents Group",
          subarr: [
            {
              id: 1,
              name: "Agents Group",
              page_level_id: [
                PageLevelData[60]?.id,
                PageLevelData[61]?.id,
                PageLevelData[62]?.id,
                PageLevelData[63]?.id,
              ],
              url: [
                PageLevelData[60]?.permission_url,
                PageLevelData[61]?.permission_url,
                PageLevelData[62]?.permission_url,
                PageLevelData[63]?.permission_url,
              ],
            },
          ],
        },
        // agents
        {
          id: 8,
          title: "Agent",
          subarr: [
            {
              id: 1,
              page_level_id: [
                PageLevelData[1]?.id,
                PageLevelData[2]?.id,
                PageLevelData[3]?.id,
                PageLevelData[4]?.id,
                PageLevelData[5]?.id,
                PageLevelData[6]?.id,
              ],
              name: "Agent",
              url: [
                PageLevelData[1]?.permission_url,
                PageLevelData[2]?.permission_url,
                PageLevelData[3]?.permission_url,
                PageLevelData[4]?.permission_url,
                PageLevelData[5]?.permission_url,
                PageLevelData[6]?.permission_url,
              ],
            },
          ],
        },
        //bank details
        {
          id: 9,
          title: "Bank Details",
          subarr: [
            {
              id: 1,
              name: "Bank Details",
              page_level_id: [
                PageLevelData[40]?.id,
                PageLevelData[41]?.id,
                PageLevelData[42]?.id,
                PageLevelData[43]?.id,
              ],
              url: [
                PageLevelData[40]?.permission_url,
                PageLevelData[41]?.permission_url,
                PageLevelData[42]?.permission_url,
                PageLevelData[43]?.permission_url,
              ],
            },
          ],
        },
        //currency
        {
          id: 10,
          title: "Currency",
          subarr: [
            {
              id: 1,
              name: "Currency",
              page_level_id: [
                PageLevelData[9]?.id,
                PageLevelData[10]?.id,
                PageLevelData[11]?.id,
              ],
              url: [
                PageLevelData[9]?.permission_url,
                PageLevelData[10]?.permission_url,
                PageLevelData[11]?.permission_url,
              ],
            },
          ],
        },
        //pricing
        {
          id: 11,
          title: "API Pricing",
          subarr: [
            {
              id: 1,
              name: "API Pricing",
              page_level_id: [
                PageLevelData[12]?.id,
                PageLevelData[13]?.id,
                PageLevelData[14]?.id,
                PageLevelData[15]?.id,
              ],
              url: [
                PageLevelData[12]?.permission_url,
                PageLevelData[13]?.permission_url,
                PageLevelData[14]?.permission_url,
                PageLevelData[15]?.permission_url,
              ],
            },
          ],
        },
        //airline
        {
          id: 12,
          title: "API Airlines",
          subarr: [
            {
              id: 1,
              name: "API Airlines",
              page_level_id: [
                PageLevelData[64]?.id,
                PageLevelData[65]?.id,
                PageLevelData[66]?.id,
                PageLevelData[67]?.id,
              ],
              url: [
                PageLevelData[64]?.permission_url,
                PageLevelData[65]?.permission_url,
                PageLevelData[66]?.permission_url,
                PageLevelData[67]?.permission_url,
              ],
            },
          ],
        },
        //password
        {
          id: 13,
          title: "API Password",
          subarr: [
            {
              id: 1,
              name: "API Password",
              page_level_id: [PageLevelData[68]?.id],
              url: [PageLevelData[68]?.permission_url],
            },
          ],
        },
        //supplier rules
        {
          id: 14,
          title: "Supplier Rules",
          subarr: [
            {
              id: 1,
              name: "Supplier Rules",
              page_level_id: [
                PageLevelData[69]?.id,
                PageLevelData[70]?.id,
                PageLevelData[71]?.id,
                PageLevelData[72]?.id,
                PageLevelData[73]?.id,
              ],
              url: [
                PageLevelData[69]?.permission_url,
                PageLevelData[70]?.permission_url,
                PageLevelData[71]?.permission_url,
                PageLevelData[72]?.permission_url,
                PageLevelData[73]?.permission_url,
              ],
            },
          ],
        },
        //resolved requests
        {
          id: 15,
          title: "Resolved Requests",
          subarr: [
            {
              id: 1,
              name: "Resolved Requests",
              page_level_id: [PageLevelData[74]?.id, PageLevelData[75]?.id],
              url: [
                PageLevelData[74]?.permission_url,
                PageLevelData[75]?.permission_url,
              ],
            },
          ],
        },
      ],
    },
    //finance
    {
      id: 2,
      heading: "Finance",
      image: Finance,
      mainArr: [
        //payments
        {
          id: 1,
          title: "Payments",
          subarr: [
            // {
            //   id: 1,
            //   name: "Make Payment",
            //   page_level_id: [],
            //   url: [],
            // },
            // {
            //   id: 2,
            //   name: "Corporate Payment",
            //   page_level_id: [],
            //   url: [],
            // },
            {
              id: 3,
              name: "Agent Payments",
              page_level_id: [PageLevelData[89]?.id],
              url: [PageLevelData[89]?.permission_url],
            },
            {
              id: 4,
              name: "Reciept",
              page_level_id: [PageLevelData[90]?.id],
              url: [PageLevelData[90]?.permission_url],
            },
            {
              id: 5,
              name: "Collection",
              page_level_id: [PageLevelData[91]?.id],
              url: [PageLevelData[91]?.permission_url],
            },
          ],
        },
        // agents
        {
          id: 2,
          title: "Agents",
          subarr: [
            {
              id: 1,
              name: "Agent List",
              page_level_id: [PageLevelData[123]?.id],
              url: [PageLevelData[123]?.permission_url],
            },
            {
              id: 2,
              name: "Agent Statement",
              page_level_id: [PageLevelData[124]?.id],
              url: [PageLevelData[124]?.permission_url],
            },
          ],
        },
        //supplier
        {
          id: 3,
          title: "Suppliers",
          subarr: [
            {
              id: 1,
              name: "Account Statement",
              page_level_id: [PageLevelData[93]?.id],
              url: [PageLevelData[93]?.permission_url],
            },
          ],
        },
        //sale
        {
          id: 4,
          title: "Sale",
          subarr: [
            {
              id: 1,
              name: "Daily Sale Report",
              page_level_id: [PageLevelData[94]?.id],
              url: [PageLevelData[94]?.permission_url],
            },
            {
              id: 2,
              name: "Sale Report",
              page_level_id: [PageLevelData[95]?.id],
              url: [PageLevelData[95]?.permission_url],
            },
            {
              id: 3,
              name: "Refund Report",
              page_level_id: [PageLevelData[96]?.id],
              url: [PageLevelData[96]?.permission_url],
            },
            {
              id: 4,
              name: "Transactional Report",
              page_level_id: [PageLevelData[97]?.id],
              url: [PageLevelData[97]?.permission_url],
            },
            {
              id: 5,
              name: "Visa Report",
              page_level_id: [PageLevelData[98]?.id],
              url: [PageLevelData[98]?.permission_url],
            },
            {
              id: 6,
              name: "Group Flight Menifest",
              page_level_id: [PageLevelData[99]?.id],
              url: [PageLevelData[99]?.permission_url],
            },

            {
              id: 8,
              name: "Agent Progress Report",
              page_level_id: [PageLevelData[100]?.id],
              url: [PageLevelData[100]?.permission_url],
            },
            {
              id: 9,
              name: "Supplier Progress Report",
              page_level_id: [PageLevelData[101]?.id],
              url: [PageLevelData[101]?.permission_url],
            },
            {
              id: 10,
              name: "Umrah Menifest View",
              page_level_id: [],
              url: [],
            },
          ],
        },
        //vouchers
        {
          id: 5,
          title: "Vouchers",
          subarr: [
            {
              id: 1,
              name: "All Vouchers",
              page_level_id: [PageLevelData[102]?.id],
              url: [PageLevelData[102]?.permission_url],
            },
            {
              id: 2,
              name: "Vouchers Entry",
              page_level_id: [PageLevelData[108]?.id],
              url: [PageLevelData[108]?.permission_url],
            },
            {
              id: 3,
              name: "Manual Invoice",
              page_level_id: [PageLevelData[109]?.id],
              url: [PageLevelData[109]?.permission_url],
            },
            {
              id: 4,
              name: "Manual Credit",
              page_level_id: [PageLevelData[110]?.id],
              url: [PageLevelData[110]?.permission_url],
            },
            {
              id: 5,
              name: "Invoice Logs",
              page_level_id: [],
              url: [],
            },
          ],
        },
        //Account
        {
          id: 6,
          title: "Accounts",
          subarr: [
            {
              id: 1,
              name: "Chart of Account",
              page_level_id: [
                PageLevelData[111]?.id,
                PageLevelData[112]?.id,
                PageLevelData[113]?.id,
                PageLevelData[114]?.id,
              ],
              url: [
                PageLevelData[111]?.permission_url,
                PageLevelData[112]?.permission_url,
                PageLevelData[113]?.permission_url,
                PageLevelData[114]?.permission_url,
              ],
            },
            {
              id: 2,
              name: "Gournal Ledger",
              page_level_id: [PageLevelData[115]?.id],
              url: [PageLevelData[115]?.permission_url],
            },
            {
              id: 3,
              name: "Trial Balence",
              page_level_id: [PageLevelData[116]?.id],
              url: [PageLevelData[116]?.permission_url],
            },
            {
              id: 4,
              name: "Balence Sheet",
              page_level_id: [PageLevelData[117]?.id],
              url: [PageLevelData[117]?.permission_url],
            },
            {
              id: 5,
              name: "Profit and Loss",
              page_level_id: [PageLevelData[118]?.id],
              url: [PageLevelData[118]?.permission_url],
            },
            {
              id: 6,
              name: "Opening Balance",
              page_level_id: [PageLevelData[135]?.id],
              url: [PageLevelData[135]?.permission_url],
            },
            {
              id: 7,
              name: "Assets Statement",
              page_level_id: [PageLevelData[119]?.id],
              url: [PageLevelData[119]?.permission_url],
            },
            {
              id: 8,
              name: "Expense Statement",
              page_level_id: [PageLevelData[120]?.id],
              url: [PageLevelData[120]?.permission_url],
            },
            {
              id: 9,
              name: "Bank/Cash",
              page_level_id: [PageLevelData[121]?.id],
              url: [PageLevelData[121]?.permission_url],
            },
            {
              id: 10,
              name: "Add Cheque Book",
              page_level_id: [PageLevelData[122]?.id],
              url: [PageLevelData[122]?.permission_url],
            },
          ],
        },
      ],
    },
    //agency
    {
      id: 3,
      heading: "Agency",
      image: Agency,
      mainArr: [
        // Agency Data
        {
          id: 1,
          title: "Agency Data",
          subarr: [
            {
              id: 1,
              name: "Agency Data",
              page_level_id: [
                PageLevelData[7]?.id,
                PageLevelData[8]?.id,
                PageLevelData[44]?.id,
                PageLevelData[45]?.id,
              ],
              url: [
                PageLevelData[7]?.permission_url,
                PageLevelData[8]?.permission_url,
                PageLevelData[44]?.permission_url,
                PageLevelData[45]?.permission_url,
              ],
            },
          ],
        },
        //users
        {
          id: 2,
          title: "Users",
          subarr: [
            {
              id: 1,
              name: "Users",
              page_level_id: [
                PageLevelData[16]?.id,
                PageLevelData[17]?.id,
                PageLevelData[18]?.id,
                PageLevelData[19]?.id,
                PageLevelData[20]?.id,
              ],
              url: [
                PageLevelData[16]?.permission_url,
                PageLevelData[17]?.permission_url,
                PageLevelData[18]?.permission_url,
                PageLevelData[19]?.permission_url,
                PageLevelData[20]?.permission_url,
              ],
            },
          ],
        },

        //Roles and permissions
        {
          id: 4,
          title: "Roles and Permissions",
          subarr: [
            {
              id: 1,
              name: "Roles  Permissions",
              page_level_id: [PageLevelData[144]?.id],
              url: [PageLevelData[144]?.permission_url],
            },
          ],
        },
        //home view settings
        {
          id: 5,
          title: "Home View Settings",
          subarr: [
            {
              id: 1,
              name: "Banner",
              page_level_id: [
                PageLevelData[21]?.id,
                PageLevelData[22]?.id,
                PageLevelData[23]?.id,
                PageLevelData[24]?.id,
              ],
              url: [
                PageLevelData[21]?.permission_url,
                PageLevelData[22]?.permission_url,
                PageLevelData[23]?.permission_url,
                PageLevelData[24]?.permission_url,
              ],
            },
            {
              id: 2,
              name: "About US",
              page_level_id: [
                PageLevelData[37]?.id,
                PageLevelData[38]?.id,
                PageLevelData[39]?.id,
              ],
              url: [
                PageLevelData[37]?.permission_url,
                PageLevelData[38]?.permission_url,
                PageLevelData[39]?.permission_url,
              ],
            },
            {
              id: 3,
              name: "Our Services",
              page_level_id: [
                PageLevelData[29]?.id,
                PageLevelData[30]?.id,
                PageLevelData[31]?.id,
                PageLevelData[32]?.id,
              ],
              url: [
                PageLevelData[29]?.permission_url,
                PageLevelData[30]?.permission_url,
                PageLevelData[31]?.permission_url,
                PageLevelData[32]?.permission_url,
              ],
            },

            {
              id: 4,
              name: "Out Partner",
              page_level_id: [
                PageLevelData[33]?.id,
                PageLevelData[34]?.id,
                PageLevelData[35]?.id,
                PageLevelData[36]?.id,
              ],
              url: [
                PageLevelData[33]?.id,
                PageLevelData[34]?.id,
                PageLevelData[35]?.id,
                PageLevelData[36]?.id,
              ],
            },
            {
              id: 5,
              name: "Footer Gallery",
              page_level_id: [
                PageLevelData[25]?.id,
                PageLevelData[26]?.id,
                PageLevelData[27]?.id,
                PageLevelData[28]?.id,
              ],
              url: [
                PageLevelData[25]?.permission_url,
                PageLevelData[26]?.permission_url,
                PageLevelData[27]?.permission_url,
                PageLevelData[28]?.permission_url,
              ],
            },
          ],
        },
      ],
    },
  ];
  // helper array for user level permissions
  const permissionArray = [
    {
      id: 3,
      heading: "Operations",
      image: Finance,
      mainArr: [
        // banking
        {
          id: 1,
          title: "Booking / Request",
          subarr: [
            {
              id: 1,
              name: "Flight",
              page_level_id: [PageLevelData[137]?.id],
              url: [PageLevelData[137]?.permission_url],
            },
            {
              id: 22,
              name: "Flight Group",
              page_level_id: [PageLevelData[138]?.id],
              url: [PageLevelData[138]?.permission_url],
            },
            // {
            //   id: 2,
            //   name: "Umrah",
            //   page_level_id: [],
            //   url: [],
            // },
            {
              id: 3,
              name: "Visa",
              page_level_id: [PageLevelData[139]?.id],
              url: [PageLevelData[139]?.permission_url],
            },
            {
              id: 4,
              name: "Insurance",
              page_level_id: [PageLevelData[140]?.id],
              url: [PageLevelData[140]?.permission_url],
            },
            {
              id: 5,
              name: "Hotels",
              page_level_id: [PageLevelData[141]?.id],
              url: [PageLevelData[141]?.permission_url],
            },
            {
              id: 6,
              name: "Activity",
              page_level_id: [PageLevelData[142]?.id],
              url: [PageLevelData[142]?.permission_url],
            },
            {
              id: 7,
              name: "Transfer",
              page_level_id: [PageLevelData[143]?.id],
              url: [PageLevelData[143]?.permission_url],
            },
            {
              id: 7,
              name: "Own Agents Bookings",
              page_level_id: [PageLevelData[156]?.id],
              url: [PageLevelData[156]?.permission_url],
            },
          ],
        },

        // collection
        {
          id: 3,
          title: "Collections",
          subarr: [
            {
              id: 1,
              name: "Process",
              page_level_id: [PageLevelData[125]?.id, PageLevelData[126]?.id],
              url: [
                PageLevelData[125]?.permission_url,
                PageLevelData[126]?.permission_url,
              ],
            },
          ],
        },
        // flight group
        {
          id: 5,
          title: "Flight Group",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[87]?.id],
              url: [PageLevelData[87]?.permission_url],
            },
            {
              id: 1,
              name: "Edit",
              page_level_id: [PageLevelData[127]?.id],
              url: [PageLevelData[127]?.permission_url],
            },
          ],
        },

        // visa types
        {
          id: 7,
          title: "Visa Type / Visa Rate",
          subarr: [
            {
              id: 1,
              name: "Add (Visa Type)",
              page_level_id: [PageLevelData[80]?.id],
              url: [PageLevelData[80]?.permission_url],
            },
            {
              id: 1,
              name: "Edit (Visa Type)",
              page_level_id: [PageLevelData[81]?.id],
              url: [PageLevelData[81]?.permission_url],
            },
            {
              id: 3,
              name: "Add (Visa Rate)",
              page_level_id: [PageLevelData[83]?.id],
              url: [PageLevelData[83]?.permission_url],
            },
            {
              id: 4,
              name: "Edit (Visa Rate)",
              page_level_id: [PageLevelData[84]?.id],
              url: [PageLevelData[84]?.permission_url],
            },
          ],
        },
        // supplier
        {
          id: 8,
          title: "Supplier",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[128]?.id],
              url: [PageLevelData[128]?.permission_url],
            },
            {
              id: 1,
              name: "Edit",
              page_level_id: [PageLevelData[129]?.id],
              url: [PageLevelData[129]?.permission_url],
            },
          ],
        },
        // agents
        {
          id: 9,
          title: "Agents",
          subarr: [
            {
              id: 1,
              name: "Credit Line",
              page_level_id: [PageLevelData[4]?.id],
              url: [PageLevelData[4]?.permission_url],
            },
            {
              id: 3,
              name: "Agent Status",
              page_level_id: [
                PageLevelData[3]?.id,
                PageLevelData[5]?.id,
                PageLevelData[6]?.id,
              ],
              url: [
                PageLevelData[3]?.permission_url,
                PageLevelData[5]?.permission_url,
                PageLevelData[6]?.permission_url,
              ],
            },
            {
              id: 4,
              name: "Agent List",
              page_level_id: [PageLevelData[155]?.id],
              url: [PageLevelData[155]?.permission_url],
            },
            {
              id: 2,
              name: "Edit agent office data",
              page_level_id: [PageLevelData[2]?.id],
              url: [PageLevelData[2]?.permission_url],
            },
          ],
        },
        // agency data
        {
          id: 99,
          title: "Agency Data",
          subarr: [
            {
              id: 1,
              name: "Office Data",
              page_level_id: [PageLevelData[45]?.id],
              url: [PageLevelData[45]?.permission_url],
            },
            {
              id: 2,
              name: "View Settings",
              page_level_id: [PageLevelData[44]?.id],
              url: [PageLevelData[44]?.permission_url],
            },
          ],
        },
        // bank details
        {
          id: 10,
          title: "Banks",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[41]?.id],
              url: [PageLevelData[41]?.permission_url],
            },
            {
              id: 2,
              name: "Edit",
              page_level_id: [PageLevelData[42]?.id],
              url: [PageLevelData[42]?.permission_url],
            },
          ],
        },
        // currency
        {
          id: 114,
          title: "Currency",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[10]?.id],
              url: [PageLevelData[10]?.permission_url],
            },
            {
              id: 2,
              name: "Edit",
              page_level_id: [PageLevelData[11]?.id],
              url: [PageLevelData[11]?.permission_url],
            },
          ],
        },
        // pricing
        {
          id: 11,
          title: "API Pricing",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[13]?.id],
              url: [PageLevelData[13]?.permission_url],
            },
            {
              id: 2,
              name: "Edit",
              page_level_id: [PageLevelData[14]?.id],
              url: [PageLevelData[14]?.permission_url],
            },
          ],
        },
        // airlines
        {
          id: 12,
          title: "API Airlines",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[65]?.id],
              url: [PageLevelData[65]?.permission_url],
            },
            {
              id: 2,
              name: "Edit",
              page_level_id: [PageLevelData[66]?.id],
              url: [PageLevelData[66]?.permission_url],
            },
          ],
        },
        // api password
        {
          id: 13,
          title: "API Passwords",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[68]?.id],
              url: [PageLevelData[68]?.permission_url],
            },
            {
              id: 2,
              name: "Edit",
              page_level_id: [PageLevelData[130]?.id],
              url: [PageLevelData[130]?.permission_url],
            },
          ],
        },
        // umrah hotel rates
        {
          id: 14,
          title: "Umrah Hotel Rates",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [],
              url: [],
            },
            {
              id: 2,
              name: "Edit",
              page_level_id: [],
              url: [],
            },
          ],
        },
        // users
        {
          id: 15,
          title: "Users",
          subarr: [
            {
              id: 1,
              name: "Add",
              page_level_id: [PageLevelData[17]?.id],
              url: [PageLevelData[17]?.permission_url],
            },
            {
              id: 2,
              name: "Edit",
              page_level_id: [PageLevelData[18]?.id],
              url: [PageLevelData[18]?.permission_url],
            },
            {
              id: 3,
              name: "Delete",
              page_level_id: [PageLevelData[20]?.id],
              url: [PageLevelData[20]?.permission_url],
            },
            {
              id: 4,
              name: "Status",
              page_level_id: [PageLevelData[19]?.id],
              url: [PageLevelData[19]?.permission_url],
            },
          ],
        },
        // vouchers
        {
          id: 16,
          title: "Voucher",
          subarr: [
            {
              id: 1,
              name: "Save Voucher",
              page_level_id: [PageLevelData[104]?.id],
              url: [PageLevelData[104]?.permission_url],
            },
            {
              id: 2,
              name: "Post Voucher",
              page_level_id: [PageLevelData[107]?.id],
              url: [PageLevelData[107]?.permission_url],
            },
          ],
        },
        {
          id: 17,
          title: "Roles & Permissions",
          subarr: [
            {
              id: 1,
              name: "Edit",
              page_level_id: [PageLevelData[145]?.id],
              url: [PageLevelData[145]?.permission_url],
            },
          ],
        },
      ],
    },
    {
      id: 1,
      heading: "Ticketing",
      image: Agency,
      mainArr: [
        {
          id: 1,
          title: "Ticketing",
          subarr: [
            {
              id: 1,
              name: "Issue Ticket",
              page_level_id: [PageLevelData[146]?.id],
              url: [PageLevelData[146]?.permission_url],
            },
            {
              id: 2,
              name: "Void Ticket",
              page_level_id: [PageLevelData[147]?.id],
              url: [PageLevelData[147]?.permission_url],
            },
            {
              id: 3,
              name: "Request",
              page_level_id: [PageLevelData[148]?.id],
              url: [PageLevelData[148]?.permission_url],
            },
            {
              id: 4,
              name: "Re-Issue Ticket",
              page_level_id: [PageLevelData[149]?.id],
              url: [PageLevelData[149]?.permission_url],
            },
            {
              id: 5,
              name: "Umrah Edit",
              page_level_id: [],
              url: [],
            },
            {
              id: 6,
              name: "DIS/PSF",
              page_level_id: [PageLevelData[154]?.id],
              url: [PageLevelData[154]?.permission_url],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      heading: "Finance",
      image: Setup,
      mainArr: [
        {
          id: 1,
          title: "Finance",
          subarr: [
            // {
            //   id: 1,
            //   name: "All Action",
            //   page_level_id: [PageLevelData[133]?.id],
            //   url: [PageLevelData[133]?.permission_url],
            // },
            {
              id: 2,
              name: "Edit Voucher",
              page_level_id: [PageLevelData[132]?.id],
              url: [PageLevelData[132]?.permission_url],
            },
            {
              id: 3,
              name: "Delete Voucher",
              page_level_id: [PageLevelData[131]?.id],
              url: [PageLevelData[131]?.permission_url],
            },
            {
              id: 4,
              name: "Voucher Entry",
              page_level_id: [PageLevelData[108]?.id],
              url: [PageLevelData[108]?.permission_url],
            },
            {
              id: 5,
              name: "Manual INV",
              page_level_id: [PageLevelData[134]?.id],
              url: [PageLevelData[134]?.permission_url],
            },
            {
              id: 6,
              name: "Manual Credit",
              page_level_id: [PageLevelData[110]?.id],
              url: [PageLevelData[110]?.permission_url],
            },
            {
              id: 7,
              name: "OP Balance",
              page_level_id: [PageLevelData[135]?.id],
              url: [PageLevelData[135]?.permission_url],
            },
            {
              id: 8,
              name: "Chq Book Edit",
              page_level_id: [PageLevelData[136]?.id],
              url: [PageLevelData[136]?.permission_url],
            },
          ],
        },
      ],
    },
  ];

  return (
    <>
      <div>
        {data?.map((role) => {
          const isRoleExpanded = expandedRole === role.role_name;
          const isPermissionExpanded = expandedPermission === role.role_name;
          let user_role_permission = userTypePermissions?.filter(
            (type) => role.role_id === type.user_type_id
          );
          return (
            <div className="border py-3 px-2" key={role.role_name}>
              <div className="row align-items-center">
                <div className="col-4 h5 color_primary">{role.role_name}</div>
                <div className="col-4">
                  <button
                    className="button_style px-5"
                    onClick={() => handleAssignButtonClick(role.role_name)}
                    disabled={isPermissionExpanded}
                  >
                    {isRoleExpanded ? "Cancel" : "Assign"}
                  </button>
                </div>
                {[6, 7].includes(role?.role_id) ? null : (
                  <div className="col-4">
                    <button
                      className="button_style px-5 bg-primary"
                      onClick={() =>
                        handlePermissionButtonClick(role.role_name)
                      }
                      disabled={isRoleExpanded}
                    >
                      {isPermissionExpanded ? "Cancel" : "Assign"}
                    </button>
                  </div>
                )}
              </div>
              {/* Permission detail */}
              {isPermissionExpanded && (
                <>
                  <div className="m-2 border border-primary py-2 pb-0 mt-2 rounded arrow">
                    {allUsersData
                      ?.filter((au) => au.role_id === role.role_id)
                      ?.map((ai) => {
                        let user_permission = userPermission?.filter(
                          (permission) => permission?.user_id === ai?.user_id
                        );
                        const isUserExpanded = expandedUser === ai.user_id;
                        return (
                          <div
                            className={`user_name_cont p-3 border-bottom `}
                            key={ai.user_id}
                          >
                            <div
                              key={ai.user_id}
                              onClick={() => handleUserClick(ai.user_id)}
                              className="color_primary"
                            >
                              <img
                                src={User}
                                alt="user"
                                width={25}
                                height={25}
                                className="me-2"
                              />
                              <span>
                                {ai.f_name} {ai.l_name}
                              </span>
                              <span className="px-2">
                                {isUserExpanded ? (
                                  <HiOutlineMinus />
                                ) : (
                                  <HiPlus />
                                )}
                              </span>
                            </div>
                            {isUserExpanded && (
                              <div className="mt-4">
                                <Grid container>
                                  {/* ////////// User level permissions  ///////////////////////// */}
                                  {permissionArray?.map((element) => {
                                    return (
                                      <Grid item xs={4} key={element.heading}>
                                        <div className="pe-3">
                                          <div className="d-flex justify-content-center align-items-end color_primary">
                                            <h5>{element.heading}</h5>
                                          </div>

                                          <div>
                                            {element?.mainArr?.map((item) => {
                                              return (
                                                <div
                                                  className="rounded-2 border py-3 pt-0 my-3"
                                                  key={item.title}
                                                >
                                                  <h6 className="text-center border-bottom py-3 bg-primary text-white">
                                                    {item.title}
                                                  </h6>

                                                  <div className="row px-3">
                                                    {item?.subarr.map((it) => {
                                                      let isChecked =
                                                        user_permission?.find(
                                                          (up) =>
                                                            up?.page_level_id ===
                                                            it?.page_level_id[0]
                                                        );
                                                      return (
                                                        <div
                                                          className="col-6 my-3 "
                                                          key={it.name}
                                                        >
                                                          <div
                                                            className="checkbox-rect"
                                                            style={{
                                                              opacity:
                                                                !editRolesAndPermissions
                                                                  ? 0.4
                                                                  : 1,
                                                            }}
                                                          >
                                                            <input
                                                              disabled={
                                                                !editRolesAndPermissions
                                                              }
                                                              checked={
                                                                isChecked !==
                                                                undefined
                                                              }
                                                              type="checkbox"
                                                              id={
                                                                "with_sar_" +
                                                                it.name +
                                                                item.title
                                                              }
                                                              onChange={(e) =>
                                                                assignPermissions(
                                                                  it,
                                                                  ai,
                                                                  item?.title
                                                                )
                                                              }
                                                            />
                                                            <label
                                                              htmlFor={
                                                                "with_sar_" +
                                                                it.name +
                                                                item.title
                                                              }
                                                              className="color_primary"
                                                            >
                                                              {it.name}
                                                            </label>
                                                          </div>
                                                        </div>
                                                      );
                                                    })}
                                                  </div>
                                                  {item?.title ===
                                                    toastMessage?.title && (
                                                    <Message
                                                      toastMessage={
                                                        toastMessage
                                                      }
                                                    />
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </Grid>
                                    );
                                  })}
                                </Grid>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}

              {/* ///////////////////////////////////// Page level Permission ///////////////////////// */}
              {isRoleExpanded && (
                <div className="mt-2 rounded-2 border p-3 rol_per_detail">
                  <Grid container>
                    {rolesArary.map((element) => {
                      return (
                        <Grid item xs={4} key={element.heading}>
                          <div className="pe-3">
                            <div className="d-flex justify-content-center align-items-end">
                              <img
                                src={element.image}
                                alt="setup"
                                className="menu_link_icon me-2"
                              />
                              <h5 className="color_primary">
                                {element.heading}
                              </h5>
                            </div>
                            <div>
                              {element?.mainArr?.map((item) => {
                                return (
                                  <div
                                    className="rounded-2 border py-3 pt-0 my-3"
                                    key={item.title}
                                  >
                                    <h6 className="text-center border-bottom py-3 bg_primary text-white">
                                      {item.title}
                                    </h6>

                                    <div className="row px-3">
                                      {item?.subarr.map((it) => {
                                        let isChecked =
                                          user_role_permission?.find(
                                            (userRole) =>
                                              userRole?.page_level_id ===
                                              it?.page_level_id[0]
                                          );
                                        return (
                                          <div
                                            className="col-6 my-3 "
                                            key={it.name}
                                          >
                                            <div
                                              className="checkbox-rect"
                                              style={{
                                                opacity:
                                                  !editRolesAndPermissions &&
                                                  user.user_id !== 1
                                                    ? 0.4
                                                    : 1,
                                              }}
                                            >
                                              <input
                                                disabled={
                                                  editRolesAndPermissions !==
                                                    undefined &&
                                                  user.user_id !== 1
                                                }
                                                checked={
                                                  isChecked !== undefined
                                                }
                                                type="checkbox"
                                                id={"with_sar_" + it.name}
                                                onChange={(e) =>
                                                  assignPageLevel(
                                                    it,
                                                    role,
                                                    item?.title
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={"with_sar_" + it.name}
                                                className="p color_primary"
                                              >
                                                {it.name}
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    {item?.title === toastMessage?.title && (
                                      <Message toastMessage={toastMessage} />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const Message = ({ toastMessage }) => {
  return (
    toastMessage?.msg && (
      <div className="mt-3">
        <p
          style={{
            textAlign: "center",
            color: toastMessage.success ? "#01C851" : "#FE4543",
          }}
        >
          {toastMessage.msg}
        </p>
      </div>
    )
  );
};

export default UserLevel;
