import React, { useEffect, useState } from "react";
import PiaLogo from "../../../../assets/images/airlineLogo/PK.png";
import {
  MdFlight,
  MdOutlineFlightLand,
  MdOutlineFlightTakeoff,
} from "react-icons/md";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import moment from "moment";

const HititSegments = ({ segment, index, inx, segmentArray, timeDuration }) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");

  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        let airportCode =
          segment?.flightSegment?.departureAirport?.locationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response?.data?.data?.at(0)?.airport_name;
        setairport_name(data);
      } catch (error) { }
    };
    const fetchDataArr = async () => {
      try {
        let airportCode = segment?.flightSegment?.arrivalAirport?.locationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        setArrairport_name(data);
      } catch (error) { }
    };
    fetchDataDep();
    fetchDataArr();
  }, []);
  return (
    <div key={inx}>
      <div className="row align-items-center">
        <div className="col-2">
          <div>
            {inx === 0 && (
              <h6 className="py-2 fw-bolder ps-2">{`Flight - ${index + 1}`}</h6>
            )}
          </div>
          <img
            src={`/NEW_LOGO/${segment?.flightSegment?.operatingAirline ? segment?.flightSegment?.operatingAirline?.code : segment?.flightSegment?.airline?.code}.png`}
            alt="logo"
            width={70}
            height={70}
          />
          <h6 className="mt-3 ms-2">
            {segment?.flightSegment?.operatingAirline ? segment?.flightSegment?.operatingAirline?.code : segment?.flightSegment?.airline?.code}{" "}
            {segment?.flightSegment?.flightNumber}
          </h6>

          <h6 className="mt-2 ps-2" style={{ whiteSpace: "nowrap" }}>
            Operated by : {segment?.flightSegment?.operatingAirline ? segment?.flightSegment?.operatingAirline?.code : segment?.flightSegment?.airline?.code}
          </h6>
        </div>
        <div className="col-10 d-flex justify-content-between align-items-center">
          <div className="col-5 d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div className="">
              <h6>
                {moment(
                  new Date(
                    segment?.flightSegment?.departureDateTime?.split("T")[0]
                  )
                ).format("DD-MMM-YY")}
              </h6>
              <h6 className="my-3">
                {segment?.flightSegment?.departureDateTime
                  ?.split("T")[1]
                  ?.slice(0, 5)}
              </h6>
              <h6>
                {segment?.flightSegment?.departureAirport?.locationCode} -{" "}
                {airport_name}
              </h6>
            </div>
          </div>

          <div className="col-2 d-flex flex-column align-items-start">
            <div className="d-flex justify-content-center">
              <MdFlight className="color_primary fs-5 rotate-right" />
              <h6 className="text-center">Trip Time</h6>
            </div>
            <div className="border_rounded mb-2" />
            <h6 className="text-center ms-4">
              {timeDuration(
                segment?.flightSegment?.departureDateTime,
                segment?.flightSegment?.arrivalDateTime
              )}
            </h6>
          </div>
          <div className="col-5 d-flex align-items-center ps-4">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(
                  new Date(
                    segment?.flightSegment?.arrivalDateTime?.split("T")[0]
                  )
                ).format("DD-MMM-YY")}
              </h6>
              <h6 className="my-3">
                {segment?.flightSegment?.arrivalDateTime
                  ?.split("T")[1]
                  ?.slice(0, 5)}
              </h6>
              <h6>
                {segment?.flightSegment?.arrivalAirport?.locationCode}-
                {arr_airport_name}
              </h6>
            </div>
          </div>
        </div>
      </div>
      {inx < segmentArray.length - 1 && (
        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <div className="border_rounded w-25" />
          <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
            <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
            <h6 className="pt-2">
              Layover time:{" "}
              <span className="fs-5">
                {" "}
                {timeDuration(
                  segment?.flightSegment?.arrivalDateTime,
                  segmentArray[inx + 1]?.flightSegment?.departureDateTime
                )}
              </span>
            </h6>
          </div>
          <div className="border_rounded w-25" />
        </div>
      )}
    </div>
  );
};

export default HititSegments;
