import React from "react";
import Table from "react-bootstrap/esm/Table";

const HotelSummary = ({ values, setFieldValue }) => {
  const isPkr = values.hotel_info?.at(0).cur_label === "PKR" ? true : false;
  const currCode = values.hotel_info?.at(0).cur_label;
  const ROE = values.hotel_info?.at(0).roe;

  //find total amount
  const totalAmount = () => {
    let supplier = values.hotel_info?.reduce(
      (sum, passenger) => sum + Number(passenger.pkr_pay),
      0
    );
    let agent = values.hotel_info?.reduce(
      (sum, passenger) => sum + Number(passenger.pkr_rec),
      0
    );
    return { supplier_total: supplier, agent_total: agent };
  };

  const discPsf = totalAmount().supplier_total - totalAmount().agent_total;

  return (
    <div className="manual_invoice_footer p-4 px-0 pt-5">
      <div className="row">
        <div className="col-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={6}>
                  <h5 className="text-center py-1">Hotel Summary</h5>
                </th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th colSpan={2}>
                  <h6>Agent</h6>
                </th>
                <th colSpan={2}>
                  <h6>Supplier</h6>
                </th>
              </tr>
              <tr>
                <th></th>
                <th style={{ minWidth: "3rem" }}></th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && currCode}</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && currCode}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hotel Value</td>
                <td></td>
                <td>{totalAmount().agent_total}</td>
                <td>{!isPkr ? totalAmount().agent_total / ROE : null}</td>
                <td>{totalAmount().supplier_total}</td>
                <td>{!isPkr ? totalAmount().supplier_total / ROE : null}</td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Total</td>
                <td></td>
                <td>{totalAmount().agent_total}</td>
                <td>{!isPkr ? totalAmount().agent_total / ROE : null}</td>
                <td>{totalAmount().supplier_total}</td>
                <td>{!isPkr ? totalAmount().supplier_total / ROE : null}</td>
              </tr>
            </tbody>
          </Table>
        </div>

        {/* Discount PSF  */}
        <div className="col-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={6}>
                  <h5 className="text-center py-1">Discount / PSF</h5>
                </th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th colSpan={2}>
                  <h6>Agent</h6>
                </th>
                <th colSpan={2}>
                  <h6>Supplier</h6>
                </th>
              </tr>
              <tr>
                <th></th>
                <th style={{ minWidth: "3rem" }}></th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && currCode}</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>PKR</h6>
                </th>
                <th className="fixed_width_column_ins">
                  <h6>{!isPkr && currCode}</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PSF</td>
                <td></td>
                <td>{discPsf < 0 ? Math.abs(discPsf) : null}</td>
                <td>
                  {!isPkr && discPsf < 0 ? Math.abs(discPsf) / ROE : null}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>DISC</td>
                <td></td>
                <td>{discPsf > 0 ? discPsf : null}</td>
                <td>{!isPkr && discPsf > 0 ? discPsf / ROE : null}</td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Total</td>
                <td></td>
                <td>{Math.abs(discPsf)}</td>
                <td>{!isPkr ? Math.abs(discPsf) / ROE : null}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </div>

        {/* total section */}
        <div className="col-4">
          <Table bordered hover>
            <thead>
              <tr>
                <th colSpan={4}>
                  <h5 className="text-center py-1">Total Summary(PKR)</h5>
                </th>
              </tr>
              <tr>
                <th className="w-25"></th>
                <th className="w-25"></th>
                <th className="w-25">
                  <h6>Agent</h6>{" "}
                </th>
                <th className="w-25">
                  <h6>Supplier</h6>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Total Value</td>
                <td style={{ minWidth: "2rem" }}></td>
                <td>{totalAmount().agent_total}</td>
                <td>{totalAmount().supplier_total}</td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td className="py-3"></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Total</td>
                <td></td>
                <td>{totalAmount().agent_total}</td>
                <td>{totalAmount().supplier_total}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default HotelSummary;
