import moment from 'moment'
import React from 'react'
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from 'react-icons/md';
import { useDispatch } from 'react-redux';

const AirsialSegments = ({ airsial, formatDuration }) => {
    const dispatch = useDispatch();
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;

    const getCity = (loc) => {
        let city = loc?.split("-")[2]?.toUpperCase();
        return city;
    };
    return (
        <div>
            <div className="d-flex align-items-center py-4 border-bottom">
                <div className="col-2">
                    <div className="">
                        <div className="text-center">
                            <img
                                src={"/NEW_LOGO/PF.png"}
                                alt="airsial"
                                width={90}
                                height={90}
                            />
                            <div className="pt-3">
                                <span>{airsial?.outbound?.FLIGHT_NO}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightTakeoff size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(airsial?.outbound?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    <h6 className="my-3">{airsial?.outbound?.DEPARTURE_TIME}</h6>
                                </h6>
                                <h6>
                                    {airsial?.outbound?.ORGN} -{" "}
                                    {getCity(locDeparture)}
                                </h6>
                            </div>
                        </div>

                        <div>
                            <h6 className="text-center">
                                {formatDuration(airsial?.outbound?.DURATION)}
                            </h6>
                            <div className="border_rounded mb-2" />
                            <h6 className="mx-3">
                                Non-Stop
                            </h6>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightLand size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(airsial?.outbound?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    <h6 className="my-3">{airsial?.outbound?.ARRIVAL_TIME}</h6>
                                </h6>
                                <h6>
                                    {airsial?.outbound?.DEST} -{" "}
                                    {getCity(locArrival)}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center py-4">
                <div className="col-2">
                    <div className="text-center">
                        <img
                            src={"/NEW_LOGO/PF.png"}
                            alt="airsial"
                            width={90}
                            height={90}
                        />
                        <div className="pt-3">
                            <span>{airsial?.inbound?.FLIGHT_NO}</span>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightTakeoff size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(airsial?.inbound?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    <h6 className="my-3">{airsial?.inbound?.DEPARTURE_TIME}</h6>
                                </h6>
                                <h6>
                                    {airsial?.inbound?.ORGN} -{" "}
                                    {getCity(locArrival)}
                                </h6>
                            </div>
                        </div>

                        <div>
                            <h6 className="text-center">
                                {formatDuration(airsial?.inbound?.DURATION)}
                            </h6>
                            <div className="border_rounded mb-2" />
                            <h6 className="mx-3">
                                Non-Stop
                            </h6>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightLand size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(airsial?.inbound?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    <h6 className="my-3">{airsial?.inbound?.ARRIVAL_TIME}</h6>
                                </h6>
                                <h6>
                                    {airsial?.inbound?.DEST} -{" "}
                                    {getCity(locDeparture)}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirsialSegments
