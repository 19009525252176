import React from 'react';
import ResolveRequests from '../components/Setup/ResolveRequests'

const ResolvedRequests = () => {
  return (
    <div className=''>
      <ResolveRequests />
    </div>
  )
}

export default ResolvedRequests