import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpandFlightDetails,
  setNewArrivalDate,
  setNewDepDate,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import moment from "moment";
import { MdFlightLand, MdFlightTakeoff } from "react-icons/md";
function ChangeAvailabiltyDate() {
  const dispatch = useDispatch();
  const { newArrivalDate, newDepDate, selectedFlight } = useSelector(
    (state) => state.FlightSearch
  );

  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  );

  return (
    <div>
      {selectedFlight ? (
        <button
          className="button_style"
          onClick={() => {
            dispatch(setselectedFlight(null));
            dispatch(setExpandFlightDetails(null));
          }}
        >
          Change Flight
        </button>
      ) : (
        <div className="d-flex gap-2">
          <div className="rounded-2 shadow-sm bg-white p-2 px-3">
            <h6>
              {" "}
              <MdFlightTakeoff size={25} /> Outbound
            </h6>
            <button
              className="button_style me-2"
              onClick={() => {
                if (newDepDate) {
                  let parts = newDepDate?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);
                  let newDate = new Date(year, month, day);
                  newDate.setDate(newDate.getDate() - 1);
                  dispatch(setNewDepDate(moment(newDate).format("DD-MM-YYYY")));
                } else {
                  let parts = localsearchValues?.departingOn?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);

                  let date = new Date(year, month, day);
                  date.setDate(date.getDate() - 1);
                  dispatch(setNewDepDate(moment(date).format("DD-MM-YYYY")));
                }
              }}
            >
              Previous Date
            </button>
            <button
              className="button_style"
              onClick={() => {
                if (newDepDate) {
                  let parts = newDepDate?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);
                  let newDate = new Date(year, month, day);
                  newDate.setDate(newDate.getDate() + 1);
                  dispatch(setNewDepDate(moment(newDate).format("DD-MM-YYYY")));
                } else {
                  let parts = localsearchValues?.departingOn?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);

                  let date = new Date(year, month, day);
                  date.setDate(date.getDate() + 1);
                  dispatch(setNewDepDate(moment(date).format("DD-MM-YYYY")));
                }
              }}
            >
              Next Date
            </button>
          </div>
          <div className="rounded-2 shadow-sm bg-white p-2 px-3">
            <h6>
              <MdFlightLand size={25} /> Inbound
            </h6>
            <button
              className="button_style me-2"
              onClick={() => {
                if (newArrivalDate) {
                  let parts = newArrivalDate?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);
                  let newDate = new Date(year, month, day);
                  newDate.setDate(newDate.getDate() - 1);
                  dispatch(
                    setNewArrivalDate(moment(newDate).format("DD-MM-YYYY"))
                  );
                } else {
                  let parts = localsearchValues?.ReturningOn?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);

                  let date = new Date(year, month, day);
                  date.setDate(date.getDate() - 1);
                  dispatch(
                    setNewArrivalDate(moment(date).format("DD-MM-YYYY"))
                  );
                }
              }}
            >
              Previous Date
            </button>
            <button
              className="button_style"
              onClick={() => {
                if (newArrivalDate) {
                  let parts = newArrivalDate?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);
                  let newDate = new Date(year, month, day);
                  newDate.setDate(newDate.getDate() + 1);
                  dispatch(
                    setNewArrivalDate(moment(newDate).format("DD-MM-YYYY"))
                  );
                } else {
                  let parts = localsearchValues?.ReturningOn?.split("-");
                  let day = parseInt(parts[0], 10);
                  let month = parseInt(parts[1], 10) - 1;
                  let year = parseInt(parts[2], 10);

                  let date = new Date(year, month, day);
                  date.setDate(date.getDate() + 1);
                  dispatch(
                    setNewArrivalDate(moment(date).format("DD-MM-YYYY"))
                  );
                }
              }}
            >
              Next Date
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChangeAvailabiltyDate;
