import React, { useState } from "react";
import Fare from "./Fare";
import PassangerInfo from "./PassangerInfo";
import FlightInfo from "./FlightInfo";
import { Button } from "@mui/material";
import "./importTab.css";

const NewPnr = ({ values, setFieldValue, FieldArray }) => {
  const [InputGroup, setInputGroup] = useState("passanger");
  return (
    <div style={{ marginTop: 40 }}>
      <div className="row">
        <div className="d-flex justify-content-center py-3 pt-2 align-items-center progress_bar-register">
          <div className="d-none d-md-flex justify-content-between align-items-center w-100 progress_bar-register-cont">
            <div
              className=" d-flex flex-column align-items-center pb-3"
              style={{ cursor: "pointer" }}
              onClick={() => setInputGroup("passanger")}
            >
              <div
                className={`progress_bar-register rounded-circle ${
                  InputGroup === "passanger" ? "primarycolor" : "secondarycolor"
                }  text-white px-3 py-2 h4`}
              >
                1
              </div>

              <h6
                className={`${
                  InputGroup === "passanger" ? "primarytext" : "secodarytext"
                }`}
              >
                Passanger Information
              </h6>
            </div>
            <div
              className="d-flex flex-column align-items-center pb-3"
              style={{ cursor: "pointer" }}
              onClick={() => setInputGroup("flight")}
            >
              <div
                className={`progress_bar-register rounded-circle ${
                  InputGroup === "flight" ? "primarycolor" : "secondarycolor"
                } text-white px-3 py-2 h4`}
              >
                2
              </div>

              <h6
                className={`${
                  InputGroup === "flight" ? "primarytext" : "secodarytext"
                }`}
              >
                Flight Information
              </h6>
            </div>
            <div
              className="d-flex flex-column align-items-center pb-3"
              style={{ cursor: "pointer" }}
              onClick={() => setInputGroup("fare")}
            >
              <div
                className={`progress_bar-register rounded-circle ${
                  InputGroup === "fare" ? "primarycolor" : "secondarycolor"
                } text-white px-3 py-2 h4`}
              >
                3
              </div>

              <h6
                className={`${
                  InputGroup === "fare" ? "primarytext" : "secodarytext"
                } `}
              >
                Fare Information
              </h6>
            </div>
          </div>
        </div>
        <div className="inputs_group">
          {InputGroup === "passanger" && (
            <PassangerInfo
              values={values}
              setFieldValue={setFieldValue}
              FieldArray={FieldArray}
            />
          )}
          {InputGroup === "flight" && (
            <FlightInfo
              values={values}
              setFieldValue={setFieldValue}
              FieldArray={FieldArray}
            />
          )}
          {InputGroup === "fare" && (
            <Fare
              values={values}
              setFieldValue={setFieldValue}
              FieldArray={FieldArray}
            />
          )}
        </div>
        <div className="d-flex justify-content-center align-items-center py-5 px-3 pe-4 pb-0">
          <div className="d-md-none">
            <>
              {InputGroup === "passanger" && (
                <span
                  onClick={() => setInputGroup("flight")}
                  className={`${
                    InputGroup === "passanger" ? "btn-primary" : "btn-secondary"
                  } px-2 py-2`}
                  style={{ cursor: "pointer" }}
                >
                  Continue
                </span>
              )}
              {InputGroup === "flight" && (
                <>
                  <span
                    onClick={() => setInputGroup("passanger")}
                    className={`btn-primary px-2 py-2 me-4 `}
                    style={{ cursor: "pointer" }}
                  >
                    Previous
                  </span>
                  {InputGroup === "pessenger" ? (
                    <span
                      onClick={() => setInputGroup("flight")}
                      className={`btn-primary px-2 py-2`}
                      style={{ cursor: "pointer" }}
                    >
                      Continue
                    </span>
                  ) : (
                    <span
                      onClick={() => setInputGroup("fare")}
                      className={`btn-primary px-2 py-2`}
                      style={{ cursor: "pointer" }}
                    >
                      Continue
                    </span>
                  )}
                </>
              )}
            </>
            {InputGroup === "fare" && (
              <>
                <Button variant="contained">Generate Booking</Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPnr;
