
import React, { useState } from "react";


const OriginList = ({ origanList, setFieldValue, name, setdrp }) => {
  const array = [...origanList];
  return (
    <div className="airport_search_drp">
      {array?.length > 0 ? (
        array?.map((item) => {
          console.log("item", item);
          return (
            <div
              key={item.code}
              className="airport_search_drp_item"
              onClick={() => {
                let value = `${item.code}-${item.airport_name}-${item?.city}`;
                setFieldValue(name, value?.toUpperCase());
                setdrp("");
              }}
            >
              <div style={{ fontSize: 16 }}>
                {item.code} - {item.airport_name}
              </div>
            </div>
          );
        })
      ) : (
        <div className="airport_search_drp_item">No Record Found.</div>
      )}
    </div>
  );
};
export default OriginList