import React, { useEffect, useState } from "react";
import { options } from "../../../../Utils/ColumnOptions";
import MUIDataTable from "mui-datatables";
import { Box } from "@mui/material";
import { formatDate } from "../../../../Utils/dataFormate";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetHistoryCheckBook } from "../../../../redux/features/Finance/Account/BankBook/ChequeBookSlice";

const ExpandableRowTable = () => {
  const [receivedData, setReceivedData] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
 const {historyChequeBook } = useSelector(
   (state) => state?.chequeBook
 );  
 console.log("historyChequeBook", historyChequeBook);
  const bank_id = location?.state?.bankid;
  const chqId=location?.state?.chqId
  


  const chqHistory = historyChequeBook?.filter(
    (chqh, i) => chqh?.chq_book_id === chqId
  );
  useEffect(() => {
    const data = localStorage.getItem("historyCheckbookdata");
    dispatch(asyncGetHistoryCheckBook(bank_id ));
    if (data) {
      setReceivedData(JSON.parse(data));
      localStorage.removeItem("historyCheckbookdata");
    }
  }, [dispatch]);
console.log("chqHistory", chqHistory);
  /********************Table columns ******** */
  const columns = [
    {
      label: "Date",
      name: "chq_serial_id",
      options: {
        customBodyRender: (value) => {
          console.log("values", value);
          const chqHistoryRow = chqHistory
            ?.at(0)
            ?.cheque_serials?.find((id, index) => id?.chq_serial_id === value);

          return (
            <div>
              {chqHistoryRow?.acc_voucher?.saved_date?.split("T")?.at(0)}
            </div>
          );
        },
      },
    },
    {
      label: "Paid To",
      name: "chq_serial_id",
      options: {
        customBodyRender: (value) => {
          const chqHistoryRow = chqHistory
            ?.at(0)
            ?.cheque_serials?.find((id, index) => id?.chq_serial_id === value);
          console.log("chqHistoryRow", chqHistoryRow);
          return (
            <div>
              {
                chqHistoryRow?.acc_voucher?.acc_voucher_trans?.at(1)
                  ?.acc_account?.acc_title
              }
            </div>
          );
        },
      },
    },
    {
      label: "Cheque Number",
      name: "chq_numbers",
    },
    {
      label: "Payment Voucher",
      name: "chq_serial_id",
      options: {
        customBodyRender: (value) => {
          const chqHistoryRow = chqHistory
            ?.at(0)
            ?.cheque_serials?.find((id, index) => id?.chq_serial_id === value);
          return <div>{chqHistoryRow?.acc_voucher?.voucher_no}</div>;
        },
      },
    },
    {
      label: "Status",
      name: "chq_status",
      options: {
        customBodyRender: (value) => {
          return (
            <Box
              sx={{
                width: "10rem",
                padding: ".5rem 0",
                textAlign: "center",
                bgcolor: "red",
                color: "#ffffff",
                borderRadius: "4px",
                margin: "10px 0",
              }}
            >
              {value}
            </Box>
          );
        },
      },
    },
  ];

  return (
    <div className="shadow-sm rounded-2 col-11 mx-auto mt-5 bg-white">
      <p className="h4 otawix-heading box_title">Cheque Books History</p>
      <div className="p-5">
        <MUIDataTable
          title={""}
          columns={columns}
          data={chqHistory[0]?.cheque_serials}
          options={options}
        />
      </div>
    </div>
  );
};

export default ExpandableRowTable;
