import React, { useEffect, useState } from "react";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import HititSegments from "./HititSegments";

const HititConnectedFlighs = ({ segment, index, boundArray, timeDuration }) => {
  return (
    <div
      key={index}
      className={`${index === 0 ? "" : "pt-4 "} border-bottom pb-4`}
    >
      {segment?.map((seg, inx, segArray) => {
        return (
          <HititSegments
            segment={seg}
            index={index}
            inx={inx}
            segmentArray={segArray}
            timeDuration={timeDuration}
          />
        );
      })}
    </div>
  );
};

export default HititConnectedFlighs;
