import * as React from "react";

import { Form, Formik } from "formik";
import PaxDetail from "./PaxDetail";
import HotelInfo from "./HotelInfo";
import HotelSummary from "../Summary/HotelSummary";

function Hotel({ initValues, permission }) {
  const availableLimit =
    initValues?.agent?.credit_limit - initValues?.agent?.credit_used;

  //find total amount
  const totalAmount = (values) => {
    let agent = values.hotel_info?.reduce(
      (sum, passenger) => sum + Number(passenger.pkr_rec),
      0
    );
    return agent;
  };
  const initialValues = {
    ...initValues,
    title: "",
    f_name: "",
    l_name: "",
    agent_total: 0,
    hotel_info: [
      {
        hotel_name: "",
        room_type: "",
        check_in_date: "",
        check_out_date: "",
        currency: "",
        cur_label: "PKR",
        roe: 1.0,
        curr_pay: 0,
        curr_rec: 0,
        pkr_pay: 0,
        pkr_rec: 0,
      },
    ],
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, action) => {
        // console.log(values)
      }}
    >
      {({ values, setFieldValue, resetForm }) => {
        return (
          <Form>
            <div>
              {/* passenger for room section */}
              <div>
                <PaxDetail setFieldValue={setFieldValue} values={values} />
              </div>
              {/* hotel info */}
              <div>
                <HotelInfo setFieldValue={setFieldValue} values={values} />
              </div>
              <div>
                <HotelSummary values={values} />
              </div>
              <div className="my-4 d-flex justify-content-center align-item-center">
                <button
                  type="button"
                  className="button_style px-5 me-3"
                  onClick={() => resetForm()}
                  disabled={!permission}
                >
                  Clear Form
                </button>
                <button
                  type="submit"
                  className="button_style px-5"
                  disabled={
                    Number(availableLimit) < Number(totalAmount(values)) ||
                    !permission
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Hotel;
