import React, { useEffect, useState } from "react";
import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import DeleteModal from "../../DeleteModal";
import { useDispatch, useSelector } from "react-redux";

import { ChangeStatus } from "../../../Utils/ChangeStatus";
import {
  asyncDeletePKCredential,
  asyncGetPKApiCredentials,
  asyncGetSinglePKApiCred,
  asyncPostPKApiCredentials,
  asyncUpdatePKCredential,
  editPKCredentialNull,
} from "../../../redux/features/setupFeature/ApiCredential/PKCredentialSlice";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dropMenuProp } from "../../../Utils/MenuProps";

function Hitit() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //use selector
  const { PKCredentialsData, loading, editPKCredentials } = useSelector(
    (state) => state.pkCredential
  );
  const { newSupplier } = useSelector((state) => state.supplier);

  //use state
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);


  //use effect functions
  useEffect(() => {
    dispatch(asyncGetPKApiCredentials());
  }, [dispatch]);

  //  table columns
  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/pk-credential/status",
                      !value,
                      id,
                      "status"
                    );
                  }}
                />
                <label htmlFor={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "PK Agent Name",
      name: "agent_name",
    },

    {
      label: "PK Agent ID",
      name: "pk_agent_id",
    },
    {
      label: "PK Agent Password",
      name: "pk_agent_password",
    },
    {
      label: "PK MCO No",
      name: "pk_mco_no",
    },
    {
      label: "PK Agent Full Name",
      name: "pk_agent_fullname",
    },
    {
      label: "PK Agent Short Name",
      name: "pk_agent_shortname",
    },

    {
      label: "PK Service URL",
      name: "pk_service_url",
    },

    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncGetSinglePKApiCred(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowModel(true);
                  setRowData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-thin fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  //initital value
  const initialValues = {
    agent_name: editPKCredentials?.agent_name || "",
    pk_agent_id: editPKCredentials?.pk_agent_id || "",
    pk_agent_password: editPKCredentials?.pk_agent_password || "",
    pk_mco_no: editPKCredentials?.pk_mco_no || "",
    pk_agent_fullname: editPKCredentials?.pk_agent_fullname || "",
    pk_agent_shortname: editPKCredentials?.pk_agent_shortname || "",
    pk_service_url: editPKCredentials?.pk_service_url || "",
    ticket_status: editPKCredentials?.ticket_status || 1,
  };

  useEffect(() => {
    if (editPKCredentials) {
      const initialSupplier = newSupplier.find(supplier => supplier.supp_name === editPKCredentials.agent_name);
      setSelectedSupplier(initialSupplier || null);
    }
  }, [editPKCredentials, newSupplier]);

  //validation schemas
  const validationSchema = yup.object().shape({
    agent_name: yup.string().required("PK Agent Name is required"),
    pk_agent_id: yup.string().required("PK Agent Id is required"),
    pk_agent_password: yup.string().required("PK Agent Password is required"),
    pk_mco_no: yup.string().required("PK mco number is required"),
    pk_agent_fullname: yup.string().required("PK Full Name is required"),
    pk_agent_shortname: yup.string().required("PK Short Name is required"),
    pk_service_url: yup.string().required("PK Service URL is required"),
  });

  return (
    <div>
      <div>
        <div className="mx-5 my-5 bg-white rounded-2">
          <div className="d-flex align-items-center bg_primary text-white py-3">
            <div className="col-5">
              <span
                className="cursorpointer fs-5 w-content"
                onClick={() => {
                  dispatch(editPKCredentialNull());
                
                  navigate("/api-credentials");
                }}
              >
                <MdArrowBackIosNew /> Back
              </span>
            </div>
            <div className="box_title px-2">Add / Edit HITIT</div>
          </div>

          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let id = editPKCredentials?.id;
              if (editPKCredentials) {
                dispatch(asyncUpdatePKCredential({ id, values }));
                dispatch(editPKCredentialNull());
              } else {
                dispatch(asyncPostPKApiCredentials(values));
              }

              resetForm({});
              setSelectedSupplier(null)
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div className="row px-4 pb-3 align-items-start">
                  <div className="col-2 mt-4 h-5rem">
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={newSupplier}
                        getOptionLabel={(option) => option.supp_name}
                        value={selectedSupplier}
                        getOptionSelected={(option, value) => option.supp_name === value.supp_name}
                        onChange={(event, newValue) => {
                          setSelectedSupplier(newValue)
                          if (newValue) {
                            setFieldValue("supplier", newValue);
                            setFieldValue("agent_name", newValue.supp_name);
                          } else {
                            setFieldValue("supplier", "");
                            setFieldValue("agent_name", "");

                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="standard"
                            label="Select Agent Name"
                          />
                        )}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="agent_name"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="PK Agent ID"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pk_agent_id"
                        value={values.pk_agent_id}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("pk_agent_id", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pk_agent_id"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="PK Agent Password"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pk_agent_password"
                        value={values.pk_agent_password}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("pk_agent_password", value);
                        }}
                      />

                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pk_agent_password"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="PK MCO No"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pk_mco_no"
                        value={values.pk_mco_no}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("pk_mco_no", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pk_mco_no"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="PK Agent Full Name"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pk_agent_fullname"
                        value={values.pk_agent_fullname}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("pk_agent_fullname", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pk_agent_fullname"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="PK Agent Short Name"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pk_agent_shortname"
                        value={values.pk_agent_shortname}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("pk_agent_shortname", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pk_agent_shortname"
                      />
                    </div>
                  </div>
                  <div className="row px-4 pb-3 align-items-start">
                    <div className="col-2 mt-4">
                      <TextField
                        label="PK Service URL"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pk_service_url"
                        value={values.pk_service_url}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pk_service_url"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id={`ticket_status`}>
                          Ticket Status
                        </InputLabel>
                        <Select
                          id={`ticket_status`}
                          value={values.ticket_status}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("ticket_status", value);
                          }}
                          MenuProps={dropMenuProp}
                          label={"Ticket Status"}
                        >
                          <MenuItem value={1}>YES</MenuItem>
                          <MenuItem value={0}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-12 text-center mt-5 pb-3">
                      <button className="button_style px-5 me-3" type="submit">
                        {loading ? (
                          <BtnLoader />
                        ) : editPKCredentials ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </button>
                      {editPKCredentials && (
                        <button
                          type="button"
                          className="button_style px-5 bg-danger"
                          onClick={() => {
                            dispatch(editPKCredentialNull());
                            setSelectedSupplier(null);
                          }}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/*// Credensiol list  ////////*/}

        <div className="mx-5 shadow-sm rounded-2 my-4 bg-white">
          <p className="otawix-heading box_title py-2">
            HITIT Credentials List
          </p>
          <div className="p-4">
            <DeleteModal
              show={ShowModel}
              setShow={setShowModel}
              onClick={(e) => {
                dispatch(asyncDeletePKCredential(rowData[0]));
                setShowModel(false);
              }}
            />
            <MUIDataTable
              className="muidatatable"
              title={""}
              data={PKCredentialsData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hitit;
