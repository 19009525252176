import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  FlightBookingList: [],

  isLoading: false,
  error: null,
};

export const asyncPostFlightBookingList = createAsyncThunk(
  "asyncPostFlightBookingList/post",
  async (value, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/booking-main", value);

      // return await response.data.data
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const FlightBookingList = createSlice({
  name: "FlightBookingList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      asyncPostFlightBookingList.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncPostFlightBookingList.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
      }
    );
    builder.addCase(
      asyncPostFlightBookingList.rejected,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );
  },
});

export default FlightBookingList.reducer;
