import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  PKCredentialsData: [],
  editPKCredentials: null,
  loading: false,
  error: null,
};

// post ER API CREDENTIAL
export const asyncPostPKApiCredentials = createAsyncThunk(
  "asyncPostPKApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/pk-credential`, values);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get ER API CREDENTIAL
export const asyncGetPKApiCredentials = createAsyncThunk(
  "asyncGetPKApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/pk-credential`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get single ER API CREDENTIAL
export const asyncGetSinglePKApiCred = createAsyncThunk(
  "asyncGetSinglePKApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/pk-credential/${id}`);

      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// Update ER API CREDENTIAL
export const asyncUpdatePKCredential = createAsyncThunk(
  "asyncUpdatePKCredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/pk-credential/${id}`,
        values
      );
      dispatch(asyncGetPKApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Update ER API CREDENTIAL
export const asyncDeletePKCredential = createAsyncThunk(
  "asyncDeletePKCredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/pk-credential/${id}`);
      dispatch(asyncGetPKApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const PkApiCredentialSlice = createSlice({
  name: "PkApiCredentialSlice",
  initialState,
  reducers: {
    editPKCredentialNull: (state) => {
      state.editPKCredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post ER Api Credentials
    builder.addCase(asyncPostPKApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostPKApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.PKCredentialsData = [...state.PKCredentialsData, payload];
      }
    );
    builder.addCase(
      asyncPostPKApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get ER Api Credentials
    builder.addCase(asyncGetPKApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetPKApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.PKCredentialsData = payload;
      }
    );
    builder.addCase(asyncGetPKApiCredentials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Get single ER Api Credentials
    builder.addCase(asyncGetSinglePKApiCred.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSinglePKApiCred.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editPKCredentials = payload;
    });
    builder.addCase(asyncGetSinglePKApiCred.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update ER Api Credentials
    builder.addCase(asyncUpdatePKCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdatePKCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdatePKCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // DElete ER Api Credentials
    builder.addCase(asyncDeletePKCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeletePKCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeletePKCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { editPKCredentialNull } = PkApiCredentialSlice.actions;
export default PkApiCredentialSlice.reducer;
