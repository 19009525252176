import {
  Box,
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import AgentPaymentModel from "./AgentPaymentModel";
import { MdClose } from "react-icons/md";
import { asyncSearchAgencyData } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { asyncGetBankDetailData } from "../../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { asyncGetAgentGroup } from "../../../redux/features/setupRestPages/agentsSlice";

const AgentPayment = () => {
  const { searchAgency } = useSelector((state) => state.newAgency);
  const { bankDetails } = useSelector((state) => state?.bank_curr_roleSlice);
  const { agentGroup } = useSelector((state) => state.agentsCategories);

  const dispatch = useDispatch();
  const [agentDRopDwn, setagentDRopDwn] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [agentData, setAgentData] = useState();
  const [AgentCompData, setAgentCompData] = useState();

  const foundAgent = agentGroup.find(
    (agent) => agent.agent_grp_id === AgentCompData?.agent_grp_id
  );

  const agentId = searchAgency.find(
    (it) => it.agent_grp_id === AgentCompData?.agent_grp_id
  );

  useEffect(() => {
    dispatch(asyncGetBankDetailData());
    dispatch(asyncGetAgentGroup());
  }, []);

  const handleSubmit = async (values, actions) => {
    setAgentData({ ...values, id: agentId?.acc_id });
    setShowModal(!showModal);
  };

  const user = JSON.parse(localStorage.getItem("loginUser"));

  const selectAgent = user?.agent_id === 1 ? null : user?.agents_comp?.agent_name;
  const Cur_code = user?.agent_id === 1 ? "" : user?.agents_comp?.agent_group?.currency?.curr_code;
  const Agent_Acc_Id = user?.agent_id === 1 ? null : user?.agents_comp?.acc_id;


  const initialValues = {
    amount: "",
    agentName: selectAgent || "",
  };

  const basicSchema = yup.object().shape({
    amount: yup.number().required("Required"),
    agentName: yup.string().required("Required"),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={basicSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form
            className="col-sm-5 mx-auto pb-3 rounded-3"
            style={{ marginTop: "5rem", background: "#fff" }}
          >
            <p className="otawix-heading h4">Agent Payment</p>
            <div className="row mt-4 mx-auto  px-3 my-5">
              <div className="col-6">
                {user?.agent_id === 1 ? (
                  <Box flex={1}>
                    <FormControl
                      variant="standard"
                      className="w-100"
                      onClick={() => setagentDRopDwn(!agentDRopDwn)}
                    >
                      <InputLabel htmlFor="supplier">Select Agent</InputLabel>
                      <Input
                        variant="standard"
                        label="Select Agency"
                        autoComplete="off"
                        className="w-100"
                        disabled={user?.agent_id !== 1}
                        name="agentName"
                        value={values?.agentName}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("agentName", value);
                          dispatch(asyncSearchAgencyData(value));
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            {values?.agentName !== "" && (
                              <IconButton
                                onClick={() => {
                                  setFieldValue("agentName", "");
                                  setAgentCompData("");
                                }}
                              >
                                <MdClose />
                              </IconButton>
                            )}
                          </InputAdornment>
                        }
                      />
                    </FormControl>

                    {agentDRopDwn ? (
                      <ClickAwayListener
                        onClickAway={() => setagentDRopDwn(false)}
                      >
                        <div className="agent_dropdown">
                          {searchAgency?.length > 0 ? (
                            searchAgency?.map((item, index) => {
                              return (
                                <div
                                  className="p-3"
                                  onClick={(e) => {
                                    setFieldValue("agentName", item.agent_name);
                                    setAgentCompData(item);
                                    setagentDRopDwn(false);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  key={index}
                                >
                                  {item.agent_name}
                                </div>
                              );
                            })
                          ) : (
                            <div className="p-3">No record available.</div>
                          )}
                        </div>
                      </ClickAwayListener>
                    ) : null}
                  </Box>
                ) : (
                  <TextField
                    id="standard-basic"
                    variant="standard"
                    className="w-100"
                    label="Select Agent"
                    autoComplete="off"
                    inputProps={{ readOnly: true }}
                    name="amount"
                    value={values.agentName}
                  />
                )}
                <ErrorMessage
                  name="agentName"
                  component={"div"}
                  className="text-danger mt-1"
                />
              </div>

              <div className="col-6">
                <TextField
                  id="standard-basic"
                  label={`Enter Amount In ${
                    foundAgent ? foundAgent?.currency?.curr_code : "" || Cur_code
                  }`}
                  variant="standard"
                  className="w-100"
                  autoComplete="off"
                  inputProps={{ maxLength: 50 }}
                  name="amount"
                  value={values.amount}
                  onChange={(e) => {
                    setFieldValue("amount", e.target.value);
                  }}
                />
                <ErrorMessage
                  name="amount"
                  component={"div"}
                  className="text-danger mt-1"
                />
              </div>
            </div>
            <div className="col-sm-3 mx-auto">
              <button className="button_style" type="submit" style={{whiteSpace:"nowrap"}}>
                Confirm Payment
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <AgentPaymentModel
        show={showModal}
        setShow={setShowModal}
        agentData={agentData}
        Apidata={bankDetails}
        foundAgent={foundAgent}
        agentValues={initialValues}
        Cur_code={Cur_code}
        Agent_Acc_Id={Agent_Acc_Id}
      />
    </>
  );
};

export default AgentPayment;
