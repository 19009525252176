import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { options } from "../../../Utils/ColumnOptions";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { apiUrl } from "../../../Utils/configUrl";
import {
  asyncDeleteOurPartner,
  asyncEditOurPartner,
  asyncGetOurPartner,
  asyncPostOurPartner,
  asyncUpdateOurPartner,
  editpartner_null,
} from "../../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import BtnLoader from "../../AppForm/BtnLoader";
import { FieldArray, Form, Formik } from "formik";

const OurPartner = () => {
  const { editpartner, partnerStateData, loading } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  const SUPPORTED_FORMATS = ["image/png"];
  const dispatch = useDispatch();

  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // if the login user has permissions to add partner
  const postPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/post-partner"
  );
  // if the user has permission to edit partner
  const editPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/update-partner"
  );
  // if the user has permission to edit partner
  const deletePermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/update-parnter"
  );

  useEffect(() => {
    dispatch(asyncGetOurPartner());
  }, [dispatch]);

  /******************Initials values ********** */
  const initialValues = {
    partnerValue: [
      {
        image: "",
      },
    ],
  };
  const column = [
    {
      label: "id",
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <img
                style={{ height: 80, width: 80 }}
                src={`${apiUrl}/${value}`}
                alt={value}
              />
            </div>
          );
        },
      },
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {editPermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-success "
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                    dispatch(asyncEditOurPartner(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-thin fa-pencil" aria-hidden="true"></i>
                </button>
              ) : null}
              {deletePermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-danger ms-3"
                  onClick={() => {
                    dispatch(asyncDeleteOurPartner(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  const validationSchema = yup.object().shape({
    partnerValue: yup.array().of(
      yup.object().shape({
        image: editpartner
          ? yup
              .mixed()
              .nullable()
              .test(
                "format",
                "Only png image are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              )
          : yup
              .mixed()
              .nullable()
              .required("Image is required")
              .test(
                "format",
                "Only png image are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              ),
      })
    ),
  });

  return (
    <div className="pb-5">
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          values?.partnerValue.map((it) => formData.append("image", it.image));
          if (!editpartner) {
            for (const [key, value] of Object.entries(values)) {
              value.map((it) => dispatch(asyncPostOurPartner(it)));
            }
          } else {
            dispatch(asyncUpdateOurPartner({ formData, id: editpartner?.id }));
            dispatch(editpartner_null());
          }
          resetForm();
        }}
      >
        {({ values, setFieldValue, errors, resetForm }) => {
          return (
            <Form>
              <div className="col-7 mx-auto rounded-2 mt-5 bg-white">
                <p className="h4 otawix-heading box_title">Our Partner</p>
                <div className="p-5 ">
                  <FieldArray
                    name={`partnerValue`}
                    render={(arrayHelpers) => (
                      <div>
                        {values.partnerValue.map((val, index) => (
                          <div key={index}>
                            <div className="row">
                              <div className="col-4">
                                <TextField
                                  fullWidth
                                  name={`partnerValue.${index}.image`}
                                  variant="standard"
                                  label="Image (size 160*140ps only png)"
                                  type="file"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `partnerValue.${index}.image`,
                                      e.currentTarget.files[0]
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <p style={{ color: "red" }}>
                                  {errors?.partnerValue &&
                                    errors?.partnerValue[index]?.image}
                                </p>
                              </div>

                              <div className="col-4">
                                {index >= 1 && (
                                  <button
                                    type="button"
                                    className="button_style px-4 bg-danger "
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    - Partner
                                  </button>
                                )}
                                {index === 0 && (
                                  <button
                                    className="button_style px-4"
                                    type="button"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        image: "",
                                      })
                                    }
                                  >
                                    + Partner
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />

                  <div className="text-center mt-5">
                    {editpartner && (
                      <button
                        className="button_style px-5 bg-danger me-2"
                        onClick={(e) => {
                          dispatch(editpartner_null());
                          resetForm();
                        }}
                        type="button"
                      >
                        Cancel
                      </button>
                    )}
                    {editpartner ? (
                      <button className="button_style px-5 me-2" type="submit">
                        {loading ? <BtnLoader /> : "Update"}
                      </button>
                    ) : null}

                    {(postPermission !== undefined || user?.user_id === 1) &&
                    !editpartner ? (
                      <button className="button_style px-5 " type="submit">
                        {loading ? <BtnLoader /> : "Save"}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-7 mx-auto shadow bg-white rounded-2 mt-5">
                <p className="h4 otawix-heading box_title">Our Partner List</p>
                <div className="p-4">
                  <MUIDataTable
                    title={""}
                    columns={column}
                    options={options}
                    data={partnerStateData}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OurPartner;
