import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetOurPartner } from "../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import { apiUrl } from "../../Utils/configUrl";

function LogosConteiner() {
  const dispatch = useDispatch();
  const { partnerStateData } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  useEffect(() => {
    dispatch(asyncGetOurPartner());
  }, [dispatch]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="container mb-5">
      <div className="d-flex flex-column justify-content-center align-items-center my-5">
        <div className="h1 mt-5">PARTNERS</div>
      </div>
      <Carousel
        swipeable={false}
        draggable={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        arrows={false}
        showDots={false}
        autoPlay={true}
      >
        {partnerStateData?.map((item, index) => {
          return (
            <div className="bg-white rounded-2 shadow-sm mx-2" key={index}>
              <img
                src={`${apiUrl}/${item?.image}`}
                alt="partner"
                style={{
                  objectFit: "contain",
                }}
                className="w-100"
                height={140}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

export default LogosConteiner;
