import React from 'react';
import SupplierRulesComp from '../components/Setup/SupplierRules'

const SupplierRules = () => {
  return (
    <div className=''>
      <SupplierRulesComp />
    </div>
  )
}

export default SupplierRules