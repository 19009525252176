import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { menuProps } from "../../helper";
import { TwoDecimalPoint } from "../../../../../../Utils/FixedTwoDecimal";

function Currency({ setFieldValue, values, friend, index, auto_manual }) {
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);

  return (
    <div className="col-10">
      <div className="row">
        <div className="col-12">
          <FormControl variant="standard" fullWidth>
            <InputLabel id={`fareValues[${index}].currency`}>
              Currency
            </InputLabel>
            <Select
              id={`fareValues[${index}].currency`}
              value={friend.curr_label}
              name={`fareValues[${index}].currency`}
              disabled={auto_manual === 0 || auto_manual===1 }
              onChange={(e) => {
                let value = currencyData?.find(
                  ({ curr_code, curr_name }) =>
                    e.target.value === `${curr_code} : ${curr_name}`
                );
                let updatedFare = values.fareValues.map((it) => ({
                  ...it,
                  currency: value,
                  curr_label: e.target.value,
                  roe: value.roe,
                  eq_b_fare: "",
                  b_fare: "",
                  text_cur: [
                    {
                      tax_yq: "",
                      tax_amount: "",
                    },
                  ],
                  text_pkr: [
                    {
                      tax_pkr_yq: "",
                      tax_pkr_amount: "",
                    },
                  ],

                  tax_pkr: {
                    s_psf: "",
                    s_psf_per: "",
                    s_gst: "",
                    s_gst_per: "",
                    s_sp: "",
                    s_sp_per: "",
                    s_wht: "",
                    s_wht_per: "",
                    c_psf: "",
                    c_psf_per: "",
                    c_sp: "",
                    c_sp_per: "",
                    c_gst: "",
                    c_gst_per: "",
                    c_wht: "",
                    c_wht_per: "",
                  },
                  tax_cur: {
                    s_psf: "",
                    s_psf_per: "",
                    s_gst: "",
                    s_gst_per: "",
                    s_sp: "",
                    s_sp_per: "",
                    s_wht: "",
                    s_wht_per: "",
                    c_psf: "",
                    c_psf_per: "",
                    c_sp: "",
                    c_sp_per: "",
                    c_gst: "",
                    c_gst_per: "",
                    c_wht: "",
                    c_wht_per: "",
                  },
                }));
                setFieldValue(`fareValues`, updatedFare);
              }}
              label="Currency"
              MenuProps={menuProps}
              inputProps={{
                readOnly: index > 0,
              }}
            >
              {currencyData?.map((it) => (
                <MenuItem
                  value={`${it.curr_code} : ${it.curr_name}`}
                  key={it.curr_code}
                >
                  {`${it.curr_code} : ${it.curr_name}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-12 mt-3">
          <TextField
            value={values.fareValues[index].roe}
            id={`fareValues.[${index}].roe`}
            onChange={(e) => {
              let value = e.target.value;
              setFieldValue(`fareValues.[${index}].roe`, value);
              setFieldValue(
                `fareValues.[${index}].eq_b_fare`,
                TwoDecimalPoint(value * friend.b_fare)
              );
              // roe change for taxes
              setFieldValue(
                `fareValues.${index}.text_pkr.tax_pkr_amount`,
                TwoDecimalPoint(friend?.text_cur.tax_amount * value)
              );

              //roe change supplier cur
              setFieldValue(
                `fareValues.${index}.tax_pkr.s_psf`,
                value * values.fareValues[index].tax_cur.s_psf
              );
              setFieldValue(
                `fareValues.${index}.tax_pkr.s_gst`,
                value * values.fareValues[index].tax_cur.s_gst
              );
              setFieldValue(
                `fareValues.${index}.tax_pkr.s_sp`,
                value * values.fareValues[index].tax_cur.s_sp
              );
              setFieldValue(
                `fareValues.${index}.tax_pkr.s_wht`,
                value * values.fareValues[index].tax_cur.s_wht
              );

              // roe change agent cur
              setFieldValue(
                `fareValues.${index}.tax_pkr.c_psf`,
                value * values.fareValues[index].tax_cur.c_psf
              );
              setFieldValue(
                `fareValues.${index}.tax_pkr.c_gst`,
                value * values.fareValues[index].tax_cur.c_gst
              );
              setFieldValue(
                `fareValues.${index}.tax_pkr.c_sp`,
                value * values.fareValues[index].tax_cur.c_sp
              );
              setFieldValue(
                `fareValues.${index}.tax_pkr.c_wht`,
                value * values.fareValues[index].tax_cur.c_wht
              );
            }}
            label="ROE"
            variant="standard"
            type={"number"}
            className="w-100"
            inputProps={{
              readOnly: index > 0,
            }}
            disabled={auto_manual === 0}
          />
        </div>
        <div className="col-12 mt-3">
          <TextField
            value={friend.b_fare}
            id={`fareValues.[${index}].b_fare`}
            onChange={(e) => {
              const bFareValue = e.target.value;
              const roeValue = friend.roe;
              setFieldValue(`fareValues[${index}].b_fare`, bFareValue);
              setFieldValue(
                `fareValues[${index}].eq_b_fare`,
                TwoDecimalPoint(roeValue * bFareValue)
              );

              ///////////////

              if (friend?.currency?.curr_code !== "PKR") {
                //// currency fields
                setFieldValue(
                  `fareValues[${index}].tax_cur.s_psf`,
                  TwoDecimalPoint(
                    (friend?.tax_cur?.s_psf_per * bFareValue) / 100
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_cur.s_sp`,
                  TwoDecimalPoint(
                    (friend?.tax_cur?.s_sp_per * bFareValue) / 100
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_cur.c_psf`,
                  TwoDecimalPoint(
                    (friend?.tax_cur?.c_psf_per * bFareValue) / 100
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_cur.c_sp`,
                  TwoDecimalPoint(
                    (friend?.tax_cur?.c_sp_per * bFareValue) / 100
                  )
                );
                // currency gst wht
                setFieldValue(
                  `fareValues[${index}].tax_cur.s_gst`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.s_psf_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.s_gst_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_cur.s_wht`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.s_sp_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.s_wht_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_cur.c_gst`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.c_psf_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.c_gst_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_cur.c_wht`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.c_sp_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.c_wht_per
                  )
                );

                // pkr
                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_psf`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.s_psf_per * bFareValue) / 100) * roeValue
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_sp`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.s_sp_per * bFareValue) / 100) * roeValue
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_psf`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.c_psf_per * bFareValue) / 100) * roeValue
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_sp`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.c_sp_per * bFareValue) / 100) * roeValue
                  )
                );
                //
                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_gst`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.s_psf_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.s_gst_per *
                      roeValue
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_wht`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.s_sp_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.s_wht_per *
                      roeValue
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_gst`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.c_psf_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.c_gst_per *
                      roeValue
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_wht`,
                  TwoDecimalPoint(
                    ((friend?.tax_cur?.c_sp_per * bFareValue) / 100 / 100) *
                      friend?.tax_cur?.c_wht_per *
                      roeValue
                  )
                );
              } else {
                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_psf`,
                  TwoDecimalPoint(
                    (bFareValue / 100) * friend?.tax_pkr?.s_psf_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_sp`,
                  TwoDecimalPoint(
                    (bFareValue / 100) * friend?.tax_pkr?.s_sp_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_psf`,
                  TwoDecimalPoint(
                    (bFareValue / 100) * friend?.tax_pkr?.c_psf_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_sp`,
                  TwoDecimalPoint(
                    (bFareValue / 100) * friend?.tax_pkr?.c_sp_per
                  )
                );

                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_gst`,
                  TwoDecimalPoint(
                    (((bFareValue / 100) * friend?.tax_pkr?.s_psf_per) / 100) *
                      friend?.tax_pkr?.s_gst_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.s_wht`,
                  TwoDecimalPoint(
                    (((bFareValue / 100) * friend?.tax_pkr?.s_sp_per) / 100) *
                      friend?.tax_pkr?.s_wht_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_gst`,
                  TwoDecimalPoint(
                    (((bFareValue / 100) * friend?.tax_pkr?.c_psf_per) / 100) *
                      friend?.tax_pkr?.c_gst_per
                  )
                );
                setFieldValue(
                  `fareValues[${index}].tax_pkr.c_wht`,
                  TwoDecimalPoint(
                    (((bFareValue / 100) * friend?.tax_pkr?.c_sp_per) / 100) *
                      friend?.tax_pkr?.c_wht_per
                  )
                );
              }
            }}
            label="B.fare"
            variant="standard"
            type={"number"}
            className="w-100"
            disabled={auto_manual === 0}
          />
        </div>
        <div className="col-12 mt-3">
          <TextField
            value={friend.eq_b_fare}
            name={`fareValues.[${index}].eq_b_fare`}
            id={`fareValues.[${index}].eq_b_fare`}
            onChange={(e) => {
              setFieldValue(`fareValues.[${index}].eq_b_fare`, e.target.value);
            }}
            label="EQ B.Fare"
            variant="standard"
            type={"number"}
            className="w-100"
            disabled={auto_manual === 0}
          />
        </div>
      </div>
    </div>
  );
}

export default Currency;
