import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import countries from "../Countries.json";
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Select,
} from "@mui/material";
import { MdAdd } from "react-icons/md";
import { HiMinusSm } from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { asyncSearchAgencyData } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

function PreviewInsurance({
  isActiveTaxPayer,
  setisActiveTaxPayer,
  setFieldValue,
  values,
  FieldArray,
  handleClose,
  handleSubmit
}) {
  const dispatch = useDispatch();
  const [countriesDropdown, setCountriesDropdown] = useState([]);
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const { searchAgency } = useSelector((state) => state.newAgency);

  useEffect(() => {
    let countriesArray = countries.filter(
      (country) =>
        country?.Category === "WORLDWIDE" || country?.Category === "SCENGEN"
    );
    setCountriesDropdown(
      countriesArray?.map((coun) => {
        return coun?.Countries;
      })
    );
  }, []);

  const arry = [
    "7 days",
    "10 days",
    "15 days",
    "21 days",
    "31 days",
    "62 days",
    "92 days",
    "180 days",
    "360 days",
    "2 years",
  ];

  return (
    <div className="Preview_Container ">
      <p className="otawix-heading header_preview_model h4 ">
        Insurance Information
      </p>
      {/********************************  plan information ************************** */}
      <div className="px-3">
        <div className="my-4 border-bottom pb-3">
          <h5>Plan</h5>
          <div className="row">
            <div className="d-flex" style={{ marginTop: 12, marginBottom: 10 }}>
              <div style={{ width: "30%", marginRight: 10 }}>
                <div className="insurance_boxes p-2">
                  <h5>Covid Protection:</h5>
                  <div className="d-flex flex-column flex-md-row py-4 justify-content-around">
                    <label class="form-control">
                      <input
                        type="radio"
                        id="covid_protection"
                        name="covid_protection"
                        value={values?.covid_protection}
                        checked={values.covid_protection === "Covid Covered"}
                        onChange={() =>
                          setFieldValue("covid_protection", "Covid Covered")
                        }
                      />
                      <h6>Covid Covered</h6>
                    </label>
                    <label class="form-control ms-4">
                      <input
                        type="radio"
                        name="covid_protection"
                        value={values?.covid_protection}
                        id="covid_protection"
                        checked={
                          values.covid_protection === "Covid Not Covered"
                        }
                        onChange={() =>
                          setFieldValue("covid_protection", "Covid Not Covered")
                        }
                      />
                      <h6>Covid Not Covered</h6>
                    </label>
                  </div>
                </div>
              </div>
              <div style={{ width: "20%", marginRight: 10 }}>
                <div className="insurance_boxes p-2">
                  <h5>Insurer:</h5>
                  <div className="d-flex py-4 justify-content-around">
                    <label className="form-control">
                      <input
                        type="radio"
                        name="insurer"
                        value="Single"
                        checked={values.insurer === "Single"}
                        onChange={() => setFieldValue("insurer", "Single")}
                      />
                      <h6>Single</h6>
                    </label>
                    <label className="form-control">
                      <input
                        type="radio"
                        name="insurer"
                        value="Family"
                        checked={values.insurer === "Family"}
                        onChange={() => setFieldValue("insurer", "Family")}
                      />
                      <h6>Family</h6>
                    </label>
                  </div>
                </div>
              </div>

              <div style={{ width: "50%" }}>
                <div className="insurance_boxes p-2">
                  <h5>Destination:</h5>
                  <div className="d-flex justify-content-around py-4">
                    <label className="form-control">
                      <input
                        type="radio"
                        name="destination"
                        value="Single World wide"
                        checked={values.destination === "Single World wide"}
                        onChange={() =>
                          setFieldValue("destination", "Single World wide")
                        }
                      />
                      <h6>Single World wide</h6>
                    </label>
                    <label className="form-control">
                      <input
                        type="radio"
                        name="destination"
                        value="Rest Of The world"
                        checked={values.destination === "Rest Of The world"}
                        onChange={() =>
                          setFieldValue("destination", "Rest Of The world")
                        }
                      />
                      <h6>Rest Of The world</h6>
                    </label>
                    <label className="form-control">
                      <input
                        type="radio"
                        name="destination"
                        value="Schengen Countries"
                        checked={values.destination === "Schengen Countries"}
                        onChange={() =>
                          setFieldValue("destination", "Schengen Countries")
                        }
                      />
                      <h6>Schengen Countries</h6>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mt-2 mb-4">
              <div className="row align-items-end">
                <div className="col-sm-2 mb-3 mb-md-0">
                  <ReactDatePicker
                    minDate={new Date()}
                    monthsShown={1}
                    className="date_picker mt-4"
                    selected={values.ins_date}
                    placeholderText="From Date"
                    onChange={(date) => setFieldValue("ins_date", date)}
                  />
                </div>
                <div className="col-6 col-sm-2">
                  <FormControl variant="standard" className="w-100" sx={{}}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Days
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={values?.days}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("days", value);
                      }}
                      label="Select Days"
                    >
                      {arry?.map((list) => (
                        <MenuItem
                          value={list}
                          sx={{ m: 1, bgcolor: "#fff" }}
                          key={list}
                        >
                          {list}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-6 col-sm-2">
                  <FormControl variant="standard" className="w-100" sx={{}}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Country
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                          },
                        },
                      }}
                      value={values?.country}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("country", value);
                      }}
                      label="Select Country"
                    >
                      {countriesDropdown?.map((list) => (
                        <MenuItem
                          value={list}
                          sx={{ m: 1, bgcolor: "#fff" }}
                          key={list}
                        >
                          {list}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/********************************  Traveler ************************** */}
        <div className="border-bottom pb-5">
          <h5>Traveler:</h5>

          <div className="row align-items-end" style={{ marginBottom: 10 }}>
            <div className="col-6 col-sm-3">
              <TextField
                variant="standard"
                label="Name Of Insured"
                value={values?.insure_name}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("insure_name", value.toUpperCase());
                }}
                className="w-100"
              />
            </div>
            <div className="col-6 col-sm-3">
              <ReactDatePicker
                className="date_picker"
                maxDate={new Date()}
                placeholderText="Date Of Birth"
                monthsShown={1}
                selected={values?.dob}
                onChange={(date) => {
                  setFieldValue("dob", date);
                }}
              />
            </div>
            <div className="col-6 col-sm-3 mt-3 mt-md-0">
              <TextField
                variant="standard"
                label="Email"
                value={values.email}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("email", value);
                }}
                className="w-100"
              />
            </div>
            <div className="col-6 col-sm-3 mt-3 mt-md-0">
              <TextField
                variant="standard"
                label="Passport No"
                value={values.pass_no}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("pass_no", value);
                }}
                className="w-100"
              />
            </div>
          </div>

          <div className="row align-items-end" style={{ marginBottom: 10 }}>
            <div className="col-6 col-sm-3 mt-3">
              <TextField
                variant="standard"
                label="CNIC"
                type="number"
                value={values.cnic}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("cnic", value);
                }}
                className="w-100"
              />
            </div>
            <div className="col-6 col-sm-3 mt-3">
              <TextField
                variant="standard"
                label="Address"
                value={values.address}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setFieldValue("address", value);
                }}
                className="w-100"
              />
            </div>
            <div className="col-6 col-sm-3 mt-3">
              <TextField
                variant="standard"
                label="Contact"
                type="number"
                value={values.contact}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("contact", value);
                }}
                className="w-100"
              />
            </div>
            <div className="col-6 col-sm-3 checkbox-rect">
              <input
                type="checkbox"
                id="all_select"
                checked={isActiveTaxPayer}
                onChange={(e) => {
                  setisActiveTaxPayer(true);
                }}
                style={{ marginRight: "8px" }}
              />
              {!isActiveTaxPayer && (
                <label for="all_select">Active Tax Payer</label>
              )}
              {isActiveTaxPayer && (
                <TextField
                  variant="standard"
                  label="Enter NTN No"
                  value={values.ntn_No}
                  onChange={(e) => {
                    const value = e.target.value;
                    setFieldValue("ntn_No", value);
                  }}
                  className="w-100"
                />
              )}
            </div>
          </div>

          {values?.insurer === "Family" && (
            <div className="pt-4">
              <h4>Family</h4>
              <hr />
              <div className="row">
                <div className="col-sm-2">
                  <TextField
                    variant="standard"
                    label="Spouse"
                    value={values.spouse}
                    onChange={(e) => {
                      const value = e.target.value.toUpperCase();
                      setFieldValue("spouse", value);
                    }}
                    className="w-100"
                  />
                </div>
                <div className="col-sm-1">
                  <TextField
                    variant="standard"
                    label="Age"
                    type="number"
                    value={values.age}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFieldValue("age", value);
                    }}
                    className="w-100"
                  />
                </div>
                <div className="col-sm-2">
                  <TextField
                    variant="standard"
                    label="Passport No"
                    value={values.passport_No}
                    onChange={(e) => {
                      const value = e.target.value.toUpperCase();
                      setFieldValue("passport_No", value);
                    }}
                    className="w-100"
                  />
                </div>
              </div>
              <FieldArray
                name="family"
                render={(arrayHelpers) => (
                  <div>
                    {values?.family && values?.family.length > 0
                      ? values?.family?.map((familymember, index) => (
                          <div className="row mt-5">
                            <div className="col-sm-2">
                              <TextField
                                variant="standard"
                                label={`Children ${index + 1}`}
                                value={values.family[index].child}
                                onChange={(e) => {
                                  const value = e.target.value.toUpperCase();
                                  setFieldValue(`family.${index}.child`, value);
                                }}
                                className="w-100"
                              />
                            </div>
                            <div className="col-sm-1">
                              <TextField
                                variant="standard"
                                label="Age"
                                type="number"
                                value={values.family[index].chl_age}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  setFieldValue(
                                    `family.${index}.chl_age`,
                                    value
                                  );
                                }}
                                className="w-100"
                              />
                            </div>

                            <div className="col-sm-2">
                              <TextField
                                variant="standard"
                                label="Passport No"
                                value={values.family[index].chl_passport_no}
                                onChange={(e) => {
                                  const value = e.target.value.toUpperCase();
                                  setFieldValue(
                                    `family.${index}.chl_passport_no`,
                                    value
                                  );
                                }}
                                className="w-100"
                              />
                            </div>
                            <div className="col-sm-2">
                              {index === 0 ? (
                                <button
                                  type="button"
                                  className="add-btn"
                                  disabled={values?.family?.length === 5}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      child: "",
                                      chl_age: "",
                                      chl_passport_no: "",
                                    })
                                  }
                                >
                                  <MdAdd size={25} />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="sub-btn btn-danger me-2 me-md-2"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <HiMinusSm size={25} />
                                </button>
                              )}
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                )}
              />
            </div>
          )}
        </div>

        {/********************************  beneficiary ************************** */}
        <div className="my-3  py-2 pb-3">
          <h5>Beneficiary:</h5>

          <div className="row align-items-end">
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Beneficiary"
                className="w-100"
                value={values.beneficiary}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setFieldValue("beneficiary", value);
                }}
              />
            </div>
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Relationship"
                className="w-100"
                value={values.relationship}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setFieldValue("relationship", value);
                }}
              />
            </div>
            <div className="col-6 col-md-3">
              <FormControl
                variant="standard"
                className="w-100"
                onClick={() => setagentDRopDwn(!agentDRopDwn)}
              >
                <InputLabel htmlFor="supplier">Select Agent</InputLabel>
                <Input
                  variant="standard"
                  label="Select Agent"
                  autoComplete="off"
                  className="w-100"
                  name="agent"
                  value={values?.select_agent}
                  onChange={(e) => {
                    let value = e.target.value;
                    setFieldValue("select_agent", value);
                    dispatch(asyncSearchAgencyData(value));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {values?.select_agent !== "" && (
                        <IconButton
                          onClick={() => {
                            setFieldValue("select_agent", "");
                          }}
                        >
                          <MdClose />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>

              {agentDRopDwn ? (
                <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
                  <div className="agent_dropdown">
                    {searchAgency?.length > 0 ? (
                      searchAgency?.map((item, index) => {
                        return (
                          <div
                            className="p-3"
                            onClick={(e) => {
                              setFieldValue("select_agent", item.agent_name);
                              setagentDRopDwn(false);
                            }}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            {item?.agent_name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="p-3">No record available.</div>
                    )}
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/***********************  foooter ************************************ */}

      <div className="d-flex justify-content-end pe-4 footer_preview_model">
        <div className="mb-2"> 
          <button
            onClick={handleClose}
            type="button"
            className="removeflt-btn bg-danger me-4"
          >
            Close
          </button>
          <button type="submit" className="addflt-btn" onClick={handleSubmit}>
            Issue
          </button>
        </div>
      </div>
    </div>
  );
}

export default PreviewInsurance;
