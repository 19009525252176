import React, { useEffect, useState } from "react";
import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import DeleteModal from "../../DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteErCredential,
  asyncGetERApiCredentials,
  asyncGetSingleERApiCred,
  asyncPostERApiCredentials,
  asyncUpdateErCredential,
  editErCredentialNull,
} from "../../../redux/features/setupFeature/ApiCredential/ErCredential";
import { ChangeStatus } from "../../../Utils/ChangeStatus";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dropMenuProp } from "../../../Utils/MenuProps";

function SereneAir() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //use selector
  const { ERCredentialsData, loading, editErCredentials } = useSelector(
    (state) => state.erCredential
  );
  console.log("editErCredentials",editErCredentials, "  ", "ERCredentialsData",ERCredentialsData);
  const { newSupplier } = useSelector((state) => state.supplier);
  //use state
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  //use effect functions
  useEffect(() => {
    dispatch(asyncGetERApiCredentials());
  }, [dispatch]);

  //  table columns
  // const columns = [
  //   {
  //     name: "id",
  //     options: {
  //       display: false,
  //     },
  //   },
  //   {
  //     label: "Status",
  //     name: "status",
  //     options: {
  //       customBodyRender: (value, tableMeta, updateValue) => {
  //         const id = tableMeta.rowData[0];
  //         return (
  //           <>
  //             <div className="checkbox-rect">
  //               <input
  //                 value={value}
  //                 checked={value}
  //                 type="checkbox"
  //                 id={"with_sar_" + id}
  //                 onChange={(event) => {
  //                   updateValue(!value);
  //                   ChangeStatus(
  //                     "/api/v1/b2b/er-api-credential/status",
  //                     !value,
  //                     id,
  //                     "status"
  //                   );
  //                 }}
  //               />
  //               <label htmlFor={"with_sar_" + id} className="h6"></label>
  //             </div>
  //           </>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     label: "ER Agent Name",
  //     name: "agent_name",
  //   },
  //   {
  //     label: "ER Agent ID",
  //     name: "er_agent_id",
  //   },
  //   {
  //     label: "ER Agent Password",
  //     name: "er_agent_password",
  //   },
  //   {
  //     label: "ER Service URL",
  //     name: "er_service_url",
  //   },

  //   {
  //     name: "Actions",
  //     options: {
  //       customBodyRender: (value, tableMeta, updateValue) => {
  //         let id = tableMeta.rowData[0];
  //         return (
  //           <div className="">
  //             <button
  //               className="btn-success rounded edit-delete-icon-button me-1"
  //               onClick={() => {
  //                 window.scroll({ top: 0 });
  //                 dispatch(asyncGetSingleERApiCred(id));
  //               }}
  //             >
  //               <i className="fa fa-thin fa-pencil"></i>
  //             </button>
  //             <button
  //               className="btn-danger rounded edit-delete-icon-button me-1"
  //               onClick={() => {
  //                 setShowModel(true);
  //                 setRowData(tableMeta.rowData);
  //               }}
  //             >
  //               <i className="fa fa-thin fa-trash"></i>
  //             </button>
  //           </div>
  //         );
  //       },
  //     },
  //   },
  // ];
  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"status_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/er-api-credential/status",
                      !value,
                      id,
                      "status"
                    );
                  }}
                />
                <label htmlFor={"status_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Agent Name",
      name: "agent_name",
    },
    {
      label: "Signature Key",
      name: "signature_key",
    },
    {
      label: "Auth User ID",
      name: "auth_user_id",
    },
    {
      label: "Auth App ID",
      name: "auth_app_id",
    },
    {
      label: "User",
      name: "user",
    },
    {
      label: "Token",
      name: "token",
    },
    {
      label: "Ticket Department ID",
      name: "tkt_dep_id",
    },
    {
      label: "Service URL",
      name: "url",
    },
    {
      label: "Version",
      name: "version",
    },
    {
      label: "Language",
      name: "language",
    },
    {
      label: "Client IP",
      name: "client_ip",
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncGetSingleERApiCred(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowModel(true);
                  setRowData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-thin fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];
  

  //initital value
  const initialValues = {
    agent_name: editErCredentials?.agent_name || "",
    // er_agent_id: editErCredentials?.er_agent_id || "",
    // er_agent_password: editErCredentials?.er_agent_password || "",
    // er_service_url: editErCredentials?.er_service_url || "",
    // ticket_status: editErCredentials?.ticket_status || 1,

    auth_user_id:editErCredentials?.auth_user_id || "",
    auth_app_id:editErCredentials?.auth_app_id ||"",
    user: editErCredentials?.user || "",
    token: editErCredentials?.token || "",
    tkt_dep_id: editErCredentials?.tkt_dep_id || "",
    url: editErCredentials?.url || "",
    version: editErCredentials?.version || "",
    language: editErCredentials?.language || "",
    client_ip: editErCredentials?.client_ip || "",
    signature_key: editErCredentials?.signature_key || "",
    status: editErCredentials?.status || 0,

  };

  useEffect(() => {
    if (editErCredentials) {
      const initialSupplier = newSupplier.find(supplier => supplier.supp_name === editErCredentials.agent_name);
      setSelectedSupplier(initialSupplier || null);
    }
  }, [editErCredentials, newSupplier]);


  //validation schemas
  const validationSchema = yup.object().shape({
    agent_name: yup.string().required("ER Agent Name is required"),
    // er_agent_id: yup.string().required("ER Agent Id is required"),
    // er_agent_password: yup.string().required("ER Agent Password is required"),
    // er_service_url: yup.string().required("ER Service URL is required"),
    auth_user_id: yup.string().required("Auth User ID is required"),
    auth_app_id: yup.string().required("Auth App ID is required"),
    user: yup.string().required("User is required"),
    token: yup.string().required("Token is required"),
    tkt_dep_id: yup.string().required("Ticket Department ID is required"),
    url: yup.string().url("Enter a valid URL").required("URL is required"),
    version: yup.string().required("Version is required"),
    language: yup.string().required("Language is required"),
    client_ip: yup.string().required("Client IP is required"),
    signature_key: yup.string().required("signature_key is required"),
    status: yup.number().oneOf([0, 1]).required("Status is required"),
  });

  return (
    <div>
      <div>
        <div className="mx-5 my-5 bg-white rounded-2">
          <div className="d-flex align-items-center bg_primary text-white py-3">
            <div className="col-5">
              <span
                className="cursorpointer fs-5 w-content"
                onClick={() => {
                  dispatch(editErCredentialNull());
                 
                  navigate("/api-credentials");
                }}
              >
                <MdArrowBackIosNew /> Back
              </span>
            </div>
            <div className="box_title px-2">Add / Edit Serene Air</div>
          </div>

          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let id = editErCredentials?.id;
              if (editErCredentials) {
                dispatch(asyncUpdateErCredential({ id, values }));
                dispatch(editErCredentialNull());
              } else {
                dispatch(asyncPostERApiCredentials(values));
              }
              resetForm({});
              setSelectedSupplier(null);
            }}
          >
            {({ values, setFieldValue }) => {
              console.log("values",values)
              return (
                <Form>
                  <div className="row px-4 pb-3 align-items-start pt-2">
                  <div className="col-2 mt-4 h-5rem">
                      <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={newSupplier}
                        getOptionLabel={(option) => option.supp_name}
                        value={selectedSupplier}
                        getOptionSelected={(option, value) => option.supp_name === value.supp_name}
                        onChange={(event, newValue) => {
                          console.log("newvalue",newValue)
                          setSelectedSupplier(newValue)
                          if (newValue) {
                            setFieldValue("supplier", newValue);
                            setFieldValue("agent_name", newValue.supp_name);
                          } else {
                            setFieldValue("supplier", "");
                            setFieldValue("agent_name", "");

                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="standard"
                            label="Select Agent Name"
                          />
                        )}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="agent_name"
                      />
                    </div>
                    {/* <div className="col-2 mt-4">
            <TextField
              label="Agent Name"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="agent_name"
              value={values.agent_name}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("agent_name", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="agent_name"
            />
          </div> */}
                    <div className="col-2 mt-4">
                    
            <TextField
              label="Auth User ID"
              variant="standard"
              className="w-100"
              
              autoComplete="off"
              name="auth_user_id"
              value={values.auth_user_id}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("auth_user_id", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="auth_user_id"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="Auth App ID"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="auth_app_id"
              value={values.auth_app_id}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("auth_app_id", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="auth_app_id"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="User"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="user"
              value={values.user}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("user", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="user"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="Token"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="token"
              value={values.token}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("token", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="token"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="Ticket Department ID"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="tkt_dep_id"
              value={values.tkt_dep_id}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("tkt_dep_id", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="tkt_dep_id"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="URL"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="url"
              value={values.url}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("url", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="url"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="Version"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="version"
              value={values.version}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("version", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="version"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="Language"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="language"
              value={values.language}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("language", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="language"
            />
          </div>

          <div className="col-2 mt-4">
            <TextField
              label="Client IP"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="client_ip"
              value={values.client_ip}
              onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("client_ip", value);
                        }}
            />
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="client_ip"
            />
          </div>
        

          <div className="col-2 mt-4">
            <FormControl variant="standard" className="w-100">
              <InputLabel>Ticket Status</InputLabel>
              <Select
                name="status"
                value={values.status}
                onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("status", value);
                        }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
            <ErrorMessage
              component={"div"}
              className="mt-2 text-danger"
              name="status"
            />
          </div>
          <div className="col-2 mt-4">
                    
                    <TextField
                      label="Signature Key"
                      variant="standard"
                      className="w-100"
                      
                      autoComplete="off"
                      name="signature_key"
                      value={values.signature_key}
                      onChange={(e) => {
                                  let value = e.target.value;
                                  setFieldValue("signature_key", value);
                                }}
                    />
                    <ErrorMessage
                      component={"div"}
                      className="mt-2 text-danger"
                      name="signature_key"
                    />
                  </div>
                    {/* <div className="col-2 mt-4">
                      <TextField
                        label="ER Agent ID"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="er_agent_id"
                        value={values.er_agent_id}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("er_agent_id", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="er_agent_id"
                      />
                    </div> */}
                    {/* <div className="col-2 mt-4">
                      <TextField
                        label="ER Agent Password"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="er_agent_password"
                        value={values.er_agent_password}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("er_agent_password", value);
                        }}
                      />

                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="er_agent_password"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="ER Service URL"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="er_service_url"
                        value={values.er_service_url}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="er_service_url"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id={`ticket_status`}>
                          Ticket Status
                        </InputLabel>
                        <Select
                          id={`ticket_status`}
                          value={values.ticket_status}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("ticket_status", value);
                          }}
                          MenuProps={dropMenuProp}
                          label={"Ticket Status"}
                        >
                          <MenuItem value={1}>YES</MenuItem>
                          <MenuItem value={0}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}

                    <div className="col-12 text-center mt-5 pb-3">
                      <button className="button_style px-5 me-3" type="submit">
                        {loading ? (
                          <BtnLoader />
                        ) : editErCredentials ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </button>
                      {editErCredentials && (
                        <button
                          type="button"
                          className="button_style px-5 bg-danger"
                          onClick={() => {dispatch(editErCredentialNull());
                            setSelectedSupplier(null);
                          }}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/*// Credensiol list  ////////*/}

        <div className="mx-5 shadow-sm rounded-2 my-4 bg-white">
          <p className="otawix-heading box_title py-2">
            Serene Air Credentials List
          </p>
          <div className="p-4">
            <DeleteModal
              show={ShowModel}
              setShow={setShowModel}
              onClick={(e) => {
                dispatch(asyncDeleteErCredential(rowData[0]));
                setShowModel(false);
              }}
            />
            <MUIDataTable
              className="muidatatable"
              title={""}
              data={ERCredentialsData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SereneAir;
