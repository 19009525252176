import React from 'react'
import AgencyDocument from "../components/Agency/AgencyDocument";

const Document = () => {
  return (
    <div className='Full_height'>
      <AgencyDocument />
    </div>
  )
}

export default Document