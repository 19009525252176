import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import { TextField } from "@mui/material";
import Flydubai from "../../assets/images/Flydubai.jpeg";
import Economy from "../../assets/images/Economy.png";
import Plan from "../../assets/images/plan.png";
import WaitingTime from "../../assets/images/waiting_time.png";
import { IoIosAirplane } from "react-icons/io";
import Accordion from "react-bootstrap/Accordion";

const ResolveRequests = () => {
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const columns = [
    {
      name: "req_no",
      label: "Number",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "date",
      label: "Date/Time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "office_name",
      label: "Office Name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "booking_id",
      label: "Booking Id",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "Call_center_staff",
      label: "Call Center Staff",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_type",
      label: "Request type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-secondary rounded edit-delete-icon-button"
              onClick={() => {
                setShowModal(true);
                setRowData(data[tableMeta.rowIndex]);
              }}
              style={{ fontSize: "20px" }}
            >
              <i class="fa fa-thin fa-pen-to-square"></i>
            </button>
          );
        },
      },
    },
  ];

  const data = [
    {
      req_no: "FLT-103",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      booking_id: "276",
      Call_center_staff: "Demo Two",
      status: "New",
      req_type: "Issue",
      description: "Issue Ticket Description",
      action: "Generate Booking",
    },
    {
      req_no: "FLT-103",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      booking_id: "276",
      Call_center_staff: "Demo Two",
      status: "New",
      req_type: "Issue",
      description: "Issue Ticket Description",
      action: "Generate Booking",
    },
    {
      req_no: "FLT-103",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      booking_id: "276",
      Call_center_staff: "Demo Two",
      status: "New",
      req_type: "Issue",
      description: "Issue Ticket Description",
      action: "Generate Booking",
    },
    {
      req_no: "FLT-103",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      booking_id: "276",
      Call_center_staff: "Demo Two",
      status: "New",
      req_type: "Issue",
      description: "Issue Ticket Description",
      action: "Generate Booking",
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <div className="col-12 col-sm-10 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Call Center Resolved Requests</p>
      <div className="col-12 mt-5 px-4">
        <RowInfoModal
          show={showModal}
          setShow={setShowModal}
          rowData={rowData}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Call Center Resolved Requests"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default ResolveRequests;

const RowInfoModal = ({ show, setShow, rowData }) => {
  const [radio, setRadio] = useState("company");

  const data = [
    {
      req_no: "FLT-103",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      booking_id: "276",
      Call_center_staff: "Demo Two",
      status: "New",
      req_type: "Issue",
      description: "Issue Ticket Description",
      action: "Take this Booking",
    },
  ];
  const columns = [
    {
      name: "req_no",
      label: "Booking ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Booking status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Document status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Paid by Agency",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  };
  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      centered
      dialogClassName="modal-xl modal-full"
    >
      <Modal.Header>
        <Modal.Title>Call Center Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 d-flex">
            <div className="col-sm-3 px-3 mt-3">
              <TextField
                label="Company Name"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-3 px-3 mt-3">
              <TextField
                label="Approve Limit"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-3 px-3 mt-3">
              <TextField
                label="Used Limit"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-3 px-3 mt-3">
              <TextField
                label="Available Limit"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-sm-3 px-3 mt-5">
              <TextField
                label="Request Number"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-3 px-3 mt-5">
              <TextField
                label="Request Time"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-3 px-3 mt-5">
              <TextField
                label="Type"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-3 px-3 mt-5">
              <TextField
                label="Supplier"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Agent Description"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Supplier Description"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Agent Notes"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Supplier Notes"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="row px-3">
            <div className="col-sm-6 px-3 mt-5">
              <MUIDataTable
                className="muidatatable"
                title={"Booked Items"}
                data={data}
                columns={columns}
                options={options}
              />
            </div>
            <div className="col-sm-6 px-3 mt-5">
              <MUIDataTable
                className="muidatatable"
                title={"Passengers"}
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </div>
          <div className="col-12 my-5 px-3">
            <div className="d-flex justify-content-between align-items-center border px-5 py-2">
              <div className="text-center">
                <img src={Flydubai} className="mb-2" />
                <div>FZ 329</div>
              </div>
              <div className="d-flex col-sm-8 justify-content-between align-items-center">
                <div className="flight-from-div">
                  <div>25 Nov 2022 07:50 PM</div>
                  <div>DXB-Dubai, United Arab Emirates - International</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <img
                        src={Economy}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span> 0</span>
                    </div>
                    <div>
                      <img
                        src={Plan}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span>Equipment 7200</span>
                    </div>
                    <div>
                      <img
                        src={WaitingTime}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span> -6h 0m</span>
                    </div>
                  </div>
                </div>
                <div>
                  <i
                    class="fa fa-thin fa-arrow-right"
                    style={{
                      color: "#4d496b",
                      fontFamily: "FontAwesome",
                    }}
                  ></i>
                </div>
                <div className="flight-from-div">
                  <div>25 Nov 2022 07:50 PM</div>
                  <div>DXB-Dubai, United Arab Emirates - International</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
