import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: false,
  colors: {
    primary: "#457a7c",
    secondary: "#355a6b",
  },
};

export const NotificationSlice = createSlice({
  name: "Utility",
  initialState,

  reducers: {
    notificationMsg: (state, { payload }) => {
      state.message = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { notificationMsg } = NotificationSlice.actions;
export default NotificationSlice.reducer;
