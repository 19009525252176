import React from 'react'
import Header from '../components/Common/Header'
import Payment from '../components/Setup/Payments/MakePayment'
import Footer from '../components/Footer'

const MakePayment = () => {
  return (
    <div className='Full_height'>
      <Payment />
    </div>
  )
}

export default MakePayment