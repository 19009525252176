import React from 'react';
import Header from '../components/Common/Header'
import TransferSupplierComp from '../components/Setup/Umrah/TransferSupplier'
import Footer from '../components/Footer'

const UmrahTransferSupplier = () => {
  return (
    <div className=''>
      <TransferSupplierComp />
    </div>
  )
}

export default UmrahTransferSupplier