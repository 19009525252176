import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTPForm, NODEURL } from "../ApiConfig";

const initialState = {
  agentCategories: [],
  editCategories_id: null,

  agentGroup: [],
  agentGroupSubPageData: [],
  editAgentGroup: null,

  agentsData: [],
  editAgentsData: null,
  editAgentDataNull: null,
  adminAgencyData: {},

  message: null,
  loading: false,
  error: null,
};

// i have added
// (1) agent Catagory
// (2) agent Group
// (3) agent
// pages in this slice from Setup section

// ******************************************* AGENT CATAAGORIES SECTION *************************************************************************

// get asyncAgentCategoriesData
export const asyncAgentCategoriesData = createAsyncThunk(
  "asyncAgentCategoriesData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/buyer_grp_cat");
      // console.log(response?.data?.data)
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);
// posting asyncPostCategoriesData
export const asyncPostCategoriesData = createAsyncThunk(
  "asyncPostCategoriesData/post",
  async (name, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(
        "/api/v1/b2b/buyer_grp_cat/add_buyer_group_cat",
        { name }
      );
      // console.log(response?.data?.data)
      dispatch(asyncAgentCategoriesData());
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);
// Edit asyncEditCategoriesData
export const asyncEditCategoriesData = createAsyncThunk(
  "asyncEditCategoriesData/edit",
  async (rowId, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/buyer_grp_cat/edit_buyer_grp_cat`,
        {
          id: rowId,
        }
      );
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);
// post asyncEditCategoriesData
export const asyncUpdateCategoriesData = createAsyncThunk(
  "asyncUpdateCategoriesData/Update",
  async ({ id, name }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/buyer_grp_cat/update_buyer_grp_cat/`,
        {
          id: id,
          name: name,
        }
      );
      console.log(response?.data?.data);
      dispatch(asyncAgentCategoriesData());
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// ******************************************* AGENT GROUPS SECTION *************************************************************************

// get asyncGetAgentGroup
export const asyncGetAgentGroup = createAsyncThunk(
  "asyncGetAgentGroup/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/agent-group");
      // console.log(response?.data)
      return await response?.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// get asyncGetAgentSubPageData
export const asyncGetAgentSubPageData = createAsyncThunk(
  "asyncGetAgentSubPageData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        "/api/v1/b2b/buyergroup/add_buyer_group"
      );
      // console.log(response?.data)
      return await response?.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// Post asyncPostAgentGroup
export const asyncPostAgentGroup = createAsyncThunk(
  "asyncPostAgentGroup/post",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/agent-group", values);
      // console.log(response?.data)
      dispatch(asyncGetAgentGroup());
      // return await response?.data
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// edit asyncEditAgentgroup
export const asyncEditAgentgroup = createAsyncThunk(
  "asyncEditAgentgroup/get",
  async (id, { rejectWithValue }) => {
    console.log(id);
    try {
      const response = await NODEURL.get(`/api/v1/b2b/agent-group/${id}`);
      console.log(response.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// Post asyncUpdateAgentGroup
export const asyncUpdateAgentGroup = createAsyncThunk(
  "asyncUpdateAgentGroup/post",
  async ({ values, id }, { rejectWithValue, dispatch }) => {
    console.log(id);
    console.log(values);
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/agent-group/${id}`,
        values
      );
      // console.log(await response?.data)
      dispatch(asyncGetAgentGroup());
      // return await response?.data
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// ******************************************* AGENT FUNCTIONS SECTION *************************************************************************

// get asyncGetAgent
export const asyncGetAgentData = createAsyncThunk(
  "asyncGetAgentData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/new-agency");
      // console.log(await response.data)
      return await response.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// get Main agency (admin)
export const asyncGetAdminAgency = createAsyncThunk(
  "asyncGetAdminAgency/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/new-agency/1`);
      return await response.data.data;
    } catch (error) {
      // console.log(error);
      rejectWithValue(error);
    }
  }
);

// get asyncGetAgent
export const asyncEditAgentsData = createAsyncThunk(
  "asyncEditAgentsData/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/new-agency/${id}`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

// get asyncGetAgent
export const asyncUpdateMainAgency = createAsyncThunk(
  "asyncUpdateMainAgency/patch",
  async ({ id, formData }, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await HTTPForm.patch(
        `/api/v1/b2b/main-agency/${id}`,
        formData
      );
      dispatch(myMessage("success"));
      dispatch(asyncGetAgentData());
      return await response.data.data;
    } catch (error) {
      console.log(error);
      dispatch(myMessage("error"));
      rejectWithValue(error);
    }
  }
);

export const AgentSlice = createSlice({
  name: "agentCategories",
  initialState,
  reducers: {
    editCategoriesid_null(state) {
      state.editCategories_id = null;
    },
    editAgentGroupNull: (state) => {
      state.editAgentGroup = null;
    },
    editAgentsDataNull: (state) => {
      state.editAgentsData = null;
    },
    errorNull: (state) => {
      state.error = null;
    },
    myMessage: (state, { payload }) => {
      state.message = payload;
    },
  },
  extraReducers: (builder) => {
    // ******************************************* AGENT CATAAGORIES SECTION *************************************************************************

    //get agentCategories data
    builder.addCase(asyncAgentCategoriesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncAgentCategoriesData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.agentCategories = payload;
      }
    );
    builder.addCase(asyncAgentCategoriesData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    //post agentCategories data
    builder.addCase(asyncPostCategoriesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostCategoriesData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.agentCategories = payload;
    });
    builder.addCase(asyncPostCategoriesData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    //edit agentCategories data
    builder.addCase(asyncEditCategoriesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditCategoriesData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editCategories_id = payload;
    });
    builder.addCase(asyncEditCategoriesData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    //update agentCategories data
    builder.addCase(asyncUpdateCategoriesData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncUpdateCategoriesData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.agentCategories = payload;
      }
    );
    builder.addCase(
      asyncUpdateCategoriesData.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // ******************************************* AGENT GROUPS SECTION *************************************************************************

    // get agent groups data
    builder.addCase(asyncGetAgentGroup.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAgentGroup.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.agentGroup = payload;
    });
    builder.addCase(asyncGetAgentGroup.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get agent groups SubPage data
    builder.addCase(asyncGetAgentSubPageData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetAgentSubPageData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.agentGroupSubPageData = payload;
      }
    );
    builder.addCase(asyncGetAgentSubPageData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post agent groups data
    builder.addCase(asyncPostAgentGroup.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostAgentGroup.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.agentGroup = payload;
    });
    builder.addCase(asyncPostAgentGroup.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Edit agent groups data
    builder.addCase(asyncEditAgentgroup.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditAgentgroup.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editAgentGroup = payload;
    });
    builder.addCase(asyncEditAgentgroup.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // ******************************************* AGENT REDUCERS SECTION *************************************************************************
    // get agent Data
    builder.addCase(asyncGetAgentData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAgentData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.agentsData = payload;
    });
    builder.addCase(asyncGetAgentData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Edit agent Data
    builder.addCase(asyncEditAgentsData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditAgentsData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editAgentsData = payload;
    });
    builder.addCase(asyncEditAgentsData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // Update Main Agency
    builder.addCase(asyncUpdateMainAgency.pending, (state, { payload }) => {
      state.loading = true;
      // state.error = null;
    });
    builder.addCase(asyncUpdateMainAgency.fulfilled, (state, { payload }) => {
      state.loading = false;
      // state.error = null;
    });
    builder.addCase(asyncUpdateMainAgency.rejected, (state, { payload }) => {
      state.loading = false;
      // state.error = payload;
    });

    // Get Main Agency
    builder.addCase(asyncGetAdminAgency.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAdminAgency.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.adminAgencyData = payload;
    });
    builder.addCase(asyncGetAdminAgency.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const {
  editCategoriesid_null,
  editAgentGroupNull,
  editAgentsDataNull,
  errorNull,
  myMessage,
} = AgentSlice.actions;
export default AgentSlice.reducer;
