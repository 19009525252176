import React, { useEffect, useState } from "react";
import Tab from "./Tab";

const RequestTab = ({ data }) => {
  const [tab, setTab] = useState("Progress");
  return (
    <div className="d-flex flex-column gap-4 container py-4">
      {/* <div>
        {" "}
        <h4 className="theme-text-color">Call Center Requests</h4>
      </div> */}
      <div className="border d-flex ">
        {/* <spane
          className={
            tab === "Resolved"
              ? "bg_primary  text-white px-4 py-3 fs-6 cursor-text"
              : " px-4 py-3 fs-6 cursor-text"
          }
          onClick={() => setTab("Resolved")}
        >
          Resolved
        </spane> */}
        {/* <spane
          className={
            tab === "Progress"
              ? "bg_primary text-white px-4 py-3 fs-6 cursor-pointer"
              : " px-4 py-3 fs-6 cursor-pointer"
          }
          onClick={() => setTab("Progress")}
        >
          Progress
        </spane> */}
      </div>
      <Tab
        status="Progress"
        booking={data?.booking}
        data={
          tab === "Progress"
            ? data?.booking?.flight_requests?.filter(({ status }) => status === "Progress")
            : data?.booking?.flight_requests?.filter(({ status }) => status !== "Progress")
        }
        // data={
        //  data?.booking?.flight_requests?.filter(({ status }) => status === "Progress")
        // }
      />
    </div>
  );
};

export default RequestTab;
