import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAddFlshNewsData,
  asyncPostAddFlshNewsData,
  asyncDeleteflshnNewsData,
  asyncEditflashNewsData,
  asyncUpdateFlashNewsData,
  toggleEditId,
} from "../../../redux/features/promotion/AddFlashNewsSlice";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";
import DeleteModal from "../../DeleteModal";
import { options } from "../../../Utils/ColumnOptions";

const AddSliderText = () => {
  //usedispatch
  const dispatch = useDispatch();
  //useselector
  const { flashData, showModel, editflashId, loading } = useSelector(
    (state) => state.addFlashNews
  );
  console.log('flsssss',flashData);
  //usestate 
  const [modalData, setModalData] = useState([]);
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [EffectCall, setuseEffectCall] = useState(false);
  useEffect(() => {
    dispatch(asyncGetAddFlshNewsData());
  }, [editflashId]);

  //table colum
  const columns = [
    {
      name: "id",
      label: "id",
    },
    {
      name: "news",
      label: "Flash News",
    },
    {
      label: "Action",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <div className="d-flex">
              <button
                className="edit-delete-icon-button btn-success"
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncEditflashNewsData(id));
                }}

              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>

              <button
                className="edit-delete-icon-button btn-danger ms-2"
                onClick={() => {
                  setShowModel(true);
                  setRowData(id);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ]
  // initial values 
  const initialValues = {
    news: editflashId?.news || "",
  };
  // validation schema
  const validationSchema = yup.object().shape({
    news: yup.string().required("Add Flash News Required"),
  });

  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Flash News</p>
      <Formik
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const flash_news = values.news;
          if (editflashId) {
            dispatch(
              asyncUpdateFlashNewsData({
                flash_news, id: editflashId?.id
              })
            )
            dispatch(toggleEditId());

          } else {
            dispatch(asyncPostAddFlshNewsData(flash_news));
            resetForm()
          }
        }}
      >
        {({ values, setFieldValue, handleChange, errors }) => {
          return (
            <Form>
              <div className="col-12 px-4 d-flex flex-column justify-content-center">
                <div className="mx-auto  col-6 my-4 py-3 ">
                  <TextField
                    id="standard-basic"
                    label="Add Flash News"
                    value={values?.news}
                    variant="standard"
                    className="w-100"
                    autoComplete="off"
                    name="news"
                    onChange={(e) => {
                      setFieldValue("news", e.target.value.toUpperCase());
                    }}
                  />
                  <ErrorMessage
                    component={"div"}
                    className="text-danger mt-1"
                    name="news"
                  />
                </div>

                <div className="text-center">
                  <button
                    className="btn setup-btn"
                    type="submit"
                  // disabled={loading ? true : false}
                  >
                    {loading ? <BtnLoader /> : editflashId ? "Update" : "Save"}
                  </button>
                  {editflashId && (
                    <button
                      variant="danger modal-button"
                      className="setup-btn btn setup-btn ms-2"
                      onClick={(e) => {
                        dispatch(toggleEditId());
                      }}
                      style={{ background: "#dc3545", color: "#fff" }}
                    >
                      Cancel
                    </button>
                  )}
                </div>

              </div>
            </Form>
          );
        }}




      </Formik>
      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={ShowModel}
          setShow={setShowModel}
          onClick={(e) => {
            dispatch(asyncDeleteflshnNewsData(rowData));
            setShowModel(false);
          }}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Flash News Details"}
          data={flashData}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default AddSliderText;
