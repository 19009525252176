import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../../DeleteModal";
import { useDispatch, useSelector } from "react-redux";

import {
  asyncGetVisaSupplier,
  asyncPostVisaSupplier,
  asyncEditVisaSupplier,
  asyncUpdateVisaSupplier,
  asyncDeleteVisaSupplier,
  editVisaSupplierNull,
} from "../../../redux/features/umrah/VisaSupplierSlice";
import { toast } from "react-toastify";
import { options } from "../../../Utils/ColumnOptions";
import { Form, Formik } from "formik";

import * as yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";
import { appImgUrl } from "../../../Utils/configUrl";
import ErrorMessage from "../../AppForm/ErrorMessage";

const Supplier = () => {
  const dispatch = useDispatch();


  const { umrahVisaSupplierData, editVisaSupplier, loading } = useSelector(
    (state) => state.visaSupplier
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [rndState, setrndState] = useState();
  const [preview, setPreview] = useState();

  useEffect(() => {
    dispatch(asyncGetVisaSupplier());
    setrndState(new Date());
  }, [editVisaSupplier,dispatch]);

  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: "address",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contact",
      label: "contact no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "image",
      name: "logo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
              style={{
                height: 80,
                width: 120,
                left: 100,
                bottom: -70,
                zIndex: 120,
              }}
              alt=""
              src={`${appImgUrl}/umrahVisaSupplier/${value}`}
              className="table-row-image"
            />
          );
        },
      },
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setPreview(null);
                  dispatch(asyncEditVisaSupplier(id));
                  window.scroll({ top: 0 });
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(id);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const validationSchem = yup.object().shape({
    Supplier_id: yup.number().required("Supplier_id required"),
    contact: yup.number().required("contact required"),
    address: yup.string().required("address required"),
    logo: !editVisaSupplier?.logo && yup.string().required("logo required"),
  });

  const initialValues = {
    Supplier_id: editVisaSupplier
      ? editVisaSupplier?.Supplier_id
      : 0 || umrahVisaSupplierData?.VisaSupplier?.length
      ? umrahVisaSupplierData?.VisaSupplier[0].id
      : 0,
    contact: editVisaSupplier?.contact || "",
    previous_logo: editVisaSupplier?.logo || "",
    address: editVisaSupplier?.address || "",
    logo: "",
  };

  console.log(preview);

  return (
    <div className="col-12 col-sm-7 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Umrah Visa Supplier</p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchem}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, action) => {
          console.log(values);
          console.log(values.previous_logo);
          // console.log(Math.round(values.logo.size / 1024));
          if (editVisaSupplier) {
            const fd = new FormData();
            fd.append("id", editVisaSupplier?.id);
            fd.append("Supplier_id", values.Supplier_id);
            fd.append("contact", values.contact);
            fd.append("previous_logo", values.previous_logo);
            fd.append("address", values.address);
            values.logo && fd.append("logo", values.logo);
            dispatch(asyncUpdateVisaSupplier(fd));
            dispatch(editVisaSupplierNull());
            setrndState(new Date());
            setPreview(null);

          } else {
            const fd = new FormData();
            fd.append("Supplier_id", values.Supplier_id);
            fd.append("contact", values.contact);
            fd.append("address", values.address);
            fd.append("logo", values.logo);
            dispatch(asyncPostVisaSupplier(fd));
            action.resetForm({ values: "" });
            setrndState(new Date());
            setPreview(null);

          }

        }}
      >
        {({ values, handleChange, errors, setFieldValue }) => {
          return (
            <Form
              className="col-12 px-4 d-flex flex-column justify-content-center"
              method="POST"
            >
              <div className="col-12 mt-4 d-flex">
                <div className="col-5 me-5">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select supplier
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={values.Supplier_id}
                      onChange={handleChange}
                      name="Supplier_id"
                    >
                      {umrahVisaSupplierData?.VisaSupplier?.map((sup) => (
                        <MenuItem value={sup.id} key={sup.id}>
                          {sup.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage errors={errors && errors.Supplier_id} />
                  </FormControl>
                </div>
                <div className="col-sm-5 d-flex flex-column me-5">
                  <TextField
                    id="standard-basic"
                    label="Contact Number"
                    variant="standard"
                    className="col-sm-12"
                    name="contact"
                    value={values.contact}
                    onChange={handleChange}
                  />

                  <ErrorMessage errors={errors && errors.contact} />
                </div>
              </div>
              <div className="col-12 mt-4 d-flex">
                <div className="col-sm-5 d-flex flex-column me-5 position-relative">
                  {editVisaSupplier?.logo && (
                    <img
                      alt="edit_image"
                      type={"file"}
                      name="previous_logo"
                      accept="image/*"
                      src={`${appImgUrl}/umrahVisaSupplier/${editVisaSupplier?.logo}`}
                      style={{
                        height: 80,
                        width: 120,
                        left: 100,
                        bottom: -70,
                        zIndex: 120,
                      }}
                      className="position-absolute "
                    />
                  )}
                  {preview && (
                    <img
                      alt="prev_imgage"
                      src={preview}
                      style={{
                        height: 80,
                        width: 120,
                        left: 100,
                        bottom: -70,
                        zIndex: 120,
                      }}
                      className="position-absolute "
                    />
                  )}

                  <label htmlFor="" className="mb-2">
                    Upload Logo
                  </label>
                  <TextField
                    type={"file"}
                    key={rndState || ""}
                    name="logo"
                    accept="image/*"
                    onChange={(e) => {
                      const objectUrl = URL.createObjectURL(e.target.files[0]);
                      setPreview(objectUrl);
                      setFieldValue("logo", e.currentTarget.files[0]);
                    }}
                    variant="standard"
                    className="col-sm-12"
                  />
                  <ErrorMessage errors={errors && errors.logo} />
                </div>
                <div className="mb-2 col-sm-5">
                  <TextField
                    className="col-sm-12 mt-2"
                    name="address"
                    label="Address"
                    variant="standard"
                    value={values.address}
                    onChange={handleChange}
                  />
                  <ErrorMessage errors={errors && errors.address} />
                </div>
              </div>
              <div className="d-flex align-self-center gap-3">
                <button className="btn setup-btn mt-2" type="submit">
                  {loading ? (
                    <BtnLoader />
                  ) : editVisaSupplier ? (
                    "update"
                  ) : (
                    "save"
                  )}
                </button>
                {editVisaSupplier && (
                  <button
                    className="btn setup-btn mt-2 bg-danger"
                    type="submit"
                    onClick={() => dispatch(editVisaSupplierNull())}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          onClick={() => {
            setShowDeleteModal(false);
            dispatch(asyncDeleteVisaSupplier(modalData));
          }}
          rowId={modalData}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Umrah Visa Supplier Details"}
          data={umrahVisaSupplierData?.UmrahVisaSupplierModel}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Supplier;
