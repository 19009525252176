import React, { useState } from "react";

import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";

import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { asyncSearchAgencyData } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
const AgentPNR = ({ values, setFieldValue }) => {
  const arry = ["Travelport", "Sabre", "Hitit", "Airblue", "serene", "Airsial"];
  const dispatch = useDispatch();
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const { searchAgency } = useSelector((state) => state.newAgency);

  return (
    <Box display={"flex"} gap={2} sx={{ marginTop: 11, marginBottom: 22 }}>
      <Box flex={1}>
        <TextField
          variant="standard"
          label="PNR"
          fullWidth
          name="pnr"
          value={values?.pnr}
          onChange={(e) => setFieldValue("pnr", e.target.value)}
        />
      </Box>
      <Box flex={1}>
        <TextField
          variant="standard"
          label="Last Name"
          fullWidth
          name="l_name"
          value={values?.l_name}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue("l_name", value.toUpperCase());
          }}
        />
      </Box>
      <Box flex={1}>
        <FormControl variant="standard" className="w-100">
          <InputLabel id="demo-simple-select-standard-label">
            Select GDS
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            name={"ispnr"}
            value={values?.gds}
            onChange={(e) => setFieldValue("gds", e.target.value)}
            label="Select GDS"
          >
            {arry.map((list) => (
              <MenuItem value={list} sx={{ m: 1, bgcolor: "#fff" }} key={list}>
                {list}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box flex={1}>
        <FormControl
          variant="standard"
          className="w-100"
          onClick={() => setagentDRopDwn(!agentDRopDwn)}
        >
          <InputLabel htmlFor="supplier">Select Agent</InputLabel>
          <Input
            variant="standard"
            label="Select Agent"
            autoComplete="off"
            className="w-100"
            name="agent"
            value={values?.select_agent}
            onChange={(e) => {
              let value = e.target.value;
              setFieldValue("select_agent", value);
              dispatch(asyncSearchAgencyData(value));
            }}
            endAdornment={
              <InputAdornment position="end">
                {values?.select_agent !== "" && (
                  <IconButton
                    onClick={() => {
                      setFieldValue("select_agent", "");
                    }}
                  >
                    <MdClose />
                  </IconButton>
                )}
              </InputAdornment>
            }
          />
        </FormControl>

        {agentDRopDwn ? (
          <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
            <div className="agent_dropdown">
              {searchAgency?.length > 0 ? (
                searchAgency?.map((item, index) => {
                  return (
                    <div
                      className="p-3"
                      onClick={(e) => {
                        setFieldValue("select_agent", item.agent_name);
                        setagentDRopDwn(false);
                      }}
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      {item.agent_name}
                    </div>
                  );
                })
              ) : (
                <div className="p-3">No record available.</div>
              )}
            </div>
          </ClickAwayListener>
        ) : null}
      </Box>
      <Box mt={1}>
        <button className="setup-btn">Retrieve</button>
      </Box>
    </Box>
  );
};

export default AgentPNR;
