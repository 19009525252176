import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  otpCode: null,
  successMsg: "",

  GDSData: [],

  otpMSG: null,
  loading: false,
  error: null,
  message: null,
};

// generate otp
export const asyncGenerateOTP = createAsyncThunk(
  "asyncGenerateOTP/post",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/api-credentials-send-otp`
      );
      return await response.data;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

// VERIFY otp
export const asyncConfirmOTP = createAsyncThunk(
  "asyncConfirmOTP/post",
  async (code, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/api-credentials-confirm-otp`,
        {
          code: code,
        }
      );
      window.localStorage.setItem("apiCredentials", JSON.stringify(true));
      return await response.data;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

//////////////////  GDS  //////////////////

// getAll GDS
export const asyncGetAllGDS = createAsyncThunk(
  "asyncGetAllGDS/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/gds`);
      return await response.data.data;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const asyncUpdateAllGDS = createAsyncThunk(
  "asyncUpdateAllGDS/pacth",
  async (data, { rejectWithValue }) => {
    try {
      const response = await NODEURL.patch(`/api/v1/b2b/gds`, data);

      return await response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ApiCredentials = createSlice({
  name: "ApiCredentials",
  initialState,
  reducers: {
    otpCodeReduce: (state) => {
      state.otpCode = null;
    },
    successMsgReduce: (state) => {
      state.successMsg = "";
    },
    messageReducerNull: (state) => {
      state.message = null;
    },
  },

  extraReducers: (builder) => {
    // generate otp
    builder.addCase(asyncGenerateOTP.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGenerateOTP.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.otpCode = payload;
      state.successMsg = payload?.message;
    });
    builder.addCase(asyncGenerateOTP.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // generate otp
    builder.addCase(asyncConfirmOTP.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncConfirmOTP.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.otpMSG = payload;
    });
    builder.addCase(asyncConfirmOTP.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get gds
    builder.addCase(asyncGetAllGDS.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAllGDS.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.GDSData = payload;
    });
    builder.addCase(asyncGetAllGDS.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // update gds
    builder.addCase(asyncUpdateAllGDS.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateAllGDS.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.message = payload;
    });
    builder.addCase(asyncUpdateAllGDS.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.message = payload;
    });
  },
});
export const { otpCodeReduce, successMsgReduce, messageReducerNull } =
  ApiCredentials.actions;
export default ApiCredentials.reducer;
