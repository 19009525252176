import React from 'react';
import HotelComp from '../components/Setup/Umrah/Hotel'

const Hotel = () => {
  return (
    <div className='Full_height'>
      <HotelComp />
    </div>
  )
}

export default Hotel