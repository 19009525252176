import React from "react";

import VisaTypeComp from "../components/Setup/Visa/VisaType";

const VisaType = () => {
  return (
    <div className="">
      <VisaTypeComp />
    </div>
  );
};

export default VisaType;
