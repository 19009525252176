import React from 'react'
import PromotionsComp from '../components/Setup/Promotions/Promotion'

const Promotions = () => {
  return (
    <div className=''>
      <PromotionsComp />
    </div>
  )
}

export default Promotions