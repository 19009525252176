import React, { useEffect } from "react";
import SereneLogo from "../../../../assets/images/airlineLogo/ER.png";
import moment from "moment";
//import icons
import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
  MdClose,
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getSectorsName } from "../../../../Utils/FlightSector";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { LightTooltip } from "./LightTooltip";
import {
  asyncCreateSerenePnr,
  set_Airline_filter_drp,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ExclusiveSetors } from "../../../../Utils/exclusiveSector";

import Ancillary from "../../checkout/checkout_ancillary/Ancillary";
import { useLocation } from "react-router-dom";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";

function Serene({ serene, index, isExpanded, toggleDetail, selectedFare }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { selectedFlight } = useSelector((state) => state.FlightSearch);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "ER"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "ER");

  // Convert minutes to hours and minutes using Moment.js
  const duration = moment.duration(serene?.legs[0]?.flightTime, "minutes");
  const formattedDuration = moment
    .utc(duration.asMilliseconds())
    .format("HH:mm");

  const locDeparture = searchValues?.locationDep;
  const locationArrival = searchValues?.locationArrival;

  //find the lowest price fare
  const lowestPrice = (fares) => {
    const lowestPriceElement = fares?.reduce(
      (min, current) => {
        if (!current.soldOut && current.price < min.price) {
          return current;
        }
        return min;
      },
      { price: Number.MAX_SAFE_INTEGER, soldOut: false }
    );
    return lowestPriceElement;
  };

  //calculate the lowest total fare
  const lowestTotalFare = (fare) => {
    let newFare =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.adult?.priceWithoutTax)),
        Number(
          CurrencyExchange(fare?.adult?.price - fare?.adult?.priceWithoutTax)
        ),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.child?.priceWithoutTax)),
        Number(
          CurrencyExchange(fare?.child?.price - fare?.child?.priceWithoutTax)
        ),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.infant?.priceWithoutTax)),
        Number(
          CurrencyExchange(fare?.infant?.price - fare?.infant?.priceWithoutTax)
        ),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.total_fare * searchValues?.infantNo || 0);

    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  //discount price
  const DiscountPrice = (fare) => {
    let newFare =
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.adult?.priceWithoutTax)),
        Number(
          CurrencyExchange(fare?.adult?.price - fare?.adult?.priceWithoutTax)
        ),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.child?.priceWithoutTax)),
        Number(
          CurrencyExchange(fare?.child?.price - fare?.child?.priceWithoutTax)
        ),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.infant?.priceWithoutTax)),
        Number(
          CurrencyExchange(fare?.infant?.price - fare?.infant?.priceWithoutTax)
        ),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      )?.sp * searchValues?.infantNo || 0);

    if (newFare > 0) {
      return `Off ${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
    }
    return "";
  };

  /////////////// single pax fare
  const singlePaxFare = (fare, pax) => {
    let newFare =
      pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.priceWithoutTax)),
        Number(CurrencyExchange(fare?.price - fare?.priceWithoutTax)),
        getSectorsName(locDeparture, locationArrival),
        ExclusiveSetors(searchValues, pricing)
      ).total_fare * pax || 0;
    return `${CurrencyCode()} ${TwoDecimalPoint(newFare)}`;
  };

  /////////////////
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  /// USE EFFECT
  useEffect(() => {
    dispatch(set_Airline_filter_drp("ER"));
  }, [dispatch]);
  const supplierPrice = (fare) => {
    let newFare =
      fare?.adult?.price * searchValues?.adultsCount +
      fare?.child?.price * searchValues?.childCount +
      fare?.infant?.price * searchValues?.infantNo;
    return TwoDecimalPoint(newFare);
  };
  const getPaxSupplierPrice = (amount, pax) => (pax > 0 ? amount : 0);

  function formatDuration(inputDuration) {
    // Use a custom regular expression to extract hours and minutes
    const regex = /(\d+)h (\d+)m/;
    const match = inputDuration.match(regex);

    if (match) {
      const hours = parseInt(match[1]);
      const minutes = parseInt(match[2]);

      // Calculate the duration using Moment.js
      const duration = moment.duration({ hours, minutes });

      // Format the duration as 'hh:mm'
      const formattedDuration = `${duration
        .hours()
        .toString()
        .padStart(2, "0")}:${duration.minutes().toString().padStart(2, "0")}`;

      return formattedDuration;
    } else {
      return "Invalid input format"; // Handle invalid input
    }
  }

  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`serene${index}`}
      >
        <div
          className="shadow result_box_body cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`serene${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center justify-content-center result_box_body">
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={SereneLogo} alt="SereneLogo" width={100} />
                <div>
                  {serene?.carrierCode} {serene?.flightNumber}
                </div>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(
                        new Date(serene?.departureDate?.split("T")[0])
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {serene?.departureDate?.split("T")[1]?.slice(0, 5)}
                    </h6>
                    <h6>
                      {serene?.from?.code} - {serene?.from?.name?.split(",")[0]}
                    </h6>
                    {getSectorsName(serene?.from?.code, serene?.to?.code) ===
                      "soto" && (
                      <h6 className="color_secondary">
                        ({serene?.from?.name.split("(")[1]}
                      </h6>
                    )}
                  </div>
                </div>
                <div>
                  <h6 className="text-center">{formattedDuration}</h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">Non-Stop</h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>
                  <div>
                    <h6>
                      {moment(
                        new Date(serene?.arrivalDate?.split("T")[0])
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {serene?.arrivalDate?.split("T")[1]?.slice(0, 5)}
                    </h6>
                    <h6>
                      {serene?.to?.code} - {serene?.to?.name?.split(",")[0]}
                    </h6>
                    {getSectorsName(serene?.from?.code, serene?.to?.code) ===
                      "siti" && (
                      <h6 className="color_secondary">
                        ({serene?.to?.name?.split("(")[1]}
                      </h6>
                    )}
                  </div>{" "}
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
                {selectedFare ? (
                  <HiEye className="color_primary" size={40} />
                ) : (
                  <>
                    <h6 className="text-danger">
                      {DiscountPrice(
                        selectedFare
                          ? selectedFlight?.fare
                          : lowestPrice(serene?.fares)
                      )}
                    </h6>
                    <div className="d-flex align-items-end gap-3  ">
                      <h4>
                        {lowestTotalFare(
                          selectedFare
                            ? selectedFlight?.fare
                            : lowestPrice(serene?.fares)
                        )}
                      </h4>
                      <img src={SereneLogo} alt="" width={40} height={40} />
                    </div>
                    {user?.agents_comp?.agent_grp_show ? (
                      <div className="fw-bold fs-5">{serene?.agent_name}</div>
                    ) : null}

                    <button
                      onClick={() => {
                        toggleDetail(index);
                      }}
                      className="button_style w-75 my-3"
                      type="button"
                    >
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <>
            <div className="p-3">
              <div className="search_engin_result_box py-3 bg-white h-auto">
                <div
                  className={`d-flex ${
                    selectedFare
                      ? "justify-content-start"
                      : "justify-content-between"
                  } align-items-start px-5`}
                >
                  {/* headings */}
                  <div className={`${selectedFare ? "me-5 pe-5" : ""}`}>
                    <div className="mb-4 pb-3"></div>
                    <div className="flight-detail-headings">
                      <BsHandbag size={25} className="color_primary" />
                      <span>Hand Carry Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsBagCheck size={25} className="color_primary" />
                      <span>Checked Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdOutlineRestaurant
                        size={25}
                        className="color_primary"
                      />
                      <span>Meals</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsPerson size={25} className="color_primary" />
                      <span>Seat Selection</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdSwapHoriz size={25} className="color_primary" />
                      <span>Refund & Exchanges</span>
                    </div>
                  </div>
                  {serene?.fares
                    ?.filter((item) =>
                      selectedFare ? item.name === selectedFare : item
                    )
                    ?.map((fare, index) => {
                      return (
                        <div key={fare.id + index}>
                          <div className="fs-4 theme-text-color mb-3 fw-bold">
                            {fare.name}
                          </div>
                          <div className="flight-detail-headings">
                            <MdCheck size={25} color="green" />
                            <span>Upto 7 kg</span>
                          </div>
                          <div className="flight-detail-headings">
                            {fare.name === "Economy Regular" ? (
                              <>
                                <MdCheck size={25} color="green" />
                                <span>40 kg (2 Pcs)</span>
                              </>
                            ) : fare.name === "Free Baggage" ? (
                              <>
                                <MdCheck size={25} color="green" />
                                <span>20 kg (1 Pcs)</span>
                              </>
                            ) : fare.name === "Serene PLUS" ? (
                              <>
                                <MdCheck size={25} color="green" />
                                <span>80 kg (Any Pcs)</span>
                              </>
                            ) : (
                              <>
                                <MdClose size={25} color="red" />
                                <span>0 kg</span>
                              </>
                            )}
                          </div>
                          <div className="flight-detail-headings">
                            <MdCheck size={25} color="green" />
                            <span>Allowed</span>
                          </div>
                          <div className="flight-detail-headings">
                            <CgMenuGridR size={25} color="#38025c" />
                            <span>System Allocated</span>
                          </div>
                          {/* refund and exchange dialog  */}
                          <div className="flight-detail-headings">
                            <LightTooltip
                              title={
                                <div className="p-3">
                                  <h4>Refund & Exchange</h4>
                                  <h6>
                                    Penalty will be charged per passenger
                                    segment.
                                  </h6>
                                  <div className="text-danger font-italic">
                                    for further clarification contact with
                                    Airline Or Call our support team.
                                  </div>
                                  <div className="text-danger font-italic mt-2">
                                    After 25 days of departure ticket is
                                    Non-Refundable.
                                  </div>

                                  <h6 className="mt-3">
                                    contact with airline or call our support
                                    team
                                  </h6>
                                  {/* <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                    <div className="me-5">
                                      <h4>Exchange</h4>
                                      <h6>
                                        Before 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          1000
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          1500
                                        )}`}
                                      </h6>
                                    </div>
                                    <div>
                                      <h4>Refund</h4>
                                      <h6>
                                        Within 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          1500
                                        )}`}
                                      </h6>
                                      <h6>
                                        Within 48 Hours:{" "}
                                        {`${CurrencyCode()} ${CurrencyExchange(
                                          2500
                                        )}`}
                                      </h6>
                                    </div>
                                  </div> */}
                                </div>
                              }
                              placement="top"
                            >
                              <div style={{ cursor: "pointer" }}>
                                <MdRemoveRedEye size={25} color="#262829" />
                                <span>View</span>
                              </div>
                            </LightTooltip>
                          </div>

                          {!selectedFare ? (
                            <div className="pt-3 px-2">
                              {fare?.soldOut ? (
                                <button disabled className="button_style">
                                  Sold Out
                                </button>
                              ) : (
                                <LightTooltip
                                  title={
                                    <div className="p-3">
                                      <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex me-4">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            ADT
                                          </h5>
                                          <h5>* {searchValues?.adultsCount}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            fare?.adult,
                                            searchValues?.adultsCount
                                          )}
                                        </h6>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-end my-2">
                                        <div className="d-flex">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            CHD
                                          </h5>
                                          <h5>* {searchValues?.childCount}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            fare?.child,
                                            searchValues?.childCount
                                          )}
                                        </h6>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            INF
                                          </h5>
                                          <h5>* {searchValues?.infantNo}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            fare?.infant,
                                            searchValues?.infantNo
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <button
                                    type="button"
                                    className="button_style px-4"
                                    onClick={() => {
                                      if (!selectedFare) {
                                        let pnrData = {
                                          infant: searchValues?.infantNo,
                                          child: searchValues?.childCount,
                                          adults: searchValues?.adultsCount,
                                          flights: [
                                            {
                                              ...serene,
                                              selectedFare: fare,
                                              selectedFares: [fare],
                                            },
                                          ],
                                          agent_name: serene?.agent_name,
                                        };
                                        dispatch(
                                          asyncCreateSerenePnr({
                                            body: pnrData,
                                            navigate: navigate,
                                          })
                                        );
                                        
                                        let bookingBody = {
                                          flight: serene,
                                          fare: fare,
                                          gds: "SERENE",
                                          price: lowestTotalFare(fare),

                                          ttl_agent_fare: TwoDecimalPoint(
                                            CurrencyExchangeToPKR(
                                              Number(
                                                lowestTotalFare(fare)
                                                  ?.split(" ")
                                                  ?.at(1) || 0
                                              )
                                            )
                                          ),
                                          ttlagent_curr_fare: Number(
                                            lowestTotalFare(fare)
                                              ?.split(" ")
                                              ?.at(1) || 0
                                          ),
                                          ttl_supplier_fare:
                                            supplierPrice(fare),
                                          ttlsup_curr_fare: TwoDecimalPoint(
                                            CurrencyExchange(
                                              supplierPrice(fare)
                                            )
                                          ),

                                          adlt_ttl_d_curr:
                                            CurrencyExchangeToPKR(
                                              Number(
                                                singlePaxFare(fare?.adult, 1)
                                                  ?.split(" ")
                                                  ?.at(1)
                                              )
                                            ),
                                          chld_ttl_d_curr:
                                            CurrencyExchangeToPKR(
                                              Number(
                                                singlePaxFare(fare?.child, 1)
                                                  ?.split(" ")
                                                  ?.at(1)
                                              )
                                            ),
                                          inft_ttl_d_curr:
                                            CurrencyExchangeToPKR(
                                              Number(
                                                singlePaxFare(fare?.infant, 1)
                                                  ?.split(" ")
                                                  ?.at(1)
                                              )
                                            ),

                                          adultBaseFare: getPaxSupplierPrice(
                                            fare?.adult?.priceWithoutTax,
                                            searchValues?.adultsCount
                                          ),

                                          childBaseFare: getPaxSupplierPrice(
                                            fare?.child?.priceWithoutTax,
                                            searchValues?.childCount
                                          ),
                                          infantBaseFare: getPaxSupplierPrice(
                                            fare?.infant?.priceWithoutTax,
                                            searchValues?.infantNo
                                          ),

                                          adultTaxes:
                                            fare?.adult?.price -
                                            fare?.adult?.priceWithoutTax,

                                          childTaxes:
                                            fare?.child?.price -
                                            fare?.child?.priceWithoutTax,

                                          infantTaxes:
                                            fare?.infant?.price -
                                            fare?.infant?.priceWithoutTax,

                                          ADLT_TAXES: fare?.adult?.taxes?.map(
                                            (tax, index) => {
                                              return {
                                                code: tax?.code,
                                                amount: tax?.price,
                                              };
                                            }
                                          ),
                                          CHLD_TAXES: fare?.child?.taxes?.map(
                                            (tax, index) => {
                                              return {
                                                code: tax?.code,
                                                amount: tax?.price,
                                              };
                                            }
                                          ),
                                          INFT_TAXES: fare?.infant?.taxes?.map(
                                            (tax, index) => {
                                              return {
                                                code: tax?.code,
                                                amount: tax?.price,
                                              };
                                            }
                                          ),

                                          baggageName: fare?.name,
                                          flightNumber: serene?.flightNumber,
                                          depDate: moment(
                                            serene?.departureDate?.split("T")[0]
                                          ).format("ddd DD-MMM-YY"),

                                          depTime: serene?.departureDate
                                            ?.split("T")[1]
                                            ?.slice(0, 5),
                                          arrDate: moment(
                                            serene?.arrivalDate?.split("T")[0]
                                          ).format("ddd DD-MMM-YY"),
                                          arrTime: serene?.arrivalDate
                                            ?.split("T")[1]
                                            ?.slice(0, 5),
                                          flightDuration: serene?.flightTime,
                                          flightDuration: formattedDuration, /////
                                          logo: "Serene",
                                          code: "ER",

                                          booking: {
                                            gds: "SERENE", // should be gds code
                                            pnr: "", // returned in pricing response
                                            agent_name: serene?.agent_name,
                                            segments: [
                                              {
                                                ...serene,
                                                selectedFare: fare,
                                                selectedFares: [fare],
                                                departure_date: moment(
                                                  serene?.departureDate?.split(
                                                    "T"
                                                  )[0]
                                                ).format("YYYY-MM-DD"),

                                                baggage:
                                                  fare.name ===
                                                  "Economy Regular"
                                                    ? "40 kg (2 Pcs)"
                                                    : fare.name ===
                                                      "Free Baggage"
                                                    ? "20 kg (1 Pcs)"
                                                    : fare.name ===
                                                      "Serene PLUS"
                                                    ? " 80 kg (Any Pcs)"
                                                    : "0 kg",
                                                departure_time:
                                                  serene?.departureDate
                                                    ?.split("T")[1]
                                                    ?.slice(0, 5),
                                                arrival_date: moment(
                                                  serene?.arrDate?.split("T")[0]
                                                ).format("YYYY-MM-DD"),
                                                arrival_time:
                                                  serene?.arrivalDate
                                                    ?.split("T")[1]
                                                    ?.slice(0, 5),
                                                origin_terminal: "",
                                                desti_terminal: "",
                                                flight_number:
                                                  serene?.flightNumber,
                                                number_in_party: "",
                                                res_book_desig_code: "",
                                                status: "NN",
                                                des_location:
                                                  searchValues?.locationArrival,
                                                dest_code:
                                                  searchValues?.locationArrival
                                                    ?.split("-")
                                                    ?.at(0),

                                                marketing_airline: "ER",
                                                orig_location:
                                                  searchValues?.locationDep,
                                                orig_code:
                                                  searchValues?.locationDep
                                                    ?.split("-")
                                                    ?.at(0),
                                                marriage_grp: "",
                                                cabin_class:
                                                  searchValues?.ticket_class,
                                                segment_type: getSectorsName(
                                                  locDeparture,
                                                  locationArrival
                                                ), // dom | siti | soto | group
                                              },
                                            ],
                                            tp_ur_code: "",
                                            platting_carrier: "ER",
                                            tp_ssr_verssion: 0,
                                            tp_trace_id: "",
                                          },
                                        };

                                        dispatch(
                                          setselectedFlight({
                                            ...bookingBody,
                                          })
                                        );
                                      }
                                    }}
                                  >
                                    {lowestTotalFare(fare)}
                                  </button>
                                </LightTooltip>
                              )}
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/* acillary component */}
      </div>
    </>
  );
}

export default Serene;
