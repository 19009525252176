import React, { useState } from "react";
import FlightsBefore from "../../assets/images/flightBefore.jpeg";
import FlightsAfter from "../../assets/images/flightsAfter.jpeg";

// import icons
import ImportBefore from "../../assets/images/importBefore.jpeg";
import ImportAfter from "../../assets/images/importAfter.jpeg";
import HotelBefore from "../../assets/images/hotelBefore.jpeg";
import HotelAfter from "../../assets/images/hotelAfter.jpeg";

import UmrahBefore from "../../assets/images/umrahBefore.jpeg";
import UmrahAfter from "../../assets/images/umrahAfter.jpeg";

import VisaBefore from "../../assets/images/visaBefore.jpeg";
import VisaAfter from "../../assets/images/visaAfter.jpeg";

import InsuranceBefore from "../../assets/images/insuranceBefore.jpeg";
import InsuranceAfter from "../../assets/images/insuranceAfter.jpeg";
import FlightsTab from "./FlightsTab";
import ImportTab from "./ImportTab";
import HotelsTab from "./HotelsTab";
import UmrahTab from "./UmrahTab";
import VisaTab from "./VisaTab";
import InsuranceTab from "./InsuranceTab";

import "../../Styles/Reservation.css";
import "../../Styles/Hotels.css";
import "./ImportTab/importTab.css"

const Reservation = () => {
  const [active_tab, set_active_tab] = useState("flights");

  return (
    <div>
      <main className="bg-white border col-12 col-sm-10 mx-auto rounded-3 shadow-sm reservation_tabs">
        <div className="tabs_header mx-4 col-sm-10 mx-auto">
          <input
            id="tab1"
            type="radio"
            name="tabs"
            value="flights"
            checked={active_tab === "flights"}
            onChange={(e) => set_active_tab(e.target.value)}
            style={{ visibility: "hidden" }}
          />
          <label
            htmlFor="tab1"
            id="tabs-label"
            className="d-flex flex-column align-items-center"
          >
            <span>
              <img alt="icons" src={FlightsBefore} className="tabs-image" />
            </span>
            <span>
              <img
                alt="icons"
                src={FlightsAfter}
                className="active-tab-image"
              />
            </span>
            <p
              className={`${
                active_tab === "flights" ? "primarytext" : "secodarytext"
              } text-center`}
            >
              Flights
            </p>
          </label>

          <input
            id="tab2"
            type="radio"
            name="tabs"
            value="import"
            checked={active_tab === "import"}
            onChange={(e) => set_active_tab(e.target.value)}
            style={{ visibility: "hidden" }}
          />
          <label
            htmlFor="tab2"
            id="tabs-label"
            className="d-flex flex-column align-items-center"
          >
            <span>
              <img alt="icons" src={ImportBefore} className="tabs-image" />
            </span>
            <span>
              <img alt="icons" src={ImportAfter} className="active-tab-image" />
            </span>
            <p
              className={`${
                active_tab === "import" ? "primarytext" : "secodarytext"
              }`}
            >
              Import
            </p>
          </label>

          {/* <input
            id="tab3"
            type="radio"
            name="tabs"
            value="hotels"
            checked={active_tab === "hotels"}
            onChange={(e) => set_active_tab(e.target.value)}
            style={{ visibility: "hidden" }}
          />
          <label
            htmlFor="tab3"
            id="tabs-label"
            className="d-flex flex-column align-items-center"
          >
            <span>
              <img alt="icons" src={HotelBefore} className="tabs-image" />
            </span>
            <span>
              <img alt="icons" src={HotelAfter} className="active-tab-image" />
            </span>
            <p
              className={`${
                active_tab === "hotels" ? "primarytext" : "secodarytext"
              }`}
            >
              Hotels
            </p>
          </label> */}

          {/* <input
            id="tab4"
            type="radio"
            name="tabs"
            value="umrah"
            checked={active_tab === "umrah"}
            onChange={(e) => set_active_tab(e.target.value)}
            style={{ visibility: "hidden" }}
          />
          <label
            htmlFor="tab4"
            id="tabs-label"
            className="d-flex flex-column align-items-center"
          >
            <span>
              <img alt="icons" src={UmrahBefore} className="tabs-image" />
            </span>
            <span>
              <img alt="icons" src={UmrahAfter} className="active-tab-image" />
            </span>
            <p
              className={`${
                active_tab === "umrah" ? "text-primary" : "text-secondary"
              }`}
            >
              Umrah
            </p>
          </label> */}

          {/* <input
            id="tab5"
            type="radio"
            name="tabs"
            value="visa"
            checked={active_tab === "visa"}
            onChange={(e) => set_active_tab(e.target.value)}
            style={{ visibility: "hidden" }}
          />
          <label
            htmlFor="tab5"
            id="tabs-label"
            className="d-flex flex-column align-items-center"
          >
            <span>
              <img alt="icons" src={VisaBefore} className="tabs-image" />
            </span>
            <span>
              <img alt="icons" src={VisaAfter} className="active-tab-image" />
            </span>
            <p
              className={`${
                active_tab === "visa" ? "text-primary" : "text-secondary"
              } text-center`}
            >
              Visa
            </p>
          </label> */}

          <input
            id="tab6"
            type="radio"
            name="tabs"
            value="insurance"
            checked={active_tab === "insurance"}
            onChange={(e) => set_active_tab(e.target.value)}
            style={{ visibility: "hidden" }}
          />
          <label
            htmlFor="tab6"
            id="tabs-label"
            className="d-flex flex-column align-items-center"
          >
            <span>
              <img alt="icons" src={InsuranceBefore} className="tabs-image" />
            </span>
            <span>
              <img
                alt="icons"
                src={InsuranceAfter}
                className="active-tab-image"
              />
            </span>
            <p
              className={`${
                active_tab === "insurance" ? "primarytext" : "secodarytext"
              }`}
            >
              Insurance
            </p>
          </label>
        </div>

        <div className="px-2 px-md-5 mt-5 pb-3">
          {active_tab === "flights" && <FlightsTab />}
          {active_tab === "import" && <ImportTab />}
          {active_tab === "hotels" && <HotelsTab />}
          {active_tab === "umrah" && <UmrahTab />}
          {active_tab === "visa" && <VisaTab />}
          {active_tab === "insurance" && <InsuranceTab />}
        </div>
      </main>
    </div>
  );
};

export default Reservation;
