import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect } from "react";
import { options } from "../../../Utils/ColumnOptions";
import { FieldArray, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetOurServicesData,
  editServices_null,
  asyncPostOurServices,
  asyncEditOurServices,
  asyncDeleteOurServices,
  asyncUpdateOurServices,
} from "../../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import BtnLoader from "../../AppForm/BtnLoader";
import * as yup from "yup";
import { apiUrl } from "../../../Utils/configUrl";

const OurServices = () => {
  const { editServices, servicesdata, loading } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const SUPPORTED_FORMATS = ["image/png"];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetOurServicesData());
  }, [dispatch]);
  /******************Initials values ********** */
  const initialValues = {
    serviceValue: [
      {
        image: "",
        heading: editServices?.heading || "",
        description: editServices?.description || "",
      },
    ],
  };

  // if the login user has permissions to add services
  const postPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/post-service"
  );
  // if the user has permission to edit services
  const editPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/update-service"
  );
  // if the user has permission to delete services
  const deletePermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/delete-service"
  );

  const validationSchema = yup.object().shape({
    serviceValue: yup.array().of(
      yup.object().shape({
        image: editServices
          ? yup
              .mixed()
              .nullable()
              .test(
                "format",
                "Only png image are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              )
          : yup
              .mixed()
              .nullable()
              .required("Image is required")
              .test(
                "format",
                "Only png image are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              ),
        heading: yup.string().required("Heading Required"),
        description: yup
          .string()
          .required("Description Required")
          .test(
            "max",
            "Description must not exceed 400 characters",
            function (value) {
              return value ? value.length <= 401 : true;
            }
          ),
      })
    ),
  });

  const column = [
    {
      label: "id",
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <img
                style={{ height: 80, width: 80 }}
                src={`${apiUrl}/${value}`}
                alt={value}
              />
            </div>
          );
        },
      },
    },
    {
      label: "Heading",
      name: "heading",
    },
    {
      label: "Description",
      name: "description",
      options: {
        setCellProps: () => ({ style: { maxWidth: "200px" } }),
        customBodyRender: (value) => {
          return <div className="text-justify">{value}</div>;
        },
      },
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {editPermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-success "
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                    dispatch(asyncEditOurServices(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-thin fa-pencil" aria-hidden="true"></i>
                </button>
              ) : null}

              {deletePermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-danger ms-3"
                  onClick={() => {
                    dispatch(asyncDeleteOurServices(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="pb-5">
      <Formik
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          values?.serviceValue.map((it) => formData.append("image", it.image));
          values?.serviceValue.map((it) =>
            formData.append("heading", it.heading)
          );
          values?.serviceValue.map((it) =>
            formData.append("description", it.description)
          );

          if (!editServices) {
            for (const [key, value] of Object.entries(values)) {
              value.map((it) => dispatch(asyncPostOurServices(it)));
            }
          } else {
            dispatch(
              asyncUpdateOurServices({ formData, id: editServices?.id })
            );
            dispatch(editServices_null());
          }
          resetForm({
            image: null,
            heading: "",
            description: "",
          });
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="col-11 mx-auto rounded-2 mt-5 bg-white">
                <p className="h4 otawix-heading box_title">
                  Add / Edit Our Services
                </p>
                <div className="p-5">
                  <FieldArray
                    name={`serviceValue`}
                    render={(arrayHelpers) => (
                      <div>
                        {values.serviceValue.map((val, index) => (
                          <div key={index}>
                            <div className="row">
                              <div className="col-3">
                                <TextField
                                  fullWidth
                                  name={`serviceValue.${index}.image`}
                                  variant="standard"
                                  label="Image (size 50*50ps only png)"
                                  type="file"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `serviceValue.${index}.image`,
                                      e.currentTarget.files[0]
                                    )
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <p style={{ color: "red" }}>
                                  {errors?.serviceValue &&
                                    errors?.serviceValue[index]?.image}
                                </p>
                              </div>
                              <div className="col-3">
                                <TextField
                                  fullWidth
                                  name={`serviceValue.${index}.heading`}
                                  variant="standard"
                                  label="Heading"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `serviceValue.${index}.heading`,
                                      e.target.value.toUpperCase()
                                    )
                                  }
                                  value={val.heading}
                                />
                                <p style={{ color: "red" }}>
                                  {errors?.serviceValue &&
                                    errors?.serviceValue[index]?.heading}
                                </p>
                              </div>
                              <div className="col-3">
                                <TextField
                                  fullWidth
                                  name={`serviceValue.${index}.description`}
                                  variant="standard"
                                  label="Description(Char limit 400)"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `serviceValue.${index}.description`,
                                      e.target.value.slice(0, 400).toUpperCase()
                                    )
                                  }
                                  value={val.description}
                                />
                                <p style={{ color: "red" }}>
                                  {errors?.serviceValue &&
                                    errors?.serviceValue[index]?.description}
                                </p>
                              </div>

                              {!editServices && (
                                <div className="col-3">
                                  {index >= 1 && (
                                    <button
                                      className="button_style bg-danger"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      - Services
                                    </button>
                                  )}
                                  {index === 0 && (
                                    <button
                                      className="button_style "
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          image: "",
                                          heading: "",
                                          description: "",
                                        })
                                      }
                                    >
                                      + Services
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <div className="text-center mt-5">
                    {editServices && (
                      <button
                        className="button_style px-5 bg-danger me-2"
                        onClick={(e) => {
                          dispatch(editServices_null());
                        }}
                      >
                        Cancel
                      </button>
                    )}
                    {editServices ? (
                      <button className="button_style px-5" type="submit">
                        {loading ? <BtnLoader /> : "Update"}
                      </button>
                    ) : null}

                    {(postPermission !== undefined || user?.user_id === 1) &&
                    !editServices ? (
                      <button className="button_style px-5" type="submit">
                        {loading ? <BtnLoader /> : "Save"}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-11 bg-white mx-auto mt-5">
                <div className="otawix-heading box_title h4">
                  Our Services List
                </div>
                <div className="p-5">
                  <MUIDataTable
                    title={""}
                    columns={column}
                    options={options}
                    data={servicesdata}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OurServices;
