import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";


const FareLogs = React.memo(({ getBookingLogs }) => {
  return (
    <div className="pt-2 pb-5">
      <div className="col-8 p-3">
        <div className="h4 p-3">Fare Logs</div>
        <table className="table border">
          <thead className="table_heading">
            <tr>
              <th className="p-3">Date/Time</th>
              <th className="p-3">User Name</th>
              <th className="p-3">Description</th>
            </tr>
          </thead>
          <tbody>
            {getBookingLogs?.map((item, index) => (
              <tr>
                <td className="p-3">
                  {moment(item?.date_time?.split("T")?.at(0)).format(
                    "DD-MMM-YYYY"
                  ) +
                    " " +
                    item?.date_time?.split("T")?.at(1)?.substring(0, 5)}
                </td>
                <td className="p-3">
                  {item?.agents_user?.f_name + " " + item?.agents_user?.l_name}{" "}
                </td>
                <td className="p-3">{item?.description}</td>
              </tr>
            ))}

            {/* {ApprovedByUser?.f_name && (
               <tr>
                 <td className="p-3">
                   {approved_date}{" "}
                 </td>
                 <td className="p-3">
                   {ApprovedByUser?.f_name + " " + ApprovedByUser?.l_name}
                 </td>
                 <td className="p-3">Posted</td>
               </tr>
             )} */}

            {/* {singleVoucherLog?.length
               ? singleVoucherLog?.map((logs, ind) => (
                   <tr>
                     <td className="p-3">{logs?.date_time} </td>
                     <td className="p-3">
                       {logs?.agents_user?.f_name +
                         " " +
                         logs?.agents_user?.l_name}
                     </td>
                     <td className="p-3">{logs?.description}</td>
                   </tr>
                 ))
               : ""} */}
          </tbody>
        </table>
      </div>
    </div>
  );
});
export default FareLogs