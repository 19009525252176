const columns = [
    {
        name: "Booking Main Id/Voucher Id",
        label: "Booking Main Id/Voucher Id",
        options: {
            filter: true,
            sort: true,
        }
    },
    {
        name: "Booking Invoice Number",
        label: "Booking Invoice Number",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Document Number",
        label: "Document Number",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Booking Type",
        label: "Booking Type",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Booking Action",
        label: "Booking Action",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Action Performed By",
        label: "Action Performed By",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Booking Customer",
        label: "Booking Customer",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Booking Supplier",
        label: "Booking Supplier",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Action Performed",
        label: "Action Performed",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Date and Time",
        label: "Date and Time",
        options: {
            filter: true,
            sort: false,
        }
    },
    {
        name: "Description",
        label: "Description",
        options: {
            filter: true,
            sort: false,
        }
    },
];
export default columns