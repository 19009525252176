import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../../Utils/configUrl";
import BtnLoader from "../../AppForm/BtnLoader";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  asyncGetPromotionData,
  asyncPostPromotionData,
  asyncDeletPromotionData,
  asyncEditPromotionData,
  asyncUpdatePromotionData,
  toggleModel,
} from "../../../redux/features/promotion/PromotionSlice";
import { options } from "../../../Utils/ColumnOptions";
import { ChangeStatus } from "../../../Utils/ChangeStatus";

const SignupSchema = Yup.object().shape({
  image: Yup.string().required("Required"),
});

const Promotion = () => {
  const dispatch = useDispatch();
  const { promotionData, showModel, editPromotion, isLoading } = useSelector(
    (state) => state.promotion
  );

  const [keyVal, setkeyVal] = useState();
  const [modalData, setModalData] = useState([]);
  const [EffectCall, setuseEffectCall] = useState(false);
  const [Errors, setErrors] = useState(false);
  const [errorsforempty, setErrorsForEmpty] = useState(false);

  //useEffect
  useEffect(() => {
    setkeyVal(Date.now());
    dispatch(asyncGetPromotionData());
  }, [EffectCall, editPromotion?.id, dispatch]);

  //table colums
  const columns = [
    {
      name: "id",
      label: "id",
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <img
                style={{ height: 50, width: 80 }}
                src={`${apiUrl}/${value}`}
                alt={value}
              />
            </div>
          );
        },
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/promotion-status",
                      !value,
                      id,
                      "status",
                      dispatch
                    );
                    localStorage.removeItem("model");
                  }}
                />
                <label htmlFor={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {/* update button */}
              <button
                className="edit-delete-icon-button btn-success "
                onClick={() => {
                  window.scroll({ top: 0, behavior: "smooth" });
                  dispatch(asyncEditPromotionData(tableMeta.rowData[0]));
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-thin fa-pencil" aria-hidden="true"></i>
              </button>

              <button
                className="edit-delete-icon-button btn-danger ms-3"
                onClick={() => {
                  dispatch(toggleModel());
                  setModalData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Home Promotion</p>
      {/* Formik form */}
      <div>
        <Formik
          initialValues={{ image: null }}
          validationSchema={SignupSchema}
          onSubmit={(values, { resetForm }) => {
            const formData = new FormData();
            if (!values.image) {
              setErrorsForEmpty(true);
              setErrors(false);
              return;
            }
            if (values.image.name.split(".")[1] !== "png") {
              setErrors(true);
              setErrorsForEmpty(false);
            } else {
              setErrors(false);
              formData.append("image", values.image);
              if (editPromotion) {
                dispatch(
                  asyncUpdatePromotionData({ formData, id: editPromotion?.id })
                );
              } else {
                dispatch(asyncPostPromotionData(formData));
                setkeyVal(Date.now());
                resetForm();
              }
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form>
              <div className="col-12 my-4 py-3 ms-5">
                <div className="col-sm-12 d-flex flex-column">
                  <label htmlFor="" className="mb-3">
                    Promotion Image *{" "}
                    <span className="text-danger">(Size:800*300)</span>
                  </label>
                  <input
                    name="image"
                    type="file"
                    key={keyVal || ""}
                    onChange={(e) =>
                      setFieldValue("image", e.currentTarget.files[0])
                    }
                  />
                  <div className="border-bottom w-25"></div>
                  {Errors && <p className="text-danger">Only Png required</p>}
                  {errorsforempty && (
                    <p className="text-danger">image required</p>
                  )}
                </div>
              </div>
              <div className="col-md-12 text-center">
                <button
                  className="btn setup-btn text-align:center"
                  type="submit"
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? (
                    <BtnLoader />
                  ) : (
                    `${editPromotion?.id ? "Update" : "Save"}`
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {/* Fomi Form  */}
      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={showModel}
          toggleModel={toggleModel}
          rowId={modalData && modalData[0]}
          setuseEffectCall={setuseEffectCall}
          EffectCall={EffectCall}
        />

        <MUIDataTable
          className="muidatatable"
          title={"Promotion list"}
          data={promotionData}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

const DeleteModal = ({
  show,
  toggleModel,
  rowId,
  setuseEffectCall,
  EffectCall,
}) => {
  const dispatch = useDispatch();

  const deletePromotion = () => {
    dispatch(asyncDeletPromotionData(rowId));
    dispatch(toggleModel());
  };

  return (
    <Modal
      show={show}
      toggleModel={toggleModel}
      onHide={(e) => dispatch(toggleModel())}
      centered
    >
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
            <span className="text-danger">NOTE:</span> You are about to delete
            the record, this procedure is irreversible.
          </div>
          <div className="col-12 text-center mt-3">Do you want to proceed?</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button
            variant="primary modal-button"
            onClick={(e) => dispatch(toggleModel())}
          >
            Close
          </Button>
          <Button variant="danger modal-button" onClick={deletePromotion}>
            Delete
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default Promotion;
