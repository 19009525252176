import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TextField } from "@mui/material";
import {
  asyncGetBankDetailData,
  asyncPostBankDetailData,
  asyncEditBankDetailData,
  asyncUpdateBankDetailData,
  editBankDetails_null,
} from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { Formik, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../AppForm/BtnLoader";
import * as yup from "yup";
import { options } from "../../Utils/ColumnOptions";
import { ChangeStatus } from "../../Utils/ChangeStatus";

const BankDetails = () => {
  const dispatch = useDispatch();

  /*************Use selector redux ********************** */
  const { bankDetails, editBankDetails, loading } = useSelector(
    (state) => state?.bank_curr_roleSlice
  );

  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  //if the user has permission to add bank
  const addBankPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/post-bank-details"
      )
    : true;
  // if the login user has permissions to edit bank
  const editBankPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/update-bank-details"
      )
    : true;
  /******************UseEffect **************** */
  useEffect(() => {
    dispatch(asyncGetBankDetailData());
  }, [dispatch]);

  const isAccountNumberDuplicate = (values) => {
    return bankDetails?.some(
      (bank) =>
        bank.account_iban_no === values.account_no &&
        bank?.name?.toUpperCase() === values?.name?.toUpperCase()
    );
  };
  /*************************Initial Values ****************** */
  const initialValues = {
    name: editBankDetails?.name || "",
    account_no: editBankDetails?.account_iban_no || "",
    beneficiary_name: editBankDetails?.beneficiary_name || "",
    collection: editBankDetails?.collection || false,
    isSameIBAN: false,
  };
  /*********************  Validation Schema ************* */
  const validationSchema = yup.object().shape({
    name: yup.string().required("Bank Name Required"),

    account_no: yup
      .string()
      .required("Account No Required")
      .test(
        "uniqueAccountNo",
        "Bank with the same account number already exist.",
        function () {
          const values = this.parent;
          // Perform your check for uniqueness here
          return !isAccountNumberDuplicate(values);
        }
      ),
    beneficiary_name: yup.string().required("Beneficiary Name Required"),
  });
  /********************** Table Columns ******************* */
  const columns = [
    {
      name: "bank_id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "bank_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/bank-details-status",
                      !value,
                      id,
                      "bank_status"
                    );
                  }}
                />
                <label htmlFor={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "name",
      label: "Bank Name",
    },
    {
      name: "account_iban_no",
      label: "Account No / IBAN",
    },
    {
      name: "beneficiary_name",
      label: "Beneficiary Name",
    },
    {
      name: "collection",
      label: "Collection",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div className="">{value ? "Active" : "Inactive"}</div>;
        },
      },
    },
    {
      label: "Action",
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let bank_id = tableMeta?.rowData[0];
          return (
            <div className="">
              {/* {editPermission !== undefined || user?.user_id ? ( */}
              <button
                className="btn-success rounded edit-delete-icon-button"
                disabled={!editBankPermission && user.user_id !== 1}
                style={{
                  opacity: !editBankPermission && user.user_id !== 1 ? 0.5 : 1,
                }}
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncEditBankDetailData(bank_id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              {/* ) : null} */}
            </div>
          );
        },
      },
    },
  ];

  /** ******* Return Statement ************* */
  return (
    <div>
      <div className="mx-5 rounded-2 shadow-sm my-5 bg-white">
        <p className="h4 otawix-heading box_title">Add / Edit Bank</p>
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            let data = {
              bank_status: 1,
              name: values?.name,
              account_iban_no: values?.account_no,
              beneficiary_name: values?.beneficiary_name,
              collection: values?.collection,
            };
            let editData = {
              bank_id: editBankDetails?.bank_id,
              acc_id: editBankDetails?.acc_id,
              name: values?.name,
              account_iban_no: values?.account_no,
              beneficiary_name: values?.beneficiary_name,
              collection: values?.collection,
            };
            if (editBankDetails) {
              dispatch(asyncUpdateBankDetailData(editData));
              dispatch(editBankDetails_null());
            } else {
              dispatch(asyncPostBankDetailData(data));
              resetForm({});
            }
          }}
        >
          {({ values, handleChange, setFieldValue, setFieldError }) => {
            return (
              <Form>
                <div className="p-4 py-5">
                  <div className="row pb-5 align-items-end">
                    <div className="col-sm-2" style={{ height: "3rem" }}>
                      <TextField
                        autoComplete="off"
                        variant="standard"
                        className="w-100"
                        label="Bank Name"
                        name="name"
                        value={values?.name}
                        onChange={(e) => {
                          let value = e.target.value || "";
                          setFieldValue("name", value.toUpperCase());
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="name"
                      />
                    </div>
                    <div className="col-sm-2 px-3" style={{ height: "3rem" }}>
                      <TextField
                        label="Account No / IBAN"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="account_no"
                        value={values?.account_no}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("account_no", value);
                        }}
                      />

                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="account_no"
                      />
                    </div>
                    <div className="col-sm-2 px-3" style={{ height: "3rem" }}>
                      <TextField
                        label="Beneficiary Name"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="beneficiary_name"
                        value={values?.beneficiary_name}
                        onChange={(e) => {
                          let value = e.target.value || "";
                          setFieldValue(
                            "beneficiary_name",
                            value.toUpperCase()
                          );
                        }}
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger mt-1"
                        name="beneficiary_name"
                      />
                    </div>
                    <div className="col-sm-3 px-3">
                      <div className="checkbox-rect">
                        <input
                          type="checkbox"
                          id="collection"
                          name="collection"
                          onChange={handleChange}
                          checked={values?.collection}
                        />
                        <label
                          htmlFor="collection"
                          className="h6 primary_color"
                        >
                          Collection
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-end">
                    <div className="">
                      {!editBankDetails ? (
                        <button
                          className="setup-btn btn setup-btn me-3"
                          type="submit"
                          disabled={!addBankPermission && user.user_id !== 1}
                        >
                          {loading ? <BtnLoader /> : "Save"}
                        </button>
                      ) : null}
                      {editBankDetails ? (
                        <button
                          className="setup-btn btn setup-btn me-3"
                          type="submit"
                        >
                          {loading ? <BtnLoader /> : "Update"}
                        </button>
                      ) : null}
                    </div>
                    <div>
                      {editBankDetails && (
                        <button
                          className="setup-btn btn setup-btn"
                          onClick={(e) => {
                            dispatch(dispatch(editBankDetails_null()));
                          }}
                          style={{ background: "#dc3545", color: "#fff" }}
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className=" mx-5 rounded-2 shadow-sm my-5 bg-white">
        <p className="h4 otawix-heading box_title">Bank List</p>
        <div className="col-12 py-5 px-4">
          <MUIDataTable
            className="muidatatable"
            data={bankDetails}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default BankDetails;
