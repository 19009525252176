import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import countries from "../Countries.json";
import CustomDropdown from "../../CustomDrp";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Plan = ({ setFieldValue, values }) => {
  const [isCovidcovered, setCovidCovered] = useState(true);
  const [countriesDropdown, setCountriesDropdown] = useState([]);

  useEffect(() => {
    let countriesArray = countries.filter(
      (country) =>
        country?.Category === "WORLDWIDE" || country?.Category === "SCENGEN"
    );
    setCountriesDropdown(
      countriesArray?.map((coun) => {
        return coun?.Countries;
      })
    );
  }, []);

  const arry = [
    "7 days",
    "10 days",
    "15 days",
    "21 days",
    "31 days",
    "62 days",
    "92 days",
    "180 days",
    "360 days",
    "2 years",
  ];

  // const TripType = (value) => {
  //   setFormData({ ...formData, trip: value });
  //   let countriesArray = [];
  //   if (value === "WORLDWIDE") {
  //     countriesArray = countries.filter(
  //       (country) =>
  //         country.Category === "WORLDWIDE" || country.Category === "SCENGEN"
  //     );
  //   } else {
  //     countriesArray = countries.filter(
  //       (country) => country.Category === value
  //     );
  //   }
  //   setCountriesDropdown(
  //     countriesArray.map((coun) => {
  //       return coun.Countries;
  //     })
  //   );
  // };

  return (
    <div className="row">
      <div className="d-flex" style={{ marginTop: 12, marginBottom: 8 }}>
        <div style={{ width: "30%", marginRight: 10 }}>
          <div className="insurance_boxes p-2">
            <h5>Covid Protection:</h5>
            <div className="d-flex flex-column flex-md-row py-4 justify-content-around">
              <label class="form-control">
                <input
                  type="radio"
                  id="covid_protection"
                  name="covid_protection"
                  value={values?.covid_protection}
                  checked={values.covid_protection === "Covid Covered"}
                  onChange={() =>
                    setFieldValue("covid_protection", "Covid Covered")
                  }
                />
                <h6>Covid Covered</h6>
              </label>
              <label class="form-control ms-4">
                <input
                  type="radio"
                  name="covid_protection"
                  value={values?.covid_protection}
                  id="covid_protection"
                  checked={values.covid_protection === "Covid Not Covered"}
                  onChange={() =>
                    setFieldValue("covid_protection", "Covid Not Covered")
                  }
                />
                <h6>Covid Not Covered</h6>
              </label>
            </div>
          </div>
        </div>
        <div style={{ width: "20%", marginRight: 10 }}>
          <div className="insurance_boxes p-2">
            <h5>Insurer:</h5>
            <div className="d-flex py-4 justify-content-around">
              <label className="form-control me-3 me-md-5">
                <input
                  type="radio"
                  name="insurer"
                  value="Single"
                  checked={values.insurer === "Single"}
                  onChange={() => setFieldValue("insurer", "Single")}
                />
                <h6>Single</h6>
              </label>
              <label className="form-control me-3 me-md-5">
                <input
                  type="radio"
                  name="insurer"
                  value="Family"
                  checked={values.insurer === "Family"}
                  onChange={() => setFieldValue("insurer", "Family")}
                />
                <h6>Family</h6>
              </label>
            </div>
          </div>
        </div>

        <div style={{ width: "50%" }}>
          <div className="insurance_boxes p-2">
            <h5>Destination:</h5>
            <div className="d-flex justify-content-around py-4">
              <label className="form-control">
                <input
                  type="radio"
                  name="destination"
                  value="Single World wide"
                  checked={values.destination === "Single World wide"}
                  onChange={() =>
                    setFieldValue("destination", "Single World wide")
                  }
                />
                <h6>Single World wide</h6>
              </label>
              <label className="form-control">
                <input
                  type="radio"
                  name="destination"
                  value="Rest Of The world"
                  checked={values.destination === "Rest Of The world"}
                  onChange={() =>
                    setFieldValue("destination", "Rest Of The world")
                  }
                />
                <h6>Rest Of The world</h6>
              </label>
              <label className="form-control">
                <input
                  type="radio"
                  name="destination"
                  value="Schengen Countries"
                  checked={values.destination === "Schengen Countries"}
                  onChange={() =>
                    setFieldValue("destination", "Schengen Countries")
                  }
                />
                <h6>Schengen Countries</h6>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 my-5">
        <div className="row align-items-end">
          <div className="col-sm-2 mb-3 mb-md-0">
            <ReactDatePicker
              minDate={new Date()}
              monthsShown={1}
              className="date_picker mt-4"
              selected={values.ins_date}
              placeholderText="From Date"
              onChange={(date) => setFieldValue("ins_date", date)}
            />
          </div>
          <div className="col-6 col-sm-2">
            <FormControl variant="standard" className="w-100" sx={{}}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Days
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={values?.days}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("days", value);
                }}
                label="Select Days"
              >
                {arry?.map((list) => (
                  <MenuItem
                    value={list}
                    sx={{ m: 1, bgcolor: "#fff" }}
                    key={list}
                  >
                    {list}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-6 col-sm-2">
            <FormControl variant="standard" className="w-100" sx={{}}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Country
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300,
                    },
                  },
                }}
                value={values?.country}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("country", value);
                }}
                label="Select Country"
              >
                {countriesDropdown?.map((list) => (
                  <MenuItem
                    value={list}
                    sx={{ m: 1, bgcolor: "#fff" }}
                    key={list}
                  >
                    {list}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plan;
