import React, { useState } from "react";
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { asyncSearchAgencyData } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

const Fare = ({ values, setFieldValue, FieldArray }) => {
  const dispatch = useDispatch();
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const { searchAgency } = useSelector((state) => state.newAgency);
  return (
    <div>
      <div className="col-6 col-sm-1">
        <div className="d-flex justify-content-between">
          <label class="form-control me-3 me-md-5">
            <input type="radio" name="radio" id="PSF" />
            <span className="h6">PSF</span>
          </label>
          <label class="form-control me-3 me-md-5">
            <input type="radio" name="radio" id="SP" />
            <span className="h6">SP</span>
          </label>
        </div>
      </div>

      <div className="row col-12 pt-2">
        <div className="row col-3">
          <div className="col-6">
            <TextField
              variant="standard"
              label="Adult Base Fare"
              className="w-100"
              type="number"
              name={`adt_base_fare`}
              value={values?.adt_base_fare}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue("adt_base_fare", value);
              }}
            />
          </div>
          <div className="col-6">
            <TextField
              variant="standard"
              label="PSF/SP percent"
              className="w-100"
              type="number"
              name={`psf_sp_per`}
              value={values?.psf_sp_per}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue("psf_sp_per", value);
              }}
            />
          </div>
        </div>

        <div className="row col-5">
          <div className="col-md-3">
            <TextField
              name={`tax_code`}
              variant="standard"
              label="Total Taxes XT"
              className="w-100"
              value={values?.tax_code}
              onChange={(e) => {
                let value = e.target.value.toUpperCase().replace(/[^A-Z]/g, "");
                value = value.substring(0, 2); 
                setFieldValue(`tax_code`, value);
              }}
            />
          </div>
          <div className="col-md-4">
            <TextField
              id={`tax_amount`}
              name={`tax_amount`}
              variant="standard"
              label="Total Tax Amount"
              type="number"
              value={values?.tax_amount}
              className="w-100"
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue(`tax_amount`, value);
              }}
            />
          </div>
          <div className="col-md-5">
            <FormControl
              variant="standard"
              className="w-100"
              onClick={() => setagentDRopDwn(!agentDRopDwn)}
            >
              <InputLabel htmlFor="supplier">Select Agent</InputLabel>
              <Input
                variant="standard"
                label="Select Agent"
                autoComplete="off"
                className="w-100"
                name="agent"
                value={values?.agent}
                onChange={(e) => {
                  let value = e.target.value;
                  setFieldValue("agent", value);
                  dispatch(asyncSearchAgencyData(value));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {values?.agent !== "" && (
                      <IconButton
                        onClick={() => {
                          setFieldValue("agent", "");
                        }}
                      >
                        <MdClose />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </FormControl>

            {agentDRopDwn ? (
              <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
                <div className="agent_dropdown">
                  {searchAgency?.length > 0 ? (
                    searchAgency?.map((item, index) => {
                      return (
                        <div
                          className="p-3"
                          onClick={(e) => {
                            setFieldValue("agent", item.agent_name);
                            setagentDRopDwn(false);
                          }}
                          style={{ cursor: "pointer" }}
                          key={index}
                        >
                          {item.agent_name}
                        </div>
                      );
                    })
                  ) : (
                    <div className="p-3">No record available.</div>
                  )}
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        </div>
        <div className="row col-2">
          <button className="setup-btn" type="submit">
            Generate Booking
          </button>
        </div>
      </div>
    </div>
  );
};

export default Fare;
