import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import RequestModal from "./RequestsModal";
import axios from "axios";
import { URL2 } from "../../redux/features/ApiConfig";

export default function Tab({ data, booking }) {
  
  const [toggleModel, setToggleModel] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState({});
  const { flightgetData } = useSelector((state) => state?.flightrequest);
  const { newAgencyRegData } = useSelector((state) => state.newAgency);

  const columns = [
    {
      name: "req_resolve_dateTime",
      label: "Date/Time",
    },
    {
      name: "req_id",
      label: "Agent Name | User Name",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { agents_comp, agents_user } = flightgetData?.find(
            (item) => item?.req_id === value
          );
          return (
            <div className="d-flex gap-1">
              <p>{agents_comp?.agent_name}</p>|{" "}
              <p>{agents_user?.f_name + " " + agents_user?.l_name}</p>
            </div>
          );
        },
      },
    },
    {
      name: "book_id",
      label: "Booking Id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p style={{ color: "#457a7c" }}>{value}</p>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "request_type",
      label: "Request type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "agent_note",
      label: "Agent Description",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: "200px", wordWrap: "break-word" },
        }),
      },
    },
    {
      name: "admin_note",
      label: "Admin Description",
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { width: "200px", wordWrap: "break-word" },
        }),
      },
    },
    {
      label: "Action",
      name: "req_id",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <button
                className="btn setup-btn"
                onClick={() => {
                  setToggleModel(!toggleModel);
                  setSelectedRequest(
                    data?.find(({ req_id }) => req_id === value)
                  );
                }}
              >
                Process
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    sortOrder: {
      name: "time",
      direction: "desc",
    },
    rowsPerPage: 50,
    filter: false,
    Selection: false,

    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    enableNestedDataAccess: ".",
    search: false, // Enable search
  };

  return (
    <>
      <div class=" ">
        <MUIDataTable
          className="muidatatable"
          title={"Request Table"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
      {toggleModel && (
        <RequestModal
          show={toggleModel}
          setToggleModel={setToggleModel}
          booking={booking}
          selectedRequest={selectedRequest}
          agencyData={newAgencyRegData?.find(
            (ag) => ag?.acc_id === booking?.agent_acc_id
          )}
        />
      )}
    </>
  );
}
