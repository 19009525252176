import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { options } from "../../Utils/ColumnOptions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  InputAdornment,
  Input,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import {
  asyncGetUserData,
  asyncGetAllUserData,
  asyncPostUserData,
  asyncEditUserData,
  asyncUpdateUserData,
  asyncDeletUserData,
  nullEditValue,
} from "../../redux/features/agency/usersSlice";
import { VisibilityOff, Visibility } from "@mui/icons-material";

import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../AppForm/BtnLoader";
import DeleteModal from "../DeleteModal";
import * as yup from "yup";
import { asyncGetRoleData } from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { NODEURL } from "../../redux/features/ApiConfig";
import { asynGetAllAgencyUser } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { dropMenuProp } from "../../Utils/MenuProps";

const User = () => {
  const [ShowModel, setShowModel] = useState(false);
  const [check, setCheck] = useState();
  const [rowData, setRowData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const { allUsersData, editUserData, loading } = useSelector(
    (state) => state?.usersSlice
  );
  const { allAgencyUser } = useSelector((state) => state?.newAgency);

  const { roleData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const user_id = user?.user_id;

  // for the checks of status and update delete
  const role = user?.role_id;
  const user_role = {
    1: "Admin",
    2: "General Manager",
    5: "Finance Manager",
    6: "General Manager Sub Agent",
    9: "Sales Manager",
  };

  // Check if the login user has permission to add user
  const addUserPermission =
    user.user_id !== 1
      ? userPermission?.find(
          (it) =>
            it?.user_id === user?.user_id &&
            it?.page_level.permission_url === "/post-user"
        )
      : true;

  // Check if the login user has permission to edit user
  const editUserPermission =
    user.user_id !== 1
      ? userPermission?.find(
          (it) =>
            it?.user_id === user?.user_id &&
            it?.page_level.permission_url === "/update-user"
        )
      : true;

  // Check if the login user has permission to change user status
  const changeStatusPermission =
    user.user_id !== 1
      ? userPermission?.find(
          (it) =>
            it?.user_id === user?.user_id &&
            it?.page_level.permission_url === "/user-status"
        )
      : true;

  useEffect(() => {
    dispatch(asyncGetUserData());
    dispatch(asyncGetAllUserData());
    dispatch(asyncGetRoleData());
    dispatch(asynGetAllAgencyUser());
  }, [dispatch, editUserData, check]);

  const roleStatus = async (status, id) => {
    if (!status) {
      var statusrow = 0;
    } else if (status) {
      var statusrow = 1;
    } else if (status === 0) {
      var statusrow = 0;
    } else if (status === 1) {
      var statusrow = 1;
    }
    const formdata = {
      id: id,
      status: statusrow,
    };

    NODEURL.patch(`/api/v1/b2b/agent-user-status/${id}`, {
      status: formdata.status,
    })
      .then((res) => {
        dispatch(asyncGetAllUserData());
      })
      .catch((err) => {});
  };

  const columns = [
    {
      name: "user_id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "status",
      label: "status",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "role_id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          const obj = allUsersData?.find((userId) => userId.user_id === id);
          return (
            <div className="checkbox-rect">
              <input
                checked={obj?.status}
                value={obj?.status}
                type="checkbox"
                id={"with_sar_" + id}
                onChange={(event) => {
                  setCheck(event.target.checked);
                  updateValue(event.target.checked ? 1 : 0);
                  roleStatus(event.target.checked ? 1 : 0, id);
                  dispatch(asyncGetAllUserData());
                }}
              />
              {value === role ||
                !user_role[role] ||
                (value === 2 && role === 5) ||
                (value === 5 && role === 2) ||
                (value === 9 && role === 5) ||
                (value === 5 && role === 9) ||
                (value === 2 && role === 9) ||
                (value === 9 && role === 2) ||
                (value === 6 && role === 7) ||
                (value === 1 &&
                  (user_role[role] === "General Manager" ||
                    user_role[role] === "Finance Manager" ||
                    user_role[role] === "Sales Manager")) || (
                  <label htmlFor={"with_sar_" + id} className="h6"></label>
                )}
            </div>
          );
        },
      },
    },
    {
      name: "l_name",
      label: "Name",
      options: {
        filter: false,
        display: false,
        sort: true,
      },
    },
    {
      name: "f_name",
      label: "Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <p>{` ${value} ${tableMeta?.rowData[3]}`}</p>;
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "role_id",
      label: "Access Level",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const roleName = roleData?.find((it) => it.role_id === value);
          return <p>{roleName?.role_name}</p>;
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "created_at",
      label: "Created Date",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      label: "Actions",
      name: "role_id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta?.rowData[0];
          return (
            <div>
              <>
                {(id !== user_id && value === role) ||
                  !user_role[role] ||
                  (value === 2 && role === 5) ||
                  (value === 5 && role === 2) ||
                  (value === 5 && role === 9) ||
                  (value === 9 && role === 5) ||
                  (value === 2 && role === 9) ||
                  (value === 9 && role === 2) ||
                  (value === 6 && role === 7) ||
                  (value === 1 &&
                    (user_role[role] === "General Manager" ||
                      user_role[role] === "Finance Manager" ||
                      user_role[role] === "Sales Manager")) || (
                    <button
                      className="btn-success rounded edit-delete-icon-button me-2"
                      onClick={() => {
                        window.scroll({ top: 0 });
                        dispatch(asyncEditUserData(id));
                      }}
                    >
                      <i className="fa fa-thin fa-pencil"></i>
                    </button>
                  )}
                {role !== 1 &&
                  id === user_id &&
                  role !== 2 &&
                  role !== 5 &&
                  role !== 6 &&
                  role !== 9 && (
                    <button
                      className="btn-success rounded edit-delete-icon-button me-2"
                      onClick={() => {
                        window.scroll({ top: 0 });
                        dispatch(asyncEditUserData(id));
                      }}
                    >
                      <i className="fa fa-thin fa-pencil"></i>
                    </button>
                  )}
                {value === role ||
                  !user_role[role] ||
                  (value === 2 && role === 5) ||
                  (value === 5 && role === 2) ||
                  (value === 5 && role === 9) ||
                  (value === 9 && role === 5) ||
                  (value === 2 && role === 9) ||
                  (value === 9 && role === 2) ||
                  (value === 6 && role === 7) ||
                  (value === 1 &&
                    (user_role[role] === "General Manager" ||
                      user_role[role] === "Finance Manager" ||
                      user_role[role] === "Sales Manager")) || (
                    <button
                      type="button"
                      className="btn-danger rounded edit-delete-icon-button"
                      onClick={() => {
                        setShowModel(true);
                        setRowData(id);
                      }}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  )}
              </>
            </div>
          );
        },
      },
    },
  ];

  const initialValues = {
    f_name: editUserData?.f_name || "",
    l_name: editUserData?.l_name || "",
    email: editUserData?.email || "",
    User_Access_Level: editUserData
      ? editUserData?.role_id
      : user?.role_id === 6
      ? 7
      : 3,
    password: "",
    rep_password: "",
    mobile: editUserData?.mobile_no || "",
  };

  const validationSchema = yup.object().shape({
    f_name: yup.string().required("First Name Required"),
    l_name: yup.string().required("Last Name Required"),
    email: yup.string().required("Email Required"),
    User_Access_Level: yup.string().required("User Access Level Required"),
    password: yup
      .string()
      .min(8, "Must be Atleast 8 characters long")
      .max(25)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/,
        "Must Contain , One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    rep_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    mobile: yup.string().required("Mobile Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const f_name = values.f_name;
    const l_name = values.l_name;
    const email = values.email;
    const password = values.password;
    const mobile_no = values.mobile;
    // const role_id = 1;
    const role_id = values.User_Access_Level;
    const obj = {
      agent_id: user?.agent_id,
      role_id,
      f_name,
      l_name,
      mobile_no,
      email,
      password,
      status: editUserData?.status || 1,
    };

    if (editUserData) {
      dispatch(asyncUpdateUserData({ id: editUserData?.user_id, obj }));
      dispatch(nullEditValue());
      dispatch(asynGetAllAgencyUser());
    } else {
      dispatch(asyncPostUserData(obj));
      resetForm();
    }
  };
  return (
    <div className="pb-5">
      {[1, 2, 5, 6].includes(user?.role_id) ||
      editUserData ||
      addUserPermission !== undefined ? (
        <div className="mx-5 rounded-2 shadow-sm mt-5 pb-1 bg-white mb-3">
          <p className="otawix-heading box_title">Add / Edit Company User</p>
          <div className="px-4">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validateOnBlur={false}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue, handleChange }) => {
                return (
                  <Form>
                    <div className="col-12 d-flex my-5">
                      <div className="col-sm-2 px-3">
                        <TextField
                          label="First Name"
                          variant="standard"
                          className="w-100"
                          value={values.f_name}
                          inputProps={{
                            readOnly:
                              editUserData && user_id !== 1 ? true : false,
                          }}
                          name="f_name"
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("f_name", value.toUpperCase());
                          }}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          component={"div"}
                          className="text-danger mt-1"
                          name="f_name"
                        />
                      </div>
                      <div className="col-sm-2 px-3">
                        <TextField
                          label="Last Name"
                          variant="standard"
                          className="w-100"
                          value={values.l_name}
                          inputProps={{
                            readOnly:
                              editUserData && user_id !== 1 ? true : false,
                          }}
                          name="l_name"
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("l_name", value.toUpperCase());
                          }}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          component={"div"}
                          className="text-danger mt-1"
                          name="l_name"
                        />
                      </div>
                      <div className="col-sm-2 px-3">
                        <TextField
                          label="Email"
                          variant="standard"
                          className="w-100"
                          value={values.email}
                          inputProps={{
                            readOnly:
                              editUserData && user_id !== 1 ? true : false,
                          }}
                          name="email"
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("email", value.toUpperCase());
                          }}
                          autoComplete="off"
                        />
                        <ErrorMessage
                          component={"div"}
                          className="text-danger mt-1"
                          name="email"
                        />
                      </div>
                      <div className="col-sm-2 px-3">
                        <TextField
                          label="Mobile"
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          type="number"
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component={"div"}
                          className="text-danger mt-1"
                          name="mobile"
                        />
                      </div>
                    </div>

                    <div className="col-12 d-flex mt-5">
                      <div className="col-sm-2 px-3">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            User Access Level
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            label="User Access Level"
                            name="User_Access_Level"
                            value={values?.User_Access_Level}
                            onChange={handleChange}
                            inputProps={{
                              readOnly:
                                ((user?.role_id === 3 || user?.role_id === 4) &&
                                  editUserData &&
                                  user_id !== 1) ||
                                user?.role_id === editUserData?.role_id
                                  ? true
                                  : false,
                            }}
                            MenuProps={dropMenuProp}
                          >
                            {/* if logged in user admin dont show G m sub agent and call center sub agent */}
                            {user?.role_id === editUserData?.role_id
                              ? roleData
                                  .filter(
                                    (edrole) =>
                                      edrole.role_id === editUserData.role_id
                                  )
                                  .map((ro) => (
                                    <MenuItem
                                      value={ro?.role_id}
                                      sx={{ m: 1, bgcolor: "#fff" }}
                                      key={ro.role_id}
                                    >
                                      {ro?.role_name}
                                    </MenuItem>
                                  ))
                              : roleData
                                  ?.filter((it) =>
                                    user?.role_id === 1
                                      ? it.role_id !== 1 &&
                                        it.role_id !== 6 &&
                                        it.role_id !== 7
                                      : user?.role_id === 2 ||
                                        user?.role_id === 5 ||
                                        user?.role_id === 9
                                      ? it.role_id === 3 ||
                                        it.role_id === 4 ||
                                        it.role_id === 8
                                      : user?.role_id === 6
                                      ? it.role_id === 7
                                      : it.role_id === user?.role_id
                                  )
                                  .map((list) => (
                                    <MenuItem
                                      value={list?.role_id}
                                      sx={{ m: 1, bgcolor: "#fff" }}
                                      key={list?.role_id}
                                    >
                                      {list?.role_name}
                                    </MenuItem>
                                  ))}
                          </Select>
                        </FormControl>
                        <ErrorMessage
                          component={"div"}
                          className="text-danger mt-1"
                          name="User_Access_Level"
                        />
                      </div>
                      <div className="col-sm-2 px-3">
                        <FormControl sx={{ width: "100%" }} variant="standard">
                          <InputLabel htmlFor="standard-adornment-password">
                            Password
                          </InputLabel>
                          <Input
                            id="standard-adornment-password"
                            type={showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange}
                            name="password"
                            autoComplete="off"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={(e) =>
                                    setShowPassword(!showPassword)
                                  }
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <ErrorMessage
                          component={"div"}
                          className="text-danger mt-1"
                          name="password"
                        />
                      </div>
                      <div className="col-sm-2 px-3">
                        <TextField
                          label="Confirm Password"
                          variant="standard"
                          className="w-100"
                          autoComplete="off"
                          type="password"
                          name="rep_password"
                          value={values.rep_password}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          component={"div"}
                          className="text-danger mt-1"
                          name="rep_password"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-end my-5 pb-2">
                      <div className="me-3">
                        {!editUserData ? (
                          <button
                            type="submit"
                            className="btn setup-btn"
                            disabled={!addUserPermission && user?.user_id !== 1}
                          >
                            {loading ? <BtnLoader /> : "Save"}
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn setup-btn"
                            disabled={
                              !editUserPermission && user?.user_id !== 1
                            }
                          >
                            {loading ? <BtnLoader /> : "Update"}
                          </button>
                        )}
                      </div>
                      <div className="me-2">
                        {editUserData && (
                          <button
                            className="setup-btn btn setup-btn me-2"
                            style={{ background: "#dc3545", color: "#fff" }}
                            onClick={() => {
                              dispatch(nullEditValue());
                            }}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      ) : null}
      <div className="mx-5 rounded-2 shadow-sm mt-5 bg-white mb-3">
        <p className="otawix-heading box_title">Company User List</p>
        <div className="p-4">
          <DeleteModal
            show={ShowModel}
            setShow={setShowModel}
            onClick={(e) => {
              dispatch(asyncDeletUserData(rowData));
              setShowModel(false);
            }}
          />
          <MUIDataTable
            className="muidatatable"
            title={""}
            data={allAgencyUser}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default User;
