import React from 'react'
import CircularComp from '../components/Setup/Promotions/Circular'

const Circular = () => {
  return (
    <div>
      <CircularComp />
    </div>
  )
}

export default Circular