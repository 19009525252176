import { TextField } from "@mui/material";
import React from "react";
import { TwoDecimalPoint } from "../../../../../../Utils/FixedTwoDecimal";

function SupplierPkr({ setFieldValue, values, friend, index, auto_manual }) {
  return (
    <div className="col-6">
      <div className="row shadow-sm rounded pb-3 red_border me-1">
        <div className="h5 py-3">Supplier In PKR</div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.s_psf_per}
            id={`fareValues.[${index}].tax_pkr.s_psf_per`}
            onChange={(e) => {
              const calgst =
                (e.target.value / 100) * friend?.tax_pkr?.s_gst_per;
              const calpsf =
                (values.fareValues[index].b_fare / 100) * e.target.value;
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_psf_per`,
                e.target.value
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_psf`,
                TwoDecimalPoint(calpsf)
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_gst`,
                TwoDecimalPoint(calgst)
              );
            }}
            label="S.PSF %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.s_sp_per > 0 || auto_manual === 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.s_psf}
            id={`fareValues.[${index}].tax_pkr.s_psf`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_psf`,
                e.target.value
              );
            }}
            label="S.PSF"
            variant="standard"
            type={"number"}
            disabled={
              friend.tax_pkr.s_sp > 0 ||
              friend.tax_pkr.s_psf_per > 0 ||
              auto_manual === 0
            }
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.s_gst_per}
            id={`fareValues.[${index}].tax_pkr.s_gst_per`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_gst_per`,
                e.target.value
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_gst`,
                TwoDecimalPoint(
                  (values.fareValues[index].tax_pkr?.s_psf / 100) *
                    e.target.value
                )
              );
            }}
            label="S.GST %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.s_sp_per > 0 || auto_manual === 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.s_gst}
            id={`fareValues.[${index}].tax_pkr.s_gst`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_gst`,
                e.target.value
              );
            }}
            label="S.GST"
            variant="standard"
            type={"number"}
            disabled={
              friend.tax_pkr.s_sp > 0 ||
              friend.tax_pkr.s_gst_per > 0 ||
              auto_manual === 0
            }
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.s_sp_per}
            id={`fareValues.[${index}].tax_pkr.s_sp_per`}
            onChange={(e) => {
              const swhtper =
                (e.target.value / 100) * friend?.tax_pkr?.s_wht_per;
              const calssp =
                (values.fareValues[index].b_fare / 100) * e.target.value;

              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_sp_per`,
                e.target.value
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_sp`,
                TwoDecimalPoint(calssp)
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_wht`,
                TwoDecimalPoint(swhtper)
              );
            }}
            label="S.DISC %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.s_psf_per > 0 || auto_manual === 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.s_sp}
            id={`fareValues.[${index}].tax_pkr.s_sp`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_sp`,
                e.target.value
              );
            }}
            label="S.DISC"
            variant="standard"
            type={"number"}
            disabled={
              friend.tax_pkr.s_psf > 0 ||
              friend.tax_pkr.s_sp_per > 0 ||
              auto_manual === 0
            }
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.s_wht_per}
            id={`fareValues.[${index}].tax_pkr.s_wht_per`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_wht_per`,
                e.target.value
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_wht`,
                TwoDecimalPoint(
                  (values.fareValues[index].tax_pkr?.s_sp / 100) *
                    e.target.value
                )
              );
            }}
            label="S.WHT %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.s_psf_per > 0 || auto_manual === 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.s_wht}
            id={`fareValues.[${index}].tax_pkr.s_wht`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_wht`,
                e.target.value
              );
            }}
            label="S.WHT"
            variant="standard"
            type={"number"}
            disabled={
              friend.tax_pkr.s_psf > 0 ||
              friend.tax_pkr.s_wht_per > 0 ||
              auto_manual
            ===0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SupplierPkr;
