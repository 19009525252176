import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncEditAddNewSupplier,
  asyncGetCategory,
  asyncGetNewSupplier,
  asyncPostAddNewSupplier,
  asyncUpdateAddNewSupplier,
  editNewSupplierNull,
} from "../../redux/features/supplier/AddNewSupplier";
import { options } from "../../Utils/ColumnOptions";
import BtnLoader from "../AppForm/BtnLoader";
import * as yup from "yup";
import { Form, Formik } from "formik";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  OutlinedInput,
} from "@mui/material";

import ErrorMessage from "../AppForm/ErrorMessage";
import { asyncGetCurrencyData } from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";
import { ChangeStatus } from "../../Utils/ChangeStatus";
import { dropMenuProp } from "../../Utils/MenuProps";
import { asyncGetNewAgencyReg } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

const Supplier = () => {
  const dispatch = useDispatch();
  //usestate
  const [catagoryVal, setCatagoryVal] = useState();
  const [suppleirCateg, setSupplierCatag] = useState();
  //useSelector
  const { newSupplier, loading, editNewSupplier, supplierCatagory } =
    useSelector((state) => state.supplier);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { newAgencyRegData } = useSelector((state) => state.newAgency);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);

  // Check if the login user has permission to add supplier
  const addSuppPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/supplier-post"
      )
    : true;
  // Check if the login user has permission to edit supplier
  const editSuppPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/supplier-edit"
      )
    : true;

  //table colums
  const columns = [
    {
      label: "id",
      name: "list_id",
      options: {
        display: "none",
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div class="checkbox-rect">
              <input
                value={value}
                checked={value}
                type="checkbox"
                id={"with_sar_" + id}
                onChange={(event) => {
                  updateValue(!value);
                  ChangeStatus(
                    "/api/v1/b2b/supplier-list/status",
                    !value,
                    id,
                    "status"
                  );
                }}
              />
              <label for={"with_sar_" + id} className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "cat_id",
      label: "Supplier Category",
      options: {
        customBodyRender: (value) => {
          let sup_Categ = supplierCatagory?.find((it) => it.cat_id === value);
          return <div>{sup_Categ?.cat_name}</div>;
        },
      },
    },
    {
      name: "supp_name",
      label: "Supplier Name",
    },
    {
      name: "ctc_no",
      label: "Contact",
    },
    {
      name: "ctc_email",
      label: "Email",
    },
    {
      name: "ctc_address",
      label: "Address",
    },
    {
      name: "currency.curr_name",
      label: "Currency",
      options: {
        customBodyRender: (value, tableMeta) => {
          let list_id = tableMeta.rowData[0];
          let currency = newSupplier?.find((item) => item.list_id === list_id);
          return (
            <div>
              {currency.currency.curr_code + ":" + currency.currency.curr_name}
            </div>
          );
        },
      },
    },
    {
      name: "roe",
      label: "ROE",
    },
    {
      name: "balance",
      label: "Balance",
    },
    {
      name: "commission",
      label: "Comm",
    },
    {
      name: "refund",
      label: "Refund",
    },
    // {
    //   name: "bsp",
    //   label: "BSP",
    //   options: {
    //     customBodyRender: (value, tableMeta) => {
    //       return (
    //         <div>
    //           {newSupplier[tableMeta?.rowIndex]?.bsp === 1 &&
    //           newSupplier[tableMeta?.rowIndex]?.cat_id === 3
    //             ? "YES"
    //             : newSupplier[tableMeta?.rowIndex]?.bsp === 0 &&
    //               newSupplier[tableMeta?.rowIndex]?.cat_id === 3
    //             ? "NO"
    //             : ""}
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "gds",
      label: "GDS",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {newSupplier[tableMeta?.rowIndex]?.gds === 1 &&
              newSupplier[tableMeta?.rowIndex]?.cat_id === 2
                ? "YES"
                : newSupplier[tableMeta?.rowIndex]?.gds === 0 &&
                  newSupplier[tableMeta?.rowIndex]?.cat_id === 2
                ? "NO"
                : ""}
            </div>
          );
        },
      },
    },
    {
      label: "Action",
      name: "checkbox",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <button
              className="btn-success rounded edit-delete-icon-button"
              disabled={!editSuppPermission && user?.user_id !== 1}
              style={{
                opacity: !editSuppPermission && user?.user_id !== 1 ? 0.5 : 1,
              }}
              onClick={() => {
                window.scroll({ top: 0, behavior: "smooth" });
                dispatch(asyncEditAddNewSupplier(id));
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  //useEffect
  useEffect(() => {
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetCurrencyData());
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetCategory());
  }, [dispatch]);

  const isAgentAlreadyExist = (values) => {
    return newAgencyRegData?.some(
      (agent) =>
        agent?.agent_name?.toUpperCase() === values?.supp_name?.toUpperCase() &&
        values.add_as_agent
    );
  };
  //validation
  const validationSchem = yup.object().shape({
    cat_id: yup.string().required("Category Required"),
    supp_name: yup.string().required("Supplier name is Required"),
    // the condition is done on the base of fields hide and show upon the catagory id
    // in future if catagory id is changed then you have to change the catagory id for all fields alos coz fields hide and show is also done on the base of id
    commission:
      catagoryVal !== 1 &&
      catagoryVal !== 4 &&
      catagoryVal !== undefined &&
      yup.number().label("").min(1).max(100),
    roe: yup.number(),
    balance: yup.number(),
    refund: suppleirCateg === 5 && yup.number().required("Refund Required"),
    ctc_no: yup.string().required("Contact No Required"),
    currency_id: yup.number().required("Currency Required"),
    ctc_email: yup.string().required("Email Required"),
    ctc_address: yup.string().required("Address Required"),
    add_as_agent: yup
      .boolean()
      .test(
        "uniqueagentname",
        "Agent with the same name already exist in the agent list.",
        function () {
          const values = this.parent;
          // Perform your check for uniqueness here
          return !isAgentAlreadyExist(values);
        }
      ),
  });
  //initial values
  const initialValues = {
    cat_id: (editNewSupplier && editNewSupplier?.cat_id) || 1,
    supp_name: editNewSupplier?.supp_name || "",
    commission: parseInt(editNewSupplier?.commission) || "",
    roe: parseInt(editNewSupplier?.roe) || "",
    balance: editNewSupplier?.balance || "",
    refund: parseInt(editNewSupplier?.refund) || "",
    ctc_no: editNewSupplier?.ctc_no || "",
    currency_id: editNewSupplier?.currency_id || 1,
    ctc_email: editNewSupplier?.ctc_email || "",
    ctc_address: editNewSupplier?.ctc_address || "",
    bsp: editNewSupplier?.bsp || 0,
    gds: parseInt(editNewSupplier?.gds) || 0,
    add_as_agent: false,
  };

  return (
    <div>
      <div className="mx-5 rounded-2 shadow-sm mt-5 pb-1 bg-white mb-3">
        <p className="otawix-heading box_title">Supplier Data</p>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchem}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, action) => {
            const obj = {
              cat_id: values.cat_id,
              supp_name: values.supp_name,
              ctc_no: values.ctc_no,
              ctc_email: values.ctc_email,
              ctc_address: values.ctc_address,
              currency_id: values.currency_id,
              roe: Number(values.roe),
              balance: values?.balance,
              commission: Number(values.commission),
              refund: Number(values.refund),
              bsp: Number(values.bsp),
              gds: Number(values.gds),
            };
            const updObj = {
              cat_id: values.cat_id,
              supp_name: values.supp_name,
              ctc_no: values.ctc_no,
              ctc_email: values.ctc_email,
              ctc_address: values.ctc_address,
              currency_id: values.currency_id,
              roe: Number(values.roe),
              balance: values.balance,
              commission: Number(values.commission),
              refund: Number(values.refund),
              bsp: values.cat_id === 2 ? Number(values.bsp) : 0,
              gds: values.cat_id === 2 ? Number(values.gds) : 0,
              acc_id: editNewSupplier?.acc_id,
            };
            if (editNewSupplier) {
              dispatch(
                asyncUpdateAddNewSupplier({
                  updObj,
                  id: editNewSupplier?.list_id,
                })
              );
              dispatch(editNewSupplierNull({}));
            } else {
              dispatch(asyncPostAddNewSupplier(obj));
              action.resetForm({ values: "" });
            }
          }}
        >
          {({ values, setFieldValue, errors, handleChange }) => {
            let error = newSupplier?.find((item) => {
              return (
                item.supp_name === values.supp_name &&
                item.cat_id === values.cat_id
              );
            });
            return (
              <Form>
                <div className="col-12 mx-auto rounded-2 mt-5 bg-white">
                  <div className="row px-4">
                    <div className="col-2">
                      {/* <FormControl variant="standard" className="w-100">
                        <Select
                          multiple
                          displayEmpty
                          value={catagoryVal}
                          onChange={(e) => {
                            setCatagoryVal(e.target.value);
                            setFieldValue("cat_id", e.target.value);
                            setSupplierCatag(e.target.value);
                          }}
                          name="cat_id"
                          input={<OutlinedInput />}
                          // renderValue={(selected) => {
                          //   if (selected.length === 0) {
                          //     return <em>Placeholder</em>;
                          //   }

                          //   return selected.join(", ");
                          // }}
                          // MenuProps={dropMenuProp}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem disabled value="">
                            <em>Select Service Type</em>
                          </MenuItem>
                          {supplierCatagory?.map((typ) => (
                            <MenuItem value={typ.cat_id} key={typ.cat_id}>
                              {typ.cat_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Supplier Catagory
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.cat_id}
                          onChange={(e) => {
                            setCatagoryVal(e.target.value);
                            setFieldValue("cat_id", e.target.value);
                            setSupplierCatag(e.target.value);
                          }}
                          name="cat_id"
                          MenuProps={dropMenuProp}
                        >
                          {supplierCatagory?.map((typ) => (
                            <MenuItem value={typ.cat_id} key={typ.cat_id}>
                              {typ.cat_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage errors={errors && errors.cat_id} />
                      </FormControl>
                    </div>
                    <div className="col-2">
                      <TextField
                        label="Supplier Name"
                        variant="standard"
                        name="supp_name"
                        value={values.supp_name}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("supp_name", value.toUpperCase());
                        }}
                        className="w-100"
                        autoComplete="off"
                      />
                      <ErrorMessage errors={errors && errors.supp_name} />
                      <p className="text-danger">
                        {error && !editNewSupplier
                          ? "This name is already available for the selected supp-category, please change the category"
                          : ""}
                      </p>
                    </div>
                    <div className="col-2">
                      <TextField
                        label="Contact Number"
                        variant="standard"
                        name="ctc_no"
                        value={values.ctc_no}
                        onChange={handleChange}
                        className="w-100"
                        autoComplete="off"
                      />
                      <ErrorMessage errors={errors && errors.ctc_no} />
                    </div>
                    <div className="col-2">
                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Currency
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.currency_id}
                          onChange={handleChange}
                          name="currency_id"
                          MenuProps={dropMenuProp}
                        >
                          {currencyData?.map((curr) => (
                            <MenuItem
                              value={curr.currency_id}
                              key={curr.currency_id}
                            >
                              {curr.curr_code + " : " + curr.curr_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage errors={errors && errors.currency_id} />
                      </FormControl>
                    </div>
                    <div className="col-1">
                      <TextField
                        label="ROE"
                        variant="standard"
                        name="roe"
                        value={values.roe}
                        onChange={handleChange}
                        className="w-100"
                        autoComplete="off"
                        type="number"
                      />
                      <ErrorMessage errors={errors && errors.roe} />
                    </div>
                    <div className="col-3">
                      <div className=" d-flex align-items-start gap-2 justify-content-between">
                        <TextField
                          label="Balance"
                          variant="standard"
                          name="balance"
                          value={values.balance}
                          onChange={handleChange}
                          // className="w-25"
                          autoComplete="off"
                          type="number"
                        />
                        {/* <div>
                          <div class="checkbox-rect">
                            <input
                              value={values.add_as_agent}
                              checked={values.add_as_agent}
                              type="checkbox"
                              id={"add_as_agent"}
                              onChange={(event) => {
                                setFieldValue(
                                  "add_as_agent",
                                  !values.add_as_agent
                                );
                              }}
                            />
                            <label for={"add_as_agent"} className="h6">
                              Also add as Agent.
                            </label>
                          </div>
                          <p className="mt-3 text-danger fs-6">
                            {errors.add_as_agent}
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="row px-4 mt-4">
                    <div className="col-2 h-6rem">
                      <TextField
                        label="Commission"
                        variant="standard"
                        name="commission"
                        value={values.commission}
                        onChange={handleChange}
                        className="w-100"
                        autoComplete="off"
                      />
                      <ErrorMessage errors={errors && errors.commission} />
                    </div>

                    {values.cat_id === 5 && (
                      <div className="col-2">
                        <TextField
                          label="Refund"
                          variant="standard"
                          name="refund"
                          value={values.refund}
                          onChange={handleChange}
                          className="w-100"
                          autoComplete="off"
                        />
                        <ErrorMessage errors={errors && errors.refund} />
                      </div>
                    )}
                    <div className="col-2">
                      <TextField
                        label="Email"
                        variant="standard"
                        name="ctc_email"
                        onChange={handleChange}
                        value={values.ctc_email}
                        className="w-100"
                      />
                      <ErrorMessage errors={errors && errors.ctc_email} />
                    </div>
                    <div className="col-2">
                      <TextField
                        label="Address"
                        variant="standard"
                        name="ctc_address"
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("ctc_address", value.toUpperCase());
                        }}
                        value={values.ctc_address}
                        className="w-100"
                      />
                      <ErrorMessage errors={errors && errors.ctc_address} />
                    </div>
                    {values.cat_id === 2 ? (
                      <div className="col-5">
                        <div className="d-flex gap-3 align-items-end">
                          {/* <div class="checkbox-rect">
                            <input
                              value={values.bsp}
                              checked={values.bsp}
                              type="checkbox"
                              id={"with_sar_"}
                              onChange={(event) => {
                                setFieldValue("bsp", !values.bsp);
                              }}
                            />
                            <label for={"with_sar_"} className="h6">
                              Other Than BSP{" "}
                              <sub className="text-warning">
                                (Checked means supplier is not BSP)
                              </sub>{" "}
                            </label>
                          </div> */}
                          <div class="checkbox-rect">
                            <input
                              value={values.gds}
                              checked={values.gds}
                              type="checkbox"
                              id={"with_sar_" + 2}
                              name="gds"
                              onChange={(event) => {
                                setFieldValue("gds", !values.gds);
                              }}
                            />
                            <label for={"with_sar_" + 2} className="h6">
                              GDS
                              <sub className="text-warning ms-1">
                                (Checked means supplier is GDS)
                              </sub>{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="text-center mt-3 p-4">
                      {!editNewSupplier ? (
                        <button
                          className="btn setup-btn me-1"
                          type="submit"
                          disabled={!addSuppPermission && user?.user_id !== 1}
                          onClick={(e)=>console.log("clcik")}
                        >
                        
                          {loading ? <BtnLoader /> : "Save"}
                        </button>
                      ) : (
                        <button
                          className="btn setup-btn me-1"
                          type="submit"
                          disabled={!editSuppPermission && user?.user_id !== 1}
                        >
                          {loading ? <BtnLoader /> : "Update"}
                        </button>
                      )}

                      {editNewSupplier && (
                        <button
                          className="btn setup-btn bg-danger"
                          onClick={(e) => dispatch(editNewSupplierNull())}
                          type="submit"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="mx-5 rounded-2 shadow-sm mt-5 bg-white mb-3">
        <p className="otawix-heading box_title">Supplier data List</p>
        <div class="p-4">
          <MUIDataTable
            className="muidatatable"
            title={"Supplier Details"}
            data={newSupplier}
            columns={columns}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default Supplier;
