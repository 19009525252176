import React, { useEffect, useState } from "react";
import { CurrencyCode, CurrencyExchange } from "../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import TotalPaxRecord from "./TotalPaxRecord";
import { FaRegEdit, FaRegFlushed } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import { ImCancelCircle } from "react-icons/im";
import FareLogs from "./FareLogs";
import { useDispatch, useSelector } from "react-redux";
import { GetFareLogs } from "../../redux/features/booking/bookingDetailsSlice";

const Pricing = ({
  PaxData,
  agencyData,
  agentGroup,
  user,
  mainBooking,
  setActive_Tab,
  booking,
  FarePermission,
}) => {
  const dispatch = useDispatch();
  const totalPrice = PaxData?.reduce(
    (acc, curr) => acc + Number(curr?.ttl_agt_pay_d_cur),
    0
  );
  const check =
    booking?.booking?.doc_status !== "Issued"
      ? user?.user_id === 1
        ? true
        : FarePermission?.user_id === user?.user_id
        ? true
        : false
      : false;
  const [showPax, setShowPax] = useState(true);
  const id = mainBooking?.id;
  useEffect(() => {
    dispatch(GetFareLogs({ id }));
  }, []);
    const { getBookingLogs } = useSelector((state) => state.booking);

  return (
    <>
      <div className="p-4">
        <div className="d-flex gap-4">
          <h4 className="theme-text-color">Description:</h4>
          {check ? (
            showPax ? (
              <p
                className="h5"
                style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
              >
                <span
                  className="cursor-pointer"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => setShowPax(false)}
                >
                  <FaRegEdit />
                </span>
              </p>
            ) : (
              <p
                className="h5"
                style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
              >
                <span
                  className="cursor-pointer"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => setShowPax(true)}
                >
                  <ImCancelCircle />
                </span>
              </p>
            )
          ) : null}
        </div>

        <TotalPaxRecord
          user={user}
          FarePermission={FarePermission}
          PaxData={PaxData}
          totalPrice={totalPrice}
          agencyData={agencyData}
          agentGroup={agentGroup}
          setShowPax={setShowPax}
          showPax={showPax}
          mainBooking={mainBooking}
          setActive_Tab={setActive_Tab}
        />
      </div>
    { getBookingLogs?.length ? <FareLogs getBookingLogs={getBookingLogs} /> : null}
    </>
  );
};

export default Pricing;
