import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, HTTPForm } from "../ApiConfig";

const initialState = {
  makePayments: [],
  supplierData: [],
  agentResult:[],
  chequeData: null,
  generateVoucherData: [],
  loading: false,
  routeLoader: false,
  vehicleLoader: false,
  error: null,
};

// getting makepayments data
export const asyncGetMakePayments = createAsyncThunk(
  "asyncGetMakePayments/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/make_payment`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);


// getting agents data
export const asyncGetAgentData = createAsyncThunk(
  "asyncGetAgentData/get",
  async (Query, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/only_customer_without_bg?q=${Query}`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// fetching Cheques data
export const asyncGetChequeData = createAsyncThunk(
  "asyncGetChequeData/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/make_payment/cheque_serial_fetch`,
        { id }
      );
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting generate payment voucher supplier data
export const asyncGenerateSupplierPayment = createAsyncThunk(
  "asyncGenerateSupplierPayment/post",
  async ({ formData, toast }, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(
        `/api/v1/b2b/make_payment/generate_payment_voucher`,
        formData
      );
      toast.success("supplier payments added");
      console.log(response.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting agent payments
export const asyncGenerateAgentPayment = createAsyncThunk(
  "asyncGenerateAgentPayment/post",
  async ({ formData, toast }, { rejectWithValue, dispatch }) => {
    console.log(formData);
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/make_payment/generate_payment_voucher`,
        formData,{
          headers: {
            "Content-Type": "multipart/form-data",
        }
        }
      );
      toast.success("agent payments added");
      console.log(response.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const MakePaymentsSlice = createSlice({
  name: "MakePaymentsSlice",
  initialState,

  reducers: {
    toggleUmrahRoutes: (state) => {
      state.showRouteModal = !state.showRouteModal;
    },
    toggleCarType: (state) => {
      state.carTypeModel = !state.carTypeModel;
    },
    emptyBankData: (state) => {
      state.chequeData = null;
    },
  },
  extraReducers: (builder) => {
    // getting transfer
    builder.addCase(asyncGetMakePayments.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetMakePayments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.makePayments = payload;
      state.supplierData = payload.sup;
    });
    builder.addCase(asyncGetMakePayments.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

      // getting agent ata
      builder.addCase(asyncGetAgentData.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(asyncGetAgentData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.agentResult = payload;
      });
      builder.addCase(asyncGetAgentData.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

    // getting cheques data
    builder.addCase(asyncGetChequeData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetChequeData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.chequeData = payload;
    });
    builder.addCase(asyncGetChequeData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // posting generateVoucher data
    builder.addCase(
      asyncGenerateSupplierPayment.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGenerateSupplierPayment.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.generateVoucherData = payload;
      }
    );
    builder.addCase(
      asyncGenerateSupplierPayment.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // posting routes  data
    builder.addCase(asyncGenerateAgentPayment.pending, (state, { payload }) => {
      state.routeLoader = true;
      state.vehicleLoader = true;
      state.error = null;
    });
    builder.addCase(asyncGenerateAgentPayment.fulfilled, (state, { payload }) => {
      state.routeLoader = true;
      state.vehicleLoader = true;
      state.error = null;
      state.carTypes = payload?.cartypes;
      state.umrahRoutes = payload?.routes;
    });
    builder.addCase(asyncGenerateAgentPayment.rejected, (state, { payload }) => {
      state.routeLoader = true;
      state.vehicleLoader = true;
      state.error = payload;
    });
  },
});

export const { toggleCarType, toggleUmrahRoutes, emptyBankData } =
  MakePaymentsSlice.actions;

export default MakePaymentsSlice.reducer;
