import React from "react";

const BtnLoader = ({ cancelBooking }) => {
  return (
    <div>
      <span
        className={cancelBooking ? " " :"spinner-border spinner-border-sm"}
        role="status"
        aria-hidden="true"
      ></span>
      &nbsp;&nbsp;Loading...
    </div>
  );
};

export default BtnLoader;
