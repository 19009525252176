import React from 'react'
import AboutUs from '../components/Agency/homeviewsittings/AboutUs'

const AboutUsPage = () => {
  return (
    <div>
      <AboutUs/>
    </div>
  )
}

export default AboutUsPage