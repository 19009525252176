import React from 'react'
import { RefundExchange } from '../../../Common/RefundExchange';
import { MdOutlineAirlineSeatReclineNormal, MdOutlineRestaurant, MdSwapHoriz } from 'react-icons/md';
import { FaChevronCircleLeft, FaChevronCircleRight, FaSuitcase, FaSuitcaseRolling } from 'react-icons/fa';
import Slider from 'react-slick/lib/slider';
import { CurrencyCode, CurrencyExchange, CurrencyExchangeToPKR } from '../../../../Utils/currencyExchange';
import { Box } from '@mui/material';
import { LightTooltip } from '../OneWaySearchResult/LightTooltip';
import { asyncPostSialPnr, setPnrData, setselectedFlight } from '../../../../redux/features/home/FlightSearchSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { TwoDecimalPoint } from '../../../../Utils/FixedTwoDecimal';
import { getSectorsName } from '../../../../Utils/FlightSector';
import { useNavigate } from 'react-router-dom';
import BtnLoader from '../../../AppForm/BtnLoader';

const AirsialFares = ({ fares, data, lowestFarePrice, singlePaxFare, selectedFare, setSelectedFare, boundToggle, setBoundToggle, supplierPrice,
    combineBaseFare, formatDuration, getTaxes, selectedFlight, singlePaxForTooltip }) => {
    const { sialpnrData, pnrLoading } = useSelector((state) => state.FlightSearch);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    //local storage data
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;

    const CustomPrevArrow = (props) => {
        if (fares?.BAGGAGE_FARE?.length < 4) return null;
        return (
            <div
                onClick={props.onClick}
                className="position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color"
            >
                <FaChevronCircleLeft size={30} />
            </div>
        )
    }
    const CustomNextArrow = (props) => {
        if (fares?.BAGGAGE_FARE?.length < 4) return null;
        return (
            <div
                onClick={props.onClick}
                className="position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index"
            >
                <FaChevronCircleRight size={30} />
            </div>
        )
    }
    var settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: fares?.BAGGAGE_FARE?.length > 4 ? 4 : fares?.BAGGAGE_FARE.length,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <div>
            {
                selectedFlight ? (
                    <div className="pt-4">
                        <div>
                            <div className="fs-5 pb-4 color_primary fw-bold">
                                {boundToggle === "outBound" ? selectedFlight?.outbound?.sub_class_desc : selectedFlight?.fare?.sub_class_desc}
                            </div>

                            <Box display={"flex"} alignItems={"center"}>
                                <Box>
                                    <FaSuitcaseRolling size={25} className='color_primary' />
                                </Box>
                                <h6 className='ps-2 pt-2'>Hand Carry: 7 kg</h6>
                            </Box>

                            <Box display={"flex"} alignItems={"center"} pt={2}>
                                <Box>
                                    <FaSuitcase size={22} className='color_primary' />
                                </Box>
                                <h6 className='ps-2 pt-2'>
                                    Check Bag: {boundToggle === "outBound"
                                        ? `${selectedFlight?.outbound?.weight} KG (${selectedFlight?.fare?.piece}) Pcs`
                                        : `${selectedFlight?.fare?.weight} KG (${selectedFlight?.fare?.piece}) Pcs`
                                    }
                                </h6>
                            </Box>
                            <Box display={"flex"}
                                alignItems={"center"}
                                pt={2}
                            >
                                <MdOutlineRestaurant
                                    size={25}
                                    className="color_primary"
                                />
                                <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                            </Box>

                            <Box display={"flex"}
                                alignItems={"center"}
                                pt={2}>
                                <Box>
                                    <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
                                </Box>
                                <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                            </Box>

                            <Box
                                pt={2}
                                display={"flex"}
                                alignItems={"center"}
                            >
                                <MdSwapHoriz
                                    size={30}
                                    className="color_primary"
                                />
                                <h6 className="pt-1 ps-2 text-success">
                                    Refundable
                                </h6>
                            </Box>
                            <div className="flight-detail-headings">
                                <RefundExchange />
                            </div>

                            <LightTooltip
                                title={
                                    <div className="p-3">
                                        <div className="d-flex justify-content-between align-items-end">
                                            <div className="d-flex me-4">
                                                <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                                <h5>* {searchValues?.adultsCount}</h5>
                                            </div>
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {CurrencyCode()}{" "}
                                                {singlePaxForTooltip(
                                                    boundToggle === "outBound" ? selectedFlight?.outbound?.FARE_PAX_WISE?.ADULT
                                                        : selectedFlight?.fare?.FARE_PAX_WISE?.ADULT,
                                                    searchValues?.adultsCount
                                                )}
                                            </h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-end my-2">
                                            <div className="d-flex">
                                                <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                                <h5>* {searchValues?.childCount}</h5>
                                            </div>
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {CurrencyCode()}{" "}
                                                {singlePaxForTooltip(
                                                    boundToggle === "outBound" ? selectedFlight?.outbound?.FARE_PAX_WISE?.CHILD
                                                        : selectedFlight?.fare?.FARE_PAX_WISE?.CHILD,
                                                    searchValues?.childCount
                                                )}
                                            </h6>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-end">
                                            <div className="d-flex">
                                                <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                                <h5>* {searchValues?.infantNo}</h5>
                                            </div>
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {CurrencyCode()}{" "}
                                                {singlePaxForTooltip(
                                                    boundToggle === "outBound" ? selectedFlight?.outbound?.FARE_PAX_WISE?.INFANT
                                                        : selectedFlight?.fare?.FARE_PAX_WISE?.INFANT,
                                                    searchValues?.infantNo
                                                )}
                                            </h6>
                                        </div>
                                    </div>
                                }
                                placement="top"
                            >
                                <button
                                    type="button"
                                    className={`button_style px-4`}
                                >

                                    {CurrencyCode()}{" "}
                                    {
                                        lowestFarePrice(boundToggle === "outBound" ? selectedFlight?.outbound?.FARE_PAX_WISE : selectedFlight?.fare?.FARE_PAX_WISE, "inoutBond")
                                    }
                                </button>
                            </LightTooltip>
                        </div>
                    </div>
                ) : (
                    <Slider {...settings}>
                        {fares?.BAGGAGE_FARE?.map((fare, index) => {
                            let returnFare = boundToggle === "inBound" ? selectedFare : data?.inbound?.BAGGAGE_FARE?.at(0);
                            return (
                                <div className="pt-4" key={index}>
                                    <div>
                                        <div className="fs-5 pb-4 color_primary fw-bold">
                                            {fare?.sub_class_desc}
                                        </div>

                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box>
                                                <FaSuitcaseRolling size={25} className='color_primary' />
                                            </Box>
                                            <h6 className='ps-2 pt-2'>Hand Carry: 7 kg</h6>
                                        </Box>

                                        <Box display={"flex"} alignItems={"center"} pt={2}>
                                            <Box>
                                                <FaSuitcase size={22} className='color_primary' />
                                            </Box>
                                            <h6 className='ps-2 pt-2'>Check Bag: {`${fare?.weight} KG (${fare?.piece}) Pcs`}</h6>
                                        </Box>
                                        <Box display={"flex"}
                                            alignItems={"center"}
                                            pt={2}
                                        >
                                            <MdOutlineRestaurant
                                                size={25}
                                                className="color_primary"
                                            />
                                            <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                                        </Box>

                                        <Box display={"flex"}
                                            alignItems={"center"}
                                            pt={2}>
                                            <Box>
                                                <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
                                            </Box>
                                            <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                                        </Box>

                                        <Box
                                            pt={2}
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <MdSwapHoriz
                                                size={30}
                                                className="color_primary"
                                            />
                                            <h6 className="pt-1 ps-2 text-success">
                                                Refundable
                                            </h6>
                                        </Box>
                                        <div className="flight-detail-headings">
                                            <RefundExchange />
                                        </div>

                                        <LightTooltip
                                            title={
                                                <div className="p-3">
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <div className="d-flex me-4">
                                                            <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                                            <h5>* {searchValues?.adultsCount}</h5>
                                                        </div>
                                                        <h6 style={{ minWidth: "7rem" }}>
                                                            {CurrencyCode()}{" "}
                                                            {singlePaxFare(
                                                                fare?.FARE_PAX_WISE?.ADULT,
                                                                returnFare?.FARE_PAX_WISE?.ADULT,
                                                                searchValues?.adultsCount
                                                            )}
                                                        </h6>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-end my-2">
                                                        <div className="d-flex">
                                                            <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                                            <h5>* {searchValues?.childCount}</h5>
                                                        </div>
                                                        <h6 style={{ minWidth: "7rem" }}>
                                                            {CurrencyCode()}{" "}
                                                            {singlePaxFare(
                                                                fare?.FARE_PAX_WISE?.CHILD,
                                                                returnFare?.FARE_PAX_WISE?.CHILD,
                                                                searchValues?.childCount
                                                            )}
                                                        </h6>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-end">
                                                        <div className="d-flex">
                                                            <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                                            <h5>* {searchValues?.infantNo}</h5>
                                                        </div>
                                                        <h6 style={{ minWidth: "7rem" }}>
                                                            {CurrencyCode()}{" "}
                                                            {singlePaxFare(
                                                                fare?.FARE_PAX_WISE?.INFANT,
                                                                returnFare?.FARE_PAX_WISE?.INFANT,
                                                                searchValues?.infantNo
                                                            )}
                                                        </h6>
                                                    </div>
                                                </div>
                                            }
                                            placement="top"
                                        >
                                            <button
                                                type="button"
                                                className={` ${selectedFare?.sub_class_id === fare?.sub_class_id ? "button_acitve" : "button_style"} px-4`}
                                                onClick={() => {
                                                    if (boundToggle === "outBound") {
                                                        setSelectedFare(fare)
                                                        setBoundToggle("inBound")
                                                    }
                                                    if (boundToggle === "inBound") {
                                                        dispatch(setPnrData(null));

                                                        let pnrBody = {
                                                            isReturnFlight: true,
                                                            origin_journey_code:
                                                                data?.outbound?.JOURNEY_CODE,
                                                            origin_fare_type:
                                                                selectedFare?.sub_class_id,
                                                            origin_class:
                                                                data?.outbound?.CLASS_CODE,
                                                            origin_flight_code:
                                                                data?.outbound?.FLIGHT_NO,

                                                            dest_journey_code: data?.inbound?.JOURNEY_CODE,
                                                            dest_fare_type: fare?.sub_class_id,
                                                            dest_class: data?.inbound?.CLASS_CODE,
                                                            dest_flight_code: data?.inbound?.FLIGHT_NO,

                                                            origin: data?.outbound?.ORGN,
                                                            destination: data?.outbound?.DEST,
                                                            infant: searchValues?.infantNo,
                                                            child: searchValues?.childCount,
                                                            adults: searchValues?.adultsCount,
                                                            agent_name: data?.outbound?.agent_name,
                                                        };

                                                        dispatch(
                                                            asyncPostSialPnr({
                                                                body: pnrBody,
                                                                navigate: navigate,
                                                            })
                                                        );

                                                        const bookingBody = {
                                                            outbound: selectedFare,
                                                            flight: data,
                                                            fare: fare,
                                                            pnr: sialpnrData?.Data,
                                                            price: lowestFarePrice(fare?.FARE_PAX_WISE),
                                                            ttlagent_curr_fare: Number(lowestFarePrice(fare?.FARE_PAX_WISE)) || 0,
                                                            ttl_supplier_fare: supplierPrice(fare?.FARE_PAX_WISE),
                                                            ttlsup_curr_fare: TwoDecimalPoint(CurrencyExchange(supplierPrice(fare?.FARE_PAX_WISE))),
                                                            adultBaseFare: combineBaseFare(fare?.FARE_PAX_WISE, "ADULT").basic,
                                                            adultTaxes: combineBaseFare(fare?.FARE_PAX_WISE, "ADULT").taxes,
                                                            childBaseFare: searchValues?.childCount === 0 ? 0 : combineBaseFare(fare?.FARE_PAX_WISE, "CHILD").basic,
                                                            childTaxes: searchValues?.childCount === 0 ? 0 : combineBaseFare(fare?.FARE_PAX_WISE, "CHILD").taxes,
                                                            infantBaseFare: searchValues?.infantNo === 0 ? 0 : combineBaseFare(fare?.FARE_PAX_WISE, "INFANT").basic,
                                                            infantTaxes: searchValues?.infantNo === 0 ? 0 : combineBaseFare(fare?.FARE_PAX_WISE, "INFANT").taxes,
                                                            adlt_ttl_d_curr: CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.ADULT, returnFare?.FARE_PAX_WISE?.ADULT, 1)),
                                                            chld_ttl_d_curr: searchValues?.childCount === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.CHILD, returnFare?.FARE_PAX_WISE?.CHILD, 1)),
                                                            inft_ttl_d_curr: searchValues?.infantNo === 0 ? 0 : CurrencyExchangeToPKR(~~singlePaxFare(fare?.FARE_PAX_WISE?.INFANT, returnFare?.FARE_PAX_WISE?.INFANT, 1)),
                                                            ADLT_TAXES: getTaxes(fare?.FARE_PAX_WISE?.ADULT, "ADULT"),
                                                            CHLD_TAXES: searchValues?.childCount === 0 ? [] : getTaxes(fare?.FARE_PAX_WISE?.CHILD, "CHILD"),
                                                            INFT_TAXES: searchValues?.infantNo === 0 ? [] : getTaxes(fare?.FARE_PAX_WISE?.INFANT, "INFANT"),
                                                            baggageName: fare?.sub_class_desc,
                                                            flightNumber: `${data?.outbound?.FLIGHT_NO}`,
                                                            depDate: moment(
                                                                data?.outbound?.DEPARTURE_DATE,
                                                                "DD,MM,YYYY"
                                                            ).format("DD-MMM-YY"),
                                                            depTime: data?.outbound?.DEPARTURE_TIME,
                                                            arrDate: moment(
                                                                data?.inbound?.ARRIVAL_UTC?.split(" ")[0],
                                                                "DD,MM,YYYY"
                                                            ).format("DD-MMM-YY"),
                                                            arrTime: data?.inbound?.ARRIVAL_TIME,
                                                            flightDuration: formatDuration(
                                                                data?.outbound?.DURATION
                                                            ),
                                                            logo: "SialReturn",
                                                            code: "PF",
                                                            booking: {
                                                                gds: "AIRSIAL",
                                                                agent_name: data?.outbound?.agent_name,
                                                                segments: [
                                                                    {
                                                                        departure_date: moment(
                                                                            data?.outbound?.DEPARTURE_DATE,
                                                                            "DD,MM,YYYY"
                                                                        ).format("YYYY-MM-DD"),
                                                                        baggage: `${selectedFare?.weight} KG (${selectedFare?.piece} Pcs)`,
                                                                        departure_time: data?.outbound?.DEPARTURE_TIME,
                                                                        arrival_date: moment(
                                                                            data?.outbound?.ARRIVAL_UTC?.split(" ")[0],
                                                                            "DD,MM,YYYY"
                                                                        ).format("YYYY-MM-DD"),
                                                                        arrival_time: data?.outbound?.ARRIVAL_TIME,
                                                                        origin_terminal: "",
                                                                        desti_terminal: "",
                                                                        flight_number:
                                                                            data?.outbound?.FLIGHT_NO?.split("F")?.at(1),
                                                                        number_in_party: "",
                                                                        res_book_desig_code: "",
                                                                        status: "NN",
                                                                        des_location:
                                                                            searchValues?.locationArrival,
                                                                        dest_code: data?.outbound?.DEST,
                                                                        marketing_airline: "PF",
                                                                        orig_location:
                                                                            searchValues?.locationDep,
                                                                        orig_code: data?.outbound?.ORGN,
                                                                        marriage_grp: "",
                                                                        cabin_class:
                                                                            selectedFare?.sub_class_desc,
                                                                        segment_type: getSectorsName(
                                                                            locDeparture,
                                                                            locArrival
                                                                        ), // dom | siti | soto | group
                                                                    },
                                                                    {
                                                                        departure_date: moment(
                                                                            data?.inbound?.DEPARTURE_DATE,
                                                                            "DD,MM,YYYY"
                                                                        ).format("YYYY-MM-DD"),
                                                                        baggage: `${fare?.weight} KG (${fare?.piece} Pcs)`,
                                                                        departure_time: data?.inbound?.DEPARTURE_TIME,
                                                                        arrival_date: moment(
                                                                            data?.inbound?.ARRIVAL_UTC?.split(" ")[0],
                                                                            "DD,MM,YYYY"
                                                                        ).format("YYYY-MM-DD"),
                                                                        arrival_time: data?.inbound?.ARRIVAL_TIME,
                                                                        origin_terminal: "",
                                                                        desti_terminal: "",
                                                                        flight_number:
                                                                            data?.inbound?.FLIGHT_NO?.split("F")?.at(1),
                                                                        number_in_party: "",
                                                                        res_book_desig_code: "",
                                                                        status: "NN",
                                                                        des_location:
                                                                            searchValues?.locationDep,
                                                                        dest_code: data?.inbound?.DEST,
                                                                        marketing_airline: "PF",
                                                                        orig_location:
                                                                            searchValues?.locationArrival,
                                                                        orig_code: data?.inbound?.ORGN,
                                                                        marriage_grp: "",
                                                                        cabin_class:
                                                                            fare?.sub_class_desc,
                                                                        segment_type: getSectorsName(
                                                                            locArrival,
                                                                            locDeparture
                                                                        ), // dom | siti | soto | group
                                                                    },
                                                                ],
                                                                tp_ur_code: "",
                                                                platting_carrier: "PF",
                                                                tp_ssr_verssion: 0,
                                                                tp_trace_id: "",
                                                            },
                                                        }
                                                        dispatch(
                                                            setselectedFlight(bookingBody)
                                                        );
                                                        // navigate("/check-out");
                                                    }
                                                }}
                                            >
                                                {pnrLoading ? (
                                                    <BtnLoader />
                                                ) : (
                                                    <>
                                                        {CurrencyCode()}{" "}
                                                        {
                                                            lowestFarePrice(fare?.FARE_PAX_WISE)
                                                        }
                                                    </>
                                                )
                                                }
                                            </button>
                                        </LightTooltip>
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                )
            }
        </div>
    )
}

export default AirsialFares
