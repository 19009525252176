import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  FlyJinnahCredentialsData: [],
  editFlyJinnahCredentials: null,
  loading: false,
  error: null,
};

// post ER API CREDENTIAL
export const asyncPost9PApiCredentials = createAsyncThunk(
  "asyncPost9PApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/flyjinnah-credential`,
        values
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get ER API CREDENTIAL
export const asyncGet9PApiCredentials = createAsyncThunk(
  "asyncGet9PApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/flyjinnah-credential`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get single ER API CREDENTIAL
export const asyncGetSingle9PApiCred = createAsyncThunk(
  "asyncGetSingle9PApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/flyjinnah-credential/${id}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Update ER API CREDENTIAL
export const asyncUpdate9PCredential = createAsyncThunk(
  "asyncUpdate9PCredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/flyjinnah-credential/${id}`,
        values
      );
      dispatch(asyncGet9PApiCredentials());
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update ER API CREDENTIAL
export const asyncDelete9PCredential = createAsyncThunk(
  "asyncDelete9PCredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(
        `/api/v1/b2b/flyjinnah-credential/${id}`
      );
      dispatch(asyncGet9PApiCredentials());
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const FlyJinnahApiCredentialSlice = createSlice({
  name: "FlyJinnahApiCredentialSlice",
  initialState,
  reducers: {
    edit9PCredentialNull: (state) => {
      state.editFlyJinnahCredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post ER Api Credentials
    builder.addCase(asyncPost9PApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPost9PApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.FlyJinnahCredentialsData = [
          ...state.FlyJinnahCredentialsData,
          payload,
        ];
      }
    );
    builder.addCase(
      asyncPost9PApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get ER Api Credentials
    builder.addCase(asyncGet9PApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGet9PApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.FlyJinnahCredentialsData = payload;
      }
    );
    builder.addCase(asyncGet9PApiCredentials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Get single ER Api Credentials
    builder.addCase(asyncGetSingle9PApiCred.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSingle9PApiCred.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editFlyJinnahCredentials = payload;
    });
    builder.addCase(asyncGetSingle9PApiCred.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update ER Api Credentials
    builder.addCase(asyncUpdate9PCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdate9PCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdate9PCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // DElete ER Api Credentials
    builder.addCase(asyncDelete9PCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDelete9PCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDelete9PCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { edit9PCredentialNull } = FlyJinnahApiCredentialSlice.actions;
export default FlyJinnahApiCredentialSlice.reducer;
