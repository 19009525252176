import React from 'react';
import BankDetailsComp from '../components/Setup/BankDetails'

const BankDetails = () => {
  return (
    <div className=''>
      <BankDetailsComp />
    </div>
  )
}

export default BankDetails