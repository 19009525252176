import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NoEntry = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const inter = setTimeout(() => {
      navigate("/");
    }, 0);
    return () => clearInterval(inter);
  }, [navigate]);
};
const ProtectRoutes = ({ children }) => {
  const user = useSelector((state) => state?.user);
  // console.log(user && user?.token);
  return <div>{user?.token ? children : <NoEntry />}</div>;
};

export default ProtectRoutes;

// import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { Navigate } from "react-router-dom";

// const ProtectRoutes = ({ children }) => {
//   const {  token } = useSelector((state) => state.user);

//   console.log(token);
//   return <div>{token ? children : <Navigate to={"/"} />}</div>;
// };

// export default ProtectRoutes;
