import { TextField } from "@mui/material";
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAgentSubPageData,
  asyncPostAgentGroup,
  asyncUpdateAgentGroup,
  editAgentGroupNull,
} from "../../redux/features/setupRestPages/agentsSlice";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import BtnLoader from "../AppForm/BtnLoader";
import { asyncGetCurrencyData } from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";

function AgentGroupSubPage() {
  const dispatch = useDispatch();
  const { agentGroupSubPageData, editAgentGroup, loading, agentGroup } =
    useSelector((state) => state.agentsCategories);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);

  useEffect(() => {
    dispatch(asyncGetAgentSubPageData());
    dispatch(asyncGetCurrencyData());
  }, [dispatch, editAgentGroup?.id]);

  // console.log(currencyData);
  // THIS PAGE HAS BEAN CHANGED FROM A POP MODEL INTO SAME  AGENT GROUP PAGE *************************

  const validationSchem = yup.object().shape({
    agent_grp_name: yup.string().required("Name Required"),
    currency_id: yup.number().required("Currency Required"),
  });

  return (
    <div className="w-100">
      <div className="Full_height">
        {/* <Header /> */}
        <Formik
          className="manual_invoices mx-auto mt-5 width_60percent"
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchem}
          enableReinitialize={true}
          initialValues={{
            agent_grp_name: editAgentGroup?.agent_grp_name || "",
            // currency_id:
            //   editAgentGroup && editAgentGroup?.currency_id ||
            //   currencyData?.length
            //     ? currencyData[0]?.currency_id
            //     : 0,
            currency_id:
              editAgentGroup ? editAgentGroup?.currency_id : currencyData?.length
                ? currencyData[0]?.currency_id : 0
          }}
          onSubmit={(values, { resetForm }) => {
            console.log("values", values);
            if (editAgentGroup) {
              dispatch(
                asyncUpdateAgentGroup({
                  values,
                  id: editAgentGroup?.agent_grp_id,
                })
              );
              dispatch(editAgentGroupNull());
            } else {
              dispatch(asyncPostAgentGroup(values));
            }

            resetForm({ values: "" });
            //
          }}
        >
          {({ values, handleChange, errors, setFieldValue }) => {
            return (
              <Form className="">
                {/* <p className="otawix-heading h4">New Agent Group</p> */}
                <div className="px-5 py-3 ">
                  {/* ************ */}
                  <div className="row my-4 mt-2 w-100">
                    <div className="col-4">
                      <TextField
                        label="Name"
                        variant="standard"
                        className="w-100"
                        value={values.agent_grp_name}
                        name={"agent_grp_name"}
                        onChange={(e) => {
                          const value = e.target.value || "";
                          setFieldValue("agent_grp_name", value.toUpperCase());
                        }}
                        autoComplete="off"

                      />
                      <p style={{ color: "red" }}>
                        {" "}
                        {errors && values.agent_grp_name.length <= 0 && errors?.agent_grp_name}
                      </p>
                    </div>
                    <div className="col-4">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Currency
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.currency_id}
                          onChange={handleChange}
                          name="currency_id"
                        >
                          {currencyData?.map((curr) => (
                            <MenuItem
                              value={curr.currency_id}
                              key={curr.currency_id}
                            >
                              {curr.curr_code + ' ' + ':' + ' ' + curr.curr_name}
                            </MenuItem>
                          ))}
                        </Select>
                        <p style={{ color: "red" }}>
                          {errors && errors?.currency_id}
                        </p>
                      </FormControl>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-end gap-3 mt-5">
                    <div>
                      <button className="setup-btn btn" type="submit">
                        {loading ? (
                          <BtnLoader />
                        ) : editAgentGroup ? (
                          "Update"
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                    {editAgentGroup && (
                      <div>
                        <button
                          className="setup-btn btn bg-danger"
                          style={{ background: "#dc3545", color: "#fff" }}
                          onClick={() => {
                            // navigate("/agent-groups");
                            dispatch(editAgentGroupNull());
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default AgentGroupSubPage;
