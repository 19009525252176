import React, { useEffect, useState } from 'react'
import { CurrencyCode, CurrencyExchange, CurrencyExchangeToPKR } from '../../../../Utils/currencyExchange';
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SereneSegments from './SereneSegments';
import moment from 'moment';
import { NODEURL } from '../../../../redux/features/ApiConfig';
import { MdFlight, MdOutlineAirlineSeatReclineNormal, MdOutlineFlightLand, MdOutlineFlightTakeoff, MdOutlineRestaurant, MdRemoveRedEye, MdSwapHoriz } from 'react-icons/md';
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import { pricingairline } from '../../../../Utils/airlinePricing';
import { getSectorsName } from '../../../../Utils/FlightSector';
import { ExclusiveSetors } from '../../../../Utils/exclusiveSector';
import { TwoDecimalPoint } from '../../../../Utils/FixedTwoDecimal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BsBagCheck, BsHandbag } from 'react-icons/bs';
import { LightTooltip } from '../OneWaySearchResult/LightTooltip';
import { FaChevronCircleLeft, FaChevronCircleRight, FaSuitcase, FaSuitcaseRolling } from 'react-icons/fa';
import Slider from 'react-slick/lib/slider';
import { setselectedFlight } from '../../../../redux/features/home/FlightSearchSlice';
import { CgMenuGridR } from 'react-icons/cg';
import { Box } from '@mui/material';
import { RefundExchange } from '../../../Common/RefundExchange';

const SereneApi = ({ serene, key, index, isExpanded, toggleDetail, selectedFareSerene }) => {
    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState("Fare Options");
    const { airlinePricing } = useSelector(
        (state) => state?.price_airlines_suppRoleSlice
    );
    const [boundToggle, setBoundToggle] = useState("outBound");
    const [selectedFare, setSelectedFare] = useState(serene?.outbound?.fares?.at(0));
    const [selectedIbFare, setSelectedIbFare] = useState(serene?.inbound?.fares?.at(0));

    const { addAirLinesData } = useSelector(
        (state) => state?.price_airlines_suppRoleSlice
    );
    //local storage data
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const user = JSON.parse(localStorage.getItem("loginUser"));

    // airline pricing
    const pricing = airlinePricing?.find(
        (item) =>
            item.bg_id === searchValues?.agent?.agent_grp_id &&
            item?.add_airline?.code_alpha === "ER"
    );
    //airline
    const airline = addAirLinesData?.find((it) => it.code_alpha === "ER");

    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;


    // calculate duration
    const timeDuration = (time1, time2) => {
        if (!time1 || !time2) {
            return null;
        }
        const depMoment = moment(time1);
        const arrMoment = moment(time2);
        const duration = moment.duration(arrMoment.diff(depMoment));
        const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
        const minutes = String(duration.minutes()).padStart(2, "0");

        return `${hours}:${minutes}`;
    };

    const singlePaxFareForForToolTip = (pax, paxCount) => {
        let newFare =
            pricingairline(
                airline,
                pricing,
                Number(CurrencyExchange(Number(pax?.Price?.BaseAmount?.["#text"]))),
                Number(CurrencyExchange(Number(pax?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]))),
                getSectorsName(locDeparture, locArrival),
                ExclusiveSetors(searchValues, pricing)
            )?.total_fare * paxCount || 0;

        return TwoDecimalPoint(newFare);
    };

    const singlePaxFare = (pax, paxCount, selectedPaxes) => {
        let newFare =
            pricingairline(
                airline,
                pricing,
                Number(CurrencyExchange(Number(pax?.Price?.BaseAmount?.["#text"]) + Number(selectedPaxes === "inOutBound" ? 0 : selectedPaxes?.Price?.BaseAmount?.["#text"]))),
                Number(
                    CurrencyExchange(
                        Number(pax?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) + Number(selectedPaxes === "inOutBound" ? 0 : selectedPaxes?.Price?.TaxSummary?.TotalTaxAmount?.["#text"])
                    )
                ),
                getSectorsName(locDeparture, locArrival),
                ExclusiveSetors(searchValues, pricing)
            )?.total_fare * paxCount || 0;

        return TwoDecimalPoint(newFare);
    };


    // calculate the lowest fare price
    const lowestFarePrice = (paxes, faresSelections) => {
        paxes = Array.isArray(paxes) ? paxes : [paxes];
        const adult = paxes[0];
        const child = searchValues?.childCount > 0 ? paxes[1] : {};
        const infant = searchValues?.infantNo ? paxes[paxes?.length - 1] : {};

        // Check if faresSelections is "faresSelections", and don't add selecFare if true
        let selecFare = faresSelections === "faresSelections"
            ? null
            : selectedFareSerene
                ? selectedFareSerene?.fare?.OfferItem?.FareDetail
                : boundToggle === "inBound"
                    ? selectedFare?.OfferItem?.FareDetail
                    : serene?.inbound?.fares[0]?.OfferItem?.FareDetail;

        // Handle the case when selecFare is null (faresSelections === "faresSelections")
        let otherFares = Array.isArray(selecFare) ? selecFare : selecFare ? [selecFare] : [{}];
        const adultFares = otherFares[0] || {};
        const childFares = searchValues?.childCount > 0 ? otherFares[1] || {} : {};
        const infantFares = searchValues?.infantNo > 0 ? otherFares[otherFares?.length - 1] || {} : {};

        // Calculate prices
        const adultBasePrice = Number(adult?.Price?.BaseAmount?.["#text"]) || 0;
        const adultFareBasePrice = faresSelections === "faresSelections" ? 0 : Number(adultFares?.Price?.BaseAmount?.["#text"]) || 0;
        const adultTax = Number(adult?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;
        const adultFareTax = faresSelections === "faresSelections" ? 0 : Number(adultFares?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;

        const childBasePrice = Number(child?.Price?.BaseAmount?.["#text"]) || 0;
        const childFareBasePrice = faresSelections === "faresSelections" ? 0 : Number(childFares?.Price?.BaseAmount?.["#text"]) || 0;
        const childTax = Number(child?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;
        const childFareTax = faresSelections === "faresSelections" ? 0 : Number(childFares?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;

        const infantBasePrice = Number(infant?.Price?.BaseAmount?.["#text"]) || 0;
        const infantFareBasePrice = faresSelections === "faresSelections" ? 0 : Number(infantFares?.Price?.BaseAmount?.["#text"]) || 0;
        const infantTax = Number(infant?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;
        const infantFareTax = faresSelections === "faresSelections" ? 0 : Number(infantFares?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;

        // Calculate the total new fare
        let newFare =
            (pricingairline(
                airline,
                pricing,
                Number(CurrencyExchange(adultBasePrice + adultFareBasePrice)),
                Number(CurrencyExchange(adultTax + adultFareTax)),
                getSectorsName(locDeparture, locArrival),
                ExclusiveSetors(searchValues, pricing)
            )?.total_fare * searchValues?.adultsCount || 0) +
            (pricingairline(
                airline,
                pricing,
                Number(CurrencyExchange(childBasePrice + childFareBasePrice)),
                Number(CurrencyExchange(childTax + childFareTax)),
                getSectorsName(locDeparture, locArrival),
                ExclusiveSetors(searchValues, pricing)
            )?.total_fare * searchValues?.childCount || 0) +
            (pricingairline(
                airline,
                pricing,
                Number(CurrencyExchange(infantBasePrice + infantFareBasePrice)),
                Number(CurrencyExchange(infantTax + infantFareTax)),
                getSectorsName(locDeparture, locArrival),
                ExclusiveSetors(searchValues, pricing)
            )?.total_fare * searchValues?.infantNo || 0);

        return TwoDecimalPoint(newFare);
    };




    // calculate the supplier fare price
    const supplierPrice = (paxes) => {
        paxes = Array.isArray(paxes) ? paxes : [paxes];
        const adult = paxes[0];
        const child = searchValues?.childCount > 0 ? paxes[1] : null;
        const infant = searchValues?.infantNo > 0 ? paxes[paxes?.length - 1] : null;

        let newFare = Number(adult?.Price?.TotalAmount?.["#text"] * searchValues?.adultsCount);
        if (child) {
            newFare += Number(child?.Price?.TotalAmount?.["#text"] * searchValues?.childCount);
        }
        if (infant) {
            newFare += Number(infant?.Price?.TotalAmount?.["#text"] * searchValues?.infantNo);
        }
        return newFare;
    };


    const outBoundformateDate =
        serene?.outbound?.segments?.at(0)?.Dep?.AircraftScheduledDateTime?.split("T")[0]
    const outformattedDate = moment(outBoundformateDate)
        .format("ddd DD-MMM-YY")
        .toUpperCase();
    const inBoundformateDate =
        serene?.inbound?.segments?.at(0)?.Dep?.AircraftScheduledDateTime?.split("T")[0]
    const informattedDate = moment(inBoundformateDate)
        .format("ddd DD-MMM-YY")
        .toUpperCase();


    return (
        <div className='search_engin_result_box flight_card_shadow bg-white'>
            <div className='shadow cursorpointer d-flex justify-content-center align-items-center' onClick={() => toggleDetail(index)}>
                <div className="col-9">
                    <SereneSegments serene={serene} timeDuration={timeDuration} />
                </div>
                <div className="col-3 left-shadow text-center align-self-stretch pt-5">
                    <div
                        className={`d-flex flex-column align-items-center justify-content-center result_box_body pt-4`}
                    >
                        <>
                            <div className="d-flex align-items-center gap-3">
                                <div className="h4">
                                    <h6 className=" text-danger">
                                        {/* {totalDiscount(amadeusItem)} */}
                                    </h6>

                                    {`${CurrencyCode()} ${lowestFarePrice(selectedFareSerene ? selectedFareSerene?.outbound?.OfferItem?.FareDetail : serene?.outbound?.fares[0]?.OfferItem?.FareDetail)}`}
                                </div>
                                <img
                                    src={"/NEW_LOGO/ER.png"}
                                    alt=""
                                    width={40}
                                    height={40}
                                    className="mb-2"
                                />
                            </div>
                            <button
                                onClick={(e) => {
                                    toggleDetail(index);
                                }}
                                className="button_style w-75 mb-2 cursorpointer mt-2"
                                type="button"
                            >
                                <div>
                                    <MenuOpenIcon className="me-1" />
                                    Flight Detail
                                </div>
                            </button>
                        </>

                    </div>
                </div>
            </div>

            {isExpanded &&
                <div className="p-3">
                    <div className="d-flex mb-4 mt-2 flight_details_tabs">
                        {["Fare Options", "Flight Details"].map((item, index) => {
                            const active = selectedTab === item;
                            return (
                                <button
                                    key={index}
                                    onClick={() => setSelectedTab(item)}
                                    className={active ? "active" : ""}
                                >
                                    {item}
                                </button>
                            );
                        })}
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
                        <div
                            className={`${boundToggle === "outBound"
                                ? "button_acitve active-box"
                                : "button_style"
                                } rounded-md p-2 w-50 cursorpointer me-2`}
                            onClick={() => setBoundToggle("outBound")}
                        >
                            <div className="d-flex gap-4">
                                <div className="font-medium">
                                    {`${serene?.outbound?.segments.at(0)?.Dep?.IATA_LocationCode
                                        } -
                                             ${serene?.outbound?.segments[
                                            serene?.outbound?.segments?.length - 1
                                        ]?.Arrival?.IATA_LocationCode
                                        }`}
                                </div>
                                <div className="font-medium">{outformattedDate}</div>
                            </div>
                        </div>

                        <div
                            className={`${boundToggle === "inBound"
                                ? "button_acitve active-box text-white"
                                : "button_style"
                                } rounded-md p-2 w-50 cursorpointer ms-2`}
                            onClick={() => setBoundToggle("inBound")}
                        >
                            <div className="d-flex gap-4">
                                <div className="font-medium">
                                    {`${serene?.inbound?.segments.at(0)?.Dep?.IATA_LocationCode
                                        } -
                                             ${serene?.inbound?.segments[
                                            serene?.inbound?.segments?.length - 1
                                        ]?.Arrival?.IATA_LocationCode
                                        }`}
                                </div>
                                <div className="font-medium">{informattedDate}</div>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div>
                            <div>
                                {selectedTab === "Flight Details" && (
                                    <div className="row rounded-2 search_engin_result_box bg-white p-4">
                                        {serene?.[boundToggle.toLowerCase()]?.segments?.map((item, inx, array) => {
                                            return (
                                                <ConnectedFlight
                                                    item={item}
                                                    inx={inx}
                                                    calculateTotalTimeDifference={timeDuration}
                                                    fligthSegment={array}
                                                    operatedAirline={"ER"}
                                                    convertTime={timeDuration}
                                                />
                                            );
                                        })}
                                    </div>
                                )}
                            </div>

                            <div>
                                {selectedTab === "Fare Options" && (
                                    <div className="row rounded-2 search_engin_result_box bg-white p-4">
                                        <Fares
                                            fares={serene?.[boundToggle.toLowerCase()]}
                                            sereneFLT={serene}
                                            singPaxFareTotal={singlePaxFare}
                                            singlePaxFareForForToolTip={singlePaxFareForForToolTip}
                                            lowestFarePrice={lowestFarePrice}
                                            supplierPrice={supplierPrice}
                                            perPaxPrice={singlePaxFare}
                                            searchValues={searchValues}
                                            setBoundToggle={setBoundToggle}
                                            selectedFare={selectedFare}
                                            setSelectedFare={setSelectedFare}
                                            selectedIbFare={selectedIbFare}
                                            setSelectedIbFare={setSelectedIbFare}
                                            boundToggle={boundToggle.toLowerCase()}
                                            TwoDecimalPoint={TwoDecimalPoint}
                                            selectedFareSerene={selectedFareSerene}
                                        />
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SereneApi;

const Fares = ({
    fares,
    searchValues,
    lowestFarePrice,
    supplierPrice,
    perPaxPrice,
    sereneFLT,
    setBoundToggle,
    selectedFare,
    setSelectedFare,
    selectedIbFare,
    boundToggle,
    TwoDecimalPoint,
    selectedFareSerene,
    singlePaxFareForForToolTip
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const CustomPrevArrow = (props) => {
        if (fares?.fares?.length < 4) return null;
        return (
            <div
                onClick={props.onClick}
                className="position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color"
            >
                <FaChevronCircleLeft size={30} />
            </div>
        )
    }
    const CustomNextArrow = (props) => {
        if (fares?.fares?.length < 4) return null;
        return (
            <div
                onClick={props.onClick}
                className="position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index"
            >
                <FaChevronCircleRight size={30} />
            </div>
        )
    }
    var settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: fares?.fares?.length > 4 ? 4 : fares?.fares.length,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const getPaxSupplierPrice = (pax, count) => {
        const basePrice =
            Number(CurrencyExchange(Number(pax?.Price?.BaseAmount?.["#text"]))) || 0;
        const taxes =
            Number(pax?.Price?.TaxSummary?.TotalTaxAmount?.["#text"]) || 0;

        return { basePrice, taxes };
    };

    // Determine selectedBaggages based on boundToggle
    let selectedBaggages = boundToggle === "outbound"
        ? selectedFareSerene?.outbound?.BaggageAllowance?.baggage?.WeightAllowance?.MaximumWeightMeasure
        : selectedFareSerene?.fare?.BaggageAllowance?.baggage?.WeightAllowance?.MaximumWeightMeasure;

    // Set baggage information based on selectedBaggages
    let inOutbaggage = selectedBaggages
        ? `${selectedBaggages?.["#text"]} ${selectedBaggages?.["-UnitCode"]}`
        : "No Baggage";

    const sereneSelectedFares = boundToggle === 'outbound'
        ? selectedFareSerene?.outbound?.OfferItem?.FareDetail
        : selectedFareSerene?.fare?.OfferItem?.FareDetail;

    const sereneSelectedFaresArray = Array.isArray(sereneSelectedFares) ? sereneSelectedFares : [sereneSelectedFares];



    return (
        <>
            {selectedFareSerene ?
                (
                    <div>
                        <div className="fs-5 pb-4 color_primary fw-bold">
                            {boundToggle === "outbound" ? selectedFareSerene?.outbound?.classList?.Name : selectedFareSerene?.fare?.classList?.Name}
                        </div>

                        <Box display={"flex"} alignItems={"center"}>
                            <Box>
                                <FaSuitcaseRolling size={25} className='color_primary' />
                            </Box>
                            <h6 className='ps-2 pt-2'>Upto 7 kg</h6>
                        </Box>

                        <Box display={"flex"} alignItems={"center"} pt={2}>
                            <Box>
                                <FaSuitcase size={22} className='color_primary' />
                            </Box>
                            <h6 className='ps-2 pt-2'>{inOutbaggage}</h6>
                        </Box>
                        <Box display={"flex"}
                            alignItems={"center"}
                            pt={2}
                        >
                            <MdOutlineRestaurant
                                size={25}
                                className="color_primary"
                            />
                            <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                        </Box>

                        <Box display={"flex"}
                            alignItems={"center"}
                            pt={2}>
                            <Box>
                                <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
                            </Box>
                            <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                        </Box>

                        <Box
                            pt={2}
                            display={"flex"}
                            alignItems={"center"}
                        >
                            <MdSwapHoriz
                                size={30}
                                className="color_primary"
                            />
                            <h6 className="pt-1 ps-2 text-success">
                                Refundable
                            </h6>
                        </Box>
                        <div className="flight-detail-headings">
                            <RefundExchange />
                        </div>

                        {/* <LightTooltip
                            title={
                                <div className="p-3">
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex me-4">
                                            <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                            <h5>* {searchValues?.adultsCount}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                            {`${CurrencyCode()} ${TwoDecimalPoint(singlePaxFareForForToolTip(sereneSelectedFaresArray[0], searchValues?.adultsCount))}`}
                                        </h6>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-end my-2">
                                        <div className="d-flex">
                                            <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                            <h5>* {searchValues?.childCount}</h5>
                                        </div>
                                        {
                                            searchValues?.childCount > 0 &&
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {`${CurrencyCode()} ${TwoDecimalPoint(singlePaxFareForForToolTip(sereneSelectedFaresArray[1], searchValues?.childCount))}`}
                                            </h6>
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex">
                                            <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                            <h5>* {searchValues?.infantNo}</h5>
                                        </div>
                                        {
                                            searchValues?.infantNo > 0 &&
                                            <h6 style={{ minWidth: "7rem" }}>
                                                {`${CurrencyCode()} ${TwoDecimalPoint(singlePaxFareForForToolTip(sereneSelectedFaresArray[sereneSelectedFaresArray?.length - 1], searchValues?.childCount))}`}
                                            </h6>
                                        }
                                    </div>
                                </div>
                            }
                            placement="top"
                        >
                            <button
                                type="button"
                                className={` button_style px-4`}
                            >
                                {`${CurrencyCode()} ${TwoDecimalPoint(
                                    lowestFarePrice(boundToggle === "outBound" ? selectedFareSerene?.outbound?.OfferItem?.FareDetail : selectedFareSerene?.fare?.OfferItem?.FareDetail, "faresSelections")
                                )}`}
                            </button>
                        </LightTooltip> */}

                    </div>
                ) : (
                    <Slider {...settings}>
                        {fares?.fares?.map((brandFare, index) => {
                            let paxes = brandFare?.OfferItem?.FareDetail;
                            paxes = Array.isArray(paxes) ? paxes : [paxes];

                            const adult = paxes[0];
                            const child = searchValues?.childCount > 0 ? paxes[1] : {};
                            const infant = searchValues?.infantNo > 0 ? paxes[paxes?.length - 1] : {};

                            const sereneFares = boundToggle === 'outbound'
                                ? selectedIbFare?.OfferItem?.FareDetail
                                : selectedFare?.OfferItem?.FareDetail;

                            const sereneSelectedFare = Array.isArray(sereneFares) ? sereneFares : [sereneFares]

                            const getTaxes = (fareTaxes, selectedFareTaxes) => {
                                const currentTaxes = fareTaxes || [];
                                selectedFareTaxes = selectedFareTaxes || []
                                // Combine current fare taxes with serene selected fare taxes
                                const combinedTaxes = [...currentTaxes, ...selectedFareTaxes].reduce((acc, tax) => {
                                    const taxCode = tax.TaxCode;  // Assuming tax code is under 'TaxCode' property
                                    const taxAmount = Number(tax.Amount?.["#text"]);  // Assuming tax amount is under 'Amount.#text'

                                    if (acc[taxCode]) {
                                        acc[taxCode] += taxAmount;
                                    } else {
                                        acc[taxCode] = taxAmount;
                                    }
                                    return acc;
                                }, {});

                                // Convert the combined tax amounts back to the required array format
                                return Object.keys(combinedTaxes).map(code => ({
                                    code: code,
                                    amount: combinedTaxes[code].toFixed(2)  // Assuming you want to round to 2 decimal places
                                }));
                            };

                            let baggage =
                                brandFare?.BaggageAllowance?.baggage?.WeightAllowance
                                    ?.MaximumWeightMeasure;
                            baggage = baggage
                                ? `${baggage?.["#text"]} ${baggage?.["-UnitCode"]}`
                                : "No Baggage";


                            const outboundBaggage =
                                selectedFare?.BaggageAllowance?.baggage?.WeightAllowance?.MaximumWeightMeasure;
                            const outboundBag = outboundBaggage ? `${outboundBaggage?.["#text"]} ${outboundBaggage?.["-UnitCode"]}`
                                : "No Baggage";

                            return (
                                <div className="pt-4" key={index}>
                                    <div key={brandFare?.OfferID}>
                                        <div className="fs-5 pb-4 color_primary fw-bold">
                                            {brandFare?.classList?.Name}
                                        </div>

                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box>
                                                <FaSuitcaseRolling size={25} className='color_primary' />
                                            </Box>
                                            <h6 className='ps-2 pt-2'>Upto 7 kg</h6>
                                        </Box>

                                        <Box display={"flex"} alignItems={"center"} pt={2}>
                                            <Box>
                                                <FaSuitcase size={22} className='color_primary' />
                                            </Box>
                                            <h6 className='ps-2 pt-2'>{baggage}</h6>
                                        </Box>
                                        <Box display={"flex"}
                                            alignItems={"center"}
                                            pt={2}
                                        >
                                            <MdOutlineRestaurant
                                                size={25}
                                                className="color_primary"
                                            />
                                            <h6 className="ps-2 pt-2">Meal: Allowed</h6>
                                        </Box>

                                        <Box display={"flex"}
                                            alignItems={"center"}
                                            pt={2}>
                                            <Box>
                                                <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
                                            </Box>
                                            <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
                                        </Box>

                                        <Box
                                            pt={2}
                                            display={"flex"}
                                            alignItems={"center"}
                                        >
                                            <MdSwapHoriz
                                                size={30}
                                                className="color_primary"
                                            />
                                            <h6 className="pt-1 ps-2 text-success">
                                                Refundable
                                            </h6>
                                        </Box>
                                        <div className="flight-detail-headings">
                                            <RefundExchange />
                                        </div>

                                        {true ? (
                                            <div className="pt-3 px-2">
                                                {!brandFare?.OfferItem?.FareDetail ? (
                                                    <button disabled className="button_style">
                                                        Sold Out
                                                    </button>
                                                ) : (
                                                    <LightTooltip
                                                        title={
                                                            <div className="p-3">
                                                                <div className="d-flex justify-content-between align-items-end">
                                                                    <div className="d-flex me-4">
                                                                        <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                                                        <h5>* {searchValues?.adultsCount}</h5>
                                                                    </div>
                                                                    <h6 style={{ minWidth: "7rem" }}>
                                                                        {`${CurrencyCode()} ${TwoDecimalPoint(perPaxPrice(adult, searchValues?.adultsCount, sereneSelectedFare[0]))}`}
                                                                    </h6>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-end my-2">
                                                                    <div className="d-flex">
                                                                        <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                                                        <h5>* {searchValues?.childCount}</h5>
                                                                    </div>
                                                                    <h6 style={{ minWidth: "7rem" }}>
                                                                        {`${CurrencyCode()} ${TwoDecimalPoint(perPaxPrice(child, searchValues?.childCount, searchValues?.childCount > 0 && sereneSelectedFare[1]))}`}
                                                                    </h6>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-end">
                                                                    <div className="d-flex">
                                                                        <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                                                        <h5>* {searchValues?.infantNo}</h5>
                                                                    </div>
                                                                    <h6 style={{ minWidth: "7rem" }}>
                                                                        {`${CurrencyCode()} ${TwoDecimalPoint(perPaxPrice(infant, searchValues?.infantNo, searchValues?.infantNo > 0 && sereneSelectedFare[sereneSelectedFare?.length - 1]))}`}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        }
                                                        placement="top"
                                                    >
                                                        <button
                                                            type="button"
                                                            className={`${selectedFare?.OfferID === brandFare?.OfferID
                                                                ? "button_acitve"
                                                                : "button_style"
                                                                } px-4`}
                                                            onClick={() => {
                                                                if (boundToggle === "outbound") {
                                                                    setBoundToggle("inBound")
                                                                    setSelectedFare({
                                                                        ...brandFare,
                                                                        segments: fares?.segments,
                                                                    });
                                                                }
                                                                if (boundToggle === "inbound") {
                                                                    const InOutSegments = sereneFLT?.outbound?.segments.concat(sereneFLT?.inbound?.segments)
                                                                    let bookingBody = {
                                                                        outbound: selectedFare,
                                                                        flight: sereneFLT,
                                                                        fare: brandFare,
                                                                        gds: "SERENE-API",
                                                                        price: TwoDecimalPoint(lowestFarePrice(
                                                                            brandFare?.OfferItem?.FareDetail)),
                                                                        oferRefId: [selectedFare?.OfferID, brandFare?.OfferID],
                                                                        oferItemRefId: [selectedFare?.OfferItem?.OfferItemID, brandFare?.OfferItem?.OfferItemID],
                                                                        shopRefId: sereneFLT?.inbound?.ShoppingResponseRefID,

                                                                        ttl_agent_fare: TwoDecimalPoint(
                                                                            CurrencyExchangeToPKR(
                                                                                Number(
                                                                                    TwoDecimalPoint(lowestFarePrice(
                                                                                        brandFare?.OfferItem?.FareDetail))
                                                                                )
                                                                            )
                                                                        ),
                                                                        ttlagent_curr_fare: TwoDecimalPoint(
                                                                            Number(lowestFarePrice(brandFare?.OfferItem?.FareDetail))
                                                                        ),
                                                                        ttl_supplier_fare: TwoDecimalPoint(
                                                                            Number(supplierPrice(
                                                                                brandFare?.OfferItem?.FareDetail
                                                                            ) + supplierPrice(
                                                                                selectedFare?.OfferItem?.FareDetail
                                                                            ))
                                                                        ),
                                                                        ttlsup_curr_fare: TwoDecimalPoint(
                                                                            Number(
                                                                                CurrencyExchange(
                                                                                    supplierPrice(brandFare?.OfferItem?.FareDetail) + supplierPrice(selectedFare?.OfferItem?.FareDetail)
                                                                                )
                                                                            )
                                                                        ),
                                                                        adlt_ttl_d_curr: CurrencyExchangeToPKR(
                                                                            Number(
                                                                                TwoDecimalPoint(perPaxPrice(adult, 1, sereneSelectedFare[0]))
                                                                            )
                                                                        ),
                                                                        chld_ttl_d_curr: CurrencyExchangeToPKR(
                                                                            Number(
                                                                                TwoDecimalPoint(perPaxPrice(child, 1, sereneSelectedFare[1]))
                                                                            )
                                                                        ),
                                                                        inft_ttl_d_curr: CurrencyExchangeToPKR(
                                                                            Number(
                                                                                TwoDecimalPoint(perPaxPrice(infant, 1, sereneSelectedFare[sereneSelectedFare?.length - 1]))
                                                                            )
                                                                        ),
                                                                        adultBaseFare: TwoDecimalPoint(getPaxSupplierPrice(
                                                                            adult,
                                                                            1
                                                                        ).basePrice + getPaxSupplierPrice(sereneSelectedFare[0], 1).basePrice),
                                                                        childBaseFare: TwoDecimalPoint(getPaxSupplierPrice(
                                                                            child,
                                                                            1
                                                                        ).basePrice + getPaxSupplierPrice(sereneSelectedFare[1], 1).basePrice),

                                                                        infantBaseFare: TwoDecimalPoint(getPaxSupplierPrice(
                                                                            infant,
                                                                            1
                                                                        ).basePrice + getPaxSupplierPrice(sereneSelectedFare[sereneSelectedFare?.length - 1], 1).basePrice),

                                                                        adultTaxes: TwoDecimalPoint(getPaxSupplierPrice(
                                                                            adult,
                                                                            1
                                                                        ).taxes + getPaxSupplierPrice(sereneSelectedFare[0], 1).taxes),

                                                                        childTaxes: TwoDecimalPoint(getPaxSupplierPrice(
                                                                            child,
                                                                            1
                                                                        ).taxes + getPaxSupplierPrice(sereneSelectedFare[1], 1).taxes),

                                                                        infantTaxes: TwoDecimalPoint(getPaxSupplierPrice(
                                                                            infant,
                                                                            1
                                                                        ).taxes + getPaxSupplierPrice(sereneSelectedFare[sereneSelectedFare?.length - 1], 1).taxes),

                                                                        ADLT_TAXES: getTaxes(adult?.Price?.TaxSummary?.Tax, sereneSelectedFare[0]?.Price?.TaxSummary?.Tax),
                                                                        CHLD_TAXES: searchValues?.childCount > 0 ? getTaxes(child?.Price?.TaxSummary?.Tax, sereneSelectedFare[1]?.Price?.TaxSummary?.Tax) : [],
                                                                        INFT_TAXES: searchValues?.infantNo > 0 ? getTaxes(infant?.Price?.TaxSummary?.Tax, sereneSelectedFare[sereneSelectedFare?.length - 1]?.Price?.TaxSummary?.Tax) : [],

                                                                        baggageName: brandFare?.classList?.Name,
                                                                        logo: "Serene-api",
                                                                        code: "ER",
                                                                        booking: {
                                                                            gds: "SERENE", // should be gds code
                                                                            pnr: "", // returned in pricing response
                                                                            agent_name: sereneFLT?.agent_name || "serene",
                                                                            segments: InOutSegments?.map((segment, index) => ({
                                                                                departure_date: moment(
                                                                                    segment?.Dep?.AircraftScheduledDateTime
                                                                                ).format("YYYY-MM-DD"),
                                                                                baggage: index === 0 ? outboundBag : baggage,
                                                                                departure_time:
                                                                                    segment?.Dep?.AircraftScheduledDateTime?.split(
                                                                                        "T"
                                                                                    )
                                                                                        ?.at(1)
                                                                                        ?.slice(0, 5),
                                                                                arrival_date: moment(
                                                                                    segment?.Arrival?.AircraftScheduledDateTime
                                                                                ).format("YYYY-MM-DD"),
                                                                                arrival_time:
                                                                                    segment?.Arrival?.AircraftScheduledDateTime?.split(
                                                                                        "T"
                                                                                    )
                                                                                        ?.at(1)
                                                                                        ?.slice(0, 5),
                                                                                origin_terminal: "",
                                                                                desti_terminal: "",
                                                                                flight_number:
                                                                                    segment?.MarketingCarrierInfo
                                                                                        ?.MarketingCarrierFlightNumberText,
                                                                                number_in_party: "",
                                                                                res_book_desig_code: "",
                                                                                status: "NN",
                                                                                des_location: index === 0 ? searchValues?.locationArrival : searchValues?.locationDep,
                                                                                dest_code: segment?.Dep?.IATA_LocationCode,
                                                                                marketing_airline: "ER",
                                                                                orig_location: index === 0 ? searchValues?.locationDep : searchValues?.locationArrival,
                                                                                orig_code: segment?.Arrival?.IATA_LocationCode,
                                                                                marriage_grp: "",
                                                                                cabin_class: index === 0 ? selectedFare?.classList?.Name : brandFare?.classList?.Name,
                                                                                segment_type: getSectorsName(
                                                                                    searchValues?.locationDep,
                                                                                    searchValues?.locationArrival
                                                                                ), // dom | siti | soto | group
                                                                            })),
                                                                            tp_ur_code: "",
                                                                            platting_carrier: "ER",
                                                                            tp_ssr_verssion: 0,
                                                                            tp_trace_id: "",
                                                                        },
                                                                    };
                                                                    dispatch(
                                                                        setselectedFlight({
                                                                            ...bookingBody,
                                                                        })
                                                                    );
                                                                    navigate("/check-out");
                                                                }

                                                            }}
                                                        >

                                                            {`${CurrencyCode()} ${lowestFarePrice(brandFare?.OfferItem?.FareDetail)}`}
                                                        </button>
                                                    </LightTooltip>
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            );
                        })}
                    </Slider>
                )}
        </>
    );
};


const ConnectedFlight = ({
    item,
    inx,
    calculateTotalTimeDifference,
    fligthSegment,
    operatedAirline,
    convertTime,
}) => {
    const [airport_name, setairport_name] = useState("");
    const [arr_airport_name, setArrairport_name] = useState("");

    //api call for fatching airport name
    useEffect(() => {
        const fetchDataDep = async () => {
            try {
                let airportCode = item?.Dep?.IATA_LocationCode;
                const response = await NODEURL.get(
                    `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
                );
                const data = response.data.data?.at(0)?.airport_name;
                // Handle the data here, for example, set it in the state
                setairport_name(data);
            } catch (error) {
                // Handle errors here
            }
        };

        const fetchDataArr = async () => {
            try {
                let airportCode = item?.Arrival?.IATA_LocationCode;
                const response = await NODEURL.get(
                    `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
                );
                const data = response.data.data?.at(0)?.airport_name;
                // Handle the data here, for example, set it in the state
                setArrairport_name(data);
            } catch (error) {
                // Handle errors here
            }
        };

        fetchDataDep(); // Call the fetchData function
        fetchDataArr(); // Call the fetchData function
    }, [item?.Dep?.IATA_LocationCode]);

    return (
        <div key={inx}>
            <div className="row align-items-center">
                <div className="col-2">
                    <img src={"/NEW_LOGO/ER.png"} alt="logo" width={70} height={70} />
                    <h6 className="mt-3 ms-2">
                        {item?.MarketingCarrierInfo?.CarrierDesigCode}{" "}
                        {item?.MarketingCarrierInfo?.MarketingCarrierFlightNumberText}
                    </h6>

                    {item?.flightSegment?.operatingAirline?.code ? (
                        <div className="mt-2">
                            Operated by : {item?.flightSegment?.operatingAirline?.code}
                        </div>
                    ) : null}
                </div>
                <div className="col-10 d-flex justify-content-between align-items-center">
                    <div className="col-5 d-flex align-items-center">
                        <div className="me-3">
                            <MdOutlineFlightTakeoff size={30} className="color_primary" />
                        </div>
                        <div className="">
                            <h6>
                                {moment(item?.Dep?.AircraftScheduledDateTime).format(
                                    "ddd DD-MMM-YY"
                                )}
                            </h6>

                            <h6 className="my-3">
                                {item?.Dep?.AircraftScheduledDateTime?.split("T")
                                    ?.at(1)
                                    ?.slice(0, 5)}
                            </h6>
                            <h6>
                                {item?.Dep?.IATA_LocationCode} - {airport_name}
                            </h6>
                            {item?.flightSegment?.departureAirport?.terminal && (
                                <h6 className="color_primary">
                                    (Terminal-{item?.flightSegment?.departureAirport?.terminal})
                                </h6>
                            )}
                        </div>
                    </div>

                    <div className="col-2 d-flex flex-column align-items-start">
                        <div className="d-flex justify-content-center">
                            <MdFlight className="color_primary fs-5 rotate-right" />
                            <h6 className="text-center">Trip Time</h6>
                        </div>
                        <div className="border_rounded mb-2" />
                        <h6 className="text-center ms-4">
                            {convertTime(
                                item?.Dep?.AircraftScheduledDateTime,
                                item?.Arrival?.AircraftScheduledDateTime
                            )}
                        </h6>
                    </div>
                    <div className="col-5 d-flex align-items-center ps-4">
                        <div className="me-3">
                            <MdOutlineFlightLand size={30} className="color_primary" />
                        </div>
                        <div>
                            <h6>
                                {moment(item?.Arrival?.AircraftScheduledDateTime).format(
                                    "ddd DD-MMM-YY"
                                )}
                            </h6>
                            <h6 className="my-3">
                                {item?.Arrival?.AircraftScheduledDateTime?.split("T")
                                    ?.at(1)
                                    ?.slice(0, 5)}
                            </h6>
                            <h6>
                                {item?.Arrival?.IATA_LocationCode} - {arr_airport_name}
                            </h6>
                            {item?.flightSegment?.arrivalAirport?.terminal && (
                                <h6 className="color_primary">
                                    (Terminal-{item?.flightSegment?.arrivalAirport?.terminal})
                                </h6>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {inx < fligthSegment.length - 1 && (
                <div className="col-12 d-flex justify-content-center align-items-center my-4">
                    <div className="border_rounded w-25" />
                    <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
                        <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
                        <h6 className="pt-2">
                            Layover time:{" "}
                            <span className="fs-5">
                                {" "}
                                {calculateTotalTimeDifference(
                                    item?.Arrival?.AircraftScheduledDateTime,
                                    fligthSegment[inx + 1]?.Dep?.AircraftScheduledDateTime
                                )}
                            </span>
                        </h6>
                    </div>
                    <div className="border_rounded w-25" />
                </div>
            )}
        </div>
    );
};
