import moment from "moment";

export const TwoDecimalPoint = (number) => {
  const newNumber = number ? number?.toString() : "0";
  const ind1 = newNumber?.split(".")[0];
  const ind2 = newNumber?.split(".")[1]?.substring(0, 2);
  const res = ind2 ? ind1 + "." + ind2 : ind1;
  return Number(res);
};
export const timeDuration = (time1, time2) => {
  if (!time1 || !time2) {
    return null;
  }
  const depMoment = moment(time1);
  const arrMoment = moment(time2);
  const duration = moment.duration(arrMoment.diff(depMoment));
  const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
  const minutes = String(duration.minutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};
