import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  agencyPermissions: {},
  loading: false,
  error: null,
};

// Get Agency permission assigned by admin through Agency page
export const asyncGetAgencyPermissions = createAsyncThunk(
  "asyncGetAgencyPermissions/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/get-all-agency");
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const usersSlice = createSlice({
  name: "agencyPermissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Agency permission assigned by admin through Agency page
    builder.addCase(asyncGetAgencyPermissions.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetAgencyPermissions.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.agencyPermissions = payload;
      }
    );
    builder.addCase(
      asyncGetAgencyPermissions.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export default usersSlice.reducer;
