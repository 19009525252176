import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { options } from "../../../Utils/ColumnOptions";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncEditAboutUs,
  asyncGetAboutUsData,
  asyncPostAboutus,
  asyncUpdateAboutUs,
  editAbout_null,
} from "../../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import BtnLoader from "../../AppForm/BtnLoader";
import { apiUrl } from "../../../Utils/configUrl";

const AboutUs = () => {
  const { aboutUsData, editAboutus, loading } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // if the login user has permissions to add about us
  const postPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/post-aboutUs"
  );
  // if the user has permission to edit about us
  const editPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/update-aboutUs"
  );

  const SUPPORTED_FORMATS = ["image/png"];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(asyncGetAboutUsData());
  }, [dispatch]);
  /******************Initials values ********** */
  const initialValues = {
    image: "",
    heading: editAboutus?.heading || "",
    slogan: editAboutus?.description || "",
  };

  /**********Validation schema ************ */
  const validationSchema = yup.object().shape({
    image: editAboutus
      ? yup
          .mixed()
          .nullable()
          .test(
            "format",
            "Only png formate are accepted",
            (value) =>
              !value || (value && SUPPORTED_FORMATS.includes(value.type))
          )
      : yup
          .mixed()
          .nullable()
          .required("Image is required")
          .test(
            "format",
            "Only png formate are accepted",
            (value) =>
              !value || (value && SUPPORTED_FORMATS.includes(value.type))
          ),
    heading: yup.string().required("Heading is Required"),
    slogan: yup.string().required("Slogan is Required"),
  });

  /*********table column *********/
  const column = [
    {
      label: "Id",
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <img
                style={{ height: 80, width: 80 }}
                src={`${apiUrl}/${value}`}
                alt={value}
              />
            </div>
          );
        },
      },
    },
    {
      label: "Heading",
      name: "heading",
    },
    {
      label: "Slogan",
      name: "description",
      options: {
        setCellProps: () => ({ style: { maxWidth: "400px" } }),
        customBodyRender: (value) => {
          return <div className="text-justify">{value}</div>;
        },
      },
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {editPermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-success "
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                    dispatch(asyncEditAboutUs(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-thin fa-pencil" aria-hidden="true"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="pb-5">
      {/************ADD EDIT ABOUT US ************* */}
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, resetForm) => {
          const formData = new FormData();
          formData.append("heading", values.heading);
          values.image && formData.append("image", values.image);
          formData.append("description", values.slogan);

          if (editAboutus?.heading) {
            dispatch(asyncUpdateAboutUs({ formData, id: editAboutus?.id }));
            dispatch(editAbout_null());
          } else {
            dispatch(asyncPostAboutus(formData));
            resetForm();
          }
        }}
      >
        {({ values, setFieldValue, resetForm }) => {
          return (
            <Form>
              <div className="mx-5 rounded-2 mt-5 bg-white shadow-sm pb-4">
                <p className="h4 otawix-heading box_title">
                  Add / Edit About Us
                </p>
                <div className="row p-5">
                  <div className="col-3">
                    <TextField
                      id="standard-basic"
                      label={[
                        "Image ",
                        <span className="text-danger">
                          (Size 560*480 only Png)
                        </span>,
                      ]}
                      variant="standard"
                      className="w-100"
                      type={"file"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="image"
                      onChange={(e) =>
                        setFieldValue("image", e.currentTarget.files[0])
                      }
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      id="standard-basic"
                      autoComplete="off"
                      label="Heading"
                      variant="standard"
                      className="w-100"
                      name="heading"
                      value={values.heading}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("heading", value.toUpperCase());
                      }}
                    />
                  </div>
                  <div className="col-3">
                    <TextField
                      id="standard-basic"
                      autoComplete="off"
                      label="Slogan"
                      variant="standard"
                      className="w-100"
                      name="slogan"
                      value={values.slogan}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("slogan", value.toUpperCase());
                      }}
                    />
                  </div>
                </div>
                <div className="text-center my-5">
                  {editAboutus && (
                    <button
                      className="button_style px-5 bg-danger me-2"
                      onClick={(e) => {
                        dispatch(editAbout_null());
                        resetForm({
                          image: "",
                        });
                      }}
                      type="button"
                    >
                      Cancel
                    </button>
                  )}
                  {editAboutus ? (
                    <button className="button_style px-5" type="submit">
                      {loading ? <BtnLoader /> : "Update"}
                    </button>
                  ) : null}

                  {(postPermission !== undefined || user?.user_id === 1) &&
                  !editAboutus ? (
                    <button className="button_style px-5" type="submit">
                      {loading ? <BtnLoader /> : "Save"}
                    </button>
                  ) : null}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      {/******************About Us List */}
      <div className="mx-5 rounded-2 mt-5 bg-white shadow-sm mb-3">
        <p className="h4 otawix-heading box_title">About Us</p>
        <div className="p-5">
          <MUIDataTable
            title={""}
            columns={column}
            data={[aboutUsData]}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
