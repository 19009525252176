import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { options } from "../../../Utils/ColumnOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteOurFooterGallery,
  asyncEditOurFooterGallery,
  asyncGetOurFooterGallery,
  asyncPostOurFooterGallery,
  asyncUpdateOurFooterGallery,
  editfootergallery_null,
} from "../../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import { apiUrl } from "../../../Utils/configUrl";
import * as yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";
import { FieldArray, Form, Formik } from "formik";

const FooterGallery = () => {
  const { editfooter, footerStateData, loading } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  const SUPPORTED_FORMATS = ["image/png"];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncGetOurFooterGallery());
  }, [dispatch]);

  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));

  // if the login user has permissions to add footer
  const postPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/post-footer"
  );
  // if the user has permission to edit footer
  const editPermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/update-footer"
  );
  // if the user has permission to edit footer
  const deletePermission = userPermission?.find(
    (it) =>
      it?.user_id === user?.user_id &&
      it?.page_level.permission_url === "/update-footer"
  );

  /******************Initials values ********** */
  const initialValues = {
    footerValue: [
      {
        image: "",
      },
    ],
  };

  const column = [
    {
      label: "id",
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Image",
      name: "image",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <img
                style={{ height: 80, width: 80 }}
                src={`${apiUrl}/${value}`}
                alt={value}
              />
            </div>
          );
        },
      },
    },
    {
      label: "Action",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>
              {editPermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-success "
                  onClick={() => {
                    window.scroll({ top: 0, behavior: "smooth" });
                    dispatch(asyncEditOurFooterGallery(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-thin fa-pencil" aria-hidden="true"></i>
                </button>
              ) : null}
              {deletePermission !== undefined || user?.user_id === 1 ? (
                <button
                  className="edit-delete-icon-button btn-danger ms-3"
                  onClick={() => {
                    dispatch(asyncDeleteOurFooterGallery(tableMeta.rowData[0]));
                  }}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>
                </button>
              ) : null}
            </div>
          );
        },
      },
    },
  ];

  const validationSchema = yup.object().shape({
    footerValue: yup.array().of(
      yup.object().shape({
        image: editfooter
          ? yup
              .mixed()
              .nullable()
              .test(
                "format",
                "Only png logo are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              )
          : yup
              .mixed()
              .nullable()
              .required("Image is required")
              .test(
                "format",
                "Only png logo are accepted",
                (value) =>
                  !value || (value && SUPPORTED_FORMATS.includes(value.type))
              ),
      })
    ),
  });

  return (
    <div className="pb-5">
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const formData = new FormData();
          values?.footerValue.map((it) => formData.append("image", it.image));
          if (!editfooter) {
            for (const [key, value] of Object.entries(values)) {
              value.map((it) => dispatch(asyncPostOurFooterGallery(it)));
            }
            resetForm({});
          } else {
            dispatch(
              asyncUpdateOurFooterGallery({ formData, id: editfooter?.id })
            );
            dispatch(editfootergallery_null());
          }
        }}
      >
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <div className="col-7 mx-auto rounded-2 mt-5 bg-white">
                <p className="h4 otawix-heading box_title">
                  Footer Gallery Image
                </p>
                <div className="p-5">
                  <FieldArray
                    name={`footerValue`}
                    render={(arrayHelpers) => (
                      <div>
                        {values.footerValue.map((val, index) => (
                          <div key={index} className="row">
                            <div className="col-4">
                              <TextField
                                fullWidth
                                name={`footerValue.${index}.image`}
                                variant="standard"
                                label="Image (size 120*90ps only png)"
                                type="file"
                                onChange={(e) =>
                                  setFieldValue(
                                    `footerValue.${index}.image`,
                                    e.currentTarget.files[0]
                                  )
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <p style={{ color: "red" }}>
                                {errors?.footerValue &&
                                  errors?.footerValue[index]?.image}
                              </p>
                            </div>

                            <div className="col-4">
                              {index >= 1 && (
                                <button
                                  type="button"
                                  className="button_style px-5 bg-danger "
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  - Footer Image
                                </button>
                              )}
                              {index === 0 && (
                                <button
                                  className="button_style px-5"
                                  type="button"
                                  onClick={() => arrayHelpers.push("")}
                                >
                                  + Footer Image
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />

                  <div className="text-center mt-5">
                    {editfooter && (
                      <button
                        className="button_style bg-danger px-5 me-2"
                        onClick={(e) => {
                          dispatch(editfootergallery_null());
                        }}
                        type="button"
                      >
                        Cancel
                      </button>
                    )}
                    {editfooter ? (
                      <button className="button_style px-5 me-2" type="submit">
                        {loading ? <BtnLoader /> : "Update"}
                      </button>
                    ) : null}

                    {(postPermission !== undefined || user?.user_id === 1) &&
                    !editfooter ? (
                      <button className="button_style px-5" type="submit">
                        {loading ? <BtnLoader /> : "Save"}
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-7 mx-auto bg-white rounded-2 mt-5">
                <p className="h4 otawix-heading box_title">
                  Footer Gallery List
                </p>
                <div className="p-5">
                  <MUIDataTable
                    title={""}
                    columns={column}
                    options={options}
                    data={footerStateData}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FooterGallery;
