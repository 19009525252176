
  const data = [
    {
      type: "info",
      title: "MR",
      first_name: "Joe",
      last_name: "James",
      company: "Test Corp",
      date_of_birth: "09/07/2022",
      pass_no: "Awm123",
      visa_no: "abc123",
      visa_supplier: "otawix"
    },
    {
      type: "info",
      title: "MR",
      first_name: "Joe",
      last_name: "James",
      company: "Test Corp",
      date_of_birth: "09/07/2022",
      pass_no: "Awm123",
      visa_no: "abc123",
      visa_supplier: "otawix"
    },
    {
      type: "info",
      title: "MR",
      first_name: "Joe",
      last_name: "James",
      company: "Test Corp",
      date_of_birth: "09/07/2022",
      pass_no: "Awm123",
      visa_no: "abc123",
      visa_supplier: "otawix"
    },
    {
      type: "info",
      title: "MR",
      first_name: "Joe",
      last_name: "James",
      company: "Test Corp",
      date_of_birth: "09/07/2022",
      pass_no: "Awm123",
      visa_no: "abc123",
      visa_supplier: "otawix"
    },
    {
      type: "info",
      title: "MR",
      first_name: "Joe",
      last_name: "James",
      company: "Test Corp",
      date_of_birth: "09/07/2022",
      pass_no: "Awm123",
      visa_no: "abc123",
      visa_supplier: "otawix"
    },
    {
      type: "info",
      title: "MR",
      first_name: "Joe",
      last_name: "James",
      company: "Test Corp",
      date_of_birth: "09/07/2022",
      pass_no: "Awm123",
      visa_no: "abc123",
      visa_supplier: "otawix"
    },
    {
      type: "info",
      title: "MR",
      first_name: "Joe",
      last_name: "James",
      company: "Test Corp",
      date_of_birth: "09/07/2022",
      pass_no: "Awm123",
      visa_no: "abc123",
      visa_supplier: "otawix"
    },
  ];
  export default data