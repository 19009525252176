import React from "react";
import AirSial from "../../../../assets/images/airlineLogo/PF.png";
import moment from "moment";

import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import { CurrencyExchange } from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "./LightTooltip";
import {
  setExpandFlightDetails,
  setReturnFlight,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";

/////////////// Sial /////////////
function AirsialData({ data, index, isExpanded, toggleDetail }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedFlight } = useSelector((state) => state.FlightSearch);
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const currency = JSON.parse(localStorage.getItem("currency"));

  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "PF"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "PF");

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  // calculate the lowest fare price
  const lowestFarePrice = (fare) => {
    let newFare = CurrencyExchange(
      (pricingairline(
        airline,
        pricing,
        fare?.ADULT?.BASIC_FARE,
        fare?.ADULT?.TOTAL - fare?.ADULT?.BASIC_FARE,
        getSectorsName(locDeparture, locArrival)
      )?.total_fare * searchValues?.adultsCount || 0) +
        (pricingairline(
          airline,
          pricing,
          fare?.CHILD?.BASIC_FARE,
          fare?.CHILD?.TOTAL - fare?.CHILD?.BASIC_FARE,
          getSectorsName(locDeparture, locArrival)
        )?.total_fare * searchValues?.childCount || 0) +
        (pricingairline(
          airline,
          pricing,
          fare?.INFANT?.BASIC_FARE,
          fare?.INFANT?.TOTAL - fare?.INFANT?.BASIC_FARE,
          getSectorsName(locDeparture, locArrival)
        )?.total_fare * searchValues?.infantNo || 0),
      currency
    );
    return newFare;
  };

  //////////// convert time to required formate
  function formatDuration(inputDuration) {
    // Use a custom regular expression to extract hours and minutes
    const regex = /(\d+)h (\d+)m/;
    const match = inputDuration.match(regex);

    if (match) {
      const hours = parseInt(match[1]);
      const minutes = parseInt(match[2]);

      // Calculate the duration using Moment.js
      const duration = moment.duration({ hours, minutes });

      // Format the duration as 'hh:mm'
      const formattedDuration = `${duration
        .hours()
        .toString()
        .padStart(2, "0")}:${duration.minutes().toString().padStart(2, "0")}`;

      return formattedDuration;
    } else {
      return "Invalid input format"; // Handle invalid input
    }
  }

  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  return (
    <>
      <div className="search_engin_result_box bg-white">
        <div className="shadow">
          <div className="row align-items-center">
            <div className="col-2">
              <div className="px-2">
                <div className="text-center">
                  <img
                    src={AirSial}
                    alt="airsial"
                    style={{ width: "100px", height: "80px" }}
                  />
                </div>
                <p className="text-center">{data?.FLIGHT_NO}</p>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div>
                  <h6>
                    {moment(data?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                      "DD-MMM-YY"
                    )}
                  </h6>
                  <h6 className="my-3">{data?.DEPARTURE_TIME}</h6>
                  <h6>
                    {data?.ORGN} -{" "}
                    {selectedFlight
                      ? getCity(locArrival)
                      : getCity(locDeparture)}
                  </h6>
                </div>

                <div>
                  <h6 className="text-center">
                    {formatDuration(data?.DURATION)}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">Non-Stop</h6>
                </div>

                <div>
                  <h6>
                    {moment(data?.DEPARTURE_DATE, "DD,MM,YYYY").format(
                      "DD-MMM-YY"
                    )}
                  </h6>
                  <h6 className="my-3">{data?.ARRIVAL_TIME}</h6>
                  <h6>
                    {data?.DEST} -{" "}
                    {selectedFlight
                      ? getCity(locDeparture)
                      : getCity(locArrival)}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow d-flex flex-column align-items-center py-4">
                <h4>{lowestFarePrice(data?.BAGGAGE_FARE[0]?.FARE_PAX_WISE)}</h4>
                <button
                  onClick={() => toggleDetail(index)}
                  className="button_style w-75 my-3"
                  type="button"
                >
                  {isExpanded ? "Cancel" : "Select"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-3">
            <div className="search_engin_result_box py-3 bg-white h-auto">
              <div className="d-flex justify-content-between align-items-start px-5">
                {/* headings */}
                <div className="border-r">
                  <div className="mb-4 pb-3"></div>
                  <div className="flight-detail-headings">
                    <BsHandbag size={25} className="color_primary" />
                    <span>Hand Carry Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsBagCheck size={25} className="color_primary" />
                    <span>Checked Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdOutlineRestaurant size={25} className="color_primary" />
                    <span>Meals</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsPerson size={25} className="color_primary" />
                    <span>Seat Selection</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdSwapHoriz size={25} className="color_primary" />
                    <span>Refund & Exchanges</span>
                  </div>
                </div>

                {data?.BAGGAGE_FARE?.map((fare, index) => {
                  return (
                    <div key={index}>
                      <div className="fs-4 theme-text-color mb-3">
                        {fare?.sub_class_desc}
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Upto 7 kg</span>
                      </div>
                      <div className="flight-detail-headings">
                        <>
                          <MdCheck size={25} color="green" />
                          <span>
                            {fare?.weight} kg ({fare?.piece} Pcs)
                          </span>
                        </>
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Allowed</span>
                      </div>
                      <div className="flight-detail-headings">
                        <CgMenuGridR size={25} color="#38025c" />
                        <span>System Allocated</span>
                      </div>
                      {/* refund and exchange dialog  */}
                      <div className="flight-detail-headings">
                        <LightTooltip
                          title={
                            <div className="p-3">
                              <h4>Refund & Exchange</h4>
                              <h6>
                                Penalty will be charged per passenger segment.
                              </h6>

                              <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                <div className="me-5">
                                  <h4>Exchange</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {CurrencyExchange(1500, currency)}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {CurrencyExchange(2500, currency)}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {CurrencyExchange(3500, currency)}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {CurrencyExchange(4500, currency)}
                                  </h6>
                                </div>
                                <div>
                                  <h4>Refund</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {CurrencyExchange(1500, currency)}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {CurrencyExchange(2500, currency)}
                                  </h6>
                                  <h6>
                                    Within 24 Hours:{" "}
                                    {CurrencyExchange(3500, currency)}
                                  </h6>
                                  <h6>
                                    Within 03 Hours:{" "}
                                    {CurrencyExchange(4500, currency)}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          }
                          placement="top-start"
                        >
                          <div style={{ cursor: "pointer" }}>
                            <MdRemoveRedEye size={25} color="#262829" />
                            <span>View</span>
                          </div>
                        </LightTooltip>
                      </div>
                      {/* ///////////// fare button and tooltip */}
                      <div className="pt-3 px-2">
                        <LightTooltip
                          title={
                            <div className="p-3">
                              <div className="d-flex justify-content-between align-items-end">
                                <div className="d-flex me-4">
                                  <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                  <h5>* {searchValues?.adultsCount}</h5>
                                </div>
                                <h6 style={{ minWidth: "7rem" }}>
                                  {CurrencyExchange(
                                    pricingairline(
                                      airline,
                                      pricing,
                                      fare?.FARE_PAX_WISE?.ADULT?.BASIC_FARE,
                                      fare?.FARE_PAX_WISE?.ADULT?.TOTAL -
                                        fare?.FARE_PAX_WISE?.ADULT?.BASIC_FARE,
                                      getSectorsName(locDeparture, locArrival)
                                    )?.total_fare * searchValues?.adultsCount ||
                                      0,
                                    currency
                                  )}
                                </h6>
                              </div>
                              <div className="d-flex justify-content-between align-items-end my-2">
                                <div className="d-flex">
                                  <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                  <h5>* {searchValues?.childCount}</h5>
                                </div>
                                <h6 style={{ minWidth: "7rem" }}>
                                  {CurrencyExchange(
                                    pricingairline(
                                      airline,
                                      pricing,
                                      fare?.FARE_PAX_WISE?.CHILD?.BASIC_FARE,
                                      fare?.FARE_PAX_WISE?.CHILD?.TOTAL -
                                        fare?.FARE_PAX_WISE?.CHILD?.BASIC_FARE,
                                      getSectorsName(locDeparture, locArrival)
                                    )?.total_fare * searchValues?.childCount ||
                                      0,
                                    currency
                                  )}
                                </h6>
                              </div>
                              <div className="d-flex justify-content-between align-items-end">
                                <div className="d-flex">
                                  <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                  <h5>* {searchValues?.infantNo}</h5>
                                </div>
                                <h6 style={{ minWidth: "7rem" }}>
                                  {CurrencyExchange(
                                    pricingairline(
                                      airline,
                                      pricing,
                                      fare?.FARE_PAX_WISE?.INFANT?.BASIC_FARE,
                                      fare?.FARE_PAX_WISE?.INFANT?.TOTAL -
                                        fare?.FARE_PAX_WISE?.INFANT?.BASIC_FARE,
                                      getSectorsName(locDeparture, locArrival)
                                    )?.total_fare * searchValues?.infantNo || 0,
                                    currency
                                  )}
                                </h6>
                              </div>
                            </div>
                          }
                          placement="top"
                        >
                          <button
                            type="button"
                            className="button_style px-4"
                            onClick={() => {
                              if (selectedFlight) {
                                dispatch(
                                  setReturnFlight({
                                    flight: data,
                                    fare: fare,
                                    price: lowestFarePrice(fare?.FARE_PAX_WISE),
                                  })
                                );
                                navigate("/check-out");
                              } else {
                                dispatch(
                                  setselectedFlight({
                                    flight: data,
                                    fare: fare,
                                    price: lowestFarePrice(fare?.FARE_PAX_WISE),
                                    baggageName: fare?.sub_class_desc,
                                    flightNumber: `${data?.FLIGHT_NO}`,
                                    depDate: moment(
                                      data?.DEPARTURE_DATE,
                                      "DD,MM,YYYY"
                                    ).format("DD-MMM-YY"),
                                    depTime: data?.DEPARTURE_TIME,
                                    arrDate: moment(
                                      data?.ARRIVAL_UTC?.split(" ")[0],
                                      "DD,MM,YYYY"
                                    ).format("DD-MMM-YY"),
                                    arrTime: data?.ARRIVAL_TIME,
                                    flightDuration: formatDuration(
                                      data?.DURATION
                                    ),
                                    logo: "Sial",
                                  })
                                );
                              }
                              dispatch(setExpandFlightDetails(null));
                            }}
                          >
                            {lowestFarePrice(fare?.FARE_PAX_WISE)}
                          </button>
                        </LightTooltip>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default AirsialData;
