import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FieldArray } from "formik";
import ReactDatePicker from "react-datepicker";
import { dropMenuProp } from "../../../../../Utils/MenuProps";
import { pax_type } from "../helper";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { useSelector } from "react-redux";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

const PaxDetail = ({ values, setFieldValue }) => {
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const isPkr = values.paxDetail?.at(0).cur_label === "PKR" ? true : false;

  return (
    <FieldArray
      name="paxDetail"
      render={(arrayHelpers) => (
        <div>
          {values?.paxDetail?.map((visa, index) => (
            <div
              className="black_border rounded p-3 mb-2 overflow-hidden"
              key={index}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="h4">Pax {index + 1}</div>
                <div>
                  {index >= 1 && (
                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className="button_style bg-danger"
                    >
                      - PAX
                    </button>
                  )}
                  {index === 0 && (
                    <button
                      type="button"
                      onClick={() =>
                        arrayHelpers.push({
                          pax_type: "ADULT",
                          f_name: "",
                          l_name: "",
                          dob: "",
                          nationality: "",
                          passport_no: "",
                          currency: visa.currency,
                          cur_label: visa.cur_label,
                          roe: visa.roe,
                          curr_pay: 0,
                          curr_rec: 0,
                          pkr_pay: 0,
                          pkr_rec: 0,
                        })
                      }
                      className="button_style"
                    >
                      + PAX
                    </button>
                  )}
                </div>
              </div>
              <div className="px-2">
                <div className="row align-items-end">
                  <div className="col-2">
                    <FormControl variant="standard" className="w-100" required>
                      <InputLabel id="pax_type">Pax Type</InputLabel>
                      <Select
                        id="pax_type"
                        value={visa.pax_type}
                        onChange={(e) =>
                          setFieldValue(
                            `paxDetail.${index}.pax_type`,
                            e.target.value
                          )
                        }
                        label={"Pax Type"}
                        MenuProps={dropMenuProp}
                      >
                        {pax_type.map((list) => (
                          <MenuItem value={list} key={list}>
                            {list}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-2">
                    <TextField
                      label="First Name"
                      variant="standard"
                      className="w-100"
                      value={visa.f_name}
                      onChange={(e) => {
                        setFieldValue(
                          `paxDetail.${index}.f_name`,
                          e.target.value.toUpperCase()
                        );
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      label="Last Name"
                      variant="standard"
                      className="w-100"
                      value={visa.l_name}
                      onChange={(e) => {
                        setFieldValue(
                          `paxDetail.${index}.l_name`,
                          e.target.value.toUpperCase()
                        );
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <ReactDatePicker
                      placeholderText="DOB"
                      selected={visa.dob}
                      value={visa.dob}
                      onChange={() => {}}
                      maxDate={new Date()}
                      className="date_picker"
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <div className="col-2">
                    <CountryDropdown
                      id="visa_nationality"
                      className="inputs w-100 absolute"
                      preferredCountries={["pk"]}
                      value={visa.nationality}
                      handleChange={(e) =>
                        setFieldValue("nationality", e.target.value)
                      }
                    ></CountryDropdown>
                  </div>
                  <div className="col-2">
                    <TextField
                      value={visa.passport_no}
                      onChange={(e) => {
                        setFieldValue(
                          `paxDetail.${index}.passport_no`,
                          e.target.value.toUpperCase()
                        );
                      }}
                      label="Pasport No"
                      variant="standard"
                      className="w-100"
                    />
                  </div>
                </div>
                <div className="row align-items-start mt-3">
                  <div className="col-2">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id={`cur_label`}>Currency</InputLabel>
                      <Select
                        id={`cur_label`}
                        value={visa.cur_label}
                        onChange={(e) => {
                          let value = e.target.value;
                          let currency = currencyData?.find(
                            (it) => it.curr_code === value
                          );

                          let updatedValue = values.paxDetail.map((it) => ({
                            ...it,
                            cur_label: value,
                            currency: currency,
                            roe: currency?.roe,
                            // reset this field
                            curr_pay: 0,
                            curr_rec: 0,
                            pkr_pay: 0,
                            pkr_rec: 0,
                          }));
                          setFieldValue("paxDetail", updatedValue);
                        }}
                        label="Currency"
                        MenuProps={dropMenuProp}
                        required
                        inputProps={{
                          readOnly: index > 0,
                        }}
                      >
                        {currencyData?.map((it, ind) => (
                          <MenuItem value={it.curr_code} key={ind}>
                            {it.curr_code}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-2">
                    <TextField
                      value={visa.roe}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue(`paxDetail.${index}.roe`, value);
                        setFieldValue(
                          `paxDetail.${index}.pkr_pay`,
                          TwoDecimalPoint(value * visa.curr_pay)
                        );
                        setFieldValue(
                          `paxDetail.${index}.pkr_rec`,
                          TwoDecimalPoint(value * visa.curr_rec)
                        );
                      }}
                      label="ROE"
                      variant="standard"
                      className="w-100"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: index > 0,
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      value={visa.curr_pay}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue(`paxDetail.${index}.curr_pay`, value);
                        setFieldValue(
                          `paxDetail.${index}.pkr_pay`,
                          TwoDecimalPoint(value * visa.roe)
                        );
                      }}
                      label={`${isPkr ? "Curr" : visa.cur_label} PAY`}
                      variant="standard"
                      className="w-100"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      disabled={isPkr}
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      value={visa.curr_rec}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue(`paxDetail.${index}.curr_rec`, value);
                        setFieldValue(
                          `paxDetail.${index}.pkr_rec`,
                          TwoDecimalPoint(value * visa.roe)
                        );
                      }}
                      label={`${isPkr ? "Curr" : visa.cur_label} REC`}
                      variant="standard"
                      className="w-100"
                      type="number"
                      onFocus={(e) => e.target.select()}
                      disabled={isPkr}
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      value={visa.pkr_pay}
                      onChange={(e) => {
                        setFieldValue(
                          `paxDetail.${index}.pkr_pay`,
                          e.target.value
                        );
                      }}
                      label="PKR PAY"
                      variant="standard"
                      className="w-100"
                      type="number"
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                  <div className="col-2">
                    <TextField
                      value={visa.pkr_rec}
                      onChange={(e) => {
                        setFieldValue(
                          `paxDetail.${index}.pkr_rec`,
                          e.target.value
                        );
                      }}
                      label="PKR REC"
                      variant="standard"
                      className="w-100"
                      type="number"
                      onFocus={(e) => e.target.select()}
                    />
                    {values?.agent && values?.agent_name !== "" && (
                      <div className="text-primary fs-6 mt-3">
                        {CurrencyCode()}{" "}
                        {TwoDecimalPoint(CurrencyExchange(visa.pkr_rec))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default PaxDetail;
