import React, { useState } from "react";
import Beneficiary from "./InsuranceTab/Beneficiary";
import Plan from "./InsuranceTab/Plan";
import Tabs from "./InsuranceTab/Tabs";
import Traveler from "./InsuranceTab/Traveler";
import { Form, Formik, FieldArray } from "formik";

const InsuranceTab = () => {
  const [InputGroup, setInputGroup] = useState("plan");

  const initialValues = {
    covid_protection: "Covid Covered",
    insurer: "Single",
    destination: "Single World wide",
    ins_date: "",
    days: "",
    country: "",
    insure_name: "",
    dob: "",
    email: "",
    pass_no: "",
    cnic: "",
    address: "",
    contact: "",
    ntn_No: "",
    beneficiary: "",
    relationship: "",

    spouse: "",
    age: "",
    passport_No: "",
    select_agent: "",

    family: [
      {
        child: "",
        chl_age: "",
        chl_passport_no: "",
      },
    ],
  };

  const [isActiveTaxPayer, setisActiveTaxPayer] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <div>
            <Tabs InputGroup={InputGroup} setInputGroup={setInputGroup} />

            {InputGroup === "plan" && (
              <Plan setFieldValue={setFieldValue} values={values} />
            )}
            {InputGroup === "traveler" && (
              <Traveler
                setFieldValue={setFieldValue}
                values={values}
                FieldArray={FieldArray}
                isActiveTaxPayer={isActiveTaxPayer}
                setisActiveTaxPayer={setisActiveTaxPayer}
              />
            )}
            {InputGroup === "beneficiary" && (
              <Beneficiary
                setFieldValue={setFieldValue}
                values={values}
                setisActiveTaxPayer={setisActiveTaxPayer}
                isActiveTaxPayer={isActiveTaxPayer}
                FieldArray={FieldArray}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InsuranceTab;
