import { TextField } from "@mui/material";
import React from "react";
import { TwoDecimalPoint } from "../../../../../../Utils/FixedTwoDecimal";

function AgentPkr({ setFieldValue, values, friend, index }) {
  
  return (
    <div className="col-6">
      <div className="row shadow-sm rounded pb-3 blue_border">
        <div className="h5 py-3">Agent In PKR</div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.c_psf_per}
            id={`fareValues.[${index}].tax_pkr.c_psf_per`}
            onChange={(e) => {
              const calcpsf =
                (values.fareValues[index].b_fare / 100) * e.target.value;
             
              const calcgst =
                (e.target.value / 100) * friend?.tax_pkr?.c_gst_per;
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_psf_per`,
                e.target.value
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_psf`,
                TwoDecimalPoint(calcpsf)
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_gst`,
                TwoDecimalPoint(calcgst)
              );
            }}
            label="C.PSF %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.c_sp_per > 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.c_psf}
            id={`fareValues.[${index}].tax_pkr.c_psf`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_psf`,
                e.target.value
              );
            }}
            label="C.PSF"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.c_sp > 0 || friend.tax_pkr.c_psf_per > 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.c_gst_per}
            id={`fareValues.[${index}].tax_pkr.c_gst_per`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_gst_per`,
                e.target.value
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_gst`,
                TwoDecimalPoint(
                  (values.fareValues[index].tax_pkr?.c_psf / 100) *
                    e.target.value
                )
              );
            }}
            label="C.GST %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.c_sp_per > 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.c_gst}
            id={`fareValues.[${index}].tax_pkr.c_gst`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_gst`,
                e.target.value
              );
            }}
            label="C.GST"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.c_sp > 0 || friend.tax_pkr.c_gst_per > 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.c_sp_per}
            id={`fareValues.[${index}].tax_pkr.c_sp_per`}
            onChange={(e) => {
              const calcsp =
                (values.fareValues[index].b_fare / 100) * e.target.value;
              const calcwht =
                (e.target.value / 100) * friend?.tax_pkr?.c_wht_per;
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_sp_per`,
                e.target.value
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_sp`,
                TwoDecimalPoint(calcsp)
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_wht`,
                TwoDecimalPoint(calcwht)
              );
            }}
            label="C.DISC %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.c_psf_per > 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.c_sp}
            id={`fareValues.[${index}].tax_pkr.c_sp`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_sp`,
                e.target.value
              );
            }}
            label="C.DISC"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.c_psf > 0 || friend.tax_pkr.c_sp_per > 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_pkr.c_wht_per}
            id={`fareValues.[${index}].tax_pkr.c_wht_per`}
            onChange={(e) => {
              const value = e.target.value;

              setFieldValue(`fareValues.[${index}].tax_pkr.c_wht_per`, value);
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_wht`,
                TwoDecimalPoint(
                  (values.fareValues[index].tax_pkr?.c_sp / 100) * value
                )
              );
            }}
            label="C.WHT %"
            variant="standard"
            type={"number"}
            disabled={friend.tax_pkr.c_psf_per > 0}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_pkr.c_wht}
            id={`fareValues.[${index}].tax_pkr.c_wht`}
            onChange={(e) => {
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_wht`,
                e.target.value
              );
            }}
            label="C.WHT"
            variant="standard"
            type={"number"}
            InputProps={{
              readOnly:
                values.fareValues[index].curr_label === "PKR : RUPEES"
                  ? false
                  : true,
            }}
            disabled={friend.tax_pkr.c_psf > 0 || friend.tax_pkr.c_wht_per > 0}
          />
        </div>
      </div>
    </div>
  );
}

export default AgentPkr;
