import { useDispatch } from "react-redux";
import BtnLoader from "../AppForm/BtnLoader";
import {
  asyncDeletePaxsData,
  asyncupdateMianBookingStatus,
  cancelBooking,
  getAsyncBookingDetails,
} from "../../redux/features/booking/bookingDetailsSlice";
import { asyncupdateFlightGroupBookedSeats } from "../../redux/features/promotion/FlightGroupsSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CancelBookingPerPessenger = ({
  setMessageToggle,
  booking,
  setMessage,
  message,
  paxs,
  cancel,
  active_tab,
  auto_manual,
  booking_id,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [paxId, setPaxId] = useState([]);
  const handleCheckboxChange = (index, pax_id, isChecked) => {
    if (isChecked) {
      // Add the pax_id to the array
      setPaxId([...paxId, pax_id]);
    } else {
      // Remove the pax_id from the array
      setPaxId(paxId.filter((id) => id !== pax_id));
    }
  };
    //cancelBooking
    // const id=booking?.booking?.id
    // const gds=booking?.booking?.FlightBookingModels?.at(0)?.gds
    // console.log("hhhh",id,"  ",gds)
    // useEffect(() => {
    //   dispatch(cancelBooking({id,gds}));
    // }, []);

  return (
    <>
      <div>
        {paxs?.map((p, index) => (
          <div className="d-flex  justify-content-between">
            <p className="h6  ps-2 py-1">{p?.fName + " " + p?.lname}</p>
            <div className="checkbox-rect">
              <input
                // checked={}
                value={p?.fName}
                type="checkbox"
                id={"with_sar_" + index}
                onChange={(event) =>
                  handleCheckboxChange(index, p?.pax_id, event.target.checked)
                }
              />
              <label htmlFor={"with_sar_" + index} className="h6"></label>
            </div>
          </div>
        ))}
      </div>
      <p
        className={`h6  ps-4  ${
          cancel?.name === active_tab
            ? "booking-tab-link "
            : "bookings_details_links "
        }`}
      >
        {cancel?.name}
      </p>
      <div className="pl-2 d-flex gap-4">
        <button
          className="px-3 py-2 btn btn-danger "
          onClick={() => setMessageToggle("")}
        >
          Cancel
        </button>
        <button
          className="px-3 py-2 selectbtn_style "
          onClick={(e) => {
            if (auto_manual === 1) {
              setMessage(true);
              setTimeout(() => {
                dispatch(asyncDeletePaxsData({ id: booking_id, body: paxId }))
                  .then((res) => {
                    if (res.payload.data) {
                      navigate("/booking");
                    } else {
                      dispatch(getAsyncBookingDetails({ id: booking_id }))
                        .then(() => {})
                        .catch((err) => {
                          console.error(
                            "Error fetching updated booking details:",
                            err
                          );
                        });
                    }
                  })
                  .catch((err) => {
                    console.error("Error updating passenger:", err);
                  });
                setMessageToggle("");
                setMessage(false);
              }, 3000);
            } else {
              let id = booking?.booking?.id;
              let body = {
                book_status: "Cancelled",
              };
              setMessage(true);
              setTimeout(() => {
                dispatch(asyncupdateMianBookingStatus({ id, body }));
                let bookeSeat =
                  booking?.booking?.FlightBookingModels &&
                  booking?.booking?.FlightBookingModels[0]?.flight_group_insert
                    ?.booked_seat;
                let totalPax =
                  booking?.booking?.FlightBookingModels &&
                  booking?.booking?.FlightBookingModels[0]?.pax_tables?.length;
                dispatch(
                  asyncupdateFlightGroupBookedSeats({
                    id:
                      booking?.booking?.FlightBookingModels &&
                      booking?.booking?.FlightBookingModels[0]
                        ?.flight_group_insert?.flt_group_id,
                    body: {
                      booked_seat: bookeSeat - totalPax,
                    },
                  })
                );
                setMessageToggle("");
                setMessage(false);
              }, 3000);
            }
          }}
        >
          {message ? <BtnLoader cancelBooking={true} /> : "Confirm"}
        </button>
      </div>
    </>
  );
};
export default CancelBookingPerPessenger;
