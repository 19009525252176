import { useNavigate, useParams } from "react-router-dom";
import iata from "../../assets/images/iata.png";
// import logo from "../../../../assets/images/zairaa.jpeg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
// import { AsyncGetAllVoucher } from "../../../../redux/features/Voucher/VoucherSlice";
// import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { setChangeInvoice } from "../../redux/features/Finance/Sale/SaleReportSlice";
import { apiUrl } from "../../Utils/configUrl";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import { setOpenAgentOutStanding } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
const AgentOutStading = ({
  data,
  agentData,
  voucher,
  setChangeComponet,
  fromDate,
  toDate,
  statement,
  userData,
  allwaysCredit,
  ouStanding,
  text,
  summary,
  getAdminData,
  editVoucherPermission,
}) => {
  const dispatch = useDispatch();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);

  const componentRef = useRef();
  const firstColor = adminAgencyData?.first_color;

  console.log("data", data);
  return (
    <div className="d-flex  flex-column gap-4 bg-white">
      <div className="p-4 fs-4 no-print border border-secondry ">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginRight: "100px", marginLeft: "100px" }}
        >
          <ReactToPrint
            trigger={() => (
              <button className="bg_primary px-4 py-1 text-white">Print</button>
            )}
            content={() => componentRef.current}
          />

          <button
            onClick={(e) => dispatch(setOpenAgentOutStanding(ouStanding))}
            className="bg_primary px-4 py-1 rounded text-white"
          >
            Back
          </button>
        </div>
      </div>
      {/* bottom setion */}
      <div className="d-flex flex-column gap-3  mt-4" ref={componentRef}>
        
        <div
          className="d-flex flex-column gap-1 justify-content-center mx-4 align-items-center"
          style={{ backgroundColor: firstColor }}
        >
          <span className="fs-3 text-white fw-bold my-2">Agent Report</span>
        </div>

        <div className="d-flex flex-column justify-content-center  align-items-center mx-1  ">
          {data?.length >0 ? data?.map((agent, index) => (
            <div style={{ width: "100%" }}>
              <p className="fs-4 text-center fw-bold text-black-100">
                {agent?.title + " " + "List"}
              </p>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>AGENT NAME</th>
                    <th>CONTACT NO</th>
                    <th>EMAIL</th>
                    <th>ADDRESS</th>
                    <th>APPROVE LIMIT</th>
                    {/* <th>AVAILABLE LIMIT</th> */}
                    <th>BALANCE</th>
                  </tr>
                </thead>
                <tbody>
                  {agent?.items?.map((report, ind) => (
                    <tr key={ind}>
                      <>
                        <td>{ind + 1}</td>
                        <td>{report?.agent?.agent_name}</td>
                        <td>{report?.agent?.phone}</td>
                        <td>{report?.agent?.email}</td>
                        <td>{report?.agent?.address?.replace(/]/g, " ")}</td>
                        <td>{report?.agent?.approve_limit}</td>
                       
                        <td>{report?.total}</td>
                      </>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )):<p className="fs-5">Data Not Found</p>}
        </div>
      </div>
    </div>
  );
};

export default AgentOutStading;
