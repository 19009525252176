import React, { useEffect } from "react";
import TextSlider from "./TextSlider";
import ImageSlider from "./ImageSlider";
import Cards from "./Cards";
import Graph from "./Graph";

import Bookings from "../../assets/images/Bookings.png";
import Invoice from "../../assets/images/Invoice.png";
import Cancel from "../../assets/images/Cancel.png";
import Agents from "../../assets/images/Agents.png";
import Refund from "../../assets/images/Refund.png";
import Tickets from "../../assets/images/Tickets.png";
import Reciept from "../../assets/images/Reciept.png";
import Supplier from "../../assets/images/Suppliers.png";
import Limit from "../../assets/images/approved-limit.png";
import UsedLimit from "../../assets/images/limit-used.png";
import TotalPayment from "./TotalPayment";
import TotalSale from "../../assets/images/total-sale.png";
import Table from "react-bootstrap/esm/Table";
import { Box, Typography } from "@mui/material";

import { asyncGetDashboardData } from "../../redux/features/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import Circular from "./Circular";
import Booking from "./Booking";
import Ticket from "./Ticket";
import SupplierCount from "./SupplierCount";
import AgentCount from "./AgentCount";
import AgentLimits from "./AgentLimits";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { dashBoardData } = useSelector((state) => state?.dashboardSlice);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  useEffect(() => {
    dispatch(asyncGetDashboardData());
  }, [dispatch]);
  return (
    <div className="">
      <div className="" id="contentRow">
        <div className="w-100 px-auto pt-1 pt-md-5">
          <div style={{ position: "relative", top: 30 }}>
            <div
              className="bg-white border rounded-3 shadow-sm dashboard-wrapper h4"
              style={{ position: "absolute", top: -42, left: 200, padding: 30 }}
            >
              Booking and Summary
            </div>

            <div
              className="bg-white border col-12 col-sm-10 mx-auto rounded-3 shadow-sm dashboard-wrapper"
              // style={{ minHeight: "auto", paddingTop: 70, paddingBottom: 10 }
            >
              <div className="d-flex align-items-center mx-2 mx-sm-4 pt-3 pb-2">
                <div className="w-25"></div>
                <div className="w-75">
                  <TextSlider />
                </div>
              </div>
              <div className="px-2 px-sm-4 pb-2 d-flex flex-column flex-md-row">
                <div className="w-75">
                  <ImageSlider />
                </div>
                <div className=" border w-25  ">
                  <Circular />
                </div>
              </div>

              {user?.agent_id !== 1 ? (
                <AgentSideDashbord dashBoardData={dashBoardData} user={user} />
              ) : (
                <div className="mx-2 mx-sm-4 my-3 py-1 border-0 border-md">
                  <div className="row g-md-0">
                    <div className="col-6 col-md-3 my-2">
                      <Booking
                        bg_color="booking"
                        title="Booking"
                        img={Bookings}
                        counter={dashBoardData?.flightsCount}
                        preview={true}
                      />
                    </div>
                    <div className="col-6 col-md-3 my-2">
                      <Cards
                        bg_color="cancel"
                        title="Cancels"
                        // img={Cancel}
                        
                        counter={dashBoardData?.bookingStatus?.cancelled}
                        preview={true}
                      />
                    </div>
            
                    <div className="col-6 col-md-3 my-2">
                      <AgentCount
                        bg_color="agent"
                        title="Agents"
                        img={Agents}
                        counter={dashBoardData?.agentCompCount}
                        preview={true}
                      />
                    </div>
    
                    <div className="col-6 col-md-3 my-2">
                      <TotalPayment
                        bg_color="invoice"
                        title="Invoice (PKR)"
                        img={Invoice}
                        counter={dashBoardData?.invtotalpayment?.total[0]}
                        preview={false}
                      />
                    </div>
                  </div>

                  <div className="row g-md-0">
                    <div className="col-6 col-md-3 my-2">
                      <Ticket
                       bg_color="tickets"
                       title="Tickets"
                       img={Tickets}
                       counter={dashBoardData?.ticketsCount}
                       preview={true}  
                      />
                    </div>
                    <div className="col-6 col-md-3 my-2">
                      <Cards
                        bg_color="refund"
                        title="Refund"
                        counter={dashBoardData?.bookingStatus?.refunded}
                        preview={true}
                      />
                    </div>
                    <div className="col-6 col-md-3 my-2">
                    <SupplierCount
                      bg_color="supplier"
                      title="Suppliers"
                      img={Supplier}
                      counter={dashBoardData?.supplireCount}
                      preview={true}
                    />
                    </div>
                    <div className="col-6 col-md-3 my-2">
                      <TotalPayment
                        bg_color="reciept"
                        title="Receipt (PKR)"
                        img={Reciept}
                        counter={dashBoardData?.rvtotalpayment?.total[0]}
                        preview={false}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {user?.agent_id === 1 && (
            ""
            // <div style={{ position: "relative", top: 65, paddingBottom: 40 }}>
            //   <div
            //     className="bg-white border rounded-3 shadow-sm dashboard-wrapper h4"
            //     style={{
            //       position: "absolute",
            //       top: -42,
            //       left: 200,
            //       padding: 30,
            //     }}
            //   >
            //     Business Statistic
            //   </div>
            //   <div className="bg-white border col-12 col-sm-10 mx-auto rounded-3 shadow-sm dashboard-wrapper">
            //     <Box
            //       display={"flex"}
            //       width={6 / 8}
            //       justifyContent={"center"}
            //       pt={3}
            //       alignItems={"center"}
            //     >
            //       <Typography
            //         color={"#355A6B"}
            //         fontWeight={"bold"}
            //         fontSize={24}
            //         mr={1}
            //       >
            //         PLS:
            //       </Typography>
            //       <Typography fontSize={20}>Amount</Typography>
            //     </Box>
            //     <div
            //       className="d-flex justify-content-between"
            //       style={{ paddingTop: 20, paddingLeft: 29, paddingRight: 29 }}
            //     >
            //       <div className="col-12 col-sm-3 pe-2">
            //         <Table
            //           bordered
            //           hover
            //           className=" shadow-sm dashboard-wrapper"
            //         >
            //           <thead className="agent-heading">
            //             <tr>
            //               <th colSpan={3}>
            //                 <h4 className="text-center">Supplier Balances:</h4>
            //                 <h4 className="text-center">PKR 100,000</h4>
            //               </th>
            //             </tr>
            //           </thead>
            //           <tbody>
            //             <tr>
            //               <td></td>
            //               <td className="h6">Balance</td>
            //               <td className="h6">Payable</td>
            //             </tr>
            //             <tr>
            //               <td>IATA BSP</td>
            //               <td>PKR</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>HITII</td>
            //               <td>PKR</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>SERENE</td>
            //               <td>PKR</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>FLYJINNAH</td>
            //               <td></td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td>OTHERS</td>
            //               <td></td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td className="h6">TOTAL</td>
            //               <td className="h6">PKR</td>
            //               <td className="h6">PKR</td>
            //             </tr>
            //           </tbody>
            //         </Table>
            //       </div>
            //       <div className="col-12 col-sm-3 px-2">
            //         <Table
            //           bordered
            //           hover
            //           className="rounded-3 shadow-sm dashboard-wrapper"
            //         >
            //           <thead className="supp-theading">
            //             <tr>
            //               <th colSpan={2}>
            //                 <h4 className="text-center">Agent's Receivable:</h4>
            //                 <h4 className="text-center">PKR 100,000</h4>
            //               </th>
            //             </tr>
            //           </thead>
            //           <tbody>
            //             <tr>
            //               <td>Agents</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>Loans</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td className="h6">TOTAL</td>
            //               <td className="h6">PKR</td>
            //             </tr>
            //           </tbody>
            //         </Table>
            //       </div>
            //       <div className="col-12 col-sm-3 px-2">
            //         <Table
            //           bordered
            //           hover
            //           className="rounded-3 shadow-sm dashboard-wrapper"
            //         >
            //           <thead className="liabilities-heading">
            //             <tr>
            //               <th colSpan={2}>
            //                 <h4 className="text-center">Liabilities:</h4>
            //                 <h4 className="text-center">PKR 100,000</h4>
            //               </th>
            //             </tr>
            //           </thead>
            //           <tbody>
            //             <tr>
            //               <td>Current</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td className="h6">TOTAL</td>
            //               <td className="h6">PKR</td>
            //             </tr>
            //           </tbody>
            //         </Table>
            //       </div>
            //       <div className="col-12 col-sm-3 ps-2">
            //         <Table
            //           bordered
            //           hover
            //           className="rounded-3 shadow-sm dashboard-wrapper"
            //         >
            //           <thead className="expenses-heading">
            //             <tr>
            //               <th colSpan={2}>
            //                 <h4 className="text-center">Expenses:</h4>
            //                 <h4 className="text-center">PKR 100,000</h4>
            //               </th>
            //             </tr>
            //           </thead>
            //           <tbody>
            //             <tr>
            //               <td>Current</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td>PKR</td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td>td</td>
            //               <td></td>
            //             </tr>

            //             <tr>
            //               <td className="h6">TOTAL</td>
            //               <td className="h6">PKR</td>
            //             </tr>
            //           </tbody>
            //         </Table>
            //       </div>
            //     </div>

            //     {/* <div className="d-flex justify-content-center">
            //     <div className="border mb-4 px-5 py-4">
            //       <h4>Summary:</h4>
            //       <div className="ps-3 py-3">
            //         <h6>Total Payable: 100,000</h6>
            //         <h6>Total Received: 100,000</h6>
            //         <h6>Total Liability: 200,000</h6>
            //         <h6>Total Expenses: 100,00</h6>
            //         <h6>Total PLS: 50,000</h6>
            //       </div>
            //     </div>
            //   </div> */}
            //   </div>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AgentSideDashbord = ({ dashBoardData, user }) => {
  return (
    <div className="row mx-2 mx-sm-4 my-3 py-1 border-0 border-md">
      <div className="col-md-6 g-md-0">
        <div className="row g-md-0">
          <div className="col-6  my-2">
            <Booking
              bg_color="booking"
              title="Booking"
              img={Bookings}
              counter={dashBoardData?.flightsCount}
              preview={true}
            />
          </div>
          <div className="col-6 my-2">
            <Cards
              bg_color="cancel"
              title="Cancels"
              img={Cancel}
              counter={dashBoardData?.bookingStatus?.cancelled}
              preview={true}
            />
          </div>
          <div className="col-6 my-2">
            <Ticket
              bg_color="tickets"
              title="Tickets"
              img={Tickets}
              counter={dashBoardData?.ticketsCount}
              preview={true}
            />
          </div>
          <div className="col-6 my-2">
            <Cards
              bg_color="refund"
              title="Refund"
              img={Refund}
              counter={dashBoardData?.bookingStatus?.refunded}
              preview={true}
            />
          </div>
        </div>
      </div>

      <div className="col-md-6 g-md-0 card_container_column">
        <div className="row g-md-0">
          {user?.agent_id === 1 && (
            <>
              <div className="col-6 my-2">
                <AgentLimits
                  bg_color="#08629e"
                  title="Approved Limit"
                  img={Limit}
                  counter={dashBoardData?.agent_limits.at(0)?.credit_limit}
                  preview={true}
                />
              </div>
              <div className="col-6 my-2">
                <AgentLimits
                  bg_color="#ae6f3b"
                  title="Used Limit"
                  img={UsedLimit}
                  counter={dashBoardData?.agent_limits.at(0)?.credit_used}
                  preview={false}
                />
              </div>
            </>
          )}
          <div className="col-6 my-2">
            <TotalPayment
              bg_color="supplier"
              key = "Payment"
              title={`Total Payment (${dashBoardData?.rvtotalpayment?.curr_code})`}
              // title= "payment"
              img={TotalPayment}
              counter={dashBoardData?.rvtotalpayment?.total[0]}
              preview={true}
            />
          </div>
          <div className="col-6 my-2">
            <TotalPayment
              key="Sale"
              bg_color="reciept"
              title={`Total Sale (${dashBoardData?.invtotalpayment?.curr_code})`}
              // title= "payment"
              img={TotalSale}
              counter={dashBoardData?.invtotalpayment?.total[0]}
              preview={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

// {
//   /* <div className="col-12 col-md-6">
//                 <Graph
//                   title="Agents Sale & Refund Chart"
//                   labels={["ZAIRAA B2B", "ZAIRAA Main Off", "KPT"]}
//                   values={[0.4, 0.6, 0.8, 1.0]}
//                 />
//               </div> */
// }

// {
//   /* <div className="col-12 col-md-6 mt-2 mt-md-0">
//                 <Graph
//                   title="Supplier Sale & Refund Chart"
//                   labels={[
//                     "ZAIRAA B2B",
//                     "ZAIRAA Main Off",
//                     "KPT",
//                     "B2B",
//                     "Value",
//                     "Ticker",
//                     "Another Value",
//                   ]}
//                   values={[0, 0.4, 0.6, 0.2, 0.8, 0.6, 1.0]}
//                 />
//               </div> */
// }
