import React, { useState } from "react";
import FlyJinnahReturn from "./FlyJinnahReturn";

const FlyJinnahSegmentCard = ({
  flyjinnahItem,selectedFare,
  index,isExpanded,
  toggleDetail,
  selectedFlight,
  selOutbound,
  selInBound
}) => {
  const [showOtherOption, setShowOtherOption] = useState(false);
 
  
  return (
    <div
      className="search_engin_result_box flight_card_shadow bg-white"
      id={`flyjinnah${index}`}
    >
      <FlyJinnahReturn
        flyjinnahItem={flyjinnahItem}
         selectedFlight={selectedFlight}
         isExpanded={isExpanded}
        index={index}
        // index={"flyjinnah" + index}
        // isExpanded={expandFlightDetail === "flyjinnah" + index}
        toggleDetail={toggleDetail}
        showOtherOption={showOtherOption}
        setShowOtherOption={setShowOtherOption}
        selectedFare={selectedFare}
        selOutbound={selOutbound}
       selInBound={selInBound}
      />
    </div>
  );
};

export default FlyJinnahSegmentCard;
