import React, { useState, useEffect, useRef } from "react";

// const Timer = ({ duration }) => {
//   console.log("time",durationa);
//   const Ref = useRef(null);

//   // The state for our timer
//   const [timer, setTimer] = useState("00:00");

//   const getTimeRemaining = (e) => {
//     const total = Date.parse(e) - Date.parse(new Date());
//     const seconds = Math.floor((total / 1000) % 60);
//     const minutes = Math.floor((total / 1000 / 60) % 60);
//     const hours = Math.floor((total / 1000 / 60 / 60) % 24);
//     return {
//       total,
//       hours,
//       minutes,
//       seconds,
//     };
//   };

//   const startTimer = (e) => {
//     let { total, hours, minutes, seconds } = getTimeRemaining(e);
//     if (total >= 0) {
//       setTimer(
//         (minutes > 9 ? minutes : "0" + minutes) +
//           ":" +
//           (seconds > 9 ? seconds : "0" + seconds)
//       );
//     }
//   };

//   const clearTimer = (e) => {
//     setTimer(`${duration}:00`);

//     if (Ref.current) clearInterval(Ref.current);
//     const id = setInterval(() => {
//       startTimer(e);
//     }, 1000);
//     Ref.current = id;
//   };

//   const getDeadTime = () => {
//     let deadline = new Date();
//     deadline.setMinutes(deadline.getMinutes() + duration);
//     return deadline;
//   };

//   useEffect(() => {
//     clearTimer(getDeadTime());
//   }, [duration]);

//   const onClickReset = () => {
//     clearTimer(getDeadTime());
//   };

//   return (
//     <div>
//       <span className="color_primary fs-5 ">
//         Availability expire in: {timer}
//       </span>
//     </div>
//   );
// };

// export default Timer;




const Timer = ({ duration, availabilityTime, timer, setTimer }) => {
  const Ref = useRef(null);

  const startTimer = (deadline) => {
    const total = Math.floor((Date.parse(deadline) - Date.now()) / 1000);
    if (total >= 0) {
      const minutes = Math.floor(total / 60);
      const seconds = total % 60;
      setTimer({ minutes, seconds });
    } else {
      clearInterval(Ref.current);
    }
  };

  const clearTimer = (deadline) => {
    const total = Math.floor((Date.parse(deadline) - Date.now()) / 1000);
    const minutes = Math.floor(total / 60);
    const seconds = total % 60;
    setTimer({ minutes, seconds });

    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(deadline);
    }, 1000);
    Ref.current = id;
  };

  const getDeadline = () => {
    const deadline = new Date(availabilityTime + duration * 60 * 1000);
    return deadline;
  };

  useEffect(() => {
    if (availabilityTime) {
      clearTimer(getDeadline());
    }
    // Clean up the interval on component unmount
    return () => clearInterval(Ref.current);
  }, [availabilityTime, duration]);

  return (
    <div>
      <h6 className="color_primary fs-5">
        Availability expire in: {timer?.minutes}:{timer?.seconds < 10 ? "0" : ""}
        {timer?.seconds}
      </h6>
    </div>
  );
};

export default Timer;

