import React from "react";
import Header from '../components/Common/Header'
import SupplierComp from '../components/Setup/Umrah/VisaSupplier'
import Footer from '../components/Footer'

const UmrahVisaSupplier = () => {
  return (
    <div className=''>
      <SupplierComp />
    </div>
  );
};

export default UmrahVisaSupplier;
