import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import DeleteModal from "../../DeleteModal";
import { HTTP } from "../../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import { asyncConfirmSupBankStatus, asyncDeleteAdminVoucher, asyncGetAdminUnPaidVoucher } from "../../../redux/features/Payments/PaymentsAllSlices";
import { TextField } from "@mui/material";
import BtnLoader from "../../AppForm/BtnLoader";

const UnpaidVoucher = () => {
  const { adminUnPaidVoucher } = useSelector((state) => state.allPayments);
  const dispatch = useDispatch();
  

  const [showBankStatus, setShowBankStatus] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);

  useEffect(() => {
    dispatch(asyncGetAdminUnPaidVoucher());
  }, []);


  const columns = [
    {
      name: "pay_voucher_id",
      lable: "id",
      options: {
        display: false,
      },
    },
    {
      name: "cheque_date",
      label: "Date/Time",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "supplier.name",
      label: "Supplier",
      options: {
        display:false,
      },
    },
    // i hided the supplier name here just to get the value of it from the row data down
    {
      // name: "supplier.name",
      name: `company.compnay_name`,
      label: "Supplier | Customer",
     
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <p>{tableMeta.rowData[3] } {tableMeta.rowData[2]}</p>
            </div>
          );
        },
      },
    },
    {
      name: "total_amount",
      label: "Amount",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Status By Bank",
      name: "b_status.status_name",
      options: {
        customBodyRender: (value, tableMeta, tableData, updateValue) => {
          return (
            <button
              className="table-btn btn-primary"
              onClick={() => {
             
                setShowBankStatus(true);
                setModalData(tableMeta.rowData);
              }}
            >
              {value}
            </button>
          );
        },
      },
    },
    {
      // here is only ibft image not a deposite image
      label: "Deposit Image",
      name: "ibft_image",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
           value && <a

            target="_blank"
              // role="button"
              href={value}
              style={{ textDecoration: "none" }}
            >
              <button className="table-btn btn-success">View</button>
            </a>
          );
        },
      },
    },
    {
      label: "Delete",
      name: "bank_status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-danger edit-delete-icon-button rounded"
              onClick={() => {
                setShowDeleteModal(true);
                setModalData(tableMeta.rowData);
              }}
            >
              <i className="fa fa-thin fa-trash-can"></i>
            </button>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    enableNestedDataAccess: ".",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };

  return (
    <div className="pb-5 px-4 mt-5">
      <StatusModal
        show={showBankStatus}
        setShow={setShowBankStatus}
        modalData={modalData}
      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        rowId={modalData && modalData[3]}
        onClick={()=>{
          dispatch(asyncDeleteAdminVoucher(modalData[0]))
          setShowDeleteModal(false)
        }}
      />
      <MUIDataTable
        className="muidatatable"
        title={"Unpaid Voucher"}
        // data={data}
        data={adminUnPaidVoucher}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default UnpaidVoucher;

const StatusModal = ({ show, setShow, modalData }) => {
  const { loading} = useSelector((state) => state.allPayments);

  const [date, setdate] = useState("")
const dispatch=useDispatch()
  const handleForm=()=>{
     dispatch(asyncConfirmSupBankStatus({id:modalData[0],date}))
      setShow(false)
  }
  return (
    <Modal show={show} onHide={(e) => setShow(false)} centered>
      <Modal.Header>
        <Modal.Title>Voucher Number: PV-00000006</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
            Do you really want to confirm the payment?
          </div>
          <div className="col-12 d-flex my-3">
            <div className="col-10 mx-auto d-flex justify-content-center">
              <TextField
              type={"date"}
              value={date}
              onChange={e=>setdate(e.target.value)}
                className="date_picker mt-4"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button variant="danger modal-button" onClick={(e) => setShow(false)}>
            Close
          </Button>
          <Button
            variant="primary modal-button"
            onClick={handleForm}
          >
            {loading?<BtnLoader/>:"Confirm"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
