import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  WYCredentialsData: [],
  editWYCredentials: null,
  loading: false,
  error: null,
};

// post ER API CREDENTIAL
export const asyncPostWYApiCredentials = createAsyncThunk(
  "asyncPostWYApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/wy-credential`, values);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get ER API CREDENTIAL
export const asyncGetWYApiCredentials = createAsyncThunk(
  "asyncGetWYApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/wy-credential`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Get single ER API CREDENTIAL
export const asyncGetSingleWYApiCred = createAsyncThunk(
  "asyncGetSingleWYApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/wy-credential/${id}`);

      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// Update ER API CREDENTIAL
export const asyncUpdateWYCredential = createAsyncThunk(
  "asyncUpdateWYCredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/wy-credential/${id}`,
        values
      );
      dispatch(asyncGetWYApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Update ER API CREDENTIAL
export const asyncDeleteWYCredential = createAsyncThunk(
  "asyncDeleteWYCredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/wy-credential/${id}`);
      dispatch(asyncGetWYApiCredentials());
      return await response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const WYApiCredentialSlice = createSlice({
  name: "WYApiCredentialSlice",
  initialState,
  reducers: {
    editWYCredentialNull: (state) => {
      state.editWYCredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post ER Api Credentials
    builder.addCase(asyncPostWYApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostWYApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.WYCredentialsData = [...state.WYCredentialsData, payload];
      }
    );
    builder.addCase(
      asyncPostWYApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get ER Api Credentials
    builder.addCase(asyncGetWYApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetWYApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.WYCredentialsData = payload;
      }
    );
    builder.addCase(asyncGetWYApiCredentials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Get single ER Api Credentials
    builder.addCase(asyncGetSingleWYApiCred.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSingleWYApiCred.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editWYCredentials = payload;
    });
    builder.addCase(asyncGetSingleWYApiCred.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update ER Api Credentials
    builder.addCase(asyncUpdateWYCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateWYCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateWYCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // DElete ER Api Credentials
    builder.addCase(asyncDeleteWYCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteWYCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteWYCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { editWYCredentialNull } = WYApiCredentialSlice.actions;
export default WYApiCredentialSlice.reducer;
