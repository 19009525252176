import React, { useEffect, useState } from "react";
import {
  asyncEditAgentsData,
  editAgentsDataNull,
} from "../../../redux/features/setupRestPages/agentsSlice";
import { useDispatch } from "react-redux";
import AgentUser from "./AgentUser";
import AgentsCreditLine from "./AgentsCreditLine";
import AgencyRegistration from "../Registration/AgencyRegistration";
import { useNavigate, useParams } from "react-router-dom";
import { BiChevronLeft } from "react-icons/bi";

const EditAgents = () => {
  const [tabs, setTabs] = useState("office");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(asyncEditAgentsData(id));

    return () => dispatch(editAgentsDataNull());
  }, [id, dispatch]);

  return (
    <>
      <div className="px-5 pb-3">
        <div className="col-12 my-5 bg-white min-h-60vh">
          <div className="agents-container">
            <div className="agents-navigation-tabs">
              <div
                className={`h5  ${
                  tabs === "office" ? "active-link" : "agents_details_links"
                }`}
                onClick={() => setTabs("office")}
              >
                Office Data
              </div>
              <div
                className={`h5  ${
                  tabs === "user" ? "active-link" : "agents_details_links"
                }`}
                onClick={() => setTabs("user")}
              >
                Users
              </div>
              <div
                className={`h5  ${
                  tabs === "credit" ? "active-link" : "agents_details_links"
                }`}
                onClick={() => setTabs("credit")}
              >
                Credit Line
              </div>
            </div>
            <div className="col-11 mx-auto">
              <button
                onClick={() => navigate("/agents")}
                className="setup-btn "
              >
                <BiChevronLeft /> Back to Agent List
              </button>
            </div>

            {tabs === "office" && (
              <div className="col-12 mt-4">
                <AgencyRegistration header={false} />
              </div>
            )}
            {tabs === "user" && (
              <div className="col-12 mt-5">
                <AgentUser />
              </div>
            )}
            {tabs === "credit" && (
              <div className="col-12 mt-5">
                <AgentsCreditLine />
              </div>
            )}
            {/* {tabs === "documents" && (
          <div className="col-12 mt-5">
            <AgentDocument />
          </div>
        )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAgents;
