import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import { ErrorMessage, Form, Formik } from "formik";
import BtnLoader from "../AppForm/BtnLoader";
import {
  asyncGetAddAirlinesData,
  asyncPostAddAirlinesData,
  asyncEditAddAirlinesData,
  asyncUpdateAddAirlinesData,
  editAirlinesData_null,
  asyncGetAirlinePricing,
} from "../../redux/features/setupRestPages/Price_AirLine_SuppRole";
import { useDispatch, useSelector } from "react-redux";
import { ChangeStatus } from "../../Utils/ChangeStatus";

const Airlines = () => {
  const [validValue, setValidValue] = useState(false);

  const dispatch = useDispatch();
  // use selectors redux
  const { addAirLinesData, loading, editAirlinesData, airlinePricing } =
    useSelector((state) => state?.price_airlines_suppRoleSlice);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);

  // Check if the login user has permission to add airline
  const addAirlinePermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/airline-post"
      )
    : true;

  // Check if the login user has permission to edit airline
  const editAirlinePermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/airline-update"
      )
    : true;

  //  useEffect call
  useEffect(() => {
    dispatch(asyncGetAddAirlinesData());
  }, [dispatch, editAirlinesData]);

  useEffect(() => {
    dispatch(asyncGetAirlinePricing());
  }, [dispatch]);

  // pricing airline
  const pricingAirline = airlinePricing?.find(
    (airline) => airline?.arr_id === editAirlinesData?.arr_id
  );
  // Table Column Definition
  const columns = [
    {
      name: "arr_id",
      label: "ID",
      options: {
        display: false,
      },
    },
    {
      name: "arr_status",
      label: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div class="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/airline-status",
                      !value,
                      id,
                      "status"
                    );
                  }}
                />
                <label for={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "code_alpha",
      label: "Code",
    },
    {
      name: "code_tkt",
      label: "TKT Code",
    },
    {
      name: "com_intl",
      label: "COMM Code INTL",
    },
    {
      name: "com_dom",
      label: "COMM Code DOMM",
    },
    {
      name: "soto",
      label: "SOTO",
    },
    {
      name: "tour_code",
      label: "Tour Code",
    },
    {
      name: "wht",
      label: "Supplier WHT",
    },
    {
      label: "Action",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <button
              className="btn-success rounded edit-delete-icon-button"
              disabled={!editAirlinePermission && user?.user_id !== 1}
              style={{
                opacity:
                  !editAirlinePermission && user?.user_id !== 1 ? 0.5 : 1,
              }}
              onClick={() => {
                window.scroll({ top: 0 });
                dispatch(asyncEditAddAirlinesData(id));
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  // Table Options
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    sortOrder: {
      name: "name",
      direction: "asc",
    },
  };

  // Yup Validation
  const validationSchema = yup.object().shape({
    airline_name: yup.string().required("Airline Name Required"),
    com_intl: yup.number().required("COMM Code International Required"),
    com_dom: yup.number().required("COMM Code Domestic Required"),
    // supplier_wht: yup.number().required("Supplier WHT Required"),
    code: yup.string().required("Code Required"),
    code_tkt: yup.number().required("Ticketing Code Required"),
    // tour_code: yup.string().required("Tour Code Required"),
    soto: yup.number().required("SOTO Required"),
  });

  // Initial values formik
  const initialValues = {
    airline_name: editAirlinesData?.name || "",
    com_intl: editAirlinesData?.com_intl || 0,
    com_dom: editAirlinesData?.com_dom || 0,
    supplier_wht: editAirlinesData?.wht || 0,
    code: editAirlinesData?.code_alpha || "",
    code_tkt: editAirlinesData?.code_tkt || 0,
    tour_code: editAirlinesData?.tour_code || "",
    soto: editAirlinesData?.soto || 0,
    flag: editAirlinesData?.flag || "",
  };
  return (
    <div className="mx-5 rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading box_title">Add Airline</p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          const name = values?.airline_name;
          const com_intl = values?.com_intl;
          const com_dom = values?.com_dom;
          const wht = values?.supplier_wht;
          const code_alpha = values?.code;
          const code_tkt = values?.code_tkt;
          const tour_code = values?.tour_code;
          const soto = values?.soto;
          const status = values?.flag;

          const addairlineobj = {
            name,
            com_intl,
            com_dom,
            wht,
            code_alpha,
            code_tkt,
            tour_code,
            soto,
          };

          if (editAirlinesData) {
            dispatch(
              asyncUpdateAddAirlinesData({
                arr_id: editAirlinesData?.arr_id,
                name: name,
                com_intl: com_intl,
                code_alpha: code_alpha,
                code_tkt: code_tkt,
                tour_code: tour_code,
                status: status,
                soto: soto,
                com_dom: com_dom,
                wht: wht,
              })
            );
            dispatch(dispatch(editAirlinesData_null()));
          } else {
            dispatch(asyncPostAddAirlinesData(addairlineobj));
            resetForm({});
          }
        }}
      >
        {({ values, setFieldValue, handleChange }) => {
          return (
            <Form>
              <div className="col-12 px-4">
                <div className="row my-5 gap-2">
                  <div className="col-sm-2">
                    <TextField
                      name="airline_name"
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue("airline_name", value.toUpperCase());
                      }}
                      label="Airline Name"
                      autoComplete="off"
                      className="w-100"
                      variant="standard"
                      inputProps={{ maxLength: 50 }}
                      value={values?.airline_name}
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="airline_name"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      className="w-100"
                      name="com_intl"
                      value={values?.com_intl}
                      onChange={handleChange}
                      type="number"
                      label="Commission Code (Internationl)"
                      variant="standard"
                      autoComplete="off"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 2))
                      }
                      inputMode="numeric"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="com_intl"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      name="com_dom"
                      value={values?.com_dom}
                      onChange={(e) => {
                        let value = e.target.value;
                        setFieldValue("com_dom", value);
                      }}
                      autoComplete="off"
                      className="w-100"
                      label="Commission Code (Domestic)"
                      type="number"
                      variant="standard"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 2))
                      }
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="com_dom"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      name="soto"
                      value={values?.soto}
                      onChange={handleChange}
                      autoComplete="off"
                      type="number"
                      className="w-100"
                      label="SOTO"
                      variant="standard"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 2))
                      }
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="soto"
                    />
                  </div>
                </div>
                <div className="row gap-2">
                  <div className="col-sm-2">
                    <TextField
                      name="code"
                      value={values?.code}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        let find = addAirLinesData?.find(
                          (it) => it?.code_alpha === value.toUpperCase()
                        );
                        find ? setValidValue(true) : setValidValue(false);
                        setFieldValue("code", value.toUpperCase());
                      }}
                      label="Code *"
                      autoComplete="off"
                      className="w-100"
                      variant="standard"
                      inputProps={{ maxLength: 2 }}
                      // helperText={
                      //   validValue ? (
                      //     <span className="text-danger">
                      //       This Code is Already Created
                      //     </span>
                      //   ) : (
                      //     ""
                      //   )
                      // }
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="code"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      name="code_tkt"
                      value={values?.code_tkt}
                      onChange={handleChange}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 3))
                      }
                      autoComplete="off"
                      type="number"
                      className="w-100"
                      label="Ticketing Code *"
                      variant="standard"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="code_tkt"
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      name="tour_code"
                      value={values?.tour_code}
                      onChange={(e) => {
                        const value = e.target.value || "";
                        setFieldValue("tour_code", value.toUpperCase());
                      }}
                      autoComplete="off"
                      className="w-100"
                      label="Tour Code"
                      variant="standard"
                      inputProps={{ maxLength: 15 }}
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      name="supplier_wht"
                      value={values?.supplier_wht}
                      onChange={handleChange}
                      autoComplete="off"
                      className="w-100"
                      label="Supplier WHT"
                      type="number"
                      variant="standard"
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 2))
                      }
                      InputProps={{
                        readOnly:
                          values.com_dom === 0 &&
                          values.com_intl === 0 &&
                          values.soto === 0,
                      }}
                    />
                    {/* <ErrorMessage
                      component="div"
                      className="text-danger mt-1"
                      name="supplier_wht"
                    /> */}
                  </div>
                </div>
                <div className="text-center my-5">
                  {editAirlinesData && (
                    <button
                      className="setup-btn btn setup-btn me-2"
                      onClick={(e) => {
                        dispatch(dispatch(editAirlinesData_null()));
                      }}
                      style={{ background: "#dc3545", color: "#fff" }}
                    >
                      Cancel
                    </button>
                  )}
                  {editAirlinesData ? (
                    <button type="submit" className="btn setup-btn">
                      {loading ? <BtnLoader /> : "Update"}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn setup-btn"
                      disabled={!addAirlinePermission && user?.user_id !== 1}
                    >
                      {loading ? <BtnLoader /> : "Save"}
                    </button>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="col-12 mt-2 px-4">
        <MUIDataTable
          className="muidatatable add_airline_table "
          title={"Airline Details"}
          data={addAirLinesData}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};
export default Airlines;
