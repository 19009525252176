import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { NODEURL } from "../ApiConfig"


const initialState= {
    status: [],
    isLoading: false,
    error: null
}





  export const getAllStatus = createAsyncThunk(
    "getAllStatus/get",
    async (_, { rejectWithValue }) => {
      try {
        const response = await NODEURL.get("/api/v1/b2b/book-status")
  
        return await response?.data?.data
      } catch (error) {
        rejectWithValue(error)
      }
    }
  )


export const bookingStatus = createSlice({
    name: "bookingStatus",
    initialState,
    reducers: { },
    extraReducers: builder => {
        builder.addCase(getAllStatus.pending, (state, { payload }) => {
            state.isLoading = true
            state.error = null
          })
          builder.addCase(getAllStatus.fulfilled, (state, { payload }) => {
            state.isLoading = false
            state.error = null
            state.status = payload
          })
          builder.addCase(getAllStatus.rejected, (state,action ) => {
            state.isLoading = false
            state.error = action.payload
          })


         
    }
})

export default bookingStatus.reducer