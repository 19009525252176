import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { setNewDepDate } from "../../../../redux/features/home/FlightSearchSlice";

function ChangeAvailabilityDate({ dates, index, segmentType }) {
  const dispatch = useDispatch();

  const localsearchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  const updateDateInLocalStorage = (newDate) => {
    if (segmentType === "multi_des_from") {
      localsearchValues.multi_des_from_date[index] = newDate;
    } else if (segmentType === "single_segment") {
      localsearchValues.departingOn = newDate;
    }
    localStorage.setItem("flightSearchData", JSON.stringify(localsearchValues));
  };

  const handleDateChange = (days) => {
    let dateToUpdate = dates; // Only use the dates passed as prop
    let parts = dateToUpdate.split("-");
    let day = parseInt(parts[0], 10);
    let month = parseInt(parts[1], 10) - 1;
    let year = parseInt(parts[2], 10);

    let newDate = new Date(year, month, day);
    newDate.setDate(newDate.getDate() + days);
    const formattedDate = moment(newDate).format("DD-MM-YYYY");

    // Update localStorage and Redux for the specific segment
    updateDateInLocalStorage(formattedDate);

    // Optionally dispatch to Redux if necessary, or omit this if Redux state is not needed
    dispatch(setNewDepDate(formattedDate));
  };

  return (
    <div>
      <button
        className="button_style fs-6 me-3"
        onClick={() => handleDateChange(-1)}
      >
        Previous Date
      </button>
      <button
        className="button_style fs-6"
        onClick={() => handleDateChange(1)}
      >
        Next Date
      </button>
    </div>
  );
}

export default ChangeAvailabilityDate;
