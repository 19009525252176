import React from "react";
import VisaSummary from "../Summary/VisaSummary";
import { Form, Formik } from "formik";
import PaxDetail from "./PaxDetail";
import { useSelector } from "react-redux";

function Visa({ initValues, permission }) {
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { visaType } = useSelector((state) => state?.visaType);
  const pkr = currencyData?.find((it) => it.curr_code === "PKR");
  const availableLimit =
    initValues?.agent?.credit_limit - initValues?.agent?.credit_used;

  const visaArray = visaType?.filter(
    (it) => it.country_id === initValues.visa_country
  );
  console.log(visaArray);
  const initialValues = {
    ...initValues,
    totalAgent: 0,
    paxDetail: [
      {
        pax_type: "ADULT",
        f_name: "",
        l_name: "",
        dob: "",
        nationality: "",
        passport_no: "",
        currency: pkr,
        cur_label: "PKR",
        roe: 1.0,
        curr_pay: 0,
        curr_rec: 0,
        pkr_pay: 0,
        pkr_rec: 0,
      },
    ],
  };
  return (
    <Formik
      // validateOnChange={false}
      // validateOnBlur={false}
      // validationSchema={validationSchem}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, action) => {
        // console.log(values)
      }}
    >
      {({ values, setFieldValue, resetForm }) => {
        return (
          <Form>
            <div className="">
              <div className="section_box row py-4">
                <h4>Visa</h4>
                <div className="black_border rounded col-4 py-3 tax_pf">
                  {!initValues.visa_country ? (
                    <p className="box_title">Please Select visa country.</p>
                  ) : visaArray?.length ? (
                    visaArray?.map((item) => (
                      <div className="py-2" key={item.id}>
                        <label class="form-control">
                          <input type="radio" name="radio" />
                          <span className="fs-6">{item.visa_type}</span>
                        </label>
                      </div>
                    ))
                  ) : (
                    <p className="box_title text-danger">
                      Visa is not added for the selected visa country.
                    </p>
                  )}
                </div>
                <div className="col-8">
                  <PaxDetail setFieldValue={setFieldValue} values={values} />
                </div>
              </div>
              <VisaSummary values={values} setFieldValue={setFieldValue} />
              {/* Submit */}
              <div className="my-4 d-flex justify-content-center align-item-center">
                <button
                  type="button"
                  className="button_style px-5 me-3"
                  onClick={() => resetForm()}
                  disabled={!permission}
                >
                  Clear Form
                </button>
                <button
                  type="submit"
                  className="button_style px-5"
                  disabled={
                    Number(availableLimit) < Number(values.totalAgent) ||
                    !permission
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Visa;
