import * as React from "react";
import { Form, Formik } from "formik";
import PaxDetail from "./PaxDetail";
import GeneralInfo from "./GeneralInfo";
import GeneralSummary from "../Summary/GeneralSummary";

function General({ initValues, permission }) {
  const availableLimit =
    initValues?.agent?.credit_limit - initValues?.agent?.credit_used;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initValues,
        title: "",
        f_name: "",
        l_name: "",
        dob: "",
        description: "",
        currency: "",
        cur_label: "PKR",
        roe: 1.0,
        curr_pay: 0,
        curr_rec: 0,
        pkr_pay: 0,
        pkr_rec: 0,
      }}
      onSubmit={(values, action) => {
        // console.log(values)
      }}
    >
      {({ values, setFieldValue, resetForm }) => {
        return (
          <Form>
            <div>
              <div>
                <PaxDetail values={values} setFieldValue={setFieldValue} />
              </div>

              <div>
                <GeneralInfo values={values} setFieldValue={setFieldValue} />
              </div>

              <div>
                <GeneralSummary values={values} setFieldValue={setFieldValue} />
              </div>
            </div>
            <div className="my-4 d-flex justify-content-center align-item-center">
              <button
                type="button"
                className="button_style px-5 me-3"
                onClick={() => resetForm()}
                disabled={!permission}
              >
                Clear Form
              </button>
              <button
                type="submit"
                className="button_style px-5"
                disabled={availableLimit > values.agent_total || !permission}
              >
                Submit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default General;
