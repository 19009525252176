import { useEffect } from "react";
import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAllGDS,
  asyncUpdateAllGDS,
  messageReducerNull,
} from "../../../redux/features/setupFeature/ApiCredential/ApiCredentials";
import { TextField } from "@mui/material";
import BtnLoader from "../../AppForm/BtnLoader";
import * as yup from "yup";

const GDSOrder = () => {
  const dispatch = useDispatch();

  const { GDSData, loading, message } = useSelector(
    (state) => state?.apiCredentials
  );

  useEffect(() => {
    dispatch(asyncGetAllGDS());
  }, [dispatch, message]);

  // Initial values
  const initialValues = {
    data:
      GDSData?.map((it) => ({
        id: it?.id,
        gds_order: it?.gds_order,
      })) || [],
  };

  // Validation schema
  const validationSchema = yup.object().shape({
    data: yup.array().of(
      yup.object().shape({
        gds_order: yup.number().required("Order required"),
      })
    ),
  });

  if (loading) {
    return <p>Loading data...</p>;
  }

  return (
    <div className="shadow-sm border rounded-2 h-100 px-3 py-2">
      <h5 className="text-center mt-2">GDS Order</h5>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          // Create an array with updated gds_order values
          const updatedData = values.data.map((item) => ({
            id: item.id,
            gds_order: item.gds_order,
          }));

          // Dispatch the action to update data
          dispatch(asyncUpdateAllGDS(updatedData));

          // Set a timeout to reset the message after 3 seconds
          setTimeout(() => {
            dispatch(messageReducerNull());
          }, 3000);

          // Important: Reset the form after successful submission
          resetForm({});
        }}
      >
        {({ values, setFieldValue, errors }) => (
          <Form>
            {values?.data
              ?.sort((a, b) => a.gds_order - b.gds_order)
              ?.map((it, index) => {
                const gds = GDSData?.find((gd) => gd.id === it.id);
                return (
                  <div
                    className="d-flex align-items-center border-bottom pb-2 my-3"
                    key={index}
                  >
                    <div className="col-6 fs-6">{gds?.gds_name}</div>
                    <div>
                      <TextField
                        label=""
                        variant="standard"
                        autoComplete="off"
                        type="number"
                        sx={{ maxWidth: "4rem" }}
                        name={`data[${index}].gds_order`}
                        value={it.gds_order}
                        onChange={(e) =>
                          setFieldValue(
                            `data[${index}].gds_order`,
                            e.target.value
                          )
                        }
                      />
                      {errors?.data?.[index]?.gds_order && (
                        <div className="mt-1 text-danger">
                          {errors.data[index].gds_order}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            {message && (
              <p className={`text-${message?.status}`}>{message?.message}</p>
            )}
            <div className="py-3 text-center">
              <button className="button_style py-2" type="submit">
                {loading ? <BtnLoader /> : "Update"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GDSOrder;
