import { Skeleton } from "@mui/material";
import React from "react";

const BrandFareSkeleton = () => {
  return (
    <div className="">
      <div className="shadow">
        <div className="row align-items-center ms-2 me-2 pt-3">
          <div className="col-3">
            <div>
              <Skeleton width={"120px"} height={40} />
            </div>
            <div className="mt-2">
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
            </div>
            <div>
              <Skeleton width={"120px"} height={70} />
            </div>
          </div>

          <div className="col-3">
            <div>
              <Skeleton width={"120px"} height={40} />
            </div>
            <div className="mt-2">
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
            </div>
            <div>
              <Skeleton width={"120px"} height={60} />
            </div>
          </div>

          <div className="col-3">
            <div>
              <Skeleton width={"120px"} height={40} />
            </div>
            <div className="mt-2">
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
            </div>
            <div>
              <Skeleton width={"120px"} height={60} />
            </div>
          </div>

          <div className="col-3">
            <div>
              <Skeleton width={"120px"} height={40} />
            </div>
            <div className="mt-2">
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
              <Skeleton width={"300px"} height={25}/>
            </div>
            <div>
              <Skeleton width={"120px"} height={60} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandFareSkeleton;
