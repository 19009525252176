import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import TotalSection from "../Summary/InsuranceSumm";
import ReactDatePicker from "react-datepicker";
import { dropMenuProp } from "../../../../../Utils/MenuProps";
import { pax_title, plan_days, plan_type } from "../helper";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

function Insurance({ initValues, permission }) {
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);

  const pkr = currencyData?.find((it) => it.curr_code === "PKR");

  const availableLimit =
    initValues?.agent?.credit_limit - initValues?.agent?.credit_used;

  const initialValues = {
    ...initValues,
    nationality: "Pakistan",
    plan_days: "",
    plan_type: "",
    from_date: new Date(),
    currency: pkr,
    cur_label: "PKR",
    roe: 1,
    curr_pay: 0,
    curr_rec: 0,
    pkr_pay: 0,
    pkr_rec: 0,
    title: "",
    f_name: "",
    l_name: "",
  };

  const submitInsurance = (values) => {
    console.log("kkkk", values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => submitInsurance(values)}
    >
      {({ values, handleChange, setFieldValue, resetForm }) => {
        const isPkr = values.cur_label === "PKR" ? true : false;
        return (
          <Form>
            <div className="pb-3">
              <div className="section_box">
                <div className="h4">Insurance Particulars</div>
                {/* insurance particulars   */}
                <div className="row py-3 align-items-end">
                  <div className="col-6 ">
                    <div className="black_border rounded p-2">
                      <div className="row align-items-end py-3 pb-4">
                        <div className="col-3 ">
                          <CountryDropdown
                            id="UNIQUE_ID"
                            className="inputs w-100 absolute"
                            preferredCountries={["pk"]}
                            value={values.nationality}
                            handleChange={(e) =>
                              setFieldValue("nationality", e.target.value)
                            }
                          ></CountryDropdown>
                        </div>
                        <div className="col-2">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id={`plan_days`}>Plan Days</InputLabel>
                            <Select
                              labelId={`plan_days`}
                              id={`plan_days`}
                              value={values.plan_days}
                              onChange={handleChange}
                              label="Plan Type"
                              MenuProps={dropMenuProp}
                              required
                            >
                              {plan_days?.map((it) => (
                                <MenuItem value={it} key={it}>
                                  {it}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-2">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id={`plan_type`}>Plan Type</InputLabel>
                            <Select
                              labelId={`plan_type`}
                              id={`plan_type`}
                              value={values.plan_type}
                              onChange={handleChange}
                              label="Plan Type"
                              MenuProps={dropMenuProp}
                              required
                            >
                              {plan_type?.map((it) => (
                                <MenuItem value={it} key={it}>
                                  {it}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-3">
                          <ReactDatePicker
                            placeholderText="Travel Date"
                            selected={values.from_date}
                            onChange={(date) => {
                              setFieldValue("from_date", date);
                            }}
                            minDate={new Date()}
                            className="date_picker"
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="black_border rounded p-2">
                      <div className="row align-items-start py-3">
                        <div className="col-2">
                          <FormControl
                            variant="standard"
                            className="w-100"
                            required
                          >
                            <InputLabel id={`cur_label`}>Currency</InputLabel>
                            <Select
                              labelId={`cur_label`}
                              id={`cur_label`}
                              value={values.cur_label}
                              onChange={(e) => {
                                let value = e.target.value;
                                let currency = currencyData?.find(
                                  (it) => it.curr_code === value
                                );
                                setFieldValue(`cur_label`, value);
                                setFieldValue("currency", currency);
                                setFieldValue("roe", currency?.roe);

                                // reset this field
                                setFieldValue("curr_pay", 0);
                                setFieldValue("curr_rec", 0);
                                setFieldValue("pkr_pay", 0);
                                setFieldValue("pkr_rec", 0);
                              }}
                              label="Currency"
                              MenuProps={dropMenuProp}
                              required
                            >
                              {currencyData?.map((it, ind) => (
                                <MenuItem value={it.curr_code} key={ind}>
                                  {it.curr_code}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-2">
                          <TextField
                            onFocus={(e) => e.target.select()}
                            label="ROE"
                            variant="standard"
                            type="number"
                            className="w-100"
                            value={values.roe}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("roe", value);
                            }}
                          />
                        </div>
                        <div className="col-2">
                          <TextField
                            onFocus={(e) => e.target.select()}
                            label={`${isPkr ? "Curr" : values.cur_label} PAY`}
                            variant="standard"
                            className="w-100"
                            type="number"
                            value={values.curr_pay}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("curr_pay", value);
                              setFieldValue("pkr_pay", value * values.roe);
                            }}
                            disabled={isPkr}
                          />
                        </div>
                        <div className="col-2">
                          <TextField
                            onFocus={(e) => e.target.select()}
                            label={`${isPkr ? "Curr" : values.cur_label} REC`}
                            variant="standard"
                            className="w-100"
                            type="number"
                            value={values.curr_rec}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("curr_rec", value);
                              setFieldValue("pkr_rec", value * values.roe);
                            }}
                            disabled={isPkr}
                          />
                        </div>
                        <div className="col-2">
                          <TextField
                            onFocus={(e) => e.target.select()}
                            label="PKR PAY"
                            variant="standard"
                            className="w-100"
                            type="number"
                            value={values.pkr_pay}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("pkr_pay", value);
                            }}
                          />
                        </div>
                        <div className="col-2">
                          <TextField
                            onFocus={(e) => e.target.select()}
                            label="PKR REC"
                            variant="standard"
                            className="w-100"
                            type="number"
                            value={values.pkr_rec}
                            onChange={(e) => {
                              let value = e.target.value;
                              setFieldValue("pkr_rec", value);
                            }}
                          />
                          {values.agent && values.agent_name !== "" && (
                            <div className="text-primary fs-6 mt-3">
                              {CurrencyCode()}{" "}
                              {TwoDecimalPoint(
                                CurrencyExchange(values.pkr_rec)
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section_box w-50">
                <div className="h4">Insurer</div>
                {/* insurance particulars   */}
                <div className="pb-4">
                  <div className="row  align-items-end">
                    <div className="col-3">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id={`title`}>Title</InputLabel>
                        <Select
                          labelId={`title`}
                          id={`title`}
                          value={values.title}
                          onChange={(e) => {
                            setFieldValue(`title`, e.target.value);
                          }}
                          label="Title"
                          MenuProps={dropMenuProp}
                          required
                        >
                          {pax_title?.map((it) => (
                            <MenuItem value={it} key={it}>
                              {it}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-3 ">
                      <TextField
                        id="f_name"
                        label="F-Name"
                        variant="standard"
                        className="w-100"
                        value={values.f_name}
                        onChange={(e) => {
                          setFieldValue("f_name", e.target.value.toUpperCase());
                        }}
                      />
                    </div>
                    <div className="col-3 ">
                      <TextField
                        id="l_name"
                        label="L-Name"
                        variant="standard"
                        className="w-100"
                        value={values.l_name}
                        onChange={(e) => {
                          setFieldValue("l_name", e.target.value.toUpperCase());
                        }}
                      />
                    </div>
                    <div className="col-3 ">
                      <TextField
                        id="passport_no"
                        label="Passport No"
                        variant="standard"
                        className="w-100"
                        value={values.passport_no}
                        onChange={(e) => {
                          setFieldValue(
                            "passport_no",
                            e.target.value.toUpperCase()
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Total Summary Section */}
              <TotalSection values={values} isPkr={isPkr} />
              {/* Submit */}
              <div className="my-4 d-flex justify-content-center align-item-center">
                <button
                  type="button"
                  className="button_style px-5 me-3"
                  onClick={() => resetForm()}
                  disabled={!permission}
                >
                  Clear Form
                </button>
                <button
                  type="submit"
                  className="button_style px-5"
                  disabled={
                    Number(availableLimit) < Number(values.pkr_rec) ||
                    !permission
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Insurance;
