import React, { useEffect } from "react";
import MUIDataTable from "mui-datatables";
import {
  asyncEditAgentgroup,
  asyncGetAgentGroup,
} from "../../redux/features/setupRestPages/agentsSlice";
import { useDispatch, useSelector } from "react-redux";
import AgentGroupSubPage from "./AgentGroupSubPage";
import { options } from "../../Utils/ColumnOptions";
import { ChangeStatus } from "../../Utils/ChangeStatus";

const AgentGroups = () => {
  const dispatch = useDispatch();

  const { agentGroup } = useSelector((state) => state.agentsCategories);

  useEffect(() => {
    dispatch(asyncGetAgentGroup());
  }, [dispatch]);

  const columns = [
    {
      label: "id",
      name: "agent_grp_id",
      options: {
        display: false,
      },
    },
    {
      label: "Currency Code",
      name: "currency.curr_code",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div class="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/agent-group-status",
                      !value,
                      id,
                      "status"
                    );
                  }}
                />
                <label for={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Name",
      name: "agent_grp_name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Currency",
      name: "currency.curr_name",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              {tableMeta.rowData[1]} : {value}
            </div>
          );
        },
      },
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  dispatch(asyncEditAgentgroup(tableMeta.rowData[0]));
                  window.scroll({ top: 0 });
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div>
      <div className="col-12 col-sm-8 mx-auto rounded-2 shadow-sm mt-5 flash-news pb-2 ">
        <div className="mb-5">
          <p className="h4 otawix-heading box_title">Agent Group</p>
          <div className="col-12 px-4 d-flex justify-content-end my-4">
            {/* the form is here  */}
            <AgentGroupSubPage />
          </div>
          <div className="col-12 mt-2 px-4">
            <MUIDataTable
              className="muidatatable"
              title={"Agent Group Details"}
              data={agentGroup}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentGroups;
