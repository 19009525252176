import React, { useEffect, useState } from "react";
import "../Styles/manualInvoices.css";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../components/CustomDrp";
import { asyncGetNewSupplier } from "../redux/features/supplier/AddNewSupplier";
import { useDispatch, useSelector } from "react-redux";
import {
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import SupplierStatmentPdf from "../components/Pdf/SupplierStatmentPdf";
import { BlobProvider } from "@react-pdf/renderer";
import { type } from "@testing-library/user-event/dist/type";
import { AsyncGetAllVoucher } from "../redux/features/Voucher/VoucherSlice";
import SupplierStatement from "./SupplierStatement";
import moment from "moment";
import { AsyncCreateAgentStatment } from "../redux/features/Statement/StatementSlice";
import BtnLoader from "../components/AppForm/BtnLoader";
import { asyncGetAdmin } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

function SupplierAccountStatement() {
  const dispatch = useDispatch();
  const [first, setfirst] = useState("");

  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const { agentStatement } = useSelector((state) => state.Statement);

  const [agent, setAgent] = useState(
    userData?.agent_id === 1 ? "" : userData?.agents_comp?.agent_name
  );
  const [agentAccId, setAgentAccId] = useState(
    userData?.agent_id === 1 ? "" : userData?.agents_comp?.acc_id
  );

  const { searchAgency, getAdminData } = useSelector(
    (state) => state.newAgency
  );
  const { allVoucher } = useSelector((state) => state?.voucherEntries);

  const [voucher, setVoucher] = useState({
    salin: 4,
    vch: [],
    curr: userData?.agent_id === 1 ? "PKR" : "other",
    cn: "",
    op: "",
  });
  const [agentData, setAgentData] = useState({});
  useEffect(() => {
    let value = agent;
    dispatch(AsyncGetAllVoucher());
    // if (userData?.agent_id !== 1) {
    //   dispatch(asyncSearchAgencyData(value))
    //     .then((res) => {
    //       setAgentData(res?.payload?.at(0));
    //     })
    //     .catch((err) => console.log(err));
    // }
    dispatch(asyncGetAdmin({ id: 1 }));
  }, [dispatch]);

  //////////////////////////////////////////////

  const [changeComponent, setChangeComponet] = useState("search");
  const { newSupplier } = useSelector((state) => state.supplier);

  useEffect(() => {
    dispatch(asyncGetNewSupplier());
  }, []);

  const suppArray = newSupplier?.map((sup) => sup?.supp_name);

  const [drp, setdrp] = useState(false);
  const [supplierName, setSupplierName] = useState([]);
  const [supValue, setSupValue] = useState("");

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [service, setService] = useState("");
  const arry = [
    { id: 0, type: "select All" },
    { id: 1, type: "Flight" },
    { id: 2, type: "Visa" },
    { id: 3, type: "Hotel" },
    { id: 4, type: "Insurance" },
    { id: 5, type: "Transfer" },
    { id: 6, type: "Activity" },
    { id: 7, type: "Umrah" },
  ];
  const [supplierData, setSupplierData] = useState();

  const data = allVoucher?.data?.data
    ?.map((item) => {
      const { acc_voucher_trans, ...restbody } = item;
      const trans = acc_voucher_trans?.find(
        (acc) => acc?.acc_id === supplierData?.acc_id
      );

      // Include trans in the returned object if it exists
      if (trans) {
        return {
          ...restbody,
          trans,
        };
      }

      return null; // Filter out items without the correct trans in the filter step
    })
    ?.filter((item) => {
      if (!item) return false; // Filter out null items

      const saveDate = new Date(
        moment(item?.saved_date?.split("/")?.at(0)).format("YYYY-MM-DD")
      );

      const isSalinMatch =
        !voucher.salin || item?.voucher_type_id === voucher.salin;

      const isVchMatch =
        !voucher?.vch?.length || voucher?.vch.includes(item?.voucher_type_id);
      const isCreditNoteMatch =
        !voucher.cn || item?.voucher_type_id === voucher.cn;
      // Adjusted date range logic
      const fromDateAdjusted = new Date(moment(fromDate).format("YYYY-MM-DD"));
      const toDateAdjusted = new Date(moment(toDate).format("YYYY-MM-DD"));

      const isDateRangeMatch =
        saveDate >= fromDateAdjusted && saveDate <= toDateAdjusted;

      // Handling the case when both "Sale Invoice" and "Vouchers" checkboxes are checked

      // If both voucher types are selected, the item should match either salin or vch criteria
      const isVoucherTypeMatch =
        (voucher.salin && isSalinMatch) ||
        (voucher.cn && isCreditNoteMatch) ||
        (voucher.vch?.length && isVchMatch);

      return isVoucherTypeMatch && isDateRangeMatch;
    });

  const earliestDate = allVoucher?.data?.data
    ?.map(
      (item) =>
        new Date(
          moment(item?.saved_date?.split("/")?.at(0)).format("YYYY-MM-DD")
        )
    )
    ?.reduce(
      (minDate, currentDate) => (currentDate < minDate ? currentDate : minDate),
      new Date()
    );
  // Adjust the fromDate based on voucher.op
  const isOpChecked = voucher?.op;
  const adjustedFromDate = isOpChecked
    ? new Date(moment(fromDate).subtract(1, "days").format("YYYY-MM-DD"))
    : new Date(moment(fromDate).format("YYYY-MM-DD"));

  // Filter the data to include items within the date range from earliestDate to adjustedFromDate
  const filteredData = allVoucher?.data?.data
    ?.map((item, index) => {
      const { acc_voucher_trans, ...restbody } = item;
      const trans = acc_voucher_trans?.find(
        (acc) => acc?.acc_id === supplierData?.acc_id
      );

      // Include trans in the returned object if it exists
      if (trans) {
        return {
          ...restbody,
          trans,
        };
      }

      return null;
    })
    .filter((item) => {
      if (!item) return false; // Filter out null items
      const saveDate = new Date(
        moment(item?.saved_date?.split("/")?.at(0)).format("YYYY-MM-DD")
      );
      return saveDate >= earliestDate && saveDate <= adjustedFromDate;
    });

  const totalCredit = filteredData?.reduce(
    (sum, item) => sum + (~~item?.trans?.d_cur_credit || 0),
    0
  );
  const totalDebit = filteredData?.reduce(
    (sum, item) => sum + (~~item?.trans?.d_cur_debit || 0),
    0
  );
  const total = totalDebit - totalCredit;
  const isVoucherNotEmpty = () => {
    return (
      voucher.salin !== "" ||
      (voucher.vch && voucher.vch.length > 0) ||
      voucher.cn !== "" ||
      voucher.op !== ""
    );
  };
  const hasVoucherData = isVoucherNotEmpty();
  const [message, setMessage] = useState(true);
const { userPermission } = useSelector((state) => state?.roleAndPermissions);
const editVoucherPermission = ![1, 6, 7].includes(userData.role_id)
  ? userPermission?.find(
      (it) =>
        it?.user_id === userData?.user_id &&
        it?.page_level?.permission_url === "/voucher-edit"
    )
  : true;
  return (
    <div className="">
      {changeComponent === "search" && (
        <div className="manual_invoices mx-auto mt-5">
          <div className="manual_invoices_header">
            <div className="w-100 text-center">Supplier Account Statement</div>
          </div>
          <div className="px-2 px-md-5">
            <div className=" pt-5">
              <div className="row align-items-center">
                <div className="input_groupS col-4">
                  <ReactDatePicker
                    placeholderText="From Date"
                    selected={fromDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                    maxDate={new Date()}
                    className="date_picker "
                  />
                </div>
                <div className="input_groupS col-4">
                  <ReactDatePicker
                    placeholderText="To Date"
                    selected={toDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                      setToDate(date);
                    }}
                    maxDate={new Date()}
                    className="date_picker "
                  />
                </div>
                <div className="col-4" style={{ marginBottom: "20px" }}>
                  <FormControl
                    variant="standard"
                    className="w-100"
                    onClick={() => {
                      setdrp(!drp);
                    }}
                  >
                    <InputLabel htmlFor="supplier">Select Supplier</InputLabel>
                    <Input
                      autoComplete="off"
                      id="supplier"
                      className="w-100"
                      value={supValue}
                      name={supValue}
                      onChange={(e) => {
                        let value = e.target.value.toUpperCase();
                        setSupValue(value);

                        let filterSupplier = value
                          ? suppArray?.filter((item) =>
                              item?.toLowerCase().includes(value.toLowerCase())
                            )
                          : suppArray;
                        setSupplierName([...filterSupplier]);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          {supValue !== "" && (
                            <IconButton
                              onClick={() => {
                                setSupValue("");
                              }}
                            >
                              <MdClose />
                            </IconButton>
                          )}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {drp && (
                    <ClickAwayListener onClickAway={() => setdrp(!drp)}>
                      <div className="supplier_dropdown">
                        {supplierName?.length > 0 ? (
                          supplierName?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="supplier_style"
                                onClick={() => {
                                  let value = `${item}`;
                                  setSupValue(value);
                                  setdrp(!drp);
                                  let supp_data = newSupplier
                                    ?.filter((sup) => sup?.supp_name === value)
                                    ?.at(0);
                                  setSupplierData(supp_data);
                                }}
                              >
                                <div className="fs-6 supplier_captilize">
                                  {item.toUpperCase()}
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="supplier_style">No Record Found.</div>
                        )}
                      </div>
                    </ClickAwayListener>
                  )}
                </div>

                <div className="col-sm-4 " style={{ marginTop: 30 }}>
                  <FormControl variant="standard" className="w-100" sx={{}}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Services
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name={service}
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                      label="Services"
                    >
                      {arry?.map((list) => (
                        <MenuItem
                          value={list?.type}
                          sx={{ bgcolor: "#fff" }}
                          key={list?.id}
                        >
                          {list?.type?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="py-5">
              <div className="row ">
                <div class="checkbox-rect col-6 col-md-3">
                  <input
                    type="checkbox"
                    id="checkbox-rect1"
                    name="check"
                    checked={voucher?.salin}
                    onChange={() =>
                      setVoucher({
                        ...voucher,
                        salin: voucher?.salin === 4 ? "" : 4,
                      })
                    }
                  />
                  <label for="checkbox-rect1" className="h6">
                    Sale Invoice
                  </label>
                </div>

                <div class="checkbox-rect col-6 col-md-3">
                  <input
                    type="checkbox"
                    id="add_voucher"
                    name="check"
                    checked={voucher?.vch?.length > 0}
                    onChange={() =>
                      setVoucher({
                        ...voucher,
                        vch: voucher?.vch?.length ? [] : [1, 2, 3],
                      })
                    }
                  />
                  <label for="add_voucher" className="h6">
                    Vochers
                  </label>
                </div>

                <div class="checkbox-rect col-6 mt-4 col-md-3 mt-md-0">
                  <input
                    type="checkbox"
                    id="add_credit_note"
                    name="check"
                    checked={voucher?.cn}
                    onChange={() =>
                      setVoucher({
                        ...voucher,
                        cn: voucher?.cn === 5 ? "" : 5,
                      })
                    }
                  />
                  <label for="add_credit_note" className="h6">
                    Credit Note
                  </label>
                </div>

                <div class="checkbox-rect col-6 col-md-3 mt-4 mt-md-0">
                  <input
                    type="checkbox"
                    id="add_opening_balence"
                    name="check"
                    checked={voucher?.op === "opb"}
                    onChange={() =>
                      setVoucher({
                        ...voucher,
                        op: voucher?.op === "opb" ? "" : "opb",
                      })
                    }
                  />
                  <label for="add_opening_balence" className="h6">
                    Opening Balence
                  </label>
                </div>

                <span
                  className="form-control me-5"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    paddingTop: "40px",
                    paddingLeft: "10px",
                  }}
                >
                  <input
                    type="radio"
                    id="without_statement"
                    value="cheque"
                    name="currency"
                    checked={voucher?.curr === "PKR"}
                    onChange={() =>
                      setVoucher({
                        ...voucher,
                        curr: "PKR",
                      })
                    }
                  />
                  <label for="without_statement" className="h6">
                    PKR
                  </label>
                </span>

                <span
                  className="form-control me-5"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    paddingTop: "40px",
                    paddingLeft: "10px",
                  }}
                >
                  <input
                    type="radio"
                    id="with_sar"
                    value="cheque"
                    name="currency"
                    checked={voucher?.curr === "other"}
                    onChange={() => {
                      setVoucher({
                        ...voucher,
                        curr:
                          agentData?.agent_group?.currency?.curr_code === "PKR"
                            ? "PKR"
                            : "other",
                      });
                    }}
                  />
                  <label for="with_sar" className="h6">
                    Currency
                  </label>
                </span>
              </div>

              {/* radio buttons */}

              {message ? (
                <div className="text-center py-4">
                  <button
                    className="button_style px-4"
                    type="submit"
                    disabled={!hasVoucherData || supplierData?.acc_id === ""}
                    onClick={(e) => {
                      const body = {
                        ...voucher,
                        fromDate: new Date(
                          moment(fromDate).format("YYYY-MM-DD")
                        ),
                        toDate: new Date(moment(toDate).format("YYYY-MM-DD")),
                        accID: supplierData?.acc_id,
                      };
                      dispatch(AsyncCreateAgentStatment({ body }));
                      setMessage(false);
                      setTimeout(() => {
                        setMessage(true);
                        setChangeComponet("data");
                      }, 3000);
                    }}
                  >
                    Agent Statement
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {agentStatement?.succcess && changeComponent === "data" && (
        <SupplierStatement
          agentData={supValue}
          fromDate={moment(fromDate).format("MMM DD, YYYY")}
          toDate={moment(toDate).format("MMM DD, YYYY")}
          statement="agent"
          data={
            voucher?.op === "opb"
              ? [
                  {
                    acc_voucher_trans: [
                      {
                        d_cur_credit: 0,
                        d_cur_debit: agentStatement?.total,
                        trans_description: "Opening Balance",
                      },
                    ],
                  },
                  ...agentStatement?.filterData,
                ]
              : [
                  {
                    acc_voucher_trans: [
                      {
                        d_cur_credit: 0,
                        d_cur_debit: 0,
                        trans_description: "Opening Balance",
                      },
                    ],
                  },
                  ...agentStatement?.filterData,
                ]
          }
          getAdminData={getAdminData?.logo}
          userData={userData}
          voucher={voucher}
          setChangeComponet={setChangeComponet}
          editVoucherPermission={editVoucherPermission}
        />
      )}
    </div>
  );
}

export default SupplierAccountStatement;
