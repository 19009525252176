import React, { useState } from "react";

import PassengerInformation from "./UmrahTab/PassengerInformation";
import Tabs from "./UmrahTab/Tabs";
import FlightInfo from "./UmrahTab/FlightInfo";
import HotelInfo from "./UmrahTab/HotelInfo";
import TransferInformation from "./UmrahTab/TransferInformation";
import { Button, TextField } from "@mui/material";

const UmrahTab = () => {
  const [InputGroup, setInputGroup] = useState("passanger");
  return (
    <div>
      <Tabs InputGroup={InputGroup} setInputGroup={setInputGroup} />
      <div className="col-8 col-md-3">
        <TextField variant="standard" label='Select a Company' className="w-100" />
      </div>
      {InputGroup === "passanger" && (
        <>
          <PassengerInformation setInputGroup={setInputGroup} />
        </>
      )}

      {InputGroup === "flight" && (
        <>
          <FlightInfo setInputGroup={setInputGroup} />
        </>
      )}
      {InputGroup === "hotel" && (
        <>
          <HotelInfo setInputGroup={setInputGroup} />
        </>
      )}
      {InputGroup === "Transfer" && (
        <>
          <TransferInformation setInputGroup={setInputGroup} />
        </>
      )}
      <div className="d-flex d-md-none justify-content-center align-items-center mt-2">
        {/* {showPrevios && <Button variant="contained" className="me-2"
          onClick={e => {
            if (InputGroup === 'passenger') {
              setshowNext(true)
              setshowPrevios(false)
            } else if (InputGroup === 'flight') {
              setshowNext(true)
              setshowPrevios(false)
              setInputGroup('passenger')
            } else if (InputGroup === 'hotel') {
              setInputGroup('flight')
              setshowNext(true)
              setshowPrevios(true)
            }
            else {
              setshowNext(false)
              setshowPrevios(true)
            }
          }}>Previous</Button>}
        {showNext && <Button variant="contained" onClick={e => {
          if (InputGroup === 'passenger') {
            setshowNext(true)
            setshowPrevios(false)
            setInputGroup('flight')
          } else if (InputGroup === 'flight') {
            setshowNext(true)
            setshowPrevios(false)
            setInputGroup('hotel')
          } else if (InputGroup === 'hotel') {
            setInputGroup('Transfer')
            setshowNext(false)
            setshowPrevios(true)
          }
          else {
            setshowNext(!showNext)
            setshowPrevios(true)
            setInputGroup('hotel')
          }
        }}>Next</Button>} */}
      </div>
    </div>
  );
};

export default UmrahTab;
