import React from 'react';
import PricingComp from '../components/Setup/Pricing';

const Pricing = () => {
  return (
    <div className='Full_height'>
      <PricingComp />
    </div>
  )
}

export default Pricing