import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP} from "../ApiConfig";

const initialState = {
    requestsData: [],
  
    loading: false,
    error: null,
  };

  export const asyncRequestsGetData = createAsyncThunk(
    "asyncRequestsGetData/get",
    async(_, {rejectWithValue})=>{
        try {
            const response = await HTTP.get("/api/v1/b2b/resolved_requests/requests");
            return await response?.data?.data;
        } catch (error) {
            console.log(error)
            rejectWithValue(error)
        }
    }
  )

const requestsSlice = createSlice({
    name:"requestsSlice",
    initialState,
    reducers: {},
    extraReducers: (builder)=>{
        builder.addCase(asyncRequestsGetData.pending, (state, {payload})=>{
            state.loading = true;
            state.error = null
        })
        builder.addCase(asyncRequestsGetData.fulfilled, (state, {payload})=>{
            state.loading = false;
            state.error = null;
            state.requestsData = payload;
        })
        builder.addCase(asyncRequestsGetData.rejected, (state, {payload})=>{
            state.loading = false;
            state.error = payload;
        })
    }
})

export default requestsSlice.reducer;