import React from 'react'
import Header from '../components/Common/Header'
import RequestDetails from '../components/FlightRequest/RequestDetails'
import Footer from '../components/Footer'

const FlightRequests = () => {
  return (
    <div className='Full_height'>
      <RequestDetails />
    </div>
  )
}

export default FlightRequests