import React, { useEffect, useRef, useState } from "react";
import MUIDataTable from "mui-datatables";
import DeleteModal from "../DeleteModal";
import "react-quill/dist/quill.snow.css";
import {
  asyncDeleteSupplierRule,
  asyncEditSupplierData,
  asyncGetSupplierRule,
  asyncPostSupplier,
  asyncUpdateSupplierRule,
  editSupplierRuleNull,
} from "../../redux/features/setupRestPages/Price_AirLine_SuppRole";
import { useDispatch, useSelector } from "react-redux";
import { options } from "../../Utils/ColumnOptions";
import { Form, Formik } from "formik";

import * as yup from "yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import ErrorMessage from "../AppForm/ErrorMessage";
import BtnLoader from "../AppForm/BtnLoader";
import { apiUrl } from "../../Utils/configUrl";
import { ChangeStatus } from "../../Utils/ChangeStatus";

const SupplierRules = () => {
  const dispatch = useDispatch();
  const [imgError, setImgError] = useState("");
  const fileInputRef = useRef(null);

  const { supplierRulesData, editSupplierRule, loading } = useSelector(
    (state) => state.price_airlines_suppRoleSlice
  );

  useEffect(() => {
    dispatch(asyncGetSupplierRule());
  }, [dispatch]);

  const typeData = [
    { id: 1, type: "gds" },
    { id: 2, type: "flight-group" },
    { id: 3, type: "insurance" },
    { id: 4, type: "visa" },
    { id: 5, type: "hotel" },
    { id: 6, type: "transfer" },
    { id: 7, type: "activity" },
    { id: 8, type: "umrah-visa" },
    { id: 9, type: "general-invoice" },
  ];

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/supplier-rule/status",
                      !value,
                      id,
                      "status",
                      dispatch
                    );
                    localStorage.removeItem("model");
                  }}
                />
                <label htmlFor={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRender: (value) => {
          return (
            <div>
              <img src={`${apiUrl}/${value}`} className="table-row-image" />
            </div>
          );
        },
      },
    },
    {
      name: "heading",
      label: "Heading",
    },
    {
      name: "description",
      label: "Description",
    },

    {
      label: "Action",
      name: "delete",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <div className=" d-flex">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={(e) => {
                  dispatch(asyncEditSupplierData(id));
                  window.scroll({ top: 0 });
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  dispatch(asyncDeleteSupplierRule(id));
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  const validationSchem = yup.object().shape({
    type: yup.string().required("Type Required"),
    description: yup.string().required("Description Required"),
    heading: yup.string().required("Heading is required"),
  });

  const initialValues = {
    type: editSupplierRule?.type || "",
    description: editSupplierRule?.description || "",
    heading: editSupplierRule?.heading || "",
    image: editSupplierRule?.image || "",
  };

  return (
    <>
      <div className="pb-5">
        <div className="mx-5 rounded-2 shadow-sm mt-5 pb-1 bg-white mb-3 ">
          <p className="otawix-heading box_title">Supplier Rules</p>
          <div className="px-4">
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={validationSchem}
              initialValues={initialValues}
              onSubmit={(values, action) => {
                const formData = new FormData();
                formData.append("type", values?.type);
                formData.append("image", values?.image);
                formData.append("heading", values?.heading);
                formData.append("description", values?.description);

                if (editSupplierRule) {
                  dispatch(
                    asyncUpdateSupplierRule({
                      id: editSupplierRule?.id,
                      formData,
                    })
                  );
                  dispatch(editSupplierRuleNull());
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                } else {
                  dispatch(asyncPostSupplier(formData));
                  action.resetForm();
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                }
              }}
            >
              {({ values, setFieldValue, errors }) => {
                return (
                  <Form>
                    <div className="row my-5">
                      <div className="col-2 ">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            Select Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={values.type}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue("type", value);
                            }}
                            name="type"
                          >
                            {typeData?.map((typ) => (
                              <MenuItem value={typ?.type} key={typ?.id}>
                                {typ?.type}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage errors={errors && errors.type} />
                        </FormControl>
                      </div>

                      <div className="col-2">
                        <TextField
                          id="standard-basic"
                          label={[
                            "Image ",
                            <span className="text-danger">
                              (size 80*80 only png)
                            </span>,
                          ]}
                          variant="standard"
                          className="w-100"
                          inputRef={fileInputRef}
                          type={"file"}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="image"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file && file.type === "image/png") {
                              setFieldValue("image", file);
                              setImgError("");
                            } else {
                              setImgError("Only png Images Required");
                              setFieldValue("image", "");
                            }
                          }}
                        />
                        {imgError && (
                          <div className="text-danger pt-1">{imgError}</div>
                        )}
                      </div>

                      <div className="col-2  ">
                        <TextField
                          id="standard-basic"
                          autoComplete="off"
                          label="Heading"
                          variant="standard"
                          className="w-100"
                          name="heading"
                          value={values?.heading}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("heading", value.toUpperCase());
                          }}
                        />
                        <ErrorMessage errors={errors && errors.heading} />
                      </div>
                      <div className="col-3">
                        <TextField
                          fullWidth
                          id="standard-basic"
                          autoComplete="off"
                          className="w-100"
                          variant="standard"
                          value={values.description}
                          label="Description"
                          onChange={(e) => {
                            const value = e.target.value.toUpperCase();
                            setFieldValue(`description`, value);
                          }}
                        />
                        <ErrorMessage errors={errors && errors.description} />
                      </div>
                      <div className="col-1 my-4 ">
                        <div className="checkbox-rect">
                          <input
                            type="checkbox"
                            id="status"
                            name="status"
                            checked={values?.status}
                            onChange={(e) => {
                              setFieldValue("status", e.target.checked);
                            }}
                          />
                          <label htmlFor="status" className=" primary_color">
                            Status
                          </label>
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <button className="setup-btn me-1" type="submit">
                          {loading ? (
                            <BtnLoader />
                          ) : editSupplierRule ? (
                            "Update"
                          ) : (
                            "Save"
                          )}
                        </button>
                        {editSupplierRule && (
                          <button
                            className="setup-btn bg-danger"
                            onClick={(e) => dispatch(editSupplierRuleNull())}
                          >
                            Cancel
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="mx-5 rounded-2 shadow-sm mt-5 bg-white mb-3">
          <p className="box_title otawix-heading">Supplier Rules List</p>
          <div className="p-4">
            {/* <DeleteModal
              show={showDeleteModal}
              setShow={setShowDeleteModal}
              rowId={modalData}
              onClick={(e) => {
                dispatch(asyncDeleteSupplierRule(modalData));
                setShowDeleteModal(false);
              }}
            /> */}
            <MUIDataTable
              className="muidatatable"
              title={""}
              data={supplierRulesData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierRules;
