// import { OutlinedInput, TextField } from "@mui/material";
// import React, { useState, useEffect } from "react";
// import { HTTP } from "../../../Utils/API";
// import MakePaymentModal from "./MakePaymentModal";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { useDispatch, useSelector } from "react-redux";
// import { asyncGetMakePayments } from "../../../redux/features/Payments/MakePaymentsSlice";
// import { useFormik } from "formik";
// import * as yup from "yup";
// import FormButton from "../../AppForm/FormButton";
// import AppToast from "../../AppToast";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

// export const basicSchema = yup.object().shape({
//   amount: yup.number().required("Required"),
//   supplier: yup.string().required("Required"),
//   // agent: yup.string().required("Required"),
// });

// const MakePayment = () => {
//   const dispatch = useDispatch();
//   const { makePayments, supplierData } = useSelector(
//     (state) => state.makePayments
//   );
//   const [radio, setRadio] = useState("supplier");
//   const [supplierId, setSupplierId] = useState("");
//   const [showModal, setShowModal] = useState(false);
//   // const [data, setData] = useState({});
//   // const [Supdata, setSupData] = useState([]);
//   const [SupplierD, setSupplierD] = useState({
//     amount: "",
//     supplier: "",
//     // agent:""
//   });
//   const [agentData, setagentData] = useState({
//     q: "",
//   });
//   const [agentResult, setAgentResult] = useState([]);
//   useEffect(() => {
//     dispatch(asyncGetMakePayments());
//   }, []);
//   const onSubmit = (values, actions) => {
//     console.log(values);
//     if (values.cancelable == true) {
//       return;
//     }
//     setSupplierD({ ...values, agent: agentData?.q,agentId:agentData?.agentId });
//     setShowModal(true);
//   };

//   const {
//     values,
//     errors,
//     touched,
//     setFieldValue,
//     handleBlur,
//     handleChange,
//     handleSubmit,
//   } = useFormik({
//     initialValues: {
//       amount: "",
//       supplier: "s",
//       // supplier: radio =="supplier"?"":"s",
//       agent: "",
//     },
//     validationSchema: basicSchema,
//     onSubmit,
//   });

//   console.log(makePayments);
//   console.log(supplierData);
//   let Supdata = supplierData;
//   let data = makePayments;

//   useEffect(() => {
//     HTTP.get(`/api/v1/b2b/only_customer_without_bg?q=${agentData.q}`)
//       .then((res) => {
//         console.log(res.data.data);
//         setAgentResult(res?.data?.data);
//       })
//       .catch((err) => console.log(err));
//   }, [agentData]);

//   const fetchAgent = () => {};

//   return (
//     <>
//       <form
//         onSubmit={handleSubmit}
//         className="col-sm-7 mx-auto pb-3 rounded-3"
//         style={{ marginTop: "5rem", background: "#fff" }}
//       >
//         <p className="otawix-heading h4">Make Payment</p>
//         <div className="col-sm-4 d-flex mx-auto px-3 mt-5">
//           <label className="form-control me-4 ">
//             <input
//               type="radio"
//               id="supplier"
//               value="supplier"
//               checked={radio === "supplier"}
//               onChange={(e) => {
//                 setRadio(e.target.value);
//                 setSupplierD({
//                   amount: "",
//                   supplier: "",
//                 });
//               }}
//             />
//             <span className="h6">Supplier</span>
//           </label>

//           <label className="form-control">
//             <input
//               type="radio"
//               id="customer"
//               value="customer"
//               checked={radio === "customer"}
//               onChange={(e) => {
//                 setRadio(e.target.value);
//               }}
//             />
//             <span className="h6">Agent</span>
//           </label>
//         </div>
//         <div className="row mt-4 mx-auto col-sm-11 mt-5 px-3">
//           <div className="col-sm-1"></div>
//           <div className="col-sm-5">
//             <TextField
//               id="standard-basic"
//               type="number"
//               label="Enter Amount"
//               variant="standard"
//               className="w-100"
//               autoComplete="off"
//               name="amount"
//               error={touched.amount && errors.amount ? 1 : 0}
//               value={values.amount}
//               onChange={handleChange}
//               onBlur={handleBlur}
//               helperText={touched.amount && errors.amount ? errors.amount : ""}
//             />
//           </div>
//           {radio === "supplier" ? (
//             <div className="col-sm-5">
//               <FormControl variant="standard" className="w-100">
//                 <InputLabel id="demo-simple-select-standard-label">
//                   Supplier
//                 </InputLabel>
//                 <Select
//                   labelId="demo-simple-select-standard-label"
//                   id="demo-simple-select-standard"
//                   label="Supplier"
//                   name="supplier"
//                   error={touched.supplier && errors.supplier ? 1 : 0}
//                   value={values.supplier}
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   helperText={
//                     touched.supplier && errors.supplier ? errors.supplier : ""
//                   }
//                 >
//                   {Supdata.map((supp) => {
//                     return (
//                       <MenuItem
//                         value={supp.name}
//                         key={supp.id}
//                         onClick={() => setSupplierId(supp.id)}
//                       >
//                         {supp.name}
//                       </MenuItem>
//                     );
//                   })}
//                 </Select>
//               </FormControl>
//             </div>
//           ) : (
//             <div className="col-sm-5">
//               <FormControl sx={{ m: 1, width: 300 }}>
//                 <TextField
//                   id="standard-basic"
//                   label="agent"
//                   variant="standard"
//                   value={agentData.q}
//                   onChange={(e) => setagentData({ q: e.target.value })}
//                 />
//                 {agentResult?.map((name) => (
//                   <MenuItem
//                     key={name.name}
//                     value={name.name}
//                     label="agent"
//                     // name="agent"
//                     // error={touched.agent && errors.agent ? 1 : 0}
//                     // value={values.agent}
//                     // onChange={handleChange}
//                     // onBlur={handleBlur}
//                     // helperText={
//                     //   touched.agent && errors.agent ? errors.agent : ""
//                     // }
//                     // style={getStyles(name, personName, theme)}
//                     onClick={() => setagentData({ ...agentData, q: name.name,agentId:name.id })}
//                     // onClick={(e) => setFieldValue(e.target.value)}
//                   >
//                     {name.name}
//                   </MenuItem>
//                 ))}
//                 {/* <Select
//           labelId="demo-multiple-name-label"
//           id="demo-multiple-name"
//           multiple
//           value={agentData.q}
//                     onChange={e=>setagentData({q:e.target.value})}
//           input={<OutlinedInput label="Name" />}
//           MenuProps={MenuProps}
//         >
//           {agentResult?.map((name) => (
//             <MenuItem
//               key={name.name}
//               value={name.name}
//               // style={getStyles(name, personName, theme)}
//             >
//               {name}
//             </MenuItem>
//           ))}
//         </Select> */}
//               </FormControl>
//               {/* <FormControl variant="standard" className="w-100">
//               <InputLabel id="demo-simple-select-standard-label">
//                 Agent
//               </InputLabel>
//               <TextField
//                     variant="outlined"
//                     label="Agent"
//                     autoComplete="off"
//                     className="w-100"
//                     value={agentData.q}
//                     onChange={e=>setagentData({q:e.target.value})}
//                   />
            
//             </FormControl> */}
//               {/* {agentResult && 
//                 <MenuItem >
//                   {agentResult?.map(it=>it?.name)}
//                 </MenuItem>
//              } */}
//             </div>
//           )}
//           <div className="col-sm-1"></div>
//         </div>
//         <div className="col-sm-2 mx-auto my-5">
//           <FormButton type="submit" onClick={onSubmit}>
//             Confirm Payment
//           </FormButton>
//         </div>
//       </form>
//       <MakePaymentModal
//         show={showModal}
//         setShow={setShowModal}
//         SupplierD={SupplierD}
//         data={data}
//         AgentSuplierradio={radio}
//         supplierId={supplierId}
//       />
//     </>
//   );
// };

// export default MakePayment;









// // ********************************************************************************* this is in build form ,...modifying it







import { OutlinedInput, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { HTTP } from "../../../Utils/API";
import MakePaymentModal from "./MakePaymentModal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAgentData, asyncGetMakePayments } from "../../../redux/features/Payments/MakePaymentsSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import FormButton from "../../AppForm/FormButton";
import BtnLoader from "../../AppForm/BtnLoader";



const MakePayment = () => {
  const dispatch = useDispatch();
  const { makePayments, supplierData,agentResult,loading } = useSelector(
    (state) => state.makePayments
  );
  console.log(supplierData)


  const [radio, setRadio] = useState("supplier");
  const [supplierId, setSupplierId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [agentData, setAgentData] = useState();
  const [Query, setQuery] = useState();
  const [handleMenu, sethandleMenu] = useState(false);

  const [SupplierD, setSupplierD] = useState({
    amount: "",
    supplier: "",
    agent:""
  });

// console.log(supplierId);

  useEffect(() => {
    dispatch(asyncGetMakePayments());
    dispatch(asyncGetAgentData(Query))
  }, [Query]);



 const basicSchema = yup.object().shape({
  amount: yup.number().required("Required"),
  supplier: yup.string().required("Required"),
  agent:radio=="supplier" ? yup.string(): yup.string().required("Required"),

});


  const onSubmit = (values, actions) => {
    console.log(values);
    if (values.cancelable == true) {
      return;
    }
    setSupplierD({...values,agent:agentResult});
    setShowModal(true);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      // supplier:supplierData[0].name || "v",
      // supplier:supplierData[0]?.name || "",
      supplier:supplierData[0]?.name || "",
      agent:agentData?.name || "" 
    },
    validationSchema: basicSchema,
    onSubmit,
  });
  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="col-sm-7 mx-auto pb-3 rounded-3"
        style={{ marginTop: "5rem", background: "#fff" }}
      >
        <p className="otawix-heading h4">Make Payment</p>
        <div className="col-sm-4 d-flex mx-auto px-3 mt-5">
          <label className="form-control me-4 ">
            <input
              type="radio"
              id="supplier"
              value="supplier"
              checked={radio === "supplier"}
              onChange={(e) => {
                setRadio(e.target.value);
                setSupplierD({
                  amount: "",
                  supplier: "",
                });
              }}
            />
            <span className="h6">Supplier</span>
          </label>

          <label className="form-control">
            <input
              type="radio"
              id="customer"
              value="customer"
              checked={radio === "customer"}
              onChange={(e) => {
                setRadio(e.target.value);
              }}
            />
            <span className="h6">Agent</span>
          </label>
        </div>
        <div className="row mt-4 mx-auto col-sm-11 mt-5 px-3">
          <div className="col-sm-1"></div>
          <div className="col-sm-5">
            <TextField
              id="standard-basic"
              type="number"
              label="Enter Amount"
              variant="standard"
              className="w-100"
              autoComplete="off"
              name="amount"
              error={touched.amount && errors.amount ? 1 : 0}
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.amount && errors.amount ? errors.amount : ""}
            />
          </div>
          {radio === "supplier" ? (
            <div className="col-sm-5">
              <FormControl variant="standard" className="w-100">
                <InputLabel id="demo-simple-select-standard-label">
                  Supplier
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="Supplier"
                  name="supplier"
                  error={touched.supplier && errors.supplier ? 1 : 0}
                  value={values.supplier}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched.supplier && errors.supplier ? errors.supplier : ""
                  }
                >
                  {supplierData.map((supp) => {
                    return (
                      <MenuItem
                        value={supp.name}
                        key={supp.id}
                        onClick={() => {
                          setSupplierId(supp.id)
                          // console.log(supp);
                       
                        }}
                      >
                        {supp.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          ) : (
            <div className="col-sm-5">
             
          <TextField
            error={touched.agent && errors.agent ? 1 : 0}
            id="standard-basic"
            label="agent Name"
            variant="standard"
            className="w-100"
            autoComplete="off"
            onBlur={handleBlur}
            onKeyPress={()=>sethandleMenu(true)}
            helperText={touched.agent && errors.agent ? errors.agent : ""}
            value={agentData?.name ||""}
            onClick={(e) => sethandleMenu(!handleMenu)}
            onChange={(e) => setQuery(e.target.value)}

          />
{/* this is for drop down data to show  */}
          {agentResult?.map((comp, ind) => {
            return (
              handleMenu && (
                <MenuItem
                  onClick={(e) => {
                    sethandleMenu(!handleMenu);
                    setAgentData(comp)
                    // setCompIndex(ind);
                  }}
                >
                  {loading ? <BtnLoader/>:<TextField
                    sx={{
                      "& fieldset": { border: 'none' },
                    }}
                    id="standard-basic"
                    style={{border:"none !important"}}
                    className="w-100 "
                    autoComplete="off"
                    value={comp?.name}
                    onChange={handleChange}
                    name="agent"
                    onBlur={handleBlur}
                    helperText={
                      touched.agent && errors.agent ? errors.agent : ""
                    }
                  />}
                </MenuItem>
              )
            );
          })}
            </div>
          )}
          <div className="col-sm-1"></div>

        </div>
        <div className="col-sm-2 mx-auto my-5">
          <FormButton type="submit" onClick={onSubmit}>
            Confirm Payment
          </FormButton>

        </div>
      </form>
      <MakePaymentModal
        show={showModal}
        setShow={setShowModal}
        SupplierD={SupplierD}
        data={makePayments}
        AgentSuplierradio={radio}
        supplierId={supplierId ||supplierData[0]?.id}
      />
    </>
  );
};

export default MakePayment;
