import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  visaCountry: [],
  visaCountryEdit: null,
  loading: false,
  error: null,
};

// getting visaCountries data
export const asyncGetVisaCountry = createAsyncThunk(
  "asyncGetVisaCountry/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/visa_countries`);
      return await response?.data?.data;
    } catch (error) {
      console.log(error?.message);
      return rejectWithValue(error?.message);
    }
  }
);

// posting visaCountries data
export const asyncPostVisaCountry = createAsyncThunk(
  "asyncPostVisaCountry/post",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/visa_countries`, values);
      dispatch(asyncGetVisaCountry());
      return await response?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Edit visaCountries data
export const asyncgetSingleCountryList = createAsyncThunk(
  "asyncEditVisaCountry/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/visa_countries/${id}`);
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update visaCountries data
export const asyncUpdateCountryList = createAsyncThunk(
  "asyncUpdateCountryList/patch",
  async (updateobj, { rejectWithValue, dispatch }) => {
    const { id, ...updateData } = updateobj;
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/visa_countries/${id}`,
        updateData
      );
      dispatch(asyncGetVisaCountry());
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const CountrySlice = createSlice({
  name: "CountrySlice",
  initialState,
  reducers: {
    clearVisaCountryList: (state) => {
      state.visaCountryEdit = null;
    },
  },

  extraReducers: (builder) => {
    // getting visaCountries data
    builder.addCase(asyncGetVisaCountry.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetVisaCountry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.visaCountry = payload;
    });
    builder.addCase(asyncGetVisaCountry.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting visaCountries data
    builder.addCase(asyncPostVisaCountry.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostVisaCountry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.visaCountry = payload;
    });
    builder.addCase(asyncPostVisaCountry.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //edit visaCountries data
    builder.addCase(asyncgetSingleCountryList.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncgetSingleCountryList.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.visaCountryEdit = payload;
      }
    );
    builder.addCase(
      asyncgetSingleCountryList.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // update visaCountries data
    builder.addCase(asyncUpdateCountryList.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateCountryList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.visaCountry = payload;
    });
    builder.addCase(asyncUpdateCountryList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { clearVisaCountryList } = CountrySlice.actions;
export default CountrySlice.reducer;
