import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import airsial from "../../assets/images/Airsial.jpeg";
import pia from "../../assets/images/PIA.jpeg";
import serene from "../../assets/images/Serene.jpeg";
import moment from "moment";
import { IoIosAirplane } from "react-icons/io";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { asyncupdateFlightGroupBookeRequestFlag } from "../../redux/features/booking/fligthBookingSlice";
import { asyncCreateFlightRequestsData } from "../../redux/features/requests/flightrequest";
import BtnLoader from "../AppForm/BtnLoader";

const RequestChangeModal = ({
  show,
  setShow,
  rowData,
  agencyData,
  setActive_Tab,
  booking,
  type,
  message,
  setMessage,
}) => {
  
  const [requestData, setRequestData] = useState({
    Request_Number: "",
    Date: moment().format("ddd DD-MMM-YY HH:mm"),
    Agent_Description: "",
  });

  const dispatch = useDispatch();
  const handleSubmit = (values) => {
    let body = {
      agent_id: agencyData?.agent_id,
      book_id:
        booking?.booking?.id,
      req_resolvedBy_id: 0,
      req_dateTime: moment().format("ddd DD-MMM-YY HH:mm"),
      req_resolve_dateTime: moment().format("ddd DD-MMM-YY HH:mm"),
      agent_note: values?.Agent_Description,
      admin_note: "",
      // req_flag: 7,
      take_by_user_id: 0,
      request_type: "Issued",
      status: "new",
    };

     setMessage(true);
     setTimeout(() => {
      setMessage(false)
      setShow(setActive_Tab("Details"));
      dispatch(
        asyncupdateFlightGroupBookeRequestFlag({
          id: booking?.booking?.FlightBookingModels[0]?.id,
          body: {
            request_flag: "Issued",
          },
        })
      );
      dispatch(asyncCreateFlightRequestsData(body));
     }, 3000);
  };

  return (
    <Formik
      initialValues={{
        ...requestData,
      }}
      // validationSchema={SignupSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Modal
          show={show}
          onHide={(e) => setShow(setActive_Tab("Details"))}
          centered
          dialogClassName="modal-xl modal-full"
        >
          <Form>
            <Modal.Header>
              <Modal.Title>Update Request</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="px-md-5">
                <div className="row my-4 justify-content-center align-items-center">
                  <div className="col-1">
                    <span>Type: {type}</span>
                  </div>
                  {/* <div className="col-3">
                    <TextField
                      variant="standard"
                      label="Request Number"
                      name="Request_Number"
                      className="w-100"
                      value={values.Request_Number}
                      onChange={(e) => {
                        setFieldValue("Request_Number", e.target.value);
                      }}
                    />
                  </div> */}
                  <div className="col-4">
                    <TextField
                      variant="standard"
                      label="Date & Time"
                      className="w-100"
                      name="Date"
                      value={values.Date}
                      // onChange={e => {
                      //   setFieldValue(
                      //     "Request_Number",
                      //     e.target.value
                      //   )
                      // }}
                    />
                  </div>

                  <div className="col-4">
                    {" "}
                    <TextField
                      variant="standard"
                      label="Agent Description"
                      name="Agent_Description"
                      className="w-100"
                      value={values.Agent_Description}
                      onChange={(e) => {
                        setFieldValue("Agent_Description", e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* <hr className="my-5" /> */}
                <div className="row mt-5">
                  <div className="col-12 col-md-6">
                    {/* ///////////////////////////////////////////////////////////////////////////Main Boking/////////////////////////// */}
                    <div className="booked_requestModel p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Booking </h5>
                      </div>
                      <hr />

                      <table class="table table-bordered border-dark align-items-center ">
                        <thead
                          className="bg_secondary text-white "
                          style={{ fontSize: "20px" }}
                        >
                          <tr>
                            <th scope="col">Booking ID</th>
                            <th scope="col">Booking Status</th>
                            <th scope="col">Document Status</th>
                            <th scope="col"> Paid by Agency</th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-dark "
                          style={{ fontSize: "20px", color: "#000000" }}
                        >
                          {
                            <tr>
                              <td>{booking?.booking?.id}</td>
                              <td>{booking?.booking?.book_status}</td>
                              <td>{booking?.booking?.doc_status}</td>
                              <td></td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>

                    {/* //////////////////////////////////////////////////////////////////////Flight Segemnet////////////////////////////////////// */}
                    <div>
                      {booking?.booking?.FlightBookingModels &&
                        booking?.booking?.FlightBookingModels[0]?.FlightSegmentModels?.map(
                          (fltSegment, index) => (
                            <div className="booked_items">
                              <div className="d-flex align-items-center">
                                <div>
                                  <IoIosAirplane size={26} />
                                </div>
                                <p
                                  className="h5"
                                  style={{
                                    lineHeight: 1,
                                    marginLeft: 6,
                                    paddingTop: 5,
                                  }}
                                >
                                  Flight
                                </p>
                              </div>

                              <div className="d-flex align-items-center justify-content-center ">
                                <div className="col-2">
                                  <div className="d-flex flex-column justify-content-center align-items-center">
                                    <img
                                      src={`/NEW_LOGO/${fltSegment?.flt}.png`}
                                      alt="SereneLogo"
                                      width={80}
                                    />
                                    <div style={{ paddingLeft: 4 }}>
                                      {fltSegment?.flt +
                                        " " +
                                        fltSegment?.flt_no}{" "}
                                    </div>
                                  </div>
                                </div>

                                <div className="col-8">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h6>
                                        {moment(
                                          fltSegment?.dep_date,
                                          "DD-MM-YYYY"
                                        ).format("ddd DD-MMM-YY")}
                                      </h6>
                                      <h6 className="my-3">
                                        {fltSegment?.dep_time}{" "}
                                      </h6>
                                      <h6>
                                        {fltSegment?.origin.split("-")[0] +
                                          "-" +
                                          fltSegment?.origin.split("-")[1]}
                                      </h6>
                                    </div>

                                    <div>
                                      <h6 className="text-center">01:59</h6>
                                      <div className="border_rounded mb-2" />
                                      <h6 className="mx-3">Non-Stop</h6>
                                    </div>

                                    <div>
                                      <h6>
                                        {moment(
                                          fltSegment?.arr_date,
                                          "DD-MM-YYYY"
                                        ).format("ddd DD-MMM-YY")}
                                      </h6>
                                      <h6 className="my-3">
                                        {fltSegment?.arr_time}{" "}
                                      </h6>
                                      <h6>
                                        {fltSegment?.destination.split("-")[0] +
                                          "-" +
                                          fltSegment?.destination.split("-")[1]}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </div>
                  </div>
                  {/* /////////////////////////////////////////////////passeng Data //////////////////////////////////////////////*/}

                  <div className="col-12 col-md-6">
                    <div className="booked_requestModel p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>Passenger</h5>
                        <h5></h5>
                      </div>
                      <hr />

                      <table class="table table-bordered border-dark align-items-center ">
                        <thead
                          className="bg_secondary text-white "
                          style={{ fontSize: "20px" }}
                        >
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">Type</th>
                            <th scope="col">Name</th>
                            <th scope="col">Ticket No</th>
                          </tr>
                        </thead>
                        <tbody
                          className="text-dark "
                          style={{ fontSize: "20px", color: "#000000" }}
                        >
                          {booking?.booking?.FlightBookingModels &&
                            booking?.booking?.FlightBookingModels[0]?.pax_tables?.map(
                              (pax, index) => (
                                <tr>
                                  <td class="checkbox-rect">
                                    <input
                                      type="checkbox"
                                      id={"passenger" + index}
                                      name="passenger"
                                      checked={type === "Issue"}
                                    />
                                    <label
                                      htmlFor={"passenger" + index}
                                      for="passenger"
                                      className="h6"
                                    ></label>
                                  </td>
                                  <td>{pax?.pax_type}</td>
                                  <td>{pax?.fName + " " + pax?.lname}</td>
                                  <td>{pax?.tkt_number}</td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-column gap-2 justify-content-end align-items-end ">
                  <div className="d-flex gap-4 ">
                    {" "}
                    <button
                      className="border px-4  mt-4 selectbtn_style"
                      style={{ fontSize: "20px", color: "white", padding: 8 }}
                      onClick={() => setShow(setActive_Tab("Details"))}
                    >
                      Close
                    </button>
                    <button
                      className="border px-4  mt-4 selectbtn_style"
                      type="submit"
                      style={{ fontSize: "20px", color: "white", padding: 8 }}
                    >
                      {message ? <BtnLoader /> : "Save Request"}
                    </button>
                  </div>
                  {message && (
                    <div>
                      <p className="fs-5 text-success">
                        Your Request Save Successfully
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default RequestChangeModal;
