import { ListItemText, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { HTTP } from "../../../Utils/API";
import CorporateModal from "./CorporateModal";
import * as yup from "yup";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetCompany, asyncGetCorporatePayment } from "../../../redux/features/Payments/PaymentsAllSlices";
import FormButton from "../../AppForm/FormButton";
import BtnLoader from "../../AppForm/BtnLoader";

const basicSchema = yup.object().shape({
  amount: yup.number().required("Required"),
  company: yup.string().required("Required"),
});
const CorporatePayment = () => {
  
  const {companyList,loading,carporateApiData}=useSelector(state=>state.allPayments)
const dispatch=useDispatch()

  const [showModal, setShowModal] = useState(false);
  const [handleMenu, sethandleMenu] = useState(false);
  const [query, setQuery] = useState("");
  const [corporateData, setCorporateData] = useState();
  const [compIndex, setCompIndex] = useState(0);
  // const [compData, setCompData] = useState();


  useEffect(() => {
dispatch(asyncGetCompany(query))
dispatch(asyncGetCorporatePayment())

  }, [query]);

  const onSubmit = async (values, actions) => {
    if (values.cancelable == true) {
      return;
    }
    setCorporateData({...values,id:companyList?.[compIndex]?.id})
    setShowModal(true);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        amount: "",
        company: companyList?.[compIndex]?.name || "",
      },
      validationSchema: basicSchema,
      onSubmit,
    });

  return (
    <>
    <form
      onSubmit={handleSubmit}
      className="col-sm-5 mx-auto pb-3 rounded-3"
      style={{ marginTop: "5rem", background: "#fff" }}
    >
      <p className="otawix-heading h4">Corporate Payment</p>
      <div className="row mt-4 mx-auto  px-3 my-5" onSubmit={handleSubmit}>
        <div className="col-6">
          <TextField
            error={touched.amount && errors.amount ? 1 : 0}
            id="standard-basic"
            label="Enter Amount"
            variant="standard"
            className="w-100"
            autoComplete="off"
            inputProps={{ maxLength: 50 }}
            name="amount"
            value={values.amount}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.amount && errors.amount ? errors.amount : ""}
          />
        </div>
        <div className="col-6">
          <TextField
            error={touched.company && errors.company ? 1 : 0}
            id="standard-basic"
            label="Company Name"
            variant="standard"
            className="w-100"
            autoComplete="off"
            onBlur={handleBlur}
            onKeyPress={()=>sethandleMenu(true)}
            helperText={touched.company && errors.company ? errors.company : ""}
            value={companyList?.[compIndex]?.name||""}
            onClick={(e) => sethandleMenu(!handleMenu)}
            onChange={(e) => setQuery(e.target.value)}
          />
{/* this is for drop down data to show  */}
          {companyList?.map((comp, ind) => {
            return (
              handleMenu && (
                <MenuItem
                  onClick={(e) => {
                    sethandleMenu(!handleMenu);
                    setCompIndex(ind);
                    // setCompData(comp)
                  }}
                >
                  {loading ? <BtnLoader/>:
                  <TextField
                    sx={{
                      "& fieldset": { border: 'none' },
                    }}
                    id="standard-basic"
                    style={{border:"none !important"}}
                    className="w-100 "
                    autoComplete="off"
                    value={comp?.name}
                    onChange={handleChange}
                    name="company"
                    onBlur={handleBlur}
                    helperText={
                      touched.company && errors.company ? errors.company : ""
                    }
                  />}
                </MenuItem>
              )
            );
          })}
        </div>
      </div>
      <div className="col-sm-3 mx-auto">
        <FormButton type="submit" onClick={onSubmit}>
          Confirm Payment
        </FormButton>
       
      </div>
    </form>
  
     <CorporateModal
          show={showModal}
          setShow={setShowModal}
          corporateData={corporateData}
          Apidata={carporateApiData}
        />
    </>
  );
};

export default CorporatePayment;
