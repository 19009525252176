import React, { useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetOurServicesData } from "../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import { apiUrl } from "../../Utils/configUrl";

const Packages = () => {
  const dispatch = useDispatch();

  const { servicesdata } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  useEffect(() => {
    dispatch(asyncGetOurServicesData());
  }, [dispatch]);

  return (
    <div className="container mb-5" id="packages">
      <div className="d-flex flex-column justify-content-center align-items-center my-5">
        <div className="h1 mt-5">SERVICES</div>
      </div>
      <div className="d-flex flex-wrap gap-3 justify-content-start">
        {servicesdata?.map((data) => {
          return (
            <div
              key={data.id}
              className="d-flex p-4 border bg-white rounded-2 shadow-sm our_service_card gap-2"
            >
              <div>
                <img
                  src={`${apiUrl}/${data?.image}`}
                  alt="packages"
                  width={50}
                  height={50}
                />
              </div>

              <div className="px-2 d-flex flex-column ">
                <h5 className="service-contaner-heading pb-1">
                  {data?.heading}
                </h5>
                <p className="">{data?.description}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Packages;
