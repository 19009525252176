import React, { useState } from "react";
import AgentPNR from "./ImportTab/AgentPNR";
import NewPnr from "./ImportTab/NewPnr";
import "../../Styles/import.css";
import CustomDropDown from "../CustomDrp";
import { Form, Formik, FieldArray } from "formik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const ImportTab = () => {
  const initialValues = {
    ispnr: "Agent PNR",
    // agent pnr states
    pnr: "",
    l_name: "",
    gds: "",
    select_agent: "",
    //new pnr states passanger Information
    PassangerInfo: [
      {
        pax_title: "",
        pax_type: "",
        f_name: "",
        pax_l_name: "",
      },
    ],
    //new pnr states flight Information
    flightInfo: [
      {
        from: "",
        to: "",
        dep_date: "",
        dep_time: "",
        arrival_date: "",
        arrival_time: "",
        airline_code: "",
        airline_no: "",
      },
    ],

    //new pnr states fare Information
    adt_base_fare: "",
    psf_sp_per: "",
    tax_code: "",
    tax_amount: "",
    agent: "",
  };

  const handleSubmit = (values) => {
    console.log("values", values);
    const data = {
      PassangerInfo: values.PassangerInfo,
      flightInfo: values.flightInfo,
      adt_base_fare: values.adt_base_fare,
      psf_sp_per: values.psf_sp_per,
      tax_code: values.tax_code,
      tax_amount: values.tax_amount,
      agent: values.agent,
    };
  };

  const arry = ["Agent PNR", "New PNR"];

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div style={{ paddingTop: 30, paddingBottom: 27 }}>
            <div className="row align-items-end">
              <div className="col-6 col-sm-3">
                <FormControl variant="standard" className="w-100" sx={{}}>
                  <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name={"ispnr"}
                    value={values?.ispnr}
                    onChange={(e) => setFieldValue("ispnr", e.target.value)}
                    label=""
                  >
                    {arry.map((list) => (
                      <MenuItem
                        value={list}
                        sx={{ m: 1, bgcolor: "#fff" }}
                        key={list}
                      >
                        {list}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div>
              {values?.ispnr === "Agent PNR" && (
                <AgentPNR values={values} setFieldValue={setFieldValue} />
              )}
              {values?.ispnr === "New PNR" && (
                <NewPnr
                  values={values}
                  setFieldValue={setFieldValue}
                  FieldArray={FieldArray}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ImportTab;
