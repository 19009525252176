import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CountryDropdown, {
  __esModule,
} from "country-dropdown-with-flags-for-react";
import { HTTP } from "../../../Utils/API";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteTerm,
  asyncEditTerm,
  asyncGetVisaTerms,
  asyncPostVisaTerms,
  asyncUpdateTerm,
  termEditNull,
} from "../../../redux/features/visa/TermsAndConditionsSlice";
import RoundBtn from "../../AppForm/RoundBtn";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import { ErrorMessage, Field, FieldArray, Formik, getIn } from "formik";
import * as Yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";
const Terms = () => {
  const dispatch = useDispatch();
  const { visaTerms, visaTermsEdit, loading } = useSelector(
    (state) => state.visaTerm
  );
  // console.log(visaTermsEdit.map(it=>it.terms));
  const schema = Yup.object().shape({
    country: Yup.string().required(),

    terms: Yup.array().of(
      Yup.string()
        .min(10, "term must be 10 characters")
        .required("terms are required values")
    ),
  });

  // just to map only those whose term is 1 if there is no term added for any one then it will not be shown
  let visaTermsFiltered =
    visaTerms && visaTerms?.filter((val) => val.term.length !== 0);

  const handleEdit = (id) => {
    window.scroll({ top: 0 });
    dispatch(asyncEditTerm(id));
  };

  useEffect(() => {
    dispatch(asyncGetVisaTerms());
  }, [visaTermsEdit, dispatch]);

  console.log(visaTermsEdit);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "name",
      label: "country",
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: "term",
      label: "Requirements",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <ol>
              {value.map((it) => (
                <li className="d-flex justify-content-between">{it.terms}
                <span>
                <button
              className="bg-white btn text-danger"
              onClick={() => dispatch(asyncDeleteTerm(it.id))}
            >
              <FaTrash/>
              {/* <i className="fa fa-trash" style={{fontSize:"12px"}}></i> */}
            </button>
                </span>
                </li>
              ))}
            </ol>
          );
        },
      },
    },
    {
      name: "Action",
      label: "action",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => handleEdit(tableMeta.rowData[0])}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];


  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow mt-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Visa Terms & Condition</p>
      <Formik
          
        enableReinitialize={true}
        validationSchema={schema}
        // validateOnChange={false}
        validateOnBlur={false}

        initialValues={{
          country: (visaTermsEdit && visaTermsEdit[0].country_id) || visaTerms.length ? visaTerms[0]?.id : 0,
          id: visaTermsEdit?.map((it) => it?.id) || [""],
          terms: visaTermsEdit?.map((it) => it?.terms) || [""],
        }}
        onSubmit={(values, action) => {
          // console.log(values);
          // visaTermsEdit
          if (visaTermsEdit) {
            dispatch(asyncUpdateTerm(values));
           dispatch(termEditNull());
          } else {
            dispatch(asyncPostVisaTerms(values));
          }
          action.resetForm({values:""});
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <form
              className="col-12 px-5 mt-5 d-flex flex-column justify-content-center"
              onSubmit={handleSubmit}
            >
              <div className="col-4">
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="country"
                    onChange={handleChange}
                    value={values.country}
                  >
                    {visaTerms &&
                      visaTerms?.map((country) => {
                        return (
                          <MenuItem value={country.id} key={country.id}>
                            {country.name}
                          </MenuItem>
                        );
                      })}
                  </Select>

                  <p style={{ color: "red" }}>{errors.country}</p>
                </FormControl>
              </div>

              <div className="col-6 mt-2 mb-5 text-field w-100 ">
                <FieldArray
                  name="terms"
                  render={(arrayHelpers) => (
                    <div className="w-100 ">
                      {values.terms && values.terms.length > 0 ? (
                        values.terms.map((val, index) => (
                          <div key={index} className="gap-3 my-2 d-flex ">
                            <div className="d-flex flex-column w-100">
                              <TextField
                                fullWidth
                                name={`terms.${index}`}
                                variant="standard"
                                label="term"
                                onChange={(e) =>
                                  setFieldValue(
                                    `terms.${index}`,
                                    e.target.value
                                  )
                                }
                                value={values.terms[index]}
                              />
                              {/* <ErrorMessage name={`terms.${index}`}/> */}
                              <ErrorMessage name={`terms.${index}`}>
                                {(msg) => (
                                  <p style={{ color: "red" }}> {msg}</p>
                                )}
                              </ErrorMessage>
                            </div>
                            {/* if edit data is present then dont show it */}
                            {!visaTermsEdit && (
                              <div className="d-flex gap-3 align-items-center">
                                {index >=1  && (
                                  <button
                                    className="btn bg-danger text-white h-4"
                                    style={{ width: 120, height: 38 }}
                                    onClick={() => arrayHelpers.remove(index)} // insert an empty string at a position
                                  >
                                    - Term
                                  </button>
                                )}
                               { index ===0 &&<button
                                  className="btn bg-primary text-white h-4"
                                  style={{ width: 120, height: 38 }}
                                  type="button"
                                  onClick={() => arrayHelpers.push("")} // insert an empty string at a position
                                >
                                  + Term
                                </button>}
                              </div>
                            )}
                          </div>
                        ))
                      ) : (
                        <button
                          className="btn bg-primary text-white h-4"
                          style={{ width: 120, height: 38 }}
                          type="button"
                          onClick={() => arrayHelpers.push("")} // insert an empty string at a position
                        >
                          + Term
                        </button>
                      )}
                    </div>
                  )}
                />

                <div className="col-12 py-2 mt-3">
                  <div className="buttons d-flex"></div>
                </div>
              </div>
              <div className="d-flex w-50 align-self-center justify-content-center align-items-center gap-3">

              <button
                className="btn setup-btn"
                // onClick={{}}
                type="submit"
                >
                {loading ? <BtnLoader /> : visaTermsEdit ? "update" : "save"}
              </button>
            {visaTermsEdit &&  <button className="btn bg-danger text-white" onClick={e=>dispatch(termEditNull())}>Cancel</button>}
                </div>
            </form>
          );
        }}
      </Formik>
      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Terms & Condition Details"}
          data={visaTermsFiltered}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Terms;
