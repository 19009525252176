import React from "react";
import AddChequeBook from "../components/Finance/Accounts/BankBook/AddChequeBook";

function ChequeBookPage() {
  return (
    <div>
      <AddChequeBook />
    </div>
  );
}

export default ChequeBookPage;
