import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  newSupplier: [],
  editNewSupplier: null,
  loading: false,
  error: null,
  supplierCatagory: [],
  getAllSupplier: [],
  supplierPayble:"form"

};

// getting newSupplier data
export const asyncGetNewSupplier = createAsyncThunk(
  "asyncGetNewSupplier/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/supplier-list`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// posting addNewSupplier data
export const asyncPostAddNewSupplier = createAsyncThunk(
  "asyncPostAddNewSupplier/post",
  async (obj, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/supplier-list`, obj);
      dispatch(asyncGetNewSupplier());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// edit addNewSupplier data
export const asyncEditAddNewSupplier = createAsyncThunk(
  "asyncEditAddNewSupplier/edit",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/supplier-list/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// update addNewSupplier data
export const asyncUpdateAddNewSupplier = createAsyncThunk(
  "asyncUpdateAddNewSupplier/update",
  async ({ updObj, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/supplier-list/${id}`,
        updObj
      );
      dispatch(asyncGetNewSupplier());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// getting category data
export const asyncGetCategory = createAsyncThunk(
  "asyncGetCategory/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/supplier-category`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Get all Supplier Data by Status
export const asyncGetAllSupplierByStatus = createAsyncThunk(
  "asyncGetAllSupplierByStatus/get",
  async ({id}, { rejectWithValue, dispatch }) => {
    console.log("status",id);
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/supplier-list-by-status/${id}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const NewSupplierSlice = createSlice({
  name: "NewSupplierSlice",
  initialState,
  reducers: {
    editNewSupplierNull: (state) => {
      state.editNewSupplier = null;
    },
    setSupplierPaybleComponent: (state,action) => {
      state.supplierPayble = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getting NewSupplier data
    builder.addCase(asyncGetNewSupplier.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetNewSupplier.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.newSupplier = payload;
    });
    builder.addCase(asyncGetNewSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting newSupplier data
    builder.addCase(asyncPostAddNewSupplier.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostAddNewSupplier.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostAddNewSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // edit newSupplier data
    builder.addCase(asyncEditAddNewSupplier.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditAddNewSupplier.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editNewSupplier = payload;
    });
    builder.addCase(asyncEditAddNewSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //  update newSupplier data
    builder.addCase(asyncUpdateAddNewSupplier.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncUpdateAddNewSupplier.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.editvisaType = payload;
      }
    );
    builder.addCase(
      asyncUpdateAddNewSupplier.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    //getting Category data
    builder.addCase(asyncGetCategory.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.supplierCatagory = payload;
    });
    builder.addCase(asyncGetCategory.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get all Supplier by Status
    builder.addCase(
      asyncGetAllSupplierByStatus.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetAllSupplierByStatus.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.getAllSupplier = payload;
      }
    );
    builder.addCase(
      asyncGetAllSupplierByStatus.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { editNewSupplierNull, setSupplierPaybleComponent } =
  NewSupplierSlice.actions;
export default NewSupplierSlice.reducer;
