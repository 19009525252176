import React, { useEffect, useState } from "react";
import moment from "moment";
import { BsBagCheck, BsHandbag } from "react-icons/bs";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { useDispatch, useSelector } from "react-redux";
import { LightTooltip } from "./LightTooltip";
import {
  setExpandFlightDetails,
  setShowMoreOptions,
  set_Airline_filter_drp,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import SabreLogo from "../../../../assets/images/airlineLogo/SB.png";
import {
  MdOutlineFlightTakeoff,
  MdOutlineFlightLand,
  MdFlight,
  MdRemoveRedEye,
  MdOutlineRestaurant,
} from "react-icons/md";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AirlineSeatLegroomNormalIcon from "@mui/icons-material/AirlineSeatLegroomNormal";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  ExclusiveSetors,
  codeShareSabre,
} from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";

/////////////// sabre card /////////////
function SabreCard({ data, index, isExpanded, toggleDetail, selectedFare }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("Fare options");

  const [showError, setShowError] = useState(false);
  // get state from redux state
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { showMoreOption } = useSelector((state) => state.FlightSearch);
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  const sabreSupplier = newSupplier?.find((it) => it?.supp_name === "IATA BSP");
  const { user } = useSelector((state) => state.user);
  //use selector redux
  const { sabreBrandFeatures } = useSelector((state) => state.FlightSearch);
  //local storage data
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  // flight segment
  const fligthSegment =
    data?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption?.at(
      0
    )?.FlightSegment;
  // airline pricing
  const pricing = airlinePricing?.find((item) => {
    if (fligthSegment?.at(0)?.MarketingAirline?.Code === "PK") {
      return (
        item.bg_id === searchValues?.agent?.agent_grp_id &&
        item?.add_airline?.name === "PIA-GDS"
      );
    } else {
      return (
        item.bg_id === searchValues?.agent?.agent_grp_id &&
        item?.add_airline?.code_alpha ===
          fligthSegment?.at(0)?.MarketingAirline?.Code
      );
    }
  });

  //airline
  const airline = addAirLinesData?.find((it) => {
    if (fligthSegment?.at(0)?.MarketingAirline?.Code === "PK") {
      return it.name === "PIA-GDS";
    } else
      return it.code_alpha === fligthSegment?.at(0)?.MarketingAirline?.Code;
  });

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  // ptc breakdown
  const ptcBreakDown =
    data?.AirItineraryPricingInfo?.at(0)?.PTC_FareBreakdowns?.PTC_FareBreakdown;

  // Brand features of sabre flight
  const brandFeatureRef = data?.AirItineraryPricingInfo?.at(0)
    ?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)
    ?.PassengerFare?.TPA_Extensions?.FareComponents?.FareComponent?.at(
      0
    )?.BrandFeatureRef;

  const brandFeatures = brandFeatureRef?.flatMap((feature) => {
    const array = sabreBrandFeatures?.filter(
      (sb) => sb?.Id === feature?.FeatureId
    );
    return [...array];
  });

  // get seat available
  const seatAvailable = data?.AirItineraryPricingInfo?.at(
    0
  )?.FareInfos?.FareInfo?.reduce((min, current) =>
    current?.TPA_Extensions?.SeatsRemaining.Number <
    min?.TPA_Extensions?.SeatsRemaining.Number
      ? current
      : min
  );

  // adult fare
  const adultFareTotal = (fare) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing, seatAvailable?.FareReference),
        codeShareSabre(fligthSegment)
      )?.total_fare * searchValues?.adultsCount
    );
  };
  ///// child fare
  const childFareTotal = (fare) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing, seatAvailable?.FareReference),
        codeShareSabre(fligthSegment)
      )?.total_fare * searchValues?.childCount
    );
  };
  ///// infant fare
  const infantFareTotal = (fare) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(searchValues, pricing, seatAvailable?.FareReference),
        codeShareSabre(fligthSegment)
      )?.total_fare * searchValues?.infantNo
    );
  };
  //calculate the total fare price
  const totalFarePrice = (fare) => {
    let adultFare = adultFareTotal(fare?.at(0));
    let childFare = childFareTotal(fare?.at(1));
    let infantFare = infantFareTotal(fare?.at(2));

    let total = adultFare + ~~childFare + ~~infantFare;

    return TwoDecimalPoint(total);
  };

    // adult fare
    const adultDiscount = (fare) => {
      return (
        ~~pricingairline(
          airline,
          pricing,
          Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
          Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
          getSectorsName(locDeparture, locArrival),
          ExclusiveSetors(searchValues, pricing, seatAvailable?.FareReference),
          codeShareSabre(fligthSegment)
        )?.sp * searchValues?.adultsCount
      );
    };
    ///// child fare
    const childDiscount = (fare) => {
      return (
        ~~pricingairline(
          airline,
          pricing,
          Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
          Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
          getSectorsName(locDeparture, locArrival),
          ExclusiveSetors(searchValues, pricing, seatAvailable?.FareReference),
          codeShareSabre(fligthSegment)
        )?.sp * searchValues?.childCount
      );
    };
    ///// infant fare
    const infantDiscount = (fare) => {
      return (
        ~~pricingairline(
          airline,
          pricing,
          Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
          Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
          getSectorsName(locDeparture, locArrival),
          ExclusiveSetors(searchValues, pricing, seatAvailable?.FareReference),
          codeShareSabre(fligthSegment)
        )?.sp * searchValues?.infantNo
      );
    };

    //DiscountPrice
  const DiscountPrice = (fare) => {
    let adultFare = adultDiscount(fare?.at(0));
    let childFare = childDiscount(fare?.at(1));
    let infantFare = infantDiscount(fare?.at(2));

    let total = adultFare + ~~childFare + ~~infantFare;

    return TwoDecimalPoint(total);
  };

  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };
  /////////////////// scroll to show detail
  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }
  /// convert elapsed time to hours, minutes
  const elapsedTime = (time) => {
    const duration = moment.duration(time, "minutes");
    const hours = duration.hours().toString().padStart(2, "0");
    const minutes = duration.minutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  // calculate layover time between connected fligths
  function calculateTotalTimeDifference(time1, time2) {
    // Parse the input time values with the provided format
    const format = "YYYY-MM-DDTHH:mm:ss";
    const moment1 = moment(time1, format);
    const moment2 = moment(time2, format);

    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(moment1 - moment2);

    // Convert the time difference to a moment duration
    const duration = moment.duration(timeDifference);

    // Format the duration as hours and minutes
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }
  /////////////// if the pricing or airline is undefine will show error message
  const hideError = () => {
    setShowError(false);
  };

  useEffect(() => {
    if (showError) {
      const timeoutId = setTimeout(hideError, 5000); // 5000 milliseconds = 5 seconds
      // Clear the timeout if the component unmounts
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [showError]);

  // set airline filter
  useEffect(() => {
    dispatch(
      set_Airline_filter_drp(fligthSegment?.at(0).MarketingAirline?.Code)
    );
  }, [dispatch]);

  return (
    <div key={index}>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`sabre${index}`}
      >
        <div
          className="shadow cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`sabre${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="px-2">
                <div className="text-center">
                  <img
                    src={`/NEW_LOGO/${
                      fligthSegment?.at(0)?.MarketingAirline?.Code
                    }.png`}
                    alt="logo"
                    width={90}
                    height={90}
                  />
                </div>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>
                      {moment(fligthSegment?.at(0)?.DepartureDateTime).format(
                        "ddd DD-MMM-YY"
                      )}
                    </h6>
                    <h6 className="my-3">
                      {moment(fligthSegment?.at(0)?.DepartureDateTime).format(
                        "HH:mm"
                      )}
                    </h6>
                    <h6>
                      {fligthSegment?.at(0)?.DepartureAirport?.LocationCode} -{" "}
                      {getCity(locDeparture)}
                    </h6>
                  </div>
                </div>

                <div>
                  <h6 className="text-center">
                    {elapsedTime(
                      data?.AirItinerary?.OriginDestinationOptions?.OriginDestinationOption?.at(
                        0
                      )?.ElapsedTime
                    )}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">
                    {fligthSegment[2]
                      ? "Two Stop"
                      : fligthSegment[1]
                      ? "One Stop"
                      : "Non-Stop"}
                  </h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>
                  <div>
                    <h6>
                      {moment(
                        fligthSegment[fligthSegment?.length - 1]
                          ?.ArrivalDateTime
                      ).format("ddd DD-MMM-YY")}
                    </h6>
                    <h6 className="my-3">
                      {moment(
                        fligthSegment[fligthSegment?.length - 1]
                          ?.ArrivalDateTime
                      ).format("HH:mm")}
                    </h6>
                    <h6>
                      {
                        fligthSegment[fligthSegment?.length - 1]?.ArrivalAirport
                          ?.LocationCode
                      }{" "}
                      - {getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body pb-3">
                {selectedFare ? (
                  <HiEye
                    className="color_primary cursorpointer"
                    size={40}
                    onClick={() => {
                      toggleDetail(index);
                      setTimeout(() => {
                        !isExpanded && scrollToElement(`sabre${index}`);
                      }, 200);
                    }}
                  />
                ) : (
                  <>
                  {/* discount goes here */}
                  {/* <h6 className="text-danger">
                    {DiscountPrice(ptcBreakDown)}
                  </h6> */}

                    <h4>
                      {CurrencyCode()} {totalFarePrice(ptcBreakDown)}{" "}
                      <img src={SabreLogo} alt="" width={50} height={50} />
                    </h4>
                    <button
                      onClick={() => {
                        toggleDetail(index);
                        setTimeout(() => {
                          !isExpanded && scrollToElement(`sabre${index}`);
                        }, 200);
                      }}
                      className="button_style w-75 mb-2 cursorpointer"
                      type="button"
                    >
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                )}
                {data?.otherOptions?.length > 0 && !selectedFare && (
                  <button
                    className="bg-white color_primary border_primary p-2 px-4 fs-5 rounded-2 cursorpointer"
                    onClick={() =>
                      showMoreOption === null || showMoreOption !== index
                        ? dispatch(setShowMoreOptions(index))
                        : dispatch(setShowMoreOptions(null))
                    }
                  >
                    <div>
                      <UnfoldMoreIcon className="me-1" />
                      {showMoreOption === index
                        ? "Hide Options"
                        : "More Options"}
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* /////////////////////// flight detail ///////////// */}
        {isExpanded ? (
          <div className="p-4">
            {/* Tabs start */}
            {data?.TPA_Extensions?.AdditionalFares ? (
              <div className="d-flex mb-4 flight_details_tabs">
                {["Fare options", "Flight details"].map((item, index) => {
                  const active = selectedTab === item;
                  return (
                    <button
                      key={index}
                      onClick={() => setSelectedTab(item)}
                      className={active ? "active" : ""}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
            ) : null}
            {selectedTab === "Flight details" && (
              <div className="row rounded-2 shadow-sm border p-4">
                <div
                  className={`${
                    data?.TPA_Extensions?.AdditionalFares ? "col-12" : "col-9"
                  }`}
                >
                  {fligthSegment?.map((item, inx, array) => {
                    return (
                      <ConnectedFlight
                        item={item}
                        inx={inx}
                        calculateTotalTimeDifference={
                          calculateTotalTimeDifference
                        }
                        elapsedTime={elapsedTime}
                        fligthSegment={array}
                      />
                    );
                  })}
                </div>
                {data?.TPA_Extensions?.AdditionalFares ? null : (
                  <div className="col-3 d-flex">
                    <div className="h-auto border_primary" />
                    <div className="d-flex flex-column justify-content-end ps-5">
                      <h4 className="color_primary">
                        {ptcBreakDown
                          ?.at(0)
                          ?.PassengerFare?.TPA_Extensions?.FareComponents?.FareComponent?.at(
                            0
                          )?.BrandName || "ECONOMY"}{" "}
                        ({seatAvailable?.FareReference})
                      </h4>
                      <h6 className="text-danger">
                        <AirlineSeatLegroomNormalIcon />{" "}
                        {seatAvailable?.TPA_Extensions?.SeatsRemaining?.Number}{" "}
                        Seat Left
                      </h6>
                      {ptcBreakDown[0]?.PassengerFare?.PenaltiesInfo?.Penalty?.filter(
                        (penalty) => penalty?.Type === "Refund"
                      )?.filter((refund) => refund?.Refundable === true)
                        ?.length > 0 ? (
                        <div className="refundable my-3">Refundable</div>
                      ) : (
                        <div className="non_refundable my-3">
                          Non-Refundable
                        </div>
                      )}
                      <div className="d-flex align-items-end color_primary fs-6 pt-2">
                        <BsHandbag className="me-3" />{" "}
                        <div>7 kg Hand Carry</div>
                      </div>
                      <div className="d-flex align-items-end color_primary my-3 fs-6">
                        <BsBagCheck className="me-3" />{" "}
                        <div>
                          {data?.AirItineraryPricingInfo?.at(0)
                            ?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)
                            ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                              0
                            )
                            ?.Allowance?.at(0)?.Weight ||
                            data?.AirItineraryPricingInfo?.at(0)
                              ?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)
                              ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                                0
                              )
                              ?.Allowance?.at(0)?.Pieces ||
                            0}{" "}
                          {data?.AirItineraryPricingInfo?.at(0)
                            ?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)
                            ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                              0
                            )
                            ?.Allowance?.at(0)?.Weight
                            ? data?.AirItineraryPricingInfo?.at(0)
                                ?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)
                                ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                                  0
                                )
                                ?.Allowance?.at(0)?.Unit || "kg"
                            : "Pieces"}{" "}
                          Checked Bag
                        </div>
                      </div>
                      {/* refund and exchange dialog  */}
                      <RefundExchange
                        penalties={
                          data?.AirItineraryPricingInfo?.at(
                            0
                          )?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)
                            ?.PassengerFare?.PenaltiesInfo?.Penalty
                        }
                      />
                      {!selectedFare && (
                        <div className="mt-4 ">
                          <LightTooltip
                            title={
                              <div className="p-3">
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex me-4">
                                    <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                    <h5>* {searchValues?.adultsCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyCode()}{" "}
                                    {TwoDecimalPoint(
                                      adultFareTotal(ptcBreakDown?.at(0))
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end my-2">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                    <h5>* {searchValues?.childCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyCode()}{" "}
                                    {TwoDecimalPoint(
                                      childFareTotal(ptcBreakDown?.at(1))
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                    <h5>* {searchValues?.infantNo}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyCode()}{" "}
                                    {TwoDecimalPoint(
                                      infantFareTotal(ptcBreakDown?.at(2))
                                    )}
                                  </h6>
                                </div>
                              </div>
                            }
                            placement="top"
                          >
                            <button
                              type="button"
                              className="button_style px-4"
                              onClick={() => {
                                dispatch(
                                  setselectedFlight({
                                    flight: data,
                                    price: totalFarePrice(ptcBreakDown),
                                    adultBaseFare:
                                      ~~ptcBreakDown?.at(0)?.PassengerFare
                                        ?.EquivFare?.Amount,
                                    adultTaxes:
                                      ~~ptcBreakDown?.at(0)?.PassengerFare
                                        ?.Taxes?.TotalTax?.Amount,
                                    childBaseFare:
                                      ~~ptcBreakDown[1]?.PassengerFare
                                        ?.EquivFare?.Amount,
                                    childTaxes:
                                      ~~ptcBreakDown[1]?.PassengerFare?.Taxes
                                        ?.TotalTax?.Amount,
                                    infantBaseFare:
                                      ~~ptcBreakDown[2]?.PassengerFare
                                        ?.EquivFare?.Amount,
                                    infantTaxes:
                                      ~~ptcBreakDown[2]?.PassengerFare?.Taxes
                                        ?.TotalTax?.Amount,
                                    baggageName: `${searchValues?.ticket_class?.toUpperCase()} (${
                                      seatAvailable?.FareReference
                                    })`,
                                    logo: "Sabre",
                                    code: fligthSegment?.at(0)?.MarketingAirline
                                      ?.Code,

                                    booking: {
                                      gds: "SABRE", // should be gds code
                                      agent: {
                                        name:
                                          searchValues?.agent?.agent_name || "", // should be full name having space in between
                                        email: searchValues?.agent?.email || "", // should be in proper email form
                                        phone: searchValues?.agent?.phone || "", // should be without country code
                                        country_code: "92", // country code without + sign
                                      },
                                      paxes: [
                                        {
                                          title: "", // MR | MS | MRS | MSTR | INF
                                          first_name: "",
                                          last_name: "",
                                          nationality: "",
                                          DOB: "",
                                          doc_type: "", // CNIC | Passport
                                          doc_no: "", // 11111-1111111-1 | asdf12345
                                          doc_issue_country: "",
                                          doc_expiry: "",
                                          pax_type: "", // ADLT | CHLD | INFT
                                        },
                                      ],
                                      segments: fligthSegment?.map(
                                        (segment) => {
                                          return {
                                            departure_date:
                                              segment?.DepartureDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            departure_time:
                                              segment?.DepartureDateTime?.split(
                                                "T"
                                              )[1],
                                            arrival_date:
                                              segment?.ArrivalDateTime?.split(
                                                "T"
                                              )?.at(0),
                                            arrival_time:
                                              segment?.ArrivalDateTime?.split(
                                                "T"
                                              )[1],
                                            origin_terminal:
                                              segment?.DepartureAirport
                                                ?.TerminalID || "", // in case of any
                                            desti_terminal:
                                              segment?.ArrivalAirport
                                                ?.TerminalID || "", // in case of any
                                            flight_number:
                                              segment?.FlightNumber,
                                            number_in_party:
                                              searchValues?.adultsCount +
                                              searchValues?.childCount, // only mandatory for sabre
                                            res_book_desig_code:
                                              segment?.ResBookDesigCode, // only mandatory for sabre
                                            status: "NN",
                                            des_location:
                                              segment?.ArrivalAirport
                                                ?.LocationCode, // only mandatory for sabre
                                            marketing_airline:
                                              segment?.MarketingAirline?.Code, // only mandatory for sabre
                                            orig_location:
                                              segment?.DepartureAirport
                                                ?.LocationCode,
                                            marriage_grp: segment?.MarriageGrp, // only mandatory for sabre
                                            cabin_class: "Economy",
                                            segment_type: getSectorsName(
                                              segment?.DepartureAirport
                                                ?.LocationCode,
                                              segment?.ArrivalAirport
                                                ?.LocationCode
                                            ), // dom | siti | soto | group
                                          };
                                        }
                                      ),

                                      tp_ur_code: "", // mandatory for travelport only
                                      platting_carrier: "", // mandatory for travelport only
                                      tp_ssr_verssion: 3, // mandatory for travelport only
                                      tp_trace_id: "", // mandatory for travelport only
                                      adult_count: searchValues?.adultsCount,
                                      child_count: searchValues?.childCount,
                                      infant_count: searchValues?.infantNo,

                                      ttl_agent_fare:
                                        totalFarePrice(ptcBreakDown), // "calculated price total"
                                      user: { id: user?.user_id }, // logged in user id
                                      agent_acc_id:
                                        searchValues?.agent?.acc_id || 0, // agent id in case of selected other wise logged in user id
                                      supplier_acc_id:
                                        sabreSupplier?.acc_id || 0, // supplier id
                                    },
                                  })
                                );
                                if (
                                  searchValues?.agent === "" ||
                                  (pricing && airline)
                                ) {
                                  dispatch(setExpandFlightDetails(null));
                                  navigate("/check-out");
                                } else {
                                  setShowError(true);
                                }
                              }}
                            >
                              {CurrencyCode()} {totalFarePrice(ptcBreakDown)}
                            </button>
                          </LightTooltip>

                          {showError ? (
                            <div className="text-danger mt-2">
                              <p>
                                Pricing method is not define for this airline.
                                Call to our support team for assistance.
                              </p>
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* /////// additional fares /////// */}
            {data?.TPA_Extensions?.AdditionalFares &&
            selectedTab === "Fare options" ? (
              <div className="row rounded-2 shadow-sm border p-4 mt-4">
                <AdditionalFare
                  Data={data}
                  airline={airline}
                  pricing={pricing}
                  fligthSegment={fligthSegment}
                  availability={searchValues}
                  showError={showError}
                  setShowError={setShowError}
                  sabreSupplier={sabreSupplier}
                  user={user}
                  selectedFare={selectedFare}
                  brandFeatures={brandFeatures}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SabreCard;

const ConnectedFlight = ({
  item,
  inx,
  calculateTotalTimeDifference,
  elapsedTime,
  fligthSegment,
}) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");
  //api call for fatching airport name
  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${item?.DepartureAirport?.LocationCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    const fetchDataArr = async () => {
      try {
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${item?.ArrivalAirport?.LocationCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setArrairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchDataDep(); // Call the fetchData function
    fetchDataArr(); // Call the fetchData function
  }, []);

  return (
    <div key={inx}>
      <div className="row align-items-center">
        <div className="col-2">
          <img
            src={`/NEW_LOGO/${item?.MarketingAirline?.Code}.png`}
            alt="logo"
            width={70}
            height={70}
          />
          <h6 className="mt-3 ms-2">
            {item?.MarketingAirline?.Code} {item?.FlightNumber}
          </h6>
          {codeShareSabre(fligthSegment) ? (
            <h6>Operated by : {item?.OperatingAirline?.Code}</h6>
          ) : null}
        </div>
        <div className="col-10 d-flex justify-content-between align-items-center">
          <div className="col-5 d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div className="">
              <h6>{moment(item?.DepartureDateTime).format("ddd DD-MMM-YY")}</h6>
              <h6 className="my-3">
                {moment(item?.DepartureDateTime).format("HH:mm")}
              </h6>
              <h6>
                {item?.DepartureAirport?.LocationCode} - {airport_name}
              </h6>
              {item?.DepartureAirport?.TerminalID && (
                <h6 className="color_primary">
                  (Terminal-{item?.DepartureAirport?.TerminalID})
                </h6>
              )}
            </div>
          </div>

          <div>
            <div className="d-flex">
              <MdFlight className="color_primary fs-5 rotate-right" />
              <h6 className="text-center">Trip Time</h6>
            </div>
            <div className="border_rounded mb-2" />
            <h6 className="text-center">{elapsedTime(item?.ElapsedTime)}</h6>
          </div>
          <div className="col-5 d-flex align-items-center ps-4">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>{moment(item?.ArrivalDateTime).format("ddd DD-MMM-YY")}</h6>
              <h6 className="my-3">
                {moment(item?.ArrivalDateTime).format("HH:mm")}
              </h6>
              <h6>
                {item?.ArrivalAirport?.LocationCode} - {arr_airport_name}
              </h6>
              {item?.ArrivalAirport?.TerminalID && (
                <h6 className="color_primary">
                  (Terminal-{item?.ArrivalAirport?.TerminalID})
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
      {inx < fligthSegment.length - 1 && (
        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <div className="border_rounded w-25" />
          <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
            <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
            <h6 className="pt-2">
              Layover time:{" "}
              <span className="fs-5">
                {" "}
                {calculateTotalTimeDifference(
                  item?.ArrivalDateTime,
                  fligthSegment[inx + 1]?.DepartureDateTime
                )}
              </span>
            </h6>
          </div>
          <div className="border_rounded w-25" />
        </div>
      )}
    </div>
  );
};

const AdditionalFare = ({
  Data,
  airline,
  pricing,
  fligthSegment,
  availability,
  showError,
  setShowError,
  sabreSupplier,
  user,
  selectedFare,
  brandFeatures,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Extracting AirItineraryPricingInfo from the original array
  const originalArray = Data?.AirItineraryPricingInfo;
  // Extracting AirItineraryPricingInfo from AdditionalFares
  const additionalFaresArray = Data?.TPA_Extensions?.AdditionalFares?.map(
    (additionalFare) => additionalFare.AirItineraryPricingInfo
  );
  // Combining both arrays
  const newFaresArray = originalArray.concat(additionalFaresArray);

  // get seat available
  const seatAvailable = (item) =>
    item?.FareInfos?.FareInfo?.reduce((min, current) =>
      current?.TPA_Extensions?.SeatsRemaining.Number <
      min?.TPA_Extensions?.SeatsRemaining.Number
        ? current
        : min
    );
  // adult fare
  const adultFareTotal = (fare, item) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(
          availability?.locationDep,
          availability?.locationArrival
        ),
        ExclusiveSetors(
          availability,
          pricing,
          seatAvailable(item)?.FareReference
        ),
        codeShareSabre(fligthSegment)
      )?.total_fare * availability?.adultsCount
    );
  };
  ///// child fare
  const childFareTotal = (fare, item) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(
          availability?.locationDep,
          availability?.locationArrival
        ),
        ExclusiveSetors(
          availability,
          pricing,
          seatAvailable(item)?.FareReference
        ),
        codeShareSabre(fligthSegment)
      )?.total_fare * availability?.childCount
    );
  };
  ///// infant fare
  const infantFareTotal = (fare, item) => {
    return (
      ~~pricingairline(
        airline,
        pricing,
        Number(CurrencyExchange(fare?.PassengerFare?.EquivFare?.Amount)),
        Number(CurrencyExchange(fare?.PassengerFare?.Taxes?.TotalTax?.Amount)),
        getSectorsName(
          availability?.locationDep,
          availability?.locationArrival
        ),
        ExclusiveSetors(
          availability,
          pricing,
          seatAvailable(item)?.FareReference
        ),
        codeShareSabre(fligthSegment)
      )?.total_fare * availability?.infantNo
    );
  };
  //calculate the total fare price
  const totalFarePrice = (fare) => {
    let adultFare = adultFareTotal(fare?.at(0));
    let childFare = childFareTotal(fare?.at(1));
    let infantFare = infantFareTotal(fare?.at(2));

    let total = adultFare + ~~childFare + ~~infantFare;

    return TwoDecimalPoint(total);
  };

  return (
    <div className="multi_fares_box pb-4">
      {newFaresArray
        .filter((elemFilter) =>
          selectedFare
            ? elemFilter?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(
                0
              )?.PassengerFare?.TPA_Extensions?.FareComponents?.FareComponent?.at(
                0
              )?.BrandName === selectedFare
            : elemFilter?.PTC_FareBreakdowns
        )
        ?.map((item, index) => {
          const ptc_breakdown = item?.PTC_FareBreakdowns?.PTC_FareBreakdown;
          const brandName = ptc_breakdown
            ?.at(0)
            ?.PassengerFare?.TPA_Extensions?.FareComponents?.FareComponent?.at(
              0
            )?.BrandName;
          return (
            <div className="col-3 px-4" key={index}>
              <div className="fs-5 color_primary fw-bold">{brandName} </div>
              <div className="text-danger my-2">
                <AirlineSeatLegroomNormalIcon />{" "}
                {seatAvailable(item)?.TPA_Extensions?.SeatsRemaining?.Number}{" "}
                Seat Left
              </div>
              {item?.PTC_FareBreakdowns?.PTC_FareBreakdown?.at(0)?.Endorsements
                ?.NonRefundableIndicator ? (
                <div className="non_refundable my-1">Non-Refundable</div>
              ) : (
                <div className="refundable my-1">Refundable</div>
              )}
              <hr />

              <div>
                <p className="fs-6 color_primary fw-bold">Baggage</p>
                <div className="d-flex align-items-center gap-2">
                  <BsBagCheck size={20} className="color_primary" />
                  <div>
                    {ptc_breakdown
                      .at(0)
                      ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                        0
                      )
                      ?.Allowance?.at(0)?.Weight ||
                      ptc_breakdown
                        ?.at(0)
                        ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                          0
                        )
                        ?.Allowance?.at(0)?.Pieces ||
                      0}{" "}
                    {ptc_breakdown
                      .at(0)
                      ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                        0
                      )
                      ?.Allowance?.at(0)?.Weight
                      ? ptc_breakdown
                          ?.at(0)
                          ?.PassengerFare?.TPA_Extensions?.BaggageInformationList?.BaggageInformation?.at(
                            0
                          )
                          ?.Allowance?.at(0)?.Unit || "kg"
                      : "Pieces"}{" "}
                    Checked Bag
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <MdOutlineRestaurant size={20} className="color_primary" />
                  <div>{seatAvailable(item)?.TPA_Extensions?.Meal?.Code}</div>
                </div>
              </div>
              <hr />
              {/* refund and exchange dialog  */}
              <RefundExchange
                penalties={
                  ptc_breakdown?.at(0)?.PassengerFare?.PenaltiesInfo?.Penalty
                }
              />
              {/* {brandFeatures?.length > 0 && (
                <BrandFeatures brandFeatures={brandFeatures} />
              )} */}
              {selectedFare ? null : (
                <>
                  {" "}
                  <hr />
                  <LightTooltip
                    title={
                      <div className="p-3">
                        <div className="d-flex justify-content-between align-items-end">
                          <div className="d-flex me-4">
                            <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                            <h5>* {availability?.adultsCount}</h5>
                          </div>
                          <h6 style={{ minWidth: "7rem" }}>
                            {CurrencyCode()}{" "}
                            {TwoDecimalPoint(
                              adultFareTotal(ptc_breakdown?.at(0), item)
                            )}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-end my-2">
                          <div className="d-flex">
                            <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                            <h5>* {availability?.childCount}</h5>
                          </div>
                          <h6 style={{ minWidth: "7rem" }}>
                            {CurrencyCode()}{" "}
                            {TwoDecimalPoint(
                              childFareTotal(ptc_breakdown?.at(1), item)
                            )}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-end">
                          <div className="d-flex">
                            <h5 style={{ minWidth: "3rem" }}>INF</h5>
                            <h5>* {availability?.infantNo}</h5>
                          </div>
                          <h6 style={{ minWidth: "7rem" }}>
                            {CurrencyCode()}{" "}
                            {TwoDecimalPoint(
                              infantFareTotal(ptc_breakdown?.at(2), item)
                            )}
                          </h6>
                        </div>
                      </div>
                    }
                    placement="top"
                  >
                    <button
                      type="button"
                      className="button_style px-4"
                      onClick={() => {
                        dispatch(
                          setselectedFlight({
                            flight: Data,
                            price: totalFarePrice(ptc_breakdown),
                            adultBaseFare:
                              ~~ptc_breakdown?.at(0)?.PassengerFare?.EquivFare
                                ?.Amount,
                            adultTaxes:
                              ~~ptc_breakdown?.at(0)?.PassengerFare?.Taxes
                                ?.TotalTax?.Amount,
                            childBaseFare:
                              ~~ptc_breakdown[1]?.PassengerFare?.EquivFare
                                ?.Amount,
                            childTaxes:
                              ~~ptc_breakdown[1]?.PassengerFare?.Taxes?.TotalTax
                                ?.Amount,
                            infantBaseFare:
                              ~~ptc_breakdown[2]?.PassengerFare?.EquivFare
                                ?.Amount,
                            infantTaxes:
                              ~~ptc_breakdown[2]?.PassengerFare?.Taxes?.TotalTax
                                ?.Amount,
                            baggageName: brandName,
                            logo: "Sabre",
                            code: fligthSegment?.at(0)?.MarketingAirline?.Code,

                            booking: {
                              gds: "SABRE", // should be gds code
                              agent: {
                                name: availability?.agent?.agent_name || "", // should be full name having space in between
                                email: availability?.agent?.email || "", // should be in proper email form
                                phone: availability?.agent?.phone || "", // should be without country code
                                country_code: "92", // country code without + sign
                              },
                              paxes: [
                                {
                                  title: "", // MR | MS | MRS | MSTR | INF
                                  first_name: "",
                                  last_name: "",
                                  nationality: "",
                                  DOB: "",
                                  doc_type: "", // CNIC | Passport
                                  doc_no: "", // 11111-1111111-1 | asdf12345
                                  doc_issue_country: "",
                                  doc_expiry: "",
                                  pax_type: "", // ADLT | CHLD | INFT
                                },
                              ],
                              segments: fligthSegment?.map((segment) => {
                                return {
                                  departure_date:
                                    segment?.DepartureDateTime?.split("T")?.at(
                                      0
                                    ),
                                  departure_time:
                                    segment?.DepartureDateTime?.split("T")[1],
                                  arrival_date:
                                    segment?.ArrivalDateTime?.split("T")?.at(0),
                                  arrival_time:
                                    segment?.ArrivalDateTime?.split("T")[1],
                                  origin_terminal:
                                    segment?.DepartureAirport?.TerminalID || "", // in case of any
                                  desti_terminal:
                                    segment?.ArrivalAirport?.TerminalID || "", // in case of any
                                  flight_number: segment?.FlightNumber,
                                  number_in_party:
                                    availability?.adultsCount +
                                    availability?.childCount, // only mandatory for sabre
                                  res_book_desig_code:
                                    segment?.ResBookDesigCode, // only mandatory for sabre
                                  status: "NN",
                                  des_location:
                                    segment?.ArrivalAirport?.LocationCode, // only mandatory for sabre
                                  marketing_airline:
                                    segment?.MarketingAirline?.Code, // only mandatory for sabre
                                  orig_location:
                                    segment?.DepartureAirport?.LocationCode,
                                  marriage_grp: segment?.MarriageGrp, // only mandatory for sabre
                                  cabin_class: "Economy",
                                  segment_type: getSectorsName(
                                    segment?.DepartureAirport?.LocationCode,
                                    segment?.ArrivalAirport?.LocationCode
                                  ), // dom | siti | soto | group
                                };
                              }),

                              tp_ur_code: "", // mandatory for travelport only
                              platting_carrier: "", // mandatory for travelport only
                              tp_ssr_verssion: 3, // mandatory for travelport only
                              tp_trace_id: "", // mandatory for travelport only
                              adult_count: availability?.adultsCount,
                              child_count: availability?.childCount,
                              infant_count: availability?.infantNo,

                              ttl_agent_fare: totalFarePrice(ptc_breakdown), // "calculated price total"
                              user: { id: user?.user_id }, // logged in user id
                              agent_acc_id: availability?.agent?.acc_id || 0, // agent id in case of selected other wise logged in user id
                              supplier_acc_id: sabreSupplier?.acc_id || 0, // supplier id
                            },
                          })
                        );
                        if (
                          availability?.agent === "" ||
                          (pricing && airline)
                        ) {
                          dispatch(setExpandFlightDetails(null));
                          navigate("/check-out");
                        } else {
                          setShowError(index);
                        }
                      }}
                    >
                      {CurrencyCode()} {totalFarePrice(ptc_breakdown)}
                    </button>
                  </LightTooltip>
                </>
              )}
              {showError === index ? (
                <div className="text-danger mt-2">
                  Pricing method is not define for this airline. Call to our
                  support team for assistance.
                </div>
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

const RefundExchange = ({ penalties }) => {
  const refund_array = penalties?.filter((item) => item.Type === "Refund");
  const exchange_array = penalties?.filter((item) => item.Type === "Exchange");
  const isNonRefundable = refund_array?.find((it) => it.Refundable === false);
  const isNonExchangable = exchange_array?.find(
    (it) => it.Changeable === false
  );
  return (
    <div>
      <p className="fs-6 color_primary fw-bold">Refund & Exchange</p>
      <LightTooltip
        title={
          <div className="p-3">
            <h4>Refund & Exchange</h4>
            <h6>Penalty will charged per passenger wise.</h6>
            <div className="text-danger font-italic">
              for further clarification contact with Airline Or Call our support
              team.
            </div>

            <div className="d-flex justify-content-between align-item-start w-fit mt-4">
              <div className="me-5">
                <h4>Exchange</h4>
                {exchange_array?.map((it, ind) => (
                  <h6 key={ind}>
                    {it.Applicability}:{" "}
                    {it?.Changeable
                      ? `${it.CurrencyCode} ${it.Amount}`
                      : "NON-CHG"}
                  </h6>
                ))}
              </div>
              <div>
                <h4>Refund</h4>
                {refund_array?.map((it, ind) => (
                  <h6 key={ind}>
                    {it.Applicability}:{" "}
                    {it?.Refundable
                      ? `${it.CurrencyCode} ${it.Amount}`
                      : "NON-REF"}
                  </h6>
                ))}
              </div>
            </div>
          </div>
        }
        placement="top"
      >
        <div
          className="d-flex align-items-start gap-2 cursorpointer"
          style={{ width: "fit-content" }}
        >
          <MdRemoveRedEye size={24} className="color_primary" />
          <p>View</p>
        </div>
      </LightTooltip>
    </div>
  );
};

// Brand features

const BrandFeatures = ({ brandFeatures }) => {
  return (
    <div>
      <p className="fs-6 color_primary fw-bold border-2">Brand Features</p>
      <LightTooltip
        title={
          <div className="p-3">
            <h4>Brand features</h4>

            <div className="me-5" style={{ width: 600 }}>
              <div className="d-flex justify-content-between flex-wrap align-item-start mt-4">
                {brandFeatures?.map((feature, ind) => (
                  <div key={ind} style={{ width: 180 }} className="mb-2">
                    <h6 key={ind}>{feature.CommercialName}</h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        placement="top"
      >
        <div
          className="d-flex align-items-start gap-2 cursorpointer"
          style={{ width: "fit-content" }}
        >
          <MdRemoveRedEye size={24} className="color_primary" />
          <p>View</p>
        </div>
      </LightTooltip>
    </div>
  );
};
