import React from 'react';
import Corporate from '../components/Setup/Payments/CorporatePayment'

const CorporatePayment = () => {
  return (
    <div className='Full_height'>
      <Corporate />
    </div>
  )
}

export default CorporatePayment