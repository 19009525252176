import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetLandingAboutPage } from "../../redux/features/landingPage/landingPageSlice";
import { apiUrl } from "../../Utils/configUrl";

const Aboutus = () => {
  const dispatch = useDispatch();

  const { aboutUsData } = useSelector((state) => state?.landingPage);

  useEffect(() => {
    dispatch(asyncGetLandingAboutPage());
  }, [dispatch]);

  return (
    <div className="container my-5 " id="about-us">
      <div className="row justify-content-evenly">
        <div className="col-12 col-lg-6 order-2 order-md-1">
          <img
            src={`${apiUrl}/${aboutUsData?.image}`}
            alt="about-us"
            className=""
            style={{
              width: "100%",
              height: "520px",
            }}
          />
        </div>
        <div className="col-12 col-lg-6 mt-4 mt-lg-0 order-1 order-md-2">
          <div className="d-flex flex-column about-one__right-text">
            <h2 className="text-uppercase pb-2 ">{aboutUsData?.heading}</h2>
            <p className="">{aboutUsData?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
