import React, { useState } from 'react';
import { Document, Page,pdfjs } from 'react-pdf';
import PdfFile from '../Home/pdfFile.pdf'
export default function CircularPdf() {
	
pdfjs.GlobalWorkerOptions.workerSrc =
`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const [numPages, setNumPages] = useState(null);
const [pageNumber, setPageNumber] = useState(1);

/*When document gets loaded successfully*/
function onDocumentLoadSuccess({ numPages }) {
	setNumPages(numPages);
	setPageNumber(1);
}
function changePage(offset) {
	setPageNumber(prevPageNumber => prevPageNumber + offset);
}

function previousPage() {
	changePage(-1);
}

function nextPage() {
	changePage(1);
}

return (
	<>
	<div className="main">
     <Document
		file={PdfFile}
		onLoadSuccess={onDocumentLoadSuccess}
        >
		<Page pageNumber={pageNumber} />
	</Document>

	</div>
	</>
);
}


