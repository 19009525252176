import React, { useState, useEffect } from "react";
import DeleteModal from "../../DeleteModal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { options } from "../../../Utils/ColumnOptions";

import * as yup from "yup";
import {
  asyncDeleteTransferSupplier,
  asyncEditTransferSupplier,
  asyncGetTransferSupplier,
  asyncPostVisaTransfer,
  asyncUpdateTransferSupplier,
  editTransferSupplierNull,
} from "../../../redux/features/umrah/TransferSupplierSlice";
import { useDispatch, useSelector } from "react-redux";
import { appImgUrl } from "../../../Utils/configUrl";
import { Form, Formik } from "formik";
import ErrorMessage from "../../AppForm/ErrorMessage";
import BtnLoader from "../../AppForm/BtnLoader";

const TransferSupplier = () => {
  const dispatch = useDispatch();
  
  const { transferSupData, editTransferSupplier, loading } = useSelector(
    (state) => state.transferSupplier
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [preview, setPreview] = useState();
  const [rndState, setrndState] = useState();



  useEffect(() => {
    dispatch(asyncGetTransferSupplier());

    setrndState(new Date());
  }, [editTransferSupplier,dispatch]);

  console.log(transferSupData?.transfer_supplier);
  const validationSchem = yup.object().shape({
    Supplier_id: yup.number().required("Supplier_id required"),
    contact: yup.number().required("contact required"),
    address: yup.string().required("address required"),
    logo: !editTransferSupplier?.logo && yup.string().required("logo required"),
  });

  const initialValues = {
    Supplier_id: editTransferSupplier
      ? editTransferSupplier?.Supplier_id
      : 0 || transferSupData?.transfer_supplier?.length
      ? transferSupData?.transfer_supplier[0].id
      : 0,
    contact: editTransferSupplier?.contact || "",

    previous_logo: editTransferSupplier?.logo || "",

    address: editTransferSupplier?.address || "",
    logo: "",
  };

  const columns = [
    {
      name: "id",
      label: "S.No",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "name",
      label: "name",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "address",
      label: "address",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "contact",
      label: "contact no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Action",
      name: "logo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <img
            style={{
              height: 80,
              width: 120,
              left: 100,
              bottom: -70,
              zIndex: 120,
            }}
            alt=""
              src={`${appImgUrl}/TransferSupplier/${value}`}
              className="table-row-image"
            />
          );
        },
      },
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setPreview(null);
                  window.scroll({ top: 0 });
                  dispatch(asyncEditTransferSupplier(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowDeleteModal(true);
                  setModalData(id);
                }}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="col-12 col-sm-7 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Umrah Transfer Supplier</p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchem}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, action) => {
          console.log(values);

          if (editTransferSupplier) {
            const fd = new FormData();
            fd.append("id", editTransferSupplier?.id);
            fd.append("Supplier_id", values.Supplier_id);
            fd.append("contact", values.contact);
            fd.append("previous_logo", values.previous_logo);
            fd.append("address", values.address);
            values.logo && fd.append("logo", values.logo);
            dispatch(asyncUpdateTransferSupplier(fd));
            dispatch(editTransferSupplierNull());
            setrndState(new Date());
            setPreview(null);
          } else {
            const fd = new FormData();
            fd.append("Supplier_id", values.Supplier_id);
            fd.append("contact", values.contact);
            fd.append("address", values.address);
            fd.append("logo", values.logo);
            dispatch(asyncPostVisaTransfer(fd));
            action.resetForm({ values: "" });
            setPreview(null);
            setrndState(new Date());
          }
        }}
      >
        {({ values, handleChange, errors, setFieldValue }) => {
          console.log(errors);
          return (
            <Form className="col-12 px-4 d-flex flex-column justify-content-center">
              <div className="col-12 mt-4 d-flex">
                <div className="col-5 me-5">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select supplier
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={values.Supplier_id}
                      onChange={handleChange}
                      name="Supplier_id"
                    >
                      {transferSupData?.transfer_supplier?.map((sup) => (
                        <MenuItem value={sup.id} key={sup.id}>
                          {sup.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage errors={errors && errors.Supplier_id} />
                  </FormControl>
                </div>
                <div className="col-sm-5 d-flex flex-column me-5">
                  <TextField
                    id="standard-basic"
                    label="Contact Number"
                    variant="standard"
                    name="contact"
                    value={values.contact}
                    onChange={handleChange}
                    className="col-sm-12"
                  />
                  <ErrorMessage errors={errors && errors.contact} />
                </div>
              </div>
              <div className="col-12 mt-4 d-flex">
                <div className="col-sm-5 d-flex flex-column me-5 position-relative">

                  {editTransferSupplier?.logo && (
                    <img
                    // the data of the image is taken from this img src for edewting with its name attributr
                      alt="editImag"
                      name="previous_logo"
                      accept="image/*"
                      // onChange={(e)=>setFieldValue("previous_logo", e.currentTarget.files[0])}
                      src={`${appImgUrl}/TransferSupplier/${editTransferSupplier?.logo}`}
                      style={{
                        height: 80,
                        width: 120,
                        left: 100,
                        bottom: -70,
                        zIndex: 120,
                      }}
                      className="position-absolute "
                    />
                  )}
                  {preview && (
                    <img
                      alt="prevImg"
                      src={preview}
                      style={{
                        height: 80,
                        width: 120,
                        left: 100,
                        bottom: -70,
                        zIndex: 120,
                      }}
                      className="position-absolute "
                    />
                  )}

                  <label htmlFor="" className="mb-2">
                    Logo
                  </label>
                  <TextField
                    type={"file"}
                    key={rndState || ""}
                    name="logo"
                    onChange={(e) => {
                      const objectUrl = URL.createObjectURL(e.target.files[0]);
                      setPreview(objectUrl);
                      setFieldValue("logo", e.currentTarget.files[0]);
                    }}
                    variant="standard"
                    className="col-sm-12"
                  />
                  <ErrorMessage errors={errors && errors.logo} />
                  {/* this is hidden file for previous_logo image if we dont add new then this old img will go to backend */}
                  {/* <TextField
                    type={"file"}
                    name="previous_logo"
                    onChange={(e) => {
                      // setFieldValue("previous_logo", e.currentTarget.files[0]);
                    }}
                    hidden={true}
                  /> */}
                </div>
                <div className="mb-2 col-sm-5">
                  <TextField
                    className="col-sm-12 mt-2"
                    name="address"
                    variant="standard"
                    label="Address"
                    value={values.address}
                    onChange={handleChange}
                  />
                  <ErrorMessage errors={errors && errors.address} />
                </div>
              </div>
              <div className="d-flex align-self-center gap-3">
                <button className="btn setup-btn mt-2" type="submit">
                  {loading ? (
                    <BtnLoader />
                  ) : editTransferSupplier ? (
                    "update"
                  ) : (
                    "save"
                  )}
                </button>
                {editTransferSupplier && (
                  <button
                    className="btn setup-btn mt-2 bg-danger"
                    type="submit"
                    onClick={() => dispatch(editTransferSupplierNull())}
                  >
                    Cancel
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className="col-12 mt-5 px-4">
        <DeleteModal
          show={showDeleteModal}
          setShow={setShowDeleteModal}
          rowId={modalData}
          onClick={(e) => {
            setShowDeleteModal(false);
            dispatch(asyncDeleteTransferSupplier(modalData));
          }}
        />
        <MUIDataTable
          className="muidatatable"
          title={"Umrah Transfer Supplier Details"}
          data={transferSupData?.TransferSupplierModel}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default TransferSupplier;
