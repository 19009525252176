import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { MdClose } from "react-icons/md";
import { asyncGetNewSupplier } from "../../../../redux/features/supplier/AddNewSupplier";
import {
  asyncGetNewAgencyReg,
  asyncSearchAgencyData,
} from "../../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeBasedOnROE,
} from "../../../../Utils/currencyExchange";
import { dropMenuProp } from "../../../../Utils/MenuProps";
import { asyncGetVisaCountry } from "../../../../redux/features/visa/CountrySlice";
import { debounce } from "lodash";
import { getAsyncSearchPNR } from "../../../../redux/features/booking/bookingDetailsSlice";
import { useParams } from "react-router-dom";

const arry = [
  { id: 1, type: "Flight" },
  { id: 2, type: "Visa" },
  { id: 3, type: "Hotel" },
  { id: 4, type: "Insurance" },
  { id: 5, type: "Transfer" },
  { id: 6, type: "Activity" },
  { id: 7, type: "Umrah" },
];

const ServiceType = ({
  values,
  setInitValues,
  pnrEror,
  setPnrError,
  VoucherBooking
  // auto_manual,
}) => {
  const dispatch = useDispatch();
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const { agentGroup } = useSelector((state) => state.agentsCategories);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const { searchAgency } = useSelector((state) => state.newAgency);
  const { newSupplier } = useSelector((state) => state.supplier);
  const { visaCountry } = useSelector((state) => state.visaCountry);
  const { booking } = useSelector((state) => state?.booking);
const userParam = useParams()
  const suppArray = newSupplier?.filter((it) => {
    if (values.service_type === "Flight") {
      return it?.cat_id === 2;
    } else if (values.service_type === "Insurance") {
      return it?.cat_id === 4;
    } else if (values.service_type === "Visa") {
      return it?.cat_id === 1;
    } else if (values.service_type === "Hotel") {
      return it?.cat_id === 5;
    } else if (values.service_type === "Transfer") {
      return it?.cat_id === 7;
    } else if (values.service_type === "Activity") {
      return it?.cat_id === 9;
    } else return true;
  });

  // get agent group currency
  const getAgentGrpCurr = (comp) => {
    //agent group
    const agent_grp = agentGroup?.find(
      (item) => item.agent_grp_id === comp?.agent_grp_id
    );
    // currency of the selected agent group
    const currency = currencyData?.find(
      (it) => it.currency_id === agent_grp?.currency_id
    );
    //set local storage
    localStorage.setItem(
      "currency",
      JSON.stringify(currency !== undefined ? currency : "")
    );
  };

  useEffect(() => {
    dispatch(asyncGetNewSupplier());
    dispatch(asyncGetNewAgencyReg());
    dispatch(asyncGetVisaCountry());
  }, [dispatch]);

  return (
    <>
      <div className="col-12 col-md-2 my-4 py-3">
        <div className="fs-5  d-flex gap-2">
          <p>{VoucherBooking?.voucherData?.voucher_no}</p>
          <p
            className="text-primary"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              const url = `${window.location.origin}/booking-details/${VoucherBooking?.id}`;
              window.open(url, "_blank");
            }}
          >
            {VoucherBooking?.id}
          </p>
        </div>
        <FormControl variant="standard" className="w-100">
          <InputLabel id="service_type ">{"Select Service Type"} </InputLabel>
          <Select
            id="service_type"
            value={values.service_type}
            onChange={
              (e) => ""
              // setInitValues({ ...values, service_type: e.target.value })
            }
            label={"Select Service Type"}
            MenuProps={dropMenuProp}
            disabled={VoucherBooking?.auto_manual}
          >
            {arry.map((list) => (
              <MenuItem
                onClick={(e) => {
                  setInitValues({
                    ...values,
                    service_type: list?.type,
                    service_id: list.id,
                  });
                }}
                value={list?.type}
                sx={{ bgcolor: "#fff" }}
                key={list?.id}
              >
                {list?.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <hr className=" horizontal-line" />
      {/*  CUSTOMER , SUPPLIER , DOCUMENT NO , INVOICE DATE ,  */}
      <div className="section_box">
        <div className=" row align-items-end my-4">
          <div className="col-6 col-md-2 position-relative">
            <FormControl
              variant="standard"
              className="w-100"
              onClick={() => setagentDRopDwn(!agentDRopDwn)}
              required
            >
              <InputLabel htmlFor="supplier">Select Agent</InputLabel>
              <Input
                variant="standard"
                label="Select Agent"
                autoComplete="off"
                className="w-100"
                name="agent"
                value={values.agent_name}
                onChange={(e) => {
                  let value = e.target.value;
                  setInitValues({
                    ...values,
                    agent_name: e.target.value,
                  });
                  dispatch(asyncSearchAgencyData(value));
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {values.agent !== "" && (
                      <IconButton
                        onClick={() => {
                          setInitValues({
                            ...values,
                            agent_name: "",
                            agent: "",
                          });
                        }}
                      >
                        <MdClose />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                disabled={booking?.auto_manual === 0}
              />
            </FormControl>

            {agentDRopDwn ? (
              <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
                <div className="agent_dropdown">
                  {searchAgency?.length > 0 ? (
                    searchAgency?.map((item, index) => {
                      return (
                        <div
                          className="p-3"
                          onClick={(e) => {
                            setInitValues({
                              ...values,
                              agent_name: item.agent_name,
                              agent: item,
                            });
                            // setFieldValue(`agent_name`, item.agent_name);
                            // setFieldValue(`agent`, item);
                            setagentDRopDwn(false);
                            getAgentGrpCurr(item);
                          }}
                          style={{ cursor: "pointer" }}
                          key={index}
                        >
                          {item.agent_name}
                        </div>
                      );
                    })
                  ) : (
                    <div className="p-3">No record available.</div>
                  )}
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
          <div className="col-6 col-md-2">
            <Autocomplete
              id="size-small-standard"
              size="small"
              options={suppArray}
              getOptionLabel={(option) => option.supp_name}
              onChange={(event, newValue) => {
                if (newValue) {
                  setInitValues({
                    ...values,
                    supplier: newValue.acc_id,
                  }); // Set supplier acc id
                } else {
                  setInitValues({
                    ...values,
                    supplier: "",
                  }); // Clear supplier name if nothing is selected
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Select Supplier"
                />
              )}
              defaultValue={suppArray?.find(
                (option) => option?.acc_id === values?.supplier
              )}
              disabled={booking?.auto_manual === 0}
            />
          </div>
          {values.service_type === "Visa" && (
            <div className="col-6 col-md-2">
              <FormControl variant="standard" className="w-100" required>
                <InputLabel id={`visa_country`}>Visa Country</InputLabel>
                <Select
                  id={`visa_country`}
                  value={values.visa_country}
                  onChange={(e) => {
                    let value = e.target.value;
                    setInitValues({
                      ...values,
                      visa_country: value,
                    });
                  }}
                  label="Visa Country"
                  MenuProps={dropMenuProp}
                  required
                >
                  {visaCountry?.map((it, ind) => (
                    <MenuItem value={it.id} key={ind}>
                      {it.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="col-6 col-md-2 mt-3 mt-md-4 position-relative">
            <TextField
              id="d_No_pnr"
              value={values.d_No_pnr}
              onChange={(e) => {
                setInitValues({
                  ...values,
                  d_No_pnr: e.target.value.toUpperCase(),
                });
              }}
              label="Document No| PNR"
              variant="standard"
              className="w-100"
              required
              disabled={VoucherBooking?.auto_manual}
            />
          </div>

          <div className="col-6 col-md-2 mt-3 mt-md-0">
            <div>
              <ReactDatePicker
                placeholderText="Invoice Date"
                selected={values.inovice_date}
                value={values.inovice_date}
                dateFormat="dd-MM-yyyy"
                onChange={(date) => {
                  setInitValues({
                    ...values,
                    inovice_date: date,
                  });
                }}
                maxDate={new Date()}
                className="date_picker"
                showYearDropdown
                dropdownMode="select"
                yearItemNumber={10}
                yearDropdownItemNumber={10}
                disabled={booking?.auto_manual === 0}
              />
            </div>
          </div>
          <div className="col-6 col-md-2 mt-3 mt-md-0">
            <TextField
              id="remarks"
              label="Remarks"
              value={values.remarks}
              onChange={(e) => {
                setInitValues({
                  ...values,
                  remarks: e.target.value.toUpperCase(),
                });
              }}
              variant="standard"
              className="w-100"
              required
              // disabled={booking?.auto_manual === 0}
            />
          </div>
        </div>
        {values?.agent_name && (
          <div className="py-2">
            <h5>
              Available Limit:{" "}
              <span className="text-primary">
                {values?.agent?.agent_group?.currency?.curr_code}{" "}
                {CurrencyExchangeBasedOnROE(
                  Number(values?.agent?.credit_limit) -
                    Number(values?.agent?.credit_used),
                  Number(values?.agent?.agent_group?.currency?.roe)
                )}
              </span>
            </h5>
          </div>
        )}
      </div>
    </>
  );
};

export default ServiceType;
