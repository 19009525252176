import React from 'react'
import PaidVoucher from '../components/Setup/Payments/PaidVoucher'

const AdminPaidVoucher = () => {
  return (
    <div className='Full_height'>
      <PaidVoucher />
    </div>
  )
}

export default AdminPaidVoucher