import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetBannerData } from "../../redux/features/agency/homeviewsittingslices/banner_about_services_slice";
import { apiUrl } from "../../Utils/configUrl";

function Slider() {
  const dispatch = useDispatch();

  const { bannerStateData } = useSelector(
    (state) => state?.banner_about_services_slice
  );
  /************useEffect , get banner data ************* */
  useEffect(() => {
    dispatch(asyncGetBannerData());
  }, [dispatch]);
  return (
    <div className="landing-page-slider border">
      <Carousel indicators={false}>
        {bannerStateData?.map((item) => (
          <Carousel.Item key={item.id}>
            <img
              className="d-block w-100 landing-slider-image"
              src={`${apiUrl}/${item?.image}`}
              alt="First slide"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default Slider;
