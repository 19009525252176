import React, { useEffect, useState } from "react";
import "./ancillaryStyle.css";
import Baggage from "./Baggage";
import singlebage from "../../../../assets/images/singlebage.png";
import airportservices from "../../../../assets/images/airportservice.png";
import Divider from "@mui/material/Divider";
import Meals from "./Meals";
import { GiMeal } from "react-icons/gi";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import Seats from "./Seats";
import { Box, Typography, Collapse } from "@mui/material";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import axios from "axios";
import { URL2 } from "../../../../redux/features/ApiConfig";
import { useLocation } from "react-router-dom";
import AirportServices from "./AirportServices";

const Ancillary = ({ values, index, setFieldValue, flight, sel_flight }) => {
  const retur = sel_flight?.booking?.seg_r;
  const location = useLocation();
  const [ancillary, setAncillary] = useState("");
  const [baggageToggle, setBaggageToggle] = useState(false);
  const { pax } = values;
  const paxes = [...pax];

  // const { selectedFlight,returnFlight } = useSelector(
  //   (state) => state.FlightSearch
  //   );

  const [responseData, setResponseData] = useState(null);
  const [responseMealData, setResponseMealData] = useState(null);
  const [responseSeatsData, setSeatsData] = useState(null);
  const [responseAirportServicesData, setAirportServicesData] = useState(null);

  //ancillaries states
  const [selectedBaggage, setSelectedBaggage] = useState(
    Array(paxes.length).fill(null)
  );
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedService, setSelectedService] = useState(
    Array(paxes.length).fill(null)
  );

  const { agent_name, transaction_identifier, bundleIdOutbound, rph, cookie } =
    sel_flight;
  const rphh = Array.isArray(rph) ? rph : [rph];

  const requestedData = flight.flightSegments
    .map((flightSegment) => {
      const {
        flightNumber,
        origin,
        destination,
        departureDateTimeLocal,
        arrivalDateTimeLocal,
        availablePaxCounts,
      } = flightSegment;

      return {
        agent_name: agent_name,
        ArrivalDateTime: arrivalDateTimeLocal,
        transactionIdentifier: transaction_identifier,
        DepartureDateTime: departureDateTimeLocal,
        FlightNumber: flightNumber,
        rph: rphh?.at(index),
        cookie,
        dep_LocationCode: origin.airportCode,
        arr_LocationCode: destination.airportCode,
        Adt_Quantity:
          availablePaxCounts.find((item) => item.paxType === "ADT")?.count || 0,
        Chd_Quantity:
          availablePaxCounts.find((item) => item.paxType === "CHD")?.count || 0,
        Inf_Quantity:
          availablePaxCounts.find((item) => item.paxType === "INF")?.count || 0,
        bundleIdOutbound: bundleIdOutbound,
      };
    })
    .flat();

  const arrTime = flight.flightSegments
    .map((flightSegment) => {
      const {
        flightNumber,
        origin,
        destination,
        departureDateTimeLocal,
        arrivalDateTimeLocal,
        availablePaxCounts,
      } = flightSegment;
      return {
        flightNumber,
        origin,
        destination,
        departureDateTimeLocal,
        arrivalDateTimeLocal,
        availablePaxCounts,
      };
    })
    .flat();

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${URL2}/api/v1/b2b/ancilliry/9p/baggages`,
        { rph: rphh, requestedData: requestedData, agent_name: agent_name }
      );

      // Assuming the API returns some data, you can update state with it
      setResponseData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchMealData = async () => {
    try {
      const response = await axios.post(
        `${URL2}/api/v1/b2b/ancilliry/9p/meal`,
        { rph: rphh, requestedData: requestedData, agent_name: agent_name }
      );

      // Assuming the API returns some data, you can update state with it
      setResponseMealData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSeatsData = async () => {
    try {
      const response = await axios.post(
        `${URL2}/api/v1/b2b/ancilliry/9p/seats`,
        { rph: rphh, requestedData: requestedData, agent_name: agent_name }
      );

      // Assuming the API returns some data, you can update state with it
      setSeatsData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAirportServicesData = async () => {
    try {
      const response = await axios.post(
        `${URL2}/api/v1/b2b/ancilliry/9p/airportServices`,
        { rph: rphh, requestedData: requestedData, agent_name: agent_name }
      );

      // Assuming the API returns some data, you can update state with it
      setAirportServicesData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (location.pathname === "/check-out") {
      fetchData();
      fetchMealData();
      fetchSeatsData();
      fetchAirportServicesData();
    }
  }, []);

  const handleToggleClick = () => {
    setBaggageToggle(!baggageToggle);
  };

  const baggageDetailsResponses =
    responseData?.response?.["ns1:BaggageDetailsResponses"]?.[
      "ns1:OnDBaggageDetailsResponse"
    ];
  const mealData = responseMealData?.response?.["ns1:MealDetailsResponses"];
  const seatData =
    responseSeatsData?.response?.["ns1:OTA_AirSeatMapRS"][
      "ns1:SeatMapResponses"
    ];
  const servicesData =
    responseAirportServicesData?.response?.["ns1:AA_OTA_AirSSRDetailsRS"]?.[
      "ns1:SSRDetailsResponses"
    ]?.["ns1:SSRDetailsResponse"]?.["ns1:AirportService"];

  const baggageText =
    ancillary === "Baggage" ? "Confirm Baggage" : "Baggage Selection";
  const mealText = ancillary === "Meal" ? "Confirm Meal" : "Meal Selection";
  const seatText = ancillary === "seats" ? "Confirm Seat" : "Seat Selection";
  const AirportServicesText =
    ancillary === "AirportServices"
      ? "Confirm AirportServices"
      : "AirportService";

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        borderRadius={2}
        // onClick={() => setBaggageToggle(!baggageToggle)}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="h6" fontWeight="bold" color="textPrimary">
          Select Ancillaries For{" "}
          <Typography component="span" variant="inherit" sx={{ color: "red" }}>
            {arrTime?.[1]
              ? `${arrTime?.[0]?.origin?.airportCode}/${arrTime?.[1]?.origin?.airportCode}/${arrTime?.[1]?.destination?.airportCode}  (${arrTime?.[0]?.flightNumber} - ${arrTime?.[1]?.flightNumber})`
              : `${arrTime?.[0]?.origin?.airportCode} - ${arrTime?.[0]?.destination?.airportCode} (${arrTime?.[0]?.flightNumber})`}
          </Typography>
        </Typography>
        <Box
          width={60}
          height={40}
          bgcolor="#f0f0f0"
          borderRadius={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleToggleClick}
        >
          {baggageToggle ? (
            <FaChevronUp size={15} />
          ) : (
            <FaChevronDown size={15} />
          )}
        </Box>
      </Box>
      <Collapse in={!baggageToggle}>
        <div>
          <div>
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: "15%",
                  pt: 2,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={singlebage} alt="" style={{ height: 80 }} />
              </Box>
              <Box sx={{ width: "60%", px: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ pt: { xs: 4, md: 0 }, fontWeight: "medium" }}
                >
                  Baggage
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "16px" }}>
                  Save time and select a baggage allowance for you.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  cursor: "pointer",
                }}
              >
                <div
                  className="button_style"
                  style={{ width: 200, textAlign: "center" }}
                  onClick={() =>
                    setAncillary(ancillary === "Baggage" ? "" : "Baggage")
                  }
                >
                  {baggageDetailsResponses ? baggageText : "Baggage Selection"}
                </div>
              </Box>
            </Box>

            {ancillary === "Baggage" &&
              (baggageDetailsResponses ? (
                <Baggage
                  baggageDetails={baggageDetailsResponses}
                  paxes={paxes?.filter(({ pax_type }) => pax_type !== "INFT")}
                  setFieldValue={setFieldValue}
                  index={index}
                  setSelectedBaggage={setSelectedBaggage}
                  selectedBaggage={selectedBaggage}
                />
              ) : (
                ""
              ))}
          </div>
          {mealData ? (
            <div>
              <Box py={4}>
                <Divider />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
                <Box
                  sx={{
                    width: "15%",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GiMeal size={70} />
                </Box>
                <Box sx={{ width: "60%", pl: 2, pr: 10 }}>
                  <Typography
                    variant="h6"
                    sx={{ pt: { xs: 4, md: 0 }, fontWeight: "medium" }}
                  >
                    Meals
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    Pre-order your preferred meal and ensure that you are served
                    first onboard the flight.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="button_style"
                    style={{ width: 200, textAlign: "center" }}
                    onClick={() =>
                      setAncillary(ancillary === "Meal" ? "" : "Meal")
                    }
                  >
                    {mealData ? mealText : "Meal Selection"}
                  </div>
                </Box>
              </Box>

              {ancillary === "Meal" && mealData ? (
                <Meals
                  setAncillary={setAncillary}
                  meal={mealData}
                  paxes={paxes?.filter(({ pax_type }) => pax_type !== "INFT")}
                  setFieldValue={setFieldValue}
                  setSelectedMeals={setSelectedMeals}
                  selectedMeals={selectedMeals}
                  indexx={index}
                />
              ) : null}
            </div>
          ) : null}

          {seatData ? (
            <div>
              <Box py={4}>
                <Divider />
              </Box>
              <Box pb={4}>
                <Box sx={{ display: "flex", alignItems: "center", py: 2 }}>
                  <Box
                    sx={{
                      width: "15%",
                      pt: 2,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MdAirlineSeatReclineNormal size={70} />
                  </Box>
                  <Box sx={{ width: "60%", px: 2 }}>
                    <Typography
                      variant="h6"
                      sx={{ pt: { xs: 4, md: 0 }, fontWeight: "medium" }}
                    >
                      Seats
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "16px" }}>
                      Select your preferred seat and ensure it is reserved for
                      you.
                    </Typography>
                  </Box>
                  <Box sx={{ width: "25%", display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className="button_style"
                        style={{ width: 200, textAlign: "center" }}
                        onClick={() =>
                          setAncillary(ancillary === "seats" ? "" : "seats")
                        }
                      >
                        {seatData ? seatText : "Seat Selection"}
                      </div>
                    </Box>
                  </Box>
                </Box>
                {ancillary === "seats" && seatData ? (
                  <Seats
                    setAncillary={setAncillary}
                    seats={seatData}
                    pax={pax}
                    setFieldValue={setFieldValue}
                    //  paxIndex={paxIndex}
                    paxes={paxes?.filter(({ pax_type }) => pax_type !== "INFT")}
                    index={index}
                    selectedSeats={selectedSeats}
                    setSelectedSeats={setSelectedSeats}
                  />
                ) : (
                  ""
                )}
              </Box>
            </div>
          ) : null}

          {/* AIRPORT SERVICES */}
          {servicesData ? (
            <div>
              <Box py={4}>
                <Divider />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", pb: 2 }}>
                <Box
                  sx={{
                    width: "15%",
                    pt: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={airportservices}
                    alt="Airport Services"
                    width="80"
                    height="80"
                  />
                </Box>
                <Box sx={{ width: "60%", pl: 2, pr: 10 }}>
                  <Typography
                    variant="h6"
                    sx={{ pt: { xs: 4, md: 0 }, fontWeight: "medium" }}
                  >
                    Airport services
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "16px" }}>
                    Proceed to the dedicated Priority check-in counter for a
                    faster check-in experience at the airport.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                  }}
                >
                  <div
                    className="button_style"
                    style={{ width: 200, textAlign: "center" }}
                    onClick={() =>
                      setAncillary(
                        ancillary === "AirportServices" ? "" : "AirportServices"
                      )
                    }
                  >
                    {servicesData ? AirportServicesText : "AirportServices"}
                  </div>
                </Box>
              </Box>

              {ancillary === "AirportServices" && servicesData ? (
                <AirportServices
                  serviceData={servicesData}
                  paxes={paxes?.filter(({ pax_type }) => pax_type !== "INFT")}
                  setFieldValue={setFieldValue}
                  setSelectedService={setSelectedService}
                  selectedService={selectedService}
                  index={index}
                  setAncillary={setAncillary}
                />
              ) : (
                ""
              )}
            </div>
          ) : null}
        </div>
      </Collapse>
    </div>
  );
};

export default Ancillary;
