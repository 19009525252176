import React from 'react';
import SupplierComp from '../components/Setup/Supplier'

const Supplier = () => {
  return (
    <div className=''>
      <SupplierComp />
    </div>
  )
}

export default Supplier