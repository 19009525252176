import { FieldArray, Formik } from "formik";
import DatePicker from "react-datepicker";
import * as yup from "yup";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import {
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form } from "react-bootstrap";

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { asyncSearchAgencyData } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import {
  UpdatedDataNull,
  asyncGetFlightGroupData,
  asyncUpdateFlightGroup,
} from "../../redux/features/promotion/FlightGroupsSlice";
import { useNavigate } from "react-router-dom";
import BtnLoader from "../AppForm/BtnLoader";
import { dropMenuProp } from "../../Utils/MenuProps";
import { CurrencyCode, CurrencyExchange } from "../../Utils/currencyExchange";
import moment from "moment";
import { TwoDecimalPoint } from "../../Utils/FixedTwoDecimal";
import { getFlightType, getSectorsName } from "../../Utils/FlightSector";
export const PaxModel = ({
  ind,
  PaxType,
  totalSeat,
  passenger,
  PaxTitle,
  PAXPrice,
  items,
  toggle,
  setToggle,
  modelShow,
  setModalShow,
  agent,
  setAgent,
  currency,
  user,
}) => {
  const currentDate = new Date();
  const dob = moment().subtract(12, "years").toDate();
  const tomorrow = moment().add(7, "day").toDate();
  const [defaultPassenger, setDefaultPassenger] = useState({
    Type: "ADULT",
    Title: "MR",
    F_name: "FIRST",
    L_name: "LAST",
    DOB: dob,
    Passport: "12345",
    Pass_Exp: tomorrow,
    Nationality: "Pakistan",
    Total: items?.flight_group_pax_fares[0]?.default_currency_received, // Set your default value here
    agent_name: user?.f_name + " " + user?.l_name,
    selected_currency_pay:
      items?.flight_group_pax_fares[0]?.selected_currency_pay,
    selected_currency_received:
      items?.flight_group_pax_fares[0]?.selected_currency_received,
    default_currency_pay:
      items?.flight_group_pax_fares[0]?.default_currency_pay,
    default_currency_received:
      items?.flight_group_pax_fares[0]?.default_currency_received,
  });
  const validationSchema = yup.object().shape({
    /////////////////////////////////////
    agent_name: yup.string().required("Agent name is Required"),
    passenger: yup.array().of(
      yup.object().shape({
        Type: yup.string().required(" Required"),
        Title: yup.string().required(" Required"),
        F_name: yup.string().required("Required"),
        L_name: yup.string().required("Required"),
        DOB: yup.string().required("Required"),
        Passport: yup.string().required(" Required"),
        Pass_Exp: yup.string().required("Required"),
        Nationality: yup.string().required("Required"),
        Total: yup.string().required(" Required"),
      })
    ),
    /////////////////////////////////////
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const [seatsMessage, setSeatsMessage] = useState();
  const { searchAgency } = useSelector((state) => state.newAgency);
  // const { upDateflightGroup, seatsError } = useSelector(
  //   (state) => state?.flightGroup
  // );
  const [responseErr, setResponseError] = useState(false);
  const [getid, setGetId] = useState(false);

  const calculateTotal = (mainBookingData, property) => {
    return mainBookingData?.passenger?.reduce(
      (prevValue, currentValue) => prevValue + currentValue[property],
      0
    );
  };
  // useEffect(() => {
  //   if (upDateflightGroup) {
  //     navigate("/booking-details", {
  //       state: {
  //         upDateflightGroup,
  //       },
  //     });
  //   }
  //   dispatch(UpdatedDataNull());
  // }, [upDateflightGroup]);
  return (
    <div className="mt-3 border rounded-3 py-3 px-2 shadow-lg">
      <h5>Pax Details:</h5>
      <Formik
        initialValues={{
          agent_id: user?.agents_comp?.acc_id,
          email: user?.agents_comp?.email,
          agent_name:
            user?.agent_id === 1 
              ? ""
              : user?.f_name + " " + user?.l_name,
          passenger: [defaultPassenger],
        }} // Set default values here
        // enableReinitialize={true}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={(values, { resetForm }) => {
          const ttl_supplier_fare = calculateTotal(
            values,
            "default_currency_pay"
          );
          const ttl_agent_fare = calculateTotal(
            values,
            "default_currency_received"
          );

          const currentDate = new Date();

          // Add two hours to the current date and time
          const newDate = new Date(currentDate);
          newDate.setHours(currentDate.getHours() + 2);
          let {
            flight_group_pax_fares,
            flight_group_flights,
            group_tktl,
            ...allItemProperties
          } = items;
          let id = items?.flt_group_id;
          // console.log("upDateflightGroup", upDateflightGroup);
          // const upDateflightGroupId = upDateflightGroup;

          // Use the spread operator to include all properties in the new data object
          let body = {
            ...allItemProperties,
            flight_Booking: true,
            ttl_supplier_fare: TwoDecimalPoint(ttl_supplier_fare),
            ttl_agent_fare: TwoDecimalPoint(ttl_agent_fare),
            currencyCode: currency?.currency_id,
            asg_currency_roe: Number(currency?.roe),
            group_tktl: newDate,
            paxData: {
              ...values,
              passenger: values?.passenger.map((psngr, ind) => {
                const { Total, ...rest } = psngr;
                return {
                  ...rest,
                  default_currency_received: Total,
                  ttl_agt_pay_asg_cur: Number(
                    CurrencyExchange(Total).toFixed(2)
                  ),
                  pax_type:
                    (psngr.Type === "ADULT" && "ADT") ||
                    (psngr.Type === "CHILD" && "CNN") ||
                    (psngr.Type === "INFANT" && "INF"),
                };
              }),
            },
            booked_seat: items?.booked_seat + values.passenger.length,
            flight_group_pax_fare: flight_group_pax_fares.map((pa) => ({
              ...pa,
            })),
            flight_group_flight: flight_group_flights?.map((fl, index) => ({
              ...fl,
              segment_type: getSectorsName(fl?.origin, fl?.destination),
            })),
            flight_type: getFlightType(
              flight_group_flights?.at(0)?.origin,
              flight_group_flights
            ),
          };
          // update Record in flight_group_table
          // dispatch(UpdatedDataNull());
          dispatch(asyncUpdateFlightGroup({ id, body })).then((res) => {
            // setToggle(!toggle);
            setGetId(true);
            setTimeout(() => {
              if (res?.payload?.book_id) {
                setModalShow({
                  ...modelShow,
                  ["open_" + items.flt_group_id]:
                    !modelShow["open_" + items.flt_group_id],
                });
                setGetId(false);
                resetForm();

                navigate("/booking-details", {
                  state: {
                    upDateflightGroupId: res.payload.book_id,
                  },
                });
              } else {
                setResponseError({
                  ...responseErr,
                  ["open_" + ind]: !responseErr["open_" + ind],
                });
              }
            }, 3000);
          });

          localStorage.setItem("currency", JSON.stringify(""));
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors }) => (
          <Form>
            <FieldArray name="passenger">
              {({ push, remove }) => (
                <div>
                  {values.passenger.map((item, index) => (
                    <div>
                      <div className="d-flex justify-content-between align-items-end mt-3">
                        <div />
                        <div className="d-flex gap-2">
                          {index === 0 && (
                            <button
                              type="button"
                              disabled={values.passenger.length === 1}
                              variant="danger"
                              className=" button_style bg-danger"
                              onClick={() =>
                                remove(values.passenger.length - 1)
                              }
                            >
                              - PAX
                            </button>
                          )}
                          {index === 0 && (
                            <button
                              type="button"
                              className="button_style"
                              variant="primary"
                              disabled={values.passenger.length === totalSeat}
                              onClick={(e) => {
                                push({
                                  Type: "ADULT",
                                  Title: "MR",
                                  F_name: "FIRST",
                                  L_name: "LAST",
                                  DOB: dob,
                                  Passport: "12345",
                                  Pass_Exp: tomorrow,
                                  Nationality: "Pakistan",
                                  Total:
                                    items?.flight_group_pax_fares[0]
                                      ?.default_currency_received, // Set your default value here
                                  agent_name: user?.f_name + " " + user?.l_name,
                                  selected_currency_pay:
                                    items?.flight_group_pax_fares[0]
                                      ?.selected_currency_pay,
                                  selected_currency_received:
                                    items?.flight_group_pax_fares[0]
                                      ?.selected_currency_received,
                                  default_currency_pay:
                                    items?.flight_group_pax_fares[0]
                                      ?.default_currency_pay,
                                  default_currency_received:
                                    items?.flight_group_pax_fares[0]
                                      ?.default_currency_received,
                                });
                              }}
                            >
                              + PAX
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row mt-2 ">
                        <div className="col-1">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="Type"
                              value={values?.passenger[index]?.Type}
                              onChange={(e) => {
                                setFieldValue(
                                  `passenger.${index}.Type`,
                                  e.target.value
                                );

                                let pax = PAXPrice?.filter(
                                  (pax) => pax.pax_type === e.target.value
                                ).map((item) => {
                                  return item;
                                });

                                if (e.target.value === "ADULT") {
                                  setFieldValue(
                                    `passenger.${index}.DOB`,
                                    moment().subtract(12, "years").toDate()
                                  );
                                  setFieldValue(
                                    `passenger.${index}.Pass_Exp`,
                                    moment().add(7, "days").toDate()
                                  );
                                } else if (e.target.value === "CHILD") {
                                  setFieldValue(
                                    `passenger.${index}.DOB`,
                                    moment().subtract(2, "years").toDate()
                                  );
                                  setFieldValue(
                                    `passenger.${index}.Pass_Exp`,
                                    moment().add(7, "days").toDate()
                                  );
                                } else {
                                  setFieldValue(
                                    `passenger.${index}.DOB`,
                                    moment().subtract(0, "day").toDate()
                                  );
                                  setFieldValue(
                                    `passenger.${index}.Pass_Exp`,
                                    moment().add(7, "days").toDate()
                                  );
                                }

                                setFieldValue(
                                  `passenger.${index}.Total`,
                                  pax[0]?.default_currency_received
                                    ? pax[0]?.default_currency_received
                                    : 0
                                );
                                setFieldValue(
                                  `passenger.${index}.selected_currency_received`,
                                  pax[0]?.selected_currency_received
                                    ? pax[0]?.selected_currency_received
                                    : 0
                                );
                                setFieldValue(
                                  `passenger.${index}.selected_currency_pay`,
                                  pax[0]?.selected_currency_pay
                                    ? pax[0]?.selected_currency_pay
                                    : 0
                                );
                                setFieldValue(
                                  `passenger.${index}.default_currency_pay`,
                                  pax[0]?.default_currency_pay
                                    ? pax[0]?.default_currency_pay
                                    : 0
                                );

                                setFieldValue(
                                  `passenger.${index}.default_currency_received`,
                                  pax[0]?.default_currency_received
                                    ? pax[0]?.default_currency_received
                                    : 0
                                );
                              }}
                              MenuProps={dropMenuProp}
                            >
                              {PaxType?.filter((x) =>
                                items?.flight_group_pax_fares?.find(
                                  (pa) => pa.pax_type === x.type
                                )
                              )?.map((p, i) => (
                                <MenuItem
                                  value={p?.type}
                                  sx={{ m: 1, bgcolor: "#fff" }}
                                >
                                  {p?.type}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <p className="text-danger mt-2">
                            {errors?.passenger &&
                              errors?.passenger[index]?.Type}
                          </p>
                        </div>
                        <div className="col-1">
                          <FormControl variant="standard" className="w-100">
                            <InputLabel id="demo-simple-select-standard-label">
                              Title
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              name="Title"
                              value={values?.passenger[index]?.Title}
                              onChange={(e) =>
                                setFieldValue(
                                  `passenger.${index}.Title`,
                                  e.target.value
                                )
                              }
                              MenuProps={dropMenuProp}
                            >
                              {PaxTitle?.map((item, index) => (
                                <MenuItem
                                  value={item?.title}
                                  sx={{ m: 1, bgcolor: "#fff" }}
                                  // key={selectPassangerTypeList?.key}
                                >
                                  {item?.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <p className="text-danger mt-2">
                            {errors?.passenger &&
                              errors?.passenger[index]?.Title}
                          </p>
                        </div>
                        <div style={{ width: 130 }}>
                          <TextField
                            variant="standard"
                            label="First Name"
                            name="F_name"
                            className="w-100"
                            value={values?.passenger[index]?.F_name}
                            onChange={(e) => {
                              setFieldValue(
                                `passenger.${index}.F_name`,
                                e.target.value.toUpperCase()
                              );
                            }}
                          />
                          <p className="text-danger mt-2">
                            {errors?.passenger &&
                              errors?.passenger[index]?.F_name}
                          </p>
                        </div>
                        <div style={{ width: 130 }}>
                          <TextField
                            variant="standard"
                            label="Last Name"
                            name="L_name"
                            value={values?.passenger[index]?.L_name}
                            className="w-100"
                            onChange={(e) => {
                              setFieldValue(
                                `passenger.${index}.L_name`,
                                e.target.value.toUpperCase()
                              );
                            }}
                          />
                          <p className="text-danger mt-2">
                            {errors?.passenger &&
                              errors?.passenger[index]?.L_name}
                          </p>
                        </div>
                        <div className=" mt-4" style={{ width: 120 }}>
                          <DatePicker
                            name={`passenger.${index}.DOB`}
                            selected={moment(
                              values?.passenger[index]?.DOB
                            ).toDate()}
                            className=" date_picker "
                            calendarClassName="custom-datepicker  "
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            minDate={
                              values?.passenger[index]?.Type === "ADULT"
                                ? null
                                : values?.passenger[index]?.Type === "CHILD"
                                ? moment(currentDate)
                                    .subtract(12, "years")
                                    .toDate()
                                : values?.passenger[index]?.Type === "INFANT"
                                ? moment(currentDate)
                                    .subtract(2, "years")
                                    .toDate()
                                : null
                            }
                            maxDate={
                              values?.passenger[index]?.Type === "ADULT"
                                ? moment(currentDate)
                                    .subtract(12, "years")
                                    .toDate()
                                : values?.passenger[index]?.Type === "CHILD"
                                ? moment(currentDate)
                                    .subtract(2, "years")
                                    .toDate()
                                : values?.passenger[index]?.Type === "INFANT"
                                ? moment(currentDate)
                                    .subtract(0, "years")
                                    .toDate()
                                : null
                            }
                            placeholderText="DOB"
                            onChange={(date) => {
                              if (date) {
                                const formattedDate =
                                  moment(date).format("YYYY-MM-DD");
                                setFieldValue(
                                  `passenger.${index}.DOB`,
                                  formattedDate
                                );
                              }
                            }}
                          />
                          <p
                            className="text-danger "
                            style={{ marginTop: -40 }}
                          >
                            {errors?.passenger && errors?.passenger[index]?.DOB}
                          </p>
                        </div>
                        <div style={{ width: 120 }}>
                          <TextField
                            variant="standard"
                            label="Passport"
                            name="Passport"
                            value={values?.passenger[index]?.Passport}
                            className="w-100"
                            onChange={(e) => {
                              setFieldValue(
                                `passenger.${index}.Passport`,
                                e.target.value
                              );
                            }}
                          />
                          <p className="text-danger mt-2">
                            {errors?.passenger &&
                              errors?.passenger[index]?.Passport}
                          </p>
                        </div>

                        <div className=" mt-4" style={{ width: 120 }}>
                          <DatePicker
                            name={`passenger.${index}.Pass_Exp`}
                            selected={moment(
                              values?.passenger[index]?.Pass_Exp
                            ).toDate()}
                            className="date_picker "
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            calendarClassName=" custom-datepicker"
                            minDate={new Date()}
                            placeholderText="Pass Exp"
                            onChange={(date) => {
                              if (date) {
                                const formattedDate =
                                  moment(date).format("YYYY-MM-DD");
                                setFieldValue(
                                  `passenger.${index}.Pass_Exp`,
                                  formattedDate
                                );
                              }
                            }}
                          />
                          <p
                            className="text-danger "
                            style={{ marginTop: -40 }}
                          >
                            {errors?.passenger &&
                              errors?.passenger[index]?.Pass_Exp}
                          </p>
                        </div>
                        <div style={{ width: 240 }}>
                          <CountryDropdown
                            id={values?.passenger[index]?.Nationality}
                            className="country_dropdown absolute "
                            preferredCountries={["pk"]}
                            value={values?.passenger[index]?.Nationality}
                            handleChange={(e) => {
                              setFieldValue(
                                `passenger.${index}.Nationality`,
                                e.target.value
                              );
                            }}
                          />
                          <p className="text-danger mt-2">
                            {errors?.passenger &&
                              errors?.passenger[index]?.Nationality}
                          </p>
                        </div>
                        <div className="col-1">
                          <TextField
                            variant="standard"
                            label={`Total ${CurrencyCode()}`}
                            className="w-100"
                            name="Total"
                            value={
                              CurrencyExchange(
                                values?.passenger[index]?.Total
                              ).toFixed(2) ||
                              CurrencyExchange(
                                item[index]?.default_currency_received
                              ).toFixed(2)
                            }
                            // onChange={e => {
                            //   setFieldValue(
                            //     `passenger.${index}.Total`,
                            //     e.target.value
                            //   )
                            // }}
                          />
                          <p className="text-danger mt-2">
                            {errors?.passenger &&
                              errors?.passenger[index]?.Total}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            <div className="d-flex justify-content-end align-middle gap-5">
              <div>
                {responseErr["open_" + ind] ? (
                  <p className="text-danger">
                    Dear Agent, Due to high frequency of seats holding Your
                    required booking request is not success due to not available
                    OR low seats are left. Kindly check your seat request again
                    or try in another flight group or call our support team.
                  </p>
                ) : null}
              </div>
              <div className="col-4">
                {/* /////// agent company filter  ///////////// */}

                {user?.agent_id === 1 && (
                  <>
                    {" "}
                    <TextField
                      variant="standard"
                      label="Agent Company"
                      placeholder="Enter Agent Name"
                      autoComplete="off"
                      className="w-100"
                      name="agent_name"
                      value={values?.agent_name}
                      onClick={() => setagentDRopDwn(!agentDRopDwn)}
                      onChange={(e) => {
                        let value = e.target.value;

                        setFieldValue("agent_name", value);
                        // setFieldValue("agent", "");
                        dispatch(asyncSearchAgencyData(e.target.value));
                      }}
                      onFocus={(e) => e.target.select()}
                    />
                    <p className="text-danger mt-2">
                      {errors && errors?.agent_name}
                    </p>
                  </>
                )}
                {agentDRopDwn ? (
                  <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
                    <div className="agent_dropdown">
                      {searchAgency?.length > 0 ? (
                        searchAgency?.map((item) => {
                          return (
                            <div
                              className="p-3"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                setFieldValue("agent_name", item?.agent_name);
                                setFieldValue("agent_id", item?.acc_id);
                                setAgent(item);

                                setagentDRopDwn(false);
                              }}
                              style={{ cursor: "pointer" }}
                              key={item?.agent_id}
                            >
                              {item?.agent_name}
                            </div>
                          );
                        })
                      ) : (
                        <div className="p-3">No record available.</div>
                      )}
                    </div>
                  </ClickAwayListener>
                ) : null}
              </div>
              <div className="col-2">
                {getid ? (
                  <div className="border px-4  mt-4 selectbtn_style">
                    <BtnLoader />
                  </div>
                ) : (
                  <button
                    className="border px-4  mt-4 selectbtn_style"
                    type="button" // Change type to "button" to prevent form submission
                    onClick={(e) => {
                      e.preventDefault(); // Prevent the default form submission
                      handleSubmit(); // Manually trigger Formik's submit handler
                    }}
                  >
                    Book
                  </button>
                )}

                {/* { ? <BtnLoader /> : ""} */}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
