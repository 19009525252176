import React, { useState, useEffect } from "react";
import twobaggage from "../../../../assets/images/twobaggage.png";
import singlebage from "../../../../assets/images/singlebage.png";
import './ancillaryStyle.css'
import {
  Box,
  Typography,
  Container,
  Grid,
} from "@mui/material";
// import { useSelector } from "react-redux";

const Baggage = ({ 
  baggageDetails,
  paxes, // Array of passengers
  setFieldValue,setSelectedBaggage,selectedBaggage,index
}) => {
  // const { selectedFlight } = useSelector(
  //   (state) => state.FlightSearch
  // );
 
  const [selectedPassengerIndex, setSelectedPassengerIndex] = useState(null);
  const [error, setError] = useState("");
  

  const handlePassengerClick = (paxIndex) => {
    setSelectedPassengerIndex(paxIndex);
    setError("");
  };

  baggageDetails =  {...baggageDetails, "ns1:Baggage": Array.isArray(baggageDetails?.["ns1:Baggage"]) ? baggageDetails?.["ns1:Baggage"] : [baggageDetails?.["ns1:Baggage"]]} 
  

  const handleBaggageSelection = (baggageCode, baggageCharge) => {
    if (selectedPassengerIndex === null) {
      setError("Please select a passenger first."); // Set error if no passenger is selected
      return;
    }
    const updatedSelectedBaggage = [...selectedBaggage];
    const baggageKey = index===0 ? 'outbound' : 'inbound';
    updatedSelectedBaggage[selectedPassengerIndex] = { code: baggageCode, charge: baggageCharge };
    setSelectedBaggage(updatedSelectedBaggage);
    setFieldValue(`pax[${selectedPassengerIndex}].${baggageKey}.baggage`, `${baggageCode}, ${baggageCharge}`);
    setFieldValue(`pax[${selectedPassengerIndex}].index`, `${index}`);
  };


  useEffect(() => {
    if (selectedPassengerIndex >= 0) {
     
      // Only set default baggage if no baggage has been selected for this passenger
      if (!selectedBaggage[selectedPassengerIndex] || selectedBaggage[selectedPassengerIndex]?.charge === 0) {
        const defaultBaggage = baggageDetails["ns1:Baggage"].find(baggage => Number(baggage["ns1:baggageCharge"]) === 0);
        if (defaultBaggage) {
          handleBaggageSelection(defaultBaggage["ns1:baggageCode"], defaultBaggage["ns1:baggageCharge"]);
        }
      }
    }
  }, [selectedPassengerIndex]);


  return (
    <Container>
      <Box display="flex" flexDirection="row" border={1} p={1} mt={3} borderRadius={2} borderColor="gray" className="flex-sm-column flex-md-column flex-lg-row">
        
        {/* First Component */}
        <Box border={1} p={1} mt={3} borderLeft='gray' borderBottom='none' borderTop='none' >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            p={3}
            mt={3}
            sx={{width: {
                      xs: '15rem', 
                      md: '20rem', 
                    }
                  }}
          >
            {paxes.map((pax, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                bgcolor={selectedPassengerIndex === index ? "#00324E" : "#19465F"}
                borderRadius={1}
                color="white"
                py={1}
                px={2}
                mt={2}
                justifyContent="space-between"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "auto",
                  textOverflow: "ellipsis",
                  cursor: "pointer"
                }}
                sx={{
                  borderRadius: 1, // Add border radius
                  "&:hover": {
                    bgcolor: selectedPassengerIndex === index ? "#19465F" : "#0e304b",
                  }
                }}
                onClick={() => handlePassengerClick(index)}
              >
                <Typography
                  variant="body1"
                  pl={4}
                  style={{
                    flex: '0 0 70%',
                    maxWidth: '70%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {`${pax.title}: ${pax.f_name} ${pax.l_name}`.slice(0, 16)}
                </Typography>
                <Box className="checkbox-wrapper-12">
                  <Box className="cbx">
                    <input type="checkbox" id="cbx-12" checked={selectedBaggage[index]} onClick={() => handlePassengerClick(index)} />
                    <label htmlFor="cbx-12"></label>
                    <svg fill="none" viewBox="0 0 15 14" height="14" width="15">
                      <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {error && (
            <Box ml={4} bgcolor="red.100" fontSize={18} color="red">
              {error}
            </Box>
          )}
        </Box>

        {/* Second Component */}
        {/* <Box mt={3} bgcolor={"red"} flex={1} className="flex-sm-column flex-md-row">
          <Grid container spacing={2} >
            {baggageDetails && baggageDetails["ns1:Baggage"].map((baggage, baggageIndex) => (
              <Grid item xs={12} md={6} key={baggageIndex}>
                <Box textAlign="center" py={4} bgcolor="gray.100" px={4} mb={2}>
                  <Box display="flex" flexDirection="row" gap={2} pt={0} bgcolor={"blue"}>
                    <img src={baggage['ns1:baggageCode'] === 'No Bag' ? singlebage : twobaggage} alt="" style={{ height: "4rem", marginBottom: "0.5rem" }} />
                    <Box>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography sx={{ mb: 2 }}>Hand Carry: 10kg</Typography>
                        <Typography sx={{ mb: 2 }}>Check Bag: {baggage["ns1:baggageCode"]}</Typography>
                        <Typography sx={{ pt: 2 }} fontWeight="bold">
                          <input
                            type="radio"
                            key={selectedPassengerIndex}
                            name={`baggage${baggageIndex}`}
                            checked={selectedPassengerIndex !== null && selectedBaggage[selectedPassengerIndex]?.code === baggage["ns1:baggageCode"] && selectedBaggage[selectedPassengerIndex]?.charge === baggage["ns1:baggageCharge"]}
                            value={`${baggage?.["ns1:baggageCode"]}, ${baggage?.["ns1:baggageCharge"]}`}
                            onClick={() => {
                              handleBaggageSelection(baggage["ns1:baggageCode"], baggage["ns1:baggageCharge"]);
                            }}
                          />
                          PKR {baggage["ns1:baggageCharge"]}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box> */}
        <Box mt={3} flex={1}>
  <Grid container spacing={2} className="flex-sm-column flex-md-row" sx={{ flexDirection: 'row', flexWrap: 'wrap' }}>
    {baggageDetails && baggageDetails?.["ns1:Baggage"].map((baggage, baggageIndex) => (
      <Grid item xs={12} md={6} key={baggageIndex} sx={{ flex: '1 0 50%', marginBottom: '16px' }}>
        <Box textAlign="center" py={4} bgcolor="gray.100" px={4} mb={2}>
          <Box display="flex" flexDirection="row" gap={2} pt={0} >
            <img src={baggage?.['ns1:baggageCode'] === 'No Bag' ? singlebage : twobaggage} alt="" style={{ height: "4rem", marginBottom: "0.5rem" }} />
            <Box>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Typography sx={{ mb: 2 }}>Hand Carry: 10kg</Typography>
                <Typography sx={{ mb: 2 }}>Check Bag: {baggage?.["ns1:baggageCode"]}</Typography>
                <Typography sx={{ pt: 2 }} fontWeight="bold">
                  <input
                    type="radio"
                    key={selectedPassengerIndex}
                    name={`baggage${baggageIndex}`}
                    checked={selectedPassengerIndex !== null && selectedBaggage[selectedPassengerIndex]?.code === baggage?.["ns1:baggageCode"] && selectedBaggage[selectedPassengerIndex]?.charge === baggage?.["ns1:baggageCharge"]}
                    value={`${baggage?.["ns1:baggageCode"]}, ${baggage?.["ns1:baggageCharge"]}`}
                    onClick={() => {
                      handleBaggageSelection(baggage?.["ns1:baggageCode"], baggage?.["ns1:baggageCharge"]);
                    }}
                  />
                  PKR {baggage?.["ns1:baggageCharge"]}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    ))}
  </Grid>
</Box>


      </Box>
    </Container>
  );
};

export default Baggage;
