import React from 'react';
import AgentsComp from '../components/Setup/Agents'

const Agents = () => {
  return (
    <div className='Full_height'>
      <AgentsComp />
    </div>
  )
}

export default Agents