import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetCircularData } from "../../redux/features/promotion/CircularSlice";
import { apiUrl } from "../../Utils/configUrl";

function Circular() {
  const dispatch = useDispatch();
  const { circularData } = useSelector((state) => state.circular);
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 4;

  useEffect(() => {
    dispatch(asyncGetCircularData());
  }, [dispatch]);

  const handleShowMore = () => {
    if (circularData?.length > itemsPerPage) {
      setStartIndex((prevStartIndex) => {
        const newStartIndex = (prevStartIndex + 1) % circularData.length;
        return newStartIndex;
      });
    }
  };

  const visibleItems = circularData
    ? circularData
        .slice(startIndex, startIndex + itemsPerPage)
        .concat(circularData.slice(0, startIndex))
        .slice(0, itemsPerPage)
    : [];

  return (
    <div>
      <div style={{ height: 402, overflow: "hidden" }}>
        {visibleItems?.map((item, id) => (
          <div
            key={id}
            style={{ display: "flex", alignItems: "center", marginTop: 15 }}
          >
            <div>
              <img
                src={`${apiUrl}/${item?.icon}`}
                alt="working wait"
                style={{
                  width: 80,
                  height: 80,
                  objectFit: "contain",
                  padding: 5,
                }}
              />
            </div>
            <div>
              <a
                href={`${apiUrl}/${item?.attachment}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  width: 270,
                  paddingTop: 10,
                  paddingLeft: 15,
                  display: "block",
                  fontSize: 16
                }}
              >
                {item?.description}
              </a>
            </div>
          </div>
        ))}
      </div>
      {circularData?.length > itemsPerPage && (
        <div
          className="showmore-btn text-center fs-6 p-3"
          onClick={handleShowMore}
        >
          Show More
        </div>
      )}
    </div>
  );
}

export default Circular;

/*<Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        arrows={false}
        showDots={false}
        containerClass="carousel-container"
      >
        <div className="border" style={{ width: 800}}>
          {circularData?.map((item, id) => (
            <div key={id} className=" d-flex align-items-center" >
              <img
                src={`${apiUrl}/${item?.icon}`}
                alt="working wait"
                style={{ width: 70, height: 70, objectFit: "contain", padding: 5 }}
              />
              <p
                style={{ width: 400, paddingTop: 10, paddingLeft: 15}}
                onClick={() =>
                  window.open(
                    `${apiUrl}/${item?.attachment}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                {item?.description}
              </p>
            </div>
          ))}
        </div>
      </Carousel> */

{
  /* <div style={{ width: "400px" }}>
        <div style={{ height: 400, overflow: "auto" }}>
          {circularData
            ?.slice(0, showAll ? totalItems : itemsToShowInitially)
            .map((item, id) => {
              return (
                <div key={id} className="d-flex align-items-center mt-3">
                  <div className="p-1">
                    <img
                      src={`${apiUrl}/${item?.icon}`}
                      alt="working wait"
                      style={{ width: 70, height: 70, objectFit: "contain" }}
                    />
                  </div>
                  <p
                    style={{ width: 270, paddingLeft: 10, paddingTop: 10 }}
                    onClick={(e) =>
                      window.open(
                        `${apiUrl}/${item?.attachment}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    {item?.description}
                  </p>
                </div>
              );
            })}
        </div>
        {totalItems > itemsToShowInitially && (
          <div
            className="showmore-btn text-center fs-6"
            style={{ padding: 17 }}
            onClick={handleClickShowMore}
          >
            {showAll ? "Show Less" : "Show More"}
          </div>
        )}
      </div> */
}

// {totalItems > itemsToShowInitially && (
//   <div
//     className="showmore-btn text-center fs-6"
//     style={{ padding: 17 }}
//     onClick={handleClickShowMore}
//   >
//     {showAll ? "Show Less" : "Show More"}
//   </div>
// )}

// const [showAll, setShowAll] = useState(false);
// const itemsToShowInitially = 4;
// const totalItems = circularData?.length || 0;

// const handleClickShowMore = () => {
//   setShowAll(!showAll);
// };
