import React from 'react';
import CurrencyComp from '../components/Setup/Currency'

const Currency = () => {
  return (
    <div className='Full_height'>
      <CurrencyComp />
    </div>
  )
}

export default Currency