import { Button, ClickAwayListener, TextField } from "@mui/material";
import React, { useState } from "react";

import { GrClose } from "react-icons/gr";
import CustomDropdown from "../../CustomDrp";

function TransferInformation({ setInputGroup }) {
  const [preview, setPreview] = useState(false);
  const [multiRows, setMultiRows] = useState([]);

  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1];
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1]);
  };

  const removeRow = () => {
    let rowsArray = multiRows;
    rowsArray.pop();
    setMultiRows([...rowsArray]);
  };

  return (
    <div className="mt-4">
      <div className="d-flex d-md-none flex-column align-items-center pb-3">
        <div
          className={`progress_bar-register rounded-circle bg-primary text-white px-3 py-2 h4`}
        >
          4
        </div>

        <h6 className={`text-primary`}>Transfer Information</h6>
      </div>
      <div className="d-flex justify-content-between align-items-center row">
        <h4 className="col-sm-8 my-3 my-md-0"> Transfer Information: </h4>
        <div className="col-sm-4 text-end">
          <Button variant="contained" className="me-2" onClick={addRow}>
            {" "}
            Add Transfer
          </Button>
          <Button variant="contained" color="error" onClick={removeRow}>
            {" "}
            Remove Transfer
          </Button>
        </div>
      </div>

      <div>
        <TransferInfoFields />
      </div>
      {multiRows.map((val) => {
        return (
          <div className="mt-3">
            <TransferInfoFields />
          </div>
        );
      })}

      <div className="d-flex justify-content-center pt-3 mb-4 mb-md-0">
        <Button
          variant="contained"
          onClick={() => setInputGroup("hotel")}
          className="me-2"
        >
          Previous
        </Button>
        <Button variant="contained" onClick={() => setPreview(true)}>
          Preview
        </Button>
      </div>
      {preview ? (
        <div className="Preview_model">
          <Preview setPreview={setPreview} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const TransferInfoFields = () => (
  <div>
    <div className="custom_passenger_info py-4" style={{ height: "auto" }}>
      <div className="row align-items-end px-2">
        <div className="col-6 col-md-3">
          <CustomDropdown
            arry={["Air", "", "", ""]}
            placehlder="Select Route"
          />
        </div>
        <div className="col-6 col-md-3">
          <CustomDropdown
            arry={["Plan", "Train", "Bus", ""]}
            placehlder="Select Vehicle"
          />
        </div>
        <div className="col-6 col-md-3 mt-3 mt-md-0">
          <CustomDropdown
            arry={["", "", "", ""]}
            placehlder="Select Supplier"
          />
        </div>
        <div className="col-6 col-md-3 mt-3 mt-md-0">
          <TextField
            variant="standard"
            label="Rate Of Exchange"
            className="w-100"
          />
        </div>
      </div>

      <div className="row align-items-end my-4 px-2">
        <div className="col-6 col-md-3">
          <TextField
            variant="standard"
            label="Total Rec SAR"
            className="w-100"
            type={"number"}
          />
        </div>
        <div className="col-6 col-md-3">
          <TextField
            variant="standard"
            label="Total Pay SAR"
            className="w-100"
            type={"number"}
          />
        </div>
        <div className="col-6 col-md-3 mt-3 mt-md-0">
          <TextField
            variant="standard"
            label="Total Rec PKR"
            className="w-100"
            type={"number"}
          />
        </div>
        <div className="col-6 col-md-3 mt-3 mt-md-0">
          <TextField
            variant="standard"
            label="Total Pay PKR"
            className="w-100"
            type={"number"}
          />
        </div>
      </div>
    </div>
  </div>
);

const Preview = ({ setPreview }) => {
  return (

    <ClickAwayListener onClickAway={e=>setPreview(false)}>
    <div className="Preview_Container container ">
      <header className="d-flex justify-content-between align-items-baseline border-bottom py-2 header_preview_model">
        <h4>Umra Information</h4>
        <GrClose
          size={25}
          onClick={() => setPreview(false)}
          style={{ cursor: "pointer" }}
        />
      </header>
      {/********************************  passenger information ************************** */}
      <div className="px-1 px-md-3">
        <div className="my-4 border-bottom pb-5">
          <h4>Passenger Information:</h4>
          <div className="col-6 col-sm-4 mt-2 mb-4 my-sm-4 ">
            <TextField
              variant="standard"
              label="Company"
              className="w-100"
              disabled
            />
          </div>

          <h5>Passenger Manual Information:</h5>
          <div className="mt-5 h4">1.</div>

          <div className="row align-items-end mt-3">
            <div className="col-4 col-sm-1">
              <TextField
                variant="standard"
                label="Title"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="First Name"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Last Name"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="DOB"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="PSPT No"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-1">
              <TextField
                variant="standard"
                label="Visa No"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Visa Supplier"
                className="w-100"
                disabled
              />
            </div>
          </div>
        </div>

        {/********************************  flight information ************************** */}
        <div className="my-4 border-bottom pb-2">
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start">
            <h4>Flight Information:</h4>
            <div className="d-flex flex-column my-1 mb-4 my-sm-4">
              <TextField variant="standard" label="ENR" className="" disabled />
            </div>
          </div>

          <div className="h6">Arrival ( Saudi Arabia )</div>
          <div className="row align-items-end mt-1 mt-sm-3">
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Flight"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Date"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Origin"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Destination"
                className="w-100"
                disabled
              />
            </div>

            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Departure Time"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Arrival"
                className="w-100"
                disabled
              />
            </div>
          </div>

          <div className="h6 mt-5">Departure ( Saudi Arabia )</div>
          <div className="row align-items-end mt-1 mt-sm-3 mb-4">
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Flight"
                className="w-100"
                disabled
              />
            </div>

            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Date"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Origin"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Destination"
                className="w-100"
                disabled
              />
            </div>

            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Departure Time"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Arrival"
                className="w-100"
                disabled
              />
            </div>
          </div>
        </div>

        {/********************************  Hotel information ************************** */}
        <div className="my-sm-4 border-bottom ">
          <h4 className="">Hotel Information:</h4>

          {/**************************hotel info */}
          <div className="d-flex  align-items-center  border-bottom pb-4">
            <div className="row align-items-end mt-1 mt-sm-3 mb-4">
              <div className="col-4 col-sm-2">
                <TextField
                  variant="standard"
                  label="From Date"
                  className="w-100"
                  disabled
                />
              </div>

              <div className="col-4 col-sm-2">
                <TextField
                  variant="standard"
                  label="To Date"
                  className="w-100"
                  disabled
                />
              </div>
              <div className="col-4 col-sm-2">
                <TextField
                  variant="standard"
                  label="Nights"
                  className="w-100"
                  disabled
                />
              </div>
              <div className="col-4 col-sm-2">
                <TextField
                  variant="standard"
                  label="Room Nature"
                  className="w-100"
                  disabled
                />
              </div>

              <div className="col-4 col-sm-2">
                <TextField
                  variant="standard"
                  label="City"
                  className="w-100"
                  disabled
                />
              </div>
              <div className="col-4 col-sm-2">
                <TextField
                  variant="standard"
                  label="Hotel"
                  className="w-100"
                  disabled
                />
              </div>
            </div>
          </div>

          {/************************ room info */}

          <div className="row align-items-end border-bottom py-4 pb-sm-4">
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Room"
                className="w-100"
                disabled
              />
            </div>

            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="Room Price"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="SAR ROE"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="REC SAR"
                className="w-100"
                disabled
              />
            </div>

            <div className="col-4 col-sm-2">
              <TextField
                variant="standard"
                label="PAY SAR"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-1">
              <TextField
                variant="standard"
                label="REC PKR"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-4 col-sm-1">
              <TextField
                variant="standard"
                label="PAY PKR"
                className="w-100"
                disabled
              />
            </div>
          </div>

          {/************************ totel payebel info *******************/}
          <div className="row  py-4 pb-5">
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Hotel Totel Recievable SAR"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Hotel Total Payable SAR"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Hotel Total Receivable PKR"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Hotel Total Payable PKR"
                className="w-100"
                disabled
              />
            </div>
          </div>
        </div>

        {/******************************** Transfer information ************************** */}
        <div className="my-4 border-bottom pb-5">
          <h4 className="my-5">Transfer Information:</h4>

          <div className="row  align-items-center pb-4">
            <div className="col-3 col-md-2">
              <TextField
                variant="standard"
                label="Route"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-3 col-md-2 ">
              <TextField
                variant="standard"
                label="Vehicle"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-3 col-md-2 ">
              <TextField
                variant="standard"
                label="Supplier"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-3 col-md-2 ">
              <TextField
                variant="standard"
                label="ROE"
                className="w-100"
                disabled
              />
            </div>
          </div>
          <div className="row  align-items-center ">
            <div className="col-3 col-md-2">
              <TextField
                variant="standard"
                label="vehicle"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-3 col-md-2 ">
              <TextField
                variant="standard"
                label="Recievable Amount"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-3 col-md-2 ">
              <TextField
                variant="standard"
                label="Payable Value"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-3 col-md-2 ">
              <TextField
                variant="standard"
                label="Payeble Amount"
                className="w-100"
                disabled
              />
            </div>
          </div>
        </div>

        {/******************************** Totel Vochers information ************************** */}
        <div className="my-4 ">
          <h4 className="my-5 ">Total Vochers Payeble</h4>

          <div className="row pb-4">
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Amount"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Recievable Amount"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Payebel Value"
                className="w-100"
                disabled
              />
            </div>
            <div className="col-6 col-md-3">
              <TextField
                variant="standard"
                label="Payebel Amount"
                className="w-100"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      {/***********************  foooter ************************************ */}

      <div className="d-flex justify-content-end align-items-center border-top py-2 footer_preview_model">
        <button
          onClick={() => setPreview(true)}
          className="btn btn-primary rounded-2 me-2"
        >
          Edit
        </button>
        <button
          onClick={() => setPreview(true)}
          className="btn btn-primary rounded-2 "
        >
          Submit
        </button>
      </div>
    </div>
    </ClickAwayListener>
  );
};

export default TransferInformation;
