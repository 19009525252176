import React from "react";
import Header from "../../src/components/Common/Header";
import ChequeBookHistory from "../components/Finance/Accounts/BankBook/ChequeBookHistory";

function ChequeBookHistoryPage() {
  return (
    <div>
      <ChequeBookHistory />
    </div>
  );
}

export default ChequeBookHistoryPage;
