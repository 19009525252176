import React from "react"
import FlightGroupDetails from "../components/Setup/Promotions/FlightGroupDetails"

const FlightGroup = () => {
    const fligtData = JSON.parse(localStorage.getItem('flight'));
  return (
    <div className="Full_height">
      <FlightGroupDetails fligtData={fligtData} />
  
    </div>
  )
}

export default FlightGroup
