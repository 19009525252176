import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncGetSingleVoucherEntries } from "../../../../redux/features/Voucher/VoucherSlice";
import { useLocation } from "react-router-dom";

const ManualVoucherLogsCreate = ({ voucher }) => {
const { SavedByUser,
    saved_date,
    approved_date,
    ApprovedByUser,
    VoucherLogModels,
  } = voucher?.at(0) || {};
  return (
    <div className="pt-2 pb-5">
      <div className="col-8 p-3">
        <div className="h4 p-3">Voucher Logs</div>
        <table className="table border">
          <thead className="table_heading">
            <tr>
              <th className="p-3">Date/Time</th>
              <th className="p-3">User Name</th>
              <th className="p-3">Logs</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-3">
                {saved_date}
                {/* {saved_date?.split("T")[1].slice(0, 5)} */}
              </td>
              <td className="p-3">
                {SavedByUser?.f_name + " " + SavedByUser?.l_name}
              </td>
              <td className="p-3">Created</td>
            </tr>

            {ApprovedByUser?.f_name && (
              <tr>
                <td className="p-3">{approved_date}</td>
                <td className="p-3">
                  {ApprovedByUser?.f_name + " " + ApprovedByUser?.l_name}
                </td>
                <td className="p-3">Posted</td>
              </tr>
            )}

            {voucher?.at(1)?.length
              ? voucher?.at(1)?.map((logs, ind) => (
                  <tr>
                    <td className="p-3">
                      {logs?.date_time}{" "}
                      {/* {logs?.date_time?.split("T")?.at(1).slice(0, 5)} */}
                    </td>
                    <td className="p-3">
                      {logs?.agents_user?.f_name +
                        " " +
                        logs?.agents_user?.l_name}
                    </td>
                    <td className="p-3">{logs?.description}</td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManualVoucherLogsCreate;
