import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import CustomDropdown from "../components/CustomDrp";
import "../Styles/manualInvoices.css";

function UmrahMnifestView() {
  const [startDate, setStartDate] = useState("");

  return (
    <div className=''>
      <div className="manual_invoices mx-auto mt-5">
        <div className="manual_invoices_header">
          <div className="w-100 text-center">Umrah Manifest</div>
        </div>
        <div className=" pt-5 px-2 px-md-5">
          <div className="row align-items-center">
            <div className="col-6">
              <ReactDatePicker
                placeholderText="From Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                minDate={new Date()}
                className="date_picker "
              />
            </div>
            <div className=" col-6">
              <ReactDatePicker
                placeholderText="To Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                minDate={new Date()}
                className="date_picker "
              />
            </div>
            <div className="col-6 mt-5">
              <CustomDropdown arry={['Umrah', 'Visa']} placehlder='Select Umrah' />
            </div>
          </div>
        </div>


        {/* generat report button */}
        <div className="d-flex justify-content-center align-items-center my-3 mt-5">
          <button className="button_style">Generate Report</button>
        </div>

      </div>
    </div>
  );
};




export default UmrahMnifestView;
