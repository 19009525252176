import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  visaType: [],
  editvisaType: null,
  loading: false,
  error: null,
};

// getting visaType data
export const asyncGetVisaType = createAsyncThunk(
  "asyncGetVisaType/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/visa_types`);
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// posting visaType data
export const asyncPostVisaType = createAsyncThunk(
  "asyncPostVisaType/post",
  async (postBody, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/visa_types`, postBody);
      dispatch(asyncGetVisaType());
      return await response?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// deleteing visaType data
export const asyncDeleteVisaType = createAsyncThunk(
  "asyncDeleteVisaType/delete",
  async (id, { rejectWithValue, dispatch }) => {
    console.log(id);
    try {
      const response = await NODEURL.get(`/api/v1/b2b/visa_type_delete/${id}`);
      dispatch(asyncGetVisaType());
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// edit visaType data
export const asyncEditVisatype = createAsyncThunk(
  "asyncEditVisatype/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/visa_types/${id}`);
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// update visaType data
export const asyncUpdatevisaType = createAsyncThunk(
  "asyncUpdatevisaType/patch",
  async (updateobj, { rejectWithValue, dispatch }) => {
    const { id, ...updatedata } = updateobj;
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/visa_types/${id}`,
        updatedata
      );
      dispatch(asyncGetVisaType());
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const asyncVisaTypeStatus = createAsyncThunk(
  "asyncVisaTypeStatus/update",
  async (status, id, { rejectWithValue, dispatch }) => {
    if (status === false) {
      var statusrow = 0;
    } else if (status === true) {
       statusrow = 1;
    } else if (status === 0) {
       statusrow = 0;
    } else if (status === 1) {
       statusrow = 1;
    }
    const formData = {
      id: id,
      status: statusrow,
    };
    try {
      const response = await NODEURL.post(
        `/api/v1/b2b/visa_service_status`,
        formData
      );
      console.log(response.data);
      return await response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const VisaTypeSlice = createSlice({
  name: "VisaTypeSlice",
  initialState,

  reducers: {
    editNull: (state) => {
      state.editvisaType = null;
    },
  },

  extraReducers: (builder) => {
    // getting visaType data
    builder.addCase(asyncGetVisaType.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetVisaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.visaType = payload;
    });
    builder.addCase(asyncGetVisaType.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting visaType data
    builder.addCase(asyncPostVisaType.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostVisaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.visaType = payload;
    });
    builder.addCase(asyncPostVisaType.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // deleting visaType data
    builder.addCase(asyncDeleteVisaType.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteVisaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.visaType = payload;
    });
    builder.addCase(asyncDeleteVisaType.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // edit visaType data
    builder.addCase(asyncEditVisatype.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditVisatype.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editvisaType = payload;
    });
    builder.addCase(asyncEditVisatype.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // update visaType data
    builder.addCase(asyncUpdatevisaType.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdatevisaType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.editvisaType = payload;
    });
    builder.addCase(asyncUpdatevisaType.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { editNull } = VisaTypeSlice.actions;

export default VisaTypeSlice.reducer;
