import {
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetChequeData } from "../../../redux/features/Payments/MakePaymentsSlice";
import { asyncPostCorporatePayment } from "../../../redux/features/Payments/PaymentsAllSlices";
import { toast } from "react-toastify";
import * as yup from "yup";

import "../../../Styles/setup.css";
import BtnLoader from "../../AppForm/BtnLoader";

export const basicSchema = yup.object().shape({
  officeId: yup.number().required("Required"),
  officeName: yup.string().required("Required"),
  amount: yup.number().positive("Must be positive number").required("Required"),
  // bankReceiptImage: yup.string().required("Required"),
  bankReceiptNumber: yup.string().required("Required"),
  bank: yup.string().required("Required"),
  bankName: yup.string().required("Required"),
  branch: yup.string().required("Required"),
  cheque_no: yup.number().required("Required"),
  date: yup.date().required("Required"),
});

const CorporateModal = ({ show, setShow, corporateData, Apidata }) => {
  console.log(Apidata)
  const dispatch = useDispatch();
  const [radio, setRadio] = useState("Cheque");
  const [file, setFile] = useState();
  const [bankData, setBankData] = useState();
  const { loading } = useSelector((state) => state.allPayments);

  const Schema = yup.object().shape({
    officeId: yup.number().required("Required"),
    officeName: yup.string().required("Required"),
    amount: yup
      .number()
      .positive("Must be positive number")
      .required("Required"),
    // bankReceiptImage: yup.string().required("Required"),
    bankReceiptNumber: yup.string().required("Required"),
    bank: yup.string().required("Required"),
  });

  const handleClose = () => setShow(false);

  const onSubmit = (values, { resetForm }) => {
    console.log(values);

    const formData = new FormData();

    formData.append("balance_selection", "");
    // formData.append("bank_account_id",bankData?.account_id)
    // formData.append("bank_account_id",bankData?.account_id ? bankData?.account_id:0)
    // this is for sending the bankAcountId to be null if there is no bank sellected
    bankData?.account_id &&
      formData.append("bank_account_id", bankData?.account_id);
    formData.append("bank_name", values?.bankName);
    formData.append("bank_receipt_no", values?.bankReceiptNumber);
    formData.append("branch", values?.branch);
    formData.append("cheque_date", values?.date);
    formData.append("cheque_no", values?.cheque_no);
    formData.append("company_id", values?.officeId);
    formData.append("doc_file", file);
    formData.append("fop", radio);

    formData.append("invoice_amount", 0);
    formData.append("office", values?.officeName);
    formData.append("office_id", values?.officeId);
    formData.append("other_amount", 0);
    formData.append("pay_and_add_balance", 0);
    formData.append("total_amount", values?.amount);

    if (values.cancelable === true) {
      return;
    }
    console.log(formData);
    dispatch(asyncPostCorporatePayment({ formData, toast }));
    resetForm({ values: "" });
    setShow(false);
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        officeId: corporateData?.id || "",
        officeName: corporateData?.company || "",
        amount: corporateData?.amount || "",
        bank: bankData?.name || "",
        bankReceiptNumber: "",
        // bankReceiptImage:  "",
        bankName: "",
        branch: "",
        cheque_no: "",
        date: "",
      },
      validationSchema: radio === "Cheque" ? basicSchema : Schema,
      // validationSchema:  basicSchema,
      onSubmit,
    });
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"modal-xl"}
        backdrop="static"
        keyboard={false}
        centered
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Corporate Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              <section className="row justify-content-between">
                <div className="col-sm-4 px-3">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Office ID *"
                    variant="standard"
                    className="col-12"
                    name="officeId"
                    error={touched.officeId && errors.officeId ? 1 : 0}
                    value={corporateData?.id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.officeId && errors.officeId ? errors.officeId : ""
                    }
                  />
                </div>
                <div className="col-sm-4 px-3">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Office Name *"
                    variant="standard"
                    className="col-12"
                    name="staffName"
                    value={corporateData?.company}
                    error={touched.officeName && errors.officeName ? 1 : 0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.officeName && errors.officeName
                        ? errors.officeName
                        : ""
                    }
                  />
                </div>
                <div className="col-sm-4 px-3">
                  <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    label="Amount *"
                    variant="standard"
                    className="col-12"
                    name="amount"
                    value={corporateData?.amount}
                    error={touched.amount && errors.amount ? 1 : 0}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.amount && errors.amount ? errors.amount : ""
                    }
                  />
                </div>
              </section>
              <hr className="mt-5" />
              <h5 className="section-text otawix-sub-heading">
                Select Bank for Confirm Deposit:
              </h5>
              <hr />
              <section className="row justify-content-between mt-1">
                <div className="col-sm-4">
                  <FormControl variant="standard" className="w-100 mt-3">
                    <InputLabel id="demo-simple-select-standard-label">
                      Bank Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      label="Bank"
                      name="bank"
                      defaultValue="Select-Bank"
                      error={touched.bank && errors.bank ? 1 : 0}
                      value={values.bank}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.bank && errors.bank ? errors.bank : ""
                      }
                    >
                      <MenuItem value="Select-Bank">Select Bank</MenuItem>
                      {Apidata?.banks?.map((bank) => {
                        return (
                          <MenuItem
                            value={bank.name}
                            key={bank.id}
                            onClick={() => {
                              setBankData(bank);
                              // // dispatch(emptyBankData())
                              // setMakePayment({ ...MakePayment, cheque_no: "" });
                            }}
                          >
                            {` ${bank.name} - ${bank.account_no} - ${bank.beneficiary_name}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-sm-4">
                  <TextField
                    variant="standard"
                    label="Bank Receipt Number "
                    type="text"
                    name="bankReceiptNumber"
                    value={values.bankReceiptNumber}
                    error={
                      touched.bankReceiptNumber && errors.bankReceiptNumber
                        ? 1
                        : 0
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      touched.bankReceiptNumber && errors.bankReceiptNumber
                        ? errors.bankReceiptNumber
                        : ""
                    }
                    className=" inputs_visa_tab mt-3"
                  />
                </div>
                <div className="col-sm-4">
                  <TextField
                    variant="standard"
                    label="Bank receipt Image *"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                    className=" inputs_visa_tab mt-3"
                    placeholder=""
                  />
                </div>
              </section>
              <div className="col-sm-5 d-flex justify-content-between mt-3">
                <label class="form-control">
                  <input
                    type="radio"
                    id="Cheque"
                    value="Cheque"
                    checked={radio === "Cheque"}
                    onChange={(e) => setRadio(e.target.value)}
                  />
                  <span className="h6">Cheque</span>
                </label>

                <label class="form-control">
                  <input
                    type="radio"
                    id="IBFT"
                    value="IBFT"
                    checked={radio === "IBFT"}
                    onChange={(e) => setRadio(e.target.value)}
                  />
                  <span className="h6">IBFT</span>
                </label>
                <label class="form-control">
                  <input
                    type="radio"
                    id="cash"
                    value="cash"
                    checked={radio === "cash"}
                    onChange={(e) => setRadio(e.target.value)}
                  />
                  <span className="h6">Cash</span>
                </label>
              </div>
              {radio === "Cheque" && (
                <section className="row align-items-end bank-info mt-3">
                  <div className="col-sm-4 px-3">
                    <TextField
                      label="Bank Name *"
                      variant="standard"
                      className="col-12"
                      name="bankName"
                      value={values.bankName}
                      error={touched.bankName && errors.bankName ? 1 : 0}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.bankName && errors.bankName
                          ? errors.bankName
                          : ""
                      }
                    />
                  </div>
                  <div className="col-sm-4 px-3">
                    <TextField
                      label="Branch *"
                      variant="standard"
                      className="col-12"
                      name="branch"
                      value={values.branch}
                      error={touched.branch && errors.branch ? 1 : 0}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.branch && errors.branch ? errors.branch : ""
                      }
                    />
                  </div>
                  <div className="col-sm-4 px-3">
                    <TextField
                      // label="Cheque Date *"
                      variant="standard"
                      className="col-12"
                      name="date"
                      type={"date"}
                      defaultValue={Date.now()}
                      error={touched.date && errors.date ? 1 : 0}
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.date && errors.date ? errors.date : ""
                      }
                    />
                  </div>
                  <div className="d-flex flex-column mt-5">
                    <span>Cheque No. *</span>
                    <TextField
                      className="col-sm-12 mt-3"
                      name="cheque_no"
                      value={values.cheque_no}
                      error={touched.cheque_no && errors.cheque_no ? 1 : 0}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        touched.cheque_no && errors.cheque_no
                          ? errors.cheque_no
                          : ""
                      }
                    />
                  </div>
                </section>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              cancel
            </Button>
            <Button variant="secondary" type="submit" onClick={onSubmit}>
              {loading ? <BtnLoader /> : "save"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CorporateModal;
