import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP } from "../ApiConfig";

const initialState = {
  carTypes: [],
  umrahRoutes: [],
  showRouteModal: false,
  carTypeModel: false,
  loading: false,

  routeLoader: false,
  editRouteData: null,

  vehicleLoader: false,
  editVehicleData: null,

  error: null,
};

// getting transfer data
export const asyncGetUmrahTransfer = createAsyncThunk(
  "asyncGetUmrahTransfer/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/umrah_transfer`);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// ******************************************************* vehcile section  *******************************************************
// posting vehicles data
export const asyncPostVehicle = createAsyncThunk(
  "asyncPostVehicle/post",
  async (vehicle, { rejectWithValue, dispatch }) => {
    console.log(vehicle);
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/umrah_transfer/car_type_create`,
        { vehicle }
      );
      console.log(await response.data.data);
      // toast.success("vehicle added");
      dispatch(asyncGetUmrahTransfer());
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit vehicles data
export const asyncEditVehicle = createAsyncThunk(
  "asyncEditVehicle/post",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/umrah_transfer/car_edit/${id}`
      );
      // toast.success("vehicle added");
      return await response.data.data.UmrahCarTypeModel_edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// update vehicles data
export const asynUpdateVehicle = createAsyncThunk(
  "asynUpdateVehicle/post",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/umrah_transfer/car_update`,
        values
      );
      // toast.success("vehicle added");
      dispatch(asyncGetUmrahTransfer());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// ******************************************************* routes section  *******************************************************
// posting routes data
export const asyncPostUmrahRoutes = createAsyncThunk(
  "asyncPostUmrahRoutes/post",
  async (route, { rejectWithValue, dispatch }) => {
    console.log(route);

    try {
      const response = await HTTP.post(
        `/api/v1/b2b/umrah_transfer/route_create`,
        { route }
      );
      console.log(await response.data.data);
      // toast.success("umrah Route added");
      dispatch(asyncGetUmrahTransfer());
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit routes data
export const asyncEditRoute = createAsyncThunk(
  "asyncEditRoute/post",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/umrah_transfer/route_edit/${id}`
      );
      // toast.success("routes added");
      // console.log(response.data.data);
      return await response.data.data.UmrahRouteModel_edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// update routes data
export const asyncUpdateRoute = createAsyncThunk(
  "asyncUpdateRoute/post",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/umrah_transfer/route_update`,
        values
      );
      // toast.success("vehicle added");
      dispatch(asyncGetUmrahTransfer());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const HotelSlice = createSlice({
  name: "HotelSlice",
  initialState,

  reducers: {
    editVehicleDataNull: (state) => {
      state.editVehicleData = null;
    },
    editRouteDataNull: (state) => {
      state.editRouteData = null;
    },
  },
  extraReducers: (builder) => {
    // getting transfer
    builder.addCase(asyncGetUmrahTransfer.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetUmrahTransfer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.carTypes = payload.cartypes;
      state.umrahRoutes = payload.routes;
    });
    builder.addCase(asyncGetUmrahTransfer.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // ******************************************************* vehicle section  *******************************************************
    // posting vehicles data
    builder.addCase(asyncPostVehicle.pending, (state, { payload }) => {
      state.vehicleLoader = true;
      state.error = null;
    });
    builder.addCase(asyncPostVehicle.fulfilled, (state, { payload }) => {
      state.vehicleLoader = false;
      state.error = null;
    });
    builder.addCase(asyncPostVehicle.rejected, (state, { payload }) => {
      state.vehicleLoader = false;
      state.error = payload;
    });

    // edit vehicles data
    builder.addCase(asyncEditVehicle.pending, (state, { payload }) => {
      state.vehicleLoader = true;
      state.error = null;
    });
    builder.addCase(asyncEditVehicle.fulfilled, (state, { payload }) => {
      state.vehicleLoader = false;
      state.editVehicleData = payload;
      state.error = null;
    });
    builder.addCase(asyncEditVehicle.rejected, (state, { payload }) => {
      state.vehicleLoader = false;
      state.error = payload;
    });

    // update vehicles data
    builder.addCase(asynUpdateVehicle.pending, (state, { payload }) => {
      state.vehicleLoader = true;
      state.error = null;
    });
    builder.addCase(asynUpdateVehicle.fulfilled, (state, { payload }) => {
      state.vehicleLoader = false;
      state.editVehicleData = payload;
      state.error = null;
    });
    builder.addCase(asynUpdateVehicle.rejected, (state, { payload }) => {
      state.vehicleLoader = false;
      state.error = payload;
    });

    // ******************************************************* routes section  *******************************************************
    // posting routes  data
    builder.addCase(asyncPostUmrahRoutes.pending, (state, { payload }) => {
      state.routeLoader = true;
      state.error = null;
    });
    builder.addCase(asyncPostUmrahRoutes.fulfilled, (state, { payload }) => {
      state.routeLoader = false;
      state.error = null;
      // state.carTypes = payload.cartypes;
      // state.umrahRoutes = payload.routes;
    });
    builder.addCase(asyncPostUmrahRoutes.rejected, (state, { payload }) => {
      state.routeLoader = false;
      state.error = payload;
    });

    // edit route data
    builder.addCase(asyncEditRoute.pending, (state, { payload }) => {
      state.routeLoader = true;
      state.error = null;
    });
    builder.addCase(asyncEditRoute.fulfilled, (state, { payload }) => {
      state.routeLoader = false;
      state.editRouteData = payload;
      state.error = null;
    });
    builder.addCase(asyncEditRoute.rejected, (state, { payload }) => {
      state.routeLoader = false;
      state.error = payload;
    });

    // update route data
    builder.addCase(asyncUpdateRoute.pending, (state, { payload }) => {
      state.routeLoader = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateRoute.fulfilled, (state, { payload }) => {
      state.routeLoader = false;
      state.editRouteData = payload;
      state.error = null;
    });
    builder.addCase(asyncUpdateRoute.rejected, (state, { payload }) => {
      state.routeLoader = false;
      state.error = payload;
    });
  },
});

export const { editVehicleDataNull, editRouteDataNull } = HotelSlice.actions;

export default HotelSlice.reducer;
