import React, { useEffect, useState } from "react";
import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import DeleteModal from "../../DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncDeleteTPCredential,
  asyncGetSingleTPApiCred,
  asyncGetTPApiCredentials,
  asyncPostTPApiCredentials,
  asyncUpdateTPCredential,
  editTPCredentialNull,
} from "../../../redux/features/setupFeature/ApiCredential/TPCredentialSlice";
import { ChangeStatus } from "../../../Utils/ChangeStatus";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dropMenuProp } from "../../../Utils/MenuProps";

function TravelPorts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //use selector
  const { TPCredentialsData, loading, editTPCredentials } = useSelector(
    (state) => state.tpCredential
  );
  const { newSupplier } = useSelector((state) => state.supplier);
  //use state
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState(false);

  //use effect functions
  useEffect(() => {
    dispatch(asyncGetTPApiCredentials());
  }, [dispatch]);

  //  table columns
  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/tp-credential/status",
                      !value,
                      id,
                      "status"
                    );
                  }}
                />
                <label htmlFor={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Agent Name",
      name: "agent_name",
    },
    {
      label: "PCC",
      name: "pcc",
    },
    {
      label: "TP Api Key",
      name: "tp_uapi_key",
    },
    {
      label: "TP Api Password",
      name: "tp_uapi_password",
    },

    {
      label: "TP Target Branch",
      name: "tp_target_branch",
    },
    {
      label: "TP Service URL 1",
      name: "tp_service_url1",
    },
    {
      label: "TP Service URL 2",
      name: "tp_service_url2",
    },
    {
      label: "Prohibited CRR",
      name: "prohibited_crr",
    },
    {
      label: "Allow CRR",
      name: "allow_crr",
    },
    {
      name: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncGetSingleTPApiCred(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button me-1"
                onClick={() => {
                  setShowModel(true);
                  setRowData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-thin fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  //initital value
  const initialValues = {
    agent_name: editTPCredentials?.agent_name || "",
    pcc: editTPCredentials?.pcc || "",
    tp_uapi_key: editTPCredentials?.tp_uapi_key || "",
    tp_uapi_password: editTPCredentials?.tp_uapi_password || "",
    tp_target_branch: editTPCredentials?.tp_target_branch || "",
    tp_service_url1: editTPCredentials?.tp_service_url1 || "",
    tp_service_url2: editTPCredentials?.tp_service_url2 || "",
    prohibited_crr: editTPCredentials?.prohibited_crr || "",
    allow_crr: editTPCredentials?.allow_crr || "",
    ticket_status: editTPCredentials?.ticket_status || 1,
  };

  //validation schemas
  const validationSchema = yup.object().shape({
    agent_name: yup.string().required("Agent Name is required"),
    pcc: yup.string().required("PCC is required"),
    tp_uapi_key: yup.string().required("TravelPort api key is required"),
    tp_uapi_password: editTPCredentials
      ? yup.string()
      : yup.string().required("Travelport Password is required"),
    tp_target_branch: yup.string().required("Target branch is required"),
    tp_service_url1: yup.string().required("Service url 1 is required"),
    tp_service_url2: yup.string().required("Service url 2 is required"),
    prohibited_crr: yup
      .string()
      .matches(/^[\w,]+$/, "Only comma-separated values are allowed")
      .test("valid-format", "Invalid format", (value) => {
        if (!value) return true; // Empty value is allowed
        const values = value.split(",");
        return values.every((item) => /^\w+$/.test(item));
      })
      .required("Prohibited crr is required"),

    allow_crr: yup
      .string()
      .matches(/^[\w,]+$/, "Only comma-separated values are allowed")
      .test("valid-format", "Invalid format", (value) => {
        if (!value) return true; // Empty value is allowed
        const values = value.split(",");
        return values.every((item) => /^\w+$/.test(item));
      })
      .required("Allow crr is required"),
  });

  return (
    <div>
      <div>
        <div className="mx-5 my-5 bg-white rounded-2">
          <div className="d-flex align-items-center bg_primary text-white py-3">
            <div className="col-5">
              <span
                className="cursorpointer fs-5 w-content"
                onClick={() => navigate("/api-credentials")}
              >
                <MdArrowBackIosNew /> Back
              </span>
            </div>
            <div className="box_title px-2">Add / Edit Travel Port</div>
          </div>

          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let id = editTPCredentials?.id;
              if (editTPCredentials) {
                dispatch(asyncUpdateTPCredential({ id, values }));
                dispatch(editTPCredentialNull());
              } else {
                dispatch(asyncPostTPApiCredentials(values));
              }
              resetForm({});
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div className="row px-4 pb-3 align-items-start pt-2">
                    <div className="col-2 mt-4 ">
                    <Autocomplete
                        id="size-small-standard"
                        size="small"
                        options={newSupplier}
                        getOptionLabel={(option) => option.supp_name}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setFieldValue("supplier", newValue);
                            setFieldValue("agent_name", newValue.supp_name);
                          } else {
                            setFieldValue("supplier", "");
                            setFieldValue("agent_name", "");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            variant="standard"
                            label="Select Agent Name"
                          />
                        )}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="agent_name"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="PCC"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="pcc"
                        value={values.pcc}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("pcc", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="pcc"
                      />
                    </div>

                    <div className="col-2 mt-4 ">
                      <TextField
                        label="TP Api Key"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="tp_uapi_key"
                        value={values.tp_uapi_key}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("tp_uapi_key", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="tp_uapi_key"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="TP Api Password"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="tp_uapi_password"
                        value={values.tp_uapi_password}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("tp_uapi_password", value);
                        }}
                      />

                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="tp_uapi_password"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="TP Target Branch"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="tp_target_branch"
                        value={values.tp_target_branch}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("tp_target_branch", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="tp_target_branch"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <TextField
                        label="TP Service URL 1"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="tp_service_url1"
                        value={values.tp_service_url1}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="tp_service_url1"
                      />
                    </div>
                  </div>
                  <div className="row px-4 pb-3 align-items-start pt-2">
                    <div className="col-2 mt-4">
                      <TextField
                        label="TP Service URL 2"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="tp_service_url2"
                        value={values.tp_service_url2}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="tp_service_url2"
                      />
                    </div>

                    <div className="col-2  mt-4">
                      <TextField
                        label="Prohibited CRR"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="prohibited_crr"
                        value={values.prohibited_crr}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("prohibited_crr", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="prohibited_crr"
                      />
                    </div>

                    <div className="col-2  mt-4">
                      <TextField
                        label="Allow CRR"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="allow_crr"
                        value={values.allow_crr}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("allow_crr", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="allow_crr"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id={`ticket_status`}>
                          Ticket Status
                        </InputLabel>
                        <Select
                          id={`ticket_status`}
                          value={values.ticket_status}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("ticket_status", value);
                          }}
                          MenuProps={dropMenuProp}
                          label={"Ticket Status"}
                        >
                          <MenuItem value={1}>YES</MenuItem>
                          <MenuItem value={0}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-12 text-center py-3 pb-4">
                    <button className="button_style px-5 me-3" type="submit">
                      {loading ? (
                        <BtnLoader />
                      ) : editTPCredentials ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </button>
                    {editTPCredentials && (
                      <button
                        type="button"
                        className="button_style px-5 bg-danger"
                        onClick={() => dispatch(editTPCredentialNull())}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/*// Credensiol list  ////////*/}

        <div className="mx-5 shadow-sm rounded-2 my-4 bg-white">
          <p className="otawix-heading box_title py-2">
            Travel Port Credentials List
          </p>
          <div className="p-4">
            <DeleteModal
              show={ShowModel}
              setShow={setShowModel}
              onClick={(e) => {
                dispatch(asyncDeleteTPCredential(rowData[0]));
                setShowModel(false);
              }}
            />
            <MUIDataTable
              className="muidatatable"
              title={""}
              data={TPCredentialsData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TravelPorts;
