// i have added
// (1) Bank Detail page
// (2) currency page
// (3) add Role page
// (4) Passsword Api page
// pages in this slice from Setup section

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  //bank details state
  bankDetails: [],
  editBankDetails: null,

  // currency page state
  currencyData: [],
  editCurrency: null,

  // role page state
  roleData: [],
  editRole: null,

  // loading: false,
  error: null,
};

// ******************************************* Bank Details SECTION *************************************************************************
// get BankDetailData
export const asyncGetBankDetailData = createAsyncThunk(
  "asyncGetBankDetailData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/bank-details");
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// post BankDetailData
export const asyncPostBankDetailData = createAsyncThunk(
  "asyncPostBankDetailData/post",
  async (obj, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/bank-details", obj);
      //   dispatch(asyncGetBankDetailData());
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// edit BankDetailData
export const asyncEditBankDetailData = createAsyncThunk(
  "asyncEditBankDetailData/edit",
  async (rowId, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/bank-details/${rowId}`);
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// update BankDetailData
export const asyncUpdateBankDetailData = createAsyncThunk(
  "asyncUpdateBankDetailData/Update",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/bank-details/${data?.bank_id}`,
        data
      );
      dispatch(asyncGetBankDetailData());
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// ******************************************* Currency SECTION *************************************************************************
export const asyncGetCurrencyData = createAsyncThunk(
  "asyncGetCurrencyData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/currency");
      return await response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// posting function
export const asyncPostCurrencyData = createAsyncThunk(
  "asyncPostCurrencyData/post",
  async (obj, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/currency", obj);

      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// edit BankDetailData
export const asyncEditCurrencyData = createAsyncThunk(
  "asyncEditCurrencyData/edit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/currency/${id}`);
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
// update BankDetailData
export const asyncUpdateCurrencyData = createAsyncThunk(
  "asyncUpdateCurrencyData/Update",
  async ({ id, obj }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch("/api/v1/b2b/currency/" + id, obj);
      dispatch(asyncGetBankDetailData());
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// ******************************************* Role SECTION *************************************************************************
// get RoleData
export const asyncGetRoleData = createAsyncThunk(
  "asyncGetRoleData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/role");
      return await response.data.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
//post RoleData
export const asyncPostRoleData = createAsyncThunk(
  "asyncPostRoleData/post",
  async (name, { rejectWithValue, dispatch }) => {
    console.log(name);
    try {
      const response = await NODEURL.post("/api/v1/b2b/role", {
        role_name: name,
      });
      dispatch(asyncGetRoleData());
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
//Edit RoleData
export const asyncEditRoleData = createAsyncThunk(
  "asyncEditRoleData/Edit",
  async (rowId, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/single-role/" + rowId);
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
//Update RoleData
export const asyncUpdateRoleData = createAsyncThunk(
  "asyncUpdateRoleData/Update",
  async ({ id, role_name }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.put(`/api/v1/b2b/role/${id}`, {
        role_name,
      });
      dispatch(asyncGetRoleData());
      return await response?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

//  *********************************** bank section *****************
export const Bank_curr_RoleSlice = createSlice({
  name: "Bank_curr_RoleSlice",
  initialState,
  reducers: {
    // bank details reducer
    editBankDetails_null(state) {
      state.editBankDetails = null;
    },
    // currency page reducer
    editCurrency_null(state) {
      state.editCurrency = null;
    },
    // role page reducer
    editRole_null(state) {
      state.editRole = null;
    },
  },
  extraReducers: (builder) => {
    // ******************************************* Bank Details SECTION *************************************************************************
    //get asyncGetBankDetailData
    builder.addCase(asyncGetBankDetailData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetBankDetailData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.bankDetails = payload;
    });
    builder.addCase(asyncGetBankDetailData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post asyncPostBankDetailData
    builder.addCase(asyncPostBankDetailData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostBankDetailData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.bankDetails = [...state.bankDetails, payload];
    });
    builder.addCase(asyncPostBankDetailData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // edit asyncEditBankDetailData
    builder.addCase(asyncEditBankDetailData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditBankDetailData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editBankDetails = payload;
    });
    builder.addCase(asyncEditBankDetailData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update asyncUpdateBankDetailData
    builder.addCase(asyncUpdateBankDetailData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncUpdateBankDetailData.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.bankDetails = payload;
      }
    );
    builder.addCase(
      asyncUpdateBankDetailData.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // ******************************************* Currency SECTION *************************************************************************
    builder.addCase(asyncGetCurrencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetCurrencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.currencyData = payload;
    });
    builder.addCase(asyncGetCurrencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // posting
    builder.addCase(asyncPostCurrencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostCurrencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      console.log(payload);
      // state.currencyData = [...state.currencyData, payload];
      state.currencyData.push(payload);
    });
    builder.addCase(asyncPostCurrencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Edit
    builder.addCase(asyncEditCurrencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditCurrencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editCurrency = payload;
    });
    builder.addCase(asyncEditCurrencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update
    builder.addCase(asyncUpdateCurrencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateCurrencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateCurrencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // ******************************************* Role SECTION *************************************************************************
    builder.addCase(asyncGetRoleData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetRoleData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.roleData = payload;
    });
    builder.addCase(asyncGetRoleData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    //postin role data
    builder.addCase(asyncPostRoleData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostRoleData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.roleData = payload;
    });
    builder.addCase(asyncPostRoleData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //edtit Role data
    builder.addCase(asyncEditRoleData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditRoleData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editRole = payload;
    });
    builder.addCase(asyncEditRoleData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //Update Role data
    builder.addCase(asyncUpdateRoleData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateRoleData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.roleData = payload
    });
    builder.addCase(asyncUpdateRoleData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { editBankDetails_null, editCurrency_null, editRole_null } =
  Bank_curr_RoleSlice.actions;
export default Bank_curr_RoleSlice.reducer;
