import React, { useState } from "react";
import MultiAmadeusCard from "./MultiAmadeusCard";

const MultiAmadeus = ({
  amadeusItem,
  index,
  toggleDetail,
  expandFlightDetail,
  selectedFlight,
}) => {
  const [showOtherOption, setShowOtherOption] = useState(false);

  return (
    <div
      className="search_engin_result_box flight_card_shadow bg-white"
      id={`amadeus${index}`}
    >
      <MultiAmadeusCard
        amadeusItem={amadeusItem}
        selectedFlight={selectedFlight}
        key={index}
        index={"amadeus" + index}
        isExpanded={expandFlightDetail === "amadeus" + index}
        toggleDetail={toggleDetail}
        showOtherOption={showOtherOption}
        setShowOtherOption={setShowOtherOption}
      />

      {showOtherOption ? (
        <div>
          {amadeusItem?.otherOptions?.map((otherOption, index) => {
            return (
              <MultiAmadeusCard
                amadeusItem={otherOption}
                key={index}
                index={"amadeus" + index}
                isExpanded={expandFlightDetail === "amadeus" + index}
                toggleDetail={toggleDetail}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default MultiAmadeus;
