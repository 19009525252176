import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Common/Header";
import Table from "../components/Requests/Table";

const Request = () => {
  return (
    <div className="Full_height">
      <Table />
    </div>
  );
};

export default Request;
