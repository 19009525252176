import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, HTTPForm, NODEURL } from "../ApiConfig";

const initialState = {
  companyList: [],
  corporatePayment:[],
  carporateApiData:[],

  AgentsPayments:[],
  agentCompanyList:[],

  receiptData:[],
  companyData:[],
  collectionData:[],
  receiptPrintData:[],

  adminUnPaidVoucher:[],
  adminPaidVoucher:[],

  loading: false,
  error: null,
  errorMessage:null
};

// i created the rest of the slices here because these were not too much and created them in this only make payment has a separate slice.

// getting company data
export const asyncGetCompany = createAsyncThunk(
  "asyncGetCompany/get",
  async (query, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/only_corporate?q=${query}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



// post corporate payments data
export const asyncPostCorporatePayment = createAsyncThunk(
  "asyncPostCorporatePayment/post",
  async ({formData,toast}, { rejectWithValue }) => {
    try {
      const response = await HTTP.post(`/api/v1/b2b/make_payment/generate_payment`,formData);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get corporate payments data
export const asyncGetCorporatePayment = createAsyncThunk(
  "asyncGetCorporatePayment/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/make_payment/corporate_payment`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// *******************************AGENTS PAYEMENTS SECTION ************************************************

// getting agents company data
export const asyncGetAgentCompany = createAsyncThunk(
  "asyncGetAgentCompany/get",
  async (query, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/only_travel_agent?q=${query}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// post agents payments data
export const asyncPostAgentsPayment = createAsyncThunk(
  "asyncPostAgentsPayment/post",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(`/api/v1/b2b/add-agent-payment`, formData);
      dispatch(asyncGetCollectionData())
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// *******************************Receipts SECTION ************************************************

// getting Receipt data
export const asyncGetReceiptData = createAsyncThunk(
  "asyncGetReceiptData/get",
  async (agentId, { rejectWithValue }) => {
    try {
      // const response = await NODEURL.get(`/api/v1/b2b/getAllRV?agent_acc_id=${agentId}`);
      const response = await NODEURL.get(`/api/v1/b2b/get-paid-collection?agent_acc_id=${agentId}`);
      return await response.data.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// // getting the Receipt data for printing
// export const asyncGetReceiptDeposit = createAsyncThunk(
//   "asyncGetReceiptDeposit/post",
//   async (id, { rejectWithValue }) => {
//     try {
//       const response = await HTTP.post(`/api/v1/b2b/make_payment/paid_deposits/deposit_data`,{id:id});
//       // console.log(response.data.data);
//       return await response.data.data;
//     } catch (error) {
//       console.log(error);
//       return rejectWithValue(error);
//     }
//   }
// );


// *******************************Collection SECTION ************************************************

// getting collection data
export const asyncGetCollectionData = createAsyncThunk(
  "asyncGetCollectionData/get",
  async (agentId, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/get-pending-collection?agent_acc_id=${agentId}`);
      return await response.data.data
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// deleting collection data
export const asyncDeleteCollectionData = createAsyncThunk(
  "asyncDeleteCollectionData/delete",
  async (rowId, { rejectWithValue,dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/delete-agent-payment/${rowId}`);
      dispatch(asyncGetCollectionData())
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// confirm bank status
export const asyncConfirmBankStatus = createAsyncThunk(
  "asyncConfirmBankStatus/post",
  async (confirm_body, { rejectWithValue,dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/process-the-collection`, confirm_body)
      dispatch(asyncGetCollectionData())
      // return await response.data;
    } catch (error) {
      dispatch(setErrorMessage(error.response.data.message))
      return rejectWithValue(error.response.data.message);
    }
  }
);

// *******************************Admin UnPaid SECTION ************************************************

// getting Admin unpaid data
export const asyncGetAdminUnPaidVoucher = createAsyncThunk(
  "asyncGetAdminUnPaidVoucher/get",
  async (query, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/make_payment/admin_paid_vouchers`);
      return await response.data.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

//confirming the banks status of unpaid data
export const asyncConfirmSupBankStatus = createAsyncThunk(
  "asyncConfirmSupBankStatus/get",
  async (data, { rejectWithValue,dispatch }) => {
    console.log(data);
    try {
      const response = await HTTP.post(`/api/v1/b2b/make_payment/confirm_sup_bank_status`,{
        type:null,
        v_id:data.id,
        vouch_date:data.date,
      });
      dispatch(asyncGetAdminUnPaidVoucher())
      console.log(response.data);
      return await response.data.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

//delete admin_vouchers of unpaid data
export const asyncDeleteAdminVoucher = createAsyncThunk(
  "asyncDeleteAdminVoucher/delete",
  async (id, { rejectWithValue,dispatch }) => {
    try {
      const response = await HTTP.post(`/api/v1/b2b/make_payment/admin_vouchers-destroy/${id}`);
      dispatch(asyncGetAdminUnPaidVoucher())
      console.log(response.data);
      return await response.data.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// *******************************Admin Paid SECTION ************************************************

// getting Admin Paid Voucher
export const asyncGetAdminPaidVoucher = createAsyncThunk(
  "asyncGetAdminPaidVoucher/get",
  async (query, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/make_payment/admin_vouchers`);
      return await response.data.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);


export const MakePaymentAllSlices = createSlice({
  name: "MakePaymentAllSlices",
  initialState,

  reducers:{
    // i created this reducer to store the company data from array and the data from the row of the MUI with onClick
    ReceiptDepositPrint:(state,{payload})=>{
      console.log(payload);
      state.companyData={...state.companyData, payload}
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  },
  extraReducers: (builder) => {

    // getting company data
    builder.addCase(asyncGetCompany.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.companyList = payload;
     
    });
    builder.addCase(asyncGetCompany.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post corporate payments
    builder.addCase(asyncPostCorporatePayment.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostCorporatePayment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.corporatePayment = payload;
     
    });
    builder.addCase(asyncPostCorporatePayment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // get corporate payments data
    builder.addCase(asyncGetCorporatePayment.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetCorporatePayment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.carporateApiData = payload;
     
    });
    builder.addCase(asyncGetCorporatePayment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

// *******************************AGENTS PAYEMENTS SECTION ************************************************

    // post agents payments
    builder.addCase(asyncPostAgentsPayment.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostAgentsPayment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostAgentsPayment.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // getting agents company data
       builder.addCase(asyncGetAgentCompany.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(asyncGetAgentCompany.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.agentCompanyList = payload;
       
      });
      builder.addCase(asyncGetAgentCompany.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  
      // *******************************Receipt SECTION ************************************************

    // Reciept data
    builder.addCase(asyncGetReceiptData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetReceiptData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.receiptData = payload;
      state.companyData = payload
    });
    builder.addCase(asyncGetReceiptData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

        // // Reciept print data
        // builder.addCase(asyncGetReceiptDeposit.pending, (state, { payload }) => {
        //   state.loading = true;
        //   state.error = null;
        // });
        // builder.addCase(asyncGetReceiptDeposit.fulfilled, (state, { payload }) => {
        //   state.loading = false;
        //   state.error = null;
        //   state.receiptPrintData = payload;
        // });
        // builder.addCase(asyncGetReceiptDeposit.rejected, (state, { payload }) => {
        //   state.loading = false;
        //   state.error = payload;
        // });
      // *******************************Collection SECTION ************************************************

    // Collection data
    builder.addCase(asyncGetCollectionData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetCollectionData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.collectionData = payload;
    });
    builder.addCase(asyncGetCollectionData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
        //delete Collection
        builder.addCase(asyncDeleteCollectionData.pending, (state, { payload }) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(asyncDeleteCollectionData.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.error = null;
        });
        builder.addCase(asyncDeleteCollectionData.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });

           //posting bankStatus Collection
           builder.addCase(asyncConfirmBankStatus.pending, (state, { payload }) => {
            state.loading = true;
            state.error = null;
          });
          builder.addCase(asyncConfirmBankStatus.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
            // state.collectionData = payload;
          });
          builder.addCase(asyncConfirmBankStatus.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
          });
         // *******************************Admin Unpaid Voucher SECTION ************************************************

    // Admin unpaid Voucher data
    builder.addCase(asyncGetAdminUnPaidVoucher.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAdminUnPaidVoucher.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.adminUnPaidVoucher = payload;
    });
    builder.addCase(asyncGetAdminUnPaidVoucher.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

       // confirm bank status of unpaid Voucher 
       builder.addCase(asyncConfirmSupBankStatus.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(asyncConfirmSupBankStatus.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.adminUnPaidVoucher = payload;
      });
      builder.addCase(asyncConfirmSupBankStatus.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

       // delete admin voucher of unpaid Voucher 
       builder.addCase(asyncDeleteAdminVoucher.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(asyncDeleteAdminVoucher.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        // state.adminUnPaidVoucher = payload;
      });
      builder.addCase(asyncDeleteAdminVoucher.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });

        // *******************************Admin paid Voucher SECTION ************************************************

    // Admin paid Voucher data
    builder.addCase(asyncGetAdminPaidVoucher.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAdminPaidVoucher.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.adminPaidVoucher = payload;
    });
    builder.addCase(asyncGetAdminPaidVoucher.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

  },
});

export const {ReceiptDepositPrint,setErrorMessage} = MakePaymentAllSlices.actions
export default MakePaymentAllSlices.reducer;
