import React, { useEffect, useState } from "react";
import "../Styles/manualInvoices.css";
import ReactDatePicker from "react-datepicker";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetSaleReportData,
  setChangeInvoice,
} from "../redux/features/Finance/Sale/SaleReportSlice";
import Reports from "../components/Reports/Reports";
import BtnLoader from "../components/AppForm/BtnLoader";
import { asyncGetAdmin } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

function TransactionalReport() {
  const dispatch = useDispatch();
  const { saleReportData, changeInvoice } = useSelector(
    (state) => state.SaleReport
  );
  const { searchAgency, getAdminData } = useSelector(
    (state) => state.newAgency
  );
  const [transactionalType, setTransactionalType] = useState("");
  const [saleReport, setSaleReport] = useState({
    fromDate: new Date(),
    toDate: new Date(),
    voucher_type_id: [],
    VoucherName: "",
  });

  const arry = [
    { id: 7, title: "Select All" },
    { id: 1, title: "Journal Voucher" },
    { id: 2, title: "Payment Voucher" },
    {
      id: 3,
      title: "Reciept Voucher",
    },
  ];
  useEffect(() => {
    dispatch(asyncGetAdmin({ id: 1 }));
  }, {});
  const [message, setMessage] = useState(true);
  const handleGenerateReport = () => {
    let queryParams = {
      ...saleReport,
    };
    setMessage(false);
    setTimeout(() => {
      dispatch(asyncGetSaleReportData(queryParams));
      setMessage(true);
      dispatch(setChangeInvoice("transectionReport"));
    }, 3000);
  };
  const userData = JSON.parse(localStorage.getItem("loginUser"));
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const editVoucherPermission = ![1, 6, 7].includes(userData.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === userData?.user_id &&
          it?.page_level?.permission_url === "/voucher-edit"
      )
    : true;

        const isButtonDisabled = () => {
          return saleReport?.VoucherName === "";
        };
  return (
    <div>
      {changeInvoice === "transection" && (
        <div className="">
          <div className="manual_invoices mx-auto mt-5">
            <div className="manual_invoices_header">
              <div className="w-100 text-center">Transactional Report</div>
            </div>
            <div className=" pt-5 px-2 px-md-5">
              <div className="row align-items-center">
                <div className="col-6">
                  <ReactDatePicker
                    placeholderText="From Date"
                    selected={saleReport?.fromDate}
                    onChange={(date) => {
                      setSaleReport((prev) => ({
                        ...prev,
                        fromDate: date,
                      }));
                    }}
                    maxDate={new Date()}
                    className="date_picker "
                  />
                </div>
                <div className="col-6">
                  <ReactDatePicker
                    placeholderText="From Date"
                    selected={saleReport?.toDate}
                    onChange={(date) => {
                      setSaleReport((prev) => ({
                        ...prev,
                        toDate: date,
                      }));
                    }}
                    maxDate={new Date()}
                    className="date_picker "
                    minDate={saleReport?.fromDate}
                  />
                </div>
                <div className="col-6 pt-5">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="demo-simple-select-standard-label">
                      Transactional Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={transactionalType}
                      onChange={(e) => {
                        const selectedId = e.target.value;
                        setTransactionalType(selectedId);
                      }}
                      label="Transactional Type"
                    >
                      {arry.map((list) => (
                        <MenuItem
                          value={list.id}
                          key={list.id}
                          onClick={(e) =>
                            setSaleReport((prev) => ({
                              ...prev,
                              voucher_type_id:
                                list?.title === "Select All"
                                  ? [1, 2, 3]
                                  : list.id,
                              VoucherName: list.title,
                            }))
                          }
                        >
                          {list.title.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {/* generat report button */}
            <div className="container py-4">
              {message ? (
                <div className="text-center py-4">
                  <button
                    className="button_style px-4"
                    onClick={handleGenerateReport}
                    disabled={isButtonDisabled()}
                  >
                    Generate Report
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {changeInvoice === "transectionReport" && (
        <div>
          <Reports
            data={saleReportData}
            report="transection"
            text="Transactional Report"
            summary={saleReport}
            getAdminData={getAdminData?.logo}
            editVoucherPermission={editVoucherPermission}
            userData={userData}
          />
        </div>
      )}
    </div>
  );
}

export default TransactionalReport;
