import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../../ApiConfig";

const initialState = {
  chequeBookData: [],
  allChequeSerials: [],
  historyChequeBook: [],
  loading: false,
  error: null,
};

// get Single cheque book
export const asyncGetChequeSerials = createAsyncThunk(
  "asyncGetChequeSerials/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/cheque-book/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// post serial data
export const asyncPostChequeSerials = createAsyncThunk(
  "asyncPostChequeSerials/post",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/cheque-book`, data);
      setTimeout(() => dispatch(errorMsgNull()), 3000);
      return await response.data.data;
    } catch (error) {
      setTimeout(() => dispatch(errorMsgNull()), 3000);
      return rejectWithValue(error);
    }
  }
);

// get all cheque serials
export const asyncGetAllChequeSerials = createAsyncThunk(
  "asyncGetAllChequeSerials/get",
  async (bank_id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/cheque-serial-opened/${bank_id}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// get all cheque serials
export const asyncGetHistoryCheckBook = createAsyncThunk(
  "asyncGetHistoryCheckBook/get",
  async (bank_id, { rejectWithValue }) => {
    console.log("bank_idslice", bank_id);
    try {
      const response = await NODEURL.get(
        `/api/v1/b2b/cheque-serial-history/${bank_id}`
      );
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get all cheque serials
export const asyncUpdateSerialStatus = createAsyncThunk(
  "asyncUpdateSerialStatus/patch",
  async ({ id, bank_id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/cheque-serial-status/${id}`,
        {
          status: "void",
        }
      );
      // return await response.data.data;
      dispatch(asyncGetAllChequeSerials(bank_id));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const ChequeBookSlice = createSlice({
  name: "ChequeBookSlice",
  initialState,
  reducers: {
    errorMsgNull: (state) => {
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    // getting cheque book serials
    builder.addCase(asyncGetChequeSerials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetChequeSerials.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.chequeBookData = payload;
    });
    builder.addCase(asyncGetChequeSerials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload.response.data.message;
    });
    // post cheque book
    builder.addCase(asyncPostChequeSerials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostChequeSerials.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = "Serial Added Successfully!";
    });
    builder.addCase(asyncPostChequeSerials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload?.response?.data?.message;
    });
    // get all cheque serials
    builder.addCase(asyncGetAllChequeSerials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
      state.allChequeSerials = [];
    });
    builder.addCase(
      asyncGetAllChequeSerials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.allChequeSerials = payload;
      }
    );
    builder.addCase(asyncGetAllChequeSerials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload.response.data.message;
      state.allChequeSerials = [];
    });
    // get history cheque books
    builder.addCase(asyncGetHistoryCheckBook.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetHistoryCheckBook.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.historyChequeBook = payload;
      }
    );
    builder.addCase(asyncGetHistoryCheckBook.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload.response.data.message;
    });
  },
});

export const { errorMsgNull } = ChequeBookSlice.actions;

export default ChequeBookSlice.reducer;
