import React from 'react'
import UserLevelComp from "../components/Agency/UserLevel";

const UserLevel = () => {
  return (
    <div className=''>
      <UserLevelComp />
    </div>
  )
}

export default UserLevel