import React from 'react';
import CountriesComp from '../components/Setup/Visa/Countries'

const Countries = () => {
  return (
    <div >
      <CountriesComp />
    </div>
  )
}

export default Countries