import moment from "moment";
import React, { useState } from "react";

import AmadeusCard from "./amadeus/AmadeusCard";

const Amadeus = ({ amadeusItem, toggleDetail, expandFlightDetail, index, otherOptiontoggle, selectedFlight }) => {

  const [showOtherOption, setShowOtherOption] = useState(false);
  return (
    <div className="search_engin_result_box flight_card_shadow bg-white">
      <AmadeusCard
        showOtherOption={showOtherOption}
        setShowOtherOption={setShowOtherOption}
        key={index}
        amadeusItem={amadeusItem}
        index={"amadeus" + index}
        toggleDetail={toggleDetail}
        isExpanded={expandFlightDetail === "amadeus" + index}
        selectedFlight={selectedFlight}
      />

      {showOtherOption ? (
        <>
          {amadeusItem?.otherOptions?.length > 0 && (
            <>
              {amadeusItem?.otherOptions?.map((otherOption, idx) => {
                return (
                  <div key={idx}>
                    <AmadeusCard
                      amadeusItem={otherOption}
                      key={idx}
                      index={"otheroption" + idx}
                      toggleDetail={otherOptiontoggle}
                      isExpanded={expandFlightDetail === "otheroption" + idx}
                      selectedFlight={selectedFlight}
                    />
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Amadeus;
