import React, { useState } from "react";
import LoginModal from "../LoginReset.js/LoginModal";
import { TiSocialFacebook } from "react-icons/ti";
import { FiInstagram, FiMail } from "react-icons/fi";
import { FaPinterest, FaTwitter } from "react-icons/fa";
import { BiPhoneCall, BiPhone, BiTime } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { MdClose, MdNavigateNext, MdLocationOn } from "react-icons/md";
import RegisterationForm from "./RegisterationForm";
import User1 from "../../assets/images/user1.png";
import User2 from "../../assets/images/user2.png";
import Registration1 from "../../assets/images/Registration1.png";
import Registration2 from "../../assets/images/Registration2.png";

import "../../Styles/registerModel.css";
import { useSelector } from "react-redux";
import { apiUrl } from "../../Utils/configUrl";
import { IoMdMail } from "react-icons/io";

const Header = () => {
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowReisterModal] = useState(false);
  const [Show, setShow] = useState(false);
  const [ShowUser1Icon, setShowUser1Icon] = useState(true);
  const [ShowRegisterIcon, setShowRegisterIcon] = useState(true);

  return (
    <>
      {/************* Registration and login form */}
      {showLoginModal && (
        <LoginModal
          handleClose={setShowLoginModal}
          setShowReisterModal={setShowReisterModal}
        />
      )}
      {showRegisterModal && (
        <RegisterationForm
          handleRegisterClose={setShowReisterModal}
          setShowReisterModal={setShowReisterModal}
        />
      )}
      <div className="sticky_top">
        {/* large screen */}
        <div className="custom-class-largeScreen">
          <div
            className="contact_nav py-3 px-5"
            style={{ backgroundColor: adminAgencyData?.first_color }}
          >
            <div className="me-4 d-flex align-items-center">
              <div>
                <MdLocationOn color="#ffffff" size={22} className="me-2" />
              </div>
              <div className="nav_contact_text">
                {adminAgencyData?.address?.replace(/]/g, ", ").toUpperCase()}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <IoMdMail color="#ffffff" size={20} className="me-2" />
              </div>
              <div className="nav_contact_text">{adminAgencyData?.email}</div>
            </div>
          </div>

          <nav className="navbar navbar-expand-lg navigation-container px-5 d-flex justify-content-between align-items-center">
            <div className="">
              <img
                src={`${apiUrl}/${adminAgencyData?.logo}`}
                alt="logo"
                className="logo"
              />
            </div>
            <div className="d-flex">
              <div className="d-flex align-items-center me-5">
                <BiPhoneCall size={30} className="me-3" />
                <div className="d-flex flex-column">
                  <span className="h6 text-uppercase">Contact Us</span>
                  <span className="h6 text-uppercase">{adminAgencyData?.mobile_no}</span>
                </div>
              </div>

              <div className="d-flex align-items-center primary_color">
                <div>
                  <BiTime size={30} className="me-2" />
                </div>
                <div className="d-flex flex-column">
                  <span className="h6 text-uppercase">Working Time</span>
                  <span className="h6 text-uppercase">Mon - Sat : 9:00AM - 06:00PM</span>
                </div>
              </div>
            </div>

            {/*************** register icon and login icon  ******************/}

            <div className="d-flex justify-content-center align-items-center pt-4">
              <div
                className="me-4 profile_info text-center"
                style={{ cursor: "pointer" }}
                onClick={(e) => setShowLoginModal(true)}
              >
                <img
                  onMouseOver={() => setShowUser1Icon(false)}
                  src={User1}
                  className="user-signup-signin-icons"
                  alt="login"
                />
                <p className="">Sign In</p>
              </div>
              <div
                className="profile_info text-center"
                style={{ cursor: "pointer" }}
                onClick={(e) => setShowReisterModal(true)}
              >
                <img
                  onMouseOver={() => setShowRegisterIcon(false)}
                  src={Registration1}
                  className="user-signup-signin-icons"
                  alt="register"
                />
                <p>Register</p>
              </div>
            </div>
            {/* ****************************/}
          </nav>
        </div>

        {/* small screen */}
        <div className="custom-class-smallScreen">
          <header className="navigation-container d-flex justify-content-between align-items-center px-3">
            <div>
              <img
                src={`${apiUrl}/${adminAgencyData?.logo}`}
                alt="logo"
                className="smlogo"
              />
            </div>
            <div className="">
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="me-3 d-flex justify-content-center align-items-center flex-column"
                  onClick={(e) => setShowLoginModal(true)}
                >
                  {ShowUser1Icon ? (
                    <img
                      onMouseOver={() => setShowUser1Icon(false)}
                      src={User1}
                      className="user-signup-signin-icons"
                      alt="user"
                    />
                  ) : (
                    <img
                      onMouseOut={() => setShowUser1Icon(true)}
                      src={User2}
                      className="user-signup-signin-icons"
                      alt="user"
                    />
                  )}
                  <div>Sign In</div>
                </div>
                <div
                  className=" d-flex justify-content-center align-items-center flex-column"
                  onClick={(e) => setShowReisterModal(true)}
                >
                  {ShowRegisterIcon ? (
                    <img
                      onMouseOver={() => setShowRegisterIcon(false)}
                      src={Registration1}
                      className="user-signup-signin-icons"
                      alt="register"
                    />
                  ) : (
                    <img
                      onMouseOut={() => setShowRegisterIcon(true)}
                      src={Registration2}
                      className="user-signup-signin-icons"
                      alt="register"
                    />
                  )}
                  <div>Register</div>
                </div>
              </div>
            </div>
          </header>
          {Show ? (
            <div className="position-absolute top-0 w-100 h-100 menubar_for_mobile ">
              <div className=" h-100 custom_class_menulinks px-3 pt-4">
                {/* logo and close icon */}
                <div className="d-flex justify-content-between align-items-center">
                  <img
                    src={`http://localhost:3009/${adminAgencyData?.logo}`}
                    alt="logo"
                    width={130}
                    height={80}
                  />
                  <MdClose
                    color="#919db1"
                    size={25}
                    onClick={() => setShow(!Show)}
                  />
                </div>
                {/* menu links */}
                <div className="pt-4">
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Home</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Destinations</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Tours</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-white ">Pages</div>
                    <div className="bg-danger px-1 pb-1">
                      <MdNavigateNext size={20} color="white" />
                    </div>
                  </div>
                  <hr className="bg-white" />
                </div>

                {/* contact links */}

                <div>
                  <div className=" ">
                    <div className="pt-1">
                      <span className="bg-danger me-2 py-1 pb-2 rounded-circle px-2">
                        <HiOutlineMail color="white" size={18} />
                      </span>
                      <span className="text-white">
                        {adminAgencyData?.email}
                      </span>
                    </div>
                    <div className="me-4 pt-2 mt-3">
                      <span className="bg-danger me-2 py-1 pb-2 rounded-circle px-2">
                        <BiPhone color="white" size={18} />
                      </span>
                      <span className="text-white">
                        {adminAgencyData?.mobile_no}
                      </span>
                    </div>
                  </div>
                  <div className="pe-3 pt-3">
                    <TiSocialFacebook
                      color="#d05a48"
                      size={17}
                      className="me-3"
                    />
                    <FaTwitter color="#d05a48" size={17} className="me-3" />
                    <FiInstagram color="#d05a48" size={17} className="me-3 " />
                    <FaPinterest color="#d05a48" size={17} className="" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
