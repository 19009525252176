import * as React from "react";
import { Form, Formik } from "formik";
import PaxDetail from "./PaxDetail";
import ActivityInfo from "./ActivityInfo";
import ActivitySummary from "../Summary/ActivitySummary";

function Activity({ initValues, permission }) {
  const availableLimit =
    initValues?.agent?.credit_limit - initValues?.agent?.credit_used;
  const totalAmount = (values) => {
    let agent = values.activity_info?.reduce(
      (sum, passenger) => sum + Number(passenger.pkr_rec),
      0
    );
    return agent;
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initValues,
        agent_total: 0,
        title: "",
        f_name: "",
        l_name: "",
        activity_info: [
          {
            destination_city: "",
            activity_tour: "",
            transfer_date: "",
            currency: "",
            cur_label: "PKR",
            roe: 1.0,
            curr_pay: 0,
            curr_rec: 0,
            pkr_pay: 0,
            pkr_rec: 0,
          },
        ],
      }}
      onSubmit={(values, action) => {
        // console.log(values)
      }}
    >
      {({ values, setFieldValue, resetForm }) => {
        return (
          <Form>
            <div>
              <div>
                <PaxDetail values={values} setFieldValue={setFieldValue} />
              </div>

              <div>
                <ActivityInfo values={values} setFieldValue={setFieldValue} />
              </div>

              <div>
                <ActivitySummary values={values} />
              </div>
            </div>
            <div className="my-4 d-flex justify-content-center align-item-center">
              <button
                type="button"
                className="button_style px-5 me-3"
                onClick={() => resetForm()}
                disabled={!permission}
              >
                Clear Form
              </button>
              <button
                type="submit"
                className="button_style px-5"
                disabled={
                  Number(availableLimit) < Number(totalAmount(values)) ||
                  !permission
                }
              >
                Submit
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default Activity;
