import React, { useEffect, useState } from "react";
import PiaLogo from "../../../../assets/images/airlineLogo/PK.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { BsBagCheck, BsHandbag, BsPerson } from "react-icons/bs";
import {
  MdCheck,
  MdFlight,
  MdOutlineFlightLand,
  MdOutlineFlightTakeoff,
  MdOutlineRestaurant,
  MdRemoveRedEye,
  MdSwapHoriz,
} from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { LightTooltip } from "../OneWaySearchResult/LightTooltip";
import {
  setExpandFlightDetails,
  setReturnFlight,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";
import { HiEye } from "react-icons/hi";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  ExclusiveSetors,
  codeShareHITIT,
} from "../../../../Utils/exclusiveSector";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";
import { NODEURL } from "../../../../redux/features/ApiConfig";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";

function Pia({ piadata, index, isExpanded, toggleDetail, selectedFare }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Fare Options");
  const { selectedFlight, returnFlight } = useSelector(
    (state) => state.FlightSearch
  );
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );

  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const fareComponentList = Array.isArray(
    piadata?.fareComponentGroupList?.fareComponentList
  )
    ? piadata?.fareComponentGroupList?.fareComponentList
    : [piadata?.fareComponentGroupList?.fareComponentList];

  const fareboundList = selectedFlight
    ? piadata?.fareComponentGroupList?.boundList[1]
    : piadata?.fareComponentGroupList?.boundList?.at(0);
  //////////// check if connected flights
  const isConnectedFlight = Array.isArray(fareboundList?.availFlightSegmentList)
    ? true
    : false;
  const flightSeg = fareboundList?.availFlightSegmentList?.flightSegment;
  const availableFlightSegments = Array.isArray(
    fareboundList?.availFlightSegmentList
  )
    ? fareboundList?.availFlightSegmentList
    : [fareboundList?.availFlightSegmentList];

  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  /// selected fare return flight
  const selectedFareReturn = [returnFlight?.fare];
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.name === "PIA-HITIT"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.name === "PIA-HITIT");

  // Convert minutes to hours and minutes using Moment.js
  const convertTime = (inputTime) => {
    // Parse the input time using Moment.js
    const duration = moment.duration(inputTime);
    // Extract hours and minutes
    const hours = duration.hours();
    const minutes = duration.minutes();
    // Format hours and minutes as 'hh:mm'
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  };

  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  // add flight duration of conected flights
  const calculateTimeDifference = () => {
    const departureDate = moment(
      fareboundList?.availFlightSegmentList?.at(0)?.flightSegment
        ?.departureDateTime
    );
    const arrivalDate = moment(
      fareboundList?.availFlightSegmentList[
        fareboundList?.availFlightSegmentList.length - 1
      ]?.flightSegment?.arrivalDateTime
    );

    // Check for invalid date
    if (!departureDate.isValid() || !arrivalDate.isValid()) {
      return "Invalid date";
    }

    // Calculate the time difference
    const duration = moment.duration(arrivalDate.diff(departureDate));

    // Get the total hours and minutes
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    // Format the result
    const formattedResult = `${String(hours).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")}`;
    return formattedResult;
  };

  const validFareGroupNames = ["NILBAG", "ECO", "EXC ECO"];
  const seenFareGroupNames = new Set();

  const newarray = fareComponentList?.filter((current) => {
    let paxFareInfoList = Array.isArray(current?.passengerFareInfoList)
      ? current?.passengerFareInfoList
      : [current?.passengerFareInfoList];
    const fareGroupName = paxFareInfoList
      ?.at(0)
      ?.fareInfoList?.at(0)?.fareGroupName;

    if (selectedFlight && selectedFlight?.logo === "HITIT") {
      if (fareGroupName === selectedFlight?.baggageName) {
        return true;
      }
    } else if (
      validFareGroupNames.includes(fareGroupName) &&
      !seenFareGroupNames.has(fareGroupName)
    ) {
      seenFareGroupNames.add(fareGroupName);
      return true;
    }

    return false;
  });
  //calculate lowest total fare
  const totalPaxFare = (fare) => {
    // passenger fare info list
    const newFare = Array.isArray(fare) ? fare : [fare];
    let total =
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(0)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(0)?.pricingInfo?.totalFare?.amount?.value -
                ~~newFare?.at(0)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          newFare?.at(0)?.fareInfoList[selectedFlight ? 1 : 0]?.resBookDesigCode
        ),
        codeShareHITIT(fareboundList?.availFlightSegmentList)
      )?.total_fare * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare[1]?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(1)?.pricingInfo?.totalFare?.amount?.value -
                ~~newFare?.at(1)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          newFare[1]?.fareInfoList[selectedFlight ? 1 : 0]?.resBookDesigCode
        ),
        codeShareHITIT(fareboundList?.availFlightSegmentList)
      )?.total_fare * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare[2]?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(2)?.pricingInfo?.totalFare?.amount?.value -
                ~~newFare?.at(2)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          newFare[2]?.fareInfoList[selectedFlight ? 1 : 0]?.resBookDesigCode
        ),
        codeShareHITIT(fareboundList?.availFlightSegmentList)
      )?.total_fare * searchValues?.infantNo || 0);

    return TwoDecimalPoint(total);
  };

  //Discount price
  const DiscountPrice = (fare) => {
    // passenger fare info list
    const newFare = Array.isArray(fare) ? fare : [fare];
    let total =
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare?.at(0)?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(0)?.pricingInfo?.totalFare?.amount?.value -
                ~~newFare?.at(0)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          newFare?.at(0)?.fareInfoList[selectedFlight ? 1 : 0]?.resBookDesigCode
        ),
        codeShareHITIT(fareboundList?.availFlightSegmentList)
      )?.sp * searchValues?.adultsCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare[1]?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(1)?.pricingInfo?.totalFare?.amount?.value -
                ~~newFare?.at(1)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          newFare[1]?.fareInfoList[selectedFlight ? 1 : 0]?.resBookDesigCode
        ),
        codeShareHITIT(fareboundList?.availFlightSegmentList)
      )?.sp * searchValues?.childCount || 0) +
      (pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(
            Math.round(newFare[2]?.pricingInfo?.equivBaseFare?.value)
          )
        ),
        Number(
          CurrencyExchange(
            Math.round(
              ~~newFare?.at(2)?.pricingInfo?.totalFare?.amount?.value -
                ~~newFare?.at(2)?.pricingInfo?.equivBaseFare?.value
            )
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          newFare[2]?.fareInfoList[selectedFlight ? 1 : 0]?.resBookDesigCode
        ),
        codeShareHITIT(fareboundList?.availFlightSegmentList)
      )?.sp * searchValues?.infantNo || 0);
    if (total > 0) {
      return `Off ${CurrencyCode()} ${TwoDecimalPoint(total)}`;
    }
    return "";
  };
  ///////////////////// single passenger fare
  const singlePaxFare = (fare, pax) => {
    let total =
      pricingairline(
        airline,
        pricing,
        Number(
          CurrencyExchange(Math.round(fare?.pricingInfo?.equivBaseFare?.value))
        ),
        Number(
          CurrencyExchange(
            Math.round(fare?.pricingInfo?.taxes?.totalAmount?.value)
          )
        ),
        getSectorsName(locDeparture, locArrival),
        ExclusiveSetors(
          searchValues,
          pricing,
          fare?.fareInfoList[selectedFlight ? 1 : 0]?.resBookDesigCode
        ),
        codeShareHITIT(fareboundList?.availFlightSegmentList)
      )?.total_fare * pax || 0;

    return `${CurrencyCode()} ${TwoDecimalPoint(total)}`;
  };
  // departure date and time , arrival date and time ASSIGN to the variables so code is not repeating
  const depDate = moment(
    new Date(
      (isConnectedFlight
        ? fareboundList?.availFlightSegmentList?.at(0)?.flightSegment
            ?.departureDateTime
        : flightSeg?.departureDateTime
      )
        ?.split("T")
        ?.at(0)
    )
  ).format("ddd DD-MMM-YY");
  const depTime = (
    isConnectedFlight
      ? fareboundList?.availFlightSegmentList?.at(0)?.flightSegment
          ?.departureDateTime
      : flightSeg?.departureDateTime
  )
    ?.split("T")[1]
    ?.slice(0, 5);
  const arrDate = moment(
    new Date(
      (isConnectedFlight
        ? fareboundList?.availFlightSegmentList[
            fareboundList?.availFlightSegmentList?.length - 1
          ]?.flightSegment?.arrivalDateTime
        : flightSeg?.arrivalDateTime
      )
        ?.split("T")
        ?.at(0)
    )
  ).format("ddd DD-MMM-YY");
  const arrTime = (
    isConnectedFlight
      ? fareboundList?.availFlightSegmentList[
          fareboundList?.availFlightSegmentList?.length - 1
        ]?.flightSegment?.arrivalDateTime
      : flightSeg?.arrivalDateTime
  )
    ?.split("T")[1]
    ?.slice(0, 5);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      const yOffset = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset,
        behavior: "smooth",
      });
    }
  }

  // get city name
  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  ////////////////////// operated airline if code share
  const operatedAirline =
    Array.isArray(fareboundList?.availFlightSegmentList) &&
    fareboundList?.availFlightSegmentList?.find(
      (it) => it.flightSegment.operatingAirline
    );

  // calculate layover time between connected fligths
  function calculateTotalTimeDifference(time1, time2) {
    // Parse the input time values with the provided format
    const format = "YYYY-MM-DDTHH:mm:ss";
    const moment1 = moment(time1, format);
    const moment2 = moment(time2, format);

    // Calculate the time difference in milliseconds
    const timeDifference = Math.abs(moment1 - moment2);

    // Convert the time difference to a moment duration
    const duration = moment.duration(timeDifference);

    // Format the duration as hours and minutes
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  const getPaxTaxes = (taxes = []) => {
    taxes = Array.isArray(taxes) ? taxes : [taxes];

    return taxes.map((tax) => ({
      code: tax?.taxCode,
      amount: ~~tax?.taxAmount?.value,
    }));
  };

  return (
    <>
      <div
        className="search_engin_result_box flight_card_shadow bg-white"
        id={`pia${index}`}
      >
        <div
          className="shadow cursorpointer"
          onClick={() => {
            toggleDetail(index);
            setTimeout(() => {
              !isExpanded && scrollToElement(`pia${index}`);
            }, 200);
          }}
        >
          <div className="row align-items-center">
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={PiaLogo} alt="PiaLogo" width={100} />
                <div>
                  {isConnectedFlight
                    ? fareboundList?.availFlightSegmentList
                        ?.map((it) => `PK ${it.flightSegment.flightNumber}`)
                        ?.join(" - ")
                    : `PK ${flightSeg?.flightNumber}`}
                </div>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightTakeoff
                      size={30}
                      className="color_primary"
                    />
                  </div>
                  <div>
                    <h6>{depDate}</h6>
                    <h6 className="my-3">{depTime}</h6>
                    <h6>
                      {isConnectedFlight
                        ? fareboundList?.availFlightSegmentList?.at(0)
                            ?.flightSegment?.departureAirport?.locationCode
                        : flightSeg?.departureAirport?.locationCode}{" "}
                      -{" "}
                      {isConnectedFlight
                        ? fareboundList?.availFlightSegmentList
                            ?.at(0)
                            ?.flightSegment?.departureAirport?.locationName?.toUpperCase()
                        : flightSeg?.departureAirport?.locationName?.toUpperCase()}
                    </h6>
                  </div>
                </div>
                <div>
                  <h6 className="text-center">
                    {isConnectedFlight
                      ? calculateTimeDifference()
                      : convertTime(flightSeg?.journeyDuration)}
                  </h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">
                    {fareboundList?.availFlightSegmentList[2]
                      ? `Two-Stop`
                      : fareboundList?.availFlightSegmentList[1]
                      ? "One-Stop"
                      : "Non-Stop"}
                  </h6>
                  <h6 className="text-center">
                    {isConnectedFlight
                      ? fareboundList?.availFlightSegmentList
                          ?.map(
                            (it) =>
                              it.flightSegment?.arrivalAirport?.locationCode
                          )
                          ?.slice(0, -1) // Exclude the last element
                          ?.join(" - ")
                      : null}
                  </h6>
                </div>
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <MdOutlineFlightLand size={30} className="color_primary" />
                  </div>
                  <div>
                    <h6>{arrDate}</h6>
                    <h6 className="my-3">{arrTime}</h6>
                    <h6>
                      {isConnectedFlight
                        ? fareboundList?.availFlightSegmentList[
                            fareboundList?.availFlightSegmentList.length - 1
                          ]?.flightSegment?.arrivalAirport?.locationCode
                        : flightSeg?.arrivalAirport?.locationCode}{" "}
                      -{" "}
                      {selectedFlight
                        ? getCity(locDeparture)
                        : getCity(locArrival)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
                {selectedFare ? (
                  <HiEye className="color_primary" size={40} />
                ) : (
                  <>
                    <h6 className="text-danger">
                      {DiscountPrice(newarray?.at(0)?.passengerFareInfoList)}
                    </h6>
                    <div className="d-flex align-items-end gap-3">
                      {piadata?.fareComponentGroupList?.fareComponentList ? (
                        <h4>
                          {CurrencyCode()}{" "}
                          {totalPaxFare(newarray?.at(0)?.passengerFareInfoList)}
                        </h4>
                      ) : (
                        <h4>Sold Out</h4>
                      )}
                      <img
                        src={"/NEW_LOGO/hitit.png"}
                        alt=""
                        width={40}
                        height={40}
                      />
                    </div>
                    <button className="button_style w-75 my-3" type="button">
                      <div>
                        <MenuOpenIcon className="me-1" />
                        Flight Detail
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded && (
          <div className="p-3">
            {/* Tabs start */}
            {/* {isConnectedFlight ? ( */}
            <div className="d-flex mb-4 mt-2 flight_details_tabs">
              {["Fare Options", "Flight Details"].map((item, index) => {
                const active = selectedTab === item;
                return (
                  <button
                    key={index}
                    onClick={() => setSelectedTab(item)}
                    className={active ? "active" : ""}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
            {/* ) : null} */}

            {/* Flight Details */}
            {selectedTab === "Flight Details" && (
              <div className="row rounded-2 search_engin_result_box bg-white p-4">
                {availableFlightSegments?.map((item, inx, array) => {
                  return (
                    <ConnectedFlight
                      item={item}
                      inx={inx}
                      calculateTotalTimeDifference={
                        calculateTotalTimeDifference
                      }
                      fligthSegment={array}
                      operatedAirline={operatedAirline}
                      convertTime={convertTime}
                    />
                  );
                })}
              </div>
            )}
            {/* Fare options start */}
            {selectedTab === "Fare Options" && (
              <div className="search_engin_result_box py-3 bg-white h-auto">
                <div className="d-flex align-items-start px-5">
                  {/* headings */}
                  <div className="w-25">
                    <div className="mb-4 pb-3"></div>
                    <div className="flight-detail-headings">
                      <BsHandbag size={25} className="color_primary" />
                      <span>Hand Carry Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsBagCheck size={25} className="color_primary" />
                      <span>Checked Bags</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdOutlineRestaurant
                        size={25}
                        className="color_primary"
                      />
                      <span>Meals</span>
                    </div>
                    <div className="flight-detail-headings">
                      <BsPerson size={25} className="color_primary" />
                      <span>Seat Selection</span>
                    </div>
                    <div className="flight-detail-headings">
                      <MdSwapHoriz size={25} className="color_primary" />
                      <span>Refund & Exchanges</span>
                    </div>
                  </div>
                  <div
                    className={`w-75 d-flex  ${
                      selectedFare ? "ps-5" : "justify-content-around"
                    }`}
                  >
                    {(selectedFare ? selectedFareReturn : newarray)?.map(
                      (fare, fareIndex) => {
                        let infoList = Array.isArray(
                          fare?.passengerFareInfoList
                        )
                          ? fare?.passengerFareInfoList
                          : [fare?.passengerFareInfoList];
                        return (
                          <div key={fare?.internalID} className="">
                            <div className="fs-5 theme-text-color mb-4 fw-bold">
                              {
                                infoList?.at(0)?.fareInfoList[
                                  selectedFlight ? 1 : 0
                                ]?.fareGroupName
                              }{" "}
                              (
                              {
                                infoList?.at(0)?.fareInfoList[
                                  selectedFlight ? 1 : 0
                                ]?.resBookDesigCode
                              }
                              )
                            </div>
                            <div className="flight-detail-headings">
                              <MdCheck size={25} color="green" />
                              <span>Upto 7 kg</span>
                            </div>
                            <div className="flight-detail-headings">
                              <MdCheck size={25} color="green" />
                              <span>
                                {isConnectedFlight
                                  ? `${
                                      infoList?.at(0)?.fareInfoList?.at(0)
                                        ?.fareBaggageAllowance?.maxAllowedWeight
                                        ?.weight
                                    } ${
                                      infoList?.at(0)?.fareInfoList?.at(0)
                                        ?.fareBaggageAllowance?.maxAllowedWeight
                                        ?.unitOfMeasureCode
                                    }` ||
                                    `${
                                      infoList?.at(0)?.fareInfoList?.at(0)
                                        ?.fareBaggageAllowance?.maxAllowedPieces
                                    } ${
                                      infoList?.at(0)?.fareInfoList?.at(0)
                                        ?.fareBaggageAllowance?.allowanceType
                                    } ( ${
                                      infoList?.at(0)?.fareInfoList?.at(0)
                                        ?.fareBaggageAllowance?.pieceDefinitions
                                    } )`
                                  : `${
                                      infoList?.at(0)?.fareInfoList[
                                        selectedFlight ? 1 : 0
                                      ]?.fareBaggageAllowance?.maxAllowedWeight
                                        ?.weight
                                    } kg`}{" "}
                              </span>
                            </div>
                            <div className="flight-detail-headings">
                              <MdCheck size={25} color="green" />
                              <span>Allowed</span>
                            </div>
                            <div className="flight-detail-headings">
                              <CgMenuGridR size={25} color="#38025c" />
                              <span>System Allocated</span>
                            </div>
                            {/* refund and exchange dialog  */}
                            <div className="flight-detail-headings">
                              <RefundExchange />
                            </div>

                            {!selectedFare ? (
                              <div className="pt-3 px-2">
                                <LightTooltip
                                  title={
                                    <div className="p-3">
                                      <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex me-4">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            ADT
                                          </h5>
                                          <h5>* {searchValues?.adultsCount}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            infoList?.at(0),
                                            searchValues?.adultsCount
                                          )}
                                        </h6>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-end my-2">
                                        <div className="d-flex">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            CHD
                                          </h5>
                                          <h5>* {searchValues?.childCount}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            infoList[1],
                                            searchValues?.childCount
                                          )}
                                        </h6>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-end">
                                        <div className="d-flex">
                                          <h5 style={{ minWidth: "3rem" }}>
                                            INF
                                          </h5>
                                          <h5>* {searchValues?.infantNo}</h5>
                                        </div>
                                        <h6 style={{ minWidth: "7rem" }}>
                                          {singlePaxFare(
                                            infoList[2],
                                            searchValues?.infantNo
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                  }
                                  placement="top"
                                >
                                  <button
                                    type="button"
                                    className="button_style px-4"
                                    onClick={() => {
                                      if (selectedFlight) {
                                        dispatch(
                                          setReturnFlight({
                                            flight: piadata,
                                            fare: fare,
                                            price: totalPaxFare(infoList),
                                            ttl_agent_fare: TwoDecimalPoint(
                                              CurrencyExchangeToPKR(
                                                totalPaxFare(infoList) || 0
                                              )
                                            ),
                                            ttlagent_curr_fare: Number(
                                              totalPaxFare(infoList) || 0
                                            ),
                                            ttl_supplier_fare: 0,
                                            ttlsup_curr_fare: 0,

                                            adultBaseFare:
                                              infoList.at(0)?.pricingInfo
                                                ?.equivBaseFare?.value,
                                            adultTaxes:
                                              Number(
                                                infoList?.at(0)?.pricingInfo
                                                  ?.totalFare?.amount?.value
                                              ) -
                                              Number(
                                                infoList?.at(0)?.pricingInfo
                                                  ?.equivBaseFare?.value
                                              ),
                                            childBaseFare:
                                              searchValues?.childCount === 0
                                                ? 0
                                                : infoList?.at(1)?.pricingInfo
                                                    ?.equivBaseFare?.value,
                                            childTaxes:
                                              searchValues?.childCount === 0
                                                ? 0
                                                : Number(
                                                    infoList?.at(1)?.pricingInfo
                                                      ?.totalFare?.amount?.value
                                                  ) -
                                                  Number(
                                                    infoList?.at(1)?.pricingInfo
                                                      ?.equivBaseFare?.value
                                                  ),
                                            infantBaseFare:
                                              searchValues?.infantNo === 0
                                                ? 0
                                                : infoList?.at(
                                                    infoList?.length - 1
                                                  )?.pricingInfo?.equivBaseFare
                                                    ?.value,
                                            infantTaxes:
                                              searchValues?.infantNo === 0
                                                ? 0
                                                : Number(
                                                    infoList?.at(
                                                      infoList?.length - 1
                                                    )?.pricingInfo?.totalFare
                                                      ?.amount?.value
                                                  ) -
                                                  Number(
                                                    infoList[
                                                      infoList?.length - 1
                                                    ]?.pricingInfo
                                                      ?.equivBaseFare?.value
                                                  ),

                                            adlt_ttl_d_curr:
                                              CurrencyExchangeToPKR(
                                                Number(
                                                  singlePaxFare(
                                                    infoList?.at(0),
                                                    1
                                                  )
                                                    ?.split(" ")
                                                    ?.at(1)
                                                )
                                              ),
                                            chld_ttl_d_curr:
                                              searchValues?.childCount === 0
                                                ? 0
                                                : CurrencyExchangeToPKR(
                                                    Number(
                                                      singlePaxFare(
                                                        infoList?.at(1),
                                                        1
                                                      )
                                                        ?.split(" ")
                                                        ?.at(1)
                                                    )
                                                  ),
                                            inft_ttl_d_curr:
                                              searchValues?.infantNo === 0
                                                ? 0
                                                : CurrencyExchangeToPKR(
                                                    Number(
                                                      singlePaxFare(
                                                        infoList?.at(
                                                          infoList?.length - 1
                                                        ),
                                                        1
                                                      )
                                                        ?.split(" ")
                                                        ?.at(1)
                                                    )
                                                  ),

                                            ADLT_TAXES: getPaxTaxes(
                                              infoList?.at(0)?.pricingInfo
                                                ?.taxes?.taxList
                                            ),
                                            CHLD_TAXES:
                                              searchValues?.childCount === 0
                                                ? []
                                                : getPaxTaxes(
                                                    infoList?.at(1)?.pricingInfo
                                                      ?.taxes?.taxList
                                                  ),
                                            INFT_TAXES:
                                              searchValues?.infantNo === 0
                                                ? []
                                                : getPaxTaxes(
                                                    infoList?.at(
                                                      infoList?.length - 1
                                                    )?.pricingInfo?.taxes
                                                      ?.taxList
                                                  ),

                                            ADLT_FARE: infoList?.at(0),
                                            CHLD_FARE:
                                              searchValues?.childCount === 0
                                                ? {}
                                                : infoList?.at(1),
                                            INFT_FARE:
                                              searchValues?.infantNo === 0
                                                ? {}
                                                : infoList?.at(
                                                    infoList?.length - 1
                                                  ),

                                            baggageName:
                                              infoList?.at(0)?.fareInfoList[1]
                                                ?.fareGroupName,
                                            flightNumber: `PK ${flightSeg?.flightNumber}`,
                                            depDate: depDate,
                                            depTime: depTime,
                                            arrDate: arrDate,
                                            arrTime: arrTime,
                                            flightDuration: convertTime(
                                              flightSeg?.journeyDuration
                                            ),
                                            logo: "HITIT",
                                            code: "PK",

                                            booking: {
                                              gds: "HITIT", // should be gds code
                                              pnr: "", // returned in pricing response
                                              agent_name: piadata?.agent_name,
                                              segments:
                                                availableFlightSegments?.map(
                                                  (
                                                    {
                                                      flightSegment,
                                                      ...segment
                                                    },
                                                    segmentIndex
                                                  ) => {
                                                    const requiredFare =
                                                      Array.isArray(
                                                        infoList?.at(0)
                                                          ?.fareInfoList
                                                      )
                                                        ? infoList
                                                            ?.at(0)
                                                            ?.fareInfoList?.at(
                                                              segmentIndex +
                                                                selectedFlight
                                                                  ?.booking
                                                                  ?.segments
                                                                  ?.length
                                                            )
                                                        : infoList?.at(0)
                                                            ?.fareInfoList;
                                                    const baggage =
                                                      requiredFare
                                                        ?.fareBaggageAllowance
                                                        ?.maxAllowedWeight
                                                        ?.weight;

                                                    const getDate = (
                                                      dateTime
                                                    ) =>
                                                      dateTime
                                                        ?.split("T")
                                                        ?.at(0);
                                                    const getTime = (
                                                      dateTime
                                                    ) =>
                                                      dateTime
                                                        ?.split("T")
                                                        ?.at(1)
                                                        ?.slice(0, 5);
                                                    return {
                                                      boundList: {
                                                        ...segment,
                                                        flightSegment,
                                                        bookingClassList:
                                                          segment
                                                            ?.bookingClassList
                                                            ?.length
                                                            ? segment?.bookingClassList?.at(
                                                                fareIndex
                                                              )
                                                            : segment?.bookingClassList,
                                                      },
                                                      flight: "OW",
                                                      status: "",
                                                      fare: requiredFare,
                                                      baggage: `${baggage} KGS`,
                                                      departure_date: getDate(
                                                        flightSegment?.departureDateTime
                                                      ),
                                                      departure_time: getTime(
                                                        flightSegment?.departureDateTime
                                                      ),
                                                      arrival_date: getDate(
                                                        flightSegment?.arrivalDateTime
                                                      ),
                                                      arrival_time: getTime(
                                                        flightSegment?.arrivalDateTime
                                                      ),
                                                      origin_terminal: "",
                                                      desti_terminal: "",
                                                      flight_number:
                                                        flightSegment?.flightNumber,
                                                      res_book_desig_code:
                                                        requiredFare?.resBookDesigCode,
                                                      des_location:
                                                        flightSegment
                                                          ?.arrivalAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode +
                                                        "- -" +
                                                        flightSegment
                                                          ?.arrivalAirport
                                                          ?.cityInfo?.city
                                                          ?.locationName,
                                                      dest_code:
                                                        flightSegment
                                                          ?.arrivalAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode,
                                                      marketing_airline: "PK",
                                                      equipment_code:
                                                        flightSegment?.equipment
                                                          ?.airEquipType,
                                                      operatingairline_code:
                                                        "PK",
                                                      orig_location:
                                                        flightSegment
                                                          ?.departureAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode +
                                                        "- -" +
                                                        flightSegment
                                                          ?.departureAirport
                                                          ?.cityInfo?.city
                                                          ?.locationName,
                                                      orig_code:
                                                        flightSegment
                                                          ?.departureAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode,
                                                      marriage_grp: "",
                                                      cabin_class:
                                                        requiredFare?.cabin,
                                                      segment_type:
                                                        getSectorsName(
                                                          flightSegment
                                                            ?.departureAirport
                                                            ?.cityInfo?.city
                                                            ?.locationCode,
                                                          flightSegment
                                                            ?.arrivalAirport
                                                            ?.cityInfo?.city
                                                            ?.locationCode
                                                        ), // dom | siti | soto | group
                                                    };
                                                  }
                                                ),
                                              tp_ur_code: "",
                                              platting_carrier: "PK",
                                              tp_ssr_verssion: 0,
                                              tp_trace_id: "",
                                            },
                                          })
                                        );
                                        navigate("/check-out");
                                      } else {
                                        dispatch(
                                          setselectedFlight({
                                            flight: piadata,
                                            fare: fare,
                                            price: totalPaxFare(infoList),
                                            ttl_agent_fare: TwoDecimalPoint(
                                              CurrencyExchangeToPKR(
                                                totalPaxFare(infoList) || 0
                                              )
                                            ),
                                            ttlagent_curr_fare: Number(
                                              totalPaxFare(infoList) || 0
                                            ),
                                            ttl_supplier_fare: 0,
                                            ttlsup_curr_fare: 0,

                                            adultBaseFare:
                                              infoList.at(0)?.pricingInfo
                                                ?.equivBaseFare?.value,
                                            adultTaxes:
                                              Number(
                                                infoList?.at(0)?.pricingInfo
                                                  ?.totalFare?.amount?.value
                                              ) -
                                              Number(
                                                infoList?.at(0)?.pricingInfo
                                                  ?.equivBaseFare?.value
                                              ),
                                            childBaseFare:
                                              searchValues?.childCount === 0
                                                ? 0
                                                : infoList?.at(1)?.pricingInfo
                                                    ?.equivBaseFare?.value,
                                            childTaxes:
                                              searchValues?.childCount === 0
                                                ? 0
                                                : Number(
                                                    infoList?.at(1)?.pricingInfo
                                                      ?.totalFare?.amount?.value
                                                  ) -
                                                  Number(
                                                    infoList?.at(1)?.pricingInfo
                                                      ?.equivBaseFare?.value
                                                  ),
                                            infantBaseFare:
                                              searchValues?.infantNo === 0
                                                ? 0
                                                : infoList?.at(
                                                    infoList?.length - 1
                                                  )?.pricingInfo?.equivBaseFare
                                                    ?.value,
                                            infantTaxes:
                                              searchValues?.infantNo === 0
                                                ? 0
                                                : Number(
                                                    infoList?.at(
                                                      infoList?.length - 1
                                                    )?.pricingInfo?.totalFare
                                                      ?.amount?.value
                                                  ) -
                                                  Number(
                                                    infoList[
                                                      infoList?.length - 1
                                                    ]?.pricingInfo
                                                      ?.equivBaseFare?.value
                                                  ),

                                            adlt_ttl_d_curr:
                                              CurrencyExchangeToPKR(
                                                Number(
                                                  singlePaxFare(
                                                    infoList?.at(0),
                                                    1
                                                  )
                                                    ?.split(" ")
                                                    ?.at(1)
                                                )
                                              ),
                                            chld_ttl_d_curr:
                                              searchValues?.childCount === 0
                                                ? 0
                                                : CurrencyExchangeToPKR(
                                                    Number(
                                                      singlePaxFare(
                                                        infoList?.at(1),
                                                        1
                                                      )
                                                        ?.split(" ")
                                                        ?.at(1)
                                                    )
                                                  ),
                                            inft_ttl_d_curr:
                                              searchValues?.infantNo === 0
                                                ? 0
                                                : CurrencyExchangeToPKR(
                                                    Number(
                                                      singlePaxFare(
                                                        infoList?.at(
                                                          infoList?.length - 1
                                                        ),
                                                        1
                                                      )
                                                        ?.split(" ")
                                                        ?.at(1)
                                                    )
                                                  ),

                                            ADLT_TAXES: getPaxTaxes(
                                              infoList?.at(0)?.pricingInfo
                                                ?.taxes?.taxList
                                            ),
                                            CHLD_TAXES:
                                              searchValues?.childCount === 0
                                                ? []
                                                : getPaxTaxes(
                                                    infoList?.at(1)?.pricingInfo
                                                      ?.taxes?.taxList
                                                  ),
                                            INFT_TAXES:
                                              searchValues?.infantNo === 0
                                                ? []
                                                : getPaxTaxes(
                                                    infoList?.at(
                                                      infoList?.length - 1
                                                    )?.pricingInfo?.taxes
                                                      ?.taxList
                                                  ),

                                            ADLT_FARE: infoList?.at(0),
                                            CHLD_FARE:
                                              searchValues?.childCount === 0
                                                ? {}
                                                : infoList?.at(1),
                                            INFT_FARE:
                                              searchValues?.infantNo === 0
                                                ? {}
                                                : infoList?.at(
                                                    infoList?.length - 1
                                                  ),

                                            baggageName: infoList
                                              ?.at(0)
                                              ?.fareInfoList?.at(0)
                                              ?.fareGroupName,
                                            flightNumber: isConnectedFlight
                                              ? fareboundList?.availFlightSegmentList
                                                  ?.map(
                                                    (it) =>
                                                      `PK ${it.flightSegment.flightNumber}`
                                                  )
                                                  ?.join(" - ")
                                              : `PK ${flightSeg?.flightNumber}`,
                                            depDate: depDate,
                                            depTime: depTime,
                                            arrDate: arrDate,
                                            arrTime: arrTime,
                                            flightDuration: isConnectedFlight
                                              ? calculateTimeDifference()
                                              : convertTime(
                                                  flightSeg?.journeyDuration
                                                ),
                                            logo: "HITIT",
                                            code: "PK",
                                            stops: fareboundList
                                              ?.availFlightSegmentList[2]
                                              ? `Two-Stop`
                                              : fareboundList
                                                  ?.availFlightSegmentList[1]
                                              ? "One-Stop"
                                              : "Non-Stop",

                                            booking: {
                                              gds: "HITIT", // should be gds code
                                              pnr: "", // returned in pricing response
                                              agent_name: piadata?.agent_name,
                                              segments:
                                                availableFlightSegments?.map(
                                                  (
                                                    {
                                                      flightSegment,
                                                      ...segment
                                                    },
                                                    segmentIndex
                                                  ) => {
                                                    const requiredFare =
                                                      Array.isArray(
                                                        infoList?.at(0)
                                                          ?.fareInfoList
                                                      )
                                                        ? infoList
                                                            ?.at(0)
                                                            ?.fareInfoList?.at(
                                                              segmentIndex
                                                            )
                                                        : infoList?.at(0)
                                                            ?.fareInfoList;
                                                    const baggage =
                                                      requiredFare
                                                        ?.fareBaggageAllowance
                                                        ?.maxAllowedWeight
                                                        ?.weight;

                                                    const getDate = (
                                                      dateTime
                                                    ) =>
                                                      dateTime
                                                        ?.split("T")
                                                        ?.at(0);
                                                    const getTime = (
                                                      dateTime
                                                    ) =>
                                                      dateTime
                                                        ?.split("T")
                                                        ?.at(1)
                                                        ?.slice(0, 5);
                                                    return {
                                                      boundList: {
                                                        ...segment,
                                                        flightSegment,
                                                        bookingClassList:
                                                          segment
                                                            ?.bookingClassList
                                                            ?.length
                                                            ? segment?.bookingClassList?.at(
                                                                fareIndex
                                                              )
                                                            : segment?.bookingClassList,
                                                      },
                                                      flight: "OW",
                                                      status: "",
                                                      fare: requiredFare,
                                                      baggage: `${baggage} KGS`,
                                                      departure_date: getDate(
                                                        flightSegment?.departureDateTime
                                                      ),
                                                      departure_time: getTime(
                                                        flightSegment?.departureDateTime
                                                      ),
                                                      arrival_date: getDate(
                                                        flightSegment?.arrivalDateTime
                                                      ),
                                                      arrival_time: getTime(
                                                        flightSegment?.arrivalDateTime
                                                      ),
                                                      origin_terminal: "",
                                                      desti_terminal: "",
                                                      flight_number:
                                                        flightSegment?.flightNumber,
                                                      res_book_desig_code:
                                                        requiredFare?.resBookDesigCode,
                                                      des_location:
                                                        flightSegment
                                                          ?.arrivalAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode +
                                                        "- -" +
                                                        flightSegment
                                                          ?.arrivalAirport
                                                          ?.cityInfo?.city
                                                          ?.locationName,
                                                      dest_code:
                                                        flightSegment
                                                          ?.arrivalAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode,
                                                      marketing_airline: "PK",
                                                      equipment_code:
                                                        flightSegment?.equipment
                                                          ?.airEquipType,
                                                      operatingairline_code:
                                                        "PK",
                                                      orig_location:
                                                        flightSegment
                                                          ?.departureAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode +
                                                        "- -" +
                                                        flightSegment
                                                          ?.departureAirport
                                                          ?.cityInfo?.city
                                                          ?.locationName,
                                                      orig_code:
                                                        flightSegment
                                                          ?.departureAirport
                                                          ?.cityInfo?.city
                                                          ?.locationCode,
                                                      marriage_grp: "",
                                                      cabin_class:
                                                        requiredFare?.cabin,
                                                      segment_type:
                                                        getSectorsName(
                                                          flightSegment
                                                            ?.departureAirport
                                                            ?.cityInfo?.city
                                                            ?.locationCode,
                                                          flightSegment
                                                            ?.arrivalAirport
                                                            ?.cityInfo?.city
                                                            ?.locationCode
                                                        ), // dom | siti | soto | group
                                                    };
                                                  }
                                                ),
                                              tp_ur_code: "",
                                              platting_carrier: "PK",
                                              tp_ssr_verssion: 0,
                                              tp_trace_id: "",
                                            },
                                          })
                                        );
                                      }
                                      dispatch(setExpandFlightDetails(null));
                                    }}
                                  >
                                    {CurrencyCode()} {totalPaxFare(infoList)}
                                  </button>
                                </LightTooltip>
                              </div>
                            ) : null}
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default Pia;

// refund and exchange
const RefundExchange = () => {
  return (
    <LightTooltip
      title={
        <div className="p-3">
          <h4>Refund & Exchange</h4>
          <p className="text-danger font-italic">
            for further clarification contact with Airline Or Call our support
            team.
          </p>
          <div className="d-flex pt-3">
            <div>
              <h4>Conditions</h4>
              <h6>More than 48 hours to departure</h6>
              <h6>Within 48 hours To 4 hours to Departure</h6>
              <h6>Less than 4 hours after departure</h6>
              <h6>No show</h6>
            </div>
            <div className="mx-4">
              <h4>COB</h4>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
            </div>
            <div>
              <h4>Refund</h4>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
            </div>
          </div>
        </div>
      }
      placement="top"
    >
      <div style={{ cursor: "pointer" }}>
        <MdRemoveRedEye size={25} color="#262829" />
        <span>View</span>
      </div>
    </LightTooltip>
  );
};

// Connected Flights
const ConnectedFlight = ({
  item,
  inx,
  calculateTotalTimeDifference,
  fligthSegment,
  operatedAirline,
  convertTime,
}) => {
  const [airport_name, setairport_name] = useState("");
  const [arr_airport_name, setArrairport_name] = useState("");

  //api call for fatching airport name
  useEffect(() => {
    const fetchDataDep = async () => {
      try {
        let airportCode = item?.flightSegment?.departureAirport?.locationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    const fetchDataArr = async () => {
      try {
        let airportCode = item?.flightSegment?.arrivalAirport?.locationCode;
        const response = await NODEURL.get(
          `/api/v1/b2b/airportlist/filter/?query_param=${airportCode}`
        );
        const data = response.data.data?.at(0)?.airport_name;
        // Handle the data here, for example, set it in the state
        setArrairport_name(data);
      } catch (error) {
        // Handle errors here
      }
    };

    fetchDataDep(); // Call the fetchData function
    fetchDataArr(); // Call the fetchData function
  }, []);

  return (
    <div key={inx}>
      <div className="row align-items-center">
        <div className="col-2">
          <img src={PiaLogo} alt="logo" width={70} height={70} />
          <h6 className="mt-3 ms-2">
            {item?.flightSegment?.airline?.code}{" "}
            {item?.flightSegment?.flightNumber}
          </h6>

          <div className="mt-2">
            Operated by : {item?.flightSegment?.airline?.code}
          </div>
        </div>
        <div className="col-10 d-flex justify-content-between align-items-center">
          <div className="col-5 d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div className="">
              <h6>
                {moment(item?.flightSegment?.departureDateTime).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>

              <h6 className="my-3">
                {moment(item?.flightSegment?.departureDateTime).format("HH:mm")}
              </h6>
              <h6>
                {item?.flightSegment?.departureAirport?.locationCode} -{" "}
                {airport_name}
              </h6>
              {item?.flightSegment?.departureAirport?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{item?.flightSegment?.departureAirport?.terminal})
                </h6>
              )}
            </div>
          </div>

          <div className="col-2 d-flex flex-column align-items-start">
            <div className="d-flex justify-content-center">
              <MdFlight className="color_primary fs-5 rotate-right" />
              <h6 className="text-center">Trip Time</h6>
            </div>
            <div className="border_rounded mb-2" />
            <h6 className="text-center ms-4">
              {convertTime(item?.flightSegment?.journeyDuration)}
            </h6>
          </div>
          <div className="col-5 d-flex align-items-center ps-4">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(item?.flightSegment?.arrivalDateTime).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>
              <h6 className="my-3">
                {moment(item?.flightSegment?.arrivalDateTime).format("HH:mm")}
              </h6>
              <h6>
                {item?.flightSegment?.arrivalAirport?.locationCode} -{" "}
                {arr_airport_name}
              </h6>
              {item?.flightSegment?.arrivalAirport?.terminal && (
                <h6 className="color_primary">
                  (Terminal-{item?.flightSegment?.arrivalAirport?.terminal})
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
      {inx < fligthSegment.length - 1 && (
        <div className="col-12 d-flex justify-content-center align-items-center my-4">
          <div className="border_rounded w-25" />
          <div className="color_primary mx-2 d-flex justify-content-center align-items-center">
            <AirlineSeatReclineNormalIcon className="color_primary" />{" "}
            <h6 className="pt-2">
              Layover time:{" "}
              <span className="fs-5">
                {" "}
                {calculateTotalTimeDifference(
                  item?.flightSegment?.arrivalDateTime,
                  fligthSegment[inx + 1]?.flightSegment?.departureDateTime
                )}
              </span>
            </h6>
          </div>
          <div className="border_rounded w-25" />
        </div>
      )}
    </div>
  );
};
