import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";

// import icons
import AccountW from "../../b2b icons/finance/account W.png";
import AccountB from "../../b2b icons/finance/accounts.png";
import AgentW from "../../b2b icons/finance/agents W.png";
import AgentB from "../../b2b icons/finance/agent.png";
import SaleW from "../../b2b icons/finance/sale W.png";
import SaleB from "../../b2b icons/finance/sale.png";
import SupplierW from "../../b2b icons/finance/supplier W.png";
import Supplier from "../../b2b icons/finance/supplier.png";
import VouchersW from "../../b2b icons/finance/vouchers W.png";
import Vouchers from "../../b2b icons/finance/vouchers.png";

import PaymentsW from "../../b2b icons/setup/payments W.png";
import PaymentsB from "../../b2b icons/setup/payments.png";
import checkMenuItemExistence from "../../Utils/checkMenuItemExistence";
import { setChangeComponet } from "../../redux/features/Statement/StatementSlice";
import { setChangeInvoice } from "../../redux/features/Finance/Sale/SaleReportSlice";
import { setOpenAgentOutStanding } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { setSupplierPaybleComponent } from "../../redux/features/supplier/AddNewSupplier";
import { handleEmptysubAcountData } from "../../redux/features/Finance/Account/ChartOfAccountSlice";

const FinanceDropDown = ({ callback }) => {
  const navigate = useNavigate();

  // set and get local storage
  const Active_link = localStorage.getItem("Active_link")?.split(")")[0];
  const setActive_link = (value) =>
    localStorage.setItem("Active_link", `${value})finance`);
  // use state
  const [SubMenu, setSubMenu] = useState("");
  const [isHovered, setIsHovered] = useState("dark");
  const [bankbooking, setBankBooking] = useState(false);

  //use Selector
  const user = JSON.parse(localStorage.getItem("loginUser"));
  const { userTypePermissions } = useSelector(
    (state) => state?.roleAndPermissions
  );
  // Menu array for agency dropdown
  const menuArrays = [
    //payments
    {
      title: "Payments",
      page_level_id: [91, 92, 93],
      subMenu: [
        {
          title: user?.agent_id === 1 ? "Agent Payment" : "Make Deposit",
          pageLink: "/agent-payment",
          page_level_id: 91,
        },
        {
          title: "Reciept",
          pageLink: "/reciept",
          page_level_id: 92,
        },
        {
          title: "Collections",
          pageLink: "/collection",
          page_level_id: 93,
        },
      ],
      iconW: PaymentsW,
      iconB: PaymentsB,
    },
    // agents
    {
      title: "Agents",
      page_level_id: [125, 126],
      subMenu: [
        {
          title: "Agent Outstanding", // change from Agent List Agent Outstanding
          pageLink: "/agent-list",
          page_level_id: 125,
        },
        {
          title: "Agent Statement",
          pageLink: "/agent-account-statement",
          page_level_id: 126,
        },
      ],
      iconW: AgentW,
      iconB: AgentB,
    },
    //supplier
    {
      title: "Suppliers",
      page_level_id: [95],
      iconW: SupplierW,
      iconB: Supplier,
      subMenu: [
        {
          title: "Supplier Payable", //change from Supplier List Supplier Payable
          pageLink: "/supplier-list",
          page_level_id: 0,
        },
        {
          title: "Supplier Statement",
          pageLink: "/supplier-account-statement",
          page_level_id: 95,
        },
      ],
    },
    //sale
    {
      title: "Sale",
      page_level_id: [96, 97, 98, 99, 100, 101],
      subMenu: [
        {
          title: "Daily Sale Report",
          pageLink: "/daily-sales-report",
          page_level_id: 96,
        },
        {
          title: "Sale Report",
          pageLink: "/sales-report",
          page_level_id: 97,
        },
        {
          title: "Refund Report",
          pageLink: "/refund-report",
          page_level_id: 98,
        },
        {
          title: "Transactional Report",
          pageLink: "/transaction-report",
          page_level_id: 99,
        },
        {
          title: "Visa Report",
          pageLink: "/visa-report",
          page_level_id: 100,
        },
        // {
        //   title: "Group Flight Menifest",
        //   pageLink: "/group-flight-manifest",
        //   page_level_id: 101,
        // },
        // {
        //   title: "Airline PLB",
        //   pageLink: "/airline-report",
        // },
        {
          title: "Agent Progress Report",
          pageLink: "/agent-error-finding",
          page_level_id: 102,
        },
        {
          title: "Supplier Progress Report",
          pageLink: "/supplier-error-finding",
          page_level_id: 103,
        },
        // {
        //   title: "Umrah Menifest View",
        //   pageLink: "/umrah-manifest-view",
        // },
      ],
      iconW: SaleW,
      iconB: SaleB,
    },
    //  vouchers
    {
      title: "Vouchers",
      page_level_id: [110, 104, 111, 112],
      subMenu: [
        {
          title: "All Voucher",
          page_level_id: 104,
          drp_sub: [
            {
              title: "Save Voucher",
              pageLink: "/save-voucher",
              page_level_id: 104,
            },
            {
              title: "Post Voucher",
              pageLink: "/post-voucher",
              page_level_id: 105,
            },
          ],
        },
        {
          title: "Voucher Entry",
          pageLink: "/vouchers-entry",
          page_level_id: 110,
        },
        {
          title: "Manual Invoice",
          pageLink: "/manual-invoice",
          page_level_id: 111,
        },
        {
          title: "Manual Credit",
          pageLink: "/manual-credit",
          page_level_id: 112,
        },
        // {
        //   title: "Invoice Logs",
        //   pageLink: "/invoice-logs",
        // },
      ],
      iconW: VouchersW,
      iconB: Vouchers,
    },
    // accounts
    {
      title: "Accounts",
      page_level_id: [113, 117, 118, 119, 120, 121, 122, 123, 124],
      subMenu: [
        {
          title: "Chart of Accounts",
          pageLink: "/chart-of-account",
          page_level_id: 113,
        },
        {
          title: "Journal Ledger",
          pageLink: "/gournal-ledger",
          page_level_id: 117,
        },
        {
          title: "Trial Balance",
          pageLink: "/trial-balance",
          page_level_id: 118,
        },
        {
          title: "Balance Sheet",
          pageLink: "/balance-sheet",
          page_level_id: 119,
        },
        {
          title: "Profit & Loss",
          pageLink: "/profit-and-lose",
          page_level_id: 120,
        },
        {
          title: "Asset Statement",
          pageLink: "/asset-statement",
          page_level_id: 121,
        },
        {
          title: "Expance Statement",
          pageLink: "/expance-statement",
          page_level_id: 122,
        },
        {
          title: "Bank/Cash Statement",
          pageLink: "/bank-cash",
          page_level_id: 123,
        },
        {
          title: "Opening Balance",
          pageLink: "/opening-balance",
          page_level_id: 137,
        },
        {
          title: "Add Cheque Book",
          pageLink: "/add-cheque-book",
        },
        // {
        //   title: "Bank Book",
        //   page_level_id: 12,
        //   drp_sub: [
        //     {
        //       title: "Add Cheque Book",
        //       page_level_id: 124,
        //     },
        //   ],
        // },
      ],
      iconW: AccountW,
      iconB: AccountB,
    },
  ];

  useEffect(() => {
    const filteredItems = checkMenuItemExistence(
      userTypePermissions,
      menuArrays,
      user
    );
    callback(filteredItems);
  }, [userTypePermissions, user]);
  const dispatch = useDispatch();
  return (
    <div className="dropdown-menu-finance" id="dropdown-menu-finance">
      {menuArrays.map((item, index) => {
        // if the login user has permission to the specified page
        let rolePermission = userTypePermissions?.find((role) => {
          return item?.page_level_id?.some(
            (id) =>
              role.user_type_id === user?.role_id && id === role.page_level_id
          );
        });

        return rolePermission || user?.user_id === 1 ? (
          <div
            key={index}
            onMouseEnter={() => setIsHovered(`W${index}`)}
            onMouseLeave={() => setIsHovered("")}
          >
            <div
              className={`finance-dropdown-items ${
                Active_link === item.pageLink ||
                item?.subMenu?.find((it) => it.pageLink === Active_link) ||
                item?.subMenu?.some((submenu) =>
                  submenu.drp_sub?.some(
                    (drpSub) => drpSub.pageLink === Active_link
                  )
                )
                  ? "menu_link_container-active"
                  : ""
              }`}
              onMouseEnter={() => setIsHovered(`W${index}`)}
              onMouseLeave={() => setIsHovered("")}
              onClick={() => {
                item.subMenu.length > 0 && SubMenu !== item.title
                  ? setSubMenu(item.title)
                  : setSubMenu("");
              }}
            >
              <div className="d-flex align-items-end">
                <img
                  src={
                    isHovered === `W${index}` ||
                    Active_link === item.pageLink ||
                    item?.subMenu?.find((it) => it.pageLink === Active_link) ||
                    item?.subMenu?.some((submenu) =>
                      submenu.drp_sub?.some(
                        (drpSub) => drpSub.pageLink === Active_link
                      )
                    )
                      ? item.iconW
                      : item.iconB
                  }
                  alt=""
                  width={25}
                  height={25}
                  className="me-3"
                />
                <div className="drp-links">{item.title}</div>
              </div>
              {item.subMenu.length > 0 && (
                <div>
                  {SubMenu === item.title ? (
                    <IoIosArrowUp size={25} />
                  ) : (
                    <IoIosArrowDown size={25} />
                  )}
                </div>
              )}
            </div>
            {item.subMenu && SubMenu === item.title ? (
              <div
                className="py-2"
                style={{ paddingLeft: "2rem" }}
                onMouseEnter={() => setIsHovered(``)}
              >
                {item.subMenu?.map((it) => {
                  // if the login user has permission to the specified page
                  let submenuPermition = userTypePermissions?.find(
                    (role) =>
                      role.user_type_id === user?.role_id &&
                      role.page_level_id === it?.page_level_id
                  );
                  return submenuPermition || user.user_id === 1 ? (
                    <div key={it.title + index}>
                      <div
                        onClick={() => {
                          it?.title === "Daily Sale Report" &&
                            dispatch(setChangeInvoice("dailyInvoice"));
                          it?.title === "Sale Report" &&
                            dispatch(setChangeInvoice("Invoice"));
                          it?.title === "Refund Report" &&
                            dispatch(setChangeInvoice("refundInvoice"));

                          it?.title === "Transactional Report" &&
                            dispatch(setChangeInvoice("transection"));
                          it?.title === "Agent Outstanding" &&
                            dispatch(setOpenAgentOutStanding("form"));
                          it?.title === "Supplier Payable" &&
                            dispatch(setSupplierPaybleComponent("form"));
                          it?.title === "Opening Balance" &&
                            dispatch(handleEmptysubAcountData());

                          it.pageLink && setActive_link(`${it.pageLink}`);
                          it.pageLink && navigate(`${it.pageLink}`);
                          it.drp_sub && setBankBooking(!bankbooking);
                          const element = document.getElementById(
                            "dropdown-menu-finance"
                          );
                          if (!["All Voucher"].includes(it?.title)) {
                            element.classList.remove(
                              "dropdown-menu-finance-show"
                            );
                          }
                          dispatch(setChangeComponet("search"));
                        }}
                        className={`finance-dropdown-items ${
                          Active_link === it.pageLink ||
                          it?.drp_sub?.find(
                            (sub) => sub.pageLink === Active_link
                          )
                            ? "menu_link_container-active"
                            : ""
                        }`}
                      >
                        <div className="d-flex">
                          <EastIcon color="white" size="20" className="me-2" />
                          <span>{it.title}</span>
                        </div>
                        {it?.drp_sub && (
                          <div>
                            {bankbooking ? (
                              <IoIosArrowUp size={25} />
                            ) : (
                              <IoIosArrowDown size={25} />
                            )}
                          </div>
                        )}
                      </div>
                      {it?.drp_sub && bankbooking ? (
                        <div className="py-2" style={{ paddingLeft: "2rem" }}>
                          {it?.drp_sub?.map((drp) => {
                            return (
                              <div
                                onClick={() => {
                                  setActive_link(`${drp.pageLink}`);
                                  navigate(`${drp.pageLink}`);

                                  const element = document.getElementById(
                                    "dropdown-menu-finance"
                                  );
                                  element.classList.remove(
                                    "dropdown-menu-finance-show"
                                  );
                                }}
                                className={`finance-dropdown-items ${
                                  Active_link === drp.pageLink &&
                                  "menu_link_container-active"
                                }`}
                                key={drp.title}
                              >
                                <div className="d-flex">
                                  <EastIcon
                                    color="white"
                                    size="20"
                                    className="me-2"
                                  />
                                  <span>{drp.title}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  ) : null;
                })}
              </div>
            ) : null}
          </div>
        ) : null;
      })}
    </div>
  );
};

export default FinanceDropDown;
