import React, { useEffect, useLayoutEffect, useRef } from "react";
import AppNavigation from "./AppNavigation";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAdminAgency } from "./redux/features/setupRestPages/agentsSlice";
import { apiUrl } from "./Utils/configUrl";
import {
  asyncGetAllUserPermission,
  asyncGetAllUserTypePermission,
} from "./redux/features/agency/rolesPermissionSlice";
import { useNavigate } from "react-router-dom";
import { asyncUserLogout } from "./redux/features/userLogin/UserSlice";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminAgencyData } = useSelector((state) => state.agentsCategories);
  const timer = useRef(null);

  const firstColor = adminAgencyData?.first_color;
  const secondColor = adminAgencyData?.second_color;

  const root = document.querySelector(":root");
  root.style.setProperty("--primary-color", firstColor);
  root.style.setProperty("--secondary-color", secondColor);

  // GET USER PERMITION, MAIN AGENCY DATA
  useLayoutEffect(() => {
    dispatch(asyncGetAllUserTypePermission());
    dispatch(asyncGetAllUserPermission());
    dispatch(asyncGetAdminAgency());
  }, [dispatch]);
  // SET COLORS, FAV ICON, TITTLE
  useEffect(() => {
    //set colors (primary and secondary colors)
    document.documentElement.style.setProperty(
      "--primary-color",
      adminAgencyData?.first_color
    );
    document.documentElement.style.setProperty(
      "--second-color",
      adminAgencyData?.second_color
    );
    // change fav icon
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute("href", `${apiUrl}/${adminAgencyData?.fav_icon}`);

    document.title = adminAgencyData?.agent_name || "OTAWIX SOLUTION";
  }, [adminAgencyData]);

  // DISABLE INSPECT ELEMENT
  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyPress = (e) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && (e.key === "I" || e.key === "J"))
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const resetTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      handleLogout();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds
  };

  const handleLogout = () => {
    localStorage.removeItem("model");
    localStorage.removeItem("loginUser");
    localStorage.removeItem("currency");
    localStorage.removeItem("flightSearchData");
    dispatch(asyncUserLogout(navigate));
  };

  useEffect(() => {
    const events = ["mousemove", "keydown", "mousedown", "touchstart", "click"];
    const resetUserTimer = () => resetTimer();
    events.forEach((event) => window.addEventListener(event, resetUserTimer));
    resetTimer(); // initialize the timer when the component mounts
    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetUserTimer)
      );
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return <AppNavigation />;
};

export default App;
