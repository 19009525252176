import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, HTTP2, HTTPJson, NODEURL } from "../ApiConfig";

const initialState = {
  visaRate: [],
  editRate: null,
  loading: false,
  error: null,
};

// getting visaRate data
export const asyncGetVisaRate = createAsyncThunk(
  "asyncGetVisaRate/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/visa_type_rate`);
      return await response?.data?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting visaRate data
export const asyncPostVisaRate = createAsyncThunk(
  "asyncPostVisaRate/post",
  async (visabody, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/visa_type_rate`, visabody);

      dispatch(asyncGetVisaRate());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit visaRate data first we weill get it for updating
export const asyncEditRate = createAsyncThunk(
  "asyncEditRequirement/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/visa_type_rate/single/${id}`);
      return await response?.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// update visaRate data first we weill get it for updating
export const asyncUpdateRate = createAsyncThunk(
  "asyncUpdateRate/patch",
  async (visabody, { rejectWithValue, dispatch }) => {

    try {
      const response = await NODEURL.patch(`/api/v1/b2b/visa_type_rate`, visabody)
      dispatch(asyncGetVisaRate());
      // return await response.data.data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit visaRate data first we weill get it for updating
export const asyncDeleteRate = createAsyncThunk(
  "asyncDeleteRate/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(
        `/api/v1/b2b/visa_type_rate/${id}`
      );
      dispatch(asyncGetVisaRate());
      // return await response.data.data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const VisaRatelice = createSlice({
  name: "VisaRatelice",
  initialState,

  reducers: {
    reqEditNull: (state) => {
      state.editRate = null;
    },
  },
  extraReducers: (builder) => {
    // getting visaRate data
    builder.addCase(asyncGetVisaRate.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetVisaRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.visaRate = payload;
    });
    builder.addCase(asyncGetVisaRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting visaRate data
    builder.addCase(asyncPostVisaRate.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostVisaRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      // state.visaRate = payload;
    });
    builder.addCase(asyncPostVisaRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // edit visaRate data
    builder.addCase(asyncEditRate.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editRate = payload;
    });
    builder.addCase(asyncEditRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update visaRate data
    builder.addCase(asyncUpdateRate.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    //delete visaRate data
    builder.addCase(asyncDeleteRate.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { reqEditNull } = VisaRatelice.actions;
export default VisaRatelice.reducer;
