import moment from 'moment'
import React from 'react'
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from 'react-icons/md';
import { useDispatch } from 'react-redux';

const SereneSegments = ({ serene, timeDuration }) => {
    const dispatch = useDispatch();
    const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
    const locDeparture = searchValues?.locationDep;
    const locArrival = searchValues?.locationArrival;

    const getCity = (loc) => {
        let city = loc?.split("-")[2]?.toUpperCase();
        return city;
    };
    return (
        <div>
            <div className="d-flex align-items-center py-4 border-bottom">
                <div className="col-2">
                    <div className="">
                        <div className="text-center">
                            <img
                                src={`/NEW_LOGO/${serene?.outbound?.segments?.at(0)?.MarketingCarrierInfo?.CarrierDesigCode}.png`}
                                alt="logo"
                                width={90}
                                height={90}
                            />
                            <div className="pt-3" style={{ whiteSpace: "nowrap" }}>
                                {serene?.outbound?.segments?.map((it, idx, arr) => (
                                    <span className="pe-1" key={idx}>{`${it?.MarketingCarrierInfo?.CarrierDesigCode} ${it?.MarketingCarrierInfo?.MarketingCarrierFlightNumberText
                                        } ${idx < arr.length - 1 ? " -" : ""}`}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightTakeoff size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(serene?.outbound?.segments?.at(0)?.Dep?.AircraftScheduledDateTime?.split("T")[0]).format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    {moment(
                                        serene?.outbound?.segments.at(0)?.Dep?.AircraftScheduledDateTime?.split("T")[1],
                                        "HH:mm:ss"
                                    ).format("HH:mm")}
                                </h6>
                                <h6>
                                    {serene?.outbound?.segments.at(0)?.Dep?.IATA_LocationCode} -{" "}
                                    {getCity(locDeparture)}
                                </h6>
                            </div>
                        </div>

                        <div>
                            <h6 className="text-center">
                                {timeDuration(
                                    serene?.outbound?.segments?.at(0)?.Dep?.AircraftScheduledDateTime,
                                    serene?.outbound?.segments[serene?.outbound?.segments?.length - 1]?.Arrival?.AircraftScheduledDateTime
                                )}
                            </h6>
                            <div className="border_rounded mb-2" />
                            <h6 className="mx-3">
                                {serene?.outbound?.segments?.length === 1
                                    ? "Non-Stop"
                                    : serene?.outbound?.segments?.length === 2
                                        ? "One-Stop"
                                        : serene?.outbound?.segments?.length === 3
                                            ? "Two-Stop"
                                            : serene?.outbound?.segments?.length === 4
                                                ? "Three Stop"
                                                : "Four Stop"}
                            </h6>

                            {serene?.outbound?.segments?.length > 1 && (
                                <h6 className="text-center">
                                    {serene?.outbound?.segments
                                        ?.slice(1)
                                        ?.map((seg) => seg?.Dep?.IATA_LocationCode)
                                        ?.join(", ")}
                                </h6>
                            )}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightLand size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(serene?.outbound?.segments?.at(serene?.outbound?.segments?.length - 1)?.Arrival?.AircraftScheduledDateTime?.split("T")[0]).format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    {moment(
                                        serene?.outbound?.segments.at(serene?.outbound?.segments?.length - 1)?.Arrival?.AircraftScheduledDateTime?.split("T")[1],
                                        "HH:mm:ss"
                                    ).format("HH:mm")}
                                </h6>
                                <h6>
                                    {serene?.outbound?.segments.at(serene?.outbound?.segments?.length - 1)?.Arrival?.IATA_LocationCode} -{" "}
                                    {getCity(locArrival)}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center py-4">
                <div className="col-2">
                    <div className="px-2">
                        <div className="text-center">
                            <img
                                src={`/NEW_LOGO/${serene?.inbound?.segments?.at(0)?.MarketingCarrierInfo?.CarrierDesigCode}.png`}
                                alt="logo"
                                width={90}
                                height={90}
                            />
                            <div className="pt-3" style={{ whiteSpace: "nowrap" }}>
                                {serene?.inbound?.segments?.map((it, idx, arr) => (
                                    <span className="pe-1" key={idx}>{`${it?.MarketingCarrierInfo?.CarrierDesigCode} ${it?.MarketingCarrierInfo?.MarketingCarrierFlightNumberText
                                        } ${idx < arr.length - 1 ? " -" : ""}`}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <div className="d-flex justify-content-evenly align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightTakeoff size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(serene?.inbound?.segments?.at(0)?.Dep?.AircraftScheduledDateTime?.split("T")[0]).format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    {moment(
                                        serene?.inbound?.segments.at(0)?.Dep?.AircraftScheduledDateTime?.split("T")[1],
                                        "HH:mm:ss"
                                    ).format("HH:mm")}
                                </h6>
                                <h6>
                                    {serene?.inbound?.segments.at(0)?.Dep?.IATA_LocationCode} -{" "}
                                    {getCity(locArrival)}
                                </h6>
                            </div>
                        </div>

                        <div>
                            <h6 className="text-center">
                                {timeDuration(
                                    serene?.inbound?.segments?.at(0)?.Dep?.AircraftScheduledDateTime,
                                    serene?.inbound?.segments[serene?.inbound?.segments?.length - 1]?.Arrival?.AircraftScheduledDateTime
                                )}
                            </h6>
                            <div className="border_rounded mb-2" />
                            <h6 className="mx-3">
                                {serene?.inbound?.segments?.length === 1
                                    ? "Non-Stop"
                                    : serene?.inbound?.segments?.length === 2
                                        ? "One-Stop"
                                        : serene?.inbound?.segments?.length === 3
                                            ? "Two-Stop"
                                            : serene?.inbound?.segments?.length === 4
                                                ? "Three Stop"
                                                : "Four Stop"}
                            </h6>

                            {serene?.inbound?.segments?.length > 1 && (
                                <h6 className="text-center">
                                    {serene?.inbound?.segments
                                        ?.slice(1)
                                        ?.map((seg) => seg?.Dep?.IATA_LocationCode)
                                        ?.join(", ")}
                                </h6>
                            )}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <MdOutlineFlightLand size={30} className="color_primary" />
                            </div>
                            <div>
                                <h6>
                                    {moment(serene?.inbound?.segments?.at(serene?.inbound?.segments?.length - 1)?.Arrival?.AircraftScheduledDateTime?.split("T")[0]).format(
                                        "ddd DD-MMM-YY"
                                    )}
                                </h6>
                                <h6 className="my-3">
                                    {moment(
                                        serene?.inbound?.segments.at(serene?.inbound?.segments?.length - 1)?.Arrival?.AircraftScheduledDateTime?.split("T")[1],
                                        "HH:mm:ss"
                                    ).format("HH:mm")}
                                </h6>
                                <h6>
                                    {serene?.inbound?.segments.at(serene?.inbound?.segments?.length - 1)?.Arrival?.IATA_LocationCode} -{" "}
                                    {getCity(locDeparture)}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SereneSegments
