import { Box } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { BsBagCheck, BsHandbag } from "react-icons/bs";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../../Utils/currencyExchange";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import {
  asyncAmadeusPricing,
  setReturnFlight,
  setselectedFlight,
} from "../../../../../redux/features/home/FlightSearchSlice";
import { LightTooltip } from "../LightTooltip";
import BtnLoader from "../../../../AppForm/BtnLoader";
import { NODEURL } from "../../../../../redux/features/ApiConfig";
import { FaSuitcase, FaSuitcaseRolling } from "react-icons/fa";
import { RefundExchange } from "../pia";
import { MdOutlineAirlineSeatReclineNormal, MdOutlineRestaurant, MdSwapHoriz } from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";

const AmadeusRetrunNoBrandFare = ({
  amadeusItem,
  totalFarePrice,
  singPaxFareTotal,
  singPaxTotalFare,
  timeDuration,
  selectedFlight,
}) => {
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const { pricingLoading, pricingError } = useSelector(
    (state) => state.FlightSearch
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adultFare = singPaxFareTotal(
    amadeusItem,
    searchValues?.adultsCount,
    "ADULT"
  );
  const childFare = singPaxFareTotal(
    amadeusItem,
    searchValues?.childCount,
    "CHILD"
  );
  const infantFare = singPaxFareTotal(
    amadeusItem,
    searchValues?.infantNo,
    "HELD_INFANT"
  );

  const findBasefare = (fare, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );
    return Number(travelerPricing?.price?.base);
  };

  const findTaxes = (fare, travelerType) => {
    const travelerPricing = fare?.travelerPricings?.find(
      (tp) => tp?.travelerType === travelerType
    );

    return Number(travelerPricing?.price?.total - travelerPricing?.price?.base);
  };

  const findTaxesBreakup = (fare, travelerType) => {
    const travelerPricing = fare.travelerPricings.find(
      (tp) => tp.travelerType === travelerType
    );

    return travelerPricing?.price?.taxes || [];
  };

  const getAirportInfo = async (airportCode) => {
    const { data } = await NODEURL.get(
      `/api/v1/b2b/airport-list/${airportCode}`
    );
    const { code, city, airport_name } = data?.data;
    return `${code} - ${airport_name} - ${city}`;
  };

  const handlePricing = async (amadeusItem) => {
    const pricing = await dispatch(
      asyncAmadeusPricing({
        flightOffers: amadeusItem,
        agent_name: amadeusItem?.agent_name,
        type: "flight-offers-pricing",
      })
    );

    const brandFare = pricing?.payload;

    if (brandFare?.itineraries) {
      const segments = await Promise.all(
        brandFare?.itineraries
          ?.flatMap(({ segments }) => [...segments])
          ?.map(async (segment, idx) => {
            const locations = await Promise.all([
              getAirportInfo(segment?.departure?.iataCode),
              getAirportInfo(segment?.arrival?.iataCode),
            ]);
            return {
              ...amadeusItem,
              selectedFare: brandFare,
              departure_date: moment(
                segment?.departure?.at.split("T")?.at(0)
              ).format("ddd DD-MMM-YY"),
              baggage: brandFare.travelerPricings
                ?.at(0)
                ?.fareDetailsBySegment?.at(idx)?.includedCheckedBags?.weight
                ? `${brandFare.travelerPricings
                  ?.at(0)
                  ?.fareDetailsBySegment?.at(idx)?.includedCheckedBags
                  ?.weight
                } KG`
                : `${brandFare.travelerPricings
                  ?.at(0)
                  ?.fareDetailsBySegment?.at(idx)?.includedCheckedBags
                  ?.quantity
                } piece` || "",
              departure_time: segment?.departure?.at
                .split("T")
                ?.at(1)
                ?.slice(0, 5),
              arrival_date: moment(
                segment?.arrival?.at.split("T")?.at(0)
              ).format("ddd DD-MMM-YY"),
              arrival_time: segment?.arrival?.at.split("T")?.at(1)?.slice(0, 5),
              origin_terminal: "",
              desti_terminal: "",
              flight_number: segment?.number,
              number_in_party: "",
              res_book_desig_code: "",
              status: "NN",
              des_location: locations?.at(1),
              dest_code: segment?.arrival?.iataCode,
              operatingairline_code: segment?.operating?.carrierCode,
              marketing_airline: segment?.carrierCode,
              orig_location: locations?.at(0),
              orig_code: segment?.departure?.iataCode,
              marriage_grp: "",
              cabin_class: "",
              segment_type: getSectorsName(
                searchValues?.locationDep,
                searchValues?.locationArrival
              ),
            };
          })
      );
      let bookingBody = {
        flight: amadeusItem,
        fare: amadeusItem,
        gds: "IATA BSP",
        price: totalFarePrice(amadeusItem),
        ttl_agent_fare: TwoDecimalPoint(
          CurrencyExchangeToPKR(Number(totalFarePrice(amadeusItem) || 0))
        ),
        ttlagent_curr_fare: Number(totalFarePrice(amadeusItem) || 0),
        ttl_supplier_fare: ~~amadeusItem?.price?.grandTotal || 0,
        ttlsup_curr_fare:
          TwoDecimalPoint(~~CurrencyExchange(amadeusItem?.price?.grandTotal)) ||
          0,
        adlt_ttl_d_curr:
          CurrencyExchangeToPKR(singPaxTotalFare(amadeusItem, "ADULT")) || 0,
        chld_ttl_d_curr:
          CurrencyExchangeToPKR(singPaxTotalFare(amadeusItem, "CHILD")) || 0,
        inft_ttl_d_curr:
          CurrencyExchangeToPKR(singPaxTotalFare(amadeusItem, "HELD_INFANT")) ||
          0,
        adultBaseFare: findBasefare(amadeusItem, "ADULT") || 0,
        childBaseFare: findBasefare(amadeusItem, "CHILD") || 0,
        infantBaseFare: findBasefare(amadeusItem, "HELD_INFANT") || 0,
        adultTaxes: findTaxes(amadeusItem, "ADULT") || 0,
        childTaxes: findTaxes(amadeusItem, "CHILD") || 0,
        infantTaxes: findTaxes(amadeusItem, "HELD_INFANT") || 0,
        ADLT_TAXES: findTaxesBreakup(brandFare, "ADULT"),
        CHLD_TAXES: findTaxesBreakup(brandFare, "CHILD"),
        INFT_TAXES: findTaxesBreakup(brandFare, "HELD_INFANT"),
        baggageName:
          brandFare.travelerPricings?.at(0)?.fareDetailsBySegment?.at(0)
            ?.includedCheckedBags?.quantity || "",
        flightNumber: amadeusItem?.itineraries[0]?.segments[0]?.number,
        depDate: moment(
          amadeusItem?.itineraries[0]?.segments[0]?.departure?.at
            .split("T")
            ?.at(0)
        ).format("ddd DD-MMM-YY"),
        depTime: amadeusItem?.itineraries[0]?.segments[0]?.departure?.at
          .split("T")
          ?.at(1)
          ?.slice(0, 5),
        arrDate: moment(
          amadeusItem?.itineraries[0]?.segments[0]?.arrival?.at
            .split("T")
            ?.at(0)
        ).format("ddd DD-MMM-YY"),
        arrTime: amadeusItem?.itineraries[0]?.segments[0]?.arrival?.at
          ?.split("T")[1]
          ?.slice(0, 5),
        flightDuration: timeDuration(
          amadeusItem?.itineraries?.at(0)?.segments?.at(0)?.departure?.at,
          amadeusItem?.itineraries
            ?.at(0)
            ?.segments?.at(amadeusItem?.itineraries?.at(0)?.segments.length - 1)
            ?.arrival?.at
        ),
        logo: "AmadeusReturn",
        code: amadeusItem?.validatingAirlineCodes?.at(0),

        booking: {
          gds: "AMADEUS", // should be gds code
          pnr: "", // returned in pricing response
          agent_name: amadeusItem?.agent_name,
          segments,
          tp_ur_code: "",
          platting_carrier:
            amadeusItem?.itineraries[0]?.segments[0]?.carrierCode,
          tp_ssr_verssion: 0,
          tp_trace_id: "",
        },
      };

      dispatch(
        setselectedFlight({
          ...bookingBody,
        })
      );
      navigate("/check-out");
    }
  };

  return (
    <div>
      <div className="pt-6">
        <div className="d-flex align-items-center">
          <Box width={40} className="color_primary">
            <FaSuitcaseRolling size={25} />
          </Box>
          <h6>
            Hand Carry: {amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
              ?.includedCabinBags?.quantity
              ? `${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
                ?.includedCabinBags?.quantity > 1
                ? `${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCabinBags?.quantity} pieces`
                : `${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCabinBags?.quantity} piece`
              }`
              : amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
                ?.includedCabinBags?.weight &&
              `1 piece ${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCabinBags?.weight} ${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCabinBags?.weightUnit}`}
          </h6>
        </div>
        <div className="d-flex align-items-center pt-4">
          <Box width={30} className="color_primary">
            <FaSuitcase size={22} />
          </Box>

          <h6 className="ps-1">
            Check Bag: {amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
              ?.includedCheckedBags?.quantity
              ? `${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
                ?.includedCheckedBags?.quantity > 1
                ? `${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCheckedBags?.quantity} pieces`
                : `${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCheckedBags?.quantity} piece`
              }`
              : amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]
                ?.includedCheckedBags?.weight &&
              `1 piece ${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCheckedBags?.weight} ${amadeusItem?.travelerPricings[0]?.fareDetailsBySegment[0]?.includedCheckedBags?.weightUnit}`}
          </h6>
        </div>

        <Box display={"flex"}
          alignItems={"center"}
          pt={2}
        >
          <MdOutlineRestaurant
            size={25}
            className="color_primary"
          />
          <h6 className="ps-2 pt-2">Meal: Allowed</h6>
        </Box>

        <Box display={"flex"}
          alignItems={"center"}
          pt={2}>
          <Box>
            <MdOutlineAirlineSeatReclineNormal size={25} className="color_primary" />
          </Box>
          <h6 className="ps-2 pt-2">Seat Selection: System Allocated</h6>
        </Box>

        <Box
          pt={2}
          display={"flex"}
          alignItems={"center"}
        >
          <MdSwapHoriz
            size={30}
            className="color_primary"
          />
          <h6 className="pt-1 ps-2 text-success">
            Refundable
          </h6>
        </Box>
        <div className="flight-detail-headings">
          <RefundExchange />
        </div>

        <div className="mt-4">
          <LightTooltip
            title={
              <div className="p-3">
                <div className="d-flex justify-content-between align-items-end">
                  <div className="d-flex me-4">
                    <h6 style={{ minWidth: "3rem" }}>ADT</h6>
                    <h6>* {searchValues?.adultsCount}</h6>
                  </div>
                  <h6 style={{ minWidth: "7rem" }}>
                    {CurrencyCode()} {adultFare}
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-end my-2">
                  <div className="d-flex">
                    <h6 style={{ minWidth: "3rem" }}>CHD</h6>
                    <h6>* {searchValues?.childCount}</h6>
                  </div>
                  <h6 style={{ minWidth: "7rem" }}>
                    {CurrencyCode()} {childFare}
                  </h6>
                </div>
                <div className="d-flex justify-content-between align-items-end">
                  <div className="d-flex">
                    <h6 style={{ minWidth: "3rem" }}>INF</h6>
                    <h6>* {searchValues?.infantNo}</h6>
                  </div>
                  <h6 style={{ minWidth: "7rem" }}>
                    {CurrencyCode()} {infantFare}
                  </h6>
                </div>
              </div>
            }
            placement="top"
          >
            <button
              type="button"
              className="button_style px-4"
              onClick={() => {
                if (!selectedFlight) {
                  handlePricing(amadeusItem);
                }
              }}
            >
              {pricingLoading ? (
                <BtnLoader />
              ) : (
                `${CurrencyCode()} ${totalFarePrice(amadeusItem)}`
              )}
            </button>
          </LightTooltip>
          {pricingError ? (
            <div className="text-danger mt-2 fs-6">{pricingError}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AmadeusRetrunNoBrandFare;
