import { TwoDecimalPoint } from "./FixedTwoDecimal";

///////////////// calculate currency
export const CurrencyExchange = (amount) => {
  const currency = JSON.parse(localStorage.getItem("currency"));
  let newAmount = amount / currency?.roe;

  return currency !== "" ? newAmount : amount;
};
/////////// find currency code
export const CurrencyExchangeToPKR = (amount) => {
  const currency = JSON.parse(localStorage.getItem("currency"));
  let newAmount = amount * currency?.roe;

  return currency !== "" ? TwoDecimalPoint(newAmount) : amount;
};

export const CurrencyCode = () => {
  const currency = JSON.parse(localStorage.getItem("currency"));
  return currency !== "" ? currency?.curr_code : "PKR";
};

export const CurrencyExchangeBasedOnROE = (amount, roe) =>{
  return TwoDecimalPoint(amount / roe);
}

export const getCurrencyObj = () => {
  const currency = JSON.parse(localStorage.getItem("currency"));
  return currency !== "" ? currency : { roe: 1, id: 1, curr_code: "PKR" };
};
