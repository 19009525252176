import React, { useState } from "react";
import Slider from "react-slick";
import { iconMapping } from "../../../../../Utils/amadeusIcon";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncAmadeusPricing,
  setExpandFlightDetails,
  setselectedFlight,
} from "../../../../../redux/features/home/FlightSearchSlice";
import {
  CurrencyCode,
  CurrencyExchange,
  CurrencyExchangeToPKR,
} from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";
import { LightTooltip } from "../LightTooltip";
import moment from "moment";
import { getSectorsName } from "../../../../../Utils/FlightSector";
import { useNavigate } from "react-router-dom";
import BtnLoader from "../../../../AppForm/BtnLoader";
import { NODEURL } from "../../../../../redux/features/ApiConfig";

const AmadeusBrandedFare = ({
  amadeusBrandFare,
  amadeusItem,
  totalFarePrice,
  singPaxFareTotal,
  singPaxTotalFare,
  timeDuration,
  pricing,
  airline,
  selectedFlight,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showError, setShowError] = useState(false);
  const { pricingLoading } = useSelector((state) => state.FlightSearch);

  const CustomPrevArrow = (props) => (
    <div
      onClick={props.onClick}
      className="position-absolute top-50 translate-middle-y cursor-pointer custom-left-position custom-text-color"
    >
      <FaChevronCircleLeft size={30} />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div
      onClick={props.onClick}
      className="position-absolute top-50 translate-middle-y cursor-pointer custom-right-position custom-text-color custom-z-index"
    >
      <FaChevronCircleRight size={30} />
    </div>
  );

  var settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const findBasefare = (fare, travelerType) => {
    const travelerPricing = fare.travelerPricings.find(
      (tp) => tp.travelerType === travelerType
    );
    return Number(travelerPricing?.price?.base);
  };

  const findTaxes = (fare, travelerType) => {
    const travelerPricing = fare.travelerPricings.find(
      (tp) => tp.travelerType === travelerType
    );

    return Number(travelerPricing?.price?.total - travelerPricing?.price?.base);
  };

  const findTaxesBreakup = (fare, travelerType) => {
    const travelerPricing = fare.travelerPricings.find(
      (tp) => tp.travelerType === travelerType
    );

    return travelerPricing?.price?.taxes || [];
  };

  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));

  const getAirportInfo = async (airportCode) => {
    const { data } = await NODEURL.get(
      `/api/v1/b2b/airport-list/${airportCode}`
    );
    const { code, city, airport_name } = data?.data;
    return `${code} - ${airport_name} - ${city}`;
  };

  const handlePricing = async (brandFare, index) => {
    const segments = await Promise.all(
      brandFare?.itineraries[0]?.segments?.map(async (segment, idx) => {
        const locations = await Promise.all([
          getAirportInfo(segment?.departure?.iataCode),
          getAirportInfo(segment?.arrival?.iataCode),
        ]);
        return {
          ...amadeusItem,
          selectedFare: brandFare,
          departure_date: moment(
            segment?.departure?.at.split("T")?.at(0)
          ).format("ddd DD-MMM-YY"),
          baggage: brandFare.travelerPricings
            ?.at(0)
            ?.fareDetailsBySegment?.at(idx)?.includedCheckedBags?.weight
            ? `${brandFare.travelerPricings?.at(0)?.fareDetailsBySegment?.at(idx)
              ?.includedCheckedBags?.weight
            } KG`
            : `${brandFare.travelerPricings?.at(0)?.fareDetailsBySegment?.at(idx)
              ?.includedCheckedBags?.quantity
            } piece` || "",
          departure_time: segment?.departure?.at.split("T")?.at(1)?.slice(0, 5),
          arrival_date: moment(segment?.arrival?.at.split("T")?.at(0)).format(
            "ddd DD-MMM-YY"
          ),
          arrival_time: segment?.arrival?.at.split("T")?.at(1)?.slice(0, 5),
          origin_terminal: "",
          desti_terminal: "",
          flight_number: segment?.number,
          number_in_party: "",
          res_book_desig_code: "",
          status: "NN",
          des_location: locations?.at(1),
          dest_code: segment?.arrival?.iataCode,
          operatingairline_code: segment?.operating?.carrierCode,
          marketing_airline: segment?.carrierCode,
          orig_location: locations?.at(0),
          orig_code: segment?.departure?.iataCode,
          marriage_grp: "",
          cabin_class: brandFare?.travelerPricings
            ?.at(0)
            ?.fareDetailsBySegment?.at(0)?.brandedFare,
          segment_type: getSectorsName(
            searchValues?.locationDep,
            searchValues?.locationArrival
          ),
        };
      })
    );

    let bookingBody = {
      flight: amadeusItem,
      fare: brandFare,
      gds: "IATA BSP",
      price: totalFarePrice(brandFare),
      ttl_agent_fare: TwoDecimalPoint(
        CurrencyExchangeToPKR(Number(totalFarePrice(brandFare) || 0))
      ),
      ttlagent_curr_fare: Number(totalFarePrice(brandFare) || 0),
      ttl_supplier_fare: ~~brandFare?.price?.grandTotal || 0,
      ttlsup_curr_fare:
        TwoDecimalPoint(~~CurrencyExchange(brandFare?.price?.grandTotal)) || 0,
      adlt_ttl_d_curr:
        CurrencyExchangeToPKR(singPaxTotalFare(brandFare, "ADULT")) || 0,
      chld_ttl_d_curr:
        CurrencyExchangeToPKR(singPaxTotalFare(brandFare, "CHILD")) || 0,
      inft_ttl_d_curr:
        CurrencyExchangeToPKR(singPaxTotalFare(brandFare, "HELD_INFANT")) || 0,
      adultBaseFare: findBasefare(brandFare, "ADULT") || 0,
      childBaseFare: findBasefare(brandFare, "CHILD") || 0,
      infantBaseFare: findBasefare(brandFare, "HELD_INFANT") || 0,
      adultTaxes: findTaxes(brandFare, "ADULT") || 0,
      childTaxes: findTaxes(brandFare, "CHILD") || 0,
      infantTaxes: findTaxes(brandFare, "HELD_INFANT") || 0,
      ADLT_TAXES: findTaxesBreakup(brandFare, "ADULT"),
      CHLD_TAXES: findTaxesBreakup(brandFare, "CHILD"),
      INFT_TAXES: findTaxesBreakup(brandFare, "HELD_INFANT"),
      baggageName: brandFare.travelerPricings
        ?.at(0)
        ?.fareDetailsBySegment?.at(0)?.brandedFare,
      flightNumber: brandFare?.itineraries[0]?.segments[0]?.number,
      depDate: moment(
        brandFare?.itineraries[0]?.segments[0]?.departure?.at.split("T")?.at(0)
      ).format("ddd DD-MMM-YY"),
      depTime: brandFare?.itineraries[0]?.segments[0]?.departure?.at
        .split("T")
        ?.at(1)
        ?.slice(0, 5),
      arrDate: moment(
        brandFare?.itineraries[0]?.segments[0]?.arrival?.at.split("T")?.at(0)
      ).format("ddd DD-MMM-YY"),
      arrTime: brandFare?.itineraries[0]?.segments[0]?.arrival?.at
        ?.split("T")[1]
        ?.slice(0, 5),
      flightDuration: timeDuration(
        brandFare?.itineraries?.at(0)?.segments?.at(0)?.departure?.at,
        brandFare?.itineraries
          ?.at(0)
          ?.segments?.at(brandFare?.itineraries?.at(0)?.segments.length - 1)
          ?.arrival?.at
      ),
      logo: "Amadeus",
      code: brandFare?.validatingAirlineCodes?.at(0),

      booking: {
        gds: "AMADEUS", // should be gds code
        pnr: "", // returned in pricing response
        agent_name: amadeusItem?.agent_name,
        segments,
        tp_ur_code: "",
        platting_carrier: brandFare?.itineraries[0]?.segments[0]?.carrierCode,
        tp_ssr_verssion: 0,
        tp_trace_id: "",
      },
    };
    if (searchValues?.agent === "" || (pricing && airline)) {
      dispatch(
        asyncAmadeusPricing({
          flightOffers: brandFare,
          agent_name: amadeusItem?.agent_name,
          type: "flight-offers-pricing",
          navigate,
        })
      );
    } else {
      setShowError(index);
    }

    dispatch(
      setselectedFlight({
        ...bookingBody,
      })
    );
  };

  return (
    <>
      {selectedFlight ? (
        <div className="pt-4">
          <div className="fs-5 color_primary fw-bold">
            {
              selectedFlight?.fare?.travelerPricings
                ?.at(0)
                ?.fareDetailsBySegment?.at(0)?.brandedFare
            }
          </div>

          <div>
            {selectedFlight?.fare?.travelerPricings
              ?.at(0)
              ?.fareDetailsBySegment?.at(0)
              ?.amenities?.map((amenities, idx) => {
                return (
                  <div key={idx} className="d-flex align-items-center pt-3">
                    <div className="color_secondary pb-2">
                      {iconMapping[amenities?.code]}
                    </div>
                    <h6 className=" ps-2">
                      {amenities?.description.toLowerCase()}
                    </h6>
                  </div>
                );
              })}
          </div>

          <div className="mt-4">
            <LightTooltip
              title={
                <div className="p-3">
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex me-4">
                      <h6 style={{ minWidth: "3rem" }}>ADT</h6>
                      <h6>* {searchValues?.adultsCount}</h6>
                    </div>
                    <h6 style={{ minWidth: "7rem" }}>
                      {CurrencyCode()}{" "}
                      {singPaxFareTotal(
                        selectedFlight?.fare,
                        searchValues?.adultsCount,
                        "ADULT"
                      )}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-end my-2">
                    <div className="d-flex">
                      <h6 style={{ minWidth: "3rem" }}>CHD</h6>
                      <h6>* {searchValues?.childCount}</h6>
                    </div>
                    <h6 style={{ minWidth: "7rem" }}>
                      {CurrencyCode()}{" "}
                      {singPaxFareTotal(
                        selectedFlight?.fare,
                        searchValues?.childCount,
                        "CHILD"
                      )}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex">
                      <h6 style={{ minWidth: "3rem" }}>INF</h6>
                      <h6>* {searchValues?.infantNo}</h6>
                    </div>
                    <h6 style={{ minWidth: "7rem" }}>
                      {CurrencyCode()}{" "}
                      {singPaxFareTotal(
                        selectedFlight?.fare,
                        searchValues?.infantNo,
                        "HELD_INFANT"
                      )}
                    </h6>
                  </div>
                </div>
              }
              placement="top"
            >
              <button type="button" className="button_style px-4">
                {CurrencyCode()} {totalFarePrice(selectedFlight?.fare)}
              </button>
            </LightTooltip>
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          {amadeusBrandFare?.map((brandFare, index) => {
            const adultFare = singPaxFareTotal(
              brandFare,
              searchValues?.adultsCount,
              "ADULT"
            );
            const childFare = singPaxFareTotal(
              brandFare,
              searchValues?.childCount,
              "CHILD"
            );
            const infantFare = singPaxFareTotal(
              brandFare,
              searchValues?.infantNo,
              "HELD_INFANT"
            );

            return (
              <div className="ps-4 pt-4" key={index}>
                <div className="fs-5 color_primary fw-bold">
                  {
                    brandFare?.travelerPricings
                      ?.at(0)
                      ?.fareDetailsBySegment?.at(0)?.brandedFare
                  }
                </div>

                <div>
                  {brandFare?.travelerPricings
                    ?.at(0)
                    ?.fareDetailsBySegment?.at(0)
                    ?.amenities?.map((amenities, idx) => {
                      return (
                        <div
                          key={idx}
                          className="d-flex align-items-center pt-3"
                        >
                          <div className="color_secondary pb-2">
                            {iconMapping[amenities?.code]}
                          </div>
                          <h6 className=" ps-2">
                            {amenities?.description.toLowerCase()}
                          </h6>
                        </div>
                      );
                    })}
                </div>

                <div className="mt-4">
                  <LightTooltip
                    title={
                      <div className="p-3">
                        <div className="d-flex justify-content-between align-items-end">
                          <div className="d-flex me-4">
                            <h6 style={{ minWidth: "3rem" }}>ADT</h6>
                            <h6>* {searchValues?.adultsCount}</h6>
                          </div>
                          <h6 style={{ minWidth: "7rem" }}>
                            {CurrencyCode()} {adultFare}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-end my-2">
                          <div className="d-flex">
                            <h6 style={{ minWidth: "3rem" }}>CHD</h6>
                            <h6>* {searchValues?.childCount}</h6>
                          </div>
                          <h6 style={{ minWidth: "7rem" }}>
                            {CurrencyCode()} {childFare}
                          </h6>
                        </div>
                        <div className="d-flex justify-content-between align-items-end">
                          <div className="d-flex">
                            <h6 style={{ minWidth: "3rem" }}>INF</h6>
                            <h6>* {searchValues?.infantNo}</h6>
                          </div>
                          <h6 style={{ minWidth: "7rem" }}>
                            {CurrencyCode()} {infantFare}
                          </h6>
                        </div>
                      </div>
                    }
                    placement="top"
                  >
                    <button
                      type="button"
                      className="button_style px-4"
                      onClick={() => handlePricing(brandFare, index)}
                    >
                      {pricingLoading ? (
                        <BtnLoader />
                      ) : (
                        `${CurrencyCode()} ${totalFarePrice(brandFare)}`
                      )}
                    </button>
                  </LightTooltip>

                  {showError === index ? (
                    <div className="text-danger mt-2">
                      Pricing method is not define for this airline. Call to our
                      support team for assistance.
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </Slider>
      )}
    </>
  );
};

export default AmadeusBrandedFare;
