import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  agentStatement: [],
  changeComponent: "search",
};

export const AsyncCreateAgentStatment = createAsyncThunk(
  "AsyncCreateAgentStatment/post",
  async ({ body }, { rejectWithValue }) => {
    console.log("eee", body);
    try {
      const response = await NODEURL.post("/api/v1/b2b/agent-statement", body);
      return await response?.data?.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const Statement = createSlice({
  name: "Statement",
  initialState,

  reducers: {
    setChangeComponet(state, action) {
      state.changeComponent = action.payload; // Set visibility based on the passed value (true/false)
    },
  },

  extraReducers: (builder) => {
    builder.addCase(AsyncCreateAgentStatment.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      AsyncCreateAgentStatment.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.agentStatement = payload;
      }
    );
    builder.addCase(AsyncCreateAgentStatment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setChangeComponet } = Statement.actions;

export default Statement.reducer;
