import React from "react";
import { Modal } from "react-bootstrap";

const DeleteModal = ({ show, onClick, setShow, rowId, setuseEffectCall }) => {
  return (
    <Modal show={show} onHide={(e) => setShow(false)} centered>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="col-12 text-center mt-3">
            <span className="text-danger">NOTE:</span> You are about to delete
            the record, this procedure is irreversible.
          </div>
          <div className="col-12 text-center mt-3">Do you want to proceed?</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <button className="btn setup-btn" onClick={(e) => setShow(false)}>
            Close
          </button>
          <button
            className="setup-btn btn setup-btn ms-2"
            style={{ background: "#dc3545", color: "#fff" }}
            onClick={onClick}
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
