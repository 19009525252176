import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "./ancillaryStyle.css";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";
const AirportServices = ({
  paxes,
  serviceData,
  setFieldValue,
  setSelectedService,
  index,
  selectedService,
}) => {
  const [selectedPassengerIndex, setSelectedPassengerIndex] = useState(null);
  const [error, setError] = useState("");
  const handlePassengerClick = (paxIndex) => {
    setSelectedPassengerIndex(paxIndex);
    setError("");
  };

  const handleAddService = (serviceCode, serviceCharge) => {
    if (selectedPassengerIndex === null) {
      setError("Please select a passenger first");
      return;
    }
    const baggageKey = index===0 ? 'outbound' : 'inbound';
    const updatedSelectedService = [...selectedService];
    updatedSelectedService[selectedPassengerIndex] = { serviceCode, serviceCharge };
     setSelectedService(updatedSelectedService);
    setFieldValue(`pax[${selectedPassengerIndex}].${baggageKey}.service`, `${serviceCode}, ${serviceCharge}`)
  };

  return (
    <Container className="">
      <Box
        display="flex"
        columnGap={1}
        flexDirection="row"
        border={1}
        borderRadius={2}
        borderColor="gray" 
        className="flex-sm-column flex-md-column flex-lg-row" 
        
      >
        {/* First Component */}
        <Box
          sx={{
        borderRight: {
          xs: 'none', 
          sm: 'none', 
          md: 'none', 
          lg: '1px solid gray',
        },
       
      }}
        >
          <Box
            // bgcolor={"blue"}
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            p={3}
            mt={3}
            sx={{ width: { xs: "15rem", md: "20rem" }, paddingBottom: {
                xs: '100px',  
                sm: '100px',  
                md: 2,  
                lg: 0,  
              }, }}
          >
            {paxes.map((pax, index) => (
              <Box
                key={index}
                className={`d-flex align-items-center justify-content-between mt-2 px-2 py-2 rounded`}
                bgcolor={
                  selectedPassengerIndex === index ? "#00324E" : "#19465F"
                }
                borderRadius={1}
                color="white"
                justifyContent="space-between"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "auto",
                  textOverflow: "ellipsis",
                  cursor: "pointer",
                }}
                sx={{
                  borderRadius: 1,
                  "&:hover": {
                    bgcolor:
                      selectedPassengerIndex === index ? "#19465F" : "#0e304b",
                  },
                }}
                onClick={() => handlePassengerClick(index)}
              >
                <Typography
                  variant="body1"
                  pl={4}
                  style={{
                    flex: "0 0 70%",
                    maxWidth: "70%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${pax.title}: ${pax.f_name} ${pax.l_name}`.slice(0, 16)}
                </Typography>
                <Box className="checkbox-wrapper-12">
                  <Box className="cbx">
                    <input
                      type="checkbox" id="cbx-12"
                      checked={selectedService[index]}
                      onClick={() => handlePassengerClick(index)}
                    />
                    <label htmlFor="cbx-12"></label>
                    <svg fill="none" viewBox="0 0 15 14" height="14" width="15">
                      <path d="M2 8.36364L6.23077 12L13 2"></path>
                    </svg>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          {error && (
            <Box ml={4} bgcolor="red.100" fontSize={18} color="red">
              {error}
            </Box>
          )}
        </Box>

        {/* <Box mt={3} flex={1} bgcolor={"blue"} justifyContent={"center"} alignItems={"center"}> */}
        <Grid
          container
          rowGap={2}
          className="flex-sm-column flex-md-column"
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {serviceData &&
            serviceData.map((service, index) => (
              <React.Fragment key={index}>
              <Box
                // key={index}
                rowGap={20}
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  display: "flex",
                  width: "90%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "15px",
                  paddingLeft:"0"
                }}
                
              >
                <Box sx={{ width: "55%", pl: 3 }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{ display: { xs: "block", md: "block" } }}
                  >
                    {service["ns1:ssrName"]}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "0.8rem",
                      display: { xs: "block", md: "block" },
                    }}
                  >
                    {service["ns1:ssrDescription"]}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography align="right">
                    PKR{" "}
                    <span style={{ fontWeight: "bold", fontSize: "1.125rem" }}>
                      {service["ns1:adultAmount"]}
                    </span>
                  </Typography>
                  <Box
                    sx={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "#355a6b",
                      color: "white",
                      borderRadius: "4px",
                      px: 2,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleAddService(
                        service["ns1:ssrCode"],
                        service["ns1:adultAmount"]
                      )
                    }
                  >
                    <AiOutlinePlus size={18} />
                    <Typography variant="body1" sx={{ whiteSpace: "nowrap" }} padding={1}>
                      Add This Service
                    </Typography>
                  </Box>
                </Box>
                
              </Box>
              {index !== serviceData.length - 1 && (
                            <Divider sx={{ width: "100%", border:"1px solid gray" }} />
                        )}
              </React.Fragment>
            ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default AirportServices;
