import React, { useEffect, useState } from "react";
import FlightsGroupComp from "../components/Setup/Promotions/FlightsGroup";
import FlightGroupExcel from "../components/Setup/Promotions/FlightGroupExcel";
import { useDispatch, useSelector } from "react-redux";
import { asyncgetSingleFlightGroup } from "../redux/features/promotion/FlightGroupsSlice";
import { useLocation } from "react-router-dom";

const FlightGroups = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const { singleFlightData } = useSelector((state) => state.flightGroup);
  const TotalSeats = singleFlightData?.FlightBookingModels?.flatMap((item) =>
    item?.pax_tables.map((px) => ({
      ...item?.BookingMain,
      main_id: item?.main_id,
      ...px,
    }))
  );
    const id = location?.state?.id;

  useEffect(() => {
    dispatch(asyncgetSingleFlightGroup(id));
  }, [dispatch]);

  return (
    <div className="Full_height">
      <FlightsGroupComp />
      {TotalSeats?.length ? (
        <FlightGroupExcel
          TotalSeats={TotalSeats}
          totalSeat={singleFlightData?.ttl_seat}
          pnr={singleFlightData?.pnr}
          flightGroup={singleFlightData?.flight_group_flights}
          grouptType={singleFlightData?.FlightGroupTypeModel?.type_name}
        />
      ) : null}
    </div>
  );
};

export default FlightGroups;
