import React from "react";
import { TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../../AppForm/BtnLoader";
import { asyncUpdateAgencyCreditLine } from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { useParams } from "react-router-dom";
import { InputAdornment } from "@mui/material";
import { CurrencyExchangeBasedOnROE } from "../../../Utils/currencyExchange";

const AgentsCreditLine = () => {
  const dispatch = useDispatch();

  //use selectors redux
  const { loading } = useSelector((state) => state.newAgency);
  const { editAgentsData } = useSelector((state) => state.agentsCategories);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const user = JSON.parse(localStorage.getItem("loginUser"));
  // if the login user has permissions to edit the credit line
  const editPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level.permission_url === "/agency-credit-line"
      )
    : true;

  // agent id from parameters
  const { id: agentId } = useParams();
  //INITIALS VALUES
  const initialValues = {
    credit_limit: editAgentsData?.credit_limit,
    credit_used: editAgentsData?.credit_used,
    usable_credit: editAgentsData?.credit_limit - editAgentsData?.credit_used,
    limit_alert: editAgentsData?.limit_alert,
    roe: editAgentsData?.agent_group?.currency?.roe,
    currency:editAgentsData?.agent_group?.currency?.curr_code
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, action) => {
        let obj = {
          credit_limit: parseInt(values.credit_limit),
          credit_used: parseInt(values.credit_used),
          limit_alert: parseInt(values.limit_alert),
        };
        dispatch(asyncUpdateAgencyCreditLine({ obj, agentId }));
        action.resetForm({ values: "" });
      }}
    >
      {({ values, handleChange }) => {
        return (
          <Form className="d-flex justify-content-center align-items-center">
            <div className="row align-items-end rounded-2 shadow-sm w-50 black_border p-4">
              <div className="col-sm-6 mt-4 d-flex  flex-row justify-content-end align-items-end gap-2">
                <TextField
                  label="Approve Limit"
                  variant="standard"
                  className="w-100"
                  type="number"
                  name={`credit_limit`}
                  value={values.credit_limit}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="fw-bold">
                        PKR
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" className="fw-bold">
                        {values?.currency}{" "}
                        {CurrencyExchangeBasedOnROE(
                          ~~values.credit_limit,
                          ~~values?.roe
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="col-sm-6 mt-4">
                <TextField
                  label="Used Limit"
                  variant="standard"
                  className="w-100"
                  type="number"
                  name={`credit_used`}
                  value={values.credit_used}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="fw-bold">
                        PKR
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" className="fw-bold">
                        {values?.currency}{" "}
                        {CurrencyExchangeBasedOnROE(
                          ~~values.credit_used,
                          ~~values?.roe
                        )}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="col-sm-6 mt-4">
                <TextField
                  label="Available Limit"
                  variant="standard"
                  className="w-100"
                  type="number"
                  name={`usable_credit`}
                  value={values.usable_credit}
                  onChange={handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="fw-bold">
                        PKR
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" className="fw-bold">
                        {values?.currency}{" "}
                        {CurrencyExchangeBasedOnROE(
                          ~~values.usable_credit,
                          ~~values?.roe
                        )}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              {/* <div className="col-sm-6 mt-4">
                <TextField
                  label="Limit Alert"
                  variant="standard"
                  name={`limit_alert`}
                  className="w-100"
                  type="number"
                  value={values.limit_alert}
                  onChange={handleChange}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </div> */}
              <div className="text-center pb-2">
                {editPermission !== undefined || user.user_id === 1 ? (
                  <button className="btn setup-btn mt-5 mx-auto" type="submit">
                    {loading ? <BtnLoader /> : "Update"}
                  </button>
                ) : null}
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AgentsCreditLine;
