import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  user: null,
  token: null,

  loading: false,
  logInLoading: false,
  error: null,

  // forget password
  optEmail: null,
  otpCode: null,
  changePassword: null,
  loginError: null,
  otpMsg: null,
  changPass: null,
};

//agency user cookie token
export const asyncGetUserToken = createAsyncThunk(
  "asyncGetUserToken/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/agent-user-cookie");
      // console.log(response.data);
      return await response.data;
    } catch (error) {
      // console.log(error);
      rejectWithValue(error);
    }
  }
);

//get all agency user
export const asyncGetAgencyUser = createAsyncThunk(
  "asyncGetAgencyUser/post",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/agent-user-cookie");
      // console.log(response.data);
      return await response.data;
    } catch (error) {
      // console.log(error);
      rejectWithValue(error);
    }
  }
);

//agency user login
export const asyncUserLogin = createAsyncThunk(
  "asyncUserLogin/post",
  async ({ email, password, navigate }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/agent-user-login", {
        email,
        password,
      });
      asyncGetUserToken();
      localStorage.setItem("loginUser", JSON.stringify(response?.data?.result));
      navigate("/home");
      return await response.data;
    } catch (error) {
      dispatch(loginErrorMsg(error?.response?.data));
      rejectWithValue(error);
    }
  }
);

//agency user Logout
export const asyncUserLogout = createAsyncThunk(
  "asyncUserLogout/post",
  async (navigate, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/agent-user-logout");
      navigate("/");
      return await response.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// forget password
export const asyncForgetPassword = createAsyncThunk(
  "asyncForgetPassword/post",
  async (email, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/forget-password", {
        email: email,
      });
      // console.log(response.data);
      dispatch(otpEmailReducer(response.data));
      return await response.data;
    } catch (error) {
      // console.log(error);
      dispatch(otpEmailReducer(error.response.data));
      return rejectWithValue(error.response.data);
    }
  }
);

// confirm Otp Code
export const asyncConfirmOtpCode = createAsyncThunk(
  "asyncConfirmOtpCode/post",
  async (code, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/verify-code", {
        code: code,
      });
      // console.log(response.data);
      dispatch(otpMsgReducer(response.data));
      return await response.data;
    } catch (error) {
      // console.log(error);
      dispatch(otpMsgReducer(error.response.data));
      rejectWithValue(error);
    }
  }
);

// confirm Otp Code
export const asyncChangePassword = createAsyncThunk(
  "asyncChangePassword/post",
  async ({ id, password }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        "/api/v1/b2b/user/agent-user/" + id,
        {
          password: password,
        }
      );
      // console.log(response.data);
      dispatch(changePassReducer(response.data));
      return await response.data;
    } catch (error) {
      // console.log(error);
      dispatch(changePassReducer(error.response.data));
      rejectWithValue(error);
    }
  }
);

export const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    otpEmailReducer: (state, { payload }) => {
      state.optEmail = payload;
    },
    loginErrorMsg: (state, { payload }) => {
      state.loginError = payload;
    },
    otpMsgReducer: (state, { payload }) => {
      state.otpMsg = payload;
    },
    changePassReducer: (state, { payload }) => {
      state.changPass = payload;
    },
  },
  extraReducers: (builder) => {
    //agency user login
    builder.addCase(asyncUserLogin.pending, (state, { payload }) => {
      state.logInLoading = true;
      state.error = null;
    });
    builder.addCase(asyncUserLogin.fulfilled, (state, { payload }) => {
      state.logInLoading = false;
      state.error = null;
      state.user = payload?.result;
      state.token = payload?.token;
    });
    builder.addCase(asyncUserLogin.rejected, (state, { payload }) => {
      state.logInLoading = false;
      state.error = payload;
    });

    // get agency user token
    builder.addCase(asyncGetUserToken.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetUserToken.fulfilled, (state, { payload }) => {
      // console.log(payload?.user);
      state.loading = false;
      state.error = null;
      state.token = payload?.token;
      state.user = payload?.user;
    });
    builder.addCase(asyncGetUserToken.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // logout user agency
    builder.addCase(asyncUserLogout.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUserLogout.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.token = payload;
    });
    builder.addCase(asyncUserLogout.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // forget password
    builder.addCase(asyncForgetPassword.pending, (state, { payload }) => {
      state.logInLoading = true;
      state.error = null;
    });
    builder.addCase(asyncForgetPassword.fulfilled, (state, { payload }) => {
      state.logInLoading = false;
      state.error = null;
    });
    builder.addCase(asyncForgetPassword.rejected, (state, { payload }) => {
      state.logInLoading = false;
      state.error = payload;
    });
    // confirm Otp Code
    builder.addCase(asyncConfirmOtpCode.pending, (state, { payload }) => {
      state.logInLoading = true;
      state.error = null;
    });
    builder.addCase(asyncConfirmOtpCode.fulfilled, (state, { payload }) => {
      state.logInLoading = false;
      state.error = null;
      state.otpCode = payload;
    });
    builder.addCase(asyncConfirmOtpCode.rejected, (state, { payload }) => {
      state.logInLoading = false;
      state.error = payload;
    });
    // change Password
    builder.addCase(asyncChangePassword.pending, (state, { payload }) => {
      state.logInLoading = true;
      state.error = null;
    });
    builder.addCase(asyncChangePassword.fulfilled, (state, { payload }) => {
      state.logInLoading = false;
      state.error = null;
      state.otpCode = payload;
    });
    builder.addCase(asyncChangePassword.rejected, (state, { payload }) => {
      state.logInLoading = false;
      state.error = payload;
    });
  },
});

export const {
  otpEmailReducer,
  loginErrorMsg,
  otpMsgReducer,
  changePassReducer,
} = UserSlice.actions;
export default UserSlice.reducer;
