import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, NODEURL } from "../ApiConfig";

const initialState = {
    dashBoardData: [],

    loading: false,
    error: null,
}

export const asyncGetDashboardData = createAsyncThunk(
    "getAsyncBookingDetails/get",
    async (_, { rejectWithValue }) => {
      try {
        const response = await NODEURL.get(`/api/v1/b2b/all-record-count`);
        
        return await response.data?.data;
      } catch (error) {
        rejectWithValue(error);
      }
    }
  );
export const dashboardSlice = createSlice({
    name: "dashboardSlice",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(asyncGetDashboardData.pending, (state, {payload})=>{
            state.loading = true
            state.error = null
        })
        builder.addCase(asyncGetDashboardData.fulfilled, (state, {payload})=>{
            state.loading = false
            state.error = null
            state.dashBoardData = payload
        })
        builder.addCase(asyncGetDashboardData.rejected, (state, {payload})=>{
            state.loading = false
            state.error = payload
        })
    }
})

export default dashboardSlice.reducer;