import React, { useState } from "react";
// import CustomDropdown from "../components/CustomDrp";
import "../Styles/manualInvoices.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import AgentListPdf from "../components/Pdf/AgentListPdf";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetAgentOutStading, setOpenAgentOutStanding } from "../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import AgentOutStading from "../components/AgentOutStanding/AgentOutStading";
import BtnLoader from "../components/AppForm/BtnLoader";

function AgentList() {
  const dispatch = useDispatch();
  const agentArray = [
    "Select All",
    "Travel Agent",
    "Corporate",
    "Travel Consultant",
  ];
  const balanceArray = ["Exclude Zero", "Only Zero"];
  const { agentOustandingData, openAgentOutstanding } = useSelector(
    (state) => state.newAgency
  );
  const [agentOutstanding, setAgentOutStanding] = useState({
    agent: "",
    balance: "Exclude Zero",
  });
  const [message,setMessage]= useState(true)
  
  return (
    <>
      {openAgentOutstanding === "form" && (
        <div className=" finance-container Full_height">
          <div className="manual_invoices mx-auto mt-5">
            <div className="manual_invoices_header px-md-5">
              <div className="w-100 text-center">Agent Outstanding Reports</div>
            </div>

            <div className="d-flex gap-4 flex-column">
              <div className="px-5 py-4 d-flex gap-5">
                <div className="pt-5 flex-grow-1 " style={{ width: "25000px" }}>
                  <FormControl variant="standard" className="w-100" sx={{}}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Agent
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={agentOutstanding?.agent}
                      onChange={(e) => {
                        const value = e.target.value;
                        setAgentOutStanding({
                          ...agentOutstanding,
                          agent: value,
                        });
                      }}
                      label="Select Agent"
                    >
                      {agentArray?.map((list) => (
                        <MenuItem
                          value={list}
                          sx={{ m: 1, bgcolor: "#fff" }}
                          key={list}
                        >
                          {list?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="pt-5 flex-grow-1 " style={{ width: "25000px" }}>
                  <FormControl variant="standard" className="w-100" sx={{}}>
                    <InputLabel id="demo-simple-select-standard-label">
                      Balance
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={agentOutstanding?.balance}
                      onChange={(e) => {
                        const value = e.target.value;
                        setAgentOutStanding({
                          ...agentOutstanding,
                          balance: value,
                        });
                      }}
                      label="Select Agent"
                    >
                      {balanceArray?.map((list) => (
                        <MenuItem
                          value={list}
                          sx={{ m: 1, bgcolor: "#fff" }}
                          key={list}
                        >
                          {list?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="container py-4">
              {message ? (
                <div className="text-center py-4">
                  <button
                    className="button_style px-4"
                    onClick={(e) => {
                      setMessage(false)
                      setTimeout(() => {
                         dispatch(
                           asyncGetAgentOutStading({
                             queryParam: agentOutstanding,
                           })
                         );
                         dispatch(setOpenAgentOutStanding("comp"));
                         setMessage(true)
                      }, 3000);
                     
                    }}
                  >
                    Generate Report
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {openAgentOutstanding === "comp" && (
        <AgentOutStading data={agentOustandingData} ouStanding="form" />
      )}
    </>
  );
}

export default AgentList;
