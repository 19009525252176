import React from "react"

import { TextField } from "@mui/material"
import { useState } from "react"
import { Button, Modal } from "react-bootstrap"


const ReIssueTicketModal = ({ show, setShow, rowData,setActive_Tab }) => {
  const AirlineInfo = [
    {
      logo: "https://www.logolynx.com/images/logolynx/d0/d0df26bdd9cb0103f88d681d367f5f9a.jpeg",
      dateDep: "19-Oct-2022 19:35",
      airportDep: "KHI Karachi Pakistan",
      dateAriv: "19-Oct-2022 19:35",
      airportAriv: "ISB Islamabad Pakistan",
    },
    {
      logo: "https://www.logolynx.com/images/logolynx/d0/d0df26bdd9cb0103f88d681d367f5f9a.jpeg",
      dateDep: "19-Oct-2022 19:35",
      airportDep: "KHI Karachi Pakistan",
      dateAriv: "19-Oct-2022 19:35",
      airportAriv: "ISB Islamabad Pakistan",
    },
  ]
  const [multiRows, setMultiRows] = useState([1])
  const addRow = () => {
    const newvalue = multiRows[multiRows.length - 1]
    setMultiRows([...multiRows, newvalue ? newvalue + 1 : 1])
  }
  const removeRow = () => {
    if (multiRows.length === 1) return
    let rowsArray = multiRows
    rowsArray.pop()
    setMultiRows([...rowsArray])
  }

  const data = [
    {
      req_no: "FLT-103",
      date: "17-Sep-2022 11:47 AM",
      office_name: "ZAIRAA Main Office",
      booking_id: "276",
      Call_center_staff: "Demo Two",
      status: "New",
      req_type: "Issue",
      description: "Issue Ticket Description",
      action: "Take this Booking",
    },
  ]
  const columns = [
    {
      name: "req_no",
      label: "Booking ID",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Booking status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Document status",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "req_no",
      label: "Paid by Agency",
      options: {
        filter: false,
        sort: true,
      },
    },
  ]
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
  }
  return (
    <Modal
      show={show}
      onHide={e => setShow(setActive_Tab("Details"))}
      centered
      dialogClassName="modal-xl modal-full"
    >
      <Modal.Header>
        <Modal.Title>Re-Issue Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="px-md-5">
          <div className="row mt-4">
            <div className="col-12 col-md-6">
              <div className="custom_border p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Booked Items</h5>
                  <h5>ZairaaMain Office</h5>
                </div>
                <hr />
                <div className="row mt-2">
                  <div className="col-3">
                    <p className="h6 mb-3">Booking ID/Type</p>
                    <p>#2333</p>
                  </div>
                  <div className="col-3">
                    <p className="h6 mb-3">Booking Status</p>
                    <p>Confirmed</p>
                  </div>
                  <div className="col-3">
                    <p className="h6 mb-3">Document Status</p>
                    <p>Pending</p>
                  </div>
                  <div className="col-3">
                    <p className="h6 mb-3">Paid by Agency</p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-12 my-4">
                <div className="custom_border p-2">
                  {AirlineInfo.map(airline => (
                    <div className="row align-items-end">
                      <div className="col-4">
                        <img
                          src={airline.logo}
                          alt="logo"
                          width={140}
                          height={100}
                        />
                      </div>
                      <div className="col-4">
                        <p className="text-info h6">{airline.dateDep}</p>
                        <p>{airline.airportDep}</p>
                      </div>
                      <div className="col-4">
                        <p className="text-info h6">{airline.dateAriv}</p>
                        <p>{airline.airportAriv}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-12 my-5">
                <TextField
                  variant="standard"
                  label="Agent Description"
                  className="w-100"
                />
              </div>
              <div className="col-12 my-4 mt-5">
                <TextField
                  variant="outlined"
                  label="12.21.2021-30"
                  className="w-100"
                />
                <TextField
                  variant="outlined"
                  label=""
                  className="w-100"
                  multiline
                  rows={2}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="custom_border p-2">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Passenger</h5>
                </div>
                <hr />
                <div className="row mt-2">
                  <div className="col-2">
                    <p className="h6 mb-3">Check</p>
                    <div class="checkbox-rect">
                      <input type="checkbox" id="passeng" name="passeng" />
                      <label for="passeng" className="h6"></label>
                    </div>
                  </div>
                  <div className="col-2">
                    <p className="h6 mb-3">Type</p>
                    <p>ADULT</p>
                  </div>
                  <div className="col-2">
                    <p className="h6 mb-3">Name</p>
                    <p>MR Asif Ali</p>
                  </div>
                  <div className="col-2">
                    <p className="h6 mb-3">BirthDate</p>
                    <p></p>
                  </div>
                  <div className="col-2">
                    <p className="h6 mb-3">Nationality</p>
                    <p></p>
                  </div>
                  <div className="col-2">
                    <p className="h6 mb-3">#Passport</p>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="custom_border p-2 my-4">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Re-issue Flight Details</h5>
                  <div className="d-flex mb-3">
                    <Button
                      variant="contained"
                      color="error"
                      className="me-2"
                      onClick={e => removeRow()}
                    >
                      Remove Flight
                    </Button>
                    <Button variant="contained" onClick={addRow}>
                      Add Flight
                    </Button>
                  </div>
                </div>
                {/* {multiRows.map(row => (
                  <ReIssueFlight index={row} />
                ))} */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-end ">
            <div className="me-2">
              <Button
                variant="contained"
                onClick={e => setShow(false)}
                color="error"
              >
                Close
              </Button>
            </div>
            <div className="">
              <Button variant="contained" onClick={e => setShow(false)}>
                Save Request
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="col-12">
          <div className="col-12 d-flex">
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Agent Description"
                variant="standard"
                className="w-100"
              />
            </div>
            <div className="col-sm-6 px-3 mt-5">
              <TextField
                label="Agent Notes"
                variant="standard"
                className="w-100"
              />
            </div>
          </div>
          <div className="row px-3">
            <div className="col-sm-6 px-3 mt-5">
              <MUIDataTable
                className="muidatatable"
                title={"Booked Items"}
                data={data}
                columns={columns}
                options={options}
              />
            </div>
            <div className="col-sm-6 px-3 mt-5">
              <MUIDataTable
                className="muidatatable"
                title={"Passengers"}
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </div>
          <div className="col-12 mt-5 px-3">
            <div className="d-flex justify-content-between align-items-center border px-5 py-2">
              <div className="text-center">
                <img src={Flydubai} className="mb-2" />
                <div>FZ 329</div>
              </div>
              <div className="d-flex col-sm-8 justify-content-between align-items-center">
                <div className="flight-from-div">
                  <div>25 Nov 2022 07:50 PM</div>
                  <div>DXB-Dubai, United Arab Emirates - International</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <img
                        src={Economy}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span> 0</span>
                    </div>
                    <div>
                      <img
                        src={Plan}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span>Equipment 7200</span>
                    </div>
                    <div>
                      <img
                        src={WaitingTime}
                        style={{ height: "25px", width: "25px" }}
                      />
                      <span> -6h 0m</span>
                    </div>
                  </div>
                </div>
                <div>
                  <i
                    class="fa fa-thin fa-arrow-right"
                    style={{
                      color: "#4d496b",
                      fontFamily: "FontAwesome",
                    }}
                  ></i>
                </div>
                <div className="flight-from-div">
                  <div>25 Nov 2022 07:50 PM</div>
                  <div>DXB-Dubai, United Arab Emirates - International</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-5 px-3">
            <div className="align-items-center border px-3 py-2">
              <div className="row col-12 otawix-sub-heading">
                <div className="col-sm-9 pt-3">
                  <h6>Re-Issue Flight Detail's</h6>
                </div>
                <div className="col-sm-3">
                  <button
                    className="btn btn-small btn-primary col-sm-6 border rounded-pill px-1"
                    onClick={addRow}
                  > */}
        {/*<i className="fa  fa-thin fa-plus"></i>Add Flight*/}
        {/* </button>
                  <button
                    className="btn btn-small btn-danger col-sm-6 border rounded-pill px-1"
                    onClick={(e) => removeRow()}
                  >
                    Remove Flight
                    
                  </button>
                </div>
              </div>
              {multiRows.map((row) => (
                <ReIssueFlight index={row} />
              ))}
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-end mt-5 mb-3">
            <div className="me-2">
              <Button
                variant="danger modal-button"
                onClick={(e) => setShow(false)}
                style={{ background: "#dc3545", color: "#fff" }}
              >
                Close
              </Button>
            </div>
            <div className="">
              <Button
                variant="primary modal-button"
                onClick={(e) => setShow(false)}
                style={{ background: "#0d6efd", color: "#fff" }}
              >
                Save
              </Button>
            </div>
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  )
}






export default ReIssueTicketModal











// import React, { useState } from "react";
// import { useLocation } from "react-router-dom";

// import Modal from "react-bootstrap/Modal";
// import { Button, TextField } from "@mui/material";
// import CustomDropDown from "../CustomDrp";

// const Requests = () => {
//   const [modalShow, setModalShow] = React.useState(false);
//   const location = useLocation();
//   const [radio, setRadio] = useState(
//     location.pathname.split("/")[3] === "active" ? "in-progress" : "resolved"
//   );

//   return (
//     <div className="d-flex flex-column justify-content-center mt-4">
//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//       <h6>Call Center Requests</h6>
//       <div className="col-sm-3 d-flex my-4 ps-3">
//         <div class="form-control me-3 me-md-5 d-flex align-items-center">
//           <input
//             type="radio"
//             id="resolved"
//             value="resolved"
//             checked={radio === "resolved"}
//             onChange={(e) => setRadio(e.target.value)}
//           />
//           <label for="resolved">Resolved</label>
//         </div>
//         <div class="form-control d-flex align-items-center">
//           <input
//             type="radio"
//             id="in-progress"
//             value="in-progress"
//             checked={radio === "in-progress"}
//             onChange={(e) => setRadio(e.target.value)}
//           />
//           <label htmlFor="in-progress">In Progress</label>
//         </div>
//       </div>
//       {/* <div className="row px-2">
//         <table className="table table-bordered text-nowrap table-responsive">
//           <thead>
//             <tr className="table-head-requests">
//               <th>Number</th>
//               <th>Date/Time</th>
//               <th>Status</th>
//               <th>Type</th>
//               <th>Description</th>
//               <th>Booking ID</th>
//               <th>Agent</th>
//               <th>Call Center Staff</th>
//               <th>Options</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>FLT-103</td>
//               <td>17-Sep-2022 11:47 AM</td>
//               <td>New</td>
//               <td>Issue</td>
//               <td>Issue Ticket Description</td>
//               <td>ZAIRAA Main Office</td>
//               <td>276</td>
//               <td>Not yet taken by call center</td>
//               <td>
//                 <i
//                   class="fa fa-edit"
//                   style={{ fontSize: "24px", cursor: "pointer" }}
//                   onClick={() => setModalShow(true)}
//                 ></i>
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div> */}
//       <div className="booked_items">
//         <div className="d-flex align-items-center">
//           <p
//             className="h5"
//             style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
//           >
//             Call Center Requests
//           </p>
//         </div>
//         <div className="booked_items_table">
//           <div className="d-flex justify-content-between align-items-center border-bottom">
//             <div className="booked_tabel_items h6">Number</div>
//             <div className="booked_tabel_items h6">Date/Time</div>
//             <div className="booked_tabel_items h6">Status</div>
//             <div className="booked_tabel_items h6">Type</div>
//             <div className="booked_tabel_items h6">Description</div>
//             <div className="booked_tabel_items h6">Booking ID</div>
//             <div className="booked_tabel_items h6">Agent</div>
//             <div className="booked_tabel_items h6">Call Center Staff</div>
//             <div className="booked_tabel_items h6">Options</div>
//           </div>
//           <div className="d-flex justify-content-between align-items-center border-bottom">
//             <div className="booked_tabel_items ">FLT-103</div>
//             <div className="booked_tabel_items booktablebody">
//               <div>17-Sep-2022</div>
//               <div className="pt-1">11:47 AM</div>
//             </div>
//             <div className="booked_tabel_items booktablebody">New</div>
//             <div className="booked_tabel_items booktablebody">Issue</div>
//             <div className="booked_tabel_items booktablebody">
//               Issue Ticket Description
//             </div>
//             <div className="booked_tabel_items booktablebody">
//               ZAIRAA b2b
//             </div>
//             <div className="booked_tabel_items booktablebody">276</div>
//             <div className="booked_tabel_items booktablebody">
//               Not yet taken<br/> by call center
//             </div>
//             <div className="booked_tabel_items booktablebody">
//               {/* <i
//                 class="fa fa-edit"
//                 style={{ fontSize: "24px", cursor: "pointer" }}
//                 onClick={() => setModalShow(true)}
//               ></i> */}
//               <button
//                 type="button"
//                 className="btn-success rounded edit-delete-icon-button me-1"
//                 onClick={() => {
//                   setModalShow(true)
//                 }}
//               >
//                 <i className="fa fa-thin fa-pencil"></i>
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal {...props} size="xl">
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Requested for 2
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className="row p-2  align-items-end">
//           <div className="col-6 col-md-3">
//             <TextField
//               variant="standard"
//               label="Company Name"
//               className="w-100"
//             />
//           </div>
//           <div className="col-6 col-md-3">
//             <TextField
//               variant="standard"
//               label="Approve Limit"
//               className="w-100"
//             />
//           </div>
//           <div className="col-6 col-md-3">
//             <TextField
//               variant="standard"
//               label="Used Limit"
//               className="w-100"
//             />
//           </div>
//           <div className="col-6 col-md-3">
//             <TextField
//               variant="standard"
//               label="Available Limit"
//               className="w-100"
//             />
//           </div>
//           <div className="col-6 col-md-3 mt-2 mt-md-4">
//             <TextField
//               variant="standard"
//               label="Request number"
//               className="w-100 "
//             />
//           </div>
//           <div className="col-6 col-md-3 mt-2 mt-md-4">
//             <TextField
//               variant="standard"
//               label="Request time"
//               className="w-100 "
//             />
//           </div>
//           <div className="col-6 col-md-3 mt-2 mt-md-4">
//             <CustomDropDown
//               arry={["Issue", "IATA BSP", "Serene AIR", "Airblue Limited"]}
//               placehlder="Type"
//             />
//           </div>
//           <div className="col-6 col-md-3 mt-2 mt-md-4">
//             <CustomDropDown
//               arry={["HITIT-PK", "IATA BSP", "Serene AIR", "Airblue Limited"]}
//               placehlder="Select Supplier"
//             />
//           </div>
//         </div>
//         <hr className="mx-2 my-5" />

//         <div className="row align-items-baseline">
//           <div className="col-12 py-2 ">
//             <div className="custom_border px-4 py-3">
//               <p className="h5">Booked Items - ZairraMain Office</p>
//               <div className="border rounded-2 py-3">
//                 <div className="d-flex">
//                   <div className="w-25 px-2 h6">Booking ID/Type</div>
//                   <div className="w-25 px-2 h6">Booking Status</div>
//                   <div className="w-25 px-2 h6">Document Status</div>
//                   <div className="w-25 px-2 h6">Paid by Agency</div>
//                 </div>
//                 <div className="d-flex mt-3">
//                   <div className="w-25 px-2">CVSVHS ()</div>
//                   <div className="w-25 px-2">CONFIRMED</div>
//                   <div className="w-25 px-2">PENDING</div>
//                   <div className="w-25 px-2"></div>
//                 </div>
//               </div>

//               <div className=" mt-3 ">
//                 <p className="h5">Sector</p>
//                 <div className="row border rounded-2 py-2 align-items-center">
//                   <div className="col-4">
//                     <img
//                       src="https://www.logolynx.com/images/logolynx/d0/d0df26bdd9cb0103f88d681d367f5f9a.jpeg"
//                       alt="logo"
//                       width={140}
//                       height={100}
//                     />
//                   </div>
//                   <div className="col-4">
//                     <div className="h6 text-info">23-Oct-2022 18:30 PM</div>
//                     <div className="mt-3 h6">LHE - Lahore, Pakistan</div>
//                   </div>
//                   <div className="col-4">
//                     <div className="h6 text-info">23-Oct-2022 18:30 PM</div>
//                     <div className="mt-3 h6">
//                       KHI - Karachi, Pakistan - Quaid-E-Azam International
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="col-12 mt-3">
//             <div className="custom_border px-4 py-3">
//               <p className="h5">Passengers</p>
//               <div className="border rounded-2 px-2  py-3">
//                 <div className="d-flex ">
//                   <div className="h6 req_table_col">CHK</div>
//                   <div className="h6 req_table_col">Type</div>
//                   <div className="req_table_col_name h6">Name</div>
//                   <div className="req_table_col h6">Date Of Birth</div>
//                   <div className="req_table_col h6">Nationality</div>
//                   <div className="req_table_col h6">Passport #</div>
//                   <div className="req_table_col h6">Ticket #</div>
//                 </div>
//                 <div className="d-flex mt-3">
//                   <div className="req_table_col">
//                     <div class="checkbox-rect">
//                       <input type="checkbox" id="passenger" name="passenger" />
//                       <label for="passenger" className="h6"></label>
//                     </div>
//                   </div>
//                   <div className="req_table_col">Adult</div>
//                   <div
//                     className="req_table_col-name"
//                     style={{ lineHeight: "1.2rem" }}
//                   >
//                     MR MUHAMMAD ILYAS ANWAR BHATTI
//                   </div>
//                   <div className="req_table_col ">1979-01-01</div>
//                   <div className="req_table_col "></div>
//                   <div className="req_table_col "></div>
//                   <div className="req_table_col "></div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-6 mt-5 ">
//             <TextField
//               variant="standard"
//               label="Agent Description"
//               className="w-100"
//             />

//             <TextField
//               variant="outlined"
//               disabled
//               label="Agent Notes"
//               className="w-100 mt-4"
//             />
//             <TextField
//               variant="outlined"
//               label=""
//               className="w-100"
//               multiline
//               rows={2}
//             />
//           </div>

//           <div className="col-6 mt-5">
//             <TextField
//               variant="standard"
//               label="Supplier Description"
//               className="w-100"
//             />

//             <TextField
//               variant="outlined"
//               disabled
//               label="Supplier Notes"
//               className="w-100 mt-4"
//             />
//             <TextField
//               variant="outlined"
//               label=""
//               className="w-100 "
//               multiline
//               rows={2}
//             />
//           </div>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="contained" onClick={props.onHide} color="error">
//           Cancel
//         </Button>
//         <Button variant="contained" className="mx-3">
//           Issue
//         </Button>
//         <Button variant="contained" onClick={props.onHide} color="error">
//           Cancel Request
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// export default Requests;
