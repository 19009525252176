import moment from "moment";
import React from "react";
import PiaLogo from "../../../../assets/images/airlineLogo/PK.png";
import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from "react-icons/md";

const HititItneries = ({ segment, timeDuration, array, index }) => {
  return (
    <div className="row align-items-center">
      <div className="col-3 my-4">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img src={`/NEW_LOGO/${segment?.at(0)?.flightSegment?.airline?.code}.png`} alt="logo" width={90} height={90} />
          <h6>
            <div className="pt-3 text-center">
              {segment?.map((it, idx, arr) => (
                <span className="pe-1" key={idx}>{`${it?.flightSegment?.operatingAirline ? it?.flightSegment?.operatingAirline?.code : it?.flightSegment?.airline?.code} ${
                  it?.flightSegment?.flightNumber
                } ${idx < arr.length - 1 ? " -" : ""}`}</span>
              ))}
            </div>
          </h6>
        </div>
      </div>

      <div className="col-9">
        <div className="d-flex justify-content-evenly align-items-center">
          <div>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <MdOutlineFlightTakeoff size={30} className="color_primary" />
              </div>
              <div>
                <h6>
                  {moment(
                    new Date(
                      segment[0]?.flightSegment?.departureDateTime?.split(
                        "T"
                      )[0]
                    )
                  ).format("DD-MMM-YY")}
                </h6>
                <h6 className="my-3">
                  {segment[0]?.flightSegment?.departureDateTime
                    ?.split("T")[1]
                    ?.slice(0, 5)}
                </h6>
                <h6>
                  {segment[0]?.flightSegment?.departureAirport?.locationCode} -{" "}
                  {segment[0]?.flightSegment?.departureAirport?.locationName
                    ?.split(" ")
                    ?.at(0)}
                </h6>
              </div>
            </div>
          </div>

          <div>
            <h6 className="text-center">
              {timeDuration(
                segment[0]?.flightSegment?.departureDateTime,
                segment[segment?.length - 1]?.flightSegment?.arrivalDateTime
              )}
            </h6>
            <div className="border_rounded mb-2" />
            <h6 className="mx-3">
              {segment?.length === 1
                ? "Non-Stop"
                : segment?.length === 2
                ? "One-Stop"
                : segment?.length === 3
                ? "Two-Stop"
                : segment?.length === 4
                ? "Three-Stop"
                : ""
                }
            </h6>
            <h6 className="text-center">
              {segment
                ?.slice(1)
                ?.map(
                  (seg) => seg?.flightSegment?.departureAirport?.locationCode
                )
                ?.join(", ")}
            </h6>
          </div>
          <div>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <MdOutlineFlightLand size={30} className="color_primary" />
              </div>
              <div>
                <h6>
                  {moment(
                    new Date(
                      segment[
                        segment?.length - 1
                      ]?.flightSegment?.arrivalDateTime?.split("T")[0]
                    )
                  ).format("DD-MMM-YY")}
                </h6>
                <h6 className="my-3">
                  {segment[segment?.length - 1]?.flightSegment?.arrivalDateTime
                    ?.split("T")[1]
                    ?.slice(0, 5)}
                </h6>
                <h6>
                  {
                    segment[segment?.length - 1]?.flightSegment?.arrivalAirport
                      ?.locationCode
                  }
                  -
                  {segment[
                    segment?.length - 1
                  ]?.flightSegment?.arrivalAirport?.locationName
                    ?.split(" ")
                    ?.at(0)}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HititItneries;
