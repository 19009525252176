import React from "react";

const Contact = ({user,agencyData}) => {
  return (
    <div className="">
      <div className="mt-5 booked_items">
        {/* <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="h5">Agency Data</div>
            </Accordion.Header>
            <Accordion.Body> */}
        <div className="d-flex align-items-center">
          <p
            className="h5"
            style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
          >
            Agency Data
          </p>
        </div>
        <div className="col-sm-12 accordian-custom-body px-3 mt-2 booktablebody d-flex gap-5">
          <div className="">
            <p>Name:</p>
            <p>Email:</p>
            <p>Phone:</p>
            <p>Address:</p>
          </div>
          <div className="">
            <p>{agencyData?.agent_name}</p>
            <p>{agencyData?.email}</p>
            <p>{agencyData?.mobile_no}</p>
            <p>{agencyData?.address?.split("]")[0]}</p>
          </div>
        </div>
        {/* </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
      </div>
      <div className="booked_items">
        {/* <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="h5">User Data</div>
            </Accordion.Header>
            <Accordion.Body> */}
            <div className="d-flex align-items-center">
          <p
            className="h5"
            style={{ lineHeight: 1, marginLeft: 6, paddingTop: 5 }}
          >
            User Data(Book/request by)
          </p>
        </div>
        <div className="col-sm-12 accordian-custom-body px-3 mt-2 booktablebody d-flex gap-5">
          <div className="">
            <p>Name:</p>
            <p>Email:</p>
          </div>
          <div className="">
            <p>{user?.f_name + " "+ user?.l_name}</p>
            <p>{user?.email}</p>
          </div>
        </div>
        {/* </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
      </div>
    </div>
  );
};

export default Contact;
