
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { fetchBookingUpdate } from '../../../redux/features/booking/updateSlice';
import BtnLoader from '../../AppForm/BtnLoader';

const UpdateModel = () => {
  // const dispatch = useDispatch();
  const { data, loading} = useSelector((state) => state.update);
  // const gds = booking?.booking?.FlightBookingModels?.at(0)?.gds;
  // const id = booking?.booking?.id;

  // useEffect(() => {
  //   dispatch(fetchBookingUpdate({ gds, id}));
  // }, [dispatch, gds, id]);

  return (
    <div className='d-flex h-100 align-items-center justify-content-center'>
      <div>
        <h1>
        {loading ? (<BtnLoader  />)
                 : (data?.message )}
        </h1>
      </div>
    </div>
  );
};

export default UpdateModel;
