import { Slider, TextField } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CustomDropdown from "../CustomDrp";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetFlightGroupData } from "../../redux/features/promotion/FlightGroupsSlice";
import airsial from "../../assets/images/Airsial.jpeg";
import pia from "../../assets/images/PIA.jpeg";
import serene from "../../assets/images/Serene.jpeg";
import { PaxModel } from "./PaxModel";
import { asyncGetNewAgencyReg } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { CurrencyCode, CurrencyExchange } from "../../Utils/currencyExchange";
import { asyncGetAgentGroup } from "../../redux/features/setupRestPages/agentsSlice";
import { asyncGetCurrencyData } from "../../redux/features/setupRestPages/Bank_curr_RoleSlice";

const Groups = () => {
  // new Date()?.toISOString()?.split("T")[0]
 
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const { flightGroup } = useSelector((state) => state.flightGroup);
  const [agent, setAgent] = useState(
    user?.agent_id === 1 ? {} : user?.agents_comp
  );
  const { agentGroup } = useSelector((state) => state.agentsCategories);
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);

  const [toggle, setToggle] = useState(false);

  //agent group
  const agent_grp = agentGroup?.find(
    (item) => item.agent_grp_id === agent?.agent_grp_id
  );
  // currency of the selected agent group
  const currency = currencyData?.find(
    (it) => it.currency_id === agent_grp?.currency_id
  );
  
  //set local storage
  localStorage.setItem(
    "currency",
    JSON.stringify(currency !== undefined ? currency : "")
  );
  //uselayout effect
  useLayoutEffect(() => {
    dispatch(asyncGetAgentGroup());
    dispatch(asyncGetCurrencyData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(asyncGetFlightGroupData());
    dispatch(asyncGetNewAgencyReg());
  }, [dispatch, toggle]);

  const [modelShow, setModalShow] = useState(false);

  const PaxType = [
    {
      type: "ADULT",
    },
    {
      type: "CHILD",
    },
    {
      type: "INFANT",
    },
  ];

  const PaxTitle = [
    {
      title: "MR",
    },
    {
      title: "MRS",
    },
    {
      title: "MS",
    },
  ];

  const total = flightGroup.map((item, index) => {
    const adultFare = item?.flight_group_pax_fares?.find(
      (fare) => fare?.pax_type === "ADULT"
    );
    const adultPrice = adultFare ? adultFare.selected_currency_received : 0;
    return adultPrice;
  });
  const [priceRange, setPriceRange] = useState({
    min: 0,
    max: 10000, // Set your desired maximum price
  });
  useEffect(() => {
    let min = Math.min(
      ...flightGroup?.map(
        (o) =>
          ~~o?.flight_group_pax_fares?.find(
            (fare) => fare?.pax_type === "ADULT"
          )?.default_currency_received
      )
    );
    let mix = Math.max(
      ...flightGroup?.map(
        (o) =>
          ~~o?.flight_group_pax_fares?.find(
            (fare) => fare?.pax_type === "ADULT"
          )?.default_currency_received
      )
    );

    setPriceRange({ min: min, max: mix });
  }, [flightGroup]);

  const [filterArray, setFilterArray] = useState([]);

  const handleCheckboxChange = (filterName) => {
    if (filterArray.includes(filterName)) {
      ///remove element from array during  uncheck
      const flt = filterArray?.filter((item, i) => item !== filterName);
      setFilterArray(flt);
    } else {
      setFilterArray([...filterArray, filterName]);
    }
  };
  const filterData = flightGroup
    ?.filter((status) => status?.status)
    ?.filter((item) => {
      const adultFare = item?.flight_group_pax_fares?.find(
        (fare) => fare?.pax_type === "ADULT"
      );
      const recPrice = adultFare?.default_currency_received;

      return (
        recPrice && recPrice >= priceRange.min && recPrice <= priceRange.max
      );
    })
    ?.filter(
      (flt, index) =>
        filterArray?.length === 0 ||
        filterArray?.includes(flt?.FlightGroupTypeModel?.type_name) ||
        filterArray?.includes(flt?.flight_group_flights[0]?.flight)
    );

  return (
    <div className="mx-5 mt-5 rounded-2 shadow bg-white">
      <h4 className="otawix-heading box_title">Flight Groups</h4>

      {/* <GroupModal show={ModalShow} setShow={setModalShow} /> */}
      <div className="">
        <div className="mx-5 d-flex">
          <div className="col-sm-3 border p-3 fixedHeight mt-4 shadow-lg flash-news border_hover">
            <div className="border rounded-3 p-3 shadow-lg ">
              <h4>Groups Type:</h4>
              <div className="ps-2 d-flex flex-column gap-4">
                {[
                  ...new Map(
                    flightGroup?.map((item) => [
                      item?.FlightGroupTypeModel?.type_name,
                      item,
                    ])
                  ).values(),
                ]
                  ?.filter((sts) => sts?.status)
                  ?.map((item, i) => (
                    <div className="checkbox-rect">
                      <input
                        type="checkbox"
                        id={item?.FlightGroupTypeModel?.type_name}
                        onChange={() => {
                          handleCheckboxChange(
                            item?.FlightGroupTypeModel?.type_name
                          );
                        }}
                        // checked={flt_Filter[item?.title]}
                      />
                      <label
                        htmlFor={item?.FlightGroupTypeModel?.type_name}
                        className="color_primary pt-1"
                      >
                        {item?.FlightGroupTypeModel?.type_name}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
            {/* Price */}
            <div className="mt-5 border rounded-3 p-3 shadow-lg">
              <h4>Price:</h4>
              <div>
                <Slider
                  sx={{ color: "#457a7c" }}
                  defaultValue={priceRange?.min}
                  aria-label="Default"
                  value={[priceRange.min, priceRange.max]}
                  onChange={(e, newValue) =>
                    setPriceRange({ min: newValue[0], max: newValue[1] })
                  }
                  valueLabelDisplay="auto"
                  min={priceRange?.min} // Set the minimum value
                  max={priceRange?.max} // Set the maximum value
                />
              </div>
            </div>
            <div className="mt-5 border rounded-3 p-3 shadow-lg">
              <h4>Airlines:</h4>

              <div className="ps-2 d-flex flex-column gap-4">
                {[
                  ...new Map(
                    flightGroup?.map((item) => [
                      item?.group_name?.substring(0, 2),
                      item,
                    ])
                  ).values(),
                ]?.map((item, i) => (
                  <div className="checkbox-rect">
                    <input
                      type="checkbox"
                      id={item?.group_name?.substring(0, 2)}
                      onChange={() => {
                        handleCheckboxChange(item?.group_name?.substring(0, 2));
                      }}
                      // checked={flt_Filter[item?.title]}
                    />
                    <label
                      htmlFor={item?.group_name?.substring(0, 2)}
                      className="color_primary pt-1"
                    >
                      {item?.group_name?.substring(0, 2)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-sm-9  ">
            {filterData?.length ? (
              filterData
                ?.sort((a, b) => {
                  const dateA = new Date(
                    a?.flight_group_flights[0]?.flt_dep_date
                      ?.split("-")
                      .reverse()
                      .join("-")
                  );
                  const dateB = new Date(
                    b?.flight_group_flights[0]?.flt_dep_date
                      ?.split("-")
                      .reverse()
                      .join("-")
                  );

                  return dateA - dateB;
                })
                .filter(
                  (leftseat, i) => leftseat?.ttl_seat !== leftseat?.booked_seat
                )
                ?.map((item, index) => (
                  <div className="box ms-3 p-3 mt-4  flash-news shadow-lg border_hover">
                    <div className="d-flex align-items-center justify-content-between border rounded-3 p-3 shadow-lg">
                      <div className="col-sm-2  ">
                        <img
                          src={`/NEW_LOGO/${
                            item?.flight_group_flights?.at(0)?.flight
                          }.png`}
                          alt=""
                        />
                        <p className=" fs-6 text-danger">
                          Seats Left ({item?.ttl_seat - item?.booked_seat})
                        </p>
                        <h6>PNR : {item?.pnr}</h6>
                        <h6>
                          Class:{" "}
                          {item?.flight_group_flights?.at(0)?.cabin_class}
                        </h6>
                        <h6>Type : {item?.FlightGroupTypeModel?.type_name}</h6>
                      </div>
                      <div className="col-sm-10 d-flex align-items-center justify-content-between ">
                        <div className=" col-sm-9">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>FLT No</th>
                                <th>Orig</th>
                                <th>Des</th>
                                <th>Dep Date</th>
                                <th>Dep Time</th>
                                <th>Arr Date</th>
                                <th>Arr Time</th>
                                <th>Baggage</th>
                              </tr>
                            </thead>

                            <tbody>
                              {item?.flight_group_flights?.map((flight, i) => (
                                <tr>
                                  <td>
                                    {" "}
                                    {flight?.flight} {flight?.flt_no}
                                  </td>
                                  <td> {flight?.origin?.split("-")[0]}</td>
                                  <td> {flight?.destination?.split("-")[0]}</td>
                                  <td>{flight?.flt_dep_date}</td>
                                  <td>{flight?.dep_time}</td>
                                  <td>{flight?.flt_arr_date}</td>
                                  <td> {flight?.arr_time}</td>
                                  <td>{flight?.baggage}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className=" col-sm-3 align-items-center justify-content-between ms-4">
                          {/* take per passenger pkr */}
                          <p className="fs-6 text-center fw-bold">
                            {CurrencyCode()}{" "}
                            {CurrencyExchange(
                              item?.flight_group_pax_fares?.find(
                                (fare) => fare?.pax_type === "ADULT"
                              )?.default_currency_received
                            ).toFixed(2)}
                          </p>
                          <div className="d-flex justify-content-center">
                            {" "}
                            <button
                              className="selectbtn_style "
                              // type="button"
                              onClick={(e) => {
                                setModalShow({
                                  ...modelShow,
                                  ["open_" + item.flt_group_id]:
                                    !modelShow["open_" + item.flt_group_id],
                                });
                              }}
                              disabled={item?.ttl_seat === item?.booked_seat}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Pax Model */}
                    {modelShow["open_" + item.flt_group_id] && (
                      <PaxModel
                        PaxType={PaxType}
                        PaxTitle={PaxTitle}
                        totalSeat={item?.ttl_seat - item?.booked_seat}
                        PAXPrice={item?.flight_group_pax_fares}
                        items={item}
                        // passenger={passenger}
                        toggle={toggle}
                        modelShow={modelShow}
                        setModalShow={setModalShow}
                        setToggle={setToggle}
                        agent={agent}
                        user={user}
                        setAgent={setAgent}
                        currency={currency}
                        ind={index}
                      />
                    )}
                  </div>
                ))
            ) : (
              <div className="d-flex justify-content-center align-items-center fs-4 text-center">
                <p>Record not found</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const GroupModal = ({ show, setShow }) => {
  return (
    <Modal show={show} onHide={(e) => setShow(false)} centered size="xl">
      <Modal.Header>
        <Modal.Title>Flight Group Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Total remaining seat of this group is </h5>
        <div className="custom_border p-2 mt-5">
          <div className="row py-3 px-2 align-items-center">
            <div className="col-4 justify-content-center ">
              <img src="" alt="airlogo" srcset="" />
            </div>
            <div className="col-4">
              <h5 className="text-info">22 jun 2022 10:30 pm</h5>
              <h5>LHE , Lahore Pakistan</h5>
            </div>
            <div className="col-4">
              <h5 className="text-info">23 Jun 2022 2:00 pm</h5>
              <h5>JED, Jeddah, Saudi arebia</h5>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <label class="form-control ">
            <input type="radio" name="p_by_name" />
            <span className="h6">Passenger by Name</span>
          </label>

          <div className="mt-3 custom_border py-3 px-2">
            <div className="d-flex justify-content-between align-items-end">
              <h5>Passengers:</h5>
              <div className="d-flex">
                <Button variant="danger" className="me-2">
                  Remove PAX
                </Button>
                <Button variant="primary">Add PAX</Button>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-1">
                <CustomDropdown arry={[]} placehlder="Type" />
              </div>
              <div className="col-1">
                <CustomDropdown arry={[]} placehlder="Title" />
              </div>
              <div className="col-2">
                <TextField
                  variant="standard"
                  label="F-Name"
                  className="w-100"
                />
              </div>
              <div className="col-2">
                <TextField
                  variant="standard"
                  label="L-Name"
                  className="w-100"
                />
              </div>
              <div className="col-2">
                <TextField variant="standard" label="DOB" className="w-100" />
              </div>
              <div className="col-1">
                <TextField
                  variant="standard"
                  label="Passport"
                  className="w-100"
                />
              </div>
              <div className="col-2">
                <TextField
                  variant="standard"
                  label="Nationality"
                  className="w-100"
                />
              </div>
              <div className="col-1">
                <TextField variant="standard" label="Total" className="w-100" />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-end mt-5 mb-2">
          <div className="w-25">
            <CustomDropdown arry={[]} placehlder="Select a Company" />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger modal-button" onClick={(e) => setShow(false)}>
          Close
        </Button>
        <Button variant="primary modal-button">Generate Booking</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Groups;
