import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { baseURL, HTTP, HTTPForm, HTTPJson } from "../ApiConfig";

const initialState = {
  visaSupplierData: [],
  transferSupData: [],

  editTransferSupplier: null,
  loading: false,
  error: null,
};

// getting transfer supplier data
export const asyncGetTransferSupplier = createAsyncThunk(
  "asyncGetTransferSupplier/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/transfer_supplier`);
      console.log(response.data.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting visaTransfer data
export const asyncPostVisaTransfer = createAsyncThunk(
  "asyncPostVisaTransfer/post",
  async (fd, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(
        "/api/v1/b2b/transfer_supplier/transfer_supplier_create",
        fd
      );
      console.log(await response.data);
      // toast.success("visa supplier data added");
      dispatch(asyncGetTransferSupplier());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit transfer supplier data
export const asyncEditTransferSupplier = createAsyncThunk(
  "asyncEditTransferSupplier/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/transfer_supplier/transfer_supplier_edit/${id}`
      );
      console.log(await response.data);
      // toast.success("umrah Route added");
      return await response.data.data.edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// post transfer supplier data
export const asyncUpdateTransferSupplier = createAsyncThunk(
  "asyncUpdateTransferSupplier/get",
  async (fd, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(
        `/api/v1/b2b/transfer_supplier/transfer_update`,
        fd
      );
      dispatch(asyncGetTransferSupplier());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// delete transfer supplier data
export const asyncDeleteTransferSupplier = createAsyncThunk(
  "asyncDeleteTransferSupplier/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/transfer_supplier/transfer_supplier_destroy/${id}`
      );
      dispatch(asyncGetTransferSupplier());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const TransferSupplierSlice = createSlice({
  name: "TransferSupplierSlice",
  initialState,
  reducers: {
    editTransferSupplierNull: (state) => {
      state.editTransferSupplier = null;
    },
  },
  
  extraReducers: (builder) => {
    // getting transfer
    builder.addCase(asyncGetTransferSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetTransferSupplier.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.transferSupData = payload;
      }
    );
    builder.addCase(asyncGetTransferSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting vehicles data
    builder.addCase(asyncPostVisaTransfer.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostVisaTransfer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostVisaTransfer.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // edit visasupplier data
    builder.addCase(asyncEditTransferSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncEditTransferSupplier.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.editTransferSupplier = payload;
      }
    );
    builder.addCase(
      asyncEditTransferSupplier.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // update visasupplier data
    builder.addCase(asyncUpdateTransferSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateTransferSupplier.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateTransferSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // delete visasupplier data
    builder.addCase(asyncDeleteTransferSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteTransferSupplier.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(
      asyncDeleteTransferSupplier.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { editTransferSupplierNull } = TransferSupplierSlice.actions;
export default TransferSupplierSlice.reducer;
