import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNewDepDate } from "../../../../redux/features/home/FlightSearchSlice";
import moment from "moment";

function ChangeAvailabiltyDate() {
  const dispatch = useDispatch();
  const { newDepDate } = useSelector((state) => state.FlightSearch);

  const localsearchValues = JSON.parse(
    localStorage.getItem("flightSearchData")
  );

  return (
    <div>
      <button
        className="button_style me-3"
        onClick={() => {
          if (newDepDate) {
            let parts = newDepDate?.split("-");
            let day = parseInt(parts[0], 10);
            let month = parseInt(parts[1], 10) - 1;
            let year = parseInt(parts[2], 10);
            let newDate = new Date(year, month, day);
            newDate.setDate(newDate.getDate() - 1);
            dispatch(setNewDepDate(moment(newDate).format("DD-MM-YYYY")));
          } else {
            let parts = localsearchValues?.departingOn?.split("-");
            let day = parseInt(parts[0], 10);
            let month = parseInt(parts[1], 10) - 1;
            let year = parseInt(parts[2], 10);

            let date = new Date(year, month, day);
            date.setDate(date.getDate() - 1);
            dispatch(setNewDepDate(moment(date).format("DD-MM-YYYY")));
          }
        }}
      >
        Previous Date
      </button>
      <button
        className="button_style"
        onClick={() => {
          if (newDepDate) {
            let parts = newDepDate?.split("-");
            let day = parseInt(parts[0], 10);
            let month = parseInt(parts[1], 10) - 1;
            let year = parseInt(parts[2], 10);
            let newDate = new Date(year, month, day);
            newDate.setDate(newDate.getDate() + 1);
            dispatch(setNewDepDate(moment(newDate).format("DD-MM-YYYY")));
          } else {
            let parts = localsearchValues?.departingOn?.split("-");
            let day = parseInt(parts[0], 10);
            let month = parseInt(parts[1], 10) - 1;
            let year = parseInt(parts[2], 10);

            let date = new Date(year, month, day);
            date.setDate(date.getDate() + 1);
            dispatch(setNewDepDate(moment(date).format("DD-MM-YYYY")));
          }
        }}
      >
        Next Date
      </button>
    </div>
  );
}

export default ChangeAvailabiltyDate;
