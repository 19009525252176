import React from 'react';

const RequestDetails = () => {
  return (
    <div className=''>

    </div>
  )
}

export default RequestDetails