import React from 'react';
import Header from '../components/Common/Header'
import TransferComp from '../components/Setup/Umrah/Transfer'
import Footer from '../components/Footer'

const UmrahTransfer = () => {
  return (
    <div className=''>
      <TransferComp />
    </div>
  )
}

export default UmrahTransfer