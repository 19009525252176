import React from 'react'
import AgencyRegistrationComp from '../components/Setup/Registration/AgencyRegistration'

const AgencyRegistration = () => {
  return (
    <div className='Full_height'>
      <AgencyRegistrationComp />
    </div>
  )
}

export default AgencyRegistration