import React from "react";
import SereneLogo from "../../../../assets/images/airlineLogo/ER.png";
import moment from "moment";
//import icons
import {
  MdOutlineRestaurant,
  MdSwapHoriz,
  MdCheck,
  MdRemoveRedEye,
  MdClose,
} from "react-icons/md";
import { BsBagCheck, BsPerson, BsHandbag } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getSectorsName } from "../../../../Utils/FlightSector";
import { CurrencyExchange } from "../../../../Utils/currencyExchange";
import { pricingairline } from "../../../../Utils/airlinePricing";
import { LightTooltip } from "./LightTooltip";
import {
  setExpandFlightDetails,
  setReturnFlight,
  setselectedFlight,
} from "../../../../redux/features/home/FlightSearchSlice";
import { useNavigate } from "react-router-dom";

function Serene({ serene, index, isExpanded, toggleDetail }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { serene: sereneData, selectedFlight } = useSelector(
    (state) => state.FlightSearch
  );
  const { airlinePricing } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  const { addAirLinesData } = useSelector(
    (state) => state?.price_airlines_suppRoleSlice
  );
  // get values from local storage
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const currency = JSON.parse(localStorage.getItem("currency"));
  // airline pricing
  const pricing = airlinePricing?.find(
    (item) =>
      item.bg_id === searchValues?.agent?.agent_grp_id &&
      item?.add_airline?.code_alpha === "ER"
  );
  //airline
  const airline = addAirLinesData?.find((it) => it.code_alpha === "ER");

  // Convert minutes to hours and minutes using Moment.js
  const duration = moment.duration(serene?.legs[0]?.flightTime, "minutes");
  const formattedDuration = moment
    .utc(duration.asMilliseconds())
    .format("HH:mm");

  const locDeparture = searchValues?.locationDep;
  const locationArrival = searchValues?.locationArrival;

  //find the lowest price fare
  const lowestPrice = (fares) => {
    const lowestPriceElement = fares.reduce(
      (min, current) => {
        if (!current.soldOut && current.price < min.price) {
          return current;
        }
        return min;
      },
      { price: Number.MAX_SAFE_INTEGER, soldOut: false }
    );
    return lowestPriceElement;
  };

  //calculate the lowest total fare
  const lowestTotalFare = (fare) => {
    let newFare = CurrencyExchange(
      (pricingairline(
        airline,
        pricing,
        fare?.adult?.priceWithoutTax,
        fare?.adult?.price - fare?.adult?.priceWithoutTax,
        getSectorsName(locDeparture, locationArrival)
      )?.total_fare * searchValues?.adultsCount || 0) +
        (pricingairline(
          airline,
          pricing,
          fare?.child?.priceWithoutTax,
          fare?.child?.price - fare?.child?.priceWithoutTax,
          getSectorsName(locDeparture, locationArrival)
        )?.total_fare * searchValues?.childCount || 0) +
        (pricingairline(
          airline,
          pricing,
          fare?.infant?.priceWithoutTax,
          fare?.infant?.price - fare?.infant?.priceWithoutTax,
          getSectorsName(locDeparture, locationArrival)
        )?.total_fare * searchValues?.infantNo || 0),
      currency
    );
    return newFare;
  };

  return (
    <>
      <div className="search_engin_result_box bg-white">
        <div className="shadow result_box_body">
          <div className="row align-items-center justify-content-center result_box_body">
            <div className="col-2">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={SereneLogo} alt="SereneLogo" width={100} />
                <div>
                  {serene?.carrierCode} {serene?.flightNumber}
                </div>
              </div>
            </div>

            <div className="col-7">
              <div className="d-flex justify-content-evenly align-items-center">
                <div>
                  <h6>
                    {moment(
                      new Date(serene?.departureDate?.split("T")[0])
                    ).format("DD-MMM-YY")}
                  </h6>
                  <h6 className="my-3">
                    {serene?.departureDate?.split("T")[1]?.slice(0, 5)}
                  </h6>
                  <h6>
                    {serene?.from?.code} - {serene?.from?.name?.split(",")[0]}
                  </h6>
                  {getSectorsName(serene?.from?.code, serene?.to?.code) ===
                    "SOTO" && (
                    <h6 className="color_secondary">
                      ({serene?.from?.name.split("(")[1]}
                    </h6>
                  )}
                </div>

                <div>
                  <h6 className="text-center">{formattedDuration}</h6>
                  <div className="border_rounded mb-2" />
                  <h6 className="mx-3">Non-Stop</h6>
                </div>

                <div>
                  <h6>
                    {moment(
                      new Date(serene?.arrivalDate?.split("T")[0])
                    ).format("DD-MMM-YY")}
                  </h6>
                  <h6 className="my-3">
                    {serene?.arrivalDate?.split("T")[1]?.slice(0, 5)}
                  </h6>
                  <h6>
                    {serene?.to?.code} - {serene?.to?.name?.split(",")[0]}
                  </h6>
                  {getSectorsName(serene?.from?.code, serene?.to?.code) ===
                    "international" && (
                    <h6 className="color_secondary">
                      ({serene?.to?.name?.split("(")[1]}
                    </h6>
                  )}
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="shadow pt-3 d-flex flex-column align-items-center justify-content-center result_box_body">
                <h4>{lowestTotalFare(lowestPrice(serene?.fares))}</h4>

                {isExpanded !== undefined ? (
                  <button
                    onClick={() => {
                      toggleDetail(index);
                    }}
                    className="button_style w-75 my-3"
                    type="button"
                  >
                    {isExpanded ? "Cancel" : "Select"}
                  </button>
                ) : null}

                {sereneData?.routes[1]?.flights?.length === 0 && (
                  <p className="text-danger text-center">
                    Return flight is sold or not in operation, Select another
                    return date.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded ? (
          <div className="p-3">
            <div className="search_engin_result_box py-3 bg-white h-auto">
              <div className="d-flex justify-content-between align-items-start px-5">
                {/* headings */}
                <div className="border-r">
                  <div className="mb-4 pb-3"></div>
                  <div className="flight-detail-headings">
                    <BsHandbag size={25} className="color_primary" />
                    <span>Hand Carry Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsBagCheck size={25} className="color_primary" />
                    <span>Checked Bags</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdOutlineRestaurant size={25} className="color_primary" />
                    <span>Meals</span>
                  </div>
                  <div className="flight-detail-headings">
                    <BsPerson size={25} className="color_primary" />
                    <span>Seat Selection</span>
                  </div>
                  <div className="flight-detail-headings">
                    <MdSwapHoriz size={25} className="color_primary" />
                    <span>Refund & Exchanges</span>
                  </div>
                </div>
                {serene?.fares?.map((fare, index) => {
                  return (
                    <div key={fare.id + index}>
                      <div className="fs-4 theme-text-color mb-3">
                        {fare.name}
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Upto 7 kg</span>
                      </div>
                      <div className="flight-detail-headings">
                        {fare.name === "Economy Regular" ? (
                          <>
                            <MdCheck size={25} color="green" />
                            <span>40 kg (2 Pcs)</span>
                          </>
                        ) : fare.name === "Free Baggage" ? (
                          <>
                            <MdCheck size={25} color="green" />
                            <span>20 kg (1 Pcs)</span>
                          </>
                        ) : fare.name === "Serene PLUS" ? (
                          <>
                            <MdCheck size={25} color="green" />
                            <span>80 kg (3 Pcs)</span>
                          </>
                        ) : (
                          <>
                            <MdClose size={25} color="red" />
                            <span>0 kg</span>
                          </>
                        )}
                      </div>
                      <div className="flight-detail-headings">
                        <MdCheck size={25} color="green" />
                        <span>Allowed</span>
                      </div>
                      <div className="flight-detail-headings">
                        <CgMenuGridR size={25} color="#38025c" />
                        <span>System Allocated</span>
                      </div>
                      {/* refund and exchange dialog  */}
                      <div className="flight-detail-headings">
                        <LightTooltip
                          title={
                            <div className="p-3">
                              <h4>Refund & Exchange</h4>
                              <h6>
                                Penalty will be charged per passenger segment.
                              </h6>

                              <div className="d-flex justify-content-between align-item-start w-fit mt-4">
                                <div className="me-5">
                                  <h4>Exchange</h4>
                                  <h6>
                                    Before 48 Hours:{" "}
                                    {CurrencyExchange(1000, currency)}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {CurrencyExchange(1500, currency)}
                                  </h6>
                                </div>
                                <div>
                                  <h4>Refund</h4>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {CurrencyExchange(1500, currency)}
                                  </h6>
                                  <h6>
                                    Within 48 Hours:{" "}
                                    {CurrencyExchange(2500, currency)}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          }
                          placement="top-start"
                        >
                          <div style={{ cursor: "pointer" }}>
                            <MdRemoveRedEye size={25} color="#262829" />
                            <span>View</span>
                          </div>
                        </LightTooltip>
                      </div>

                      <div className="pt-3 px-2">
                        {fare?.soldOut ? (
                          <button disabled className="button_style mt-2">
                            Sold Out
                          </button>
                        ) : (
                          <LightTooltip
                            title={
                              <div className="p-3">
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex me-4">
                                    <h5 style={{ minWidth: "3rem" }}>ADT</h5>
                                    <h5>* {searchValues?.adultsCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyExchange(
                                      pricingairline(
                                        airline,
                                        pricing,
                                        fare?.adult?.priceWithoutTax,
                                        fare?.adult?.price -
                                          fare?.adult?.priceWithoutTax,
                                        getSectorsName(
                                          locDeparture,
                                          locationArrival
                                        )
                                      ).total_fare * searchValues?.adultsCount,
                                      currency
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end my-2">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>CHD</h5>
                                    <h5>* {searchValues?.childCount}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyExchange(
                                      pricingairline(
                                        airline,
                                        pricing,
                                        fare?.child?.priceWithoutTax,
                                        fare?.child?.price -
                                          fare?.child?.priceWithoutTax,
                                        getSectorsName(
                                          locDeparture,
                                          locationArrival
                                        )
                                      )?.total_fare *
                                        searchValues?.childCount || 0,
                                      currency
                                    )}
                                  </h6>
                                </div>
                                <div className="d-flex justify-content-between align-items-end">
                                  <div className="d-flex">
                                    <h5 style={{ minWidth: "3rem" }}>INF</h5>
                                    <h5>* {searchValues?.infantNo}</h5>
                                  </div>
                                  <h6 style={{ minWidth: "7rem" }}>
                                    {CurrencyExchange(
                                      pricingairline(
                                        airline,
                                        pricing,
                                        fare?.infant?.priceWithoutTax,
                                        fare?.infant?.price -
                                          fare?.infant?.priceWithoutTax,
                                        getSectorsName(
                                          locDeparture,
                                          locationArrival
                                        )
                                      )?.total_fare * searchValues?.infantNo ||
                                        0,
                                      currency
                                    )}
                                  </h6>
                                </div>
                              </div>
                            }
                            placement="top"
                          >
                            <button
                              type="button"
                              className="button_style px-4"
                              onClick={() => {
                                if (
                                  selectedFlight ||
                                  searchValues?.trip_type !== "ROUND TRIP"
                                ) {
                                  dispatch(
                                    setReturnFlight({
                                      flight: serene,
                                      fare: fare,
                                      price: lowestTotalFare(fare),
                                    })
                                  );
                                  navigate("/check-out");
                                } else {
                                  dispatch(
                                    setselectedFlight({
                                      flight: serene,
                                      fare: fare,
                                      price: lowestTotalFare(fare),
                                      baggageName: fare?.name,
                                      flightNumber: `${serene?.carrierCode} ${serene?.flightNumber}`,
                                      depDate: moment(
                                        new Date(
                                          serene?.departureDate?.split("T")[0]
                                        )
                                      ).format("DD-MMM-YY"),
                                      depTime: serene?.departureDate
                                        ?.split("T")[1]
                                        ?.slice(0, 5),
                                      arrDate: moment(
                                        new Date(
                                          serene?.arrivalDate?.split("T")[0]
                                        )
                                      ).format("DD-MMM-YY"),
                                      arrTime: serene?.arrivalDate
                                        ?.split("T")[1]
                                        ?.slice(0, 5),
                                      flightDuration: formattedDuration,
                                      logo: "Serene",
                                    })
                                  );
                                }
                                dispatch(setExpandFlightDetails(null));
                              }}
                            >
                              {lowestTotalFare(fare)}
                            </button>
                          </LightTooltip>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Serene;
