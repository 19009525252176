import React from "react";
import Plotly from "plotly.js-basic-dist-min";
import createPlotlyComponent from "react-plotly.js/factory";

const Plot = createPlotlyComponent(Plotly);

const Graph = ({ title, labels, values }) => {
  return (
    <div className="card bg-light w-100 h-auto">
      <div className="card-header">{title}</div>
      <div className="card-body">
        <form>
          <div className="">
            <div className="">
              <label className="font-weight-semibold">&nbsp;</label>
              <div className="d-flex align-items-start">
                <span className="input-group-prepend">
                  <span className="input-group-text me-2">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                  </span>
                </span>
                <input
                  type="date"
                  name="graph_date"
                  className="form-control form-control-sm graph_date hasDatepicker"
                  value="2022-09-19"
                  id="dp1663569224399"
                  onChange={(e) => console.log(e)}
                />
              </div>
            </div>
            <div className="pb-3 pt-md-4 ">
              <button type="submit" className="btn btnclr btn-sm btn-block">
                Generate All {title.split(" ")?.at(0)} Graph Report
              </button>
            </div>
          </div>
        </form>

        <div className="w-100">
          <Plot
            className="w-100"
            data={[
              {
                x: labels,
                y: values,
                type: "scatter",
                mode: "lines",
                name: { title },
                line: { color: "#686868" },
              },
            ]}
            layout={{
              xaxis: {
                titlefont: {
                  family: "Arial, sans-serif",
                  size: 18,
                  color: "grey",
                },
                showticklabels: true,
                tickfont: {
                  family: "Old Standard TT, serif",
                  size: 14,
                  color: "black",
                },
              },
              yaxis: {
                showticklabels: true,
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Graph;
