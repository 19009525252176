import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomDropdown from "../../CustomDrp";
import { HTTP } from "../../../Utils/API";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncEditHotelData,
  asyncGetHotelData,
  asyncPostHotelData,
  asyncUpdateData,
  hotelEditNull,
} from "../../../redux/features/umrah/HotelSlice";
import { options } from "../../../Utils/ColumnOptions";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { Form, Formik } from "formik";
import * as yup from "yup";
import BtnLoader from "../../AppForm/BtnLoader";
const Hotel = () => {
  const dispatch = useDispatch();
  const { hotelData, editHotelData,loading } = useSelector((state) => state.hotel);


  useEffect(() => {
    dispatch(asyncGetHotelData());
  }, []);

  const cityNames = [
    { id: 1, name: "Mecca" },
    { id: 2, name: "Medina" },
  ];

  const catagoryRatings = [
    { id: 1, value: "1 Star" },
    { id: 2, value: "2 Stars" },
    { id: 3, value: "3 Stars" },
    { id: 4, value: "4 Stars" },
    { id: 5, value: "5 Stars" },
  ];

  console.log(hotelData);

  const hotelStatus = async (status, id) => {
    if (!status) {
      var statusrow = 0;
    } else if (status) {
      var statusrow = 1;
    } else if (status === 0) {
      var statusrow = 0;
    } else if (status === 1) {
      var statusrow = 1;
    }
    const formdata = {
      id: id,
      status: statusrow,
    };
    HTTP.post(`/api/v1/b2b/umrah_hotel/status_umrah_hotel`, formdata)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const columns = [
    
    {
      name: "u_hotel_id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      label: "status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div class="checkbox-rect">
              <input
                value={value}
                checked={value}
                type="checkbox"
                id={"with_sar_" + id}
                onChange={(event) => {
                  updateValue(!value);
                  hotelStatus(!value, id);
                }}
              />
              <label for={"with_sar_" + id} className="h6"></label>
            </div>
          );
        },
      },
    },
    {
      name: "city.city_name",
      label: "City",
    },
    {
      name: "umrah_hotel_name",
      label: "name",
    },
    {
      name: "hotel_detail.category",
      label: "category",
    },
    {
      name: "hotel_detail.phone",
      label: "contact no",
    },
    {
      name: "hotel_detail.email",
      label: "email",
    },
    {
      name: "hotel_detail.distance",
      label: "distance",
    },
    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <button
              className="btn-success rounded edit-delete-icon-button me-1"
              onClick={() => {
                window.scroll({top:0})
                dispatch(asyncEditHotelData(id))
              }}
            >
              <i className="fa fa-thin fa-pencil"></i>
            </button>
          );
        },
      },
    },
  ];

  const validationSchem = yup.object().shape({
    city: yup.number().required("city required"),
    name: yup.string().required("name required"),
    category: yup.string().required("category required"),
    phone: yup.number().required("phone required"),
    email: yup
      .string()
      .email("should be type email")
      .required("email required"),
    distance: yup.string().required("distance required"),
    address: yup.string().required("address required"),
  });

  // console.log(editHotelData);

  const initialValues = {
    city: editHotelData?.city?.u_city_id || cityNames[0].id,
    name: editHotelData?.umrah_hotel_name || "",
    category: editHotelData?.hotel_detail?.category || catagoryRatings[0].value,
    phone: editHotelData?.hotel_detail?.phone || "",
    email: editHotelData?.hotel_detail?.email || "",
    distance: editHotelData?.hotel_detail?.distance || "",
    address: editHotelData?.hotel_detail?.location || "",
    // for updating data we need u_hotel_id
    u_hotel_id: editHotelData?.u_hotel_id,
  };

  return (
    <div className="col-12 col-sm-9 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Add Umrah Hotel</p>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchem}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, action) => {
          //           const vals={
          //             city:values.city,
          // name:values.name,
          // category:values.category,
          // phone:values.phone,
          // email:values.email,
          // distance:values.distance,
          // address:values.address
          //           }
          if (editHotelData) {
            dispatch(asyncUpdateData(values));
            dispatch(hotelEditNull())
          } else {
            dispatch(asyncPostHotelData(values));
            action.resetForm({values:""})
          }
        }}
      >
        {({ values, handleChange, errors }) => {
          console.log(errors);
          return (
            <Form className="col-12 px-5 d-flex flex-column justify-content-center">
              <div className="col-12 mt-4 py-3 d-flex">
                <div className="col-6 px-5">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select City
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={values.city}
                      onChange={handleChange}
                      name="city"
                    >
                      {cityNames.map((city) => (
                        <MenuItem value={city.id} key={city.id}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <p style={{ color: "red" }}>
                      {errors && errors?.city}
                    </p>
                  </FormControl>
                </div>
                <div className="col-6 px-5">
                  <FormControl variant="standard" className="w-100">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select Catagory
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={values.category}
                      onChange={handleChange}
                      name="category"
                    >
                      {catagoryRatings.map((cat) => (
                        <MenuItem value={cat?.value} key={cat.id}>
                          {cat.value}
                        </MenuItem>
                      ))}
                    </Select>
                    <p style={{ color: "red" }}>
                      {errors && errors?.category}
                    </p>
                  </FormControl>
                </div>
              </div>
              <div className="col-12 mt-4 py-3 d-flex">
                <div className="col-6 px-5">
                  <TextField
                    id="standard-basic"
                    label="Name"
                    variant="standard"
                    onChange={handleChange}
                    name="name"
                    value={values.name}
                    className="col-12"
                  />
                    <p style={{ color: "red" }}>
                      {errors && errors?.name}
                    </p>
                </div>
                <div className="col-6 px-5">
                  <TextField
                    id="standard-basic"
                    label="Phone"
                    variant="standard"
                    className="col-12"
                    type="number"
                    name="phone"
                    onChange={handleChange}
                    value={values.phone}
                  />
                    <p style={{ color: "red" }}>
                      {errors && errors?.phone}
                    </p>
                </div>
              </div>
              <div className="col-12 mt-4 py-3 d-flex">
                <div className="col-6 px-5">
                  <TextField
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    className="col-12"
                  />
                    <p style={{ color: "red" }}>
                      {errors && errors?.email}
                    </p>
                </div>
                <div className="col-6 px-5">
                  <TextField
                    id="standard-basic"
                    label="Distance"
                    variant="standard"
                    name="distance"
                    onChange={handleChange}
                    value={values.distance}
                    className="col-12"
                  />
                    <p style={{ color: "red" }}>
                      {errors && errors?.distance}
                    </p>
                </div>
              </div>
              <div className="col-12 mt-4 py-3">
                <div className="col-6 px-5">
                  <TextField
                    className="col-12 mt-3"
                    id="standard-basic"
                    label="Address"
                    variant="standard"
                    name="address"
                    value={values.address}
                    onChange={handleChange}
                 />
                    <p style={{ color: "red" }}>
                      {errors && errors?.address}
                    </p>
                </div>
              </div>
              <button type="submit" className="btn setup-btn">
              {loading ? <BtnLoader/> : editHotelData ? "update":"save"}
              </button>
            </Form>
          );
        }}
      </Formik>
      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Umrah Hotel Details"}
          data={hotelData}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Hotel;
