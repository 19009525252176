import { TextField } from "@mui/material";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { MdAdd } from "react-icons/md";
import { HiMinusSm } from "react-icons/hi";

const Traveler = ({ setFieldValue, values, FieldArray, isActiveTaxPayer, setisActiveTaxPayer }) => {

  return (
    <div>

      <div className="row align-items-end" style={{ marginBottom: 26 }}>
        <div className="col-6 col-sm-3">
          <TextField
            variant="standard"
            label="Name Of Insured"
            value={values?.insure_name}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("insure_name", value.toUpperCase());
            }}
            className="w-100"
          />
        </div>
        <div className="col-6 col-sm-3">
          <ReactDatePicker
            className="date_picker"
            maxDate={new Date()}
            placeholderText="Date Of Birth"
            monthsShown={1}
            selected={values?.dob}
            onChange={(date) => {
              setFieldValue("dob", date);
            }}
          />
        </div>
        <div className="col-6 col-sm-3 mt-3 mt-md-0">
          <TextField
            variant="standard"
            label="Email"
            value={values.email}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("email", value);
            }}
            className="w-100"
          />
        </div>
        <div className="col-6 col-sm-3 mt-3 mt-md-0">
          <TextField
            variant="standard"
            label="Passport No"
            value={values.pass_no}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              setFieldValue("pass_no", value);
            }}
            className="w-100"
          />
        </div>
      </div>

      <div
        className="row align-items-end"
        style={{ marginBottom: values?.insurer === "Family" ? 80 : 150 }}
      >
        <div className="col-6 col-sm-3 mt-3">
          <TextField
            variant="standard"
            label="CNIC"
            type="number"
            value={values.cnic}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("cnic", value);
            }}
            className="w-100"
          />
        </div>
        <div className="col-6 col-sm-3 mt-3">
          <TextField
            variant="standard"
            label="Address"
            value={values.address}
            onChange={(e) => {
              const value = e.target.value.toUpperCase();
              setFieldValue("address", value);
            }}
            className="w-100"
          />
        </div>
        <div className="col-6 col-sm-3 mt-3">
          <TextField
            variant="standard"
            label="Contact"
            type="number"
            value={values.contact}
            onChange={(e) => {
              const value = e.target.value;
              setFieldValue("contact", value);
            }}
            className="w-100"
          />
        </div>
        <div className="col-6 col-sm-3 checkbox-rect">
          <input
            type="checkbox"
            id="all_select"
            checked={isActiveTaxPayer}
            onChange={(e) => {
              setisActiveTaxPayer(true);
            }}
            style={{ marginRight: "8px" }}
          />
          {!isActiveTaxPayer && (
            <label for="all_select">Active Tax Payer</label>
          )}
          {isActiveTaxPayer && (
            <TextField
              variant="standard"
              label="Enter NTN No"
              value={values.ntn_No}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue("ntn_No", value);
              }}
              className="w-100"
            />
          )}
        </div>
      </div>


      {values?.insurer === "Family" && (
        <div className="pb-5">
          <h4>Family</h4>
          <hr />
          <div className="row">
            <div className="col-sm-2">
              <TextField
                variant="standard"
                label="Spouse"
                value={values.spouse}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setFieldValue("spouse", value);
                }}
                className="w-100"
              />
            </div>
            <div className="col-sm-1">
              <TextField
                variant="standard"
                label="Age"
                type="number"
                value={values.age}
                onChange={(e) => {
                  const value = e.target.value;
                  setFieldValue("age", value);
                }}
                className="w-100"
              />
            </div>
            <div className="col-sm-2">
              <TextField
                variant="standard"
                label="Passport No"
                value={values.passport_No}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase();
                  setFieldValue("passport_No", value);
                }}
                className="w-100"
              />
            </div>
          </div>
          <FieldArray
            name="family"
            render={(arrayHelpers) => (
              <div>
                {values?.family && values?.family.length > 0
                  ? values?.family?.map((familymember, index) => (
                      <div className="row mt-5">
                        <div className="col-sm-2">
                          <TextField
                            variant="standard"
                            label={`Children ${index + 1}`}
                            value={values.family[index].child}
                            onChange={(e) => {
                              const value = e.target.value.toUpperCase();
                              setFieldValue(`family.${index}.child`, value);
                            }}
                            className="w-100"
                          />
                        </div>
                        <div className="col-sm-1">
                          <TextField
                            variant="standard"
                            label="Age"
                            type="number"
                            value={values.family[index].chl_age}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue(`family.${index}.chl_age`, value);
                            }}
                            className="w-100"
                          />
                        </div>

                        <div className="col-sm-2">
                          <TextField
                            variant="standard"
                            label="Passport No"
                            value={values.family[index].chl_passport_no}
                            onChange={(e) => {
                              const value = e.target.value.toUpperCase();
                              setFieldValue(
                                `family.${index}.chl_passport_no`,
                                value
                              );
                            }}
                            className="w-100"
                          />
                        </div>
                        <div className="col-sm-2">
                          {index === 0 ? (
                            <button
                              type="button"
                              className="add-btn"
                              disabled={values?.family?.length === 5}
                              onClick={() =>
                                arrayHelpers.push({
                                  child: "",
                                  chl_age: "",
                                  chl_passport_no: "",
                                })
                              }
                            >
                              <MdAdd size={25} />
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="sub-btn btn-danger me-2 me-md-2"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <HiMinusSm size={25} />
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default Traveler;
