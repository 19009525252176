import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import { TextField, Box, Typography } from "@mui/material";
import DepositSlip from "./Modals/DepositSlip";
import { baseURL, HTTP } from "../../../Utils/API";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { IoMdEye } from "react-icons/io";
import {
  asyncGetReceiptData,
  ReceiptDepositPrint,
} from "../../../redux/features/Payments/PaymentsAllSlices";
import words from "number-to-words";
import iataImg from "../../../assets/images/IAT.png";

import ReactToPrint from "react-to-print";
import { ToWords } from "to-words";
import { apiUrl } from "../../../Utils/configUrl";
import moment from "moment";

const handleScroll = () => {
  const d = document.scrollingElement;
  d.scrollTop = document.body.scrollHeight;
};

const Reciept = () => {
  const dispatch = useDispatch();
  const { receiptData, receiptPrintData, companyData } = useSelector(
    (state) => state.allPayments
  );

  const user = JSON.parse(localStorage.getItem("loginUser"));
  const [showDepositSlip, setShowDepositSlip] = useState(false);
  const [subReciptModelShow, setSubReciptModalShow] = useState(false);

  const [customerName, setCustomerName] = useState("");
  const [recieptShow, setRecieptShow] = useState(false);

  const [recipModeltData, setReciptModelData] = useState(null);

  const agentId = user?.agents_comp?.acc_id;

  useEffect(() => {
    dispatch(asyncGetReceiptData(agentId));
  }, []);
  console.log("receiptData", receiptData);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
      },
    },
    {
      name: "receipt_no",
      options: {
        display: false,
      },
    },

    {
      name: "createdAt",
      label: "Date",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <div>{moment(value?.split("T")[0]).format("ddd DD-MMM-YY")}</div>
          );
        },
      },
    },
    {
      name: "acc_account",
      label: "Agent Name || User Name",
      options: {
        customBodyRender: (value, tableMeta) => {
          const agentName = tableMeta.rowData[3]?.agents_comp[0]?.agent_name;
          const user_name = tableMeta.rowData[7];

          return (
            <div>
              {agentName} || {user_name?.f_name} {user_name?.l_name}{" "}
            </div>
          );
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => {
          return <button className="confirmBtn pt-2">{value}</button>;
        },
      },
    },

    {
      name: "cash_in_hand",
      options: {
        display: false,
      },
    },

    {
      name: "roe",
      options: {
        display: false,
      },
    },

    {
      name: "agents_user",
      options: {
        display: false,
      },
    },

    {
      name: "cur",
      options: {
        display: false,
      },
    },

    {
      name: "id",
      label: "Bank || Cash in Hand",
      options: {
        customBodyRender: (value) => {
          const row = receiptData?.find((bank) => bank?.id === value);
          return <div>{row?.cash_in_hand?.acc_title}</div>;
        },
      },
    },
    {
      name: "amount_asg_cur",
      label: "Amount",
      options: {
        customBodyRender: (value, tableMeta) => {
          const amount = receiptData?.find(
            (bank) => bank?.amount_asg_cur === value
          );
          return (
            <div>
              <div className="text-primary fw-bold">{`${amount?.amount_asg_cur} ${amount?.cur}`}</div>
              {user?.user_id === 1 && (
                <div className="mt-1">
                  {amount?.cur !== "PKR" && `${amount?.amount_d_cur} PKR`}
                </div>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "image",
      label: "Bank Receipt",
      options: {
        customBodyRender: (value, tableMeta) => {
          const receipt = tableMeta?.rowData[1];
          return (
            <>
              {value && (
                <Box width={60}>
                  <a
                    href={`${apiUrl}/${value}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="eyeColor"
                  >
                    <IoMdEye size={40} />
                  </a>
                </Box>
              )}

              {receipt && <div>{receipt}</div>}
            </>
          );
        },
      },
    },

    {
      name: "voucher_number",
      label: "Receipts",
      options: {
        customBodyRender: (value, tableMeta) => {
          const selectRow = receiptData?.find(
            (dt, index) => dt?.voucher_number === value
          );
          return (
            <div>
              <button
                className="confirmBtn pt-2"
                onClick={() => {
                  setRecieptShow(true);
                  setReciptModelData(tableMeta?.rowData);
                }}
              >
                {value}
              </button>
              <p className="pt-2 ">
                {selectRow?.approved_by
                  ? "Post Date: " + selectRow.approved_date
                  : "Save Date: " + selectRow?.saved_date}
              </p>
            </div>
          );
        },
      },
    },
  ];
  const options = {
    filter: false,
    Selection: false,
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    selectableRows: "none",
    enableNestedDataAccess: ".",
    // onRowClick: (rowData) => {
    //   navigate(`/booking-details/${rowData[3]}/active`)
    // },
  };

  return (
    <div className="pb-5 px-2 mt-5">
      <RecieptModal
        show={recieptShow}
        setShow={setRecieptShow}
        setSubReciptModalShow={setSubReciptModalShow}
        setCustomerName={setCustomerName}
        customerName={customerName}
      />

      <SubRecieptModal
        show={subReciptModelShow}
        setShow={setSubReciptModalShow}
        customerName={customerName}
        setCustomerName={setCustomerName}
        recipModeltData={recipModeltData}
        user={user}
      />

      {/* <DepositSlip
        show={showDepositSlip}
        setShow={setShowDepositSlip}
        customerName={customerName}
      /> */}

      <MUIDataTable
        className="muidatatable"
        title={"Booking Filter Record"}
        data={receiptData}
        columns={columns}
        options={options}
      />
    </div>
  );
};

export default Reciept;

const RecieptModal = ({
  show,
  setShow,
  setSubReciptModalShow,
  setCustomerName,
  customerName,
}) => {
  const [radio, setRadio] = useState("company");

  const printReciept = () => {
    setShow(false);
    setSubReciptModalShow(true);
  };

  return (
    <Modal show={show} onHide={(e) => setShow(false)} centered>
      <Modal.Header>
        <Modal.Title>Print Receipt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <div className="col-5 my-2">
            <label class="form-control">
              <input
                type="radio"
                name="radio"
                value="company"
                checked={radio === "company"}
                onChange={(e) => setRadio(e.target.value)}
              />
              <span className="">Company Name</span>
            </label>
          </div>
          <div className="col-5 my-2 align-items-end">
            <label className="form-control">
              <input
                type="radio"
                name="radio"
                value="customer"
                checked={radio === "customer"}
                onChange={(e) => setRadio(e.target.value)}
              />
              <span className="">Customer Name</span>
            </label>
          </div>
        </div>
        {radio === "customer" && (
          <div className="col-10 mx-auto my-3">
            <TextField
              label="Customer Name"
              variant="standard"
              className="w-100"
              name="customerName"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-buttons-parent mx-auto">
          <Button variant="danger modal-button" onClick={(e) => setShow(false)}>
            Cancel
          </Button>
          <button className="confirmBtn ms-3" onClick={printReciept}>
            Save
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

// const ComponentToPrint = React.forwardRef((props, ref) => {
//   const { companyData } = useSelector((state) => state.allPayments);
//   return (
//     <div
//       className="d-flex flex-column justify-content-center col-12 px-3"
//       ref={ref}
//     >
//       <div className="row mt-2 w-100">
//         <div className="col-3">
//           {/* <img src={`${baseURL}/${companyData?.logo}`} className="modal-img" /> */}
//         </div>
//         <div className="col-6">
//           <div className="reciept-sub-modal-header">
//             <div className="h4">{companyData?.compnay_name}</div>
//             <span className="mt-2">
//               {companyData?.company_address?.address}{" "}
//               {companyData?.company_address?.city}
//             </span>
//             <span className="my-2">
//               Tel: {companyData?.company_address?.emg_phone} /{" "}
//               {companyData?.company_address?.phone}
//             </span>
//             <div className="h6 reciept-lic-no">
//               Govt Lic No. {companyData?.reg_number}
//             </div>
//             <div className="h2">Receipt</div>
//           </div>
//         </div>
//         <div className="col-3">
//           <img
//             src="/IATA.png"
//             className="modal-img"
//             style={{ float: "right" }}
//           />
//         </div>
//       </div>
//       <div className="row">
//         <div className="col-sm-12 d-flex justify-content-between">
//           <p>Reciept#{companyData?.payload[11]}</p>
//           {/* <p>Reciept#{customerName.id}</p> */}
//           <p>Date:{companyData?.payload[1]}</p>
//         </div>
//       </div>
//       <div className="mt-0">
//         <div className="col-12">
//           <TextField
//             value={
//               props?.customerName ? props?.customerName : companyData?.payload[2]
//             }
//             label="Received with thanks from Messers"
//             variant="standard"
//             className="w-100"
//             // defaultValue={customerName.customer_name || customerName.company_name}
//           />
//         </div>
//         <div className="col-12 mt-2">
//           <TextField
//             value={companyData?.payload[4]}
//             label="Deposit Slip#"
//             variant="standard"
//             className="w-100"
//           />
//         </div>
//         <div className="col-12 mt-2">
//           <TextField
//             value={toWords.convert(companyData?.payload[6])}
//             label="The sum of Rupees"
//             variant="standard"
//             className="w-100"
//           />
//         </div>
//         <div className="col-12 mt-2">
//           <TextField
//             label="By (Cash/ Cheque) No"
//             variant="standard"
//             // not done yet
//             value={`${
//               companyData?.payload[10] == "cash"
//                 ? `${companyData?.payload[10]} , (Cash receive at headoffice)`
//                 : companyData?.payload[10] +
//                   "  _  " +
//                   companyData?.payload[7] +
//                   "  _  " +
//                   companyData?.payload[11]
//             }`}
//             // ${companyData?.payload[7]}cheque
//             className="w-100"
//           />
//         </div>
//         <div className="col-3 mt-2">
//           <TextField
//             label="RS"
//             variant="standard"
//             className="w-100"
//             value={companyData?.payload[6]}
//           />
//         </div>
//       </div>
//     </div>
//   );
// });

const SubRecieptModal = ({
  show,
  setShow,
  customerName,
  setCustomerName,
  recipModeltData,
  user,
  ref,
}) => {
  const componentRef = useRef();

  const toCamelCase = (input) => {
    return input.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  return (
    <Modal
      show={show}
      onHide={(e) => {
        setShow(false);
        setCustomerName("");
      }}
      centered
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 900,
          bgcolor: "background.paper",
          boxShadow: 24,
          outline: "none",
          borderRadius: 1,
          pb: 2,
        }}
      >
        {/* <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: -10,
            right: -10,
          }}
          onClick={() => setShow(false)}
        >
          <CloseIcon />
        </IconButton> */}

        <Box ref={componentRef} p={4}>
          <Box display={"flex"}>
            <Box flex={1}>
              <Box width={"100%"}>
                <img
                  src={`${apiUrl}/${user?.agents_comp?.logo}`}
                  alt="logo"
                  style={{ width: "90%", height: 50 }}
                />
              </Box>
            </Box>
            <Box flex={2} textAlign="center">
              <Box fontSize={24} color={"#355a6b"} fontWeight={600}>
                {user?.agents_comp?.agent_name}
              </Box>
              <Box fontSize={14} paddingTop={1.5} fontWeight={600}>
                {" "}
                {user?.agents_comp?.address.split("]").join(" ")}
              </Box>
              <Box fontSize={14} paddingTop={1.5} fontWeight={600}>
                Tel:{" "}
                {user?.agents_comp?.mobile_no +
                  " / " +
                  user?.agents_comp?.phone}
              </Box>
            </Box>
            <Box flex={1}>
              <Box width={"100%"}>
                <Box width={"50%"} ml={13}>
                  <img src={iataImg} alt="logo" className="w-100" />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box mt={4} display={"flex"} justifyContent={"space-between"}>
              <Typography>
                Receipt#: {recipModeltData && recipModeltData[12]}
              </Typography>
              <Box display={"flex"} alignItems={"center"}>
                <Typography>Date:</Typography>
                <Typography sx={{ textDecoration: "underline" }}>
                  {recipModeltData && recipModeltData[2]?.split("T")[0]}
                </Typography>
              </Box>
            </Box>

            <Box display={"flex"} marginTop={3} alignItems={"center"}>
              <Typography fontSize={16}>
                Received with thanks from Messers:
              </Typography>
              <Typography flex={1} fontSize={16} borderBottom={1} ml={2}>
                {customerName
                  ? customerName
                  : recipModeltData &&
                    recipModeltData[3]?.agents_comp[0]?.agent_name}
              </Typography>
            </Box>

            <Box display={"flex"} marginTop={3} alignItems={"center"}>
              <Typography fontSize={16}>Sum of Amount:</Typography>
              <Typography flex={1} fontSize={16} borderBottom={1} ml={2}>
                {recipModeltData &&
                  toCamelCase(words.toWords(recipModeltData[10]))}
                {` ${recipModeltData && recipModeltData[8]}`}
              </Typography>
            </Box>

            <Box display={"flex"} marginTop={3} alignItems={"center"}>
              <Typography fontSize={16}>by (Cash/ Cheque) No:</Typography>
              <Typography flex={1} fontSize={16} borderBottom={1} ml={2}>
                {recipModeltData && recipModeltData[5]?.acc_title}
              </Typography>
            </Box>

            <Box display={"flex"} marginTop={3} alignItems={"center"}>
              <Typography fontSize={16}>
                {recipModeltData && recipModeltData[8]}:
              </Typography>
              <Typography width={120} fontSize={16} borderBottom={1} ml={2}>
                {recipModeltData && recipModeltData[10]}
              </Typography>
            </Box>
          </Box>
        </Box>

        <div className="text-center mt-5">
          <button
            className="cncl_button bg-danger"
            onClick={(e) => {
              setShow(false);
              setCustomerName("");
            }}
          >
            Cancel
          </button>
          <ReactToPrint
            trigger={() => (
              <button
                className="prnt_button ms-3"
                onClick={(e) => {
                  setShow(false);
                  setCustomerName("");
                }}
              >
                Print
              </button>
            )}
            content={() => componentRef.current}
          />
        </div>
      </Box>
    </Modal>
  );
};
