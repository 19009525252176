
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, HTTPJson } from "../ApiConfig";

const initialState = {
    visaTerms: [],
    visaTermsEdit:null,
    loading: false,
    error: null,
  };
  

// getting VisaTerms data
export const asyncGetVisaTerms = createAsyncThunk(
  "asyncGetVisaTerms/get",
  async (_,{rejectWithValue}) => {
    try {
      const response =await HTTP.get(`/api/v1/b2b/visa_terms`)
      return await response.data.data.country
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);

// posting VisaTerms data
export const asyncPostVisaTerms = createAsyncThunk(
  "asyncPostVisaTerms/post",
  async (values,{rejectWithValue,dispatch}) => {
    console.log(values);
    try {
      const response =await HTTPJson.post(`/api/v1/b2b/visa_terms`,{
        country_id:values.country,
        terms:values.terms
      })
      // this fucntion call will reload the data 
      dispatch(asyncGetVisaTerms())
      // return await response.data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);

// edit VisaTerms data, this gets the data first for updating
export const asyncEditTerm = createAsyncThunk(
  "asyncEditTerm/post",
  async (id,{rejectWithValue,dispatch}) => {
    console.log(id);
    try {
      const response =await HTTPJson.get(`/api/v1/b2b/visa_terms/${id}`)
      return await response.data.data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);

// update VisaTerms data which we got with asyncEditTerm()
export const asyncUpdateTerm = createAsyncThunk(
  "asyncUpdateTerm/post",
  async (values,{rejectWithValue,dispatch}) => {
    console.log(values);
    try {
      const response =await HTTPJson.put(`/api/v1/b2b/visa_terms_update`,{
        terms:values.terms,
        term_id:values.id
      })
      dispatch(asyncGetVisaTerms())
      // return await response.data.data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);


// delete VisaTerms data, this gets the data first for updating
export const asyncDeleteTerm = createAsyncThunk(
  "asyncDeleteTerm/delete",
  async (id,{rejectWithValue,dispatch}) => {
    console.log(id);
    try {
      const response =await HTTPJson.delete(`/api/v1/b2b/visa_terms_delete?id=${id}`)
      dispatch(asyncGetVisaTerms())
      // return await response.data.data
    } catch (error) {
      console.log(error)
      return rejectWithValue(error)
    }
  }
);

export const VisaTermSlice = createSlice({
  name: "VisaTermSlice",
  initialState,
  
  reducers:{
    termEditNull:(state)=>{
      state.visaTermsEdit=null
    }
  },
  extraReducers: (builder) => {

    // getting VisaTerms data
    builder.addCase(asyncGetVisaTerms.pending, (state, {payload}) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetVisaTerms.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.visaTerms = payload;
    });
    builder.addCase(asyncGetVisaTerms.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting VisaTerms data
    builder.addCase(asyncPostVisaTerms.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostVisaTerms.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.visaTerms = payload;
    });
    builder.addCase(asyncPostVisaTerms.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
       // getting VisaTerms data
       builder.addCase(asyncEditTerm.pending, (state, { payload }) => {
        state.loading = true;
        state.error = null;
      });
      builder.addCase(asyncEditTerm.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.visaTermsEdit = payload;
      });
      builder.addCase(asyncEditTerm.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
          // updating VisaTerms data
          builder.addCase(asyncUpdateTerm.pending, (state, { payload }) => {
            state.loading = true;
            state.error = null;
          });
          builder.addCase(asyncUpdateTerm.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.error = null;
          });
          builder.addCase(asyncUpdateTerm.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
          });
  },
});

export const {termEditNull} =VisaTermSlice.actions
export default VisaTermSlice.reducer;
