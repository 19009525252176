const columns = [
    {
      name: "type",
      label: "Type",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "date_of_birth",
      label: "D.O.B",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "pass_no",
      label: "Passport No",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "visa_no",
      label: "Visa No",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "visa_supplier",
      label: "Visa supplier",
      options: {
        filter: false,
        sort: true,
      }
    }
  ];
  export default columns