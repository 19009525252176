import React from "react";

function Sidebar() {
  return (
    <div>
      <img
        src="https://media.istockphoto.com/id/1423740789/photo/young-woman-in-the-airport.jpg?s=2048x2048&w=is&k=20&c=qsbYqspx6_Vn2KXXP50DsNGgtbYCu_gqHpA4mnNyzq0="
        alt=""
        className="w-100 rounded-2"
        height={400}
      />
    </div>
  );
}

export default Sidebar;
