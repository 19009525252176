import React from "react";
import Header from "../components/Common/Header";
import GroupsComp from "../components/Groups/Groups";
import Footer from "../components/Footer";

const Groups = () => {
  return (
    <div>
      <GroupsComp />
    </div>
  );
};

export default Groups;
