import React from "react";

const Tabs = ({ InputGroup, setInputGroup }) => {
  return (
    <div className="d-flex col-sm-6 mx-auto justify-content-between text-center progress_bar-register-cont mt-5">
      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
        onClick={() => setInputGroup("plan")}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            InputGroup === "plan" ? "primarycolor" : "secondarycolor"
          }  text-white px-3 py-2 h4`}
        >
          1
        </div>
        <h6
          className={`${
            InputGroup === "plan" ? "primarytext" : "secodarytext"
          }`}
        >
          Plan
        </h6>
      </div>

      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
        onClick={() => setInputGroup("traveler")}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            InputGroup === "traveler" ? "primarycolor" : "secondarycolor"
          }  text-white px-3 py-2 h4`}
        >
          2
        </div>
        <h6
          className={`${
            InputGroup === "traveler" ? "primarytext" : "secodarytext"
          }`}
        >
          Traveler
        </h6>
      </div>

      <div
        className="d-flex flex-column align-items-center pb-3"
        style={{ height: "8rem", width: "", cursor: "pointer" }}
        onClick={() => setInputGroup("beneficiary")}
      >
        <div
          className={`progress_bar-register rounded-circle ${
            InputGroup === "beneficiary" ? "primarycolor" : "secondarycolor"
          }  text-white px-3 py-2 h4`}
        >
          3
        </div>

        <h6
          className={`${
            InputGroup === "beneficiary" ? "primarytext" : "secodarytext"
          } `}
        >
          Beneficiary
        </h6>
      </div>
    </div>
  );
};

export default Tabs;
