import { baseURL as url } from "../redux/features/ApiConfig";
import { URL2 } from "../redux/features/ApiConfig";

export const imgUrl = "http://127.0.0.1:8000/images/homeslider";

export const appImgUrl = url;

// export const apiUrl = url; // live api
export const apiUrl = URL2; // live api
// export const apiUrl = "http://localhost:3009"; //local api
export const baseURL = url; // live api
// export const baseURL = "http://localhost:3000"; //local api
