import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP } from "../ApiConfig";

const initialState = {
  hotelRateData: [],
  hotelRateRoom:[],
  editHotelRate:null,
  // this is for re rendering the page when select hotel api is hit 
  reLoadPage:false,
  loading: false,
  error: null,
};

// getting hotelRateData data
export const asyncGetHotelRate = createAsyncThunk(
  "asyncGetHotelRate/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/hotel_rates`);
      // console.log(await response.data.data);
      return await response.data.data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);
// getting hotelRateRoom data
export const asyncGetHotelRateRoom = createAsyncThunk(
  "asyncGetHotelRateRoom/get",
  async (id, { rejectWithValue }) => {
    console.log(id);
    try {
      // const response = await HTTP.get(`/api/v1/b2b/hotel_rates/get_room?hotel_id=10`);
      const response = await HTTP.get(`/api/v1/b2b/hotel_rates/get_room?hotel_id=${id}`);
      console.log(await response.data.data);
      return await response.data.data
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting hotelrates data
export const asyncPostHotelRate = createAsyncThunk(
  "asyncPostHotelRate/post",
  async (vals, { rejectWithValue, dispatch }) => {
    console.log(vals);
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/hotel_rates`,
        vals
      );
      dispatch(asyncGetHotelRate());
      // toast.success("data added");
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit hotelRate data
export const asyncEditHotelRates = createAsyncThunk(
  "asyncEditHotelRates/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/hotel_rates/${id}`
      );
      dispatch(asyncGetHotelRate());
      // toast.success("data added");
      return await response.data.data.edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// update hotel Rates data
export const asyncUpdateHotelRate = createAsyncThunk(
  "asyncUpdateHotelRate/post",
  async (values, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.post(
        `/api/v1/b2b/hotel_rates/hotel_rates_update`,values
      );
      // console.log(await response.data.data);
      dispatch(asyncGetHotelRate());
      // toast.success("data added");
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// Delete hotel rate data
export const asyncDeleteHotelRate = createAsyncThunk(
    "asyncDeleteHotelRate/delete",
    async (id, { rejectWithValue, dispatch }) => {
        console.log(id);
      try {
        const response = await HTTP.get(
          `/api/v1/b2b/hotel_rates/hotel_rates_delete/${id}`
        );
        dispatch(asyncGetHotelRate());
      } catch (error) {
        console.log(error);
        return rejectWithValue(error);
      }
    }
  );

export const HotelRateSlice = createSlice({
  name: "HotelRateSlice",
  initialState,

  reducers:{
    editHotelRateNull:(state)=>{
      state.editHotelRate=null
    },
    setHotelRoomNull:(state)=>{
      state.hotelRateRoom=null
    }
  },
  extraReducers: (builder) => {
    // getting hotelRateData
    builder.addCase(asyncGetHotelRate.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetHotelRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.hotelRateData = payload;
    });
    builder.addCase(asyncGetHotelRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
        // getting hotelRateRoom
        builder.addCase(asyncGetHotelRateRoom.pending, (state, { payload }) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(asyncGetHotelRateRoom.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.error = null;
          state.hotelRateRoom = payload;
          // state.reLoadPage=!state.reLoadPage
        });
        builder.addCase(asyncGetHotelRateRoom.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });

    // posting RoomType data
    builder.addCase(asyncPostHotelRate.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostHotelRate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostHotelRate.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

        // edit hotel data
        builder.addCase(asyncEditHotelRates.pending, (state, { payload }) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(asyncEditHotelRates.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.error = null;
          state.editHotelRate = payload;
        });
        builder.addCase(asyncEditHotelRates.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });

                // update hotel data
                builder.addCase(asyncUpdateHotelRate.pending, (state, { payload }) => {
                  state.loading = true;
                  state.error = null;
                });
                builder.addCase(asyncUpdateHotelRate.fulfilled, (state, { payload }) => {
                  state.loading = false;
                  state.error = null;
                });
                builder.addCase(asyncUpdateHotelRate.rejected, (state, { payload }) => {
                  state.loading = false;
                  state.error = payload;
                });

                // delete hotelRate data
                builder.addCase(asyncDeleteHotelRate.pending, (state, { payload }) => {
                    state.loading = true;
                    state.error = null;
                  });
                  builder.addCase(asyncDeleteHotelRate.fulfilled, (state, { payload }) => {
                    state.loading = false;
                    state.error = null;
                  });
                  builder.addCase(asyncDeleteHotelRate.rejected, (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                  });
  },
});

export const {editHotelRateNull,setHotelRoomNull} =HotelRateSlice.actions
export default HotelRateSlice.reducer;
