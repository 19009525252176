// i have added
// (1) agency page
// (2) document page
// (3) userlevel page
// pages in this slice from agency section

// ******************************************* AGENCY SECTION *************************************************************************
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, HTTPForm } from "../ApiConfig";

const initialState = {
  // agency data
  agencyStateData: [],

  loading: false,
  error: null,
};

// get agency data
export const asyncGetAgencyData = createAsyncThunk(
  "asyncGetAgencyData/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get("http://localhost:3009/api/v1/b2b/single-agency");
    console.log(  response.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

//posting agency data
export const asyncMain_Acc_PostAgencyData = createAsyncThunk(
  "asyncPostAgencyData/post",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await HTTPForm.post("/api/v1/b2b/officedata", formData);
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export const asyncAgent_Acc_PostAgencyData = createAsyncThunk(
  "asyncAgent_Acc_PostAgencyData/post",
  async (agent_formData, { rejectWithValue }) => {
    try {
      const response = await HTTPForm.post("/api/v1/b2b/officedata", agent_formData);
    } catch (error) {
      console.log(error);
      rejectWithValue(error);
    }
  }
);


export const Agency_Doc_Userlevel_Slice = createSlice({
  name: "Agency_doc_userlevel_Slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get data
    builder.addCase(asyncGetAgencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAgencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.agencyStateData = payload;
    });
    builder.addCase(asyncGetAgencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post data
    builder.addCase(asyncMain_Acc_PostAgencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncMain_Acc_PostAgencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncMain_Acc_PostAgencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // post data
    builder.addCase(asyncAgent_Acc_PostAgencyData.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncAgent_Acc_PostAgencyData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncAgent_Acc_PostAgencyData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export default Agency_Doc_Userlevel_Slice.reducer;
