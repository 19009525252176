import React from 'react'
import AddSliderText from '../components/Setup/Promotions/AddSliderText'

const FlashNews = () => {
  return (
    <div className='Full_height'>
      <AddSliderText />
    </div>
  )
}

export default FlashNews