import React from "react";
import Accordion from "react-bootstrap/Accordion";

const TravelersDropdown = ({ title, values, formdata, setData, room_no }) => {
  return (
      <Accordion.Item eventKey={room_no-1}>
        <Accordion.Header>Room # {room_no}</Accordion.Header>
        <Accordion.Body>
          <div className="travelers__category travelers__category-adults--3">
            <div className="travelers__title-holder">
              <label for="lbladults" className="travelers__title text-xs">
                {" "}
                Adults
              </label>
            </div>
            <div name="traveler_plusminus" className="traveler_plusminus">
              <div className="travelers__options">
                <button
                  id="plusBtn"
                  aria-label="Remove one remain3adults"
                  aria-disabled="false"
                  className="travelers__minus"
                  disabled={
                    (values["adults"] === 0 || values["adults"] === 1)
                  }
                  onClick={(e) => {
                    const update = { ...values };
                    update["adults"] = values["adults"] - 1;
                    const array = [...formdata.rooms];
                    array[room_no-1] = update
                    setData({
                      ...formdata,
                      rooms: array
                    });
                  }}
                >
                  -<span className="sr-only">Remove adults</span>
                </button>
                <label className="travelers__value" id="lbladults">
                  {values["adults"]}
                </label>
                <button
                  id="minusBtn"
                  aria-label="Added 3 adults"
                  aria-disabled="false"
                  className="travelers__plus"
                  data-toggle="collapse"
                  disabled={
                    ((values["adults"] === 6) || (values["child"] + values["adults"] === 6))
                  }
                  onClick={(e) => {
                    const update = { ...values };
                    update["adults"] = values["adults"] + 1;
                    const array = [...formdata.rooms];
                    array[room_no-1] = update
                    setData({
                      ...formdata,
                      rooms: array
                    });
                  }}
                >
                  +<span className="sr-only">Add adults</span>
                </button>
              </div>
            </div>
          </div>
          <div className="travelers__category travelers__category-adults--3">
            <div className="travelers__title-holder">
              <label for="lbladults" className="travelers__title text-xs">
                {" "}
                Childs
              </label>
            </div>
            <div name="traveler_plusminus" className="traveler_plusminus">
              <div className="travelers__options">
                <button
                  id="plusBtn"
                  aria-label="Remove one remain3adults"
                  aria-disabled="false"
                  className="travelers__minus"
                  disabled={
                    values["child"] === 0
                  }
                  onClick={(e) => {
                    const update = { ...values };
                    update[title] = values["child"] - 1;
                    const array = [...formdata.rooms];
                    array[room_no-1] = update
                    setData({
                      ...formdata,
                      rooms: array
                    });
                  }}
                >
                  -<span className="sr-only">Remove adults</span>
                </button>
                <label className="travelers__value" id="lbladults">
                  {values["child"]}
                </label>
                <button
                  id="minusBtn"
                  aria-label="Added 3 adults"
                  aria-disabled="false"
                  className="travelers__plus"
                  data-toggle="collapse"
                  disabled={values["child"] + values["adults"] === 6}
                  onClick={(e) => {
                    const update = { ...values };
                    update["child"] = values["child"] + 1;
                    const array = [...formdata.rooms];
                    array[room_no-1] = update
                    setData({
                      ...formdata,
                      rooms: array
                    });
                  }}
                >
                  +<span className="sr-only">Add adults</span>
                </button>
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
  );
};

export default TravelersDropdown;