import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../../ApiConfig";

const initialState = {
  FZCredentialsData: [],
  editFZCredentials: null,
  loading: false,
  error: null,
};

// post FZ API CREDENTIAL
export const asyncPostFZApiCredentials = createAsyncThunk(
  "asyncPostFZApiCredentials/post",
  async (values, { rejectWithValue }) => {
    try {
      const response = await NODEURL.post(`/api/v1/b2b/fz-credential`, values);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get FZ API CREDENTIAL
export const asyncGetFZApiCredentials = createAsyncThunk(
  "asyncGetFZApiCredentials/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/fz-credential`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get single FZ API CREDENTIAL
export const asyncGetSingleFZApiCred = createAsyncThunk(
  "asyncGetSingleFZApiCred/get",
  async (id, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get(`/api/v1/b2b/fz-credential/${id}`);
      return await response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// Update FZ API CREDENTIAL
export const asyncUpdateFZCredential = createAsyncThunk(
  "asyncUpdateFZCredential/patch",
  async ({ id, values }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.patch(
        `/api/v1/b2b/fz-credential/${id}`,
        values
      );
      dispatch(asyncGetFZApiCredentials());
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update FZ API CREDENTIAL
export const asyncDeleteFZCredential = createAsyncThunk(
  "asyncDeleteFZCredential/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.delete(`/api/v1/b2b/fz-credential/${id}`);
      dispatch(asyncGetFZApiCredentials());
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const FZApiCredentialSlice = createSlice({
  name: "FZApiCredentialSlice",
  initialState,
  reducers: {
    editFZCredentialNull: (state) => {
      state.editFZCredentials = null;
    },
  },

  extraReducers: (builder) => {
    // Post FZ Api Credentials
    builder.addCase(asyncPostFZApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncPostFZApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.FZCredentialsData = [...state.FZCredentialsData, payload];
      }
    );
    builder.addCase(
      asyncPostFZApiCredentials.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );

    // Get FZ Api Credentials
    builder.addCase(asyncGetFZApiCredentials.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetFZApiCredentials.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.FZCredentialsData = payload;
      }
    );
    builder.addCase(asyncGetFZApiCredentials.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Get single FZ Api Credentials
    builder.addCase(asyncGetSingleFZApiCred.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetSingleFZApiCred.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editFZCredentials = payload;
    });
    builder.addCase(asyncGetSingleFZApiCred.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // Update FZ Api Credentials
    builder.addCase(asyncUpdateFZCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncUpdateFZCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncUpdateFZCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // DElete FZ Api Credentials
    builder.addCase(asyncDeleteFZCredential.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncDeleteFZCredential.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncDeleteFZCredential.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});
export const { editFZCredentialNull } = FZApiCredentialSlice.actions;
export default FZApiCredentialSlice.reducer;
