import React from 'react'
import UserComp from "../components/Agency/User";


const Users = () => {
  return (
    <div className=''>
      <UserComp />
    </div>
  )
}

export default Users