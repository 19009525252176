import React, { useState } from "react";
import planehead from "../../../../assets/images/planehead.png";
import blackseat from "../../../../assets/images/blackseat.png";
import grayseat from "../../../../assets/images/grayseat.png";
import blueicon from "../../../../assets/images/blueicon.png";
import greenicon from "../../../../assets/images/green.png";
import { Box, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import leftTailImage from "../../../../assets/images/lefttail.png";
import rightTailImage from "../../../../assets/images/righttail.png";
import { LightTooltip } from "../../FlightsTab/OneWaySearchResult/LightTooltip";

const useStyles = makeStyles({
  image: {
    width: "199px",
    marginLeft: "6px",
  },
  flexContainer: {
    display: "flex",
    fontSize: "1.25rem",
    fontWeight: "500",
    paddingTop: "3px",
  },
  flexItems: {
    flex: "1",
    display: "flex",
    justifyContent: "space-between",
    marginRight: "1rem",
  },
  flexItemsright: {
    flex: "1",
    display: "flex",
  },

  plainMainBody: {
    position: "relative",
    backgroundColor: "white",
    width: "198px",
    border: `2px solid #237db4`,
    borderLeftWidth: "2px",
    borderRightWidth: "2px",
    paddingLeft: "2px",
    marginLeft: "6px",
    height: "1185px",
    "&::before": {
      content: "''",
      backgroundImage: `url(${leftTailImage})`,
      backgroundRepeat: "no-repeat",
      position: "absolute",
      top: "500px",
      left: "-130px",
      width: "200px",
      height: "300px",
    },
    "&::after": {
      content: "''",
      backgroundImage: `url(${rightTailImage})`,
      backgroundRepeat: "no-repeat",
      position: "absolute",
      top: "500px",
      right: "-200px",
      width: "200px",
      height: "300px",
    },
  },
  absoluteText: {
    position: "absolute",
    top: "10px",
    right: "220px",
    fontSize: "0.875rem",
    fontWeight: "600",
  },

  seatRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "0.5rem",
  },
  seatImage: {
    height: "2rem",
    marginRight: "0.5rem",
  },
  seatMargin: {
    marginRight: "1.2rem",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  default: {
    cursor: "default",
  },
});

const Seats = ({
  seats,
  paxes,
  index,
  setFieldValue,
  setSelectedSeats,
  selectedSeats,
}) => {
  const classes = useStyles();
  // const [selectedSeats, setSelectedSeats] = useState([]);
  const [selectedPassengerIndex, setSelectedPassengerIndex] = useState(null);
  const [error, setError] = useState("");

const seatData = Array.isArray(seats?.["ns1:SeatMapResponse"]) ? seats?.["ns1:SeatMapResponse"]?.[0] : seats?.["ns1:SeatMapResponse"]
  const seatss =seatData?.["ns1:SeatMapDetails"]?.["ns1:CabinClass"]?.[
      "ns1:AirRows"
    ]?.["ns1:AirRow"];

  const handlePassengerClick = (paxIndex) => {
    setSelectedPassengerIndex(paxIndex);
    setError("")
  };

  const handleSeatSelection = (row) => {
    const seatKey = `${row}`;
    if (selectedPassengerIndex === null) {
      setError("Please select a passenger first."); // Set error if no passenger is selected
      return;
    } 
    if (selectedPassengerIndex === null) {
      setError("Please select a passenger first."); // Set error if no passenger is selected
      return;
    }
    // Check if the selected seat is already present in selectedSeats
    if (!selectedSeats.includes(seatKey)) {
      // Create a copy of selectedSeats
      const updatedSelectedSeats = [...selectedSeats];

      // Update the selected seat for the current passenger
      updatedSelectedSeats[selectedPassengerIndex] = seatKey;
      const seatsKey = index===0 ? 'outbound' : 'inbound';
      // Update selectedSeats and the form field value
      setSelectedSeats(updatedSelectedSeats);
      setFieldValue(`pax[${selectedPassengerIndex}]${seatsKey}.seat`, seatKey);
    }
  };

  // Function to determine the image source for each seat
  const getSeatImage = (seat, rowIndex, selectedSeats) => {
    const seatKey = `${rowIndex + 1}${seat?.["$"].SeatNumber}, ${
      seat?.["$"].SeatCharacteristics
    }`;
    if (seat?.["$"].SeatAvailability !== "RES") {
      if (selectedSeats.includes(seatKey)) {
        return blueicon; // Return blue icon if the seat is selected
      } else {
        return parseInt(seat?.["$"].SeatCharacteristics) === 0
          ? greenicon
          : blackseat; // Return green icon for free seats, black icon otherwise
      }
    } else {
      return grayseat; // Return gray icon for occupied seats
    }
  };
  const planeBodyHeight = 30 + seatss?.length * 40;
  return (
    <>
      <Box mt={3}>
        <Divider />
      </Box>
     
        <Box  mt={4} border={1} borderRadius={2} borderColor="gray"  display="flex" className="flex-md-column flex-sm-column flex-lg-row " justifyContent="space-between"  >
          
            <Box p={2} >
              {/* <Box> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                  
                  mt={3}
                  style={{ width: "20rem" }}
                >
                  {paxes.map((pax, index) => (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      bgcolor={
                        selectedPassengerIndex === index ? "#00324E" : "#19465F"
                      }
                      borderRadius={1}
                      color="white"
                      py={1}
                      px={2}
                      mt={2}
                      justifyContent="space-between"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "auto",
                        textOverflow: "ellipsis",
                        cursor: "pointer",
                      }}
                      sx={{
                        borderRadius: 1, // Add border radius
                        "&:hover": {
                          bgcolor:
                            selectedPassengerIndex === index
                              ? "#19465F"
                              : "#0e304b",
                        },
                      }}
                      onClick={() => handlePassengerClick(index)}
                    >
                      <Typography
                        variant="body1"
                        pl={4}
                        
                        style={{
                          flex: "0 0 70%",
                          maxWidth: "70%",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "17rem"
                        }}
                        fontSize={20}
                      >
                        {`${pax.title}: ${pax.f_name} ${pax.l_name}`.slice(
                          0,
                          16
                        )}
                      </Typography>

                      <Box class="checkbox-wrapper-12">
                        <Box class="cbx">
                          <input
                            type="checkbox"
                            id="cbx-12"
                            checked={selectedSeats[index]}
                            onClick={() => handlePassengerClick(index)}
                          />
                          <label for="cbx-12"></label>
                          <svg
                            fill="none"
                            viewBox="0 0 15 14"
                            height="14"
                            width="15"
                          >
                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                          </svg>
                        </Box>
                      </Box>
                    </Box>
                  ))}

                 
                </Box>
                {error && (
                    <Box ml={4} mt={2} bgcolor="red.100" fontSize={15} color="red">
                      {error}
                    </Box>
                  )}
              {/* </Box> */}
              <Box mt={3} pl={3}>
                <Box display="flex" alignItems="center">
                  <img src={blackseat} alt="" height="40" />
                  <Typography variant="body1" pl={3} fontSize={20}>
                    Available
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={3}>
                  <img src={grayseat} alt="" height="40" />
                  <Typography variant="body1" pl={3} fontSize={20}>
                    Occupied
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={3}>
                  <img src={blueicon} alt="" height="40" />
                  <Typography variant="body1" pl={3} fontSize={20}>
                    Selected
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" mt={3}>
                  <img src={greenicon} alt="" height="40" />
                  <Typography variant="body1" pl={3} fontSize={20}>
                    Free
                  </Typography>
                </Box>
              </Box>
            </Box>
          
          
            <Box m={1} position={"relative"} width={400}   sx={{ display: { xs: 'column', md: 'block' } }}  >
              <img src={planehead} alt="" className={classes.image}  />

              <Box className={classes.plainMainBody} style={{ height: planeBodyHeight }}>
                <Box className={classes.flexContainer}>
                  <Box className={classes.flexItems}>
                    <Box pl={0.5}>A</Box>
                    <Box>B</Box>
                    <Box>C</Box>
                  </Box>
                  <Box className={classes.flexItems}>
                    <Box pl={1.8}>D</Box>
                    <Box>E</Box>
                    <Box pl={0}>F</Box>
                  </Box>
                </Box>

                {seatss?.map((seats, index) => {
                  return (
                    <Box position={"relative"}   >
                      <Box className={classes.absoluteText}>{index + 1}</Box>
                      <Box className={classes.seatRow}>
                        {seats?.["ns1:AirSeats"]["ns1:AirSeat"]?.map(
                          (seat, i) => (
                            <LightTooltip
                              title={
                                parseInt(seat?.["$"]?.SeatCharacteristics) === 0
                                  ? "Free"
                                  : `PKR ${parseInt(
                                      seat?.["$"]?.SeatCharacteristics
                                    )}`
                              }
                              key={`seat-${index}-${i}`}
                            >
                              <img
                                key={`${selectedPassengerIndex}`}
                                src={getSeatImage(seat, index, selectedSeats)}
                                alt="seat"
                                // className={`${classes.seatImage} ${
                                //   i === 2 ? classes.seatMargin : ""
                                // }`}
                                // onClick={() => handleSeatSelection(index + 1, i + 1)}
                                onClick={() =>
                                  seat?.["$"].SeatAvailability !== "RES"
                                    ? handleSeatSelection(
                                        `${index + 1}${
                                          seat?.["$"].SeatNumber
                                        }, ${seat?.["$"].SeatCharacteristics}`
                                      )
                                    : () => {} // No action if the seat is reserved
                                }
                                className={`${classes.seatImage} ${
                                  i === 2 ? classes.seatMargin : ""
                                } h-8 ${
                                  seat?.["$"].SeatAvailability === "VAC"
                                    ? classes.cursorPointer
                                    : "crosshair"
                                } ${i === 2 ? "mr-5" : ""}`}
                              />
                            </LightTooltip>
                          )
                        )}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          
        </Box>
     
    </>
  );
};

export default Seats;
