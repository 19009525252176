import React, { useState } from "react";
import "./index.css";
import SaveVoucher from "./SaveVoucher";

function PostVoucher() {
  return (
    <div className="Full_height relative">
      <SaveVoucher postVoucher={true} />
    </div>
  );
}

export default PostVoucher;
