import React, { useEffect, useState } from "react";

import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";
import DeleteModal from "../../DeleteModal";
import { ChangeStatus } from "../../../Utils/ChangeStatus";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import {
  asyncDeleteWYCredential,
  asyncGetWYApiCredentials,
  asyncGetSingleWYApiCred,
  asyncPostWYApiCredentials,
  asyncUpdateWYCredential,
  editWYCredentialNull,
} from "../../../redux/features/setupFeature/ApiCredential/WYCredentialSlice";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dropMenuProp } from "../../../Utils/MenuProps";

function EmiratesNdc() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //use selector
  const { WYCredentialsData, loading, editWYCredentials } = useSelector(
    (state) => state.wyCredential
  );
  //use state
  const [ShowModel, setShowModel] = useState(false);
  const [rowData, setRowData] = useState(false);

  //use effect functions
  useEffect(() => {
    dispatch(asyncGetWYApiCredentials());
  }, [dispatch]);

  //  table columns
  const columns = [
    {
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <>
              <div className="checkbox-rect">
                <input
                  value={value}
                  checked={value}
                  type="checkbox"
                  id={"with_sar_" + id}
                  onChange={(event) => {
                    updateValue(!value);
                    ChangeStatus(
                      "/api/v1/b2b/wy-credential/status",
                      !value,
                      id,
                      "status"
                    );
                  }}
                />
                <label htmlFor={"with_sar_" + id} className="h6"></label>
              </div>
            </>
          );
        },
      },
    },
    {
      label: "Agent Name",
      name: "agent_name",
    },
    {
      label: "WY Agent ID",
      name: "wy_agent_id",
    },
    {
      label: "WY Api Key",
      name: "wy_api_key",
    },

    {
      label: "WY Secrete Key",
      name: "wy_secret_key",
    },
    {
      label: "Wy URL",
      name: "wy_url",
    },
    {
      label: "Actions",
      name: "actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          return (
            <div className="">
              <button
                className="btn-success rounded edit-delete-icon-button me-1"
                onClick={() => {
                  window.scroll({ top: 0 });
                  dispatch(asyncGetSingleWYApiCred(id));
                }}
              >
                <i className="fa fa-thin fa-pencil"></i>
              </button>
              <button
                className="btn-danger rounded edit-delete-icon-button"
                onClick={() => {
                  setShowModel(true);
                  setRowData(tableMeta.rowData);
                }}
              >
                <i className="fa fa-thin fa-trash"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  //initital value
  const initialValues = {
    agent_name: editWYCredentials?.agent_name || "",
    wy_agent_id: editWYCredentials?.wy_agent_id || "",
    wy_api_key: editWYCredentials?.wy_api_key || "",
    wy_secret_key: editWYCredentials?.wy_secret_key || "",
    wy_url: editWYCredentials?.wy_url || "",
    ticket_status: editWYCredentials?.ticket_status || 1,
  };

  //validation schemas
  const validationSchema = yup.object().shape({
    agent_name: yup.string().required("Agent Name is required"),
    wy_agent_id: yup.string().required("WY App id is required"),
    wy_api_key: yup.string().required("WY target is required"),
    wy_secret_key: yup.string().required("WY user name is required"),
    wy_url: yup.string().required("WY Service url is required"),
  });

  return (
    <div>
      <div>
        <div className="mx-5 my-5 bg-white rounded-2">
          <div className="d-flex align-items-center bg_primary text-white py-3">
            <div className="col-5">
              <span
                className="cursorpointer fs-5 w-content"
                onClick={() => navigate("/api-credentials")}
              >
                <MdArrowBackIosNew /> Back
              </span>
            </div>
            <div className="box_title px-2">Add / Edit Emirates NDC</div>
          </div>

          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              let id = editWYCredentials?.id;
              if (editWYCredentials) {
                dispatch(asyncUpdateWYCredential({ id, values }));
                dispatch(editWYCredentialNull());
              } else {
                dispatch(asyncPostWYApiCredentials(values));
              }
              resetForm({});
            }}
          >
            {({ values, handleChange, setFieldValue }) => {
              return (
                <Form>
                  <div className="row px-4 pb-3 align-items-start pt-4">
                    <div className="col-2 mt-4 h-5rem">
                      <TextField
                        label="Agent Name"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="agent_name"
                        value={values.agent_name}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("agent_name", value.toUpperCase());
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="agent_name"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="WY Agent id"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="wy_agent_id"
                        value={values.wy_agent_id}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("wy_agent_id", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="wy_agent_id"
                      />
                    </div>

                    <div className="col-2 mt-4 ">
                      <TextField
                        label="WY Api Key"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="wy_api_key"
                        value={values.wy_api_key}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("wy_api_key", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="wy_api_key"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="WY Secret Key"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="wy_secret_key"
                        value={values.wy_secret_key}
                        onChange={(e) => {
                          let value = e.target.value;
                          setFieldValue("wy_secret_key", value);
                        }}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="wy_secret_key"
                      />
                    </div>

                    <div className="col-2 mt-4">
                      <TextField
                        label="WY Url"
                        variant="standard"
                        className="w-100"
                        autoComplete="off"
                        name="wy_url"
                        value={values.wy_url}
                        onChange={handleChange}
                      />
                      <ErrorMessage
                        component={"div"}
                        className="mt-2 text-danger"
                        name="wy_url"
                      />
                    </div>
                    <div className="col-2 mt-4">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id={`ticket_status`}>
                          Ticket Status
                        </InputLabel>
                        <Select
                          id={`ticket_status`}
                          value={values.ticket_status}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue("ticket_status", value);
                          }}
                          MenuProps={dropMenuProp}
                          label={"Ticket Status"}
                        >
                          <MenuItem value={1}>YES</MenuItem>
                          <MenuItem value={0}>NO</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-12 text-center py-3 pb-4">
                    <button className="button_style px-5 me-3" type="submit">
                      {loading ? (
                        <BtnLoader />
                      ) : editWYCredentials ? (
                        "Update"
                      ) : (
                        "Save"
                      )}
                    </button>
                    {editWYCredentials && (
                      <button
                        type="button"
                        className="button_style px-5 bg-danger"
                        onClick={() => dispatch(editWYCredentialNull())}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
        {/*// Credensiol list  ////////*/}

        <div className="mx-5 shadow-sm rounded-2 my-4 bg-white">
          <p className="otawix-heading box_title py-2">
            Emirates NDC Credentials List
          </p>
          <div className="p-4">
            <DeleteModal
              show={ShowModel}
              setShow={setShowModel}
              onClick={(e) => {
                dispatch(asyncDeleteWYCredential(rowData[0]));
                setShowModel(false);
              }}
            />
            <MUIDataTable
              className="muidatatable"
              title={""}
              data={WYCredentialsData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmiratesNdc;
