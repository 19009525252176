import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NODEURL } from "../ApiConfig";

const initialState = {
  PageLevelData: [],
  rolePermission: [],
  userPermission: [],
  userTypePermissions: [],
  typePermissions: null,
  findTypePermission: null,

  loading: false,
  error: null,
};

export const asyncGetAllPageLevel = createAsyncThunk(
  "asyncGetAllPageLevel/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/page-level");
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// assign role-permissions
export const asyncAssignRolePermission = createAsyncThunk(
  "asyncAssignRolePermission/post",
  async (
    { user_type_id, page_level_id, toast },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/user-type-permission", {
        user_type_id,
        page_level_id,
      });
      dispatch(asyncGetAllUserTypePermission());
      dispatch(asyncGetAllUserPermission());
      // response.data.message
      //   ? toast.error(response.data.message)
      //   : toast.success("Permission assigned");
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// assign permissions
export const asyncAssignUserLevelPermission = createAsyncThunk(
  "asyncAssignUserLevelPermission/post",
  async ({ user_id, page_level_id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await NODEURL.post("/api/v1/b2b/user-permission", {
        user_id,
        page_level_id,
      });
      dispatch(asyncGetAllUserPermission());
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get all user permissions
export const asyncGetAllUserPermission = createAsyncThunk(
  "asyncGetAllUserPermission/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/user-permission");
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// find userType permissions
export const asyncGetAllUserTypePermission = createAsyncThunk(
  "asyncGetAllUserTypePermission/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await NODEURL.get("/api/v1/b2b/user-type-permission");
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// find userType permissions
export const asyncFindUserTypePermission = createAsyncThunk(
  "asyncFindUserTypePermission/post",
  async (url, { rejectWithValue }) => {
    console.log(url);
    try {
      const response = await NODEURL.post("/api/v1/b2b/find-type-permission", {
        permission_url: url,
      });
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rolesPermissionsSlice = createSlice({
  name: "rolesPermissionsSlice",
  initialState,
  reducers: {
    checkPermissions: (state, payload) => {
      state.typePermissions = state.userTypePermissions.find(
        (role) =>
          role.user_type_id === 6 && role.page_level.permission_url === payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncGetAllPageLevel.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetAllPageLevel.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.PageLevelData = payload;
    });
    builder.addCase(asyncGetAllPageLevel.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // post role-permissions
    builder.addCase(asyncAssignRolePermission.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncAssignRolePermission.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.rolePermission = payload;
      }
    );
    builder.addCase(
      asyncAssignRolePermission.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // post user level permissions
    builder.addCase(
      asyncAssignUserLevelPermission.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncAssignUserLevelPermission.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.rolePermission = payload;
      }
    );
    builder.addCase(
      asyncAssignUserLevelPermission.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // get all user level permissions
    builder.addCase(asyncGetAllUserPermission.pending, (state, { payload }) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      asyncGetAllUserPermission.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.userPermission = payload;
      }
    );
    builder.addCase(
      asyncGetAllUserPermission.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    //get all User type or Role permissions
    builder.addCase(
      asyncGetAllUserTypePermission.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncGetAllUserTypePermission.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.userTypePermissions = payload;
      }
    );
    builder.addCase(
      asyncGetAllUserTypePermission.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
    // find user type or Role permissions
    builder.addCase(
      asyncFindUserTypePermission.pending,
      (state, { payload }) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addCase(
      asyncFindUserTypePermission.fulfilled,
      (state, { payload }) => {
        state.loading = false;
        state.error = null;
        state.findTypePermission = payload;
      }
    );
    builder.addCase(
      asyncFindUserTypePermission.rejected,
      (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const { checkPermissions } = rolesPermissionsSlice.actions;
export default rolesPermissionsSlice.reducer;
