import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Button } from "@mui/material";
import { MdFilterList } from "react-icons/md";
import ReactDatePicker from "react-datepicker";
import { ClickAwayListener } from "@mui/material";
import { InputLabel } from "@mui/material";
import { FormControl } from "@mui/material";
import { Input } from "@mui/material";
import "./index.css";
import { Modal } from "@mui/material";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { CiSearch } from "react-icons/ci";

import {
  AsyncDeletAllPostVoucher,
  AsyncGetAllPostVoucher,
  AsyncGetVoucherEntries,
} from "../../../../redux/features/Voucher/VoucherSlice";
import moment from "moment";
import { asyncGetAgentData } from "../../../../redux/features/setupRestPages/agentsSlice";
import { apiUrl } from "../../../../Utils/configUrl";
import words from "number-to-words";
import { logDOM } from "@testing-library/react";
import BtnLoader from "../../../AppForm/BtnLoader";

function SaveVoucher({ postVoucher }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [voucher, setVoucher] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [deleteLoader, setDeletLoader] = useState(false);

  const [voucherFilter, setVoucherFilter] = useState({});
  const [voucherModel, setVoucherModel] = useState("");
  const { getvoucher } = useSelector((state) => state.voucherEntries);
  const { getAllPostVoucher } = useSelector((state) => state.voucherEntries);
  const { userPermission } = useSelector((state) => state?.roleAndPermissions);
  const { agentsData } = useSelector((state) => state.agentsCategories);
  const agentData = agentsData?.find((agent) => agent?.agent_id === 1);
  const allData = postVoucher
    ? getAllPostVoucher?.data?.data
    : getvoucher?.data?.data;
  const [allFilterData, setAllFilterData] = useState([]);

  const user = JSON.parse(localStorage.getItem("loginUser"));
  // Check if the login user has permission to edit voucher
  const editVoucherPermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level?.permission_url === "/voucher-edit"
      )
    : true;

  // Check if the login user has permission to delete voucher
  const deletePermission = ![1, 6, 7].includes(user.role_id)
    ? userPermission?.find(
        (it) =>
          it?.user_id === user?.user_id &&
          it?.page_level?.permission_url === "/voucher-delete"
      )
    : true;

    

  useEffect(() => {
    dispatch(AsyncGetVoucherEntries());
    dispatch(AsyncGetAllPostVoucher());
    dispatch(asyncGetAgentData());
  }, [dispatch]);
  // console.log(getAllPostVoucher?.data?.data);
  // console.log(
  //   "filterssssss",
  //   postVoucher
  //     ? getAllPostVoucher?.data?.data?.filter(
  //         (flt) =>
  //           moment(flt?.approved_date).format("YYYY-MM-DD") ===
  //           moment().format("YYYY-MM-DD")
  //       )
  //     : null
  // );
  useEffect(() => {
    // Use the retrieved data to update state
    const allData = postVoucher
      ? getAllPostVoucher?.data?.data
      : getvoucher?.data?.data;

    setAllFilterData(allData);
  }, [postVoucher, getvoucher, getAllPostVoucher]);
  const [voucherNumber, setVoucherNumber] = useState("");
  const obj = {
    1: "Journal Voucher",
    2: "Payment Voucher",
    3: "Reciept Voucher",
    4: "Sale Invoice",
    5: "Credit Note",
  };
  const handleFilterRecords = () => {
    if (voucher?.split("-")?.at(1) === "All") {
      let allDatas = allData?.filter((tr) => {
        const approvedDate = new Date(
          moment(tr?.approved_date).format("YYYY-MM-DD")
        );

        const isDateMatch =
          !startDate ||
          !endDate ||
          (approvedDate >= new Date(startDate) &&
            approvedDate <= new Date(endDate));
        return isDateMatch;
      });
      setAllFilterData(allDatas);
    } else {
      let filterDatas = allData?.filter((tr) => {
        const isVoucher =
          !voucher?.split("-")?.at(1) ||
          tr?.voucher_no?.split("-")?.at(0) === voucher?.split("-")?.at(1);

        const approvedDate = new Date(
          moment(tr?.approved_date).format("YYYY-MM-DD")
        );
        const isDateMatch =
          !startDate ||
          !endDate ||
          (approvedDate >= new Date(startDate) &&
            approvedDate <= new Date(endDate));
        return isDateMatch && isVoucher;
      });
      setAllFilterData(filterDatas);
    }
  };

  const columns = [
    {
      name: "voucher_id",
      label: "Voucher Date",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const getVoucherRow = allData.find(
            (item) => item?.voucher_id === value
          );
          return (
            <div
              className={`d-flex ${
                getVoucherRow?.voucher_type_id === 5 ? "text-danger" : ""
              }`}
              style={{ width: 150 }}
            >
              <p>
                {postVoucher
                  ? moment(getVoucherRow?.approved_date).format("ddd DD-MMM-YY")
                  : getVoucherRow?.saved_date?.split("/")?.at(0)}{" "}
              </p>
            </div>
          );
        },
      },
    },

    // {
    //   name: "voucher_no",
    //   label: "Voucher Type",
    //   options: {
    //     filter: true,
    //     sort: false,

    //   },
    // },
    {
      name: "voucher_id",
      label: "Voucher Type",
      options: {
        filter: true,

        sort: false,
        filterType: "voucher_no",

        customBodyRender: (value) => {
          const getVoucherRow = allData?.find(
            (item) => item?.voucher_id === value
          );

          return (
            <div
              className={`d-flex ${
                getVoucherRow?.voucher_type_id === 5 ? "text-danger" : ""
              }`}
              style={{ width: 150 }}
            >
              <p>{obj[getVoucherRow?.voucher_type_id]}</p>
            </div>
          );
        },
      },
    },

    {
      name: "voucher_id",
      label: "Voucher Title",
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const getVoucherRow = allData?.find(
            (item) => item?.voucher_id === value
          );
          return (
            <div className="d-flex gap-2 ">
              <div
                className={`d-flex gap-2 ${
                  getVoucherRow?.voucher_type_id === 5 ? "text-danger" : ""
                }`}
              >
                <p>
                  {getVoucherRow?.voucher_type_id === 1
                    ? getVoucherRow?.voucher_description
                    : getVoucherRow?.acc_voucher_trans?.at(0)
                        ?.trans_description}
                </p>
              </div>
            </div>
          );
        },
      },
    },

    {
      name: "voucher_id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = value;
          const getVoucherRow = allData.find(
            (item) => item?.voucher_id === value
          );
          const handleDelete = () => {
            setShowDeleteModal(tableMeta.rowIndex);
          };

          const handleCancel = () => {
            setShowDeleteModal(null);
          };

          const handleConfirmDelete = () => {
            setDeletLoader(true);
            dispatch(AsyncDeletAllPostVoucher(id));
            setTimeout(() => {
              setShowDeleteModal(null);
              setDeletLoader(false);
            }, 1000);
          };

          return (
            <div className="d-flex gap-2  ">
              <Button
                variant="contained"
                color="success"
                disabled={
                  getVoucherRow?.voucher_no?.split("-")?.at(0) === "INV" ||
                  getVoucherRow?.voucher_no?.split("-")?.at(0) === "CN"
                }
                onClick={() => {
                  setVoucherFilter(getVoucherRow);
                  setVoucherModel("openVoucher");
                }}
              >
                View
              </Button>

              <Button
                className="w-50"
                variant="contained"
                color="info"
                onClick={() => {
                  setVoucherFilter(getVoucherRow);
                  setVoucherModel("openTrans");
                }}
              >
                {getVoucherRow?.voucher_no}
              </Button>

              <Button
                variant="contained"
                color="success"
                disabled={!editVoucherPermission || user?.agent_id !== 1}
                onClick={() => {
                  if (getVoucherRow?.voucher_type_id === 4) {
                    navigate("/manual-invoice", {
                      state: {
                        voucherId: getVoucherRow?.voucher_description
                          ?.split("/")
                          ?.at(0),
                        postVoucher: postVoucher,
                        tkt_voucher_no:
                          getVoucherRow?.acc_voucher_trans?.at(0)
                            ?.tkt_voucher_no,
                        voucher_id: getVoucherRow?.voucher_id,
                        voucher_no: getVoucherRow?.voucher_no,
                      },
                    });
                  } else {
                    if (getVoucherRow?.voucher_type_id === 5) {
                      navigate("/manual-credit-new", {
                        state: {
                          voucherId: getVoucherRow?.voucher_description
                            ?.split("/")
                            ?.at(0),
                          postVoucher: postVoucher,
                          tkt_voucher_no:
                            getVoucherRow?.acc_voucher_trans?.at(0)
                              ?.tkt_voucher_no,
                          voucher_id: getVoucherRow?.voucher_id,
                          voucher_no: getVoucherRow?.voucher_no,
                        },
                      });
                    } else {
                      navigate("/vouchers-entry", {
                        state: {
                          voucherId: getVoucherRow?.voucher_id,
                          postVoucher: postVoucher,
                        },
                      });
                    }
                  }
                }}
              >
                <BorderColorIcon />
              </Button>

              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                disabled={
                  !deletePermission ||
                  getVoucherRow?.voucher_no?.split("-")?.at(1) === "FLA" ||
                  getVoucherRow?.voucher_no?.split("-")?.at(1) === "FLG" ||
                  user?.agent_id !== 1 ||
                  getVoucherRow?.voucher_type_id === 5
                }
                className="relative"
              >
                <i className="fa fa-trash fs-6" aria-hidden="true"></i>
              </Button>

              {showDeleteModal === tableMeta?.rowIndex && (
                <ClickAwayListener onClickAway={handleCancel}>
                  <Box
                    position={"absolute"}
                    borderRadius={1}
                    right={2}
                    bgcolor={"#ffffff"}
                    mt={5.5}
                    boxShadow={10}
                    p={2}
                    zIndex={1000000}
                  >
                    <Typography width={180}>
                      Are you sure, you want to Delete.
                    </Typography>
                    <Box
                      pt={1.5}
                      display={"flex"}
                      justifyContent={"space-between"}
                      gap={1}
                    >
                      <button
                        className="cancel_button bg-danger"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className="delete_button"
                        onClick={handleConfirmDelete}
                      >
                        {deleteLoader ? <BtnLoader /> : "Confirm"}
                      </button>
                    </Box>
                  </Box>
                </ClickAwayListener>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    Selection: false,
    search: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 50, 100],
    print: false,
    download: false,
    viewColumns: false,
    displayRowCheckbox: false,
    filter: false,
    selectableRows: "none",
  };

  const [voucherToggle, setVoucherToggle] = useState(false);
  const [filterToggle, setFilterToggle] = useState(false);
  const arry = [
    { id: 1, voucher: "All Voucher", num: "All" },
    { id: 2, voucher: "Journal Voucher", num: "JV" },
    { id: 3, voucher: "Payment Voucher", num: "PV" },
    { id: 4, voucher: "Reciept Voucher", num: "RV" },
    // { id: 4, voucher: "Sale Voucher", num: "SV" },
    // { id: 5, voucher: "Credit  Note", num: "CN" },
  ];

  const toCamelCase = (input) => {
    return input.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };
  return (
    <div className="Full_height relative">
      <div
        className="manual_invoices width_80percent mx-auto"
        style={{ position: "relative" }}
      >
        <div className="manual_invoices_header">
          <div>{postVoucher ? "Post Voucher" : "Save Voucher"}</div>
        </div>
        {/* -MUIDataTable-root */}
        <div className="p-4">
          <MUIDataTable
            title={postVoucher ? "Post Voucher" : "Save Voucher"}
            data={allFilterData}
            columns={columns}
            options={options}
          />
        </div>
        {postVoucher ? (
          <div
            className="bg-white"
            style={{ position: "absolute", top: 92, right: 130, width: 2 }}
          >
            <i
              onClick={() => setFilterToggle(!filterToggle)}
              style={{ cursor: "pointer" }}
            >
              <MdFilterList size={30} />
            </i>
          </div>
        ) : null}
        {/* ==================================================filter=========================================== */}
        <div className="relative">
          {filterToggle && (
            <ClickAwayListener
              onClickAway={() => setFilterToggle(!filterToggle)}
            >
              <div
                className="p-4 bg-white shadow-lg d-flex flex-column gap-4 justify-content-center "
                style={{
                  position: "absolute",
                  top: 130,
                  right: 0,
                  width: "50%",
                  height: "auto",
                }}
              >
                <div className="d-flex">
                  <div className="row ">
                    <div className="col-4 ">
                      <FormControl variant="standard" className="w-100" sx={{}}>
                        <InputLabel id="demo-simple-select-standard-label">
                          Voucher Type
                        </InputLabel>
                        <Input
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={voucher?.split("-")?.at(0)}
                          onClick={() => setVoucherToggle(!voucherToggle)}
                          label="Voucher Type"
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                width: "inherit",
                              },
                            },
                          }}
                        ></Input>
                      </FormControl>
                      {voucherToggle && (
                        <div
                          className="position-absolute rounded-3"
                          style={{ width: "inherit" }}
                        >
                          <div className="me-4 bg-white shadow-lg rounded-3">
                            {arry.map((list, index) => (
                              <ClickAwayListener
                                onClickAway={() =>
                                  setVoucherToggle(!voucherToggle)
                                }
                              >
                                <div
                                  className="p-2 mt-1 fs-6 voucherTpe"
                                  onClick={() => {
                                    setVoucher(list?.voucher + "-" + list?.num);
                                    setVoucherToggle(!voucherToggle);
                                  }}
                                  key={index}
                                >
                                  {list?.voucher}
                                </div>
                              </ClickAwayListener>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-4" style={{ paddingTop: 22 }}>
                      <ReactDatePicker
                        placeholderText="From Date"
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        maxDate={new Date()}
                        className="date_picker "
                      />
                    </div>
                    <div className=" col-4" style={{ paddingTop: 22 }}>
                      <ReactDatePicker
                        placeholderText="To Date"
                        selected={endDate}
                        onChange={(date) => {
                          setEndDate(date);
                        }}
                        maxDate={new Date()}
                        className="date_picker "
                      />
                    </div>
                  </div>
                  <div className=" ">
                    <button
                      className="button_style"
                      onClick={handleFilterRecords}
                    >
                      Filter Record
                    </button>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="h6">Search by Vocher number:</p>
                  <div className="d-flex gap-4  ">
                    <input
                      placeholder="Enter Voucher Number "
                      className="w-75 fs-6 "
                      style={{
                        border: "none",
                        borderBottom: "1px solid black",
                        outline: "none",
                        padding: 0,
                        margin: 0,
                        background: "transparent",
                        boxShadow: "none",
                      }}
                      onChange={(e) => {
                        let number = e.target.value;
                        setVoucherNumber(number);
                      }}
                    />
                    <span style={{ fontSize: "40px", cursor: "pointer" }}>
                      <CiSearch
                        onClick={(e) => {
                          let filterVouvherNumber = allData?.filter((tr) => {
                            const isVoucherNo =
                              !voucherNumber ||
                              tr?.voucher_no?.includes(voucherNumber);

                            return isVoucherNo;
                          });
                          setAllFilterData(filterVouvherNumber);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </ClickAwayListener>
          )}
        </div>
        {/* ======================================================= Model========================================= */}
        <div>
          <Modal
            open={["openVoucher", "openTrans"].includes(voucherModel)}
            onClose={() => setVoucherModel("")}
            BackdropProps={{
              style: {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 800,
                bgcolor: "background.paper",
                boxShadow: 24,
                outline: "none",
                py: 6,
                px: 4,
                borderRadius: 1,
              }}
            >
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  top: -10,
                  right: -10,
                }}
                onClick={() => setVoucherModel("")}
              >
                <CloseIcon />
              </IconButton>

              <Box>
                {voucherModel === "openVoucher" && (
                  <>
                    <Box display={"flex"}>
                      <Box flex={1}>
                        <Box width={"100%"}>
                          <img
                            src={`${apiUrl}/${agentData?.logo}`}
                            style={{ width: "90%", height: 50 }}
                            alt="this is agent company logo"
                          />
                        </Box>
                      </Box>
                      <Box flex={2} textAlign="center">
                        <Box fontSize={24} color={"#355a6b"} fontWeight={600}>
                          {agentData?.agent_name}
                        </Box>
                        <Box fontSize={14} paddingTop={1} fontWeight={600}>
                          {" "}
                          {agentData?.address?.split("]")?.join(",")}{" "}
                        </Box>
                        <Box fontSize={14} paddingTop={1} fontWeight={600}>
                          Tel: {agentData?.phone + "/" + agentData?.mobile_no}
                        </Box>
                      </Box>
                      <Box flex={1} textAlign="right">
                        <Box width={"100%"}>
                          <img
                            src={`${apiUrl}/${agentData?.logo}`}
                            style={{ width: "90%", height: 50 }}
                            alt="this is agent company logo"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}

                <Typography fontSize={14}>
                  Transactions For {voucherFilter?.voucher_no}
                </Typography>

                {voucherModel === "openVoucher" && (
                  <Box
                    display={"flex "}
                    justifyContent={"space-between"}
                    marginTop={3}
                  >
                    <Box
                      display={"flex flex-column"}
                      justifyContent={"space-between"}
                    >
                      <Typography fontSize={14}>
                        {obj[voucherFilter?.voucher_type_id]} #:{" "}
                        {voucherFilter?.voucher_no}
                      </Typography>
                      <Typography fontSize={14}>
                        Voucher Remarks : {voucherFilter?.voucher_description}
                      </Typography>
                    </Box>
                    <Typography fontSize={14}>
                      Date: #:{" "}
                      {postVoucher
                        ? moment(voucherFilter?.approved_date).format(
                            "ddd DD-MMM-YY"
                          )
                        : moment(voucherFilter?.saved_date).format(
                            "ddd DD-MMM-YY"
                          )}{" "}
                    </Typography>
                  </Box>
                )}
                {voucherModel === "openVoucher" ? (
                  <>
                    {" "}
                    {/* =======================================================RV Model========================================= */}
                    {voucherFilter?.voucher_no?.split("-")?.at(0) === "RV" && (
                      <>
                        <Box
                          display={"flex"}
                          marginTop={2}
                          alignItems={"center"}
                        >
                          <Typography width={"40%"} fontSize={14}>
                            Received with thanks from Messers:
                          </Typography>
                          <Typography
                            flex={1}
                            fontSize={14}
                            borderBottom={1}
                            marginLeft={2}
                            width={"60%"}
                          >
                            {
                              voucherFilter?.acc_voucher_trans[0]?.acc_account
                                ?.acc_title
                            }
                          </Typography>
                        </Box>

                        <Box
                          display={"flex"}
                          marginTop={2}
                          alignItems={"center"}
                        >
                          <Typography width={"40%"} fontSize={14}>
                            Sum of Amount:
                          </Typography>
                          <Typography
                            flex={1}
                            fontSize={14}
                            borderBottom={1}
                            marginLeft={2}
                            width={"60%"}
                          >
                            {toCamelCase(
                              words.toWords(
                                voucherFilter?.acc_voucher_trans[1]
                                  .d_cur_debit > 0
                                  ? voucherFilter?.acc_voucher_trans[1]
                                      ?.d_cur_debit
                                  : voucherFilter?.acc_voucher_trans[1]
                                      ?.d_cur_credit
                              )
                            )}{" "}
                          </Typography>
                        </Box>

                        {/* {voucherFilter?.acc_voucher_trans?.map((rv) => ( */}
                        <Box
                          display={"flex"}
                          marginTop={2}
                          alignItems={"center"}
                        >
                          <Typography width={"40%"} fontSize={14}>
                            by (Cash/ Cheque) No:
                          </Typography>
                          <Typography
                            flex={1}
                            fontSize={14}
                            borderBottom={1}
                            marginLeft={2}
                            width={"60%"}
                          >
                            {
                              voucherFilter?.acc_voucher_trans?.at(1)
                                ?.acc_account?.acc_title
                            }
                          </Typography>
                        </Box>
                        {/* ))} */}

                        <Box
                          display={"flex"}
                          marginTop={2}
                          alignItems={"center"}
                        >
                          <Typography width={"5%"} fontSize={14}>
                            {voucherFilter?.acc_voucher_trans?.at(1)?.cur}
                          </Typography>
                          <Typography
                            width={"20%"}
                            fontSize={14}
                            borderBottom={1}
                            marginLeft={2}
                          >
                            {voucherFilter?.acc_voucher_trans[1].d_cur_debit > 0
                              ? voucherFilter?.acc_voucher_trans?.at(1)
                                  ?.d_cur_debit /
                                ~~voucherFilter?.acc_voucher_trans?.at(1)?.roe
                              : voucherFilter?.acc_voucher_trans?.at(1)
                                  ?.d_cur_credit /
                                ~~voucherFilter?.acc_voucher_trans?.at(1)?.roe}
                          </Typography>
                        </Box>

                        <Box display="flex" marginTop={8}>
                          <Box width={"20%"}>
                            <Typography fontSize={14}>
                              {voucherFilter?.SavedByUser?.f_name +
                                " " +
                                voucherFilter?.SavedByUser?.l_name}
                            </Typography>
                            <Typography borderBottom={1}></Typography>
                            <Typography fontSize={14}>Prepared by</Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                    {/* ==========================================================PV Model==================================== */}
                    {voucherFilter?.voucher_no?.split("-")?.at(0) === "PV" && (
                      <>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          marginTop={2}
                        >
                          {/* <Typography fontSize={14}>
                            Paid to:
                            {
                              voucherFilter?.acc_voucher_trans[0]?.acc_account
                                ?.acc_title
                            }
                          </Typography> */}
                        </Box>

                        <Box border={1} marginTop={2}>
                          {voucherFilter?.acc_voucher_trans?.map((item, i) => (
                            <Typography
                              style={{ padding: "20px 3px" }}
                              fontSize={14}
                              border={1}
                            >
                              {" "}
                              {i === 0
                                ? "Paid to :" +
                                  " " +
                                  item?.acc_account?.acc_title
                                : "Paid from :" +
                                  " " +
                                  item?.acc_account?.acc_title}{" "}
                            </Typography>
                          ))}

                          <Box display={"flex"}>
                            <Typography
                              flex={1}
                              style={{ padding: "10px 10px" }}
                              borderTop={1}
                              fontSize={14}
                            >
                              {toCamelCase(
                                words.toWords(
                                  voucherFilter?.acc_voucher_trans[0]
                                    .d_cur_debit > 0
                                    ? voucherFilter?.acc_voucher_trans[0]
                                        ?.d_cur_debit
                                    : voucherFilter?.acc_voucher_trans[0]
                                        ?.d_cur_credit
                                )
                              )}
                              {" || "}
                              {toCamelCase(
                                words.toWords(
                                  voucherFilter?.acc_voucher_trans[0]
                                    .d_cur_debit > 0
                                    ? voucherFilter?.acc_voucher_trans[0]
                                        ?.s_cur_debit
                                    : voucherFilter?.acc_voucher_trans[0]
                                        ?.s_cur_credit
                                )
                              )}
                            </Typography>
                            <Typography
                              width="20%"
                              style={{ padding: "10px 10px" }}
                              borderTop={1}
                              fontSize={14}
                              borderLeft={1}
                            >
                              {"PKR:"}
                              {voucherFilter?.acc_voucher_trans[0].d_cur_debit >
                              0
                                ? voucherFilter?.acc_voucher_trans[0]
                                    ?.d_cur_debit +
                                  " || " +
                                  voucherFilter?.acc_voucher_trans[0]?.cur +
                                  ":" +
                                  voucherFilter?.acc_voucher_trans[0]
                                    ?.s_cur_debit
                                : voucherFilter?.acc_voucher_trans[0]
                                    ?.d_cur_credit +
                                  " || " +
                                  voucherFilter?.acc_voucher_trans[0]?.cur +
                                  ":" +
                                  voucherFilter?.acc_voucher_trans[0]
                                    ?.s_cur_credit}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          display="flex"
                          marginTop={8}
                          gap={6}
                          alignItems={"center"}
                        >
                          <Box flex={1}>
                            <Typography fontSize={14}>
                              {voucherFilter?.SavedByUser?.f_name +
                                " " +
                                voucherFilter?.SavedByUser?.l_name}
                            </Typography>
                            <Typography borderBottom={1}></Typography>
                            <Typography fontSize={14}>Prepared by</Typography>
                          </Box>

                          <Box flex={1}>
                            <Typography borderBottom={1}></Typography>
                            <Typography fontSize={14}>Accountant</Typography>
                          </Box>

                          <Box flex={1}>
                            <Typography borderBottom={1}></Typography>
                            <Typography fontSize={14}>Director</Typography>
                          </Box>

                          <Box flex={1}>
                            <Typography borderBottom={1}></Typography>
                            <Typography fontSize={14}>Received by</Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                    {/* ==============================================================JV Model======================================== */}
                    <Box display="flex" marginTop={4} justifyContent={"right"}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#dc3545", color: "#fff" }}
                        onClick={() => setVoucherModel("")}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#355a6b",
                          color: "#fff",
                          marginLeft: 15,
                        }}
                      >
                        Print
                      </Button>
                    </Box>
                  </>
                ) : (
                  (console.log("voucherFilter", voucherFilter),
                  (
                    <Box marginTop={3}>
                      {/* ======================================================= All Trans Table Records for all voucher========================================= */}

                      <TableContainer>
                        <Table sx={{ minWidth: 650 }}>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                sx={{
                                  border: "1px solid lightgray",
                                  fontWeight: "bold",
                                  padding: 1,
                                  fontSize: 14,
                                }}
                              >
                                Account Code
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid lightgray",
                                  fontWeight: "bold",
                                  padding: 1,
                                  fontSize: 14,
                                }}
                              >
                                Description
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid lightgray",
                                  fontWeight: "bold",
                                  padding: 1,
                                  fontSize: 14,
                                }}
                              >
                                ROE
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid lightgray",
                                  fontWeight: "bold",
                                  padding: 1,
                                  fontSize: 14,
                                }}
                              >
                                {voucherFilter?.voucher_type_id === 1
                                  ? ""
                                  : voucherFilter?.acc_voucher_trans?.at(0)
                                      ?.cur}{" "}
                                Debit
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid lightgray",
                                  fontWeight: "bold",
                                  padding: 1,
                                  fontSize: 14,
                                }}
                              >
                                {voucherFilter?.voucher_type_id === 1
                                  ? ""
                                  : voucherFilter?.acc_voucher_trans?.at(0)
                                      ?.cur}{" "}
                                Credit
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid lightgray",
                                  fontWeight: "bold",
                                  padding: 1,
                                  fontSize: 14,
                                }}
                              >
                                PKR Debit
                              </TableCell>
                              <TableCell
                                sx={{
                                  border: "1px solid lightgray",
                                  fontWeight: "bold",
                                  padding: 1,
                                  fontSize: 14,
                                }}
                              >
                                PKR Credit
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {voucherFilter?.acc_voucher_trans?.map((trans) => (
                              <TableRow key={trans.id}>
                                <TableCell
                                  sx={{
                                    border: "1px solid lightgray",
                                    padding: 1,
                                  }}
                                >
                                  {trans.acc_account?.acc_title}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px solid lightgray",
                                    padding: 1,
                                  }}
                                >
                                  {trans?.trans_description}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px solid lightgray",
                                    padding: 1,
                                  }}
                                >
                                  {trans.roe}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px solid lightgray",
                                    padding: 1,
                                  }}
                                >
                                  {trans?.cur === "PKR" ? 0 : trans.s_cur_debit}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px solid lightgray",
                                    padding: 1,
                                  }}
                                >
                                  {trans?.cur === "PKR"
                                    ? 0
                                    : trans.s_cur_credit}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px solid lightgray",
                                    padding: 1,
                                  }}
                                >
                                  {trans.d_cur_debit}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    border: "1px solid lightgray",
                                    padding: 1,
                                  }}
                                >
                                  {trans.d_cur_credit}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ))
                )}
              </Box>
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default SaveVoucher;
