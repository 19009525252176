import {
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  TextField,
  Box,
  Input,
} from "@mui/material";
import { ErrorMessage, Form, Formik, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetChequeData } from "../../../redux/features/Payments/MakePaymentsSlice";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import "../../../Styles/setup.css";
import BtnLoader from "../../AppForm/BtnLoader";
import {
  asyncGetCollectionData,
  asyncPostAgentsPayment,
} from "../../../redux/features/Payments/PaymentsAllSlices";

const AgentPaymentModel = ({
  show,
  setShow,
  agentData,
  Apidata,
  foundAgent,
  Cur_code,
  Agent_Acc_Id,
  agentValues,
}) => {
  const dispatch = useDispatch();
  const [fileError, setFileError] = useState("");
  const [loader, setLoader] = useState(false);
  const [receipt, setReceipt] = useState("");
  const [receive, setReceive] = useState(false);

  const navigate = useNavigate();

  const { collectionData } = useSelector((state) => state.allPayments);

  useEffect(() => {
    dispatch(asyncGetCollectionData());
  }, []);

  const validationSchema = yup.object().shape({
    bank_Id: yup.string().when("paymentType", {
      is: (paymentType) => ["Cheque", "IBFT"].includes(paymentType),
      then: yup.string().required("Please Select Bank"),
    }),
    receiptNumber: yup.string().when("paymentType", {
      is: "Cash",
      then: yup.string().required("Bank Receipt Required"),
    }),
  });

  const initialValues = {
    agentName: agentData?.agentName,
    agent_ID: agentData?.id || Agent_Acc_Id,
    amount: Number(agentData?.amount),
    bank_Id: "",
    bankLabel: "",
    receiptNumber: "",
    receiptimage: "",
    paymentType: "Cheque",
  };

  const handleClose = () => setShow(!show);

  const handleSubmit = (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("agent_acc_id", values.agent_ID);
    formData.append("amount", values.amount);
    formData.append("bank_cash_accid", values.bank_Id);
    formData.append("receipt_no", values.receiptNumber);
    formData.append("image", values?.receiptimage);
    formData.append("pay_option", values?.paymentType);

    setLoader(true);
    dispatch(asyncPostAgentsPayment(formData));

    setTimeout(() => {
      setLoader(false);
      resetForm();
      setReceive(true);
    }, 1000);

    setTimeout(() => {
      setShow(false);
      navigate("/");
    }, 3000);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={"modal-xl"}
        keyboard={false}
        centered
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Agent Payment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex flex-column">
                  <section className="row">
                    <div className="col-sm-4 px-3">
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Agent Name *"
                        variant="standard"
                        className="col-12"
                        name="agentName"
                        value={values?.agentName}
                      />
                    </div>
                    <div className="col-sm-4 px-3">
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        label={`Amount In ${
                          foundAgent?.currency?.curr_code || Cur_code
                        }`}
                        variant="standard"
                        className="col-12"
                        name="amount"
                        value={values?.amount}
                      />
                    </div>
                  </section>
                  <section className="row justify-content-between mt-5">
                    <div className="col-sm-4">
                      <FormControl variant="standard" className="w-100 mt-3">
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Bank
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={values.bankLabel}
                          readOnly={values?.paymentType === "Cash"}
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue("bank_Id", Number(value.acc_id));
                            setFieldValue("bankLabel", value);
                          }}
                        >
                          {Apidata?.map((item) => {
                            return (
                              <MenuItem value={item} key={item?.id}>
                                {`${item?.name} - ${item?.account_iban_no}`}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <ErrorMessage
                        name="bank_Id"
                        component={"div"}
                        className="text-danger mt-1"
                      />
                    </div>
                    <div className="col-sm-4">
                      <TextField
                        variant="standard"
                        label="Bank Receipt Number "
                        type="text"
                        name="receiptNumber"
                        value={values.receiptNumber}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase();
                          const isReceipt = collectionData?.find(
                            (it) =>
                              it?.receipt_no &&
                              it?.receipt_no.toUpperCase() ===
                                value.toUpperCase()
                          );
                          if (isReceipt) {
                            setFieldValue("receiptNumber", value);
                            setReceipt("This Receipt Number Already Exist");
                          } else {
                            setReceipt("");
                            setFieldValue("receiptNumber", value);
                          }
                        }}
                        className=" inputs_visa_tab mt-3"
                      />
                      {receipt && (
                        <div className="text-danger mt-1">{receipt}</div>
                      )}
                      <ErrorMessage
                        name="receiptNumber"
                        component={"div"}
                        className="text-danger mt-1"
                      />
                    </div>
                    <div className="col-sm-4">
                      <InputLabel htmlFor="receiptimage" className="mt-2">
                        Bank receipt Image *
                      </InputLabel>
                      <TextField
                        variant="standard"
                        id="receiptimage"
                        name="receiptimage"
                        type="file"
                        onChange={(e) => {
                          const image = e.target.files[0];
                          const allowedFileTypes = [
                            "image/jpeg",
                            "image/png",
                            "application/pdf",
                          ];
                          if (
                            image &&
                            allowedFileTypes.indexOf(image.type) === -1
                          ) {
                            setFileError("Please Enter PNG, JPG, or PDF file.");
                            setFieldValue("receiptimage", null);
                          } else {
                            setFileError("");
                            setFieldValue("receiptimage", image);
                          }

                          setFieldValue("receiptimage", image);
                        }}
                        className="inputs_visa_tab"
                        placeholder=""
                      />
                      <div className="text-danger mt-1">
                        {fileError && fileError}
                      </div>
                    </div>
                  </section>
                  <div className="col-sm-5 d-flex justify-content-between mt-5 pt-2">
                    <label class="form-control">
                      <input
                        type="radio"
                        id="Cheque"
                        name="paymentType"
                        checked={values?.paymentType === "Cheque"}
                        onChange={(e) => {
                          setFieldValue("paymentType", "Cheque");
                        }}
                      />
                      <span className="h6">Cheque</span>
                    </label>

                    <label class="form-control">
                      <input
                        type="radio"
                        name="paymentType"
                        checked={values?.paymentType === "IBFT"}
                        onChange={(e) => {
                          setFieldValue("paymentType", "IBFT");
                        }}
                      />
                      <span className="h6">IBFT</span>
                    </label>
                    <label class="form-control">
                      <input
                        type="radio"
                        name="paymentType"
                        checked={values?.paymentType === "Cash"}
                        onChange={(e) => {
                          setFieldValue("paymentType", "Cash");
                          setFieldValue("bank_Id", "");
                          setFieldValue("bankLabel", "");
                        }}
                      />
                      <span className="h6">Cash</span>
                    </label>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose} disabled={receive === true}>
                  cancel
                </Button>
                <button className="modelbtn" type="submit" disabled={receive === true}>
                  {loader ? <BtnLoader /> : "Save"}
                </button>
              </Modal.Footer>

              {receive && (
                <Box display={"flex"} p={2}>
                  <Box flex={1}></Box>
                  <Box
                    flex={1}
                    fontSize={16}
                    lineHeight={1.2}
                    className="theme-text-color"
                    fontWeight={500}
                  >
                    We receive your collection. Kindly wait while our Finance
                    will confirm in your account. Once the payment is confirm
                    Receipt will added in your ledger.
                  </Box>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default AgentPaymentModel;
