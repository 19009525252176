import React, { useEffect } from "react";
import { Form, Formik, ErrorMessage } from "formik";
import { TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  asyncGetVisaCountry,
  asyncPostVisaCountry,
  asyncUpdateCountryList,
  asyncgetSingleCountryList,
  clearVisaCountryList,
} from "../../../redux/features/visa/CountrySlice";
import BtnLoader from "../../AppForm/BtnLoader";
import { options } from "../../../Utils/ColumnOptions";

const Countries = () => {
  const dispatch = useDispatch();

  const { visaCountry, loading, visaCountryEdit } = useSelector(
    (state) => state.visaCountry
  );

  useEffect(() => {
    dispatch(asyncGetVisaCountry());
  }, [visaCountryEdit]);

  const initialValues = {
    name: visaCountryEdit?.name || "",
    code: visaCountryEdit?.code || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Country Name is required"),
    code: Yup.string().required("Country Code is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    if (visaCountryEdit) {
      const updateobj = {
        id: visaCountryEdit?.id,
        name: values?.name,
        code: values?.code,
      };
      dispatch(asyncUpdateCountryList(updateobj));
      dispatch(clearVisaCountryList());
    } else {
      dispatch(asyncPostVisaCountry(values));
      resetForm();
    }
  };

  const columns = [
    {
      label: "Id",
      name: "id",
      options: {
        display: false,
      },
    },
    {
      label: "Country Name",
      name: "name",
    },
    {
      label: "Country Code",
      name: "code",
    },

    {
      label: "Action",
      name: "edit",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <div>
              <button
                className="edit-delete-icon-button btn-success "
                onClick={() => {
                  window.scroll({ top: 0, behavior: "smooth" });
                  dispatch(asyncgetSingleCountryList(id));
                }}
              >
                <i className="fa fa-thin fa-pencil" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    },
  ];

  return (
    <div className="col-12 col-sm-6 mx-auto rounded-2 shadow my-5 pb-5 flash-news">
      <p className="h4 otawix-heading">Visa Countries</p>
      <div className="col-12 px-4 d-flex flex-column justify-content-center">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className="col-12 my-4 py-3 d-flex justify-content-around">
                  <div className="w-100 mx-3">
                    <TextField
                      id="standard-basic"
                      label="Country Name"
                      variant="standard"
                      className="w-100"
                      inputProps={{ maxLength: 50 }}
                      name="name"
                      value={values?.name}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("name", value.toUpperCase());
                      }}
                    />
                    <ErrorMessage component="div" name="name" className="text-danger mt-2"/>
                  </div>
                  <div className="w-100 mx-3" >
                    <TextField
                      id="standard-basic"
                      label="Country Code"
                      variant="standard"
                      className="w-100"
                      inputProps={{ maxLength: 3 }}
                      name="code"
                      value={values?.code}
                      onChange={(e) => {
                        const value = e.target.value;
                        setFieldValue("code", value.toUpperCase());
                      }}
                    />
                    <ErrorMessage component="div" name="code" className="text-danger mt-2"/>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  <button
                    className="btn setup-btn text-align: center"
                    type="submit"
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <BtnLoader />
                    ) : visaCountryEdit ? (
                      "Update"
                    ) : (
                      "save"
                    )}
                  </button>
                  {visaCountryEdit && (
                  <button
                    className="btn bg-danger text-white mx-auto ms-5"
                    onClick={(e) => dispatch(clearVisaCountryList())}
                    style={{
                      paddingLeft: 40,
                      paddingRight: 40,
                      paddingTop: 11,
                      paddingBottom: 11,
                    }}
                  >
                    Cancel
                  </button>
                )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="col-12 mt-5 px-4">
        <MUIDataTable
          className="muidatatable"
          title={"Countries"}
          data={visaCountry}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );
};

export default Countries;
