import { TextField } from "@mui/material";
import React from "react";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

function SupplierCredit({ setFieldValue, values, friend, index, auto_manual }) {
  return (
    <div className="col-6">
      <div className="row shadow-sm rounded pb-3 red_border me-1">
        <div className="h5 py-3">Supplier In {friend?.currency?.curr_code}</div>
        <div className="col-3">
          <TextField
            value={friend.tax_cur.s_psf_per}
            id={`fareValues.[${index}].tax_cur.s_psf_per`}
            onChange={(e) => {
              const value = e.target.value;
              const mul_base = (values.fareValues[index].b_fare / 100) * value;
              setFieldValue(`fareValues.[${index}].tax_cur.s_psf_per`, value);
              setFieldValue(
                `fareValues.[${index}].tax_cur.s_psf`,
                TwoDecimalPoint(mul_base)
              );
              setFieldValue(`fareValues.[${index}].tax_pkr.s_psf_per`, value);
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_psf`,
                TwoDecimalPoint(mul_base * friend.roe)
              );
              setFieldValue(
                `fareValues.[${index}].tax_cur.s_gst`,
                (value * friend.tax_cur.s_gst_per) / 100
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_gst`,
                ((value * friend.tax_cur.s_gst_per) / 100) * friend.roe
              );
            }}
            label="S.PSF %"
            variant="standard"
            type={"number"}
            // disabled={friend.tax_cur.s_sp_per > 0 || auto_manual === 0}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_cur.s_psf}
            id={`fareValues.[${index}].tax_cur.s_psf`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.s_psf`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.s_psf`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.s_psf`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.s_psf`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="S.PSF"
            variant="standard"
            type={"number"}
            // disabled={
            //   friend.tax_cur.s_sp > 0 ||
            //   friend.tax_cur.s_psf_per > 0 ||
            //   auto_manual === 0
            // }
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_cur.s_gst_per}
            id={`fareValues.[${index}].tax_cur.s_gst_per`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.s_gst_per`, "");

                setFieldValue(`fareValues.[${index}].tax_cur.s_gst`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.s_gst_per`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.s_gst`, "");
              } else {
                const mul_base =
                  (values.fareValues[index].tax_cur.s_psf / 100) * value;

                setFieldValue(`fareValues.[${index}].tax_cur.s_gst_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_cur.s_gst`,
                  TwoDecimalPoint(mul_base)
                );

                setFieldValue(`fareValues.[${index}].tax_pkr.s_gst_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_pkr.s_gst`,
                  TwoDecimalPoint(mul_base * values.fareValues[0].roe)
                );
              }
            }}
            label="S.GST %"
            variant="standard"
            type={"number"}
            // disabled={friend.tax_cur.s_sp_per > 0 || auto_manual === 0}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_cur.s_gst}
            id={`fareValues.[${index}].tax_cur.s_gst`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.s_gst`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.s_gst`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.s_gst`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.s_gst`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="S.GST"
            variant="standard"
            type={"number"}
            // disabled={
            //   friend.tax_cur.s_sp > 0 ||
            //   friend.tax_cur.s_gst_per > 0 ||
            //   auto_manual === 0
            // }
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_cur.s_sp_per}
            id={`fareValues.[${index}].tax_cur.s_sp_per`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.s_sp_per`, "");

                setFieldValue(`fareValues.[${index}].tax_cur.s_sp`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.s_sp_per`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.s_sp`, "");
              } else {
                const mul_base =
                  (values.fareValues[index].b_fare / 100) * value;

                setFieldValue(`fareValues.[${index}].tax_cur.s_sp_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_cur.s_sp`,
                  TwoDecimalPoint(mul_base)
                );

                setFieldValue(`fareValues.[${index}].tax_pkr.s_sp_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_pkr.s_sp`,
                  TwoDecimalPoint(mul_base * values.fareValues[0].roe)
                );
              }
              setFieldValue(
                `fareValues.[${index}].tax_cur.s_wht`,
                (value * values.fareValues[index].tax_cur.s_wht_per) / 100
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.s_wht`,
                ((value * values.fareValues[index].tax_cur.s_wht_per) / 100) *
                  values.fareValues[0].roe
              );
            }}
            label="S.DISC %"
            variant="standard"
            type={"number"}
            // disabled={friend.tax_cur.s_psf_per > 0 || auto_manual === 0}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_cur.s_sp}
            id={`fareValues.[${index}].tax_cur.s_sp`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.s_sp`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.s_sp`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.s_sp`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.s_sp`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="S.DISC"
            variant="standard"
            type={"number"}
            // disabled={
            //   friend.tax_cur.s_psf > 0 ||
            //   friend.tax_cur.s_sp_per > 0 ||
            //   auto_manual === 0
            // }
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_cur.s_wht_per}
            id={`fareValues.[${index}].tax_cur.s_wht_per`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.s_wht_per`, "");

                setFieldValue(`fareValues.[${index}].tax_cur.s_wht`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.s_wht_per`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.s_wht`, "");
              } else {
                const mul_base =
                  (values.fareValues[index].tax_cur.s_sp / 100) * value;

                setFieldValue(`fareValues.[${index}].tax_cur.s_wht_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_cur.s_wht`,
                  TwoDecimalPoint(mul_base)
                );

                setFieldValue(`fareValues.[${index}].tax_pkr.s_wht_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_pkr.s_wht`,
                  TwoDecimalPoint(mul_base * values.fareValues[0].roe)
                );
              }
            }}
            label="S.WHT %"
            variant="standard"
            type={"number"}
            // disabled={friend.tax_cur.s_psf_per > 0 || auto_manual === 0}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_cur.s_wht}
            id={`fareValues.[${index}].tax_cur.s_wht`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.s_wht`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.s_wht`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.s_wht`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.s_wht`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="S.WHT"
            variant="standard"
            type={"number"}
            // disabled={
            //   friend.tax_cur.s_psf > 0 ||
            //   friend.tax_cur.s_wht_per > 0 ||
            //   auto_manual === 0
            // }
          />
        </div>
      </div>
    </div>
  );
}

export default SupplierCredit;
