import React from "react";
import { useSelector } from "react-redux";
import { TwoDecimalPoint } from "../../../../Utils/FixedTwoDecimal";

function Totals({ data }) {
  const { newAgencyRegData } = useSelector((state) => state.newAgency);
  const { agentGroup } = useSelector((state) => state.agentsCategories);

const agentIds = data?.values?.currencyDetail
  ?.flatMap((flt) => {
    const matchingItem = newAgencyRegData?.find(
      (newItem) =>
        newItem?.acc_id === Number(flt?.account_no?.split("-")?.at(0))
    );
    return matchingItem?.acc_id;
  })
  .filter((id) => id !== undefined);

  const company = newAgencyRegData?.find(
    (item) => item.acc_id === agentIds?.at(0)
  );
  //agent group
  const agent_grp = agentGroup?.find(
    (item) => item.agent_grp_id === company?.agent_grp_id
  );

  return (
    <div>
      <div className="row">
        <div className="col-7"></div>
        <div className="col-1">
          <h6>
            {data?.values?.currencyDetail?.at(0)?.currency?.curr_code || "PKR"}{" "}
            {data?.totalDebit?.toFixed(2)}
          </h6>
        </div>
        <div className="col-1">
          <h6>
            {data?.values?.currencyDetail?.at(0)?.currency?.curr_code || "PKR"}{" "}
            {data?.totalCredit?.toFixed(2)}
          </h6>
        </div>
        <div className="col-1">
          <h6>PKR {data?.totalDebitor?.toFixed(2)}</h6>
        </div>
        <div className="col-1">
          <h6>PKR {data?.totalCreditor?.toFixed(2)}</h6>
        </div>
      </div>
      <hr className="my-4" />

      <div className="py-2 d-flex justify-content-center gap-4 align-items-start">
        <div className="shadow-sm rounded-2 border px-4 py-3">
          <h4 className="color_primary mb-4">Currency in PKR</h4>
          {data?.values?.currencyDetail?.map((item, i) => {
            return (
              <div className="h6 mb-3 d-flex justify-content-between" key={i}>
                <div className="me-4">{item.account_no}</div>
                {data?.values?.voucherType === "Payment Voucher" ||
                data?.values?.voucherType === "Reciept Voucher" ? (
                  i === 0 ? (
                    <div>
                      PKR <span className="text-primary">(Cr)</span> ={" "}
                      {item?.creditor || "0.00"}
                    </div>
                  ) : (
                    <div>
                      PKR <span className="text-primary">(Dr)</span> ={" "}
                      {item?.debitor || "0.00"}
                    </div>
                  )
                ) : (
                  <div>
                    PKR{" "}
                    {item?.currency_debit || item?.debitor
                      ? `(Dr) = ${~~item.debitor}`
                      : item?.currency_credit || item?.creditor
                      ? `(Cr) = ${~~item.creditor}`
                      : "= 0.00"}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {/* currency selected agency */}
        {
          data?.values?.currencyDetail?.at(0)?.curr_name !== "PKR" &&
          !company?.acc_id && (
            <div className="shadow-sm rounded-2 border px-4 py-3">
              <h4 className="color_primary mb-4">
                Currency in {data?.values?.currencyDetail?.at(0)?.curr_name}
              </h4>

              {data?.values?.currencyDetail?.map((item, i) => {
                console.log("item",item);
                return (
                  <div
                    className="h6 mb-3 d-flex justify-content-between"
                    key={i}
                  >
                    <div className="me-4">{item.account_no}</div>
                    {data?.values?.voucherType === "Payment Voucher" ||
                    data?.values?.voucherType === "Reciept Voucher" ? (
                      i === 0 ? (
                        <div>
                          {item.currency?.curr_code}{" "}
                          <span className="text-primary">(Cr)</span> ={" "}
                          {item?.currency_credit || "0.00"}
                        </div>
                      ) : (
                        <div>
                          {item.currency?.curr_code}{" "}
                          <span className="text-primary">(Dr)</span> ={" "}
                          {item?.currency_debit || "0.00"}
                        </div>
                      )
                    ) : (
                      <div>
                        {item.currency?.curr_code || "PKR"}{" "}
                        {item?.currency_debit
                          ? `(Dr) = ${~~item.currency_debit}`
                          : item?.currency_credit
                          ? `(Cr) = ${~~item.currency_credit}`
                          : "= 0.00"}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        {/* Currency assign agent */}
        {company && agent_grp?.currency?.curr_code !== "PKR" ? (
          <div className="shadow-sm rounded-2 blue_border px-4 py-3">
            <h4 className="color_primary mb-4">
              Currency in{" "}
              <span className="text-primary">
                {agent_grp?.currency?.curr_code}
              </span>
            </h4>

            {data?.values?.currencyDetail?.map((item, i) => {
              return (
                <div className="h6 mb-3 d-flex justify-content-between" key={i}>
                  <div className="me-4">{item.account_no}</div>
                  {i === 0 ? (
                    <div>
                      {agent_grp?.currency?.curr_code}{" "}
                      <span className="text-primary">
                        ({item?.debitor ? "Dr" : "Cr"})
                      </span>{" "}
                      ={" "}
                      {TwoDecimalPoint(
                        item?.creditor / Number(agent_grp?.currency?.roe) ||
                          item?.debitor / Number(agent_grp?.currency?.roe)
                      )}
                    </div>
                  ) : (
                    <div>
                      {agent_grp?.currency?.curr_code}{" "}
                      <span className="text-primary">
                        {" "}
                        {data?.values?.currencyDetail?.at(1)?.creditor
                          ? "(Cr)"
                          : data?.values?.currencyDetail?.at(1)?.debitor
                          ? "(Dr)"
                          : null}
                      </span>{" "}
                      ={" "}
                      {TwoDecimalPoint(
                        data?.values?.currencyDetail?.at(1)?.creditor /
                          Number(agent_grp?.currency?.roe) ||
                          data?.values?.currencyDetail?.at(1)?.debitor /
                            Number(agent_grp?.currency?.roe) ||
                          0
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Totals;
