import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useState } from "react";
import SupplierListPdf from "../../Pdf/SupplierListPdf";
// import PrintData from "./PrintData";
import FlightPdf from "../../Pdf/FlightPdf";
import { useDispatch, useSelector } from "react-redux";
import {
  asyncGetAllSupplierByStatus,
  setSupplierPaybleComponent,
} from "../../../redux/features/supplier/AddNewSupplier";
import BtnLoader from "../../AppForm/BtnLoader";

const SupplierListComp = () => {
  const { getAllSupplier, supplierPayble } = useSelector(
    (state) => state.supplier
  );
  console.log("getAllSupplier", getAllSupplier);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("Active");
  const arry = ["Active", "Inactive"];
  const [message, setMessage] = useState(true);
  console.log("supplierPayble", supplierPayble);
  return (
    <div>
      {supplierPayble === "form" && (
        <div className="manual_invoices mx-auto mt-5">
          <div className="manual_invoices_header px-md-5">
            <div className="w-100 text-center">Supplier Reports</div>
          </div>
          <div className="px-5 py-4">
            <div className="pt-5 ">
              <FormControl variant="standard" className="w-100" sx={{}}>
                <InputLabel id="demo-simple-select-standard-label">
                  select status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={status}
                  onChange={(e) => {
                    let value = e.target.value;
                    setStatus(value);
                  }}
                  label="select status"
                >
                  {arry.map((list) => (
                    <MenuItem
                      value={list}
                      sx={{ m: 1, bgcolor: "#fff" }}
                      key={list}
                    >
                      {list}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="container py-4">
              {message ? (
                <div className="text-center py-4">
                  <button
                    className="button_style px-4"
                    onClick={(e) => {
                      setMessage(false);
                      setTimeout(() => {
                        dispatch(
                          asyncGetAllSupplierByStatus({
                            id: status === "Active" ? 1 : 0,
                          })
                        );
                        dispatch(setSupplierPaybleComponent("supplierData"));
                        setMessage(true);
                      }, 3000);

                      console.log("status", status);
                    }}
                  >
                    Generate Report
                  </button>
                </div>
              ) : (
                <div className="text-center py-4">
                  <button className="button_style px-4">
                    <BtnLoader />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {supplierPayble === "supplierData" && (
        <SupplierListPdf data={getAllSupplier} payble="form" />
      )}
    </div>
  );
};

export default SupplierListComp;
