import {
    FaCheckDouble,
    FaChevronCircleLeft,
    FaChevronCircleRight,
    FaMusic,
    FaPlaneSlash,
    FaRegArrowAltCircleUp,
    FaSuitcase,
    FaSuitcaseRolling,
    FaUtensils,
  } from "react-icons/fa";
  import { GiHotMeal, GiWeightScale } from "react-icons/gi";
  import {
    MdChair,
    MdFreeBreakfast,
    MdOnlinePrediction,
    MdOutlineAirlineSeatReclineNormal,
    MdOutlineGolfCourse,
    MdOutlineOndemandVideo,
  } from "react-icons/md";
  import { ImNewspaper } from "react-icons/im";
  import { BiEdit } from "react-icons/bi";
import { BsExclamationCircleFill } from "react-icons/bs";

export const iconMapping = {
    // suitcase rolling checked icons
    "0C4": <FaSuitcase size={18} />,
    "0C5": <FaSuitcase size={18} />,
    "0C7": <FaSuitcase size={18} />,
    "0C8": <FaSuitcase size={18} />,
    P05: <FaSuitcase size={18} />,
    BAG: <FaSuitcase size={18} />,
    P01: <FaSuitcase size={18} />,
    A30: <FaSuitcase size={18} />,
    A35: <FaSuitcase size={18} />,
    A40: <FaSuitcase size={18} />,
    A50: <FaSuitcase size={18} />,
    "0GO": <FaSuitcase size={18} />,
    "0FA": <FaSuitcase size={18} />,
    "0FM": <FaSuitcase size={18} />,
    FBY: <FaSuitcase size={18} />,
    FBC: <FaSuitcase size={18} />,
    "0LF": <FaSuitcase size={18} />,
    "0C3": <FaSuitcase size={18} />,
    "0C6": <FaSuitcase size={18} />,
    "0I7": <FaSuitcase size={18} />,
    "0FK": <FaSuitcase size={18} />,
    FBP: <FaSuitcase size={18} />,
    "0CC": <FaSuitcase size={18} />,
    "0CD": <FaSuitcase size={18} />,
    "0CE": <FaSuitcase size={18} />,
    P23: <FaSuitcase size={18} />,
    "04J": <FaSuitcase size={18} />,
    "0GP": <FaSuitcase size={18} />,
    "0E9": <FaSuitcase size={18} />,
    "0CH": <FaSuitcase size={18} />,

    // suitcase  cabin icons
    CB1: <FaSuitcaseRolling size={21} />,
    CB0: <FaSuitcaseRolling size={21} />,
    "0M3": <FaSuitcaseRolling size={21} />,
    C01: <FaSuitcaseRolling size={21} />,
    CB2: <FaSuitcaseRolling size={21} />,
    "09Q": <FaSuitcaseRolling size={21} />,
    "09B": <FaSuitcaseRolling size={21} />,
    "010": <FaSuitcaseRolling size={21} />,
    "0L5": <FaSuitcaseRolling size={21} />,
    "0MZ": <FaSuitcaseRolling size={21} />,
    "01R": <FaSuitcaseRolling size={21} />,
    "09P": <FaSuitcaseRolling size={21} />,
    "0MR": <FaSuitcaseRolling size={21} />,
    "0MP": <FaSuitcaseRolling size={21} />,
    "0MS": <FaSuitcaseRolling size={21} />,
    "0MM": <FaSuitcaseRolling size={21} />,
    "0MU": <FaSuitcaseRolling size={21} />,
    MCE: <FaSuitcaseRolling size={21} />,
    "0MO": <FaSuitcaseRolling size={21} />,
    "0KX": <FaSuitcaseRolling size={21} />,
    "0MJ": <FaSuitcaseRolling size={21} />,

    // exclamation icons
    "06B": <BsExclamationCircleFill size={19} />,
    "06C": <BsExclamationCircleFill size={19} />,
    "06D": <BsExclamationCircleFill size={19} />,
    "06G": <BsExclamationCircleFill size={19} />,
    "06R": <BsExclamationCircleFill size={19} />,
    "06N": <BsExclamationCircleFill size={19} />,
    M02: <BsExclamationCircleFill size={19} />,
    M06: <BsExclamationCircleFill size={19} />,
    M07: <BsExclamationCircleFill size={19} />,
    "06M": <BsExclamationCircleFill size={19} />,
    "057": <BsExclamationCircleFill size={19} />,
    "06H": <BsExclamationCircleFill size={19} />,
    "06A": <BsExclamationCircleFill size={19} />,
    "06P": <BsExclamationCircleFill size={19} />,
    "09E": <BsExclamationCircleFill size={19} />,
    "09D": <BsExclamationCircleFill size={19} />,
    "05V": <BsExclamationCircleFill size={19} />,
    // meal icon
    "0B3": <FaUtensils size={20} />,
    "0AK": <FaUtensils size={20} />,
    "0LO": <GiHotMeal size={20} />,
    "019": <GiHotMeal size={20} />,
    "018": <GiHotMeal size={20} />,
    // seat icons
    "0B5": <MdOutlineAirlineSeatReclineNormal size={23} />,
    "050": <MdOutlineAirlineSeatReclineNormal size={23} />,
    PZS: <MdOutlineAirlineSeatReclineNormal size={23} />,
    PRS: <MdOutlineAirlineSeatReclineNormal size={23} />,
    LFS: <MdOutlineAirlineSeatReclineNormal size={23} />,
    // cancel seat icon
    "056": <FaPlaneSlash size={22} />,
    "06K": <FaPlaneSlash size={22} />,
    "06L": <FaPlaneSlash size={22} />,
    // change icon
    "059": <BiEdit size={22} />,
    "055": <BiEdit size={22} />,
    "06I": <BiEdit size={22} />,
    "06J": <BiEdit size={22} />,
    // upgrade icon
    "058": <FaRegArrowAltCircleUp size={22} />,
    UYC: <FaRegArrowAltCircleUp size={22} />,
    UCF: <FaRegArrowAltCircleUp size={22} />,
    "0NI": <FaRegArrowAltCircleUp size={22} />,
    // lounge icon
    "0BX": <MdChair size={22} />,
    // online icon
    CNR: <MdOnlinePrediction size={22} />,
    "0CL": <MdOnlinePrediction size={22} />,
    // check icon
    "03P": <FaCheckDouble size={19} />,
    "0G6": <FaCheckDouble size={19} />,
    "0NN": <FaCheckDouble size={19} />,
    "05Z": <FaCheckDouble size={19} />,
    SA4: <FaCheckDouble size={19} />,
    "06F": <FaCheckDouble size={19} />,
    "0BN": <FaCheckDouble size={19} />,
    "029": <FaCheckDouble size={19} />,
    "0LW": <FaCheckDouble size={19} />,
    PSB: <FaCheckDouble size={19} />,
    "0AX": <FaCheckDouble size={19} />,
    "07G": <FaCheckDouble size={19} />,
    "0BV": <FaCheckDouble size={19} />,
    "0AV": <FaCheckDouble size={19} />,
    PSA: <FaCheckDouble size={19} />,
    SBO: <FaCheckDouble size={19} />,
    "08S": <FaCheckDouble size={19} />,
    FSC: <FaCheckDouble size={19} />,
    // golf icon
    "0D4": <MdOutlineGolfCourse size={22} />,
    // weight icon
    "0H6": <GiWeightScale size={20} />,
    "0B1": <FaMusic size={18} />,
    // breakfast icon
    "0AT": <MdFreeBreakfast size={22} />,
    // newspaper icon
    "0PN": <ImNewspaper size={20} />,
    //video icon
    "032": <MdOutlineOndemandVideo size={20} />,
  };