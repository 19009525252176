import React, { useState } from "react";
import CustomDropdown from "../../CustomDrp";
import { TextField, Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const PassangerInfo = ({ values, setFieldValue, FieldArray }) => {
  const arry = ["Adult", "Child", "Infant"];
  const paxTitle = ["MR", "MS", "MRS", "MISS", "MSTR", "INFT"];
  return (
    <FieldArray
      name="PassangerInfo"
      render={(arrayHelpers) => (
        <div>
          {values?.PassangerInfo && values?.PassangerInfo?.length > 0
            ? values?.PassangerInfo?.map((paxInfo, index) => (
                <Box key={index} mt={5}>
                  <Box display="flex" alignItems="flex-end" gap={3}>
                    <Box flex="1">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel id="demo-simple-select-standard-label">
                          Select Title
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id={`PassangerInfo${index}.pax_title`}
                          name={`PassangerInfo${index}.pax_title`}
                          value={values?.PassangerInfo[index]?.pax_title}
                          onChange={(e) =>
                            setFieldValue(
                              `PassangerInfo[${index}].pax_title`,
                              e.target.value
                            )
                          }
                          label="Select Title"
                        >
                          {paxTitle.map((list) => (
                            <MenuItem
                              value={list}
                              sx={{ m: 1, bgcolor: "#fff" }}
                              key={list}
                            >
                              {list}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {/* <Box flex="1">
                      <FormControl variant="standard" className="w-100">
                        <InputLabel
                          id={`demo-simple-select-standard-label ${index}`}
                        >
                          Select Type
                        </InputLabel>
                        <Select
                          labelId={`demo-simple-select-standard-label ${index}`}
                          name={`PassangerInfo${index}.pax_type`}
                          value={values?.PassangerInfo[index]?.pax_type}
                          onChange={(e) =>
                            setFieldValue(
                              `PassangerInfo[${index}].pax_type`,
                              e.target.value
                            )
                          }
                          label="Select Type"
                        >
                          {arry.map((list) => (
                            <MenuItem
                              value={list}
                              sx={{ m: 1, bgcolor: "#fff" }}
                              key={list}
                            >
                              {list}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box> */}
                    <Box flex="1">
                      <TextField
                        variant="standard"
                        label="First Name"
                        fullWidth
                        value={values?.PassangerInfo[index].f_name}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase();
                          setFieldValue(`PassangerInfo.${index}.f_name`, value);
                        }}
                      />
                    </Box>
                    <Box flex="1">
                      <TextField
                        variant="standard"
                        label="Last Name"
                        fullWidth
                        value={values?.PassangerInfo[index].pax_l_name}
                        onChange={(e) => {
                          const value = e.target.value.toUpperCase();
                          setFieldValue(
                            `PassangerInfo.${index}.pax_l_name`,
                            value
                          );
                        }}
                      />
                    </Box>
                    <div>
                      {index === 0 ? (
                        <button
                          type="button"
                          className="setup-btn me-2 me-md-2"
                          disabled={values?.PassangerInfo?.length === 5}
                          onClick={() =>
                            arrayHelpers.push({
                              pax_type: "",
                              pax_title: "",
                              f_name: "",
                              pax_l_name: "",
                            })
                          }
                        >
                          + Passenger
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-small btn-danger me-2 me-md-2"
                          style={{ paddingLeft: 32, paddingRight: 32 }}
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          - Passenger
                        </button>
                      )}
                    </div>
                  </Box>
                </Box>
              ))
            : null}
        </div>
      )}
    />
  );
};

export default PassangerInfo;
