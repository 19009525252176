import React from 'react'
import Banner from '../components/Agency/homeviewsittings/Banner'

const Bannerpage = () => {
  return (
    <div>
      <Banner/>
    </div>
  )
}

export default Bannerpage
