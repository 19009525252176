import moment from "moment";
import React from "react";

import { MdOutlineFlightLand, MdOutlineFlightTakeoff } from "react-icons/md";
import { useDispatch } from "react-redux";

const AmadeusReturnCard = ({ segments, index, timeDuration }) => {
  const dispatch = useDispatch();
  const searchValues = JSON.parse(localStorage.getItem("flightSearchData"));
  const locDeparture = searchValues?.locationDep;
  const locArrival = searchValues?.locationArrival;

  const firstSegment = segments?.at(0);
  const lastSegment = segments?.at(segments?.length - 1);

  const getCity = (loc) => {
    let city = loc?.split("-")[2]?.toUpperCase();
    return city;
  };

  return (
    <div className="row align-items-center py-4">
      <div className="col-2">
        <div className="px-2">
          <div className="text-center">
            <img
              src={`/NEW_LOGO/${index === 0
                  ? firstSegment?.carrierCode
                  : lastSegment?.carrierCode
                }.png`}
              alt="logo"
              width={90}
              height={90}
            />
            <div className="pt-3" style={{ whiteSpace: "nowrap" }}>
              {segments?.map((it, idx, arr) => (
                <span className="pe-1" key={idx}>{`${it?.carrierCode} ${it?.number
                  } ${idx < arr.length - 1 ? " -" : ""}`}</span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-10">
        <div className="d-flex justify-content-evenly align-items-center">
          <div className="d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightTakeoff size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(firstSegment?.departure?.at?.split("T")[0]).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>
              <h6 className="my-3">
                {moment(
                  firstSegment?.departure?.at?.split("T")[1],
                  "HH:mm:ss"
                ).format("HH:mm")}
              </h6>
              <h6>
                {firstSegment?.departure?.iataCode} -{" "}
                {index === 0 ? getCity(locDeparture) : getCity(locArrival)}
              </h6>
            </div>
          </div>

          <div>
            <h6 className="text-center">
              {timeDuration(
                segments[0]?.departure?.at,
                segments[segments?.length - 1]?.arrival?.at
              )}
            </h6>
            <div className="border_rounded mb-2" />
            <h6 className="mx-3">
              {segments?.length === 1
                ? "Non-Stop"
                : segments?.length === 2
                  ? "One-Stop"
                  : segments?.length === 3
                    ? "Two-Stop"
                    : segments?.length === 4
                      ? "Three Stop"
                      : "Four Stop"}
            </h6>

            {segments?.length > 1 && (
              <h6 className="text-center">
                {segments
                  ?.slice(1)
                  ?.map((seg) => seg?.departure?.iataCode)
                  ?.join(", ")}
              </h6>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="me-3">
              <MdOutlineFlightLand size={30} className="color_primary" />
            </div>
            <div>
              <h6>
                {moment(lastSegment?.arrival?.at?.split("T")[0]).format(
                  "ddd DD-MMM-YY"
                )}
              </h6>
              <h6 className="my-3">
                {moment(
                  lastSegment?.arrival?.at?.split("T")[1],
                  "HH:mm:ss"
                ).format("HH:mm")}
              </h6>
              <h6>
                {lastSegment?.arrival?.iataCode} -{" "}
                {index === 0 ? getCity(locArrival) : getCity(locDeparture)}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmadeusReturnCard;
