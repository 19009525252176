import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FieldArray } from "formik";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { dropMenuProp } from "../../../../../Utils/MenuProps";
import {
  CurrencyCode,
  CurrencyExchange,
} from "../../../../../Utils/currencyExchange";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

const TransferInfo = ({ values, setFieldValue }) => {
  const { currencyData } = useSelector((state) => state?.bank_curr_roleSlice);
  const isPkr = values.transfer_info?.at(0).cur_label === "PKR" ? true : false;

  return (
    <FieldArray
      name="transfer_info"
      render={(arrayHelpers) => (
        <div>
          {values?.transfer_info?.map((transfer, index) => (
            <div className="section_box py-4 pb-5" key={index}>
              <div className="d-flex justify-content-between align-items-center pb-4">
                <div className="h6">Transfer Information</div>
                <div>
                  {index >= 1 && (
                    <button
                      onClick={() => arrayHelpers.remove(index)}
                      className="button_style bg-danger"
                      type="button"
                    >
                      - Transfer
                    </button>
                  )}
                  {index === 0 && (
                    <button
                      onClick={() =>
                        arrayHelpers.push({
                          location_pickup: "",
                          location_dropup: "",
                          date_pickup: "",
                          date_dropup: "",
                          currency: transfer.currency,
                          cur_label: transfer.cur_label,
                          roe: transfer.roe,
                          curr_pay: 0,
                          curr_rec: 0,
                          pkr_pay: 0,
                          pkr_rec: 0,
                        })
                      }
                      className="button_style"
                      type="button"
                    >
                      + Transfer
                    </button>
                  )}
                </div>
              </div>
              <div className="row align-items-start">
                <div className="col-6">
                  <div className="black_border rounded p-3 pb-4">
                    <div className="row align-items-end">
                      <div className="col-4">
                        <TextField
                          value={transfer.location_pickup}
                          onChange={(e) => {
                            setFieldValue(
                              `transfer_info.${index}.location_pickup`,
                              e.target.value
                            );
                          }}
                          label="Location PickUp"
                          variant="standard"
                          className="w-100"
                        />
                      </div>
                      <div className="col-4">
                        <TextField
                          value={transfer.location_dropup}
                          onChange={(e) => {
                            setFieldValue(
                              `transfer_info.${index}.location_dropup`,
                              e.target.value
                            );
                          }}
                          label="Location DropUp"
                          variant="standard"
                          className="w-100"
                        />
                      </div>

                      <div className="col-2">
                        <ReactDatePicker
                          placeholderText="PickUp Date"
                          selected={transfer.date_pickup}
                          value={transfer.date_pickup}
                          onChange={(date) => {
                            setFieldValue(
                              `transfer_info.${index}.date_pickup`,
                              date
                            );
                          }}
                          minDate={new Date()}
                          className="date_picker"
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                      <div className="col-2">
                        <ReactDatePicker
                          placeholderText="DropUp Date"
                          selected={transfer.date_dropup}
                          value={transfer.date_dropup}
                          onChange={(date) => {
                            setFieldValue(
                              `transfer_info.${index}.date_dropup`,
                              date
                            );
                          }}
                          minDate={new Date()}
                          className="date_picker"
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* currency detail  */}
                <div className="col-6">
                  <div className="black_border rounded p-3 pb-4">
                    <div className="row align-items-start">
                      <div className="col-2">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id={`cur_label`}>Currency</InputLabel>
                          <Select
                            id={`cur_label`}
                            value={transfer.cur_label}
                            onChange={(e) => {
                              let value = e.target.value;
                              let currency = currencyData?.find(
                                (it) => it.curr_code === value
                              );
                              let updatedValue = values.transfer_info.map(
                                (it) => ({
                                  ...it,
                                  cur_label: value,
                                  currency: currency,
                                  roe: currency?.roe,
                                  // reset this field
                                  curr_pay: 0,
                                  curr_rec: 0,
                                  pkr_pay: 0,
                                  pkr_rec: 0,
                                })
                              );
                              setFieldValue("transfer_info", updatedValue);
                            }}
                            label="Currency"
                            MenuProps={dropMenuProp}
                            required
                            inputProps={{
                              readOnly: index > 0,
                            }}
                          >
                            {currencyData?.map((it, ind) => (
                              <MenuItem value={it.curr_code} key={ind}>
                                {it.curr_code}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-2">
                        <TextField
                          label="ROE"
                          variant="standard"
                          className="w-100"
                          value={transfer.roe}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(`transfer_info.${index}.roe`, value);
                            setFieldValue(
                              `transfer_info.${index}.pkr_pay`,
                              TwoDecimalPoint(value * transfer.curr_pay)
                            );
                            setFieldValue(
                              `transfer_info.${index}.pkr_rec`,
                              TwoDecimalPoint(value * transfer.curr_rec)
                            );
                          }}
                          type="number"
                          onFocus={(e) => e.target.select()}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: index > 0,
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          label={`${isPkr ? "Curr" : transfer.cur_label} PAY`}
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          disabled={isPkr}
                          value={transfer.curr_pay}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(
                              `transfer_info.${index}.curr_pay`,
                              value
                            );
                            setFieldValue(
                              `transfer_info.${index}.pkr_pay`,
                              TwoDecimalPoint(value * transfer.roe)
                            );
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          value={transfer.curr_rec}
                          onChange={(e) => {
                            let value = e.target.value;
                            setFieldValue(
                              `transfer_info.${index}.curr_rec`,
                              value
                            );
                            setFieldValue(
                              `transfer_info.${index}.pkr_rec`,
                              TwoDecimalPoint(value * transfer.roe)
                            );
                          }}
                          label={`${isPkr ? "Curr" : transfer.cur_label} REC`}
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          disabled={isPkr}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          value={transfer.pkr_pay}
                          onChange={(e) => {
                            setFieldValue(
                              `transfer_info.${index}.pkr_pay`,
                              e.target.value
                            );
                          }}
                          label="PKR PAY"
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          disabled={transfer.curr_pay > 0}
                        />
                      </div>
                      <div className="col-2">
                        <TextField
                          value={transfer.pkr_rec}
                          onChange={(e) => {
                            setFieldValue(
                              `transfer_info.${index}.pkr_rec`,
                              e.target.value
                            );
                          }}
                          label="PKR REC"
                          variant="standard"
                          className="w-100"
                          type="number"
                          onFocus={(e) => e.target.select()}
                          disabled={transfer.curr_rec > 0}
                        />
                        {values?.agent && values?.agent_name !== "" && (
                          <div className="text-primary fs-6 mt-3">
                            {CurrencyCode()}{" "}
                            {TwoDecimalPoint(
                              CurrencyExchange(transfer.pkr_rec)
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
};
export default TransferInfo;
