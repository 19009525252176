import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HTTP, HTTPForm, HTTPJson, NODEURL } from "../ApiConfig";
import { useDispatch } from "react-redux";


const initialState = {
  voucher: [],
  getvoucher: [],
  isLoading: false,
  error: null,
  getSingleVoucher: {},
  getAllPostVoucher: [],
  allVoucher: [],
  getTktOrInv:{},
};

export const AsyncCreatVoucherEntries = createAsyncThunk(
  "AsyncCreatVoucherEntries/post",
  async ({ body }, { rejectWithValue }) => {
    try {
      const response = await HTTPForm.post("/api/v1/b2b/voucherEntries", body);
      return await response?.data?.message;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const AsyncGetVoucherEntries = createAsyncThunk(
  "AsyncGetVoucherEntries/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = NODEURL.get("/api/v1/b2b/voucherEntries");
      // console.log(response);
      return await response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);


export const AsyncGetSingleVoucherEntries = createAsyncThunk(
  "AsyncGetSingleVoucherEntries/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = NODEURL.get(`/api/v1/b2b/voucherEntries/${id}`);
      // console.log(response);

      return await response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// get vouher by ticket number or invoice number
export const AsyncGetSingleVoucherEntriesThrougTKTORINV = createAsyncThunk(
  "AsyncGetSingleVoucherEntriesThrougTKTORINV/get",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = NODEURL.get(`/api/v1/b2b/voucherSearchByTKTORINV/${id}`);
      console.log(response);

      return await response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const AsyncUpdateSingleVoucherEntries = createAsyncThunk(
  "AsyncUpdateSingleVoucherEntries/patch",
  async ({ id, body }, { rejectWithValue }) => {
    try {
      const response = HTTPForm.patch(`/api/v1/b2b/voucherEntries/${id}`, body);
      // console.log(response);
      // return await response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const AsyncGetAllVoucher = createAsyncThunk(
  "AsyncGetAllVoucher/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = NODEURL.get("/api/v1/b2b/getAllVoucher");
      // console.log(response);
      return await response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// Get all PostVoucer
export const AsyncGetAllPostVoucher = createAsyncThunk(
  "AsyncGetAllPostVoucher/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = NODEURL.get("/api/v1/b2b/get-postvoucher-Entries");
      // console.log("respose post",response);
      return await response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

// delete all voucher
export const AsyncDeletAllPostVoucher = createAsyncThunk(
  "AsyncDeletAllPostVoucher/delete",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await NODEURL.delete(`/api/v1/b2b/delete-voucherEntries/${id}`);
      dispatch(AsyncGetVoucherEntries());
      dispatch(AsyncGetAllPostVoucher());
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const voucherEntries = createSlice({
  name: "voucherEntries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(AsyncCreatVoucherEntries.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(
      AsyncCreatVoucherEntries.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.voucher = payload;
      }
    );
    builder.addCase(AsyncCreatVoucherEntries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(AsyncGetVoucherEntries.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(AsyncGetVoucherEntries.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.getvoucher = payload;
    });
    builder.addCase(AsyncGetVoucherEntries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    builder.addCase(
      AsyncGetSingleVoucherEntries.pending,
      (state, { payload }) => {
        state.isLoading = true;
        state.error = null;
      }
    );
    builder.addCase(
      AsyncGetSingleVoucherEntries.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.getSingleVoucher = payload; 
      }
    );
    builder.addCase(AsyncGetSingleVoucherEntries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });


    // By INV OR TKT
     builder.addCase(
       AsyncGetSingleVoucherEntriesThrougTKTORINV.pending,
       (state, { payload }) => {
         state.isLoading = true;
         state.error = null;
       }
     );
     builder.addCase(
       AsyncGetSingleVoucherEntriesThrougTKTORINV.fulfilled,
       (state, { payload }) => {
         state.isLoading = false;
         state.error = null;
         state.getTktOrInv = payload;
       }
     );
     builder.addCase(
       AsyncGetSingleVoucherEntriesThrougTKTORINV.rejected,
       (state, action) => {
         state.isLoading = false;
         state.error = action.payload;
       }
     );

    //  get all post voucher
    builder.addCase(AsyncGetAllPostVoucher.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(AsyncGetAllPostVoucher.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.getAllPostVoucher = payload;
    });
    builder.addCase(AsyncGetAllPostVoucher.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    //  get all voucer
    builder.addCase(AsyncGetAllVoucher.pending, (state, { payload }) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(AsyncGetAllVoucher.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.allVoucher = payload;
    });
    builder.addCase(AsyncGetAllVoucher.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default voucherEntries.reducer;
