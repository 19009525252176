import React from "react";
import VisaRateComp from "../components/Setup/Visa/Rate";


const VisaRate = () => {
  return (
    <div className="pb-5">
      <VisaRateComp/>
    </div>
  );
};

export default VisaRate;
