import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { HTTP, HTTPForm } from "../ApiConfig";

const initialState = {
    hotelData: [],
    currencyField: true,
    currency: '',
    loading: false,
    error: null,
}

// export const asyncGetLandingPageData = createAsyncThunk(
//     "asyncGetLandingPageData/get",
//     async (_, { rejectWithValue }) => {
//         try {
//             const response = await HTTP.get("/api/v1/homePage")
//             return await response?.data?.data;
//         } catch (error) {
//             console.log(error)
//             rejectWithValue(error)
//         }
//     }
// )

const HotelSlice = createSlice({
    name: "HotelSlice",
    initialState,
    reducers: {
        handleCurrency: (state) => {
            state.currencyField = !state.currencyField
        }
    },

})
export const { handleCurrency } = HotelSlice.actions

export default HotelSlice.reducer;