import React from "react";
import { useNavigate } from "react-router-dom";

export default function Ticket({
  bg_color,
  title,
  img,
  counter,
  preview,
  text,
}) {
  const navigate = useNavigate();
  return (
    <div className="px-md-2">
      <div
        className={`custom-card ${bg_color}`}
        style={{ background: `${bg_color}` }}
      >
       <div className="d-flex justify-content-between">
       <div>
          <h4 className="card-title  text-white card_title w-75">{title}</h4>
          <h5 className="text-white h4 mt-3  w-25">{counter?.totalCount}</h5>

        </div>{" "}
        <div className="mb-2 text-end">
          <span className="d-flex gap-4 align-items-center">
            <b className="text-white text-center mt-2 w-75">
              {counter?.pending?.title}
            </b>
            <span className="text-white text-center mt-2 w-25">
              {counter?.pending?.count}
            </span>
            {/* <img src={img} className="card-image" alt={title} /> */}
          </span>
          <span className="d-flex gap-4 align-items-center ">
            <b className="text-white text-center mt-2 w-75">
              {counter?.issued?.title}
            </b>
            <span className="text-white text-center mt-2 w-25">
              {counter?.issued?.count}
            </span>
          </span>
        </div>
       </div>
        {preview && (
          <div
            className="text-white text-center mt-3 "
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/booking")}
          >
            Preview All
          </div>
        )}
      </div>
    </div>
  );
}
