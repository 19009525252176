import React, { useEffect, useMemo, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import EastIcon from "@mui/icons-material/East";
// import icons
import AgentGroupW from "../../b2b icons/setup/agen group W.png";
import AgencyDataB from "../../b2b icons/setup/agent group.png";
import AgentW from "../../b2b icons/setup/agent W.png";
import AgentB from "../../b2b icons/setup/agent.png";
import ApiAirlineW from "../../b2b icons/setup/api airlines W.png";
import ApiAirlineB from "../../b2b icons/setup/api airlines.png";
import ApiPasswordW from "../../b2b icons/setup/api password W.png";
import ApiPasswordB from "../../b2b icons/setup/api password.png";
import ApiPricingW from "../../b2b icons/setup/api pricing W.png";
import ApiPricingB from "../../b2b icons/setup/api pricing.png";
import BankW from "../../b2b icons/setup/bank W.png";
import BankB from "../../b2b icons/setup/bank.png";
import CurrencyW from "../../b2b icons/setup/curency W.png";
import CurrencyB from "../../b2b icons/setup/currency.png";
import PromotionsW from "../../b2b icons/setup/promotions W.png";
import PromotionsB from "../../b2b icons/setup/promotions.png";
import RegistrationW from "../../b2b icons/setup/registeration W.png";
import RegistrationB from "../../b2b icons/setup/registeration.png";
import ResolvedRequestW from "../../b2b icons/setup/resolved request W.png";
import ResolvedRequestB from "../../b2b icons/setup/resolved request.png";
import SupplierRulesW from "../../b2b icons/setup/supplier rules W.png";
import SupplierRulesB from "../../b2b icons/setup/supplier rules.png";
import SupplierW from "../../b2b icons/setup/supplier W.png";
import SupplierB from "../../b2b icons/setup/supplier.png";
import UmrahW from "../../b2b icons/setup/umrah W.png";
import UmrahB from "../../b2b icons/setup/umrah.png";
import VisaW from "../../b2b icons/setup/visa W.png";
import VisaB from "../../b2b icons/setup/visa.png";
import { useDispatch, useSelector } from "react-redux";
import checkMenuItemExistence from "../../Utils/checkMenuItemExistence";
import {
  editAirlinePricing_Null,
  editAirlinesData_null,
} from "../../redux/features/setupRestPages/Price_AirLine_SuppRole";
import { editPACredentialNull } from "../../redux/features/setupFeature/ApiCredential/PACredentialSlice";
import { editErCredentialNull } from "../../redux/features/setupFeature/ApiCredential/ErCredential";
import { editPKCredentialNull } from "../../redux/features/setupFeature/ApiCredential/PKCredentialSlice";
import { editAmadeusCredentialNull } from "../../redux/features/setupFeature/ApiCredential/AmadeusCredentialSlice";
import { editSabreCredentialNull } from "../../redux/features/setupFeature/ApiCredential/SabreCredentialSlice";
import { edit9PCredentialNull } from "../../redux/features/setupFeature/ApiCredential/9PCredentialSlice";
import { editFZCredentialNull } from "../../redux/features/setupFeature/ApiCredential/FZCredentialSlice";
import { editPFCredentialNull } from "../../redux/features/setupFeature/ApiCredential/PFCredentialSlice";

const SetupDropDown = ({ callback }) => {
  const navigate = useNavigate();

  // use state
  const [SubMenu, setSubMenu] = useState("");
  const [isHovered, setIsHovered] = useState("dark");
  const [bankbooking, setBankBooking] = useState(false);
  const dispatch = useDispatch();
  //use selector redux
  const user = JSON.parse(localStorage.getItem("loginUser"));

  const { userTypePermissions } = useSelector(
    (state) => state?.roleAndPermissions
  );
  // set and get local storage
  const Active_link = localStorage.getItem("Active_link")?.split(")")[0];
  const setActive_link = (value) =>
    localStorage.setItem("Active_link", `${value})setup`);

  // Menu array for setup dropdown
  const menuArrays = useMemo(() => {
    return [
      //promotions
      {
        title: "Promotions",
        page_level_id: [47, 89, 52, 56],
        subMenu: [
          {
            title: "Add Flash News",
            pageLink: "/flash-news",
            page_level_id: 47,
          },
          {
            title: "Flights Group",
            pageLink: "/flight-groups",
            page_level_id: 89,
          },
          {
            title: "Circular",
            pageLink: "/circular",
            page_level_id: 52,
          },
          {
            title: "Promotions",
            pageLink: "/promotions",
            page_level_id: 56,
          },
        ],
        iconW: PromotionsW,
        iconB: PromotionsB,
      },
      //visa
      {
        title: "Visa",
        page_level_id: [78, 81, 84],
        subMenu: [
          {
            title: "Countries",
            pageLink: "/visa-countries",
            page_level_id: 78,
          },
          {
            title: "Visa Type",
            pageLink: "/visa-type",
            page_level_id: 81,
          },
          {
            title: "Visa Rate",
            pageLink: "/visa-rate",
            page_level_id: 84,
          },
          // {
          //   title: "Terms & Conditions",
          //   pageLink: "/visa-terms",
          //   page_level_id: 0,
          // },
        ],
        iconW: VisaW,
        iconB: VisaB,
      },
      //umrah
      // {
      //   title: "Umrah",
      //   iconW: UmrahW,
      //   iconB: UmrahB,
      //   subMenu: [
      //     {
      //       title: "Hotel",
      //       pageLink: "/umrah-hotels",
      //     },
      //     {
      //       title: "Transfer",
      //       pageLink: "/umrah-tranfer",
      //     },
      //     {
      //       title: "Visa Supplier",
      //       pageLink: "/umrah-visa-supplier",
      //     },
      //     {
      //       title: "Transfer Supplier",
      //       pageLink: "/umrah-transfer-supplier",
      //     },
      //     {
      //       title: "Hotel Room Type",
      //       pageLink: "/room-type",
      //     },
      //     {
      //       title: "Umrah Hotel Rates",
      //       pageLink: "/hotel-rates",
      //     },
      //   ],
      // },
      //registration
      {
        title: "New Registration",
        pageLink: "/new-agency-registration",
        page_level_id: 1,
        iconW: RegistrationW,
        iconB: RegistrationB,
        subMenu: [],
      },
      //Add new Supplier
      {
        title: "Add new Supplier",
        page_level_id: 61,
        pageLink: "/supplier",
        iconW: SupplierW,
        iconB: SupplierB,
        subMenu: [],
      },
      //Agent Group
      {
        title: "Agent Group",
        pageLink: "/agent-groups",
        page_level_id: 62,
        iconW: AgentGroupW,
        iconB: AgencyDataB,
        subMenu: [],
      },
      //agent
      {
        title: "Agent",
        pageLink: "/agents",
        page_level_id: 2,
        iconW: AgentW,
        iconB: AgentB,
        subMenu: [],
      },
      //bank details
      {
        title: "Bank Details",
        pageLink: "/bank-details",
        page_level_id: 41,
        iconW: BankW,
        iconB: BankB,
        subMenu: [],
      },
      //currency
      {
        title: "Currency",
        pageLink: "/currency",
        page_level_id: 10,
        iconW: CurrencyW,
        iconB: CurrencyB,
        subMenu: [],
      },
      //Api Pricing
      {
        title: user?.agent_id === 1 ? "API Pricing" : "Pricing",
        pageLink: "/api-pricing",
        page_level_id: 13,
        iconW: ApiPricingW,
        iconB: ApiPricingB,
        subMenu: [],
      },
      //api airline
      {
        title: "API Airline",
        pageLink: "/api-airlines",
        page_level_id: 66,
        iconW: ApiAirlineW,
        iconB: ApiAirlineB,
        subMenu: [],
      },
      //Api Password
      {
        title: "API Password",
        pageLink: "/api-credentials",
        page_level_id: 70,
        iconW: ApiPasswordW,
        iconB: ApiPasswordB,
        subMenu: [],
      },
      //Supplier rules
      // {
      //   title: "Supplier Rule",
      //   pageLink: "/supplier-rules",
      //   page_level_id: 71,
      //   iconW: SupplierRulesW,
      //   iconB: SupplierRulesB,
      //   subMenu: [],
      // },
      //Resolved requests
      // {
      //   title: "Resolved Request",
      //   pageLink: "/resolved-requests",
      //   page_level_id: 76,
      //   iconW: ResolvedRequestW,
      //   iconB: ResolvedRequestB,
      //   subMenu: [],
      // },
    ];
  }, [user]);

  useEffect(() => {
    const filteredItems = checkMenuItemExistence(
      userTypePermissions,
      menuArrays,
      user
    );
    callback(filteredItems);
  }, [userTypePermissions, menuArrays, user]);

  return (
    <div className="dropdown-menu-setup" id="dropdown-menu-setup">
      {menuArrays.map((item, index) => {
        // if the login user has permission to the specified page
        let isSubArrayExist = item?.page_level_id?.length > 0;
        let rolePermission = userTypePermissions?.find((role) => {
          return isSubArrayExist
            ? item?.page_level_id?.some(
                (id) =>
                  role.user_type_id === user?.role_id &&
                  id === role.page_level_id
              )
            : role.user_type_id === user?.role_id &&
                role.page_level_id === item?.page_level_id;
        });
        return rolePermission !== undefined || user?.user_id === 1 ? (
          <div
            key={index}
            onMouseEnter={() => setIsHovered(`W${index}`)}
            onMouseLeave={() => setIsHovered("")}
          >
            <div
              className={`finance-dropdown-items ${
                Active_link === item.pageLink ||
                item.subMenu.find((it) => it.pageLink === Active_link)
                  ? "menu_link_container-active"
                  : ""
              }`}
              onMouseEnter={() => setIsHovered(`W${index}`)}
              onMouseLeave={() => setIsHovered("")}
              onClick={() => {
                const element = document.getElementById("dropdown-menu-setup");
                item.subMenu.length === 0 &&
                  element.classList.remove("dropdown-menu-setup-show");
                dispatch(editAirlinePricing_Null());
                dispatch(dispatch(editAirlinesData_null()));
                dispatch(editPACredentialNull());
                dispatch(editErCredentialNull());
                dispatch(editPKCredentialNull());
                dispatch(editAmadeusCredentialNull());
                dispatch(editSabreCredentialNull());
                dispatch(edit9PCredentialNull());
                dispatch(editFZCredentialNull());
                dispatch(editPFCredentialNull());
                navigate(item.pageLink);
                item.subMenu.length > 0 && SubMenu !== item.title
                  ? setSubMenu(item.title)
                  : setSubMenu("");
                item?.pageLink && setActive_link(`${item.pageLink}`);
              }}
            >
              <div className="d-flex align-items-end">
                <img
                  src={
                    isHovered === `W${index}` ||
                    Active_link === item.pageLink ||
                    item.subMenu.find((it) => it.pageLink === Active_link)
                      ? item.iconW
                      : item.iconB
                  }
                  alt=""
                  width={25}
                  height={25}
                  className="me-3"
                />
                <div className="drp-links">{item.title}</div>
              </div>
              {item.subMenu.length > 0 && (
                <div>
                  {SubMenu === item.title ? (
                    <IoIosArrowUp size={25} />
                  ) : (
                    <IoIosArrowDown size={25} />
                  )}
                </div>
              )}
            </div>
            {item.subMenu.length > 0 && SubMenu === item.title && (
              <div
                className="py-2"
                style={{ paddingLeft: "2rem" }}
                onMouseEnter={() => setIsHovered(``)}
              >
                {item.subMenu?.map((it) => {
                  let rolePermissionSubMenu = userTypePermissions?.find(
                    (role) =>
                      role?.user_type_id === user?.role_id &&
                      role?.page_level_id === it.page_level_id
                  );

                  return rolePermissionSubMenu || user?.user_id === 1 ? (
                    <div key={it.title + index}>
                      <div
                        onClick={() => {
                          setActive_link(`${it.pageLink}`);
                          it.pageLink && navigate(`${it.pageLink}`);
                          setBankBooking(!bankbooking);
                          const element = document.getElementById(
                            "dropdown-menu-setup"
                          );
                          if (it?.title !== "Payment Vouchers") {
                            element.classList.remove(
                              "dropdown-menu-setup-show"
                            );
                          }
                        }}
                        className={`finance-dropdown-items ${
                          Active_link === it.pageLink &&
                          "menu_link_container-active"
                        }`}
                      >
                        <div className="d-flex align-items-center">
                          <EastIcon color="white" size="20" className="me-2" />
                          <span>{it.title}</span>
                        </div>
                        {it?.drp_sub?.length > 0 && (
                          <div>
                            {bankbooking ? (
                              <IoIosArrowUp size={25} />
                            ) : (
                              <IoIosArrowDown size={25} />
                            )}
                          </div>
                        )}
                      </div>
                      {it.drp_sub && it.drp_sub.length > 0 && bankbooking && (
                        <div className="py-2" style={{ paddingLeft: "2rem" }}>
                          {it.drp_sub.map((drp) => {
                            return (
                              <div
                                onClick={() => {
                                  setActive_link(`${drp.pageLink}`);
                                  navigate(`${drp.pageLink}`);
                                  const element = document.getElementById(
                                    "dropdown-menu-setup"
                                  );
                                  element.classList.remove(
                                    "dropdown-menu-setup-show"
                                  );
                                }}
                                className={`finance-dropdown-items ${
                                  Active_link === drp.pageLink &&
                                  "menu_link_container-active"
                                }`}
                                key={drp.title}
                              >
                                <div className="d-flex align-items-center">
                                  <EastIcon
                                    color="white"
                                    size="20"
                                    className="me-2"
                                  />
                                  <span>{drp.title}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  ) : null;
                })}
              </div>
            )}
          </div>
        ) : null;
      })}
    </div>
  );
};
export default SetupDropDown;
