import { MdRemoveRedEye } from "react-icons/md";
import { LightTooltip } from "../Reservation/FlightsTab/OneWaySearchResult/LightTooltip";

export const RefundExchange = () => {
    return (
        <LightTooltip
            title={
                <div className="p-3">
                    <h4>Refund & Exchange</h4>
                    <div className="text-danger font-italic">
                        for further clarification contact with Airline Or Call our support
                        team.
                    </div>
                    <div className="text-danger font-italic mt-2">
                        After 25 days of departure ticket is Non-Refundable.
                    </div>

                    <h6 className="mt-3">
                        contact with airline or call our support team
                    </h6>

                    {/* <div className="d-flex pt-3">
              <div>
                <h4>Conditions</h4>
                <h6>More than 48 hours to departure</h6>
                <h6>Within 48 hours To 4 hours to Departure</h6>
                <h6>Less than 4 hours after departure</h6>
                <h6>No show</h6>
              </div>
              <div className="mx-4">
                <h4>COB</h4>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(2000)}`}</h6>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              </div>
              <div>
                <h4>Refund</h4>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(2500)}`}</h6>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
                <h6>{`${CurrencyCode()} ${CurrencyExchange(4000)}`}</h6>
              </div>
            </div> */}
                </div>
            }
            placement="top"
        >
            <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                <MdRemoveRedEye size={25} className="color_primary" />
                <h6 className="pt-2 ps-2">View</h6>
            </div>
        </LightTooltip>
    );
};