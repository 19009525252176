import ReactDatePicker from "react-datepicker";
import {
  Autocomplete,
  ClickAwayListener,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { dropMenuProp } from "../../Utils/MenuProps";
import { useDispatch, useSelector } from "react-redux";
import React, { useState,memo } from "react";
import { asyncSearchAgencyData } from "../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";
import { MdClose } from "react-icons/md";
import { getAllAsyncBookings } from "../../redux/features/booking/bookingDetailsSlice";
import moment from "moment";
import BtnLoader from "../AppForm/BtnLoader";
const BookingFilter = ({
  bookingFilter,
  setBookingFilter,
  user,
  rowsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const [agentDRopDwn, setagentDRopDwn] = useState(false);
  const [drp, setdrp] = useState(false);
  const [supplierName, setSupplierName] = useState([]);
  const { searchAgency } = useSelector((state) => state.newAgency);
  const { newSupplier } = useSelector((state) => state.supplier);

  const suppArray = newSupplier?.map((sup) => sup?.supp_name);

  const dispatch = useDispatch();
  const [agent, setAgent] = useState("");
  const [supValue, setSupValue] = useState("");
  const [message,setMessage] = useState(true)

  const arry = [
    { id: 1, type: "Flight" },
    { id: 2, type: "Flight-Group" },
    { id: 3, type: "Visa" },
    { id: 4, type: "Hotel" },
    { id: 5, type: "Insurance" },
    { id: 6, type: "Transfer" },
    { id: 7, type: "Activity" },
    { id: 8, type: "Umrah" },
  ];

  const arryStatus = [
    { id: 1, type: "Pending" },
    { id: 2, type: "Issued" },
  ];



  return (
    <div
      className="d-flex flex-column gap-4 shadow-lg rounded p-4 bg-white"
      style={{
        width: "40%",
        height: "auto",
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* ist Row */}
      <div className="row align-items-center">
        <div className="input_groupS col-4">
          <ReactDatePicker
            placeholderText="From Date"
            selected={bookingFilter?.fromDate}
            dateFormat="dd-MM-yyyy"
            onChange={(date) => {
              setBookingFilter({ ...bookingFilter, fromDate: date });
            }}
            maxDate={new Date()}
            className="date_picker "
          />
        </div>
        <div className="input_groupS col-4">
          <ReactDatePicker
            placeholderText="To Date"
            selected={bookingFilter?.toDate}
            dateFormat="dd-MM-yyyy"
            onChange={(date) => {
              setBookingFilter({ ...bookingFilter, toDate: date });
            }}
            maxDate={new Date()}
            className="date_picker "
          />
        </div>
      </div>

      {/* second row */}
      <div className="d-flex ">
        <div className="col-4  ">
          <FormControl variant="standard" style={{ width: "200px" }}>
            <InputLabel id="service_type ">{"Select Service Type"} </InputLabel>
            <Select
              id="service_type"
              value={bookingFilter.service_type}
              onChange={
                (e) => ""
                // setInitValues({ ...values, service_type: e.target.value })
              }
              label={"Select Service Type"}
              MenuProps={dropMenuProp}
              // disabled={VoucherBooking?.auto_manual}
            >
              {arry?.map((list) => (
                <MenuItem
                  onClick={(e) => {
                    setBookingFilter({
                      ...bookingFilter,
                      service_type: list?.type,
                      // service_id: list.id,
                    });
                  }}
                  value={list?.type}
                  sx={{ bgcolor: "#fff" }}
                  key={list?.id}
                >
                  {list?.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-4">
          <TextField
            id="book_id"
            value={bookingFilter?.booking_id}
            onChange={(e) => {
              setBookingFilter({
                ...bookingFilter,
                booking_id: e.target.value.toUpperCase(),
              });
            }}
            label="Booking Id"
            variant="standard"
          />
        </div>
        <div className="col-4   ">
          <TextField
            id="Pax_id"
            value={bookingFilter?.pax_name}
            onChange={(e) => {
              setBookingFilter({
                ...bookingFilter,
                pax_name: e.target.value.toUpperCase(),
              });
            }}
            label="Passenger Name"
            variant="standard"
            required
          />
        </div>
      </div>

      {/* third row */}
      <div className="d-flex ">
        <div className="col-4  ">
          <FormControl variant="standard" style={{ width: "200px" }}>
            <InputLabel id="service_type ">{"Select Doc Status"} </InputLabel>
            <Select
              id="Doc_Status"
              value={bookingFilter?.status}
              onChange={
                (e) => ""
                // setInitValues({ ...values, service_type: e.target.value })
              }
              label={"Select Doc Status"}
              MenuProps={dropMenuProp}
            >
              {arryStatus?.map((list) => (
                <MenuItem
                  onClick={(e) => {
                    setBookingFilter({
                      ...bookingFilter,
                      status: list?.type,
                    });
                  }}
                  value={list?.type}
                  sx={{ bgcolor: "#fff" }}
                  key={list?.id}
                >
                  {list?.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {user?.agent_id === 1 ? (
          <>
            <div className="col-4" style={{ marginBottom: "20px" }}>
              <FormControl
                variant="standard"
                style={{ width: "170px" }}
                onClick={() => setagentDRopDwn(!agentDRopDwn)}
              >
                <InputLabel htmlFor="supplier">Select Agent</InputLabel>
                <Input
                  variant="standard"
                  label="Select Agent"
                  autoComplete="off"
                  className="w-100"
                  name="agent"
                  value={agent}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    setAgent(value);
                    dispatch(asyncSearchAgencyData(value));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {agent !== "" && (
                        <IconButton
                          onClick={() => {
                            setAgent("");
                            setBookingFilter({
                              ...bookingFilter,
                              agent_acc_id: "",
                            });
                          }}
                        >
                          <MdClose />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>

              {agentDRopDwn ? (
                <ClickAwayListener onClickAway={() => setagentDRopDwn(false)}>
                  <div className="agent_dropdown">
                    {searchAgency?.length > 0 ? (
                      searchAgency?.map((item, index) => {
                        return (
                          <div
                            className="p-3"
                            onClick={(e) => {
                              setBookingFilter({
                                ...bookingFilter,
                                agent_acc_id: item?.acc_id,
                              });
                              setAgent(item.agent_name);
                              setagentDRopDwn(false);
                              // setAgentData(item);
                            }}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            {item.agent_name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="p-3">No record available.</div>
                    )}
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>

            <div className="col-4" style={{ marginBottom: "20px" }}>
              <FormControl
                variant="standard"
                style={{ width: "170px" }}
                onClick={() => {
                  setdrp(!drp);
                }}
              >
                <InputLabel htmlFor="supplier">Select Supplier</InputLabel>
                <Input
                  autoComplete="off"
                  id="supplier"
                  className="w-100"
                  value={supValue}
                  name={supValue}
                  onChange={(e) => {
                    let value = e.target.value;
                    setSupValue(value);

                    let filterSupplier = value
                      ? suppArray?.filter((item) =>
                          item?.toLowerCase().includes(value.toLowerCase())
                        )
                      : suppArray;
                    setBookingFilter({
                      ...bookingFilter,
                      supplier_acc_id: filterSupplier,
                    });
                    setSupplierName([...filterSupplier]);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {supValue !== "" && (
                        <IconButton
                          onClick={() => {
                            setBookingFilter({
                              ...bookingFilter,
                              supplier_acc_id: "",
                            });
                            setSupValue("");
                          }}
                        >
                          <MdClose />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
              </FormControl>
              {drp && (
                <ClickAwayListener onClickAway={() => setdrp(!drp)}>
                  <div className="supplier_dropdown ">
                    {supplierName?.length > 0 ? (
                      supplierName?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="supplier_style "
                            onClick={() => {
                              let value = `${item}`;
                              setSupValue(value);
                              setdrp(!drp);
                              let supp_data = newSupplier
                                ?.filter((sup) => sup?.supp_name === value)
                                ?.at(0);

                              setBookingFilter({
                                ...bookingFilter,
                                supplier_acc_id: supp_data?.acc_id,
                              });

                              // setSupplierData(supp_data);
                            }}
                          >
                            <div className="supplier_captilize">
                              {item.toLowerCase()}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="supplier_style">No Record Found.</div>
                    )}
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </>
        ) : null}
      </div>

      <div className="d-flex justify-content-center gap-4 item-center">
        {message ? (
          <div className="text-center py-2">
            <button
              className="button_style px-4"
              type="submit"
              onClick={(e) => {
                const body = {
                  ...bookingFilter,
                  fromDate: moment(bookingFilter?.fromDate).format(
                    "YYYY-MM-DD"
                  ),
                  toDate: moment(bookingFilter?.toDate).format("YYYY-MM-DD"),
                };
                setMessage(false)
                setTimeout(() => {
                   dispatch(
                     getAllAsyncBookings({
                       id: user?.agents_comp?.acc_id,
                       rowsPerPage: rowsPerPage,
                       currentPage: currentPage,
                       bookingFilter: body,
                     })
                   );
                   setMessage(true)
                }, 3000);
               
              }}
            >
              Filter Data
            </button>
          </div>
        ) : (
          <div className="text-center py-2">
            <button
              className="button_style px-4"
          
              // disabled={
              //   (totalDebitor === totalCreditor &&
              //   totalDebitor !== 0 &&
              //   totalCreditor !== 0
              //     ? false
              //     : true) || !voucherEntryPermission
              // }
            >
              <BtnLoader />
            </button>
          </div>
        )}
        <div className="text-center py-2">
          <button className="button_style px-4" type="submit"
          onClick={(e)=>{
               setAgent("");
               setSupValue("");
               setCurrentPage(0);
               setBookingFilter({
                 fromDate: "",
                 toDate: "",
                 agent_acc_id: "",
                 supplier_acc_id: "",
                 booking_id: "",
                 pax_name: "",
                 service_type: "",
                 status: "",
                 change: !bookingFilter.change,
               });
          }}
          >
            Reset Form
          </button>
        </div>

        {/* <div className="text-center py-2">
          <button
            className="button_style px-4"
            type="submit"
            onClick={(e) => {
              setAgent("");
              setSupValue("");
              setCurrentPage(0)
              setBookingFilter({
                fromDate: "",
                toDate: "",
                agent_acc_id: "",
                supplier_acc_id: "",
                booking_id: "",
                pax_name: "",
                service_type: "",
                status: "",
                change:!bookingFilter.change
              });
             
            }}
          >
            Reset Form
          </button>
        </div> */}
      </div>
    </div>
  );
};
;

export default BookingFilter;
