import React from 'react'
import { useNavigate } from "react-router-dom";

export default function TotalPayment({
  bg_color,
  title,
  img,
  counter,
  preview,
  text,
}) {
  const navigate = useNavigate();
  return (
    <div className="px-md-2">
      <div
        className={`custom-card ${bg_color}`}
        style={{ background: `${bg_color}` }}
      >
        <div className="d-flex justify-content-between align-items-start">
          <div>
            <h4 className="card-title text-white card_title">{title}</h4>
            <h5 className="text-white h4 mt-3">{counter?.totalPayment !== null ? counter?.totalPayment : 0}</h5>
          </div>
        </div>
        {preview && (
          <div
            className="text-white text-center mt-3"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/agents")}
          >
            Preview All
          </div>
        )}
      </div>
    </div>
  );
}
