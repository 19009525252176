import React from 'react'
import AirlinesComp from '../components/Setup/Airlines'

const Airlines = () => {
  return (
    <div className='Full_height'>
      <AirlinesComp />
    </div>
  )
}

export default Airlines