import { Tooltip, styled, tooltipClasses } from "@mui/material";

// tooltip for exchanges and return
export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 18,
    border: "1px solid rgba(0, 0, 0, 0.9)",
    maxWidth: "none",
    
  },
}));
