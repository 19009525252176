import { TextField } from "@mui/material";
import React from "react";
import { TwoDecimalPoint } from "../../../../../Utils/FixedTwoDecimal";

function AgentCurrCredit({ setFieldValue, values, friend, index }) {
  return (
    <div className="col-6">
      <div className="row shadow-sm rounded pb-3 blue_border">
        <div className="h5 py-3">
          Agent In {values.fareValues[0].currency?.curr_code}
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_cur.c_psf_per}
            id={`fareValues.[${index}].tax_cur.c_psf_per`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_psf_per`, "");

                setFieldValue(`fareValues.[${index}].tax_cur.c_psf`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_psf_per`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_psf`, "");
              } else {
                const mul_base =
                  (values.fareValues[index].b_fare / 100) * value;

                setFieldValue(`fareValues.[${index}].tax_cur.c_psf_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_cur.c_psf`,
                  TwoDecimalPoint(mul_base)
                );

                setFieldValue(`fareValues.[${index}].tax_pkr.c_psf_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_psf`,
                  TwoDecimalPoint(mul_base * values.fareValues[0].roe)
                );
              }
              setFieldValue(
                `fareValues.[${index}].tax_cur.c_gst`,
                (value * values.fareValues[index].tax_cur.c_gst_per) / 100
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_gst`,
                ((value * values.fareValues[index].tax_cur.c_gst_per) / 100) *
                  values.fareValues[0].roe
              );
            }}
            label="C.PSF %"
            variant="standard"
            type={"number"}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_cur.c_psf}
            id={`fareValues.[${index}].tax_cur.c_psf`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_psf`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.c_psf`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.c_psf`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_psf`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="C.PSF"
            variant="standard"
            type={"number"}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_cur.c_gst_per}
            id={`fareValues.[${index}].tax_cur.c_gst_per`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_gst_per`, "");

                setFieldValue(`fareValues.[${index}].tax_cur.c_gst`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_gst_per`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_gst`, "");
              } else {
                const mul_base =
                  (values.fareValues[index].tax_cur.c_psf / 100) * value;

                setFieldValue(`fareValues.[${index}].tax_cur.c_gst_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_cur.c_gst`,
                  TwoDecimalPoint(mul_base)
                );

                setFieldValue(`fareValues.[${index}].tax_pkr.c_gst_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_gst`,
                  TwoDecimalPoint(mul_base * values.fareValues[0].roe)
                );
              }
            }}
            label="C.GST %"
            variant="standard"
            type={"number"}
          />
          <TextField
            className="mt-3"
            id={`fareValues.[${index}].tax_cur.c_gst`}
            value={values.fareValues[index].tax_cur.c_gst}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_gst`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.c_gst`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.c_gst`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_gst`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="C.GST"
            variant="standard"
            type={"number"}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_cur.c_sp_per}
            id={`fareValues.[${index}].tax_cur.c_sp_per`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_sp_per`, "");

                setFieldValue(`fareValues.[${index}].tax_cur.c_sp`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_sp_per`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_sp`, "");
              } else {
                const mul_base =
                  (values.fareValues[index].b_fare / 100) * value;

                setFieldValue(`fareValues.[${index}].tax_cur.c_sp_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_cur.c_sp`,
                  TwoDecimalPoint(mul_base)
                );

                setFieldValue(`fareValues.[${index}].tax_pkr.c_sp_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_sp`,
                  TwoDecimalPoint(mul_base * values.fareValues[0].roe)
                );
              }
              setFieldValue(
                `fareValues.[${index}].tax_cur.c_wht`,
                TwoDecimalPoint(
                  value * values.fareValues[index].tax_cur.c_wht_per
                ) / 100
              );
              setFieldValue(
                `fareValues.[${index}].tax_pkr.c_wht`,
                TwoDecimalPoint(
                  (value * values.fareValues[index].tax_cur.c_wht_per) / 100
                ) * values.fareValues[0].roe
              );
            }}
            label="C.DISC %"
            variant="standard"
            type={"number"}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_cur.c_sp}
            id={`fareValues.[${index}].tax_cur.c_sp`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_sp`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.c_sp`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.c_sp`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_sp`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="C.DISC"
            variant="standard"
            type={"number"}
          />
        </div>
        <div className="col-3">
          <TextField
            value={values.fareValues[index].tax_cur.c_wht_per}
            id={`fareValues.[${index}].tax_cur.c_wht_per`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_wht_per`, "");

                setFieldValue(`fareValues.[${index}].tax_cur.c_wht`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_wht_per`, "");

                setFieldValue(`fareValues.[${index}].tax_pkr.c_wht`, "");
              } else {
                const mul_base =
                  (values.fareValues[index].tax_cur.c_sp / 100) * value;

                setFieldValue(`fareValues.[${index}].tax_cur.c_wht_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_cur.c_wht`,
                  TwoDecimalPoint(mul_base)
                );

                setFieldValue(`fareValues.[${index}].tax_pkr.c_wht_per`, value);

                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_wht`,
                  TwoDecimalPoint(mul_base * values.fareValues[0].roe)
                );
              }
            }}
            label="C.WHT %"
            variant="standard"
            type={"number"}
          />
          <TextField
            className="mt-3"
            value={values.fareValues[index].tax_cur.c_wht}
            id={`fareValues.[${index}].tax_cur.c_wht`}
            onChange={(e) => {
              const value = e.target.value;
              if (value === "") {
                setFieldValue(`fareValues.[${index}].tax_cur.c_wht`, "");
                setFieldValue(`fareValues.[${index}].tax_pkr.c_wht`, "");
              } else {
                setFieldValue(`fareValues.[${index}].tax_cur.c_wht`, value);
                setFieldValue(
                  `fareValues.[${index}].tax_pkr.c_wht`,
                  TwoDecimalPoint(value * values.fareValues[0].roe)
                );
              }
            }}
            label="C.WHT"
            variant="standard"
            type={"number"}
          />
        </div>
      </div>
    </div>
  );
}

export default AgentCurrCredit;
