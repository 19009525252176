export const menuProps = {
  PaperProps: {
    sx: {
      width: "inherit",
    },
  },
};

export const pax_title = ["MR", "MS", "MSTR", "MISS", "INFT"];
export const pax_type = ["ADULT", "CHILD", "INFANT"];

export const plan_days = [
  "7 Days",
  "10 Days",
  "14 Days",
  "28 Days",
  "2 Months",
  "3 Months",
  "6 Months",
  "1 Year",
  "2 Years",
];
export const plan_type = ["Single", "Family", "Student", "Umrah", "Ziarat"];

export const roomDrp = ["Room 1", "Room 2", "Room 3", "Room 4"];
