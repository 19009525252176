import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import {
  asyncRegNewAgencyMultiple,
  setErrorMessage,
} from "../../../redux/features/setupFeature/NewRegistration/NewRegistrationSlice";

// Validation schema
const validationSchema2 = Yup.object().shape({
  excelFile: Yup.mixed()
    .required("A file is required")
    .test(
      "fileFormat",
      "Only .xlsx and .xls files are allowed",
      (value) =>
        value &&
        [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
        ].includes(value.type)
    ),
});

const UploadExcel = ({ initialValues2 }) => {
  const dispatch = useDispatch();
  const [parsedData, setParsedData] = useState([]);
  const [errors, setErrors] = useState([]);
  const [shouldCallApi, setShouldCallApi] = useState(false);
  // const [message2, setMessage2] = useState("");
  // const [showMessage, setShowMessage] = useState(false);

  const { message2, agentError } = useSelector((state) => state.newAgency);
  useEffect(() => {
    if (shouldCallApi && parsedData.length > 0) {
      setShouldCallApi(false);
    }
  }, [shouldCallApi, parsedData, dispatch]);

  useEffect(() => {
    if (message2) {
      const timer = setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [message2, dispatch]);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheets = workbook.SheetNames;

      let jsonData = [];
      let tempErrors = {};

      const schema = {
        Agent_Type: "string",
        Manager: "number",
        "Agent Name": "string",
        Reg_No: "number",
        "First Name": "string",
        "Last Name": "string",
        Tax_No: "number",
        TKT_Auth: "number",
        Password: "string",
        "Agent Group ID": "number",
        "Auth By User": "number",
        Hours_72: "number",
        View_Statement: "string",
        Address: "string",
        Phone: "number",
        Mobile: "number",
        Email: "string",
        Credit_Limit: "number",
        Status: "number",
      };

      const validateRow = (row, rowIndex) => {
        for (const key in schema) {
          if (!(key in row)) {
            tempErrors[rowIndex] = tempErrors[rowIndex] || {};
            tempErrors[rowIndex][key] = `Missing required key: ${key}`;
            return false;
          }
          if (typeof row[key] !== schema[key]) {
            tempErrors[rowIndex] = tempErrors[rowIndex] || {};
            tempErrors[rowIndex][
              key
            ] = `Invalid type for key: ${key}. Expected ${
              schema[key]
            }, got ${typeof row[key]}`;
            return false;
          }
        }
        return true;
      };

      sheets.forEach((sheet) => {
        const worksheet = workbook.Sheets[sheet];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        let headers = [];
        let headerRowIndex = -1;

        for (let i = 0; i < sheetData.length; i++) {
          const row = sheetData[i];
          if (
            row.every((cell) => typeof cell === "string" && cell.trim() !== "")
          ) {
            headers = row;
            headerRowIndex = i;
            break;
          }
        }

        if (headerRowIndex === -1) {
          tempErrors.general = `No header row found in sheet ${sheet}`;
          return;
        }

        for (
          let rowIndex = headerRowIndex + 1;
          rowIndex < sheetData.length;
          rowIndex++
        ) {
          const row = sheetData[rowIndex];
          const rowData = {};

          headers.forEach((header, index) => {
            const cellValue = row[index];
            if (
              cellValue !== undefined &&
              cellValue !== null &&
              cellValue !== ""
            ) {
              rowData[header] = cellValue;
            }
          });

          if (Object.keys(rowData).length > 0) {
            if (validateRow(rowData, rowIndex)) {
              jsonData.push(rowData);
            }
          }
        }
      });

      if (Object.keys(tempErrors).length > 0) {
        setErrors(tempErrors);
        setParsedData([]);
      } else {
        setErrors([]);
        setParsedData(jsonData);
        setShouldCallApi(true); // Set flag to call API
      }
    };

    reader.readAsArrayBuffer(file);
  };
  const handleGenerate = () => {
    // Call API function here with parsedData
    dispatch(asyncRegNewAgencyMultiple(parsedData));
  };

  const handleDownloadTemplate = () => {
    const headers = [
      "Agent_Type",
      "Manager",
      "Agent Name",
      "Reg_No",
      "First Name",
      "Last Name",
      "Tax_No",
      "TKT_Auth",
      "Password",
      "Agent Group ID",
      "Auth By User",
      "Hours_72",
      "View_Statement",
      "Address",
      "Phone",
      "Mobile",
      "Email",
      "Credit_Limit",
      "Status",
    ];

    const worksheet = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    XLSX.writeFile(workbook, "template.xlsx");
  };

  const handleCancel = () => {
    setParsedData([]);
    setErrors([]);
    document.getElementById("fileInput").value = null;
  };

  return (
    <div>
      <Formik
        initialValues={initialValues2}
        validationSchema={validationSchema2}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false); // Manually mark form as submitted
          handleFileUpload(values.excelFile);
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => (
          <Form>
            <div className="d-flex gap-4">
              <div className="ms-4 col-sm-2 pt-5">
                <p style={{ lineHeight: 0 }}>Import From File</p>
                <input
                  type="file"
                  accept=".xlsx, .xls"
                  id="fileInput"
                  name="excelFile"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    setFieldValue("excelFile", file);
                  }}
                />
                <ErrorMessage
                  component="div"
                  className="text-danger mt-1"
                  name="excelFile"
                />
              </div>
              <div className="d-flex gap-4">
                <div>
                  <button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className=" btn setup-btn mt-3 mx-auto"
                    disabled={isSubmitting}
                  >
                    Continue
                  </button>
                </div>

                <div>
                  <button
                    type="button"
                    className="  btn setup-btn mt-3 mx-auto"
                    onClick={handleDownloadTemplate}
                  >
                    Download Excel Template
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {errors.general && (
        <div className="ms-4 mt-4 text-danger">
          <p>{errors.general}</p>
        </div>
      )}

      {parsedData.length > 0 && (
        <div className="ms-4 mt-4 overflow-scroll">
          <h3>Parsed Data</h3>
          <table className="table">
            <thead>
              <tr>
                {Object.keys(parsedData[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {parsedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.entries(row).map(([key, value], cellIndex) => (
                    <td key={cellIndex}>
                      <input
                        type="text"
                        value={value}
                        onChange={(e) => {
                          const newData = [...parsedData];
                          newData[rowIndex][key] = e.target.value;
                          setParsedData(newData);
                          setShouldCallApi(true); // Update data triggers API call
                        }}
                      />
                      {/* Display general errors if any */}
                      {errors?.at(rowIndex) && errors?.at(rowIndex)[key] && (
                        <div className="text-danger">
                          {errors[rowIndex][key]}
                        </div>
                      )}
                      {cellIndex === 2 &&
                        agentError?.agent?.at(rowIndex)?.id && (
                          <div className="text-danger">
                            {agentError?.agent[rowIndex]?.message}
                          </div>
                        )}
                      {cellIndex === 16 &&
                        agentError?.email?.at(rowIndex)?.id && (
                          <div className="text-danger">
                            {agentError?.email[rowIndex]?.message}
                          </div>
                        )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className=" d-flex gap-3">
            <div>
              <button
                onClick={handleGenerate}
                className="mb-3 btn setup-btn mx-auto"
                disabled={!parsedData.length}
              >
                Confirm
              </button>
            </div>
            <div>
              <button
                type="button"
                className="mb-3 btn setup-btn mx-auto"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </div>
          {message2 && <p className=" text-danger">{message2}</p>}
        </div>
      )}
    </div>
  );
};

export default UploadExcel;
