import React from 'react'
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';

import columns from './Columns';
import data from './data';

function InvoiceLogs() {
    const navigate = useNavigate();



    const options = {
        filter: false,
        Selection: false,
        print: false,
        download: false,
        viewColumns: false,
        displayRowCheckbox: false,
        selectableRows: 'none',
        onRowClick: (rowData) => {
            navigate(`/booking-details/${Number(rowData[2].split(" ")[0])}`)
        },
    };
    return (
        <div className='Full_height'>
            <div className="manual_invoices width_95percent ">
                <div className="manual_invoices_header">
                    <div>Invoice Logs Details</div>
                </div>
                <div className='tabel_overflow_x'>
                    <MUIDataTable
                        className=' tabel_overflow_x'
                        title={""}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </div>

            </div>
        </div>
    )
}

export default InvoiceLogs