import React from "react";
import Header from "../components/Common/Header";
import Dashboard from "../components/Home/Dashboard";
import Footer from "../components/Footer";
import "../Styles/home.css";
import Reservation from "../components/Reservation/Reservation";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetPromotionData } from "../redux/features/promotion/PromotionSlice";
import { Modal } from "@mui/material";
import { Box } from "@mui/material";
import { useState } from "react";
import { IconButton } from "@mui/material";
import { apiUrl } from "../Utils/configUrl";
import CloseIcon from "@mui/icons-material/Close";
import { clearFlightResult } from "../redux/features/home/FlightSearchSlice";

const Home = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    localStorage.removeItem("model");
  };

  const { promotionData } = useSelector((state) => state?.promotion);
  const hasUserLoggedIn = localStorage.getItem("model");

  useEffect(() => {
    dispatch(clearFlightResult());
    localStorage.setItem("Active_link", `home`);
    if (hasUserLoggedIn && promotionData?.at(0)?.status) {
      dispatch(asyncGetPromotionData());
      setIsModalOpen(true);
    }
  }, []);

  return (
    <div className="pb-4">
      <Reservation />
      <Dashboard />

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            outline: "none",
            p: 2,
          }}
        >
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: -10,
              right: -10,
            }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>

          <img
            src={`${apiUrl}/${promotionData?.at(0)?.image}`}
            alt=""
            style={{ width: "100%", maxHeight: "500px" }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Home;
