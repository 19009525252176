import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { HTTP, HTTPForm, HTTPJson } from "../ApiConfig";

const initialState = {
  visaSupplierData: [],
  umrahVisaSupplierData: [],

  editVisaSupplier:null,
  loading: false,
  error: null,
};

// getting visasupplier data
export const asyncGetVisaSupplier = createAsyncThunk(
  "asyncGetVisaSupplier/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await HTTP.get(`/api/v1/b2b/umrah_visa_supplier`);
      console.log(response.data.data);
      return await response.data.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// posting visasupplier data
export const asyncPostVisaSupplier = createAsyncThunk(
  "asyncPostVisaSupplier/post",
  async (fd, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post("/api/v1/b2b/umrah_visa_supplier/umrah_visa_supplier_create", fd);

      console.log(await response.data);
      // toast.success("visa supplier data added");
      dispatch(asyncGetVisaSupplier());
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// edit Visa supplier data
export const asyncEditVisaSupplier = createAsyncThunk(
  "asyncEditVisaSupplier/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/umrah_visa_supplier/umrah_visa_supplier_edit/${id}`,
      );
      console.log(await response.data);
      // toast.success("umrah Route added");
      return await response.data.data.edit;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// post Visa supplier data
export const asyncUpdateVisaSupplier = createAsyncThunk(
  "asyncUpdateVisaSupplier/get",
  async (fd, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTPForm.post(
        `/api/v1/b2b/umrah_visa_supplier/umrah_visa_supplier_update`,
        fd
      );
      dispatch(asyncGetVisaSupplier())
      // console.log(await response.data);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

// delete Visa supplier data
export const asyncDeleteVisaSupplier = createAsyncThunk(
  "asyncDeleteVisaSupplier/get",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await HTTP.get(
        `/api/v1/b2b/umrah_visa_supplier/umrah_visa_supplier_destroy/${id}`,
      );
      dispatch(asyncGetVisaSupplier())
      // console.log(await response.data);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const VisaSupplier = createSlice({
  name: "VisaSupplier",
  initialState,
reducers:{
  editVisaSupplierNull:(state)=>{
    state.editVisaSupplier=null
  }
},
  extraReducers: (builder) => {
    // getting transfer
    builder.addCase(asyncGetVisaSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncGetVisaSupplier.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.umrahVisaSupplierData = payload;
    });
    builder.addCase(asyncGetVisaSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });

    // posting vehicles data
    builder.addCase(asyncPostVisaSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncPostVisaSupplier.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(asyncPostVisaSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
    // edit visasupplier data
    builder.addCase(asyncEditVisaSupplier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(asyncEditVisaSupplier.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.editVisaSupplier=payload
    });
    builder.addCase(asyncEditVisaSupplier.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
        // update visasupplier data
        builder.addCase(asyncUpdateVisaSupplier.pending, (state) => {
          state.loading = true;
          state.error = null;
        });
        builder.addCase(asyncUpdateVisaSupplier.fulfilled, (state) => {
          state.loading = false;
          state.error = null;
        });
        builder.addCase(asyncUpdateVisaSupplier.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        });
                // delete visasupplier data
                builder.addCase(asyncDeleteVisaSupplier.pending, (state) => {
                  state.loading = true;
                  state.error = null;
                });
                builder.addCase(asyncDeleteVisaSupplier.fulfilled, (state) => {
                  state.loading = false;
                  state.error = null;
                });
                builder.addCase(asyncDeleteVisaSupplier.rejected, (state, { payload }) => {
                  state.loading = false;
                  state.error = payload;
                });
  },
});

export const {editVisaSupplierNull} =VisaSupplier.actions
export default VisaSupplier.reducer;
