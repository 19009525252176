import React from 'react';
import { useNavigate } from "react-router-dom";

export default function AgentCount({ bg_color, title, img, counter, preview }) {
    const navigate = useNavigate();
    return (
        <div className="px-md-2">
            <div
                className={`custom-card ${bg_color}`}
                style={{ background: `${bg_color}` }}
            >
                <div className="d-flex justify-content-between align-items-start">
                    <div className=' flex-grow-1'>
                        <h4 className="card-title text-white card_title">{title}</h4>
                        <h5 className="text-white h4 mt-3">{counter?.totalCount}</h5>
                    </div>
                    <div className="ps-5 flex-grow-1 text-end">
                        <div className="ps-5 d-flex align-items-center ">
                            <b className="text-white mt-2 w-75">{counter?.active?.title}</b>
                            <span className="text-white mt-2 w-25">
                                {counter?.active?.count}
                            </span>
                        </div>
                        <div className="ps-5 d-flex align-items-center ">
                            <b className="text-white mt-2 w-75 ">{counter?.inactive?.title}</b>
                            <span className="text-white mt-2 w-25">
                                {counter?.inactive?.count}
                            </span>
                        </div>
                        <div className="ps-5 d-flex align-items-center ">
                            <b className="text-white mt-2 w-75">{counter?.pending?.title}</b>
                            <span className="text-white mt-2 w-25">
                                {counter?.pending?.count}
                            </span>
                        </div>
                    </div>
                </div>
                {preview && (
                    <div
                        className="text-white text-center mt-3"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate("/agents")}
                    >
                        Preview All
                    </div>
                )}
            </div>
        </div>
    );
}
